import { APPLE_APP_STORE_LINK, GOOGLE_PLAY_LINK } from "@core/globalConstants";
import {
  IconFacebook,
  IconInstagram,
  IconTikTok,
  IconX,
} from "@krakentech/icons";
import {
  Box,
  Container,
  Grid,
  Stack,
  Typography,
} from "@octopus-energy/coral-mui";
import useTranslation from "next-translate/useTranslation";
import Image from "next/image";
import Link from "next/link";

const InstagramLogo = () => (
  <a
    target="_blank"
    rel="noopener"
    title="Visit the Octopus Energy Instagram Account"
    href="https://www.instagram.com/octopusenergyus/"
  >
    <IconInstagram />
  </a>
);

const XLogo = () => (
  <a
    target="_blank"
    rel="noopener"
    title="Visit the Octopus Energy Twitter Account"
    href="https://twitter.com/OctopusEnergyUS"
  >
    <IconX />
  </a>
);

const TiktokLogo = () => (
  <a
    target="_blank"
    rel="noopener"
    title="Visit the Octopus Energy Tiktok Account"
    href="https://twitter.com/OctopusEnergyUS"
  >
    <IconTikTok />
  </a>
);

const FacebookLogo = () => (
  <a
    target="_blank"
    rel="noopener"
    title="Visit the Octopus Energy Facebook Account"
    href="https://www.facebook.com/OctopusEnergyUS/"
  >
    <IconFacebook />
  </a>
);

const FooterLogo = () => (
  <svg
    width="180"
    height="34"
    viewBox="0 0 163 23"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M13.6417 9.32711C13.6417 13.0541 10.9343 16.0762 6.74933 16.0762C2.94568 16.0762 0 13.141 0 9.414C0 5.49394 3.01241 2.75183 6.8256 2.75183C10.6388 2.75183 13.6417 5.60015 13.6417 9.32711ZM6.8256 13.5658C9.08491 13.5658 10.696 11.712 10.696 9.42366C10.696 7.13535 9.24697 5.27187 6.8256 5.27187C4.44236 5.27187 2.95522 7.12569 2.95522 9.414C2.95522 11.7023 4.54722 13.5658 6.8256 13.5658Z"
      fill="white"
    />
    <path
      d="M20.5815 2.96418C15.51 3.33109 14.3565 8.10082 14.4804 9.49119C14.4137 11.5864 15.8532 16.0761 21.4586 16.0761C22.1926 16.0665 22.8694 15.9989 23.4891 15.883V13.3437C22.9552 13.4402 22.307 13.4981 21.5253 13.5175C18.9132 13.585 17.4261 11.4126 17.4356 9.50084C17.4356 7.28012 19.2278 5.45526 21.5253 5.48422H26.0534L26.063 11.7698C26.063 11.7698 25.7389 17.0899 32.3738 15.9796V13.4499C28.6941 14.2513 28.8847 11.905 28.8847 11.905V5.47457H32.3738V2.96418H28.8847V0H26.0534V2.96418H20.5815Z"
      fill="white"
    />
    <path
      d="M46.7208 9.32711C46.7208 13.0541 44.0134 16.0762 39.8285 16.0762C36.0248 16.0762 33.0791 13.141 33.0791 9.414C33.0791 5.49394 36.0915 2.75183 39.9047 2.75183C43.7179 2.75183 46.7208 5.60015 46.7208 9.32711ZM39.9047 13.5658C42.164 13.5658 43.7751 11.712 43.7751 9.42366C43.7751 7.13535 42.3261 5.27187 39.9047 5.27187C37.5215 5.27187 36.0343 7.12569 36.0343 9.414C36.0343 11.7023 37.6263 13.5658 39.9047 13.5658Z"
      fill="white"
    />
    <path
      d="M50.3723 19.89C50.134 21.7341 47.5601 20.7493 47.5601 20.7493V9.41394C47.7221 5.80285 49.8575 2.85797 54.2522 2.75176C58.1321 2.66487 60.9253 5.59043 60.9443 9.31738C60.9729 13.2664 58.237 16.2016 54.195 16.0278C52.7555 15.9506 51.3446 15.3713 50.3723 14.1064C50.3818 14.1161 50.3723 18.5768 50.3723 19.89ZM54.1855 13.5754C56.4257 13.5754 58.0177 11.7216 58.0177 9.43325C58.0177 7.14494 56.5878 5.29111 54.1855 5.29111C51.8308 5.29111 50.3532 7.14494 50.3532 9.43325C50.3532 11.7216 51.9357 13.5754 54.1855 13.5754Z"
      fill="white"
    />
    <path
      d="M61.7734 10.6499C61.7734 10.6499 61.7734 5.55187 61.7734 4.22909C62.0118 2.38492 64.5857 3.36976 64.5857 3.36976V10.254C64.5857 12.3975 66.0347 13.4499 67.8269 13.4499C69.6191 13.4499 71.0681 12.4361 71.0681 10.254C71.0681 10.254 71.0776 5.55187 71.0776 4.22909C71.3159 2.38492 73.8803 3.37942 73.8803 3.37942V7.80156C74.2425 13.2568 79.3999 13.6623 79.3999 13.6623C84.5286 14.3768 84.0806 12.2527 84.0806 12.2527C83.9471 11.1809 83.1559 10.9395 81.7926 10.7561C80.9442 10.6499 80.2102 10.5726 80.2102 10.5726C74.7097 10.225 75.253 6.54636 75.253 6.54636C75.3198 3.43735 78.6086 2.96424 78.6086 2.96424C81.5067 2.44285 83.5562 2.95458 83.5562 2.95458C88.0653 3.91046 85.8441 6.07325 85.4724 6.19877C85.4724 6.19877 84.2712 5.08841 81.459 4.92427C77.9985 4.7022 78.0557 6.35326 78.0557 6.35326C77.9413 7.56017 79.4666 7.78225 79.4666 7.78225L83.1749 8.30363C87.5601 8.92157 87.0262 12.0692 87.0262 12.0692C86.7403 16.0086 82.1072 16.0375 82.1072 16.0375C75.7678 16.4141 73.3846 13.0734 73.3846 13.0734C72.498 14.8596 70.7249 16.0569 67.7697 16.0569C64.252 16.0569 61.7734 14.0582 61.7734 10.4761"
      fill="white"
    />
    <path
      d="M101.269 13.2373C100.173 14.5215 98.6663 15.207 96.8932 15.207C95.263 15.207 93.8807 14.618 92.8988 13.5077C91.9742 12.4649 91.4689 10.9973 91.4689 9.38484V9.14346H102.346V9.0469C102.289 7.36688 101.707 5.83168 100.687 4.71166C99.6196 3.53371 98.1801 2.91577 96.5404 2.91577C93.1753 2.91577 90.7349 5.63857 90.7349 9.38484C90.7349 13.2277 93.2706 15.9022 96.8932 15.9022C98.8856 15.9022 100.573 15.1587 101.793 13.7587L101.85 13.6911L101.326 13.1601L101.269 13.2373ZM91.5356 8.44827C91.936 5.5034 93.8807 3.61095 96.5309 3.61095C97.8941 3.61095 99.0953 4.11303 100.01 5.06891C100.849 5.93789 101.383 7.1448 101.507 8.45793H91.5356V8.44827Z"
      fill="white"
    />
    <path
      d="M113.871 4.52821C112.909 3.50475 111.517 2.91577 110.039 2.91577C108.571 2.91577 107.17 3.50475 106.207 4.52821C105.292 5.52271 104.863 6.80687 104.863 8.57379V15.6222H105.606V8.57379C105.606 7.00963 105.978 5.8703 106.788 5.00132C107.58 4.142 108.829 3.61095 110.039 3.61095C111.25 3.61095 112.499 4.142 113.29 5.00132C114.1 5.87996 114.472 7.01928 114.472 8.57379V15.6222H115.216V8.57379C115.216 6.80687 114.787 5.52271 113.871 4.52821Z"
      fill="white"
    />
    <path
      d="M128.237 13.2373C127.141 14.5215 125.635 15.207 123.862 15.207C122.232 15.207 120.849 14.618 119.868 13.5077C118.943 12.4649 118.438 10.9973 118.438 9.38484V9.14346H129.315V9.0469C129.257 7.36688 128.676 5.83168 127.656 4.71166C126.588 3.53371 125.149 2.91577 123.509 2.91577C120.144 2.91577 117.704 5.63857 117.704 9.38484C117.704 13.2277 120.239 15.9022 123.862 15.9022C125.854 15.9022 127.542 15.1587 128.762 13.7587L128.819 13.6911L128.295 13.1601L128.237 13.2373ZM118.504 8.44827C118.905 5.5034 120.849 3.61095 123.5 3.61095C124.863 3.61095 126.064 4.11303 126.979 5.06891C127.818 5.93789 128.352 7.1448 128.476 8.45793H118.504V8.44827Z"
      fill="white"
    />
    <path
      d="M137.036 2.91577C135.568 2.91577 134.167 3.50475 133.204 4.52821C132.289 5.52271 131.86 6.80687 131.86 8.57379V15.6222H132.603V8.57379C132.603 7.00963 132.975 5.8703 133.786 5.00132C134.577 4.142 135.826 3.61095 137.036 3.61095H137.132V2.91577H137.036Z"
      fill="white"
    />
    <path
      d="M144.138 2.91577C142.384 2.91577 140.783 3.59164 139.61 4.81787C138.466 6.02479 137.828 7.65654 137.828 9.40415C137.828 11.1518 138.457 12.7835 139.61 13.9904C140.783 15.2167 142.384 15.8925 144.138 15.8925C146.436 15.8925 148.285 14.7435 149.277 12.7352V15.4098C149.277 16.9063 149.229 18.6057 148.629 19.6678C147.828 21.0871 146.331 21.8692 144.405 21.8692C141.832 21.8692 140.058 20.4885 139.324 19.3009L139.267 19.214L138.667 19.6678L138.714 19.745C139.162 20.4112 140.897 22.5644 144.396 22.5644C146.598 22.5644 148.276 21.705 149.258 20.0733C149.954 18.8857 150.011 17.0319 150.011 15.3808V9.49105V9.45243C150.011 9.43312 150.011 9.42346 150.011 9.40415C150.02 5.5903 147.609 2.91577 144.138 2.91577ZM149.277 9.41381C149.277 12.8318 147.16 15.2167 144.138 15.2167C141.021 15.2167 138.581 12.6677 138.581 9.41381C138.581 6.15996 141.021 3.62061 144.138 3.62061C147.16 3.61095 149.277 5.99582 149.277 9.41381Z"
      fill="white"
    />
    <path
      d="M161.984 3.1958L157.256 13.3049L151.965 3.1958H151.174L156.855 14.1643L153.052 22.352H153.862L162.795 3.1958H161.984Z"
      fill="white"
    />
  </svg>
);

const BetterBiz = () => (
  <svg
    role="img"
    width="15%"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 47 71"
    className="fill-current"
  >
    <title>Better Business</title>
    <path d="M5.83761 2.29856C5.72815 2.13438 5.60046 1.98844 5.45452 1.86074C5.29033 1.73304 5.12615 1.64183 4.92548 1.56886C4.72481 1.49589 4.52415 1.4594 4.30524 1.4594C3.9039 1.4594 3.57553 1.53237 3.3019 1.69656C3.02826 1.8425 2.80935 2.06141 2.62692 2.31681C2.46274 2.5722 2.33504 2.86408 2.26207 3.19245C2.1891 3.52081 2.15261 3.84918 2.15261 4.21403C2.15261 4.5424 2.1891 4.87076 2.26207 5.19913C2.33504 5.50925 2.46274 5.80113 2.62692 6.05653C2.7911 6.31192 3.02826 6.51259 3.3019 6.65853C3.57553 6.80447 3.9039 6.89568 4.30524 6.89568C4.83427 6.89568 5.25385 6.7315 5.56397 6.40314C5.87409 6.07477 6.05652 5.63695 6.11125 5.10791H7.8078C7.77132 5.60046 7.64362 6.05653 7.4612 6.45786C7.27877 6.8592 7.02338 7.20581 6.73149 7.47945C6.42137 7.75308 6.07476 7.97199 5.65518 8.11794C5.25385 8.26388 4.79778 8.33685 4.30524 8.33685C3.70323 8.33685 3.13771 8.22739 2.66341 8.02672C2.17086 7.80781 1.76952 7.51593 1.42291 7.15108C1.09455 6.78623 0.839151 6.34841 0.656725 5.83762C0.4743 5.34507 0.383087 4.79779 0.383087 4.21403C0.383087 3.61203 0.4743 3.06475 0.656725 2.55396C0.839151 2.04317 1.09455 1.60535 1.42291 1.22225C1.75128 0.83916 2.17086 0.547277 2.66341 0.328366C3.15595 0.109456 3.70323 0 4.30524 0C4.74306 0 5.16264 0.0547277 5.54573 0.182425C5.92882 0.310123 6.29367 0.492549 6.58555 0.729702C6.89568 0.966855 7.15107 1.27698 7.35174 1.62359C7.55241 1.98844 7.68011 2.38978 7.73484 2.84584H6.03828C6.00179 2.64517 5.94706 2.46275 5.83761 2.29856Z" />
    <path d="M10.2889 6.74978C10.526 6.98694 10.8726 7.0964 11.3104 7.0964C11.6388 7.0964 11.9124 7.02342 12.1314 6.85924C12.3685 6.69506 12.4962 6.53088 12.5509 6.34845H13.9374C13.7185 7.04167 13.3718 7.53422 12.9158 7.8261C12.4597 8.11798 11.8942 8.26392 11.2375 8.26392C10.7814 8.26392 10.3801 8.19095 10.0152 8.04501C9.65036 7.89907 9.34024 7.6984 9.08485 7.42476C8.82945 7.15112 8.62878 6.841 8.50108 6.45791C8.35514 6.09306 8.30042 5.67348 8.30042 5.23566C8.30042 4.79783 8.37339 4.3965 8.51933 4.03165C8.66527 3.6668 8.86593 3.33843 9.12133 3.06479C9.37673 2.79115 9.68685 2.57224 10.0517 2.4263C10.4166 2.26212 10.8179 2.18915 11.2557 2.18915C11.7483 2.18915 12.1678 2.28036 12.5327 2.48103C12.8975 2.66346 13.1894 2.91885 13.4266 3.24722C13.6637 3.57558 13.8279 3.94043 13.9374 4.34177C14.0468 4.74311 14.0833 5.18093 14.0468 5.61875H9.88752C9.924 6.14778 10.0517 6.51263 10.2889 6.74978ZM12.0949 3.72152C11.9124 3.52086 11.6206 3.4114 11.2375 3.4114C10.9821 3.4114 10.7814 3.44789 10.599 3.5391C10.4348 3.63031 10.3071 3.73977 10.1976 3.84922C10.1064 3.97692 10.0335 4.10462 9.97873 4.25056C9.94225 4.3965 9.90576 4.5242 9.90576 4.63365H12.478C12.405 4.23232 12.2773 3.92219 12.0949 3.72152Z" />
    <path d="M16.2906 2.37152V3.44784H16.3088C16.3818 3.26541 16.4913 3.10123 16.6007 2.95529C16.7284 2.80935 16.8744 2.68165 17.0385 2.57219C17.2027 2.46274 17.3852 2.38977 17.5676 2.3168C17.75 2.26207 17.9507 2.22559 18.1513 2.22559C18.2608 2.22559 18.3702 2.24383 18.4979 2.28031V3.75796C18.425 3.73972 18.3338 3.72147 18.2243 3.72147C18.1149 3.70323 18.0236 3.70323 17.9324 3.70323C17.6405 3.70323 17.4034 3.75796 17.2027 3.84917C17.0021 3.94038 16.8379 4.08632 16.7102 4.25051C16.5825 4.41469 16.4913 4.61536 16.4548 4.83427C16.4001 5.05318 16.3818 5.29033 16.3818 5.56397V8.13617H14.7947V2.37152H16.2906Z" />
    <path d="M22.7302 2.37152V3.42958H21.5627V6.29366C21.5627 6.5673 21.5992 6.73149 21.6904 6.8227C21.7816 6.91391 21.9641 6.9504 22.2194 6.9504C22.3107 6.9504 22.4019 6.9504 22.4748 6.93215C22.5478 6.93215 22.639 6.91391 22.712 6.89567V8.11792C22.5843 8.13616 22.4201 8.15441 22.2742 8.15441C22.11 8.15441 21.9458 8.17265 21.7999 8.17265C21.5627 8.17265 21.3256 8.1544 21.1067 8.11792C20.8877 8.08143 20.6871 8.02671 20.5229 7.91725C20.3587 7.82604 20.231 7.6801 20.1216 7.49767C20.0303 7.31525 19.9756 7.07809 19.9756 6.80446V3.3931H19.0088V2.33503H19.9756V0.60199H21.5627V2.33503H22.7302V2.37152Z" />
    <path d="M23.5329 0.164185H25.12V1.47765H23.5329V0.164185ZM25.12 8.13618H23.5329V2.37153H25.12V8.13618Z" />
    <path d="M25.7767 3.42961V2.37154H26.7253V1.93372C26.7253 1.42293 26.8895 1.00335 27.1997 0.674985C27.528 0.346619 28.0023 0.182434 28.6591 0.182434C28.805 0.182434 28.9327 0.182435 29.0786 0.200678C29.2246 0.21892 29.3523 0.21892 29.4982 0.237162V1.42293C29.3158 1.40469 29.1151 1.38644 28.9144 1.38644C28.6955 1.38644 28.5496 1.44117 28.4584 1.53238C28.3672 1.6236 28.3124 1.80602 28.3124 2.04318V2.38978H29.407V3.44785H28.3124V8.13619H26.7253V3.42961H25.7767Z" />
    <path d="M30.3556 0.164185H31.9427V1.47765H30.3556V0.164185ZM31.9427 8.13618H30.3556V2.37153H31.9427V8.13618Z" />
    <path d="M34.4419 6.74978C34.6791 6.98694 35.0257 7.0964 35.4635 7.0964C35.7919 7.0964 36.0655 7.02342 36.2844 6.85924C36.5216 6.69506 36.6493 6.53088 36.704 6.34845H38.0904C37.8715 7.04167 37.5249 7.53422 37.0689 7.8261C36.6128 8.11798 36.0473 8.26392 35.3905 8.26392C34.9345 8.26392 34.5331 8.19095 34.1683 8.04501C33.8034 7.89907 33.4933 7.6984 33.2379 7.42476C32.9825 7.15112 32.7819 6.841 32.6542 6.45791C32.5082 6.09306 32.4535 5.67348 32.4535 5.23566C32.4535 4.79783 32.5265 4.3965 32.6724 4.03165C32.8183 3.6668 33.019 3.33843 33.2744 3.06479C33.5298 2.79115 33.8399 2.57224 34.2048 2.4263C34.5696 2.26212 34.971 2.18915 35.4088 2.18915C35.9013 2.18915 36.3209 2.28036 36.6858 2.48103C37.0506 2.66346 37.3425 2.91885 37.5796 3.24722C37.8168 3.57558 37.981 3.94043 38.0904 4.34177C38.1999 4.74311 38.2364 5.18093 38.1999 5.61875H34.0406C34.0771 6.14778 34.2048 6.51263 34.4419 6.74978ZM36.2297 3.72152C36.0473 3.52086 35.7554 3.4114 35.3723 3.4114C35.1169 3.4114 34.9162 3.44789 34.7338 3.5391C34.5696 3.63031 34.4237 3.73977 34.3325 3.84922C34.223 3.97692 34.1683 4.10462 34.1136 4.25056C34.0771 4.3965 34.0406 4.5242 34.0406 4.63365H36.6128C36.5581 4.23232 36.4121 3.92219 36.2297 3.72152Z" />
    <path d="M43.0159 7.38823C42.8335 7.69836 42.5781 7.91727 42.2862 8.06321C41.9761 8.20915 41.6477 8.26388 41.2646 8.26388C40.8268 8.26388 40.462 8.17266 40.1336 8.00848C39.8052 7.8443 39.5316 7.60714 39.3309 7.33351C39.112 7.04163 38.9661 6.71326 38.8566 6.34841C38.7472 5.98356 38.6924 5.58222 38.6924 5.18089C38.6924 4.79779 38.7472 4.4147 38.8566 4.06809C38.9661 3.70324 39.1303 3.39312 39.3309 3.11948C39.5498 2.84584 39.8052 2.62693 40.1336 2.46274C40.462 2.29856 40.8268 2.20735 41.2464 2.20735C41.593 2.20735 41.9214 2.28032 42.2133 2.42626C42.5234 2.5722 42.7605 2.79111 42.943 3.06475H42.9612V0.164185H44.5483V8.13618H43.0342V7.40648H43.0159V7.38823ZM42.943 4.5424C42.9065 4.32349 42.8153 4.12282 42.7058 3.95864C42.5963 3.79445 42.4504 3.64851 42.2862 3.5573C42.122 3.44784 41.9031 3.39312 41.6477 3.39312C41.3923 3.39312 41.1734 3.44784 40.991 3.5573C40.8086 3.66675 40.6626 3.79445 40.5532 3.97688C40.4437 4.14106 40.3708 4.34173 40.316 4.56064C40.2613 4.77955 40.2431 5.0167 40.2431 5.25385C40.2431 5.47276 40.2613 5.69168 40.316 5.92883C40.3708 6.14774 40.462 6.34841 40.5714 6.53083C40.6809 6.71326 40.8268 6.84096 41.0092 6.95041C41.1917 7.05987 41.3923 7.11459 41.6477 7.11459C41.9031 7.11459 42.122 7.05987 42.3045 6.95041C42.4869 6.84096 42.6146 6.71326 42.724 6.53083C42.8335 6.34841 42.9065 6.16598 42.943 5.92883C42.9794 5.70992 43.0159 5.47277 43.0159 5.23561C43.0159 4.99846 42.9977 4.76131 42.943 4.5424Z" />
    <path d="M12.9704 21.0884H22.2012C24.445 21.0884 27.4003 21.0884 28.9327 22.0188C30.611 23.0221 31.9244 24.8281 31.9244 27.3273C31.9244 30.0272 30.5015 32.0704 28.1482 33.0372V33.1102C31.286 33.7487 33.0007 36.102 33.0007 39.2397C33.0007 42.9794 30.3191 46.555 25.2659 46.555H12.9704V21.0884ZM16.3635 32.0339H23.1498C26.9989 32.0339 28.5678 30.6475 28.5678 27.9658C28.5678 24.4268 26.0686 23.9342 23.1498 23.9342H16.3635V32.0339ZM16.3635 43.6909H24.8463C27.7651 43.6909 29.6259 41.9031 29.6259 39.1303C29.6259 35.8101 26.9442 34.8797 24.0984 34.8797H16.3635V43.6909Z" />
    <path d="M41.666 33.8217C41.666 44.5118 33.0008 53.177 22.2924 53.177C11.6023 53.177 2.93705 44.5118 2.93705 33.8217C2.93705 23.1316 11.6023 14.4663 22.2924 14.4663C33.0008 14.4663 41.666 23.1316 41.666 33.8217ZM22.3106 11.511C9.99692 11.511 0 21.4897 0 33.8217C0 46.1354 9.97868 56.1323 22.3106 56.1323C34.6244 56.1323 44.6213 46.1536 44.6213 33.8217C44.6213 21.4897 34.6244 11.511 22.3106 11.511Z" />
    <path d="M4.12277 60.9301C4.0498 60.3646 3.50252 59.945 2.88227 59.945C1.76948 59.945 1.3499 60.8936 1.3499 61.8787C1.3499 62.8091 1.76948 63.7577 2.88227 63.7577C3.64846 63.7577 4.06804 63.2287 4.15925 62.499H5.36326C5.23556 63.9037 4.26871 64.7975 2.88227 64.7975C1.13099 64.7975 0.109406 63.5023 0.109406 61.8605C0.109406 60.1822 1.13099 58.887 2.88227 58.887C4.12277 58.887 5.16259 59.6167 5.30853 60.9119H4.12277V60.9301Z" />
    <path d="M7.95372 60.4923C9.24894 60.4923 10.0699 61.3497 10.0699 62.6449C10.0699 63.9401 9.24894 64.7976 7.95372 64.7976C6.67674 64.7976 5.83759 63.9401 5.83759 62.6449C5.85583 61.3315 6.67674 60.4923 7.95372 60.4923ZM7.95372 63.9402C8.71991 63.9402 8.95706 63.2834 8.95706 62.6449C8.95706 61.9882 8.71991 61.3315 7.95372 61.3315C7.18754 61.3315 6.96863 61.9882 6.96863 62.6449C6.96863 63.2834 7.20578 63.9402 7.95372 63.9402Z" />
    <path d="M10.7266 60.6018H11.7847V61.3679H11.803C12.0036 60.8572 12.5691 60.4923 13.0982 60.4923C13.1712 60.4923 13.2624 60.5106 13.3353 60.5288V61.5686C13.2259 61.5504 13.0617 61.5321 12.934 61.5321C12.1131 61.5321 11.8212 62.1159 11.8212 62.8456V64.6881H10.7084V60.6018H10.7266Z" />
    <path d="M13.9555 60.6018H15.0136V61.1308H15.0318C15.3055 60.693 15.7433 60.4923 16.2541 60.4923C17.5311 60.4923 18.1148 61.5321 18.1148 62.6814C18.1148 63.776 17.5128 64.7976 16.3271 64.7976C15.8345 64.7976 15.3785 64.5786 15.1048 64.1773H15.0683V66.1293H13.9555V60.6018ZM16.9838 62.6449C16.9838 61.9882 16.7284 61.3315 15.9987 61.3315C15.269 61.3315 15.0318 61.9882 15.0318 62.6449C15.0318 63.3017 15.2872 63.9402 16.0169 63.9402C16.7466 63.9402 16.9838 63.3199 16.9838 62.6449Z" />
    <path d="M20.76 60.4923C22.0552 60.4923 22.8762 61.3497 22.8762 62.6449C22.8762 63.9401 22.037 64.7976 20.76 64.7976C19.483 64.7976 18.6439 63.9401 18.6439 62.6449C18.6439 61.3315 19.483 60.4923 20.76 60.4923ZM20.76 63.9402C21.5262 63.9402 21.7634 63.2834 21.7634 62.6449C21.7634 61.9882 21.5262 61.3315 20.76 61.3315C19.9938 61.3315 19.7749 61.9882 19.7749 62.6449C19.7749 63.2834 19.9938 63.9402 20.76 63.9402Z" />
    <path d="M23.6241 60.6018H24.6822V61.3679H24.7004C24.9011 60.8572 25.4666 60.4923 25.9956 60.4923C26.0686 60.4923 26.1781 60.5106 26.2328 60.5288V61.5686C26.1233 61.5504 25.9592 61.5321 25.8315 61.5321C25.0105 61.5321 24.7187 62.1159 24.7187 62.8456V64.6881H23.6059V60.6018H23.6241Z" />
    <path d="M26.6524 61.8605C26.7253 60.8024 27.6557 60.4923 28.5678 60.4923C29.3887 60.4923 30.3556 60.6747 30.3556 61.6598V63.776C30.3556 64.1408 30.3921 64.5239 30.5015 64.6881H29.3705C29.334 64.5604 29.2975 64.4327 29.2975 64.2868C28.9509 64.6516 28.4219 64.7976 27.9293 64.7976C27.1632 64.7976 26.5429 64.4145 26.5429 63.5753C26.5429 62.6449 27.2361 62.426 27.9293 62.3348C28.6226 62.2254 29.261 62.2618 29.261 61.8058C29.261 61.3315 28.9327 61.2585 28.5313 61.2585C28.1118 61.2585 27.8199 61.4227 27.7834 61.8787H26.6524V61.8605ZM29.2428 62.6814C29.0604 62.8456 28.659 62.8638 28.3124 62.9186C27.9658 62.9915 27.6557 63.101 27.6557 63.5206C27.6557 63.9402 27.9841 64.0496 28.3489 64.0496C29.2246 64.0496 29.261 63.3564 29.261 63.1192V62.6814H29.2428Z" />
    <path d="M32.6542 60.6018H33.4751V61.3497H32.6542V63.3746C32.6542 63.7577 32.7454 63.8489 33.1285 63.8489C33.2562 63.8489 33.3656 63.8489 33.4751 63.8125V64.6881C33.2927 64.7246 33.0373 64.7246 32.8183 64.7246C32.1251 64.7246 31.5414 64.5604 31.5414 63.7577V61.3497H30.8664V60.6018H31.5414V59.3795H32.6542V60.6018Z" />
    <path d="M35.1534 59.9633H34.0406V59.0329H35.1534V59.9633ZM34.0406 60.6018H35.1534V64.6881H34.0406V60.6018Z" />
    <path d="M37.8715 60.4923C39.1667 60.4923 39.9876 61.3497 39.9876 62.6449C39.9876 63.9401 39.1485 64.7976 37.8715 64.7976C36.5945 64.7976 35.7736 63.9401 35.7736 62.6449C35.7554 61.3315 36.5945 60.4923 37.8715 60.4923ZM37.8715 63.9402C38.6377 63.9402 38.8748 63.2834 38.8748 62.6449C38.8748 61.9882 38.6559 61.3315 37.8715 61.3315C37.1053 61.3315 36.8864 61.9882 36.8864 62.6449C36.8864 63.2834 37.1053 63.9402 37.8715 63.9402Z" />
    <path d="M40.6991 60.6018H41.7571V61.1673H41.7754C42.0673 60.7112 42.5416 60.4923 43.0159 60.4923C44.2016 60.4923 44.5118 61.1673 44.5118 62.1706V64.6881H43.399V62.3895C43.399 61.7146 43.1983 61.3862 42.6875 61.3862C42.0855 61.3862 41.8119 61.7328 41.8119 62.5537V64.6881H40.6991V60.6018Z" />
    <path d="M44.5483 68.1542H0.091217V71H44.5483V68.1542Z" />
    <path d="M45.1868 58.8687H44.9314V59.5802H44.8037V58.8687H44.5483V58.7593H45.205V58.8687H45.1868ZM46.2813 59.5802H46.1536V58.887L45.88 59.5802H45.7888L45.5151 58.887V59.5802H45.3692V58.7593H45.5881L45.8435 59.3795L46.0807 58.7593H46.2996V59.5802H46.2813Z" />
  </svg>
);

const TexasIcon = () => (
  <svg
    role="img"
    width="24"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 13 13"
  >
    <title>Texas Icon</title>
    <path
      d="M12.9497 6.60279L13 6.14365L12.8954 5.98666L12.7536 5.72093L12.6416 5.44669L12.5685 5.26373L12.3946 5.14354L12.2094 3.84271L12.1985 3.14388L11.9765 3.16304L11.7922 3.19896L11.7344 3.12446L11.3018 2.9271L11.0234 2.82351L10.8133 2.92655L10.4767 2.94425L10.278 2.96357L10.1219 3.07709L9.9656 3.14142L9.82104 3.09279L9.5062 3.18432L9.34766 2.99143L9.20987 3.17908L8.94382 3.11876L8.66477 2.94709L8.38459 3.08809L8.24325 2.80123L7.80047 2.85622L7.51339 2.80584L7.18977 2.73481L7.03778 2.48782L6.79097 2.58105L6.63237 2.48766L6.40115 2.3654L6.36127 1.19749L6.31966 0L5.39464 0.0306451L4.46886 0.0510805L3.54373 0.0601024L3.5547 3.26656L3.56254 5.57613L2.65132 5.57475L0.883474 5.5435L0 5.51378L0.00697578 5.61917L0.110839 5.81949L0.457044 6.26874L0.953349 6.67258L1.53417 7.22867L1.85659 7.87898L2.09891 8.56216L2.62019 8.94114L3.46484 9.31437L4.09935 8.33268L4.92513 8.29654L5.64933 8.77211L6.18317 9.60426L6.56107 10.3179L7.1976 11.0006L7.46196 11.8609L7.78142 12.432L8.62266 12.7736L9.38878 13L9.79929 12.9337L9.57207 12.5234L9.48295 11.9292L9.44018 11.0709L9.64337 10.4958L10.1659 9.86179L11.143 9.24205L11.9893 8.23884L12.8252 7.88872L12.7264 7.73555L12.8107 7.54386L12.9446 7.36466L12.923 7.11939L12.8419 7.04581L12.8995 6.74286L12.9497 6.60279Z"
      fill="#BFA3E9"
    />
  </svg>
);

type LinkProps = {
  href: string;
  label: string;
  external?: boolean;
};

const linksGroup1: LinkProps[] = [
  { href: "/help-and-faqs", label: "footer-faq-link" },
  { href: "/about", label: "global-about-link" },
  { href: "/contact", label: "footer-contact-link" },
  {
    href: "https://octopusenergy.com/press",
    label: "press",
    external: true,
  },
  { href: "/privacy-policy", label: "footer-privacy-link" },
  { href: "/outage-info", label: "footer-outage-text" },
];

const linksGroup2: LinkProps[] = [
  { href: "/smart-features", label: "footer-smart-features-link" },
  {
    href: "https://octopusenergy.com/lubbock",
    label: "lubbockResourcePage",
    external: true,
  },
  { href: "/careers", label: "footer-careers-link", external: true },
  { href: "/legal", label: "footer-legal-link" },
  { href: "/blog", label: "global-blog-link" },
  {
    href: "https://octopusev.us/?utm_medium=partner-site&utm_source=oeus&utm_campaign=octopus-homepage",
    label: "Octopus Electric Vehicles",
    external: true,
  },
];

const countryLinks = [
  {
    href: "https://octopus.energy",
    imgSrc: "/images/uk.svg",
    label: "Octopus Energy UK",
  },
  {
    href: "https://www.octopusenergy.de",
    imgSrc: "/images/de.svg",
    label: "Octopus Energy DE",
  },
  {
    href: "https://octopusenergy.co.jp/",
    imgSrc: "/images/jp.svg",
    label: "Octopus Energy JP",
  },
  {
    href: "https://octopusenergy.fr/",
    imgSrc: "/images/fr.svg",
    label: "Octopus Energy FR",
  },
  {
    href: "https://octopusenergy.nz/",
    imgSrc: "/images/nz.svg",
    label: "Octopus Energy NZ",
  },
  {
    href: "https://octopusenergy.es/",
    imgSrc: "/images/ES.png",
    label: "Octopus Energy ES",
  },
  {
    href: "https://octopusenergy.it/",
    imgSrc: "/images/it.svg",
    label: "Octopus Energy IT",
  },
];

const renderLinks = (links: LinkProps[]) =>
  links.map(({ href, label, external }) => (
    <Link
      key={href}
      href={href}
      target={external ? "_blank" : "_self"}
      rel={external ? "noopener" : ""}
    >
      <Typography variant="caption" fontWeight="medium">
        {useTranslation("common").t(label)}
      </Typography>
    </Link>
  ));

const renderCountryLinks = () => {
  return (
    <Grid
      container
      justifyContent={{ xs: "flex-start", sm: "flex-end" }}
      columnSpacing={1}
    >
      {countryLinks.map(({ href, imgSrc, label }) => (
        <Grid item xs={6} sm={12} key={href} display="flex">
          <a
            href={href}
            rel="noopener"
            target="_blank"
            style={{ width: "100%" }}
          >
            <Box display="flex" justifyContent={{ xs: "start", sm: "end" }}>
              <Box display="flex" mr={1} minWidth={10}>
                <Image src={imgSrc} alt="" height={14} width={14} />
              </Box>
              <Typography variant="caption" fontWeight="medium">
                {label}
              </Typography>
            </Box>
          </a>
        </Grid>
      ))}
    </Grid>
  );
};

export const Footer = () => {
  const { t } = useTranslation("common");
  const currentYear = new Date().getUTCFullYear();

  return (
    <footer>
      <Stack
        sx={{ p: { xs: 4, md: 7 }, bgcolor: "background.paper", width: "100%" }}
        data-cy="footer"
      >
        <Container maxWidth="xl">
          <Stack
            direction="row"
            spacing={2}
            justifyContent="space-between"
            width="100%"
          >
            <FooterLogo />
            <Stack direction="row" spacing={1}>
              <TiktokLogo />
              <FacebookLogo />
              <XLogo />
              <InstagramLogo />
            </Stack>
          </Stack>
          <Stack
            mt={2}
            direction={{ xs: "column", sm: "row" }}
            spacing={{ xs: 2, md: 4 }}
            justifyContent={{ xs: "left", md: "space-between" }}
            width="100%"
          >
            <Stack direction="row" spacing={4} width="100%">
              <Stack>{renderLinks(linksGroup1)}</Stack>
              <Stack>{renderLinks(linksGroup2)}</Stack>
            </Stack>
            <Stack alignItems={{ xs: "flex-start", md: "flex-end" }}>
              {renderCountryLinks()}
            </Stack>
          </Stack>
          <Stack
            direction="row"
            justifyContent="flex-start"
            mt={{ xs: 3, md: 0 }}
            spacing={2}
            width="100%"
          >
            <a href={APPLE_APP_STORE_LINK} rel="noopener" target="_blank">
              <Box maxWidth={150}>
                <Image
                  src="/images/downloadApp/appleStore.svg"
                  alt=""
                  height={100}
                  width={100}
                />
              </Box>
            </a>
            <a href={GOOGLE_PLAY_LINK} rel="noopener" target="_blank">
              <Box maxWidth={150}>
                <Image
                  src="/images/downloadApp/androidStore.svg"
                  alt=""
                  height={100}
                  width={100}
                />
              </Box>
            </a>
          </Stack>
          <Stack justifyContent="center" alignItems="center" mt={3}>
            <Stack direction="row" spacing={1}>
              <TexasIcon />
              <Typography variant="caption" fontWeight="medium" color="#BFA3E9">
                {t("footer-texas-proud-text")}
              </Typography>
            </Stack>
            <Typography variant="caption" align="center" mb={2}>
              ©{currentYear} Octopus Energy LLC. {t("footer-rights-text")}. REP
              License #10262
            </Typography>
            <Box
              width={350}
              display="flex"
              justifyContent="center"
              alignItems="center"
            >
              <BetterBiz />
            </Box>
          </Stack>
        </Container>
      </Stack>
    </footer>
  );
};
