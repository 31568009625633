import {
  useCustomerIsPTC,
  useCustomerIsRivian,
  useSelectedEnrollmentProduct,
} from "@core/enrollment";
import { Maybe } from "@core/types";
import { Tooltip } from "@krakentech/coral";
import { Theme, useMediaQuery, useTheme } from "@mui/material";
import {
  Box,
  Card,
  CardContent,
  Divider,
  Stack,
  Typography,
} from "@octopus-energy/coral-mui";
import useTranslation from "next-translate/useTranslation";
import { useRouter } from "next/router";
import { FC } from "react";
import { OctopusProductTitle } from "../OctopusProducts";
import {
  Compatibility,
  FullyRenewable,
  LockedInRate,
  ProductCardMonthlyEstimate,
  ProductCardRateBreakdown,
  SolarBuyback,
} from "../ProductCard";
import {
  BillCredit,
  EarlyTerminationFee,
  NoBaitAndSwitch,
  OctoGridboost,
  PeakAndOffPeakHours,
} from "../ProductCard/Bullets";

type ProductSelectedCardProps = {
  displayName?: string | null;
  minPrice?: number | string;
  withEV?: Maybe<boolean>;
  withThermostat?: Maybe<boolean>;
  peakPrice?: Maybe<number | string>;
  offPeakPrice?: Maybe<number | string>;
  quoteHasChanged: boolean;
};

export const ProductSelectedCard: FC<ProductSelectedCardProps> = ({
  displayName,
  withEV,
  withThermostat,
  quoteHasChanged,
}) => {
  const { t } = useTranslation("product/productCard");
  const isMobile = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down("sm")
  );
  const alignment = isMobile ? "center" : "left";
  const lang = useRouter().locale;
  const theme = useTheme();
  const selectedProduct = useSelectedEnrollmentProduct();
  const titleLength = displayName?.length || 0;
  const customerIsPTC = useCustomerIsPTC();
  const customerIsRivian = useCustomerIsRivian();

  const totalRate =
    (withEV && !customerIsPTC && selectedProduct?.prices.discountEVPrice) ||
    (withThermostat &&
      !customerIsPTC &&
      selectedProduct?.prices.discountThermostatPrice) ||
    selectedProduct?.prices.basePrice;

  return (
    <Card
      borderColorVariant="secondary"
      borderWidth="medium"
      data-cy="product-checkout-card"
    >
      <Box
        bgcolor={theme.palette.secondary.main}
        justifyContent={{ xs: "center", sm: "left" }}
        display="flex"
        py={1.75}
        px={4}
        width="100%"
      >
        <Typography variant="h6" data-cy="product-header">
          {t("yourPlan")}
        </Typography>
      </Box>
      <CardContent sx={{ p: 4 }}>
        <Box
          display="flex"
          flexDirection={{ xs: "column", sm: "row" }}
          textAlign={{ xs: "center", sm: "left" }}
        >
          <Box width={{ xs: "100%", lg: "60%" }} mb={1}>
            <OctopusProductTitle
              bold={displayName}
              variant={titleLength > 17 ? "h2" : undefined}
              minHeight={60}
              withEV={withEV}
              withThermostat={withThermostat}
            />
            <ProductCardMonthlyEstimate
              minPrice={totalRate}
              monthlyDescriptionProps={{
                align: alignment,
                variant: isMobile ? "caption" : "body2",
              }}
              monthlyRateProps={{
                mt: withThermostat ? 0.25 : 1,
                align: alignment,
                variant: "h3",
              }}
              stackProps={{
                alignItems: alignment,
                justifyContent: alignment,
              }}
              offPeakPrice={selectedProduct?.prices.offPeakPrice}
            />
            <ProductCardRateBreakdown
              minPrice={totalRate}
              variant={"caption"}
              peakPrice={selectedProduct?.prices.peakPrice}
            />
            {quoteHasChanged && (
              <Stack direction="row" spacing={1}>
                <Typography variant="body2" fontWeight="bold">
                  {t("whyIsQuoteDifferent")}
                </Typography>
                <Tooltip title={t("youLiveInMultipleLZArea")} />
              </Stack>
            )}
          </Box>
          <Stack
            alignItems={{ xs: "center", sm: "right" }}
            ml={{ xs: 0, sm: 3, md: 0, lg: 5 }}
            width={{ xs: "100%", lg: "50%" }}
          >
            <Box data-cy="product-bullets">
              {!customerIsRivian && <LockedInRate mt={0.5} />}
              <FullyRenewable mt={withThermostat || withEV ? 1.25 : 2} />
              {customerIsRivian && <PeakAndOffPeakHours />}
              {customerIsRivian && <BillCredit />}
              <SolarBuyback mt={withThermostat || withEV ? 1.25 : 2} />
              {!customerIsRivian && (
                <NoBaitAndSwitch mt={withThermostat || withEV ? 1.25 : 2} />
              )}
              {customerIsRivian && <OctoGridboost />}
              <EarlyTerminationFee mt={2} />
              {(withThermostat || withEV) && (
                <Compatibility
                  mt={withThermostat || withEV ? 0.75 : 2}
                  variant={isMobile ? "caption" : "body2"}
                  withEV={withEV}
                  withThermostat={withThermostat}
                />
              )}
            </Box>
          </Stack>
        </Box>
        {(withThermostat || withEV) && (
          <>
            <Divider sx={{ mt: 2 }} />
            <Box display="flex" justifyContent="center" textAlign="center">
              <Typography
                color="tertiary"
                data-cy="check-your-inbox-text"
                fontWeight="bold"
                mt={2}
                variant={lang === "es" ? "caption" : "body2"}
              >
                {withThermostat && t("checkYourInboxThermostat")}
                {withEV && t("checkYourInboxEV")}
              </Typography>
            </Box>
          </>
        )}
      </CardContent>
    </Card>
  );
};
