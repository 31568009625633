import { Maybe } from "@core/types";
import { IconInfo } from "@krakentech/icons";
import { MenuItem, useMediaQuery } from "@mui/material";
import { Theme } from "@mui/material/styles";
import {
  Alert,
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogContent,
  FormControlLabel,
  FormGroup,
  Link,
  Stack,
  TextField,
  Typography,
} from "@octopus-energy/coral-mui";
import useTranslation from "next-translate/useTranslation";
import { FC, InputHTMLAttributes, useState } from "react";
import { useBooleanState } from "react-use-object-state";
import { useCustomerIsRivian } from "./enrollmentUtils";
import {
  evBrands,
  evModels,
  rivianEvOptions,
  thermostatBrands,
  thermostatModels,
} from "./ioBrands";

export enum IOProduct {
  AMAZON = "Amazon",
  ECOBEE = "Ecobee",
  SENSI = "Sensi",
  GOOGLE_NEST = "GoogleNest",
  HONEYWELL = "Honeywell",
  OTHER = "Other",
  AUDI = "Audi",
  BMW = "BMW",
  CHEVY = "Chevy",
  FORD = "Ford",
  MINI = "Mini",
  PORSCHE = "Porsche",
  TESLA = "Tesla",
  VOLKSWAGEN = "Volkswagen",
}

export type IOEnrollmentConfirmationDialogProps = {
  onConfirm: VoidFunction;
  open: boolean;
  onClose: VoidFunction;
  IOType: Maybe<"thermostat" | "ev">;
};

export const IOEnrollmentConfirmationDialog: FC<
  IOEnrollmentConfirmationDialogProps
> = ({ onConfirm, open, onClose, IOType }) => {
  const { t: t } = useTranslation("product/productCard");
  const ioAgreementChecked = useBooleanState(false);
  const criticalCareChecked = useBooleanState(false);
  const customerIsRivian = useCustomerIsRivian();
  const thermostat = IOType === "thermostat";
  const ev = IOType === "ev";
  const [selectedProduct, setSelectedProduct] =
    useState<Maybe<IOProduct>>(undefined);
  const [productModel, setProductModel] = useState("");
  const fullscreenInformationDialog = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down("md")
  );

  return (
    <Dialog
      maxWidth="sm"
      fullScreen={fullscreenInformationDialog}
      open={open}
      data-cy="io-enrollment-confirmation-dialog"
    >
      <DialogContent data-cy="io-enrollment-confirmation-dialog-content">
        <Typography textAlign="center" variant="h3" sx={{ mb: 2 }}>
          {customerIsRivian
            ? t("selectRivianModel")
            : t(
                thermostat
                  ? "Select your thermostat"
                  : "Select your electric vehicle"
              )}
        </Typography>

        <Alert
          variant="outlined"
          color="tertiary"
          severity="warning"
          icon={
            <Stack justifyContent="center">
              <IconInfo size={22} />
            </Stack>
          }
        >
          <Typography color="tertiary" variant="h5">
            {customerIsRivian
              ? t("confirmRivianModel")
              : t(`io${thermostat ? "Thermostat" : "EV"}AgreementTitle`)}
          </Typography>
        </Alert>

        <form
          onSubmit={(e) => {
            e.preventDefault();
            onConfirm();
          }}
        >
          <TextField
            data-cy="io-enrollment-confirmation-dialog-textfield"
            label={
              customerIsRivian
                ? t("rivianModel")
                : t(
                    thermostat
                      ? "Smart thermostat brand"
                      : "Electric vehicle make"
                  )
            }
            onChange={(e) => {
              setSelectedProduct(e.target.value as IOProduct);
              setProductModel("");
              criticalCareChecked.setFalse();
              ioAgreementChecked.setFalse();
            }}
            select
            fullWidth
            required
            sx={{ mt: 3 }}
            value={selectedProduct}
          >
            {(customerIsRivian
              ? rivianEvOptions
              : ev
              ? evBrands
              : thermostatBrands
            ).map((t) => (
              <MenuItem key={t.value} value={t.value} data-cy={t.value}>
                {t.label}
              </MenuItem>
            ))}
            {!customerIsRivian && (
              <MenuItem value={IOProduct.OTHER}>{t("ioOtherDevice")}</MenuItem>
            )}
          </TextField>
          {(ev ? evModels : thermostatModels).hasOwnProperty(
            selectedProduct as IOProduct
          ) && (
            <>
              <TextField
                data-cy="io-enrollment-confirmation-dialog-textfield"
                label={ev ? "Electric vehicle model" : "Smart thermostat model"}
                onChange={(e) => {
                  setProductModel(e.target.value);
                }}
                select
                fullWidth
                required
                sx={{ mt: 3 }}
              >
                {selectedProduct &&
                  (ev
                    ? evModels[selectedProduct]
                    : thermostatModels[selectedProduct]
                  ).map((t) => (
                    <MenuItem key={t.value} value={t.value} data-cy={t.value}>
                      {t.label}
                    </MenuItem>
                  ))}
              </TextField>
              {ev && selectedProduct === IOProduct.TESLA && (
                <Box mt={1}>
                  <Typography>
                    {t("noteWeAreCurrently")}{" "}
                    <b>{t("unableToSupportMultipleTeslas")}</b>.
                  </Typography>
                </Box>
              )}
            </>
          )}

          {thermostat && selectedProduct === IOProduct.HONEYWELL && (
            <Typography variant="body2" mt={1}>
              {t("ioThermostatHoneywellNote")}
              <Typography variant="inherit" fontWeight="bold" component="span">
                {t("ioThermostatHoneywellNoteBold")}
              </Typography>
            </Typography>
          )}
          {!customerIsRivian && (
            <Typography mt={3}>
              {selectedProduct === IOProduct.OTHER && (
                <>
                  <b>
                    {" "}
                    {t(
                      `io${
                        thermostat ? "Thermostat" : "EV"
                      }AgreementDescriptionSubtitle`
                    )}
                  </b>{" "}
                  {t(
                    `io${
                      thermostat ? "Thermostat" : "EV"
                    }AgreementDescriptionText`
                  )}{" "}
                  <Link
                    href="https://octopus.typeform.com/to/Iw0w5xOG"
                    target="_blank"
                    rel="noopener"
                  >
                    {ev && t("ioContactUs")}
                  </Link>
                </>
              )}
            </Typography>
          )}

          {!customerIsRivian &&
            selectedProduct &&
            selectedProduct !== IOProduct.OTHER && (
              <FormGroup sx={{ mt: 3 }}>
                <FormControlLabel
                  sx={{
                    ".MuiFormControlLabel-asterisk": {
                      display: "none",
                    },
                  }}
                  control={
                    <Checkbox
                      required
                      checked={ioAgreementChecked.state}
                      onChange={ioAgreementChecked.toggle}
                      inputProps={
                        {
                          "data-cy":
                            "io-enrollment-confirmation-dialog-checkbox",
                        } as InputHTMLAttributes<HTMLInputElement>
                      }
                      data-cy="io-enrollment-confirmation-dialog-checkbox-text"
                    />
                  }
                  label={
                    <Typography>
                      {t(
                        `io${
                          thermostat ? "Thermostat" : "EV"
                        }AgreementCheckboxLabel`
                      )}
                    </Typography>
                  }
                />
                <FormControlLabel
                  sx={{
                    ".MuiFormControlLabel-asterisk": {
                      display: "none",
                    },
                    mt: 2,
                  }}
                  control={
                    <Checkbox
                      required
                      checked={criticalCareChecked.state}
                      onChange={criticalCareChecked.toggle}
                      inputProps={
                        {
                          "data-cy":
                            "io-enrollment-critical-care-confirmation-dialog-checkbox",
                        } as InputHTMLAttributes<HTMLInputElement>
                      }
                      data-cy="io-enrollment-critical-care-confirmation-dialog-checkbox-text"
                    />
                  }
                  label={<Typography>{t("agreeToCriticalCare")}</Typography>}
                />
              </FormGroup>
            )}

          <Button
            data-cy="io-enrollment-confirmation-button"
            fullWidth
            type="submit"
            disabled={
              !selectedProduct ||
              selectedProduct === IOProduct.OTHER ||
              (!customerIsRivian && !ioAgreementChecked.state) ||
              (!customerIsRivian && !criticalCareChecked.state) ||
              ((ev ? evModels : thermostatModels).hasOwnProperty(
                selectedProduct
              ) &&
                !productModel)
            }
            sx={{ mt: 3 }}
          >
            {t("ioAgreementSubmit")}
          </Button>
          <Box display="flex" justifyContent="center" mt={3}>
            <Link
              data-cy="io-enrollment-cancel-button"
              onClick={() => {
                onClose();
                setSelectedProduct(undefined);
                ioAgreementChecked.setFalse();
              }}
              sx={{ textDecoration: "underline" }}
            >
              {t("ioAgreementCancel")}
            </Link>
          </Box>
        </form>
      </DialogContent>
    </Dialog>
  );
};
