import { gql } from "@apollo/client";
import { createUseQuery } from "@octopus-energy/apollo-client";
import {
  GetRegisteredThermostatsQuery,
  GetRegisteredThermostatsQueryVariables,
} from "./__generated__/useQueryGetRegisteredThermostats.generated";

export const QUERY_GET_REGISTERED_THERMOSTATS = gql`
  query GetRegisteredThermostats($accountNumber: String!) {
    registeredThermostats(accountNumber: $accountNumber) {
      thermostats {
        enabled
        id
        name
        currentTemp
        deviceType
        deviceVendor
        tokenStatus
        hasActiveHold
      }
    }
  }
`;

export const useQueryGetRegisteredThermostats = createUseQuery<
  GetRegisteredThermostatsQuery,
  GetRegisteredThermostatsQueryVariables
>(QUERY_GET_REGISTERED_THERMOSTATS);
