import useTranslation from "next-translate/useTranslation";
import { Typography } from "@octopus-energy/coral-mui";
import { BillingBreakdownCard } from "@octopus-energy/coral-mui";

export type TotalDueTodayProps = {
  totalDue?: number | string;
  prepay?: boolean;
};

export const TotalDueToday = ({ totalDue }: TotalDueTodayProps) => {
  const { t } = useTranslation("enrollment/formFields");

  return (
    <BillingBreakdownCard
      borderWidth="medium"
      borderColorVariant="secondary"
      data={[
        {
          title: <Typography sx={{ mt: 1 }}>{t("planInfo_total")}</Typography>,
          price: (
            <Typography
              variant="h2"
              component="h3"
              fontWeight="bold"
              data-cy="total-due-today-amount"
            >
              ${totalDue}
            </Typography>
          ),
        },
      ]}
    />
  );
};
