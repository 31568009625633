import { Typography } from "@octopus-energy/coral-mui";
import useTranslation from "next-translate/useTranslation";
import { FC } from "react";

export const PrepayCheckoutInformation: FC = () => {
  const { t } = useTranslation("prepay");
  return (
    <>
      <Typography
        variant="body2"
        sx={{ mt: 5 }}
        data-cy="prepay-checkout-info-title"
      >
        <Typography
          variant="inherit"
          color="tertiary"
          component="span"
          fontWeight={500}
        >
          {t("prepayInfoTitle1")}
        </Typography>{" "}
        {t("prepayInfoTitle2")}
      </Typography>
      <Typography
        variant="body2"
        sx={{ mt: 3 }}
        data-cy="prepay-checkout-info-description"
      >
        {t("prepayInfoDescription")}
      </Typography>
    </>
  );
};
