import { Button, useCoralTheme } from "@krakentech/coral";
import { IconDelete } from "@krakentech/icons";
import { Theme, useMediaQuery } from "@mui/material";
import { Skeleton, Stack, Typography } from "@octopus-energy/coral-mui";
import useTranslation from "next-translate/useTranslation";
import { FC } from "react";
import { useBooleanState } from "react-use-object-state";
import {
  QUERY_DEFAULT_PAYMENT_INSTRUCTION,
  QUERY_IS_AUTO_PAY_ENABLED,
  useInvalidatePaymentInstructionMutation,
  useQueryDefaultPaymentInstruction,
  useQueryProperties,
} from "../apiRequests";
import { PaymentType } from "../apiRequests/graphql-global-types";
import { handleApolloMutationError, handleError } from "../error";
import { useSnackbarNotification } from "../molecules";
import { getPlan, usePortalAccountNumber } from "../portalUtils";

export type DeletePaymentMethodSettingsProps = {
  additionalPaymentType: PaymentType;
  onChangeConfirm: () => void;
  confirm: boolean;
  loading: boolean;
  paymentMethodId: string;
  refetch: VoidFunction;
  editing: ReturnType<typeof useBooleanState>;
};

export const DeletePaymentMethodSettings: FC<
  DeletePaymentMethodSettingsProps
> = ({
  additionalPaymentType,
  onChangeConfirm,
  confirm,
  loading,
  paymentMethodId,
  refetch,
  editing,
}) => {
  const isMobile = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down("sm")
  );
  const { t } = useTranslation("payments/payment-methods");
  const accountNumber = usePortalAccountNumber();
  const [notification] = useSnackbarNotification();
  const deleteProcessing = useBooleanState(false);
  const theme = useCoralTheme();

  const { data } = useQueryDefaultPaymentInstruction({
    variables: {
      accountNumber,
      instructionType: additionalPaymentType,
    },
    onError: handleError,
  });

  const queryProperties = useQueryProperties({
    variables: { accountNumber, includeSubscriptionFees: true },
    onError: handleError,
  });

  const { isPrepay } = getPlan(queryProperties.data);

  const atLeastOnePaymentForPrepay =
    data?.defaultPaymentInstruction?.status === "ACTIVE";

  const [deletePaymentMethod] = useInvalidatePaymentInstructionMutation({
    variables: {
      input: {
        accountNumber,
        id: paymentMethodId,
      },
    },
    onError: handleApolloMutationError,
    refetchQueries: [
      {
        query: QUERY_DEFAULT_PAYMENT_INSTRUCTION,
        variables: {
          accountNumber,
          instructionType: additionalPaymentType,
        },
      },
      {
        query: QUERY_DEFAULT_PAYMENT_INSTRUCTION,
        variables: {
          accountNumber,
        },
      },
      {
        query: QUERY_IS_AUTO_PAY_ENABLED,
        variables: {
          accountNumber,
        },
      },
    ],
  });

  const handleSubmit = async () => {
    deleteProcessing.setTrue();
    await deletePaymentMethod();
    await refetch();
    onChangeConfirm();
    deleteProcessing.setFalse();
    editing.setFalse();
    notification.success(t("paymentMethodDeleted"));
  };

  if (isPrepay && !atLeastOnePaymentForPrepay) {
    return null;
  }

  return loading ? (
    <Skeleton variant="text" height={35} width="20%" />
  ) : !confirm ? (
    <Stack alignItems={isMobile ? "flex-start" : "flex-end"}>
      <Button variant="text" size="small" onClick={onChangeConfirm}>
        {additionalPaymentType === PaymentType.Card && (
          <IconDelete size={18} color={theme.color.tertiary.main} />
        )}
        {additionalPaymentType === PaymentType.DirectDebit && (
          <IconDelete size={18} color={theme.color.tertiary.main} />
        )}
      </Button>
    </Stack>
  ) : (
    <Stack>
      <Typography variant="body2" fontWeight="bold">
        {t("confirmDelete")}
      </Typography>
      <Stack alignItems="flex-start" direction="row" spacing={2} mt={2}>
        <Button variant="outlined" fullWidth onClick={onChangeConfirm}>
          <Typography variant="body2">{t("cancel")}</Typography>
        </Button>
        <Button
          onClick={handleSubmit}
          color="primary"
          fullWidth
          disabled={deleteProcessing.state}
        >
          <Typography variant="body2">{t("delete")}</Typography>
        </Button>
      </Stack>
    </Stack>
  );
};
