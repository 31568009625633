import { useEnrollment, useOctopusProduct } from "@core/enrollment";
import { LegalLinks } from "@core/legal";
import { useEvaluateContractStatus } from "@core/renew";
import {
  Box,
  CardContent,
  Skeleton,
  Stack,
  Typography,
} from "@octopus-energy/coral-mui";
import useTranslation from "next-translate/useTranslation";
import { useBooleanState } from "react-use-object-state";
import { ProductCard } from "./ProductCard";
import { OctopusProductId } from "./octopusProductConstants";

const MTM_PRODUCT_ID = "OCTOPUS-MONTH-TO-MONTH" as unknown as OctopusProductId;

export const OctoMTMProduct = () => {
  const { t } = useTranslation("product/productCard");
  const mtmProduct = useOctopusProduct(MTM_PRODUCT_ID);
  const loading = useBooleanState(false);
  const monthlyEstimatedUsage = Number(useEnrollment().monthlyKwhUsage) || 1000;
  const { renewalAgreement, renewalContractIsMTM } =
    useEvaluateContractStatus();
  const displayRenewalDetails = renewalAgreement && renewalContractIsMTM;

  if (!mtmProduct) {
    return;
  }

  return (
    <>
      {loading.state ? (
        <Skeleton width="100%" height={400} variant="rectangular" />
      ) : (
        <ProductCard
          data-cy="mtm-product-card"
          sx={{ border: (theme) => theme.palette.primary.main }}
        >
          <CardContent>
            <Stack textAlign="center" minHeight={72}>
              <Typography
                variant="h3"
                component="h2"
                color="white"
                fontWeight="bold"
                data-cy="mtm-product-title"
              >
                Month-to-Month
              </Typography>
            </Stack>
            <Box>
              <Stack
                direction="row"
                alignItems="center"
                justifyContent="center"
              >
                <Typography
                  variant="h1"
                  component="h3"
                  align="center"
                  fontWeight="bold"
                  data-cy="mtm-product-rate-per-kwh"
                >
                  $
                  {displayRenewalDetails
                    ? (
                        monthlyEstimatedUsage *
                        (renewalAgreement.totalApplicableRate / 100)
                      ).toFixed(0)
                    : (Number(mtmProduct?.monthlyEstimate) / 100).toFixed(0)}
                </Typography>
              </Stack>
            </Box>
            <Typography
              variant="body2"
              component="h4"
              align="center"
              data-cy="mtm-first-month-text"
            >
              {t("forFirstMonth")}
            </Typography>
            <Typography
              variant="body2"
              component="h4"
              align="center"
              data-cy="mtm-monthly-estimate-text"
            >
              {t("monthlyEstimate")}
            </Typography>
            <Typography
              align="center"
              mt={0}
              variant="body2"
              data-cy="mtm-monthly-usage"
            >
              {t("unitCost")}:{" "}
              <Typography component="span" fontWeight="bold">
                {displayRenewalDetails
                  ? Number(renewalAgreement.totalApplicableRate).toFixed(1)
                  : Number(mtmProduct?.prices?.basePrice || 0).toFixed(1)}
                ¢/kWh
              </Typography>
            </Typography>
            <Stack textAlign="center" alignItems="center" spacing={2} mt={2}>
              <Typography variant="body2">{t("onMTM")}</Typography>
              <Typography variant="body2">{t("wantSecurity")}</Typography>
              <LegalLinks
                productId={MTM_PRODUCT_ID}
                serviceProviders={mtmProduct.serviceProviders}
                color="tertiary"
                disclosures={{ efl: true }}
              />
            </Stack>
          </CardContent>
        </ProductCard>
      )}
    </>
  );
};
