export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = {
  [K in keyof T]: T[K];
};
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]?: Maybe<T[SubKey]>;
};
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]: Maybe<T[SubKey]>;
};
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /**
   * The `BigInt` scalar type represents non-fractional whole numeric values.
   * `BigInt` is not constrained to 32-bit like the `Int` type and thus is a less
   * compatible type.
   */
  BigInt: any;
  CustomGrapheneDateTime: any;
  /**
   * The `Date` scalar type represents a Date
   * value as specified by
   * [iso8601](https://en.wikipedia.org/wiki/ISO_8601).
   */
  Date: any;
  /**
   * The `DateTime` scalar type represents a DateTime
   * value as specified by
   * [iso8601](https://en.wikipedia.org/wiki/ISO_8601).
   */
  DateTime: any;
  /** The `Decimal` scalar type represents a python Decimal. */
  Decimal: any;
  Email: any;
  /**
   * The `GenericScalar` scalar type represents a generic
   * GraphQL scalar value that could be:
   * String, Boolean, Int, Float, List or Object.
   */
  GenericScalar: any;
  /**
   * Allows use of a JSON String for input / output from the GraphQL schema.
   *
   * Use of this type is *not recommended* as you lose the benefits of having a defined, static
   * schema (one of the key benefits of GraphQL).
   */
  JSONString: any;
  /** Custom scalar type representing a non-empty string. */
  NonEmptyString: any;
  /**
   * Graphene String that validates against the available roles.
   *
   * Available roles:
   * - ADMIN
   */
  RoleString: any;
  /**
   * The `Time` scalar type represents a Time value as
   * specified by
   * [iso8601](https://en.wikipedia.org/wiki/ISO_8601).
   */
  Time: any;
};

export type ApiBrownout = {
  __typename?: "APIBrownout";
  /** The API type effected by this brownout. */
  apiType?: Maybe<ApiType>;
  /** For GraphQL APIs this will be a field identifier in the format <ParentType>.≤fieldName>, for REST APIs this will be an endpoint. */
  disablesRequestsTo?: Maybe<Scalars["NonEmptyString"]>;
  /** The iso formatted datetime at which this brownout will end. */
  endsAt?: Maybe<Scalars["NonEmptyString"]>;
  /** The iso formatted datetime at which this brownout will take effect. */
  startsAt?: Maybe<Scalars["NonEmptyString"]>;
  /** The current status of this brownout. */
  status?: Maybe<ApiBrownoutStatus>;
};

/** Paginator of API brownouts. */
export type ApiBrownoutConnection = {
  __typename?: "APIBrownoutConnection";
  /** Number of nodes in the edge. */
  edgeCount: Scalars["Int"];
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<ApiBrownoutEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  /** Total number of nodes. */
  totalCount: Scalars["Int"];
};

/** A Relay edge containing a `APIBrownout` and its cursor. */
export type ApiBrownoutEdge = {
  __typename?: "APIBrownoutEdge";
  /** A cursor for use in pagination */
  cursor: Scalars["String"];
  /** The item at the end of the edge */
  node?: Maybe<ApiBrownout>;
};

export type ApiBrownoutInput = {
  /** Return brownouts with these statuses. */
  statuses?: InputMaybe<Array<InputMaybe<ApiBrownoutStatus>>>;
};

/** Possible API Brownout statuses. */
export enum ApiBrownoutStatus {
  Aborted = "ABORTED",
  Cancelled = "CANCELLED",
  Completed = "COMPLETED",
  InProgress = "IN_PROGRESS",
  Scheduled = "SCHEDULED",
}

export type ApiCallType = {
  __typename?: "APICallType";
  /** Storage for the API client to submit any contextual information. */
  context?: Maybe<Scalars["JSONString"]>;
  /** The request's correlation id. */
  correlationId: Scalars["String"];
  createdAt: Scalars["DateTime"];
  id: Scalars["ID"];
  /** Input data for the API call if any. */
  inputData?: Maybe<Scalars["JSONString"]>;
  /** Free field for the API caller to categorise their own operation name. This field can be used to filter entries on the UI. */
  operationName: Scalars["String"];
  /** The response from the API call if any. */
  response?: Maybe<Scalars["JSONString"]>;
};

/** An enumeration. */
export enum ApiExceptionCategories {
  Account = "ACCOUNT",
  Unknown = "UNKNOWN",
}

/** Paginator of API exceptions. */
export type ApiExceptionConnectionTypeConnection = {
  __typename?: "APIExceptionConnectionTypeConnection";
  /** Number of nodes in the edge. */
  edgeCount: Scalars["Int"];
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<ApiExceptionConnectionTypeEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  /** Total number of nodes. */
  totalCount: Scalars["Int"];
};

/** A Relay edge containing a `APIExceptionConnectionType` and its cursor. */
export type ApiExceptionConnectionTypeEdge = {
  __typename?: "APIExceptionConnectionTypeEdge";
  /** A cursor for use in pagination */
  cursor: Scalars["String"];
  /** The item at the end of the edge */
  node?: Maybe<ApiExceptionType>;
};

export type ApiExceptionEventType = {
  __typename?: "APIExceptionEventType";
  /** The category of the event. */
  category: Scalars["String"];
  /** A JSON context to be provided with the event, if any. */
  context?: Maybe<Scalars["JSONString"]>;
  createdAt: Scalars["DateTime"];
  /** A description of the event. */
  description?: Maybe<Scalars["String"]>;
  /** The type of the event. */
  eventType: Scalars["String"];
  id: Scalars["ID"];
};

export type ApiExceptionNoteType = {
  __typename?: "APIExceptionNoteType";
  /** The content of the API Exception note. */
  body: Scalars["String"];
  /** Timestamp of when the API Exception note was created. */
  createdAt: Scalars["DateTime"];
  /** The ID of the API Exception note. */
  id: Scalars["ID"];
};

/** An enumeration. */
export enum ApiExceptionPriority {
  High = "HIGH",
  Low = "LOW",
  Medium = "MEDIUM",
}

export type ApiExceptionQueryInput = {
  /** The account number to filter for. */
  accountNumber?: InputMaybe<Scalars["ID"]>;
  /** The category to filter for. */
  category?: InputMaybe<ApiExceptionCategories>;
  /** The channel to filter for. */
  channel?: InputMaybe<Scalars["String"]>;
  /** The customer contact to filter for. */
  customerContact?: InputMaybe<Scalars["String"]>;
  /** The external identifier to filter for. */
  externalIdentifier?: InputMaybe<Scalars["String"]>;
  /** The priority to filter for. */
  priority?: InputMaybe<ApiExceptionPriority>;
  /** The resolution status to filter for. */
  resolutionStatus?: InputMaybe<ApiExceptionResolutionStatus>;
  /** The resolution type to filter for. */
  resolutionType?: InputMaybe<ApiExceptionResolutionType>;
  /** The supply point identifier to filter for. */
  supplyPointIdentifier?: InputMaybe<Scalars["ID"]>;
  /** Tags to filter for. */
  tags?: InputMaybe<Array<InputMaybe<ApiExceptionTags>>>;
  /** The user ID to filter for. */
  userId?: InputMaybe<Scalars["ID"]>;
};

/** An enumeration. */
export enum ApiExceptionResolutionStatus {
  Assigned = "ASSIGNED",
  Cancelled = "CANCELLED",
  InProgress = "IN_PROGRESS",
  Resolved = "RESOLVED",
  Unassigned = "UNASSIGNED",
  Unsuccessful = "UNSUCCESSFUL",
  WaitingOnThirdParty = "WAITING_ON_THIRD_PARTY",
}

/** An enumeration. */
export enum ApiExceptionResolutionType {
  Automatic = "AUTOMATIC",
  Manual = "MANUAL",
  Unassigned = "UNASSIGNED",
}

/** An enumeration. */
export enum ApiExceptionTags {
  MoveIn = "MOVE_IN",
  MoveOut = "MOVE_OUT",
  ProductUpdate = "PRODUCT_UPDATE",
}

export type ApiExceptionType = {
  __typename?: "APIExceptionType";
  /** The account number provided to the exception. */
  accountNumber?: Maybe<Scalars["String"]>;
  /** The API calls associated with this exception if any. */
  apiCalls?: Maybe<Array<Maybe<ApiCallType>>>;
  /** The user assigned to handle this exception if any. */
  assignedUser?: Maybe<AssignedUserType>;
  /** Category associated with this exception. */
  category?: Maybe<ApiExceptionCategories>;
  /** Free field for the API caller to categorise a channel. This could be (but not limited to) the client's team that calleded the API, the name of the 'flow' the call belongs to, etc. */
  channel: Scalars["String"];
  /** Storage for the API client to submit any contextual information. */
  context?: Maybe<Scalars["JSONString"]>;
  createdAt: Scalars["DateTime"];
  /** The customer contact provided to the exception. */
  customerContact?: Maybe<Scalars["String"]>;
  /** The events associated with this exception if any. */
  events?: Maybe<Array<Maybe<ApiExceptionEventType>>>;
  /** External identifier submitted by the API client to track this exception on their end. */
  externalIdentifier: Scalars["String"];
  id: Scalars["ID"];
  /** The key date associated with the exception, if available. */
  keyDate?: Maybe<Scalars["Date"]>;
  /** Notes associated with this exception if any. */
  notes?: Maybe<Array<Maybe<ApiExceptionNoteType>>>;
  /** The operations team assigned to this exception if any. */
  operationsTeam?: Maybe<OperationsTeamType>;
  /** The current priority for the API exception. */
  priority: ApiExceptionPriority;
  /** The current resolution status for the API exception. */
  resolutionStatus: ApiExceptionResolutionStatus;
  /** The current resolution type for the API exception. */
  resolutionType: ApiExceptionResolutionType;
  /** The supply point identifier provided to the exception. */
  supplyPointIdentifier?: Maybe<Scalars["String"]>;
  /** Tags associated with this exception if any. */
  tags?: Maybe<Array<Maybe<ApiExceptionTags>>>;
  /** The user id provided to the exception. */
  userId?: Maybe<Scalars["Int"]>;
};

/** Possible API types. */
export enum ApiType {
  Graphql = "GRAPHQL",
  Rest = "REST",
}

/**
 * Interface for all lifecycle journey processes that
 * inherit from AbstractSupplyPointProcess.
 */
export type AbstractSupplyPointProcessInterface = {
  /** The ID or the primary key of the lifecycle process. */
  id?: Maybe<Scalars["ID"]>;
  /** The status of the process. */
  status?: Maybe<LifecycleSupplyPointProcessStatus>;
  /** The supply points associated with the process. */
  supplyPoints: SupplyPointConnectionTypeConnection;
};

/**
 * Interface for all lifecycle journey processes that
 * inherit from AbstractSupplyPointProcess.
 */
export type AbstractSupplyPointProcessInterfaceSupplyPointsArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
};

/**
 * The possible errors that can be raised are:
 *
 * - KT-CT-8223: Unauthorized.
 * - KT-CT-8201: Received an invalid quoteId.
 * - KT-CT-8224: Invalid data.
 * - KT-CT-1113: Disabled GraphQL field requested.
 * - KT-CT-1111: Unauthorized.
 * - KT-CT-1112: 'Authorization' header not provided.
 */
export type AcceptGoodsQuote = {
  __typename?: "AcceptGoodsQuote";
  /** Goods purchase created. */
  goodsPurchase?: Maybe<GoodsPurchase>;
  possibleErrors?: Maybe<Array<Maybe<PossibleErrorType>>>;
};

export type AcceptGoodsQuoteInput = {
  /** The account number. */
  accountNumber: Scalars["String"];
  /** A JSON object containing client parameters to store on the quote. */
  clientParams?: InputMaybe<Scalars["JSONString"]>;
  /** A JSON object containing market parameters to store on the purchase. */
  marketParams?: InputMaybe<Scalars["JSONString"]>;
  /** ID of the accepted quote. */
  quoteId: Scalars["Int"];
};

/** Properties relating to the accessibility of features. */
export type AccessibilityInterface = {
  /** Whether the element is hidden from view. */
  accessibilityHidden?: Maybe<Scalars["Boolean"]>;
  /** Accessible description of the element. */
  accessibilityLabel?: Maybe<Scalars["String"]>;
};

export type AccountApplicationConnectionTypeConnection = {
  __typename?: "AccountApplicationConnectionTypeConnection";
  /** Number of nodes in the edge. */
  edgeCount: Scalars["Int"];
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<AccountApplicationConnectionTypeEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  /** Total number of nodes. */
  totalCount: Scalars["Int"];
};

/** A Relay edge containing a `AccountApplicationConnectionType` and its cursor. */
export type AccountApplicationConnectionTypeEdge = {
  __typename?: "AccountApplicationConnectionTypeEdge";
  /** A cursor for use in pagination */
  cursor: Scalars["String"];
  /** The item at the end of the edge */
  node?: Maybe<AccountApplicationType>;
};

/** An enumeration. */
export enum AccountApplicationStatus {
  /** Current */
  Current = "CURRENT",
  /** Failed */
  Failed = "FAILED",
  /** Historic */
  Historic = "HISTORIC",
  /** Withdrawn */
  Withdrawn = "WITHDRAWN",
}

export type AccountApplicationType = {
  __typename?: "AccountApplicationType";
  /** Last day of the cooling off period. Barring changes or objections, the account will be gained on the next business day after this date. This value will only be returned for current applications. */
  coolingOffEndDate?: Maybe<Scalars["Date"]>;
  /** Date at which this account decided to switch to us. */
  dateOfSale?: Maybe<Scalars["Date"]>;
  /** Whether this account application represents a migration into the current system or a regular gain. */
  isMigrated?: Maybe<Scalars["Boolean"]>;
  /** The source system for a migrated account. This could be the previous supplier or the previous account management system. */
  migrationSource?: Maybe<Scalars["String"]>;
  salesChannel: Scalars["String"];
  /** The sales subchannel used when signing up. This could for example be a price comparison site. */
  salesSubchannel?: Maybe<Scalars["String"]>;
  status: AccountApplicationStatus;
};

export type AccountBalanceTransferType = {
  __typename?: "AccountBalanceTransferType";
  /** Debit details. */
  accountCharge?: Maybe<AccountChargeType>;
  /** Credit details. */
  accountCredit?: Maybe<AccountCreditType>;
  /** Balance transfer ID. */
  id?: Maybe<Scalars["ID"]>;
  /** The reason for the balance transfer. */
  reason?: Maybe<Scalars["String"]>;
};

export type AccountBillingAddressInput = {
  /** The account number of the account to update. */
  accountNumber?: InputMaybe<Scalars["String"]>;
  /** Billing address details. */
  billingAddress?: InputMaybe<BillingAddressDetailsInput>;
};

/** An enumeration. */
export enum AccountBillingOptionsPeriodLength {
  /** Monthly */
  Monthly = "MONTHLY",
  /** Quarterly */
  Quarterly = "QUARTERLY",
}

export enum AccountCampaignChoices {
  CriticalCare = "CRITICAL_CARE",
  ElectricVehicle = "ELECTRIC_VEHICLE",
  HardOfHearing = "HARD_OF_HEARING",
  HomeBattery = "HOME_BATTERY",
  Octo12Ev = "OCTO12_EV",
  Octo12Therm = "OCTO12_THERM",
  SmartThermostat = "SMART_THERMOSTAT",
  SolarPanels = "SOLAR_PANELS",
  SpanishSpeaker = "SPANISH_SPEAKER",
  Vip = "VIP",
  VulnerableCustomer = "VULNERABLE_CUSTOMER",
}

export type AccountCampaignType = {
  __typename?: "AccountCampaignType";
  /** The date on which the associated campaign itself concludes. */
  campaignExpiryDate?: Maybe<Scalars["Date"]>;
  /** The date on which the account's participation in the campaign ends. */
  expiryDate?: Maybe<Scalars["Date"]>;
  /** The name of the campaign. */
  name?: Maybe<Scalars["String"]>;
  /** The slug of the campaign. */
  slug?: Maybe<Scalars["String"]>;
  /** The date that the account's link to the campaign started. */
  startDate?: Maybe<Scalars["Date"]>;
};

export type AccountChargeMetadataType = {
  __typename?: "AccountChargeMetadataType";
  /** A JSON object containing unstructured data about the account charge. */
  data?: Maybe<Scalars["JSONString"]>;
  /** The date and time the metadata was last updated. */
  updatedAt?: Maybe<Scalars["DateTime"]>;
};

export type AccountChargeType = {
  __typename?: "AccountChargeType";
  /** The climate change levy amount of the charge. */
  cclAmount?: Maybe<Scalars["BigInt"]>;
  /** The display note for the charge. */
  displayNote?: Maybe<Scalars["String"]>;
  /** The gross amount of the charge. */
  grossAmount?: Maybe<Scalars["BigInt"]>;
  /** The ID of the account charge. */
  id?: Maybe<Scalars["ID"]>;
  metadata?: Maybe<AccountChargeMetadataType>;
  /** The net amount of the charge. */
  netAmount?: Maybe<Scalars["BigInt"]>;
  /** The note for the charge. */
  note?: Maybe<Scalars["String"]>;
  /** The reason for the charge. */
  reason?: Maybe<Scalars["String"]>;
  /** The sales tax amount of the charge. */
  salesTaxAmount?: Maybe<Scalars["BigInt"]>;
};

export type AccountCheckoutInformationInput = {
  /** Unique identifier for the account. */
  accountNumber: Scalars["String"];
  /**
   * The unique identifier for a particular meter in the ERCOT market.
   *
   * If an ESI ID is not given, Kraken will try to get the ESI ID from the
   * latest active agreement on the account or raise an error if not found.
   */
  esiId: Scalars["String"];
  /** Unique identifier for the selected product. */
  productId: Scalars["String"];
};

export type AccountCheckoutInformationOutput = {
  __typename?: "AccountCheckoutInformationOutput";
  /** This account must make a deposit payment before checkout. */
  depositRequiredForPrepayProduct?: Maybe<Scalars["Boolean"]>;
  /** This product is designated as prepay. */
  isPrepayProduct?: Maybe<Scalars["Boolean"]>;
  /** The minimum balance this account should have in cents. */
  prepayProductBalanceThreshold?: Maybe<Scalars["Int"]>;
  /** Price per kwh for the selected product. */
  pricePerKwh?: Maybe<Scalars["Decimal"]>;
  /** Unique identifier for the product. */
  productId?: Maybe<Scalars["String"]>;
  /** Human readable name for the product. */
  productName?: Maybe<Scalars["String"]>;
};

export type AccountCreatedAtOutputType = {
  __typename?: "AccountCreatedAtOutputType";
  /** Date at which the account was created. */
  createdAt?: Maybe<Scalars["DateTime"]>;
};

export type AccountCreatedByAffiliateInput = {
  accountNumber?: InputMaybe<Scalars["String"]>;
  emailAddress?: InputMaybe<Scalars["String"]>;
};

export type AccountCreatedByAffiliateOutput = {
  __typename?: "AccountCreatedByAffiliateOutput";
  accountNumber?: Maybe<Scalars["String"]>;
  emailAddress?: Maybe<Scalars["String"]>;
  meterPointStatus?: Maybe<MeterPointStatus>;
};

export type AccountCreditMetadataType = {
  __typename?: "AccountCreditMetadataType";
  data: Scalars["JSONString"];
  updatedAt: Scalars["DateTime"];
};

/** Please note: these labels are exposed in the API documentation. */
export enum AccountCreditReasonType {
  /** External referral credit */
  ExternalReferralCredit = "EXTERNAL_REFERRAL_CREDIT",
}

export type AccountCreditType = {
  __typename?: "AccountCreditType";
  grossAmount: Scalars["BigInt"];
  id: Scalars["ID"];
  metadata?: Maybe<AccountCreditMetadataType>;
  netAmount: Scalars["BigInt"];
  note: Scalars["String"];
  reason: Scalars["String"];
  salesTaxAmount: Scalars["BigInt"];
};

export type AccountEvent = EmailEventType | PrintEventType;

export type AccountEventConnectionTypeConnection = {
  __typename?: "AccountEventConnectionTypeConnection";
  /** Number of nodes in the edge. */
  edgeCount: Scalars["Int"];
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<AccountEventConnectionTypeEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  /** Total number of nodes. */
  totalCount: Scalars["Int"];
};

/** A Relay edge containing a `AccountEventConnectionType` and its cursor. */
export type AccountEventConnectionTypeEdge = {
  __typename?: "AccountEventConnectionTypeEdge";
  /** A cursor for use in pagination */
  cursor: Scalars["String"];
  /** The item at the end of the edge */
  node?: Maybe<AccountEvent>;
};

/** An enumeration. */
export enum AccountEventType {
  /** The email messages that were received by the account. */
  EmailReceived = "EMAIL_RECEIVED",
  /** The email messages that were sent by the account. */
  EmailSent = "EMAIL_SENT",
  /** The marketing email messages that were sent by the account. */
  MarketingEmailSent = "MARKETING_EMAIL_SENT",
  /** The print messages that were cancelled. */
  PrintCancelled = "PRINT_CANCELLED",
  /** The print messages that failed to be delivered. */
  PrintFailed = "PRINT_FAILED",
  /** The print messages that were returned to sender. */
  PrintReturned = "PRINT_RETURNED",
  /** The print messages that were sent to the print partner by the account. */
  PrintSent = "PRINT_SENT",
  /** The print messages that were sent by the print partner. */
  PrintSucceeded = "PRINT_SUCCEEDED",
}

export type AccountFileAttachment = FileAttachment &
  Node & {
    __typename?: "AccountFileAttachment";
    category: Scalars["String"];
    fetchUrl?: Maybe<Scalars["String"]>;
    filename: Scalars["String"];
    /** The ID of the object */
    id: Scalars["ID"];
    /** Is the file ready for use / downloadable? */
    isReady?: Maybe<Scalars["Boolean"]>;
    /** Is the file uploaded to S3? */
    isUploaded?: Maybe<Scalars["Boolean"]>;
    sizeInBytes?: Maybe<Scalars["Int"]>;
  };

export type AccountFileAttachmentConnectionTypeConnection = {
  __typename?: "AccountFileAttachmentConnectionTypeConnection";
  /** Number of nodes in the edge. */
  edgeCount: Scalars["Int"];
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<AccountFileAttachmentConnectionTypeEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  /** Total number of nodes. */
  totalCount: Scalars["Int"];
};

/** A Relay edge containing a `AccountFileAttachmentConnectionType` and its cursor. */
export type AccountFileAttachmentConnectionTypeEdge = {
  __typename?: "AccountFileAttachmentConnectionTypeEdge";
  /** A cursor for use in pagination */
  cursor: Scalars["String"];
  /** The item at the end of the edge */
  node?: Maybe<AccountFileAttachment>;
};

export type AccountInterface = {
  /** The type of account. */
  accountType?: Maybe<AccountTypeChoices>;
  /** List of active hardship agreements for the user when is_in_hardship is True. */
  activeHardshipAgreements?: Maybe<Array<Maybe<HardshipAgreementType>>>;
  /** The referral schemes currently active for this account. */
  activeReferralSchemes?: Maybe<ReferralSchemeTypes>;
  /**
   * The billing address of this account, stored in the new
   * libaddressinput-based format.
   *
   * Note that `name` and `organization` are very unlikely to be
   * supplied here; the `billing_name` field on the account
   * itself is generally used for that purpose instead.
   */
  address?: Maybe<RichAddressType>;
  /** Fetch annual statements for the account. */
  annualStatements?: Maybe<AnnualStatementConnectionTypeConnection>;
  /** Applications by this account to become our customer. More recent applications will be listed first. */
  applications?: Maybe<AccountApplicationConnectionTypeConnection>;
  /** Assistance agreements for account. */
  assistanceAgreements?: Maybe<Array<Maybe<AssistanceAgreementType>>>;
  /** The current account balance. */
  balance: Scalars["Int"];
  /** Fetch a specific issued bill (invoice/statement) for the account. */
  bill?: Maybe<BillInterface>;
  /** The billing address of the account. */
  billingAddress?: Maybe<Scalars["String"]>;
  billingAddressLine1?: Maybe<Scalars["String"]>;
  billingAddressLine2?: Maybe<Scalars["String"]>;
  billingAddressLine3?: Maybe<Scalars["String"]>;
  billingAddressLine4?: Maybe<Scalars["String"]>;
  billingAddressLine5?: Maybe<Scalars["String"]>;
  billingAddressPostcode?: Maybe<Scalars["String"]>;
  billingCountryCode?: Maybe<Scalars["String"]>;
  billingDeliveryPointIdentifier?: Maybe<Scalars["String"]>;
  /** The billing email of the account. */
  billingEmail?: Maybe<Scalars["String"]>;
  /** The billing name of the account. */
  billingName?: Maybe<Scalars["String"]>;
  /** Information about the account's billing cycle. */
  billingOptions?: Maybe<BillingOptionsType>;
  /** The billing sub name of the account. */
  billingSubName?: Maybe<Scalars["String"]>;
  /** Fetch issued bills (invoices/statements) for the account. */
  bills?: Maybe<BillConnectionTypeConnection>;
  /** The brand of the account. */
  brand?: Maybe<Scalars["String"]>;
  /** Business info related to a business account. */
  business?: Maybe<BusinessType>;
  /**
   * The company type of a business account.
   * @deprecated The 'businessType' field is deprecated.
   *
   * Use `business.businessType` instead
   *
   * - Marked as deprecated on 2022-03-09.
   * - Will be removed on 2024-01-01.
   */
  businessType?: Maybe<BusinessTypeOptions>;
  /** The campaigns associated with an account. */
  campaigns?: Maybe<Array<Maybe<AccountCampaignType>>>;
  /** Whether the account can request a credit refund. */
  canRequestRefund?: Maybe<Scalars["Boolean"]>;
  /** The method the account has specified they prefer we contact them. */
  commsDeliveryPreference?: Maybe<CommsDeliveryPreference>;
  /**
   * @deprecated The 'communicationDeliveryPreference' field is deprecated.
   *
   * Use `commsDeliveryPreference` instead
   *
   * - Marked as deprecated on 2022-05-27.
   * - Will be removed on 2024-01-01.
   */
  communicationDeliveryPreference?: Maybe<Scalars["String"]>;
  /** Consents linked to this account. */
  consents: Array<ConsentType>;
  /** Contribution agreements for account. */
  contributionAgreements?: Maybe<Array<Maybe<ContributionAgreementType>>>;
  /** The datetime that the account was originally created. */
  createdAt?: Maybe<Scalars["DateTime"]>;
  /** Debt collection proceedings for account. */
  debtCollectionProceedings?: Maybe<Array<Maybe<DebtCollectionProceedingType>>>;
  /** The direct debit instructions of the account */
  directDebitInstructions?: Maybe<DirectDebitInstructionConnectionTypeConnection>;
  /** The document accessibility preference of the account. */
  documentAccessibility?: Maybe<DocumentAccessibilityChoices>;
  /** The account events that were recorded for the account. */
  events?: Maybe<AccountEventConnectionTypeConnection>;
  /** Files attached to this account. */
  fileAttachments?: Maybe<Array<Maybe<AccountFileAttachment>>>;
  /** True if there is an active Hardship Agreement for this account. False otherwise. */
  isInHardship?: Maybe<Scalars["Boolean"]>;
  /** Ledgers provide the foundation of bookkeeping functionality. Similar to a bank account, they allow us to keep track of financial activity on a particular customer account. */
  ledgers?: Maybe<Array<Maybe<LedgerType>>>;
  /** The maximum amount a customer is allowed to request as a refund and the reason why that's the maximum amount. */
  maximumRefund?: Maybe<MaximumRefundType>;
  /** Metadata associated with the account. */
  metadata?: Maybe<Array<Maybe<Metadata>>>;
  /** Notes for the account. */
  notes?: Maybe<Array<Maybe<AccountNoteType>>>;
  /** A code that uniquely identifies the account. */
  number?: Maybe<Scalars["String"]>;
  /** The current account overdue balance. */
  overdueBalance?: Maybe<Scalars["Int"]>;
  /** Files attached to this account. */
  paginatedFileAttachments?: Maybe<AccountFileAttachmentConnectionTypeConnection>;
  /** Paginated payment forecasts for an account. Starts from today's date (inclusive). The interface supports `last` but does not guarantee 'lastness'. */
  paginatedPaymentForecast?: Maybe<PaymentForecastConnectionTypeConnection>;
  /**
   * A list displaying the payment forecast for an account. The list starts from today's date (inclusive).
   * @deprecated The 'paymentForecast' field is deprecated.
   *
   * Please use 'paginatedPaymentForecast' instead.
   *
   * - Marked as deprecated on 2024-01-03.
   * - Will be removed on 2025-01-01.
   */
  paymentForecast?: Maybe<Array<Maybe<PaymentForecastType>>>;
  /** The schedules that describe how we would expect to take payments for an account on a given month. */
  paymentSchedules?: Maybe<PaymentScheduleConnectionTypeConnection>;
  /** The payments made into an account from a payment instruction. */
  payments?: Maybe<AccountPaymentConnectionTypeConnection>;
  /** The portfolio this account is linked to. */
  portfolio?: Maybe<PortfolioType>;
  /** The language that the account preferred for communications. */
  preferredLanguageForComms?: Maybe<Scalars["String"]>;
  provisionalTransactions?: Maybe<ProvisionalTransactionConnectionTypeConnection>;
  /** Account references linked to this account. */
  references?: Maybe<Array<Maybe<AccountReferenceType>>>;
  /** The referrals created by this account. */
  referrals?: Maybe<ReferralConnectionTypeConnection>;
  /** Number of referrals created by this account. */
  referralsCreated?: Maybe<Scalars["Int"]>;
  /** The repayments that have been requested for this account. */
  repayments?: Maybe<AccountRepaymentConnectionTypeConnection>;
  /** Details about the eligibility status for requesting a refund. */
  requestRefundEligibility?: Maybe<RequestRefundEligibilityType>;
  /** The rewards applied to this account. */
  rewards?: Maybe<Array<Maybe<RewardType>>>;
  /** List of billing address lines. */
  splitBillingAddress?: Maybe<Array<Maybe<Scalars["String"]>>>;
  /** The current status of the account. */
  status?: Maybe<AccountStatus>;
  /** Fetch transactions that have taken place on the account. */
  transactions?: Maybe<TransactionConnectionTypeConnection>;
  /** Unique reference number from a 3rd party enrolment. */
  urn?: Maybe<Scalars["String"]>;
};

export type AccountInterfaceAnnualStatementsArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
};

export type AccountInterfaceApplicationsArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
};

export type AccountInterfaceBalanceArgs = {
  includeAllLedgers?: InputMaybe<Scalars["Boolean"]>;
};

export type AccountInterfaceBillArgs = {
  billType?: InputMaybe<BillTypeEnum>;
  id: Scalars["ID"];
};

export type AccountInterfaceBillsArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  fromDate?: InputMaybe<Scalars["Date"]>;
  includeBillsWithoutPDF?: InputMaybe<Scalars["Boolean"]>;
  includeHeldStatements?: InputMaybe<Scalars["Boolean"]>;
  includeHistoricStatements?: InputMaybe<Scalars["Boolean"]>;
  includeOpenStatements?: InputMaybe<Scalars["Boolean"]>;
  issuedFromDate?: InputMaybe<Scalars["Date"]>;
  issuedToDate?: InputMaybe<Scalars["Date"]>;
  last?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  onlyCurrentEmail?: InputMaybe<Scalars["Boolean"]>;
  orderBy?: InputMaybe<BillsOrderBy>;
  toDate?: InputMaybe<Scalars["Date"]>;
};

export type AccountInterfaceDirectDebitInstructionsArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  statuses?: InputMaybe<Array<InputMaybe<DirectDebitInstructionStatus>>>;
};

export type AccountInterfaceEventsArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  eventTypes?: InputMaybe<Array<InputMaybe<AccountEventType>>>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
};

export type AccountInterfaceLedgersArgs = {
  ledgerId?: InputMaybe<Scalars["ID"]>;
  ledgerNumber?: InputMaybe<Scalars["String"]>;
};

export type AccountInterfacePaginatedFileAttachmentsArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  category?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  id?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
};

export type AccountInterfacePaginatedPaymentForecastArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  dateTo?: InputMaybe<Scalars["Date"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  ledgerId?: InputMaybe<Scalars["Int"]>;
};

export type AccountInterfacePaymentForecastArgs = {
  dateTo: Scalars["Date"];
  ledgerId?: InputMaybe<Scalars["Int"]>;
};

export type AccountInterfacePaymentSchedulesArgs = {
  active?: InputMaybe<Scalars["Boolean"]>;
  activeOnDate?: InputMaybe<Scalars["Date"]>;
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  includeDormant?: InputMaybe<Scalars["Boolean"]>;
  last?: InputMaybe<Scalars["Int"]>;
  ledgerId?: InputMaybe<Scalars["ID"]>;
  ledgerType?: InputMaybe<Scalars["Int"]>;
  reason?: InputMaybe<PaymentScheduleReasonOptions>;
};

export type AccountInterfacePaymentsArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  includePromises?: InputMaybe<Scalars["Boolean"]>;
  last?: InputMaybe<Scalars["Int"]>;
  ledgerId?: InputMaybe<Scalars["String"]>;
  reason?: InputMaybe<PaymentReasonOptions>;
  status?: InputMaybe<AccountPaymentStatusOptions>;
};

export type AccountInterfaceProvisionalTransactionsArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
};

export type AccountInterfaceReferralsArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
};

export type AccountInterfaceRepaymentsArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  statuses?: InputMaybe<Array<InputMaybe<AccountRepaymentStatusOptions>>>;
};

export type AccountInterfaceTransactionsArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  fromDate?: InputMaybe<Scalars["Date"]>;
  includeAllLedgers?: InputMaybe<Scalars["Boolean"]>;
  last?: InputMaybe<Scalars["Int"]>;
  ledgerId?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<TransactionsOrderBy>;
  toDate?: InputMaybe<Scalars["Date"]>;
  transactionTypes?: InputMaybe<Array<InputMaybe<TransactionTypeFilter>>>;
  transactionTypesExcluded?: InputMaybe<
    Array<InputMaybe<TransactionTypeFilter>>
  >;
};

export type AccountIoEligibility = {
  __typename?: "AccountIoEligibility";
  /** Whether account is eligible to register devices with Intelligent Octopus or not. */
  isEligibleForIo?: Maybe<Scalars["Boolean"]>;
};

export type AccountLedgerInput = {
  /** The account number. */
  accountNumber: Scalars["ID"];
  /** The ledger id for the account. */
  ledgerId: Scalars["ID"];
};

export type AccountNoteType = {
  __typename?: "AccountNoteType";
  body: Scalars["String"];
  createdAt: Scalars["DateTime"];
  isPinned: Scalars["Boolean"];
};

export type AccountNumberInput = {
  /** Account number. */
  accountNumber: Scalars["String"];
};

export type AccountPaymentConnectionTypeConnection = {
  __typename?: "AccountPaymentConnectionTypeConnection";
  /** Number of nodes in the edge. */
  edgeCount: Scalars["Int"];
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<AccountPaymentConnectionTypeEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  /** Total number of nodes. */
  totalCount: Scalars["Int"];
};

/** A Relay edge containing a `AccountPaymentConnectionType` and its cursor. */
export type AccountPaymentConnectionTypeEdge = {
  __typename?: "AccountPaymentConnectionTypeEdge";
  /** A cursor for use in pagination */
  cursor: Scalars["String"];
  /** The item at the end of the edge */
  node?: Maybe<AccountPaymentType>;
};

/** An enumeration. */
export enum AccountPaymentStatusOptions {
  /** The payment has been approved by the merchant and added to your Kraken account. */
  Cleared = "CLEARED",
  /** This payment was deleted. From this point it cannot be altered. */
  Deleted = "DELETED",
  /** The payment failed permanently. */
  Failed = "FAILED",
  /** The payment has been made successfully and applied to the Kraken balance. */
  Fulfilled = "FULFILLED",
  /** Payments made in a previous system and then imported into Kraken. */
  Historic = "HISTORIC",
  /** The payment has been submitted. From this point it cannot be altered. */
  Pending = "PENDING",
  /** A payment promise has been created, but it has not left the customers bank account. */
  Promised = "PROMISED",
  /** The payment promise has been broken. */
  PromiseBroken = "PROMISE_BROKEN",
  /** The initial state of a payment in Kraken. It should be scheduled with a payment vendor in the future. */
  Requested = "REQUESTED",
  /** The payment has been scheduled for collection and the customer has been notified. While a payment is scheduled, it can still be deleted. */
  Scheduled = "SCHEDULED",
  /** Third Party payments are those recorded for financial purposes in a different system but should be added to statements. */
  ThirdParty = "THIRD_PARTY",
}

/** An enumeration. */
export enum AccountPaymentTransactionTypeChoices {
  Agency = "AGENCY",
  AllpayCard = "ALLPAY_CARD",
  AllpayCash = "ALLPAY_CASH",
  AllpayCheque = "ALLPAY_CHEQUE",
  AustraliaPost = "AUSTRALIA_POST",
  BacsDeposit = "BACS_DEPOSIT",
  Bpay = "BPAY",
  Bpoint = "BPOINT",
  BristolPound = "BRISTOL_POUND",
  Btre = "BTRE",
  Cash = "CASH",
  Centrepay = "CENTREPAY",
  Cheque = "CHEQUE",
  CreditCard = "CREDIT_CARD",
  DcaCollection = "DCA_COLLECTION",
  DdFinalCollection = "DD_FINAL_COLLECTION",
  DdFirstCollection = "DD_FIRST_COLLECTION",
  DdRegularCollection = "DD_REGULAR_COLLECTION",
  DdRePresentation = "DD_RE_PRESENTATION",
  DebitCard = "DEBIT_CARD",
  Dwp = "DWP",
  EapaVoucher = "EAPA_VOUCHER",
  Eft = "EFT",
  ErroneousPayment = "ERRONEOUS_PAYMENT",
  FailedRepaymentReversal = "FAILED_REPAYMENT_REVERSAL",
  FuelDirect = "FUEL_DIRECT",
  Heeas = "HEEAS",
  Ideal = "IDEAL",
  Ivr = "IVR",
  Konbini = "KONBINI",
  PagopaNotice = "PAGOPA_NOTICE",
  PaymentFee = "PAYMENT_FEE",
  PaypointCard = "PAYPOINT_CARD",
  PaypointCash = "PAYPOINT_CASH",
  PaypointCheque = "PAYPOINT_CHEQUE",
  Payzone = "PAYZONE",
  PostOfficeCard = "POST_OFFICE_CARD",
  PostOfficeCash = "POST_OFFICE_CASH",
  PostOfficeCheque = "POST_OFFICE_CHEQUE",
  PostOfficeSavingsStamps = "POST_OFFICE_SAVINGS_STAMPS",
  PrepaidCard = "PREPAID_CARD",
  PrepayCard = "PREPAY_CARD",
  PrepayKey = "PREPAY_KEY",
  PrepaySmart = "PREPAY_SMART",
  PrepayToken = "PREPAY_TOKEN",
  Telpay = "TELPAY",
  TransferFromSap = "TRANSFER_FROM_SAP",
  Unknown = "UNKNOWN",
  Urgs = "URGS",
}

export type AccountPaymentType = {
  __typename?: "AccountPaymentType";
  /** Amount of payment in pence */
  amount: Scalars["BigInt"];
  id: Scalars["ID"];
  /** The date this payment is scheduled to be debited */
  paymentDate: Scalars["Date"];
  reference: Scalars["String"];
  /** The current status of the payment. */
  status?: Maybe<AccountPaymentStatusOptions>;
  /** Surcharge amount generated by this payment. */
  surchargeAmount?: Maybe<Scalars["Int"]>;
  /** The transaction type of the payment. */
  transactionType?: Maybe<AccountPaymentTransactionTypeChoices>;
};

/** The input type for the account reference. */
export type AccountReferenceInput = {
  /** The account number. */
  accountNumber: Scalars["String"];
  /** The namespace for the reference. */
  namespace: Scalars["String"];
  /** The reference value. */
  value: Scalars["String"];
};

export type AccountReferenceType = {
  __typename?: "AccountReferenceType";
  account: AccountType;
  createdAt: Scalars["DateTime"];
  namespace: Scalars["String"];
  updatedAt: Scalars["DateTime"];
  value: Scalars["String"];
};

export type AccountReminder = {
  __typename?: "AccountReminder";
  /** Reminder content. */
  content?: Maybe<Scalars["String"]>;
  /** When the reminder is due. */
  dueAt?: Maybe<Scalars["DateTime"]>;
  /** The reminder type. */
  reminderType?: Maybe<AccountReminderTypes>;
};

/** Contains reminder type choices for all territories. */
export enum AccountReminderTypes {
  AccountCoolOff = "ACCOUNT_COOL_OFF",
  AccountMigrationSyncXoserveMeterMismatch = "ACCOUNT_MIGRATION_SYNC_XOSERVE_METER_MISMATCH",
  ActivatePaymentCancellationStopped = "ACTIVATE_PAYMENT_CANCELLATION_STOPPED",
  AdHoc = "AD_HOC",
  AgreementRevoked = "AGREEMENT_REVOKED",
  AmperageChange = "AMPERAGE_CHANGE",
  AssignedToCreditTeam = "ASSIGNED_TO_CREDIT_TEAM",
  AusCentrepayExcessiveCredit = "AUS_CENTREPAY_EXCESSIVE_CREDIT",
  AusEmbeddedAcquisitionMatrixError = "AUS_EMBEDDED_ACQUISITION_MATRIX_ERROR",
  AusEmbeddedChildNmis = "AUS_EMBEDDED_CHILD_NMIS",
  AusEmbeddedWaterEstimationRequiredForSkippedReading = "AUS_EMBEDDED_WATER_ESTIMATION_REQUIRED_FOR_SKIPPED_READING",
  AusEmbeddedWaterReadingFailure = "AUS_EMBEDDED_WATER_READING_FAILURE",
  AusGetCorrectPhoneNumber = "AUS_GET_CORRECT_PHONE_NUMBER",
  AusIndustryCustomerOwnReadingNotSent = "AUS_INDUSTRY_CUSTOMER_OWN_READING_NOT_SENT",
  AusVicSharedfuseNotification = "AUS_VIC_SHAREDFUSE_NOTIFICATION",
  AutomatedBillingDisabled = "AUTOMATED_BILLING_DISABLED",
  Billing = "BILLING",
  BillingAmendedIntervalDataReceivedCausingOvercharge = "BILLING_AMENDED_INTERVAL_DATA_RECEIVED_CAUSING_OVERCHARGE",
  BillingAmendedMeterReadBasic = "BILLING_AMENDED_METER_READ_BASIC",
  BillingAmendedMeterReadCesElecBasic = "BILLING_AMENDED_METER_READ_CES_ELEC_BASIC",
  BillingAmendedMeterReadCesElecInterval = "BILLING_AMENDED_METER_READ_CES_ELEC_INTERVAL",
  BillingAmendedMeterReadGas = "BILLING_AMENDED_METER_READ_GAS",
  BillingAmendedMeterReadInterval = "BILLING_AMENDED_METER_READ_INTERVAL",
  BillingAmendedMeterReadIntervalLegacy = "BILLING_AMENDED_METER_READ_INTERVAL_LEGACY",
  BillingAmendedMeterReadPreMigration = "BILLING_AMENDED_METER_READ_PRE_MIGRATION",
  BillingBackdatedConcessionReceived = "BILLING_BACKDATED_CONCESSION_RECEIVED",
  BillingBackdatedUmsRecordReceived = "BILLING_BACKDATED_UMS_RECORD_RECEIVED",
  BillingCustomerSelfReadReceived = "BILLING_CUSTOMER_SELF_READ_RECEIVED",
  BillingMissingRead = "BILLING_MISSING_READ",
  BillingQuotedNtcDoesNotAlign = "BILLING_QUOTED_NTC_DOES_NOT_ALIGN",
  BillingRegisterReplacedOrRemoved = "BILLING_REGISTER_REPLACED_OR_REMOVED",
  CancelMoveOutUnableToReinstateFutureAgreements = "CANCEL_MOVE_OUT_UNABLE_TO_REINSTATE_FUTURE_AGREEMENTS",
  ChurnPrevention = "CHURN_PREVENTION",
  CommercialEnergisationInterventionRequired = "COMMERCIAL_ENERGISATION_INTERVENTION_REQUIRED",
  CommsToBePrinted = "COMMS_TO_BE_PRINTED",
  ContractCommsNotDelivered = "CONTRACT_COMMS_NOT_DELIVERED",
  CosGain = "COS_GAIN",
  CosGainMigrationEcoesMeterMismatch = "COS_GAIN_MIGRATION_ECOES_METER_MISMATCH",
  CosGainRelRetrievalFailure = "COS_GAIN_REL_RETRIEVAL_FAILURE",
  CosLoss = "COS_LOSS",
  CustomerDetailsChange = "CUSTOMER_DETAILS_CHANGE",
  CustomerReportedSmartMeterIssues = "CUSTOMER_REPORTED_SMART_METER_ISSUES",
  D0010CreationFailure = "D0010_CREATION_FAILURE",
  D0052CreationFailure = "D0052_CREATION_FAILURE",
  D0205CreationFailure = "D0205_CREATION_FAILURE",
  DeuInboundAperak = "DEU_INBOUND_APERAK",
  DeuMasterDataSynchronisation = "DEU_MASTER_DATA_SYNCHRONISATION",
  DeuMasterDataUpdate = "DEU_MASTER_DATA_UPDATE",
  DeuMeterReadings = "DEU_METER_READINGS",
  DeuSwichtingProcessFailed = "DEU_SWICHTING_PROCESS_FAILED",
  DisconnectionMandatoryNoticeEmailFailure = "DISCONNECTION_MANDATORY_NOTICE_EMAIL_FAILURE",
  DisconnectionMandatoryNoticeSmsFailure = "DISCONNECTION_MANDATORY_NOTICE_SMS_FAILURE",
  DunningBestEndeavoursCall = "DUNNING_BEST_ENDEAVOURS_CALL",
  DunningDisconnectionDeEnergisationAssessment = "DUNNING_DISCONNECTION_DE_ENERGISATION_ASSESSMENT",
  DunningDisconnectionMandatoryNoticeEmailFailure = "DUNNING_DISCONNECTION_MANDATORY_NOTICE_EMAIL_FAILURE",
  DunningDisconnectionOutboundReminderCall = "DUNNING_DISCONNECTION_OUTBOUND_REMINDER_CALL",
  DunningMandatoryNoticeEmailFailure = "DUNNING_MANDATORY_NOTICE_EMAIL_FAILURE",
  DunningOutboundReminderCall = "DUNNING_OUTBOUND_REMINDER_CALL",
  DunningReminder = "DUNNING_REMINDER",
  DunningReminderCall = "DUNNING_REMINDER_CALL",
  DunningReminderDisconnectionApplication = "DUNNING_REMINDER_DISCONNECTION_APPLICATION",
  DunningReminderFuturePayment = "DUNNING_REMINDER_FUTURE_PAYMENT",
  DunningReminderPaymentMade = "DUNNING_REMINDER_PAYMENT_MADE",
  DunningVacantConsumptionDeEnergisationAssessment = "DUNNING_VACANT_CONSUMPTION_DE_ENERGISATION_ASSESSMENT",
  DuplicateCardFingerprinTs = "DUPLICATE_CARD_FINGERPRINTs",
  EmbeddedNetworkAllChildLifeSupportRecordsAreDeregistered = "EMBEDDED_NETWORK_ALL_CHILD_LIFE_SUPPORT_RECORDS_ARE_DEREGISTERED",
  EmbeddedNetworkExceptionDistributorOwnedParentLifeSupportRecord = "EMBEDDED_NETWORK_EXCEPTION_DISTRIBUTOR_OWNED_PARENT_LIFE_SUPPORT_RECORD",
  EmbeddedNetworkExceptionParentMeterPointLostOrLosing = "EMBEDDED_NETWORK_EXCEPTION_PARENT_METER_POINT_LOST_OR_LOSING",
  EmbeddedNetworkExceptionParentMeterPointReceivedDangerousLifeSupportNotification = "EMBEDDED_NETWORK_EXCEPTION_PARENT_METER_POINT_RECEIVED_DANGEROUS_LIFE_SUPPORT_NOTIFICATION",
  EmbeddedNetworkExceptionParentMeterPointReceivedLifeSupportNotification = "EMBEDDED_NETWORK_EXCEPTION_PARENT_METER_POINT_RECEIVED_LIFE_SUPPORT_NOTIFICATION",
  FamilyIssuesAdded = "FAMILY_ISSUES_ADDED",
  FieldworksAccountCreatedWithNoEmailAddress = "FIELDWORKS_ACCOUNT_CREATED_WITH_NO_EMAIL_ADDRESS",
  FieldworksAllocateNmiMarketParticipantsNotSet = "FIELDWORKS_ALLOCATE_NMI_MARKET_PARTICIPANTS_NOT_SET",
  FieldworksBulkDeploymentJourneyAutoCancelled = "FIELDWORKS_BULK_DEPLOYMENT_JOURNEY_AUTO_CANCELLED",
  FieldworksChangeRequestStepErrored = "FIELDWORKS_CHANGE_REQUEST_STEP_ERRORED",
  FieldworksChangeRequestStepFailed = "FIELDWORKS_CHANGE_REQUEST_STEP_FAILED",
  FieldworksContactSoRecipientToUpdateTheSoDetails = "FIELDWORKS_CONTACT_SO_RECIPIENT_TO_UPDATE_THE_SO_DETAILS",
  FieldworksExpectedMeterPointNotCreated = "FIELDWORKS_EXPECTED_METER_POINT_NOT_CREATED",
  FieldworksExpectedMeterReadNotReceived = "FIELDWORKS_EXPECTED_METER_READ_NOT_RECEIVED",
  FieldworksExpectedNtcsNotReceived = "FIELDWORKS_EXPECTED_NTCS_NOT_RECEIVED",
  FieldworksInvestigateWhetherJourneyNeedsContinuation = "FIELDWORKS_INVESTIGATE_WHETHER_JOURNEY_NEEDS_CONTINUATION",
  FieldworksJourneyAttachments = "FIELDWORKS_JOURNEY_ATTACHMENTS",
  FieldworksJourneyCancelledDueToLifeSupportRegistration = "FIELDWORKS_JOURNEY_CANCELLED_DUE_TO_LIFE_SUPPORT_REGISTRATION",
  FieldworksMaintainRegisterBillableOverrideForUnsolicitedMeterChanges = "FIELDWORKS_MAINTAIN_REGISTER_BILLABLE_OVERRIDE_FOR_UNSOLICITED_METER_CHANGES",
  FieldworksManuallyCompleteCustomerMoveIn = "FIELDWORKS_MANUALLY_COMPLETE_CUSTOMER_MOVE_IN",
  FieldworksMeterpointIsNotActive = "FIELDWORKS_METERPOINT_IS_NOT_ACTIVE",
  FieldworksMeterFaultJourneyAutoCancelled = "FIELDWORKS_METER_FAULT_JOURNEY_AUTO_CANCELLED",
  FieldworksMeterFaultNcomCommsFailed = "FIELDWORKS_METER_FAULT_NCOM_COMMS_FAILED",
  FieldworksMeterFaultReceived = "FIELDWORKS_METER_FAULT_RECEIVED",
  FieldworksMirnDiscoveryFailed = "FIELDWORKS_MIRN_DISCOVERY_FAILED",
  FieldworksMoveOutCancelledWhileSupplyAbolishmentInProgress = "FIELDWORKS_MOVE_OUT_CANCELLED_WHILE_SUPPLY_ABOLISHMENT_IN_PROGRESS",
  FieldworksMultipleOpenJourneysForMeterPoint = "FIELDWORKS_MULTIPLE_OPEN_JOURNEYS_FOR_METER_POINT",
  FieldworksNewConnectionCompletionReview = "FIELDWORKS_NEW_CONNECTION_COMPLETION_REVIEW",
  FieldworksNewConnectionDataNotValid = "FIELDWORKS_NEW_CONNECTION_DATA_NOT_VALID",
  FieldworksObtainCustomerApprovalBeforeProgressing = "FIELDWORKS_OBTAIN_CUSTOMER_APPROVAL_BEFORE_PROGRESSING",
  FieldworksObtainSupplyAbolishmentApproval = "FIELDWORKS_OBTAIN_SUPPLY_ABOLISHMENT_APPROVAL",
  FieldworksPayerContactFailedValidation = "FIELDWORKS_PAYER_CONTACT_FAILED_VALIDATION",
  FieldworksServiceOrderAttachments = "FIELDWORKS_SERVICE_ORDER_ATTACHMENTS",
  FieldworksServiceOrderCompleted = "FIELDWORKS_SERVICE_ORDER_COMPLETED",
  FieldworksServiceOrderFailed = "FIELDWORKS_SERVICE_ORDER_FAILED",
  FieldworksServiceOrderPartiallyCompleted = "FIELDWORKS_SERVICE_ORDER_PARTIALLY_COMPLETED",
  FieldworksServiceOrderStatusUpdated = "FIELDWORKS_SERVICE_ORDER_STATUS_UPDATED",
  FieldworksServiceOrderStepErrored = "FIELDWORKS_SERVICE_ORDER_STEP_ERRORED",
  FieldworksServiceOrderStepFailed = "FIELDWORKS_SERVICE_ORDER_STEP_FAILED",
  FieldworksServiceOrderUnableToAccessWithCustomerConsultation = "FIELDWORKS_SERVICE_ORDER_UNABLE_TO_ACCESS_WITH_CUSTOMER_CONSULTATION",
  FieldworksSupplyPeriodDoesNotExistForMeterpoint = "FIELDWORKS_SUPPLY_PERIOD_DOES_NOT_EXIST_FOR_METERPOINT",
  FieldworksUploadAttachmentToJemenaPortal = "FIELDWORKS_UPLOAD_ATTACHMENT_TO_JEMENA_PORTAL",
  FinalBillingUnableToBill = "FINAL_BILLING_UNABLE_TO_BILL",
  FlowFileError = "FLOW_FILE_ERROR",
  FollowUpPaymentPromise = "FOLLOW_UP_PAYMENT_PROMISE",
  FraActivationProcessAwaitingSwitchInConfirmation = "FRA_ACTIVATION_PROCESS_AWAITING_SWITCH_IN_CONFIRMATION",
  FraActivationProcessAwaitingSwitchReadings = "FRA_ACTIVATION_PROCESS_AWAITING_SWITCH_READINGS",
  FraActivationProcessCommanderSouscription = "FRA_ACTIVATION_PROCESS_COMMANDER_SOUSCRIPTION",
  FraActivationProcessGasProviderChangeRequest = "FRA_ACTIVATION_PROCESS_GAS_PROVIDER_CHANGE_REQUEST",
  FraActivationProcessInceptionChecks = "FRA_ACTIVATION_PROCESS_INCEPTION_CHECKS",
  FraActivationProcessPrmEligibility = "FRA_ACTIVATION_PROCESS_PRM_ELIGIBILITY",
  FraActivationProcessScheduleSiteworks = "FRA_ACTIVATION_PROCESS_SCHEDULE_SITEWORKS",
  FraElecActivationProcessWrongSwitchReadingsForProviderCalendarTemporalClasses = "FRA_ELEC_ACTIVATION_PROCESS_WRONG_SWITCH_READINGS_FOR_PROVIDER_CALENDAR_TEMPORAL_CLASSES",
  FraEnergyChequeUnknown = "FRA_ENERGY_CHEQUE_UNKNOWN",
  FraFsl = "FRA_FSL",
  FraTerminationProcessNeedsIntervention = "FRA_TERMINATION_PROCESS_NEEDS_INTERVENTION",
  FraTerminationRequestErrored = "FRA_TERMINATION_REQUEST_ERRORED",
  GasExceptionCustomerDetailsNotificationFailed = "GAS_EXCEPTION_CUSTOMER_DETAILS_NOTIFICATION_FAILED",
  GasExceptionCustomerTransferCancelled = "GAS_EXCEPTION_CUSTOMER_TRANSFER_CANCELLED",
  GasExceptionCustomerTransferRejected = "GAS_EXCEPTION_CUSTOMER_TRANSFER_REJECTED",
  GasExceptionLifeSupportNotificationFailed = "GAS_EXCEPTION_LIFE_SUPPORT_NOTIFICATION_FAILED",
  GasExceptionMeterDataVerifyRequestFailed = "GAS_EXCEPTION_METER_DATA_VERIFY_REQUEST_FAILED",
  GasExceptionMeterDataVerifyResponseNoChangeWithExplanation = "GAS_EXCEPTION_METER_DATA_VERIFY_RESPONSE_NO_CHANGE_WITH_EXPLANATION",
  GasExceptionMeterDataVerifyResponseOverdue = "GAS_EXCEPTION_METER_DATA_VERIFY_RESPONSE_OVERDUE",
  GasExceptionMeterDataVerifyResponseWithoutRevisedRead = "GAS_EXCEPTION_METER_DATA_VERIFY_RESPONSE_WITHOUT_REVISED_READ",
  GasExceptionSiteAccessDetailsNotificationFailed = "GAS_EXCEPTION_SITE_ACCESS_DETAILS_NOTIFICATION_FAILED",
  GasExceptionSiteAccessDetailsReceivedForNonExistentMeter = "GAS_EXCEPTION_SITE_ACCESS_DETAILS_RECEIVED_FOR_NON_EXISTENT_METER",
  GasExceptionSiteAddressDetailsNotificationFailed = "GAS_EXCEPTION_SITE_ADDRESS_DETAILS_NOTIFICATION_FAILED",
  GasManualServiceOrderRequiredForEnrolment = "GAS_MANUAL_SERVICE_ORDER_REQUIRED_FOR_ENROLMENT",
  GasNotificationChangeOfSiteAddress = "GAS_NOTIFICATION_CHANGE_OF_SITE_ADDRESS",
  GasReadingRemovedInstalledReceived = "GAS_READING_REMOVED_INSTALLED_RECEIVED",
  GasUnknownCustomerClassificationCodeReceived = "GAS_UNKNOWN_CUSTOMER_CLASSIFICATION_CODE_RECEIVED",
  GbrAddPhotoToMeterReading = "GBR_ADD_PHOTO_TO_METER_READING",
  GbrGetPhotoOfMeterAndCallSupplier = "GBR_GET_PHOTO_OF_METER_AND_CALL_SUPPLIER",
  GbrRaiseDispute = "GBR_RAISE_DISPUTE",
  GbrVerifyMhhsMessage = "GBR_VERIFY_MHHS_MESSAGE",
  HardshipGraduationAssessment = "HARDSHIP_GRADUATION_ASSESSMENT",
  HardshipNoPaymentPlan = "HARDSHIP_NO_PAYMENT_PLAN",
  HardshipRemovalAssessment = "HARDSHIP_REMOVAL_ASSESSMENT",
  HeldBillingDocument = "HELD_BILLING_DOCUMENT",
  HeldStatement = "HELD_STATEMENT",
  IndustryChangeOfSupplierDoubleGain = "INDUSTRY_CHANGE_OF_SUPPLIER_DOUBLE_GAIN",
  IndustryCustomerTransferDelayed = "INDUSTRY_CUSTOMER_TRANSFER_DELAYED",
  IndustryExceptionChangeOfSupplierCancelled = "INDUSTRY_EXCEPTION_CHANGE_OF_SUPPLIER_CANCELLED",
  IndustryExceptionChangeOfSupplierConflictingPeriod = "INDUSTRY_EXCEPTION_CHANGE_OF_SUPPLIER_CONFLICTING_PERIOD",
  IndustryExceptionChangeOfSupplierGainCompletionOverdue = "INDUSTRY_EXCEPTION_CHANGE_OF_SUPPLIER_GAIN_COMPLETION_OVERDUE",
  IndustryExceptionChangeOfSupplierObjected = "INDUSTRY_EXCEPTION_CHANGE_OF_SUPPLIER_OBJECTED",
  IndustryExceptionChangeOfSupplierRejected = "INDUSTRY_EXCEPTION_CHANGE_OF_SUPPLIER_REJECTED",
  IndustryExceptionChangeRequestCancellationFailed = "INDUSTRY_EXCEPTION_CHANGE_REQUEST_CANCELLATION_FAILED",
  IndustryExceptionChangeRequestCancelled = "INDUSTRY_EXCEPTION_CHANGE_REQUEST_CANCELLED",
  IndustryExceptionChangeRequestCompleted = "INDUSTRY_EXCEPTION_CHANGE_REQUEST_COMPLETED",
  IndustryExceptionChangeRequestObjected = "INDUSTRY_EXCEPTION_CHANGE_REQUEST_OBJECTED",
  IndustryExceptionChangeRequestObjectionMissingAck = "INDUSTRY_EXCEPTION_CHANGE_REQUEST_OBJECTION_MISSING_ACK",
  IndustryExceptionChangeRequestObjectionRejected = "INDUSTRY_EXCEPTION_CHANGE_REQUEST_OBJECTION_REJECTED",
  IndustryExceptionChangeRequestObjectionWithdrawalRejected = "INDUSTRY_EXCEPTION_CHANGE_REQUEST_OBJECTION_WITHDRAWAL_REJECTED",
  IndustryExceptionChangeRequestRejected = "INDUSTRY_EXCEPTION_CHANGE_REQUEST_REJECTED",
  IndustryExceptionChangeRequestWithdrawalRejected = "INDUSTRY_EXCEPTION_CHANGE_REQUEST_WITHDRAWAL_REJECTED",
  IndustryExceptionCustomerDetailsNotificationInvalidBillingAddress = "INDUSTRY_EXCEPTION_CUSTOMER_DETAILS_NOTIFICATION_INVALID_BILLING_ADDRESS",
  IndustryExceptionCustomerDetailsNotificationMissingBusinessAcceptance = "INDUSTRY_EXCEPTION_CUSTOMER_DETAILS_NOTIFICATION_MISSING_BUSINESS_ACCEPTANCE",
  IndustryExceptionCustomerDetailsNotificationMissingMandatoryFields = "INDUSTRY_EXCEPTION_CUSTOMER_DETAILS_NOTIFICATION_MISSING_MANDATORY_FIELDS",
  IndustryExceptionCustomerDetailsNotificationRejected = "INDUSTRY_EXCEPTION_CUSTOMER_DETAILS_NOTIFICATION_REJECTED",
  IndustryExceptionCustomerDetailsRequestSpecialReason = "INDUSTRY_EXCEPTION_CUSTOMER_DETAILS_REQUEST_SPECIAL_REASON",
  IndustryExceptionFailedToCancelDeenergisationServiceOrder = "INDUSTRY_EXCEPTION_FAILED_TO_CANCEL_DEENERGISATION_SERVICE_ORDER",
  IndustryExceptionHouseMoveEnrolmentCannotCalculateMoveInReading = "INDUSTRY_EXCEPTION_HOUSE_MOVE_ENROLMENT_CANNOT_CALCULATE_MOVE_IN_READING",
  IndustryExceptionHouseMoveEnrolmentServiceOrderAlreadyInProgress = "INDUSTRY_EXCEPTION_HOUSE_MOVE_ENROLMENT_SERVICE_ORDER_ALREADY_IN_PROGRESS",
  IndustryExceptionHouseMoveEnrolmentUnableToCopyLastMeterReading = "INDUSTRY_EXCEPTION_HOUSE_MOVE_ENROLMENT_UNABLE_TO_COPY_LAST_METER_READING",
  IndustryExceptionLifeSupportContactUserRemovedFromAccount = "INDUSTRY_EXCEPTION_LIFE_SUPPORT_CONTACT_USER_REMOVED_FROM_ACCOUNT",
  IndustryExceptionLifeSupportMultipleAccountsMatches = "INDUSTRY_EXCEPTION_LIFE_SUPPORT_MULTIPLE_ACCOUNTS_MATCHES",
  IndustryExceptionLifeSupportMultipleLifeSupportContactMatches = "INDUSTRY_EXCEPTION_LIFE_SUPPORT_MULTIPLE_LIFE_SUPPORT_CONTACT_MATCHES",
  IndustryExceptionLifeSupportNotificationContainsUnexpectedData = "INDUSTRY_EXCEPTION_LIFE_SUPPORT_NOTIFICATION_CONTAINS_UNEXPECTED_DATA",
  IndustryExceptionLifeSupportNotificationFailedToSend = "INDUSTRY_EXCEPTION_LIFE_SUPPORT_NOTIFICATION_FAILED_TO_SEND",
  IndustryExceptionLifeSupportNotificationInvalidContactMethod = "INDUSTRY_EXCEPTION_LIFE_SUPPORT_NOTIFICATION_INVALID_CONTACT_METHOD",
  IndustryExceptionLifeSupportNotificationInvalidPhone = "INDUSTRY_EXCEPTION_LIFE_SUPPORT_NOTIFICATION_INVALID_PHONE",
  IndustryExceptionLifeSupportNotificationMissingBusinessAcceptance = "INDUSTRY_EXCEPTION_LIFE_SUPPORT_NOTIFICATION_MISSING_BUSINESS_ACCEPTANCE",
  IndustryExceptionLifeSupportNotificationReceivedFromNonRegistrationOwner = "INDUSTRY_EXCEPTION_LIFE_SUPPORT_NOTIFICATION_RECEIVED_FROM_NON_REGISTRATION_OWNER",
  IndustryExceptionLifeSupportNotificationRejected = "INDUSTRY_EXCEPTION_LIFE_SUPPORT_NOTIFICATION_REJECTED",
  IndustryExceptionLifeSupportNotificationUnknownContact = "INDUSTRY_EXCEPTION_LIFE_SUPPORT_NOTIFICATION_UNKNOWN_CONTACT",
  IndustryExceptionLifeSupportRequestMissingBusinessAcceptance = "INDUSTRY_EXCEPTION_LIFE_SUPPORT_REQUEST_MISSING_BUSINESS_ACCEPTANCE",
  IndustryExceptionLifeSupportRequestMissingLifeSupportNotification = "INDUSTRY_EXCEPTION_LIFE_SUPPORT_REQUEST_MISSING_LIFE_SUPPORT_NOTIFICATION",
  IndustryExceptionLifeSupportRequestRejected = "INDUSTRY_EXCEPTION_LIFE_SUPPORT_REQUEST_REJECTED",
  IndustryExceptionMeterPointEnrolmentIncompleteCouldNotSendServiceOrder = "INDUSTRY_EXCEPTION_METER_POINT_ENROLMENT_INCOMPLETE_COULD_NOT_SEND_SERVICE_ORDER",
  IndustryExceptionNextScheduledReadDateTooFarInFuture = "INDUSTRY_EXCEPTION_NEXT_SCHEDULED_READ_DATE_TOO_FAR_IN_FUTURE",
  IndustryExceptionRelinkingOccurredDuringSdrSync = "INDUSTRY_EXCEPTION_RELINKING_OCCURRED_DURING_SDR_SYNC",
  IndustryExceptionSiteAccessDetailsNotificationMissingBusinessAcceptance = "INDUSTRY_EXCEPTION_SITE_ACCESS_DETAILS_NOTIFICATION_MISSING_BUSINESS_ACCEPTANCE",
  IndustryExceptionSiteAccessDetailsNotificationRejected = "INDUSTRY_EXCEPTION_SITE_ACCESS_DETAILS_NOTIFICATION_REJECTED",
  IndustryExceptionStandingDataPropertiesAddressFailedToUpdate = "INDUSTRY_EXCEPTION_STANDING_DATA_PROPERTIES_ADDRESS_FAILED_TO_UPDATE",
  IndustryExceptionUnableToEnrolMeterPointIncompleteCouldNotSendChangeRequest = "INDUSTRY_EXCEPTION_UNABLE_TO_ENROL_METER_POINT_INCOMPLETE_COULD_NOT_SEND_CHANGE_REQUEST",
  IndustryExceptionUnableToEnrolMeterPointInvalidNmiMeterStatus = "INDUSTRY_EXCEPTION_UNABLE_TO_ENROL_METER_POINT_INVALID_NMI_METER_STATUS",
  IndustryExceptionUnableToEnrolMeterPointNextScheduledReadDatePast = "INDUSTRY_EXCEPTION_UNABLE_TO_ENROL_METER_POINT_NEXT_SCHEDULED_READ_DATE_PAST",
  IndustryExceptionUnableToProcessRolr = "INDUSTRY_EXCEPTION_UNABLE_TO_PROCESS_ROLR",
  IndustryLifeSupportCancelDeregistrationFailed = "INDUSTRY_LIFE_SUPPORT_CANCEL_DEREGISTRATION_FAILED",
  IndustryLifeSupportManualBestEndeavourRequired = "INDUSTRY_LIFE_SUPPORT_MANUAL_BEST_ENDEAVOUR_REQUIRED",
  IndustryLifeSupportRegistrationFollowUpRequired = "INDUSTRY_LIFE_SUPPORT_REGISTRATION_FOLLOW_UP_REQUIRED",
  IndustryLifeSupportReviewAfterCancelledMoveOutForNextAccount = "INDUSTRY_LIFE_SUPPORT_REVIEW_AFTER_CANCELLED_MOVE_OUT_FOR_NEXT_ACCOUNT",
  IndustryLifeSupportReviewAttemptedCancelledMoveOutForNextAccount = "INDUSTRY_LIFE_SUPPORT_REVIEW_ATTEMPTED_CANCELLED_MOVE_OUT_FOR_NEXT_ACCOUNT",
  IndustryLifeSupportReviewDeregistration = "INDUSTRY_LIFE_SUPPORT_REVIEW_DEREGISTRATION",
  IndustryLifeSupportReviewPostDeregistration = "INDUSTRY_LIFE_SUPPORT_REVIEW_POST_DEREGISTRATION",
  IndustryLifeSupportReviewPostDeregistrationComms = "INDUSTRY_LIFE_SUPPORT_REVIEW_POST_DEREGISTRATION_COMMS",
  IndustryManualActionRequired = "INDUSTRY_MANUAL_ACTION_REQUIRED",
  IndustryManualCustomerDetailsNotificationRequired = "INDUSTRY_MANUAL_CUSTOMER_DETAILS_NOTIFICATION_REQUIRED",
  IndustryManualLifeSupportNotificationRequired = "INDUSTRY_MANUAL_LIFE_SUPPORT_NOTIFICATION_REQUIRED",
  IndustryMeterPointMissingCustomerClassification = "INDUSTRY_METER_POINT_MISSING_CUSTOMER_CLASSIFICATION",
  IndustryReadingsNotSent = "INDUSTRY_READINGS_NOT_SENT",
  IndustrySendLifeSupportDeRegistrationForm = "INDUSTRY_SEND_LIFE_SUPPORT_DE_REGISTRATION_FORM",
  IndustryUnableToCreateRecord = "INDUSTRY_UNABLE_TO_CREATE_RECORD",
  IndustryVicDroManualLifeSupportExtensionRequest = "INDUSTRY_VIC_DRO_MANUAL_LIFE_SUPPORT_EXTENSION_REQUEST",
  IndustryWarningMessageReceived = "INDUSTRY_WARNING_MESSAGE_RECEIVED",
  ItaAccountWithdrawnWithoutNotification = "ITA_ACCOUNT_WITHDRAWN_WITHOUT_NOTIFICATION",
  ItaProcessWelcomePackStep = "ITA_PROCESS_WELCOME_PACK_STEP",
  JpnBillingFixInvalidChargeData = "JPN_BILLING_FIX_INVALID_CHARGE_DATA",
  JpnBillingPatternChangedDueToReadingDateChange = "JPN_BILLING_PATTERN_CHANGED_DUE_TO_READING_DATE_CHANGE",
  JpnCallForDunningCampaign = "JPN_CALL_FOR_DUNNING_CAMPAIGN",
  JpnConfirmationOfRelocation = "JPN_CONFIRMATION_OF_RELOCATION",
  JpnConfirmPaymentForDunningCampaign = "JPN_CONFIRM_PAYMENT_FOR_DUNNING_CAMPAIGN",
  JpnFailedToSendSmsForBillingInfo = "JPN_FAILED_TO_SEND_SMS_FOR_BILLING_INFO",
  JpnFailedToSendSmsForConvenienceStorePayment = "JPN_FAILED_TO_SEND_SMS_FOR_CONVENIENCE_STORE_PAYMENT",
  JpnFinalReadingOutsideAgreement = "JPN_FINAL_READING_OUTSIDE_AGREEMENT",
  JpnMultipleValidReferrals = "JPN_MULTIPLE_VALID_REFERRALS",
  JpnReadingsNotReceived = "JPN_READINGS_NOT_RECEIVED",
  JpnShortTermMoveInRejection = "JPN_SHORT_TERM_MOVE_IN_REJECTION",
  JpnSupplyDetailsUpdateConfirmation = "JPN_SUPPLY_DETAILS_UPDATE_CONFIRMATION",
  JpnSupplyPointFailsCanSupplyCheck = "JPN_SUPPLY_POINT_FAILS_CAN_SUPPLY_CHECK",
  JpnSwitchInScheduleOverlap = "JPN_SWITCH_IN_SCHEDULE_OVERLAP",
  Kmt = "KMT",
  MailFailed = "MAIL_FAILED",
  MailReturned = "MAIL_RETURNED",
  MandatoryCommsNotDelivered = "MANDATORY_COMMS_NOT_DELIVERED",
  MarketSupplyExceptionAgreementFailedToCreate = "MARKET_SUPPLY_EXCEPTION_AGREEMENT_FAILED_TO_CREATE",
  MarketSupplyExceptionAgreementFailedToReverseTermination = "MARKET_SUPPLY_EXCEPTION_AGREEMENT_FAILED_TO_REVERSE_TERMINATION",
  MarketSupplyExceptionAgreementFailedToTerminate = "MARKET_SUPPLY_EXCEPTION_AGREEMENT_FAILED_TO_TERMINATE",
  MarketSupplyExceptionAgreementFailedToUpdate = "MARKET_SUPPLY_EXCEPTION_AGREEMENT_FAILED_TO_UPDATE",
  MarketSupplyExceptionMissingAccountQuotedProduct = "MARKET_SUPPLY_EXCEPTION_MISSING_ACCOUNT_QUOTED_PRODUCT",
  MeterExchangeOneWayNotification = "METER_EXCHANGE_ONE_WAY_NOTIFICATION",
  MeterFaultAndIssueOneWayNotificationAccepted = "METER_FAULT_AND_ISSUE_ONE_WAY_NOTIFICATION_ACCEPTED",
  MeterFaultAndIssueOneWayNotificationRejected = "METER_FAULT_AND_ISSUE_ONE_WAY_NOTIFICATION_REJECTED",
  MigrationType = "MIGRATION_TYPE",
  MoveIn = "MOVE_IN",
  MoveInCesLifeSupportRequired = "MOVE_IN_CES_LIFE_SUPPORT_REQUIRED",
  MoveInCommsNotDelivered = "MOVE_IN_COMMS_NOT_DELIVERED",
  MoveInDefaultPaymentScheduleFailed = "MOVE_IN_DEFAULT_PAYMENT_SCHEDULE_FAILED",
  MoveInMoveOutManualProcess = "MOVE_IN_MOVE_OUT_MANUAL_PROCESS",
  MoveOut = "MOVE_OUT",
  NetworkTariffOneWayNotification = "NETWORK_TARIFF_ONE_WAY_NOTIFICATION",
  NewPsrAddedToPrepayAccount = "NEW_PSR_ADDED_TO_PREPAY_ACCOUNT",
  NonEnergyPaymentCallReminder = "NON_ENERGY_PAYMENT_CALL_REMINDER",
  NoticeOfMeteringWorksOneWayNotification = "NOTICE_OF_METERING_WORKS_ONE_WAY_NOTIFICATION",
  NzBillingQuery = "NZ_BILLING_QUERY",
  NzDunning = "NZ_DUNNING",
  NzMeteringQuery = "NZ_METERING_QUERY",
  NzSolarMeterChange = "NZ_SOLAR_METER_CHANGE",
  NzSwitchingWithdrawal = "NZ_SWITCHING_WITHDRAWAL",
  NzVacantProperty = "NZ_VACANT_PROPERTY",
  ObsoleteBillingAmendedStandingDataRegisterChanged = "OBSOLETE_BILLING_AMENDED_STANDING_DATA_REGISTER_CHANGED",
  ObsoleteBillingAmendedStandingDataRegisterDeleted = "OBSOLETE_BILLING_AMENDED_STANDING_DATA_REGISTER_DELETED",
  PaymentsFailedRepayment = "PAYMENTS_FAILED_REPAYMENT",
  PaymentInstructionFailed = "PAYMENT_INSTRUCTION_FAILED",
  PaymentPlan = "PAYMENT_PLAN",
  PaymentPlanHardshipCompletion = "PAYMENT_PLAN_HARDSHIP_COMPLETION",
  PaymentPlanHardshipCompletionWorkflowCancelled = "PAYMENT_PLAN_HARDSHIP_COMPLETION_WORKFLOW_CANCELLED",
  PaymentPlanMissedInstalment = "PAYMENT_PLAN_MISSED_INSTALMENT",
  PaymentScheduleFailedToCreate = "PAYMENT_SCHEDULE_FAILED_TO_CREATE",
  PlannedInterruption = "PLANNED_INTERRUPTION",
  PlannedInterruptionMedicalDependency = "PLANNED_INTERRUPTION_MEDICAL_DEPENDENCY",
  PlannedInterruptionNotificationSentToLifeSupportCustomer = "PLANNED_INTERRUPTION_NOTIFICATION_SENT_TO_LIFE_SUPPORT_CUSTOMER",
  PlannedInterruptionOneWayNotification = "PLANNED_INTERRUPTION_ONE_WAY_NOTIFICATION",
  PlannedInterruptionOneWayNotificationRejected = "PLANNED_INTERRUPTION_ONE_WAY_NOTIFICATION_REJECTED",
  PostHardshipCancellation = "POST_HARDSHIP_CANCELLATION",
  PropertyAddressNeedsUpdate = "PROPERTY_ADDRESS_NEEDS_UPDATE",
  PsrImportCouldNotIdentifyAccountUser = "PSR_IMPORT_COULD_NOT_IDENTIFY_ACCOUNT_USER",
  SaConcessionStatementClosed = "SA_CONCESSION_STATEMENT_CLOSED",
  ScheduledBillingAddressUpdate = "SCHEDULED_BILLING_ADDRESS_UPDATE",
  ScheduledOccupierPackSend = "SCHEDULED_OCCUPIER_PACK_SEND",
  ServiceOrderAcknowledgementOverdue = "SERVICE_ORDER_ACKNOWLEDGEMENT_OVERDUE",
  ServiceOrderCancellationFailed = "SERVICE_ORDER_CANCELLATION_FAILED",
  ServiceOrderCancellationRequestRejected = "SERVICE_ORDER_CANCELLATION_REQUEST_REJECTED",
  ServiceOrderFailed = "SERVICE_ORDER_FAILED",
  ServiceOrderInitialResponseOverdue = "SERVICE_ORDER_INITIAL_RESPONSE_OVERDUE",
  ServiceOrderNotCompleted = "SERVICE_ORDER_NOT_COMPLETED",
  ServiceOrderOtherJobEnquiryCode = "SERVICE_ORDER_OTHER_JOB_ENQUIRY_CODE",
  ServiceOrderPartiallyCompleted = "SERVICE_ORDER_PARTIALLY_COMPLETED",
  ServiceOrderRequestRejected = "SERVICE_ORDER_REQUEST_REJECTED",
  ServiceOrderUnableToCharge = "SERVICE_ORDER_UNABLE_TO_CHARGE",
  ServiceOrderUnsolicitedReceived = "SERVICE_ORDER_UNSOLICITED_RECEIVED",
  ServiceOrderUnsolicitedReceivedDeenergisedMeterPoint = "SERVICE_ORDER_UNSOLICITED_RECEIVED_DEENERGISED_METER_POINT",
  SmartflexDeviceIntegrationPostponed = "SMARTFLEX_DEVICE_INTEGRATION_POSTPONED",
  SmartChangeOfTenancyInProgress = "SMART_CHANGE_OF_TENANCY_IN_PROGRESS",
  SmartChangeOfTenancyRequestFailed = "SMART_CHANGE_OF_TENANCY_REQUEST_FAILED",
  SmartMeterModeChangeUnsupported = "SMART_METER_MODE_CHANGE_UNSUPPORTED",
  SmartPrepayAddDebtFailure = "SMART_PREPAY_ADD_DEBT_FAILURE",
  SmartPrepayNeedToAdjustDebt = "SMART_PREPAY_NEED_TO_ADJUST_DEBT",
  SmartPrepayTopUpFailure = "SMART_PREPAY_TOP_UP_FAILURE",
  SmartPrepayTopUpMultipleActiveProcesses = "SMART_PREPAY_TOP_UP_MULTIPLE_ACTIVE_PROCESSES",
  SmartPrepayTopUpUtrn = "SMART_PREPAY_TOP_UP_UTRN",
  Smets2DataRequestFollowup = "SMETS2_DATA_REQUEST_FOLLOWUP",
  Smets2HealthCheck = "SMETS2_HEALTH_CHECK",
  Smets2IhdHealthCheck = "SMETS2_IHD_HEALTH_CHECK",
  SolarExportCreditsExcess = "SOLAR_EXPORT_CREDITS_EXCESS",
  SolrFinalBillReminder = "SOLR_FINAL_BILL_REMINDER",
  SpecialReadCancellationFailed = "SPECIAL_READ_CANCELLATION_FAILED",
  SpecialReadFailed = "SPECIAL_READ_FAILED",
  SpecialReadOutstanding = "SPECIAL_READ_OUTSTANDING",
  SpecialReadWithEstimateReadReceived = "SPECIAL_READ_WITH_ESTIMATE_READ_RECEIVED",
  UnsupportedFeature = "UNSUPPORTED_FEATURE",
  UnsupportedPaymentDay = "UNSUPPORTED_PAYMENT_DAY",
  WaterAccountReversion = "WATER_ACCOUNT_REVERSION",
  WaterMeterReadingIssue = "WATER_METER_READING_ISSUE",
  WithdrawalReceived = "WITHDRAWAL_RECEIVED",
  WorkflowCancellationFailed = "WORKFLOW_CANCELLATION_FAILED",
  WorkflowStepErrored = "WORKFLOW_STEP_ERRORED",
  WorkflowStepFailed = "WORKFLOW_STEP_FAILED",
}

export type AccountRepaymentConnectionTypeConnection = {
  __typename?: "AccountRepaymentConnectionTypeConnection";
  /** Number of nodes in the edge. */
  edgeCount: Scalars["Int"];
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<AccountRepaymentConnectionTypeEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  /** Total number of nodes. */
  totalCount: Scalars["Int"];
};

/** A Relay edge containing a `AccountRepaymentConnectionType` and its cursor. */
export type AccountRepaymentConnectionTypeEdge = {
  __typename?: "AccountRepaymentConnectionTypeEdge";
  /** A cursor for use in pagination */
  cursor: Scalars["String"];
  /** The item at the end of the edge */
  node?: Maybe<AccountRepaymentType>;
};

/** An enumeration. */
export enum AccountRepaymentStatusOptions {
  /** The repayment has been approved but not made yet. */
  Approved = "APPROVED",
  /** The repayment failed permanently. This could be because of technical issues, or if the merchant rejects the payment for some reason. The payment will need to be retried by ops. */
  Failed = "FAILED",
  /** Payments made in a previous system and then imported into Kraken. */
  Historic = "HISTORIC",
  /** The repayment has been made to the merchant to be sent to the customer. This is a terminal state, we don't get any further confirmation. */
  Paid = "PAID",
  /** The request for a repayment has been received but not actioned yet. */
  Requested = "REQUESTED",
  /** The payment has been submitted to the merchant. It is still possible for this repayment to fail. */
  Submitted = "SUBMITTED",
  /** Third Party payments are those recorded for financial purposes in a different system but should be added to statements. */
  ThirdParty = "THIRD_PARTY",
}

export type AccountRepaymentType = {
  __typename?: "AccountRepaymentType";
  /** Amount of payment in pence */
  amount: Scalars["BigInt"];
  id: Scalars["ID"];
  /** The date this payment is scheduled to be debited */
  paymentDate: Scalars["Date"];
  /** The current status of the repayment. */
  status?: Maybe<AccountRepaymentStatusOptions>;
};

export type AccountSearchInputType = {
  /** Internal account id (not account number). */
  account?: InputMaybe<Scalars["String"]>;
  /** The account number eg. A-FF15AE70. */
  accountNumber?: InputMaybe<Scalars["String"]>;
  /** Account Reference. */
  accountReferences?: InputMaybe<Scalars["String"]>;
  /** Account or Billing name. */
  billingName?: InputMaybe<Scalars["String"]>;
  /** Business name. */
  businessName?: InputMaybe<Scalars["String"]>;
  /** Business identifier or number. */
  businessNumber?: InputMaybe<Scalars["String"]>;
  /** Location (Supply or Billing, full or partial, address or post code). */
  location?: InputMaybe<Scalars["String"]>;
  /** Meter Serial Number. */
  meterSerialNumber?: InputMaybe<Scalars["String"]>;
  /** The portfolio number eg. P-A123B456. */
  portfolioNumber?: InputMaybe<Scalars["String"]>;
  /** Statements. */
  statements?: InputMaybe<Scalars["String"]>;
  /** URN Number. */
  urn?: InputMaybe<Scalars["String"]>;
  /** The Account User ID (not account number). */
  user?: InputMaybe<Scalars["String"]>;
};

/** A single accout search hit */
export type AccountSearchItemType = {
  __typename?: "AccountSearchItemType";
  /** The account found. */
  account?: Maybe<AccountType>;
  /** How well the account matched the search terms. */
  score?: Maybe<Scalars["Decimal"]>;
};

/** An enumeration. */
export enum AccountStatementStatus {
  Closed = "CLOSED",
  Open = "OPEN",
}

export enum AccountStatus {
  /** Supply could have started, be ongoing or ended. */
  Active = "ACTIVE",
  /** Dormant. Users should not be able to log into dormant accounts. */
  Dormant = "DORMANT",
  /** An error occurred when we tried to enroll a meter point. This may be deprecated in future in favour of exposing this through enrollment property of a meter point. */
  EnrolmentError = "ENROLMENT_ERROR",
  /** Meter point enrollment was rejected. This may be deprecated in future in favour of exposing this through enrollment property of a meter point. */
  EnrolmentRejected = "ENROLMENT_REJECTED",
  /** Account requires processes to be completed before supply can be set up */
  Incomplete = "INCOMPLETE",
  /** A pending account is one that has been created but no registrations have started. */
  Pending = "PENDING",
  /** Void. Account created in error. */
  Void = "VOID",
  /** Withdrawn before supply started */
  Withdrawn = "WITHDRAWN",
}

export type AccountType = AccountInterface & {
  __typename?: "AccountType";
  /** The type of account. */
  accountType?: Maybe<AccountTypeChoices>;
  /** List of active hardship agreements for the user when is_in_hardship is True. */
  activeHardshipAgreements?: Maybe<Array<Maybe<HardshipAgreementType>>>;
  /** The referral schemes currently active for this account. */
  activeReferralSchemes?: Maybe<ReferralSchemeTypes>;
  /**
   * The billing address of this account, stored in the new
   * libaddressinput-based format.
   *
   * Note that `name` and `organization` are very unlikely to be
   * supplied here; the `billing_name` field on the account
   * itself is generally used for that purpose instead.
   */
  address?: Maybe<RichAddressType>;
  /** A list of active agreements belonging to an account that is linked to the logged in user. */
  agreements?: Maybe<Array<Maybe<AgreementType>>>;
  /** Fetch annual statements for the account. */
  annualStatements?: Maybe<AnnualStatementConnectionTypeConnection>;
  /** Applications by this account to become our customer. More recent applications will be listed first. */
  applications?: Maybe<AccountApplicationConnectionTypeConnection>;
  /** Assistance agreements for account. */
  assistanceAgreements?: Maybe<Array<Maybe<AssistanceAgreementType>>>;
  /** The current account balance. */
  balance: Scalars["Int"];
  /** Fetch a specific issued bill (invoice/statement) for the account. */
  bill?: Maybe<BillInterface>;
  /** The billing address of the account. */
  billingAddress?: Maybe<Scalars["String"]>;
  billingAddressLine1?: Maybe<Scalars["String"]>;
  billingAddressLine2?: Maybe<Scalars["String"]>;
  billingAddressLine3?: Maybe<Scalars["String"]>;
  billingAddressLine4?: Maybe<Scalars["String"]>;
  billingAddressLine5?: Maybe<Scalars["String"]>;
  billingAddressPostcode?: Maybe<Scalars["String"]>;
  billingCountryCode?: Maybe<Scalars["String"]>;
  billingDeliveryPointIdentifier?: Maybe<Scalars["String"]>;
  /** The billing email of the account. */
  billingEmail?: Maybe<Scalars["String"]>;
  /** The billing name of the account. */
  billingName?: Maybe<Scalars["String"]>;
  /** Information about the account's billing cycle. */
  billingOptions?: Maybe<BillingOptionsType>;
  /** The billing sub name of the account. */
  billingSubName?: Maybe<Scalars["String"]>;
  /** Fetch issued bills (invoices/statements) for the account. */
  bills?: Maybe<BillConnectionTypeConnection>;
  /** The brand of the account. */
  brand?: Maybe<Scalars["String"]>;
  /** Business info related to a business account. */
  business?: Maybe<BusinessType>;
  /**
   * The company type of a business account.
   * @deprecated The 'businessType' field is deprecated.
   *
   * Use `business.businessType` instead
   *
   * - Marked as deprecated on 2022-03-09.
   * - Will be removed on 2024-01-01.
   */
  businessType?: Maybe<BusinessTypeOptions>;
  /** The campaigns associated with an account. */
  campaigns?: Maybe<Array<Maybe<AccountCampaignType>>>;
  /** Whether the account can request a credit refund. */
  canRequestRefund?: Maybe<Scalars["Boolean"]>;
  /** The method the account has specified they prefer we contact them. */
  commsDeliveryPreference?: Maybe<CommsDeliveryPreference>;
  /**
   * @deprecated The 'communicationDeliveryPreference' field is deprecated.
   *
   * Use `commsDeliveryPreference` instead
   *
   * - Marked as deprecated on 2022-05-27.
   * - Will be removed on 2024-01-01.
   */
  communicationDeliveryPreference?: Maybe<Scalars["String"]>;
  consentData: Scalars["JSONString"];
  /** Consents linked to this account. */
  consents: Array<ConsentType>;
  /** Contribution agreements for account. */
  contributionAgreements?: Maybe<Array<Maybe<ContributionAgreementType>>>;
  /** The datetime that the account was originally created. */
  createdAt?: Maybe<Scalars["DateTime"]>;
  /** Debt collection proceedings for account. */
  debtCollectionProceedings?: Maybe<Array<Maybe<DebtCollectionProceedingType>>>;
  /** The direct debit instructions of the account */
  directDebitInstructions?: Maybe<DirectDebitInstructionConnectionTypeConnection>;
  /** The document accessibility preference of the account. */
  documentAccessibility?: Maybe<DocumentAccessibilityChoices>;
  /** The account events that were recorded for the account. */
  events?: Maybe<AccountEventConnectionTypeConnection>;
  /** Files attached to this account. */
  fileAttachments?: Maybe<Array<Maybe<AccountFileAttachment>>>;
  id: Scalars["ID"];
  /** Returns whether this account is eligible for Intelligent Octopus products. */
  isEligibleForIntelligentOctopus?: Maybe<Scalars["Boolean"]>;
  /** True if there is an active Hardship Agreement for this account. False otherwise. */
  isInHardship?: Maybe<Scalars["Boolean"]>;
  /** Returns True if the account is on supply. */
  isOnSupply?: Maybe<Scalars["Boolean"]>;
  /** Returns True if it is a shell account. */
  isShellAccount?: Maybe<Scalars["Boolean"]>;
  /** Ledgers provide the foundation of bookkeeping functionality. Similar to a bank account, they allow us to keep track of financial activity on a particular customer account. */
  ledgers?: Maybe<Array<Maybe<LedgerType>>>;
  /** The maximum amount a customer is allowed to request as a refund and the reason why that's the maximum amount. */
  maximumRefund?: Maybe<MaximumRefundType>;
  /** Metadata associated with the account. */
  metadata?: Maybe<Array<Maybe<Metadata>>>;
  meterPointStatus?: Maybe<Scalars["String"]>;
  /** Notes for the account. */
  notes?: Maybe<Array<Maybe<AccountNoteType>>>;
  /** A code that uniquely identifies the account. */
  number?: Maybe<Scalars["String"]>;
  /** The current account overdue balance. */
  overdueBalance?: Maybe<Scalars["Int"]>;
  /** Files attached to this account. */
  paginatedFileAttachments?: Maybe<AccountFileAttachmentConnectionTypeConnection>;
  /** Paginated payment forecasts for an account. Starts from today's date (inclusive). The interface supports `last` but does not guarantee 'lastness'. */
  paginatedPaymentForecast?: Maybe<PaymentForecastConnectionTypeConnection>;
  /**
   * A list displaying the payment forecast for an account. The list starts from today's date (inclusive).
   * @deprecated The 'paymentForecast' field is deprecated.
   *
   * Please use 'paginatedPaymentForecast' instead.
   *
   * - Marked as deprecated on 2024-01-03.
   * - Will be removed on 2025-01-01.
   */
  paymentForecast?: Maybe<Array<Maybe<PaymentForecastType>>>;
  /** The schedules that describe how we would expect to take payments for an account on a given month. */
  paymentSchedules?: Maybe<PaymentScheduleConnectionTypeConnection>;
  /** The payments made into an account from a payment instruction. */
  payments?: Maybe<AccountPaymentConnectionTypeConnection>;
  /** The portfolio this account is linked to. */
  portfolio?: Maybe<PortfolioType>;
  /** The language that the account preferred for communications. */
  preferredLanguageForComms?: Maybe<Scalars["String"]>;
  /** Properties linked to the account now and in the future. */
  properties?: Maybe<Array<Maybe<PropertyType>>>;
  provisionalTransactions?: Maybe<ProvisionalTransactionConnectionTypeConnection>;
  /** Account references linked to this account. */
  references?: Maybe<Array<Maybe<AccountReferenceType>>>;
  /** The referrals created by this account. */
  referrals?: Maybe<ReferralConnectionTypeConnection>;
  /** Number of referrals created by this account. */
  referralsCreated?: Maybe<Scalars["Int"]>;
  /** The repayments that have been requested for this account. */
  repayments?: Maybe<AccountRepaymentConnectionTypeConnection>;
  /** Details about the eligibility status for requesting a refund. */
  requestRefundEligibility?: Maybe<RequestRefundEligibilityType>;
  /** The rewards applied to this account. */
  rewards?: Maybe<Array<Maybe<RewardType>>>;
  /** List of billing address lines. */
  splitBillingAddress?: Maybe<Array<Maybe<Scalars["String"]>>>;
  /** The current status of the account. */
  status?: Maybe<AccountStatus>;
  /** Fetch transactions that have taken place on the account. */
  transactions?: Maybe<TransactionConnectionTypeConnection>;
  /** Unique reference number from a 3rd party enrolment. */
  urn?: Maybe<Scalars["String"]>;
  users: Array<AccountUserType>;
};

export type AccountTypeAgreementsArgs = {
  id?: InputMaybe<Scalars["ID"]>;
};

export type AccountTypeAnnualStatementsArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
};

export type AccountTypeApplicationsArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
};

export type AccountTypeBalanceArgs = {
  includeAllLedgers?: InputMaybe<Scalars["Boolean"]>;
};

export type AccountTypeBillArgs = {
  billType?: InputMaybe<BillTypeEnum>;
  id: Scalars["ID"];
};

export type AccountTypeBillsArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  fromDate?: InputMaybe<Scalars["Date"]>;
  includeBillsWithoutPDF?: InputMaybe<Scalars["Boolean"]>;
  includeHeldStatements?: InputMaybe<Scalars["Boolean"]>;
  includeHistoricStatements?: InputMaybe<Scalars["Boolean"]>;
  includeOpenStatements?: InputMaybe<Scalars["Boolean"]>;
  issuedFromDate?: InputMaybe<Scalars["Date"]>;
  issuedToDate?: InputMaybe<Scalars["Date"]>;
  last?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  onlyCurrentEmail?: InputMaybe<Scalars["Boolean"]>;
  orderBy?: InputMaybe<BillsOrderBy>;
  toDate?: InputMaybe<Scalars["Date"]>;
};

export type AccountTypeDirectDebitInstructionsArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  statuses?: InputMaybe<Array<InputMaybe<DirectDebitInstructionStatus>>>;
};

export type AccountTypeEventsArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  eventTypes?: InputMaybe<Array<InputMaybe<AccountEventType>>>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
};

export type AccountTypeLedgersArgs = {
  ledgerId?: InputMaybe<Scalars["ID"]>;
  ledgerNumber?: InputMaybe<Scalars["String"]>;
};

export type AccountTypePaginatedFileAttachmentsArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  category?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  id?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
};

export type AccountTypePaginatedPaymentForecastArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  dateTo?: InputMaybe<Scalars["Date"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  ledgerId?: InputMaybe<Scalars["Int"]>;
};

export type AccountTypePaymentForecastArgs = {
  dateTo: Scalars["Date"];
  ledgerId?: InputMaybe<Scalars["Int"]>;
};

export type AccountTypePaymentSchedulesArgs = {
  active?: InputMaybe<Scalars["Boolean"]>;
  activeOnDate?: InputMaybe<Scalars["Date"]>;
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  includeDormant?: InputMaybe<Scalars["Boolean"]>;
  last?: InputMaybe<Scalars["Int"]>;
  ledgerId?: InputMaybe<Scalars["ID"]>;
  ledgerType?: InputMaybe<Scalars["Int"]>;
  reason?: InputMaybe<PaymentScheduleReasonOptions>;
};

export type AccountTypePaymentsArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  includePromises?: InputMaybe<Scalars["Boolean"]>;
  last?: InputMaybe<Scalars["Int"]>;
  ledgerId?: InputMaybe<Scalars["String"]>;
  reason?: InputMaybe<PaymentReasonOptions>;
  status?: InputMaybe<AccountPaymentStatusOptions>;
};

export type AccountTypePropertiesArgs = {
  activeFrom?: InputMaybe<Scalars["DateTime"]>;
};

export type AccountTypeProvisionalTransactionsArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
};

export type AccountTypeReferralsArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
};

export type AccountTypeRepaymentsArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  statuses?: InputMaybe<Array<InputMaybe<AccountRepaymentStatusOptions>>>;
};

export type AccountTypeTransactionsArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  fromDate?: InputMaybe<Scalars["Date"]>;
  includeAllLedgers?: InputMaybe<Scalars["Boolean"]>;
  last?: InputMaybe<Scalars["Int"]>;
  ledgerId?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<TransactionsOrderBy>;
  toDate?: InputMaybe<Scalars["Date"]>;
  transactionTypes?: InputMaybe<Array<InputMaybe<TransactionTypeFilter>>>;
  transactionTypesExcluded?: InputMaybe<
    Array<InputMaybe<TransactionTypeFilter>>
  >;
};

/** An enumeration. */
export enum AccountTypeChoices {
  /** An account designed to supply/bill business premises. */
  Business = "BUSINESS",
  /** An account created when we supply a business premises but do not have details for the occupants. */
  BusinessOccupier = "BUSINESS_OCCUPIER",
  /** An account created when we supply a business premises and know there are definitely no occupants. */
  BusinessVacant = "BUSINESS_VACANT",
  /** An account designed to supply/bill domestic premises. */
  Domestic = "DOMESTIC",
  /** An account created when we supply domestic premises that are managed by a business, i.e., a B2B2C model. */
  Managed = "MANAGED",
  /** An account created when we supply a domestic premises but do not have details for the occupants. */
  Occupier = "OCCUPIER",
  /** An account which is responsible for all other accounts in the portfolio, i.e. pays the bills for them. */
  PortfolioLead = "PORTFOLIO_LEAD",
  /** An account created when we supply a domestic premises and know there are definitely no occupants. */
  Vacant = "VACANT",
}

/** Information about the preferences set up for a user. */
export type AccountUserCommsPreferences = {
  __typename?: "AccountUserCommsPreferences";
  /** What format the user would like to receive their emails in. */
  emailFormat?: Maybe<EmailFormats>;
  /** This setting allows the user to adjust the default font size of the communications sent to them. */
  fontSizeMultiplier?: Maybe<Scalars["Float"]>;
  /** Whether the user has opted in to receive meter reading confirmation emails. */
  isOptedInMeterReadingConfirmations?: Maybe<Scalars["Boolean"]>;
  /** Whether a user has opted in to receive messages from the client or client group. For example, for Octopus Energy this describes whether a user is opted in to offers from Octopus Investments. */
  isOptedInToClientMessages?: Maybe<Scalars["Boolean"]>;
  /** Whether a user has opted in to receive messages offering discounts or other services not directly related to the services the client provides. */
  isOptedInToOfferMessages?: Maybe<Scalars["Boolean"]>;
  /** Whether a user has opted in to receive messages we recommend they read, but are not vital to the utilities the client provides. For example, these could be reminders that the client will take a payment. */
  isOptedInToRecommendedMessages?: Maybe<Scalars["Boolean"]>;
  /** Whether the user has opted in to receive SMS messages. */
  isOptedInToSmsMessages?: Maybe<Scalars["Boolean"]>;
  /** Whether a user has opted in to receive messages from the client's preferred third parties. */
  isOptedInToThirdPartyMessages?: Maybe<Scalars["Boolean"]>;
  /** Whether a user has opted in to receive messages updating them on client activities. */
  isOptedInToUpdateMessages?: Maybe<Scalars["Boolean"]>;
  /** Whether a user has opted to have inverted colours in their emails. This is currently only relevant to the Octopus Energy brand, whose emails have a dark background by default. */
  isUsingInvertedEmailColours?: Maybe<Scalars["Boolean"]>;
  /** Song which will be used as hold music for the user. */
  preferredHoldMusic?: Maybe<Songs>;
};

/** Information about the consents for an account user. */
export type AccountUserConsents = {
  __typename?: "AccountUserConsents";
  /** Consents linked to this user. */
  consents: Array<ConsentType>;
};

export type AccountUserDetailType = {
  __typename?: "AccountUserDetailType";
  /** The namespace for the property. */
  namespace?: Maybe<Scalars["String"]>;
  /** The property value. */
  value?: Maybe<Scalars["String"]>;
};

export type AccountUserInput = {
  communicationPreference?: InputMaybe<Communication_Preference>;
  dateOfBirth: Scalars["Date"];
  email: Scalars["String"];
  familyName: Scalars["String"];
  givenName: Scalars["String"];
  landline?: InputMaybe<Scalars["String"]>;
  languagePreference?: InputMaybe<LanguagePreferenceChoices>;
  /** The customer's mobile phone number. */
  mobile: Scalars["String"];
};

/** The role a user has in association with one account. */
export type AccountUserRoleType = {
  __typename?: "AccountUserRoleType";
  account: AccountType;
  id: Scalars["ID"];
  /** The account role. */
  role?: Maybe<Scalars["RoleString"]>;
  user: AccountUserType;
};

/** User objects are the core of the authentication system. They typically represent a customer who manages a portfolio of one or more accounts. */
export type AccountUserType = {
  __typename?: "AccountUserType";
  /** List of roles a user has for each account they're linked to. */
  accountUserRoles?: Maybe<Array<Maybe<AccountUserRoleType>>>;
  /** List of accounts that the user is linked to either via portfolio role or account role. */
  accounts?: Maybe<Array<Maybe<AccountInterface>>>;
  /** List of alternative phone numbers for the account user. */
  alternativePhoneNumbers?: Maybe<Array<Maybe<Scalars["String"]>>>;
  /** Consents linked to this user. */
  consents: Array<ConsentType>;
  /** AccountUser's date of birth. */
  dateOfBirth?: Maybe<Scalars["Date"]>;
  /** List of details linked to this user. */
  details?: Maybe<Array<Maybe<AccountUserDetailType>>>;
  /**
   * We recommend you use fullName instead of this field.
   * @deprecated The 'displayName' field is deprecated.
   *
   * Please use fullName instead of this field.
   *
   * - Marked as deprecated on 2019-12-11.
   * - Will be removed on 2024-01-01.
   */
  displayName?: Maybe<Scalars["String"]>;
  email: Scalars["String"];
  familyName: Scalars["String"];
  /**
   * We recommend you use preferredName or fullName instead of this field.
   * @deprecated The 'firstName' field is deprecated.
   *
   * Use 'givenName' instead.
   *
   * - Marked as deprecated on 2020-09-23.
   * - Will be removed on 2023-06-05.
   */
  firstName?: Maybe<Scalars["String"]>;
  /** The user's full name. */
  fullName?: Maybe<Scalars["String"]>;
  givenName: Scalars["String"];
  /** Whether there are family issues. */
  hasFamilyIssues?: Maybe<Scalars["Boolean"]>;
  id: Scalars["ID"];
  /** Designates whether this user is deceased. */
  isDeceased: Scalars["Boolean"];
  /** True if user is linked to an account with an active hardship agreement. */
  isInHardship?: Maybe<Scalars["Boolean"]>;
  landline: Scalars["String"];
  /**
   * The user's landline phone number.
   * @deprecated The 'landlinePhoneNumber' field is deprecated.
   *
   * Use 'landline' instead.
   *
   * - Marked as deprecated on 2021-03-22.
   * - Will be removed on 2024-01-01.
   */
  landlinePhoneNumber?: Maybe<Scalars["String"]>;
  /**
   * We recommend you use preferredName or fullName instead of this field.
   * @deprecated The 'lastName' field is deprecated.
   *
   * Use 'familyName' instead.
   *
   * - Marked as deprecated on 2020-09-23.
   * - Will be removed on 2023-06-05.
   */
  lastName?: Maybe<Scalars["String"]>;
  /** The user's secret key to access the Developer API. */
  liveSecretKey?: Maybe<Scalars["String"]>;
  mobile: Scalars["String"];
  /**
   * We recommend you use portfolioIds instead of this field.
   * @deprecated The 'portfolioId' field is deprecated.
   *
   * Please use 'portfolioIds' instead.
   *
   * - Marked as deprecated on 2022-08-04.
   * - Will be removed on 2024-01-01.
   */
  portfolioId?: Maybe<Scalars["ID"]>;
  /** List of portfolio ids that the user is linked to via their portfolio roles. */
  portfolioIds?: Maybe<Array<Maybe<Scalars["ID"]>>>;
  /** List of roles a user has for each portfolio they're linked to. */
  portfolioUserRoles?: Maybe<Array<Maybe<PortfolioUserRoleType>>>;
  /** List of portfolios that the user is linked to via their portfolio roles. */
  portfolios?: Maybe<PortfolioConnectionTypeConnection>;
  preferences?: Maybe<AccountUserCommsPreferences>;
  /** The user's preferred name. */
  preferredName?: Maybe<Scalars["String"]>;
  /** The user's pronouns e.g. 'she/her', 'he/him', 'they/them'. */
  pronouns?: Maybe<Scalars["String"]>;
  specialCircumstances?: Maybe<SpecialCircumstancesType>;
  title?: Maybe<Scalars["String"]>;
};

/** User objects are the core of the authentication system. They typically represent a customer who manages a portfolio of one or more accounts. */
export type AccountUserTypeAccountUserRolesArgs = {
  accountNumber?: InputMaybe<Scalars["String"]>;
};

/** User objects are the core of the authentication system. They typically represent a customer who manages a portfolio of one or more accounts. */
export type AccountUserTypeAccountsArgs = {
  allowedBrandCodes?: InputMaybe<Array<InputMaybe<BrandChoices>>>;
  excludeAccountTypes?: InputMaybe<Array<InputMaybe<AccountTypeChoices>>>;
  excludeAccountsWithoutAgreements?: InputMaybe<Scalars["Boolean"]>;
  restrictToAccountNumbers?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  restrictToPublicFacingBrands?: InputMaybe<Scalars["Boolean"]>;
};

/** User objects are the core of the authentication system. They typically represent a customer who manages a portfolio of one or more accounts. */
export type AccountUserTypePortfolioIdsArgs = {
  allowedBrandCodes?: InputMaybe<Array<InputMaybe<BrandChoices>>>;
  restrictToPublicFacingBrands?: InputMaybe<Scalars["Boolean"]>;
};

/** User objects are the core of the authentication system. They typically represent a customer who manages a portfolio of one or more accounts. */
export type AccountUserTypePortfolioUserRolesArgs = {
  accountNumber?: InputMaybe<Scalars["String"]>;
  portfolioNumber?: InputMaybe<Scalars["String"]>;
};

/** User objects are the core of the authentication system. They typically represent a customer who manages a portfolio of one or more accounts. */
export type AccountUserTypePortfoliosArgs = {
  after?: InputMaybe<Scalars["String"]>;
  allowedBrandCodes?: InputMaybe<Array<InputMaybe<BrandChoices>>>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  restrictToPublicFacingBrands?: InputMaybe<Scalars["Boolean"]>;
};

/**
 * Mutation for acquiring OAuth tokens from vendor.
 *
 * The possible errors that can be raised are:
 *
 * - KT-CT-7223: Unauthorized.
 * - KT-CT-4810: Unable to find matching device for this request.
 * - KT-CT-1113: Disabled GraphQL field requested.
 */
export type AcquireOauthTokens = {
  __typename?: "AcquireOauthTokens";
  possibleErrors?: Maybe<Array<Maybe<PossibleErrorType>>>;
  /** Whether token request was successful. */
  tokensAcquired?: Maybe<Scalars["Boolean"]>;
};

/** The input type for acquiring OAuth tokens from vendor. */
export type AcquireOauthTokensInput = {
  /** The account number. */
  accountNumber: Scalars["String"];
  /** The 'code' in OAuth redirect. */
  authCode: Scalars["String"];
  /** The 'state' in OAuth redirect. */
  state: Scalars["String"];
};

/** Actions are events created by buttons and other interaction. */
export type ActionInterface = {
  /** The name of the action object's type. */
  typeName?: Maybe<Scalars["String"]>;
};

/** Actions are things to do upon a user interaction, such as tapping a button. */
export type ActionType =
  | BackendScreenEventActionType
  | CloseActionType
  | DeeplinkActionType
  | LinkActionType
  | ScreenActionType;

/**
 * The possible errors that can be raised are:
 *
 * - KT-CT-4123: Unauthorized.
 * - KT-CT-1113: Disabled GraphQL field requested.
 * - KT-CT-1111: Unauthorized.
 * - KT-CT-1112: 'Authorization' header not provided.
 */
export type AddAccountCampaign = {
  __typename?: "AddAccountCampaign";
  campaignAdded?: Maybe<Scalars["Boolean"]>;
  possibleErrors?: Maybe<Array<Maybe<PossibleErrorType>>>;
};

export type AddAccountCampaignInput = {
  accountNumber: Scalars["String"];
  campaign: AccountCampaignChoices;
};

/**
 * The possible errors that can be raised are:
 *
 * - KT-CT-4123: Unauthorized.
 * - KT-CT-1113: Disabled GraphQL field requested.
 * - KT-CT-1111: Unauthorized.
 * - KT-CT-1112: 'Authorization' header not provided.
 */
export type AddCampaignToAccount = {
  __typename?: "AddCampaignToAccount";
  /** Whether the campaign was successfully added. */
  campaignAdded?: Maybe<Scalars["Boolean"]>;
  possibleErrors?: Maybe<Array<Maybe<PossibleErrorType>>>;
};

export type AddCampaignToAccountInput = {
  /** The account number. */
  accountNumber: Scalars["String"];
  /** The slug of the campaign we want to assign. */
  campaign: Scalars["String"];
  /** The date in which the link between the campaing and the account is meant to expire. If null, no specific expiring date will be set. */
  expiryDate?: InputMaybe<Scalars["Date"]>;
  /** The date in which the link between the campaing and the account is meant to start. If null, no specific start date will be set. */
  startDate?: InputMaybe<Scalars["Date"]>;
};

/**
 * Mutation for adding an additional property to a shell account.
 *
 * The possible errors that can be raised are:
 *
 * - KT-CT-6623: Unauthorized.
 * - KT-US-6611: Product not found.
 * - KT-US-6612: Account cannot have multiple properties. Please contact OPS.
 * - KT-US-6620: Given ESI ID is not served by us.
 * - KT-US-4615: Received an invalid quote code.
 * - KT-US-6622: Preferred supply start date cannot be in the past.
 * - KT-US-6624: Large commercial properties are not being served at the moment.
 * - KT-CT-1113: Disabled GraphQL field requested.
 * - KT-CT-1111: Unauthorized.
 * - KT-CT-1112: 'Authorization' header not provided.
 */
export type AddPropertyToShellAccount = {
  __typename?: "AddPropertyToShellAccount";
  addPropertyToShellAccountData?: Maybe<AddPropertyToShellAccountOutputType>;
  possibleErrors?: Maybe<Array<Maybe<PossibleErrorType>>>;
};

/**
 * Input type for adding a property to shell account.
 *
 * Requires an `esi_id`, `account_number`, `address` fields, `product_id`, and `enrollment_type`.
 *
 * The `effective_from` is an optional field. If it is not provided, it defaults to the current time.
 */
export type AddPropertyToShellAccountInputType = {
  accountNumber: Scalars["String"];
  addressLine1: Scalars["String"];
  addressLine2: Scalars["String"];
  addressLine3?: InputMaybe<Scalars["String"]>;
  /** Amount (in cents) to pay when account balance falls low (defaults to 7500). Used for pre-pay agreements. */
  autoTopUpPaymentAmount?: InputMaybe<Scalars["Int"]>;
  city: Scalars["String"];
  /** Date at which the move in/switch request should be initiated. */
  effectiveFrom?: InputMaybe<Scalars["DateTime"]>;
  enrollmentType: ShellAccountEnrollmentTypeEnum;
  esiId: Scalars["String"];
  hasCriticalCare?: InputMaybe<Scalars["Boolean"]>;
  postcode: Scalars["String"];
  productId: Scalars["String"];
  /** Quote code. */
  quoteCode?: InputMaybe<Scalars["String"]>;
  /** Should the agreement created be configured for auto-pay. */
  setupAutopay?: InputMaybe<Scalars["Boolean"]>;
  state: Scalars["String"];
};

export type AddPropertyToShellAccountOutputType = {
  __typename?: "AddPropertyToShellAccountOutputType";
  esiId?: Maybe<Scalars["String"]>;
};

export type AddressTdspMappingInputType = {
  address: Scalars["String"];
  city: Scalars["String"];
  houseNumber?: InputMaybe<Scalars["String"]>;
  postCode: Scalars["String"];
  state?: InputMaybe<Scalars["String"]>;
};

export type AddressTdspMappingOutputType = {
  __typename?: "AddressTdspMappingOutputType";
  address?: Maybe<Scalars["String"]>;
  address2?: Maybe<Scalars["String"]>;
  city?: Maybe<Scalars["String"]>;
  esiId?: Maybe<Scalars["String"]>;
  houseNumber?: Maybe<Scalars["String"]>;
  isCustomer?: Maybe<OeCustomerOutput>;
  loadZone?: Maybe<LoadZone>;
  /** The type of meter at the property. */
  meterType?: Maybe<MeterTypeOptions>;
  postCode?: Maybe<Scalars["String"]>;
  /** Type of property. */
  premiseType?: Maybe<PremiseType>;
  serviceProvider?: Maybe<ServiceProvider>;
  state?: Maybe<Scalars["String"]>;
};

/** Affiliate link for the organization. */
export type AffiliateLinkType = {
  __typename?: "AffiliateLinkType";
  contactEmail: Scalars["String"];
  contactName: Scalars["String"];
  id: Scalars["ID"];
  isBusiness: Scalars["Boolean"];
  landingUrl: Scalars["String"];
  organisation?: Maybe<AffiliateOrganisationType>;
  subdomain: Scalars["String"];
  trainingStatus: LinkTrainingStatus;
};

export type AffiliateOrganisationType = {
  __typename?: "AffiliateOrganisationType";
  /** Is this partner allowed to specify payment methods other than Direct Debit in the import csv or API. */
  allowAlternativePaymentMethods?: Maybe<Scalars["Boolean"]>;
  /** Are meter point registrations limited for profile classes 1 and 2 for registrations from csv or API. */
  canRegisterBusinessMeterPoints?: Maybe<Scalars["Boolean"]>;
  /** Allow registration requests with customers without an email address. */
  canRegisterCustomersWithoutEmailAddress?: Maybe<Scalars["Boolean"]>;
  /** Allow registration requests with exiting account user emails to add to the portfolio belonging to the account user. */
  canRegisterPortfolioAccounts?: Maybe<Scalars["Boolean"]>;
  /** Allow performing tariff renewals via API. */
  canRenewTariffs?: Maybe<Scalars["Boolean"]>;
  /** Allow this partner access to the IVR support API (modify their own IVR handling through third party 'IVR Flow Editor'). */
  canUseIvrSupportApi?: Maybe<Scalars["Boolean"]>;
  /** Default Account Type. */
  defaultAccountType?: Maybe<AccountTypeChoices>;
  id: Scalars["ID"];
  /** Restrict to field-sales-only products? This is only allowed for the 'field-sales' and 'events' sales channels. */
  isFieldSalesOnlyProduct?: Maybe<Scalars["Boolean"]>;
  name: Scalars["String"];
  /** Sales Channel. */
  salesChannel?: Maybe<SalesChannelChoices>;
  /** Allow this partner to skip validation that ensures all meter points belong to the same address. */
  skipMeterPointAddressValidation?: Maybe<Scalars["Boolean"]>;
};

/** A tracked session for the affiliate link. */
export type AffiliateSessionType = {
  __typename?: "AffiliateSessionType";
  id: Scalars["ID"];
  ipAddress?: Maybe<Scalars["String"]>;
  link?: Maybe<AffiliateLinkType>;
  queryParams: Scalars["JSONString"];
  userAgent: Scalars["String"];
};

/**
 * Returns a list of standing rates and consumption rates
 * that are applicable to all TDSPs and Load Zones.
 */
export type AgnosticRatesType = {
  __typename?: "AgnosticRatesType";
  /** The consumption rate will represent all consumption-based charges from the TDSP. */
  consumptionRates?: Maybe<Array<Maybe<ConsumptionRatesType>>>;
  /** The standing rate will be referenced for other recurring charges or one-off meter fees. */
  standingRates?: Maybe<Array<Maybe<StandingRatesType>>>;
};

/** Any kind of rate which can be charged or credited to a customer. */
export type AgreementType = {
  __typename?: "AgreementType";
  /** Rates of the product that the customer is currently on. */
  activeProductRates?: Maybe<RatesType>;
  agreedAt?: Maybe<Scalars["DateTime"]>;
  /** Returns the auto top up amount selected by the user. */
  autoTopUpAmountSelectedByUser?: Maybe<Scalars["Int"]>;
  /** Returns the early termination fee in dollars if one exists, else return None. */
  earlyTerminationFee?: Maybe<Scalars["Decimal"]>;
  /** Returns the date when the early termination fee expires (inclusive) if one exists, else returns None. The date accounts for early switch allowance setting. */
  earlyTerminationFeeExpiration?: Maybe<Scalars["Date"]>;
  /** Retrieve EFLs based on the product. */
  efls?: Maybe<Array<Maybe<EflType>>>;
  id: Scalars["ID"];
  /** Returns true if this customer is eligible for self renewal process. */
  isEligibleForRenewal?: Maybe<Scalars["Boolean"]>;
  /** Returns true if this customer has exported any generation (solar) readings during the last seven days. */
  isExportingGenerationReadings?: Maybe<Scalars["Boolean"]>;
  /** Outage information based on the service provider that serves the customer. */
  outageInformation?: Maybe<OutageMapOutputType>;
  /** An electricity product that the customer has signed up for using an agreement. */
  product?: Maybe<ProductType>;
  /** Returns the accepted quote request, if it exists. Otherwise, it returns None.Note: only a single quoted product will be returned in the quote request. */
  quoteRequest?: Maybe<GetQuoteOutputType>;
  terminatedAt?: Maybe<Scalars["DateTime"]>;
  /** Get the day rate for a time of use product agreement. Returns null for all other product types. */
  totalApplicableDayRate?: Maybe<Scalars["Decimal"]>;
  /** Get the night rate for a time of use product agreement. Returns null for all other product types. */
  totalApplicableNightRate?: Maybe<Scalars["Decimal"]>;
  /** Get total applicable rate for a default 1000 kWh consumption. Returns null for time of use products. */
  totalApplicableRate?: Maybe<Scalars["Decimal"]>;
  validFrom: Scalars["DateTime"];
  validTo?: Maybe<Scalars["DateTime"]>;
};

/** Any kind of rate which can be charged or credited to a customer. */
export type AgreementTypeEflsArgs = {
  language?: InputMaybe<EflLanguage>;
};

/** Any kind of rate which can be charged or credited to a customer. */
export type AgreementTypeTotalApplicableDayRateArgs = {
  kwhUsage?: InputMaybe<Scalars["Decimal"]>;
};

/** Any kind of rate which can be charged or credited to a customer. */
export type AgreementTypeTotalApplicableNightRateArgs = {
  kwhUsage?: InputMaybe<Scalars["Decimal"]>;
};

/** Any kind of rate which can be charged or credited to a customer. */
export type AgreementTypeTotalApplicableRateArgs = {
  includeDeviceConditionalRates?: InputMaybe<Scalars["Boolean"]>;
  includeSubscriptionFees?: InputMaybe<Scalars["Boolean"]>;
  kwhUsage?: InputMaybe<Scalars["Decimal"]>;
};

export enum Alignment {
  Center = "CENTER",
  End = "END",
  Start = "START",
}

export type AllSolarForecastedData = {
  __typename?: "AllSolarForecastedData";
  /** The time at which the data was last updated. */
  lastUpdatedAt?: Maybe<Scalars["DateTime"]>;
  /** The solar forecasted data. */
  solarForecastedData?: Maybe<Array<Maybe<SolarForecastedData>>>;
};

export type AllSystemWidePriceData = {
  __typename?: "AllSystemWidePriceData";
  /** The time at which the data was last updated. */
  lastUpdatedAt?: Maybe<Scalars["DateTime"]>;
  /** The system wide price data. */
  systemWidePriceData?: Maybe<Array<Maybe<SystemWidePrice>>>;
};

export type AllSystemWidePriceDataSystemWidePriceDataArgs = {
  filterBySettlementPointOptions?: InputMaybe<SettlementPointOptions>;
};

/**
 * Allow a repayment to be submitted.
 *
 * The possible errors that can be raised are:
 *
 * - KT-CT-3944: Account repayment does not exist.
 * - KT-CT-3945: Unable to allow a repayment to be submitted.
 * - KT-CT-3950: The provided reason text is too long.
 * - KT-CT-1113: Disabled GraphQL field requested.
 * - KT-CT-1111: Unauthorized.
 * - KT-CT-1112: 'Authorization' header not provided.
 */
export type AllowRepaymentSubmission = {
  __typename?: "AllowRepaymentSubmission";
  possibleErrors?: Maybe<Array<Maybe<PossibleErrorType>>>;
  /** The repayment ID. */
  repaymentId?: Maybe<Scalars["ID"]>;
  /** Resulting Repayment Intervention details. */
  repaymentIntervention?: Maybe<RepaymentInterventionType>;
};

/** Returned when an account already has a LINE account linked to it. */
export type AlreadyLinkedError = {
  __typename?: "AlreadyLinkedError";
  /** The type of error that occurred. */
  type: LineLinkErrorType;
};

/**
 * The possible errors that can be raised are:
 *
 * - KT-CT-3924: Unauthorized.
 * - KT-CT-1113: Disabled GraphQL field requested.
 * - KT-CT-1111: Unauthorized.
 * - KT-CT-1112: 'Authorization' header not provided.
 */
export type AmendPayment = {
  __typename?: "AmendPayment";
  payment?: Maybe<AccountPaymentType>;
  possibleErrors?: Maybe<Array<Maybe<PossibleErrorType>>>;
};

export type AmendPaymentInput = {
  /** The account number. */
  accountNumber: Scalars["String"];
  /** The new amount for the amended payment. */
  amount: Scalars["Int"];
  /** The new date to collect the payment. */
  paymentDate: Scalars["Date"];
  /** The ID of the payment to amend. */
  paymentId: Scalars["Int"];
  /** Reason for amending the payment. */
  reason?: InputMaybe<Scalars["String"]>;
};

/** A media element containing an animation, such as a Lottie. */
export type AnimationType = AccessibilityInterface &
  IdentifiableInterface &
  MediaInterface &
  SizedItemInterface & {
    __typename?: "AnimationType";
    /** Whether the element is hidden from view. */
    accessibilityHidden?: Maybe<Scalars["Boolean"]>;
    /** Accessible description of the element. */
    accessibilityLabel?: Maybe<Scalars["String"]>;
    /** The horizontal alignment of the media. */
    horizontalAlignment?: Maybe<Alignment>;
    /** Unique identifier of the object. */
    id?: Maybe<Scalars["ID"]>;
    /** The resource URL of the media. */
    mediaUrl: Scalars["String"];
    /** The name of the object's type. */
    typename?: Maybe<Scalars["String"]>;
    /** The measurement of the element. */
    width?: Maybe<ItemSizeType>;
  };

export type AnnualStatementConnectionTypeConnection = {
  __typename?: "AnnualStatementConnectionTypeConnection";
  /** Number of nodes in the edge. */
  edgeCount: Scalars["Int"];
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<AnnualStatementConnectionTypeEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  /** Total number of nodes. */
  totalCount: Scalars["Int"];
};

/** A Relay edge containing a `AnnualStatementConnectionType` and its cursor. */
export type AnnualStatementConnectionTypeEdge = {
  __typename?: "AnnualStatementConnectionTypeEdge";
  /** A cursor for use in pagination */
  cursor: Scalars["String"];
  /** The item at the end of the edge */
  node?: Maybe<AnnualStatementType>;
};

/** Annual statements that are sent to the account. They summarize important information about usage and tariffs. */
export type AnnualStatementType = {
  __typename?: "AnnualStatementType";
  id?: Maybe<Scalars["ID"]>;
  pdfUrl?: Maybe<Scalars["String"]>;
  periodEndsAt: Scalars["DateTime"];
  periodStartsAt: Scalars["DateTime"];
};

/** An annulment is a billing document that annuls another billing document. */
export type AnnulmentBillingDocumentType = {
  __typename?: "AnnulmentBillingDocumentType";
  /** ID of the billing document annulled by this annulment. */
  annulledBillingDocumentId: Scalars["Int"];
  /** First time the annulment was issued. */
  firstIssued?: Maybe<Scalars["DateTime"]>;
  id: Scalars["Int"];
  /** URL to the PDF of the annulment. */
  pdfUrl?: Maybe<Scalars["String"]>;
};

/**
 * The possible errors that can be raised are:
 *
 * - KT-CT-6710: Unable to create referral.
 * - KT-US-6711: Invalid reference.
 * - KT-CT-6712: Invalid reference.
 * - KT-CT-6723: Unauthorized.
 * - KT-CT-1113: Disabled GraphQL field requested.
 * - KT-CT-1111: Unauthorized.
 * - KT-CT-1112: 'Authorization' header not provided.
 */
export type ApplyReward = {
  __typename?: "ApplyReward";
  possibleErrors?: Maybe<Array<Maybe<PossibleErrorType>>>;
  rewardOutput?: Maybe<ApplyRewardOutputType>;
};

export type ApplyRewardInputType = {
  accountNumber: Scalars["String"];
  /** Provide a referral link or a promo code. */
  reference: Scalars["String"];
};

export type ApplyRewardOutputType = {
  __typename?: "ApplyRewardOutputType";
  rewardApplied?: Maybe<Scalars["Boolean"]>;
  rewardDescription?: Maybe<Scalars["String"]>;
  rewardDescriptionInSpanish?: Maybe<Scalars["String"]>;
  rewardStatus?: Maybe<ReferralCodeApplicationStatusChoices>;
};

/**
 * The possible errors that can be raised are:
 *
 * - KT-CT-3934: Repayment request already approved.
 * - KT-CT-3935: Repayment request cannot be paid.
 * - KT-CT-3959: Unauthorized.
 * - KT-CT-1113: Disabled GraphQL field requested.
 * - KT-CT-1111: Unauthorized.
 * - KT-CT-1112: 'Authorization' header not provided.
 */
export type ApproveRepayment = {
  __typename?: "ApproveRepayment";
  possibleErrors?: Maybe<Array<Maybe<PossibleErrorType>>>;
  /** The approved repayment. */
  repayment?: Maybe<AccountRepaymentType>;
};

export type ApproveRepaymentInput = {
  /** The account number for the requested repayment. */
  accountNumber: Scalars["ID"];
  /** The id of the account repayment to be approved. */
  repaymentId: Scalars["ID"];
};

export type AssignInkBucketInput = {
  /** The name of the bucket to assign the conversation to. */
  bucketName: Scalars["String"];
  clientMutationId?: InputMaybe<Scalars["String"]>;
  /** The relay id of the conversation that will be assigned to the bucket. */
  conversationRelayId: Scalars["ID"];
};

export type AssignInkBucketPayload = {
  __typename?: "AssignInkBucketPayload";
  /** The bucket that the conversation will be assigned to. */
  bucket: InkBucket;
  clientMutationId?: Maybe<Scalars["String"]>;
  /** The conversation that will be assigned to the bucket. */
  conversation: InkConversation;
};

export type AssignedUserType = {
  __typename?: "AssignedUserType";
  /** The ID of the assigned user. */
  id: Scalars["ID"];
  /** The username of the assigned user. */
  username: Scalars["String"];
};

/** A single Assistance Agreement. */
export type AssistanceAgreementType = {
  __typename?: "AssistanceAgreementType";
  /** The start datetime of the agreement. */
  activeFrom?: Maybe<Scalars["Date"]>;
  /** The end datetime of the agreement, if any. */
  activeTo?: Maybe<Scalars["Date"]>;
  /** The type of assistance provided by the agreement. */
  assistanceType?: Maybe<Scalars["String"]>;
};

/** A step where the user must complete an auth flow. */
export type AuthFlow = SmartFlexOnboardingStepInterface & {
  __typename?: "AuthFlow";
  /** The URL to redirect the user to for authorization. */
  authorizationUri?: Maybe<Scalars["String"]>;
  /** A unique identifier for this onboarding step. */
  id?: Maybe<Scalars["ID"]>;
  /** The URI where the auth flow is going to redirect back to at the end. */
  redirectUri?: Maybe<Scalars["String"]>;
};

export type AuthenticationInput = {
  /** SSO access token for the chosen provider authentication. */
  accessToken?: InputMaybe<Scalars["String"]>;
  /** Provider code from user login used for SSO. */
  authorizationCode?: InputMaybe<Scalars["String"]>;
  /** SSO token expiry for the provider's authentication (integer in seconds). */
  expiresIn?: InputMaybe<Scalars["Int"]>;
  /** ID of the device in the external provider system. */
  providerDeviceId?: InputMaybe<Scalars["String"]>;
  /** Full redirect URI (including all query string parameters) from the result of an OAuth 2.0 flow. */
  redirectUri?: InputMaybe<Scalars["String"]>;
  /** SSO refresh token for the chosen provider authentication. */
  refreshToken?: InputMaybe<Scalars["String"]>;
  /** State from user login used for SSO. */
  state?: InputMaybe<Scalars["String"]>;
};

export type AutoPayOutputType = {
  __typename?: "AutoPayOutputType";
  isEnabled?: Maybe<Scalars["Boolean"]>;
};

/**
 * Returns an Action to perform, e.g. a screen to load.
 *
 * BackendScreenEvents are specific types of Action which trigger a mutation in the Kraken backend.
 * They return an action (any type), such as a ScreenActionType (which is then used to load the next screen).
 * Any action registered in the registry should really be an "event" with some side-effect in the backend.
 *
 * The possible errors that can be raised are:
 *
 * - KT-CT-1111: Unauthorized.
 * - KT-CT-8002: No event found.
 * - KT-CT-8003: Event has no execute function.
 * - KT-CT-8004: Error executing event in the backend.
 * - KT-CT-8007: Incorrect or missing parameters for backend screen event.
 * - KT-GB-9310: Account ineligible for joining Octoplus.
 * - KT-CT-1113: Disabled GraphQL field requested.
 */
export type BackendScreenEvent = {
  __typename?: "BackendScreenEvent";
  /** An action to perform. */
  action?: Maybe<ActionType>;
  possibleErrors?: Maybe<Array<Maybe<PossibleErrorType>>>;
};

/** An action which triggers some event in the Kraken backend. */
export type BackendScreenEventActionType = ActionInterface &
  IdentifiableInterface & {
    __typename?: "BackendScreenEventActionType";
    /** The ID of the event to trigger. */
    eventId: Scalars["String"];
    /** Unique identifier of the object. */
    id?: Maybe<Scalars["ID"]>;
    /** List of key-value pairs to pass as parameters to the event. */
    params: Array<Maybe<BackendScreenParam>>;
    /** The name of the action object's type. */
    typeName?: Maybe<Scalars["String"]>;
    /** The name of the object's type. */
    typename?: Maybe<Scalars["String"]>;
  };

/** Input for a backend action. */
export type BackendScreenEventInput = {
  /** The ID of the action to perform. */
  eventId: Scalars["ID"];
  /** List of key-value pairs (strings) to pass as parameters to the mutation. */
  params?: InputMaybe<Array<InputMaybe<BackendScreenParamInputType>>>;
};

/** A backend screen is the top-level container for mobile UI. */
export type BackendScreenInterface = {
  /** The name of the screen. */
  name: Scalars["String"];
  /** The refresh / polling frequency in milliseconds. */
  refreshFrequency?: Maybe<Scalars["Int"]>;
  /** Serialized JSON representation of the screen. */
  screenData?: Maybe<Scalars["String"]>;
};

/** A key-value pair (both Strings) which is passed as a parameter to a screen. */
export type BackendScreenParam = {
  __typename?: "BackendScreenParam";
  key: Scalars["String"];
  value: Scalars["String"];
};

/** A key-value pair (both Strings) which is passed in parameters to a backend action. */
export type BackendScreenParamInputType = {
  key: Scalars["String"];
  value: Scalars["String"];
};

/** A backend screen is the top-level container for mobile UI. */
export type BackendScreenType =
  | ComponentListType
  | Dashboard
  | GenericBackendScreen;

/** A payment schedule which triggers a payment the balance of a ledger drops below a certain value. */
export type BalanceTriggeredScheduleInput = {
  /** The ledger balance (in the minor currency unit) which when passed will trigger a payment to be taken. */
  balanceThreshold: Scalars["Int"];
  /** The target balance (in the minor currency unit) for the ledger; used to calculate the payment amount. Defaults to zero. */
  targetBalance?: InputMaybe<Scalars["Int"]>;
};

/** An enumeration. */
export enum BandCategory {
  Addon = "ADDON",
  CapacityCharge = "CAPACITY_CHARGE",
  ConsumptionCharge = "CONSUMPTION_CHARGE",
  DemandCharge = "DEMAND_CHARGE",
  DiscountCredit = "DISCOUNT_CREDIT",
  GenerationCredit = "GENERATION_CREDIT",
  StandingCharge = "STANDING_CHARGE",
}

export type BankDetailsInput = {
  accountHolder?: InputMaybe<Scalars["String"]>;
  accountNumber?: InputMaybe<Scalars["String"]>;
  accountType?: InputMaybe<Scalars["String"]>;
  bankCode?: InputMaybe<Scalars["String"]>;
  branchCode?: InputMaybe<Scalars["String"]>;
  iban?: InputMaybe<Scalars["String"]>;
};

export type BatteryChargingPreferencesInput = {
  /** Account number. */
  accountNumber: Scalars["String"];
  /** The minimum state of charge (soc) %. */
  minimumSocPercentage: Scalars["Int"];
};

export type BatteryChargingPreferencesType = {
  __typename?: "BatteryChargingPreferencesType";
  /** The maximum state of charge (soc) %. */
  maximumSocPercentage?: Maybe<Scalars["Int"]>;
  /** The minimum state of charge (soc) %. */
  minimumSocPercentage?: Maybe<Scalars["Int"]>;
};

/** An enumeration. */
export enum BatteryCouplingType {
  Ac = "AC",
  Dc = "DC",
}

export type BatteryDeviceType = {
  __typename?: "BatteryDeviceType";
  /** The charging preferences of the battery (e.g. min / max state of charge %). */
  chargingPreferences?: Maybe<BatteryChargingPreferencesType>;
  /** The current status of the battery (e.g. connected, state of charge, power). */
  deviceStatus?: Maybe<DeviceStatusType>;
  /** Batteries. */
  deviceType?: Maybe<KrakenFlexDeviceTypes>;
  /** ID of the device in KrakenFlex. */
  krakenflexDeviceId?: Maybe<Scalars["ID"]>;
  /** Device specific parameters. */
  params?: Maybe<BatteryParamsType>;
  /** Battery integration provider (make). */
  provider?: Maybe<ProviderChoices>;
};

export type BatteryParamsType = {
  __typename?: "BatteryParamsType";
  /** ID of the battery variant. */
  variantId?: Maybe<Scalars["ID"]>;
};

export type BatteryVariantModelsType = {
  __typename?: "BatteryVariantModelsType";
  /** Usable capacity in kWh. */
  batteryCapacityInKwh?: Maybe<Scalars["Decimal"]>;
  /** Coupling type (AC/DC). */
  couplingType?: Maybe<BatteryCouplingType>;
  /** Whether a variant is deprecated. */
  isDeprecated?: Maybe<Scalars["Boolean"]>;
  /** Whether an integration/make is live, i.e. available. */
  isIntegrationLive?: Maybe<Scalars["Boolean"]>;
  /** Max import/export power in kW. */
  maxPowerInKw?: Maybe<Scalars["Decimal"]>;
  /** Model name of the battery. */
  model?: Maybe<Scalars["String"]>;
  /** ID of the battery variant. */
  variantId?: Maybe<Scalars["ID"]>;
};

export type BatteryVariantsType = {
  __typename?: "BatteryVariantsType";
  /** Make of the home battery. */
  make?: Maybe<Scalars["String"]>;
  /** List of home battery models per make. */
  models?: Maybe<Array<Maybe<BatteryVariantModelsType>>>;
};

/** This field is a connection type. Connections are used to implement [cursor based pagination](https://graphql.org/learn/pagination/#pagination-and-edges). */
export type BillConnectionTypeConnection = {
  __typename?: "BillConnectionTypeConnection";
  /** Number of nodes in the edge. */
  edgeCount: Scalars["Int"];
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<BillConnectionTypeEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  /** Total number of nodes. */
  totalCount: Scalars["Int"];
};

/** A Relay edge containing a `BillConnectionType` and its cursor. */
export type BillConnectionTypeEdge = {
  __typename?: "BillConnectionTypeEdge";
  /** A cursor for use in pagination */
  cursor: Scalars["String"];
  /** The item at the end of the edge */
  node?: Maybe<BillInterface>;
};

export type BillInterface = {
  attachments?: Maybe<BillingAttachmentConnectionTypeConnection>;
  /** The type of the bill. */
  billType?: Maybe<BillTypeEnum>;
  /** The date of the bill is covered from. */
  fromDate?: Maybe<Scalars["Date"]>;
  /** The ID of the bill. */
  id?: Maybe<Scalars["ID"]>;
  /** The date the bill was sent to the customer. */
  issuedDate?: Maybe<Scalars["Date"]>;
  /**
   * Requesting this field generates a temporary URL at which bill is available.
   *         This URL will expire after approximately an hour.  It is intended for redirection purposes,
   *         NOT persistence in any form (e.g. inclusion in emails or the body of a web page).
   *         This field can raise an error with errorClass NOT_FOUND if the bill document has not
   *         been created/issued yet. This field is deprecated use 'attachments' field instead.
   * @deprecated The 'temporaryUrl' field is deprecated.
   *
   * This field is deprecated. Use the 'attachments' field instead.
   *
   * - Marked as deprecated on 2024-09-16.
   * - Will be removed on 2025-09-01.
   */
  temporaryUrl?: Maybe<Scalars["String"]>;
  /** The date of the bill is covered to. */
  toDate?: Maybe<Scalars["Date"]>;
};

export type BillInterfaceAttachmentsArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
};

/**
 * A payment schedule which triggers a payment when a bill is issued.
 *
 * Optionally a payment day frequency multiplier can be specified in which case the
 * payment is taken on the first occurrence of the payment day after a bill has been issued.
 */
export type BillTriggeredScheduleInput = {
  /** The multiple of months at which payment are taken. Required when a payment day is chosen, ignored if payment day not set. */
  frequencyMultiplier?: InputMaybe<Scalars["Int"]>;
  /** The day of the month at which to take payment (1-28). If not provided payment is taken whenever a bill is issued. */
  paymentDay?: InputMaybe<Scalars["Int"]>;
};

export enum BillTypeEnum {
  Collective = "COLLECTIVE",
  CreditNote = "CREDIT_NOTE",
  Invoice = "INVOICE",
  PreKraken = "PRE_KRAKEN",
  Statement = "STATEMENT",
}

export type BillingAddressDetailsInput = {
  /** Administrative area. */
  administrativeArea?: InputMaybe<Scalars["String"]>;
  /** Billing country. */
  country?: InputMaybe<Scalars["String"]>;
  /** Billing delivery point identifier. */
  deliveryPointIdentifier?: InputMaybe<Scalars["String"]>;
  /** Billing dependent locality. */
  dependentLocality?: InputMaybe<Scalars["String"]>;
  /** Billing locality. */
  locality?: InputMaybe<Scalars["String"]>;
  /** Billing postal code. */
  postalCode?: InputMaybe<Scalars["String"]>;
  /** Billing sorting code. */
  sortingCode?: InputMaybe<Scalars["String"]>;
  /** Billing street address. */
  streetAddress?: InputMaybe<Scalars["String"]>;
  /** Billing structured street address. */
  structuredStreetAddress?: InputMaybe<Scalars["GenericScalar"]>;
};

export type BillingAddressInput = {
  address1: Scalars["String"];
  address2?: InputMaybe<Scalars["String"]>;
  city: Scalars["String"];
  state: Scalars["String"];
  zipCode: Scalars["String"];
};

/** A connection that can provide links to the attachments of the bill. */
export type BillingAttachmentConnectionTypeConnection = {
  __typename?: "BillingAttachmentConnectionTypeConnection";
  /** Number of nodes in the edge. */
  edgeCount: Scalars["Int"];
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<BillingAttachmentConnectionTypeEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  /** Total number of nodes. */
  totalCount: Scalars["Int"];
};

/** A Relay edge containing a `BillingAttachmentConnectionType` and its cursor. */
export type BillingAttachmentConnectionTypeEdge = {
  __typename?: "BillingAttachmentConnectionTypeEdge";
  /** A cursor for use in pagination */
  cursor: Scalars["String"];
  /** The item at the end of the edge */
  node?: Maybe<BillingAttachmentType>;
};

/** Attachments are associated with a bill files and can be used to provide additional information to the customer.  */
export type BillingAttachmentType = {
  __typename?: "BillingAttachmentType";
  /** The name of the file. */
  filename?: Maybe<Scalars["String"]>;
  /** The `TemporaryURL` type represents a temporary URL at which the file is available.It is intended for redirection purposes, NOT persistence in any form. (e.g. inclusion in emails or the body of a web page).  */
  temporaryUrl?: Maybe<Scalars["String"]>;
  /** The expiry datetime field of the temporary URL. */
  temporaryUrlExpiresAt?: Maybe<Scalars["DateTime"]>;
};

/** Information about an account's billing schedule. */
export type BillingOptionsType = {
  __typename?: "BillingOptionsType";
  /** The date on which the current billing cycle will end. Null if the account is on flexible billing. */
  currentBillingPeriodEndDate?: Maybe<Scalars["Date"]>;
  /** The date on which the current billing cycle started. */
  currentBillingPeriodStartDate?: Maybe<Scalars["Date"]>;
  /** If true, this account is billed on specific day of a regular cycle. If false, the billing schedule is flexible, depending on when meter readings are submitted. */
  isFixed: Scalars["Boolean"];
  /** The next date on which this account will next be billed. This is the same as the start date for their next bill cycle. Null if the account is on flexible billing. */
  nextBillingDate?: Maybe<Scalars["Date"]>;
  periodLength?: Maybe<AccountBillingOptionsPeriodLength>;
  periodLengthMultiplier?: Maybe<Scalars["Int"]>;
  /** The day of the month on which the account's billing period should start. */
  periodStartDay?: Maybe<Scalars["Int"]>;
};

export enum BillsOrderBy {
  FromDateDesc = "FROM_DATE_DESC",
  IssuedDateDesc = "ISSUED_DATE_DESC",
}

/**
 * Block a repayment from being submitted.
 *
 * The possible errors that can be raised are:
 *
 * - KT-CT-3944: Account repayment does not exist.
 * - KT-CT-3946: Unable to block a repayment from being submitted.
 * - KT-CT-3950: The provided reason text is too long.
 * - KT-CT-1113: Disabled GraphQL field requested.
 * - KT-CT-1111: Unauthorized.
 * - KT-CT-1112: 'Authorization' header not provided.
 */
export type BlockRepaymentSubmission = {
  __typename?: "BlockRepaymentSubmission";
  possibleErrors?: Maybe<Array<Maybe<PossibleErrorType>>>;
  /** The repayment ID. */
  repaymentId?: Maybe<Scalars["ID"]>;
  /** Resulting Repayment Intervention details. */
  repaymentIntervention?: Maybe<RepaymentInterventionType>;
};

/** An enumeration. */
export enum BrandChoices {
  /** Octopus Energy Usa. */
  OctopusEnergyUsa = "OCTOPUS_ENERGY_USA",
}

export type BusinessType = {
  __typename?: "BusinessType";
  /** The company type of a business account. */
  businessType?: Maybe<BusinessTypeOptions>;
  /** Account number linked to this business. */
  linkedAccountNumber: Scalars["ID"];
  name: Scalars["String"];
  number: Scalars["String"];
};

/** An enumeration. */
export enum BusinessTypeOptions {
  /** A business account where the company type is charity. */
  Charity = "CHARITY",
  /** A business account for a church or other religious organisation. */
  Church = "CHURCH",
  /** A business account for a government institution. */
  Government = "GOVERNMENT",
  /** A business account where the company type is limited. */
  Limited = "LIMITED",
  /** A business account where the company type is limited liability partnership. */
  LimitedLiabilityPartnership = "LIMITED_LIABILITY_PARTNERSHIP",
  /** A business account for a non-profit organisation. */
  NonProfit = "NON_PROFIT",
  /** A business account where the company type is partnership. */
  Partnership = "PARTNERSHIP",
  /** A business account where the company type is proprietary limited. */
  ProprietaryLimitedCompany = "PROPRIETARY_LIMITED_COMPANY",
  /** A business account where the company type is public limited. */
  PublicLimitedCompany = "PUBLIC_LIMITED_COMPANY",
  /** A business account where the company type is sole trader. */
  SoleTrader = "SOLE_TRADER",
  /** A business account where the company has a trading name to carry out its business activities. */
  TradingAs = "TRADING_AS",
  /** A business account where the company type is a trust. */
  Trust = "TRUST",
}

/** The button interface. */
export type ButtonInterface = {
  /** The action to perform when the button is pressed. */
  buttonAction: ActionType;
  /** The button style. */
  buttonStyle?: Maybe<ButtonStyle>;
  /** Title text of the button. */
  title: Scalars["String"];
};

export enum ButtonStyle {
  Primary = "PRIMARY",
  Secondary = "SECONDARY",
}

export enum ButtonVariance {
  Filled = "FILLED",
  Outlined = "OUTLINED",
  TextOnly = "TEXT_ONLY",
}

/** Input required to cancel a LeaveSupplier journey. */
export type CancelLeaveSupplierInput = {
  /** The ID of the LeaveSupplier process to cancel. */
  leaveSupplierProcessId: Scalars["ID"];
  /** The reason for the cancellation. */
  reason?: InputMaybe<Scalars["String"]>;
};

/**
 * The possible errors that can be raised are:
 *
 * - KT-CT-3924: Unauthorized.
 * - KT-CT-3954: Payment cancellation failed.
 * - KT-CT-3955: Payment cannot be cancelled.
 * - KT-CT-3956: Temporary error occurred.
 * - KT-CT-1113: Disabled GraphQL field requested.
 * - KT-CT-1111: Unauthorized.
 * - KT-CT-1112: 'Authorization' header not provided.
 */
export type CancelPayment = {
  __typename?: "CancelPayment";
  /** The cancelled payment. */
  payment?: Maybe<AccountPaymentType>;
  possibleErrors?: Maybe<Array<Maybe<PossibleErrorType>>>;
};

export type CancelPaymentInput = {
  /** The account number. */
  accountNumber: Scalars["ID"];
  /** The ID of the payment to cancel. */
  paymentId: Scalars["ID"];
  /** Reason for cancelling the payment. */
  reason?: InputMaybe<Scalars["String"]>;
};

/**
 * Cancel a repayment or refund request.
 *
 * The possible errors that can be raised are:
 *
 * - KT-CT-4231: Unauthorized.
 * - KT-CT-3930: The repayment or refund request does not exist.
 * - KT-CT-3931: This repayment or refund request cannot be cancelled.
 * - KT-CT-1113: Disabled GraphQL field requested.
 */
export type CancelRepaymentRequest = {
  __typename?: "CancelRepaymentRequest";
  possibleErrors?: Maybe<Array<Maybe<PossibleErrorType>>>;
  /** The cancelled repayment/refund request. */
  repaymentRequest?: Maybe<CancelRepaymentRequestOutputType>;
};

export type CancelRepaymentRequestInputType = {
  /** The id of the request to be cancelled. */
  requestId: Scalars["String"];
};

/** Output from cancelling a repayment or refund request. */
export type CancelRepaymentRequestOutputType = {
  __typename?: "CancelRepaymentRequestOutputType";
  /** The ID of the cancelled request. */
  requestId?: Maybe<Scalars["String"]>;
  /** The current status of the cancelled request. */
  status?: Maybe<RepaymentRequestStatus>;
};

/**
 * Cancel onboarding of a device with SmartFlex.
 *
 * The possible errors that can be raised are:
 *
 * - KT-CT-4371: Onboarding wizard ID is invalid.
 * - KT-CT-4372: Simultaneous attempts to update onboarding process.
 * - KT-CT-1113: Disabled GraphQL field requested.
 * - KT-CT-1111: Unauthorized.
 * - KT-CT-1112: 'Authorization' header not provided.
 */
export type CancelSmartFlexOnboarding = {
  __typename?: "CancelSmartFlexOnboarding";
  possibleErrors?: Maybe<Array<Maybe<PossibleErrorType>>>;
  /** The wizard created for onboarding the device with SmartFlex. */
  wizard?: Maybe<SmartFlexOnboardingWizard>;
};

export type CancelSmartFlexOnboardingInput = {
  /** The ID of the SmartFlex onboarding wizard to cancel. */
  wizardId: Scalars["ID"];
};

/** A card containing a list of items */
export type CardComponentType = AccessibilityInterface &
  IdentifiableInterface & {
    __typename?: "CardComponentType";
    /** Whether the element is hidden from view. */
    accessibilityHidden?: Maybe<Scalars["Boolean"]>;
    /** Accessible description of the element. */
    accessibilityLabel?: Maybe<Scalars["String"]>;
    /** Unique identifier of the object. */
    id?: Maybe<Scalars["ID"]>;
    /** The list of components. */
    items: Array<Maybe<CardItemType>>;
    /** The name of the object's type. */
    typename?: Maybe<Scalars["String"]>;
  };

/** Items are sections making up a card. */
export type CardItemType =
  | AnimationType
  | ImageType
  | PillButtonType
  | RectangularButtonType
  | TextType;

export type CarouselItemType = IdentifiableInterface & {
  __typename?: "CarouselItemType";
  /** The list of cards. */
  cards: Array<Maybe<CardComponentType>>;
  /** Unique identifier of the object. */
  id?: Maybe<Scalars["ID"]>;
  /** The name of the object's type. */
  typename?: Maybe<Scalars["String"]>;
};

/** An enumeration. */
export enum Category {
  DriversLicense = "DRIVERS_LICENSE",
  Ein = "EIN",
  EnphaseParticipationAgreement = "ENPHASE_PARTICIPATION_AGREEMENT",
  EsiId = "ESI_ID",
  ExEnergyProviderStatement = "EX_ENERGY_PROVIDER_STATEMENT",
  FileAttachmentCustomerSignature = "FILE_ATTACHMENT_CUSTOMER_SIGNATURE",
  IntelligentOctopusTac = "INTELLIGENT_OCTOPUS_TAC",
  LeaseAgreement = "LEASE_AGREEMENT",
  NavigatorLetterOfAuthorization = "NAVIGATOR_LETTER_OF_AUTHORIZATION",
  TexasApplicationForTaxExemption = "TEXAS_APPLICATION_FOR_TAX_EXEMPTION",
  TexasSecretaryOfStateRegistration = "TEXAS_SECRETARY_OF_STATE_REGISTRATION",
  UploadLetterOfAuthorization = "UPLOAD_LETTER_OF_AUTHORIZATION",
  W9 = "W9",
}

/** Input type for changing the payment schedule type for an account. Requires an `account_number` and `schedule_type`. The `ledger_id` is an optional field. If not given, the default ledger of the account is used. */
export type ChangePaymentScheduleInput = {
  /** Account number to change the schedule of. */
  accountNumber: Scalars["String"];
  /** ID of the ledger you wish to change the schedule type for. If not given, the default ledger will be chosen. */
  ledgerId?: InputMaybe<Scalars["Int"]>;
  /** Type of schedule to change to. */
  scheduleType: PaymentScheduleTypeChoices;
};

/**
 * Mutation for modifying autopay choices from CARD, DIRECT_DEBIT, or MANUAL.
 *
 * The possible errors that can be raised are:
 *
 * - KT-CT-6623: Unauthorized.
 * - KT-CT-3822: Unauthorized.
 * - KT-US-3915: Account must have an active payment instruction of the given type.
 * - KT-US-3914: Prepay account must not be Manual pay.
 * - KT-CT-3815: No active payment schedule found for this account.
 * - KT-CT-1113: Disabled GraphQL field requested.
 * - KT-CT-1111: Unauthorized.
 * - KT-CT-1112: 'Authorization' header not provided.
 */
export type ChangePaymentScheduleType = {
  __typename?: "ChangePaymentScheduleType";
  /** Date that changes made will go into effect. */
  changesValidOn?: Maybe<Scalars["Date"]>;
  possibleErrors?: Maybe<Array<Maybe<PossibleErrorType>>>;
  /** Success flag whether or not the schedule type has been updated. */
  typeUpdated?: Maybe<Scalars["Boolean"]>;
};

/** A charge to the customer. */
export type Charge = TransactionType & {
  __typename?: "Charge";
  /** Unique identifier of the account the transaction belongs to. */
  accountNumber?: Maybe<Scalars["String"]>;
  /**
   * Gross amount including tax (when payable). Refer to the `amounts` field for a breakdown of this information.
   * @deprecated The 'amount' field is deprecated.
   *
   * Use `amounts` instead for a breakdown of the relevant net, tax, and gross amounts.
   *
   * - Marked as deprecated on 2023-12-06.
   * - Will be removed on 2024-06-01.
   */
  amount?: Maybe<Scalars["Int"]>;
  /** The net, tax and gross amounts for the transaction. Note: for payments and repayments, only the net amount is returned. */
  amounts?: Maybe<TransactionAmountType>;
  /** The customer's resulting balance after this transaction has been applied, in the smallest unit of currency. */
  balanceCarriedForward?: Maybe<Scalars["Int"]>;
  /** The unique identifier for the most recent billing document linked with the transaction.Note: a transaction may be linked with multiple documents, but this field will only return the identifier for the most recent billing document. */
  billingDocumentIdentifier?: Maybe<Scalars["ID"]>;
  /** If this charge is for consumption of a good or service, this field will contain details of how much was consumed. Omitted in cases where the charge is not for consumption, or where consumption information is not available (e.g. for some older records). */
  consumption?: Maybe<Consumption>;
  /** The date time when the transaction is created. */
  createdAt?: Maybe<Scalars["DateTime"]>;
  /** Returns True if the transaction is linked with a statement. */
  hasStatement?: Maybe<Scalars["Boolean"]>;
  id?: Maybe<Scalars["ID"]>;
  /**
   * Deprecated.
   * @deprecated The 'isAccountCharge' field is deprecated.
   *
   * This information is provided by the __typename introspection query.
   *
   * - Marked as deprecated on 2020-06-19.
   * - Will be removed on 2022-11-15.
   */
  isAccountCharge?: Maybe<Scalars["Boolean"]>;
  /**
   * Deprecated.
   * @deprecated The 'isAccountPayment' field is deprecated.
   *
   * This information is provided by the __typename introspection query.
   *
   * - Marked as deprecated on 2020-06-19.
   * - Will be removed on 2022-11-15.
   */
  isAccountPayment?: Maybe<Scalars["Boolean"]>;
  /**
   * Deprecated.
   * @deprecated The 'isCredit' field is deprecated.
   *
   * This information is provided by the __typename introspection query.
   *
   * - Marked as deprecated on 2020-06-19.
   * - Will be removed on 2022-11-15.
   */
  isCredit?: Maybe<Scalars["Boolean"]>;
  /** Whether the statement this transaction is on has been held. A held statement is not sent to a customer automatically, but is instead marked for manual attention by operations staff.  Returns False if a statement is not linked with the transaction. */
  isHeld?: Maybe<Scalars["Boolean"]>;
  /** Whether this transaction has been issued on any billing document.Note: Look for the most recently issued transaction instead of looking through all transactions as some accounts may have initial transactions that were not issued.This will return False if the transaction is not associated with any billing documents. */
  isIssued?: Maybe<Scalars["Boolean"]>;
  isReversed: Scalars["Boolean"];
  /** Returns the note field value for the transaction, which contains additional info. */
  note?: Maybe<Scalars["String"]>;
  postedDate?: Maybe<Scalars["Date"]>;
  /** Returns the reason. */
  reasonCode?: Maybe<Scalars["String"]>;
  /**
   * Returns None if a statement is not linked with the transaction.
   * @deprecated The 'statementId' field is deprecated.
   *
   * Use `billingDocumentIdentifier` instead.
   *
   * - Marked as deprecated on 2023-11-30.
   * - Will be removed on 2024-06-01.
   */
  statementId?: Maybe<Scalars["ID"]>;
  title?: Maybe<Scalars["String"]>;
};

export type ChargePointDevicesType = {
  __typename?: "ChargePointDevicesType";
  make?: Maybe<Scalars["String"]>;
  models?: Maybe<Array<Maybe<ChargePointModelsType>>>;
};

export type ChargePointModelsType = {
  __typename?: "ChargePointModelsType";
  model?: Maybe<Scalars["String"]>;
  powerInKw?: Maybe<Array<Maybe<Scalars["String"]>>>;
};

export type ChargePointVariantModelType = {
  __typename?: "ChargePointVariantModelType";
  /** Supply amperage. Used in markets where customers are more familiar with amps than power output. */
  amperage?: Maybe<Scalars["Int"]>;
  /** Shows the availability status of an integration. */
  integrationStatus?: Maybe<IntegrationStatus>;
  isIntegrationLive?: Maybe<Scalars["Boolean"]>;
  model?: Maybe<Scalars["String"]>;
  powerInKw?: Maybe<Scalars["Decimal"]>;
  variantId?: Maybe<Scalars["Int"]>;
};

export type ChargePointVariantType = {
  __typename?: "ChargePointVariantType";
  make?: Maybe<Scalars["String"]>;
  models?: Maybe<Array<Maybe<ChargePointVariantModelType>>>;
};

export type ChargeReasonType = {
  __typename?: "ChargeReasonType";
  /** The charge reason code. */
  code?: Maybe<Scalars["String"]>;
  /** The charge reason display text. */
  display?: Maybe<Scalars["String"]>;
  /** The group the charge reason belongs to (if applicable). */
  group?: Maybe<Scalars["String"]>;
  /** Whether the charge reason is deprecated. */
  isDeprecated?: Maybe<Scalars["Boolean"]>;
  /** Whether the charge reason is hidden. */
  isHidden?: Maybe<Scalars["Boolean"]>;
  /** Whether the charge reason is sales tax exempt. */
  isTaxExempt?: Maybe<Scalars["Boolean"]>;
};

/** This field is a connection type. Connections are used to implement [cursor based pagination](https://graphql.org/learn/pagination/#pagination-and-edges). */
export type ChargesBreakdownConnectionTypeConnection = {
  __typename?: "ChargesBreakdownConnectionTypeConnection";
  /** Number of nodes in the edge. */
  edgeCount: Scalars["Int"];
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<ChargesBreakdownConnectionTypeEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  /** Total number of nodes. */
  totalCount: Scalars["Int"];
};

/** A Relay edge containing a `ChargesBreakdownConnectionType` and its cursor. */
export type ChargesBreakdownConnectionTypeEdge = {
  __typename?: "ChargesBreakdownConnectionTypeEdge";
  /** A cursor for use in pagination */
  cursor: Scalars["String"];
  /** The item at the end of the edge */
  node?: Maybe<ChargesBreakdownType>;
};

export type ChargesBreakdownType = {
  __typename?: "ChargesBreakdownType";
  /** Charge in minor currency. */
  charge?: Maybe<Scalars["Int"]>;
  /** The end date of the period the charge is for (exclusive). */
  periodEnd?: Maybe<Scalars["Date"]>;
  /** The start date of the period the charge is for (inclusive). */
  periodStart?: Maybe<Scalars["Date"]>;
};

/** An enumeration. */
export enum ClientType {
  App = "APP",
  Web = "WEB",
}

/** Closes the screen. */
export type CloseActionType = AccessibilityInterface &
  ActionInterface &
  IdentifiableInterface & {
    __typename?: "CloseActionType";
    /** Whether the element is hidden from view. */
    accessibilityHidden?: Maybe<Scalars["Boolean"]>;
    /** Accessible description of the element. */
    accessibilityLabel?: Maybe<Scalars["String"]>;
    /** Unique identifier of the object. */
    id?: Maybe<Scalars["ID"]>;
    /** The name of the action object's type. */
    typeName?: Maybe<Scalars["String"]>;
    /** The name of the object's type. */
    typename?: Maybe<Scalars["String"]>;
  };

/**
 * Close the Open Print Batch if any.
 *
 * The possible errors that can be raised are:
 *
 * - KT-CT-9010: Invalid data.
 * - KT-CT-1113: Disabled GraphQL field requested.
 * - KT-CT-1111: Unauthorized.
 * - KT-CT-1112: 'Authorization' header not provided.
 */
export type CloseOpenPrintBatch = {
  __typename?: "CloseOpenPrintBatch";
  possibleErrors?: Maybe<Array<Maybe<PossibleErrorType>>>;
  printBatch?: Maybe<PrintBatchType>;
};

export enum CodeType {
  ReferralCode = "REFERRAL_CODE",
}

/**
 * Collects the ad hoc ACH payment.
 *
 * The possible errors that can be raised are:
 *
 * - KT-CT-3923: Unauthorized.
 * - KT-US-3911: No active direct debit instruction found for this account.
 * - KT-US-3916: Payment could not be collected for the given account number.
 * - KT-CT-1113: Disabled GraphQL field requested.
 * - KT-CT-1111: Unauthorized.
 * - KT-CT-1112: 'Authorization' header not provided.
 */
export type CollectAdHocAchPayment = {
  __typename?: "CollectAdHocACHPayment";
  payment?: Maybe<CollectAdHocAchPaymentOutput>;
  possibleErrors?: Maybe<Array<Maybe<PossibleErrorType>>>;
};

/** Input for collecting the ad hoc ACH payment */
export type CollectAdHocAchPaymentInput = {
  accountNumber: Scalars["String"];
  amount: Scalars["Int"];
  description: Scalars["String"];
  idempotencyKey: Scalars["String"];
  paymentDate: Scalars["Date"];
};

/** Output for collecting the ad hoc ACH payment. */
export type CollectAdHocAchPaymentOutput = {
  __typename?: "CollectAdHocACHPaymentOutput";
  wasScheduled?: Maybe<Scalars["Boolean"]>;
};

/**
 * Collects ad hoc payment for an existing payment instruction.
 *
 * The possible errors that can be raised are:
 *
 * - KT-CT-3923: Unauthorized.
 * - KT-US-3912: Invalid reference or given account number is invalid.
 * - KT-US-3913: No active instruction found for this account.
 * - KT-US-3916: Payment could not be collected for the given account number.
 * - KT-CT-1113: Disabled GraphQL field requested.
 * - KT-CT-1111: Unauthorized.
 * - KT-CT-1112: 'Authorization' header not provided.
 */
export type CollectAdHocPaymentForInstruction = {
  __typename?: "CollectAdHocPaymentForInstruction";
  payment?: Maybe<CollectAdHocPaymentForInstructionOutput>;
  possibleErrors?: Maybe<Array<Maybe<PossibleErrorType>>>;
};

/** Input for collecting an ad hoc for a specific payment instruction */
export type CollectAdHocPaymentForInstructionInput = {
  accountNumber: Scalars["String"];
  amount: Scalars["Int"];
  description: Scalars["String"];
  idempotencyKey: Scalars["String"];
  paymentDate: Scalars["Date"];
  reference: Scalars["String"];
};

/** Output for collecting ad hoc payment for an existing instruction */
export type CollectAdHocPaymentForInstructionOutput = {
  __typename?: "CollectAdHocPaymentForInstructionOutput";
  wasScheduled?: Maybe<Scalars["Boolean"]>;
};

/**
 * Collect deposit for the given account.
 *
 * The possible errors that can be raised are:
 *
 * - KT-CT-4177: Unauthorized.
 * - KT-CT-5711: No collection is required.
 * - KT-CT-5712: Deposit agreement does not exist or has not been accepted.
 * - KT-CT-1113: Disabled GraphQL field requested.
 * - KT-CT-1111: Unauthorized.
 * - KT-CT-1112: 'Authorization' header not provided.
 */
export type CollectDeposit = {
  __typename?: "CollectDeposit";
  payment?: Maybe<CollectDepositOutput>;
  possibleErrors?: Maybe<Array<Maybe<PossibleErrorType>>>;
};

export type CollectDepositInput = {
  accountNumber: Scalars["String"];
  depositKey: Scalars["String"];
  idempotencyKey: Scalars["String"];
};

export type CollectDepositOutput = {
  __typename?: "CollectDepositOutput";
  paymentDate?: Maybe<Scalars["Date"]>;
  status?: Maybe<CollectDepositStatusChoices>;
};

export enum CollectDepositStatusChoices {
  Approved = "APPROVED",
  Cancelled = "CANCELLED",
  Cleared = "CLEARED",
  Failed = "FAILED",
  HeldForReview = "HELD_FOR_REVIEW",
  None = "NONE",
  Pending = "PENDING",
  Requested = "REQUESTED",
  Scheduled = "SCHEDULED",
}

/**
 * The possible errors that can be raised are:
 *
 * - KT-CT-3932: Invalid data.
 * - KT-CT-1113: Disabled GraphQL field requested.
 * - KT-CT-1111: Unauthorized.
 * - KT-CT-1112: 'Authorization' header not provided.
 */
export type CollectPayment = {
  __typename?: "CollectPayment";
  /** Details about the collected payment. Note that we might not be able to collect the payment (e.g. if there is no usable payment instruction), in which case the status of the returned payment might be failed or cancelled. */
  payment?: Maybe<AccountPaymentType>;
  possibleErrors?: Maybe<Array<Maybe<PossibleErrorType>>>;
};

export type CollectPaymentInput = {
  /** The account number. */
  accountNumber: Scalars["String"];
  /** The payment amount (in cents). */
  amount: Scalars["Int"];
  /** The type of the payment instruction. */
  collectionMethod?: InputMaybe<PaymentType>;
  /** The reason a payment is being collected, for internal audit purposes. */
  description: Scalars["String"];
  idempotencyKey: Scalars["String"];
  /** The ID of the ledger on which to collect the payment. */
  ledgerId: Scalars["ID"];
  /** The date to attempt to take the payment. Cannot be a date in the past. Payment will be collected on the requested date or as soon as possible after that date. */
  paymentDate: Scalars["Date"];
};

export enum CollectionCampaignType {
  None = "NONE",
}

export enum CollectionMethod {
  Card = "CARD",
  DirectDebit = "DIRECT_DEBIT",
}

/** Options for Collection Process Record Completion types. `ENDED`: ended`WITHDRAWN`: withdrawn`CANCELLED`: cancelled`NEVER_ACTIONED`: never_actioned */
export enum CollectionProcessRecordCompletionTypeChoices {
  Cancelled = "CANCELLED",
  Ended = "ENDED",
  NeverActioned = "NEVER_ACTIONED",
  Withdrawn = "WITHDRAWN",
}

/** An enumeration. */
export enum CollectionProcessRecordStatusTypes {
  Active = "ACTIVE",
  Complete = "COMPLETE",
  Pending = "PENDING",
}

export type CollectiveBillType = BillInterface & {
  __typename?: "CollectiveBillType";
  attachments?: Maybe<BillingAttachmentConnectionTypeConnection>;
  /** The type of the bill. */
  billType?: Maybe<BillTypeEnum>;
  /** Constituents of the bill. */
  constituents?: Maybe<ConstituentConnection>;
  /** The date of the bill is covered from. */
  fromDate?: Maybe<Scalars["Date"]>;
  /** The ID of the bill. */
  id?: Maybe<Scalars["ID"]>;
  /** The date the bill was sent to the customer. */
  issuedDate?: Maybe<Scalars["Date"]>;
  /**
   * Requesting this field generates a temporary URL at which bill is available.
   *         This URL will expire after approximately an hour.  It is intended for redirection purposes,
   *         NOT persistence in any form (e.g. inclusion in emails or the body of a web page).
   *         This field can raise an error with errorClass NOT_FOUND if the bill document has not
   *         been created/issued yet. This field is deprecated use 'attachments' field instead.
   * @deprecated The 'temporaryUrl' field is deprecated.
   *
   * This field is deprecated. Use the 'attachments' field instead.
   *
   * - Marked as deprecated on 2024-09-16.
   * - Will be removed on 2025-09-01.
   */
  temporaryUrl?: Maybe<Scalars["String"]>;
  /** The date of the bill is covered to. */
  toDate?: Maybe<Scalars["Date"]>;
};

export type CollectiveBillTypeAttachmentsArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
};

export type CollectiveBillTypeConstituentsArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
};

export type CombinedRateLimitInformation = {
  __typename?: "CombinedRateLimitInformation";
  /** Information about the current state of the rate limiting for rate limited fields at the time of the request. */
  fieldSpecificRateLimits: FieldSpecificRateLimitInformationConnectionTypeConnection;
  /** Information about points-allowance rate limit for viewer. */
  pointsAllowanceRateLimit?: Maybe<PointsAllowanceRateLimitInformation>;
};

export type CombinedRateLimitInformationFieldSpecificRateLimitsArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  fields?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
};

export type CommonSupplyPointInterface = {
  /** The external identifier of the supply point. */
  externalIdentifier?: Maybe<Scalars["String"]>;
  /** The ID of the supply point. */
  id: Scalars["ID"];
  /** The market this supply point belongs to. */
  marketName: Scalars["String"];
};

/** The method the account has specified they prefer we contact them */
export enum CommsDeliveryPreference {
  Email = "EMAIL",
  PostalMail = "POSTAL_MAIL",
}

/**
 * Complete the auth flow step.
 *
 * The possible errors that can be raised are:
 *
 * - KT-CT-4371: Onboarding wizard ID is invalid.
 * - KT-CT-4372: Simultaneous attempts to update onboarding process.
 * - KT-CT-4375: Incorrect or missing parameters for SmartFlex onboarding step.
 * - KT-CT-4376: Unable to complete onboarding step. Please try agan later.
 * - KT-CT-4377: Invalid onboarding step ID.
 * - KT-CT-4378: Invalid input or step id. Please make sure you are using the correct step id and providing the expected input params.
 * - KT-CT-4379: Vehicle is not ready for a test charge.
 * - KT-CT-1113: Disabled GraphQL field requested.
 * - KT-CT-1111: Unauthorized.
 * - KT-CT-1112: 'Authorization' header not provided.
 */
export type CompleteAuthFlowForSmartFlexOnboarding = {
  __typename?: "CompleteAuthFlowForSmartFlexOnboarding";
  possibleErrors?: Maybe<Array<Maybe<PossibleErrorType>>>;
  /** The wizard created for onboarding the device with SmartFlex. */
  wizard?: Maybe<SmartFlexOnboardingWizard>;
};

export type CompleteAuthFlowInput = {
  /** The entire continuation URI returned by the vendor. */
  continuationUri: Scalars["String"];
  /** The ID of the SmartFlex onboarding step to complete. */
  stepId: Scalars["ID"];
  /** The ID of the SmartFlex onboarding wizard. */
  wizardId: Scalars["ID"];
};

export type CompleteDeviceRegistrationInput = {
  /** Account number that the device is registered to. */
  accountNumber: Scalars["String"];
  /** External reference in the third-party system to identify the device. */
  externalDeviceIdentifier: Scalars["String"];
  /** Postcode of the property (linked to the account) that the device is registered to. */
  postalCode: Scalars["String"];
};

export type CompleteSmartFlexOnboardingStepInput = {
  /** The ID of the SmartFlex onboarding step to complete. */
  stepId: Scalars["ID"];
  /** The ID of the SmartFlex onboarding wizard. */
  wizardId: Scalars["ID"];
};

/**
 * Complete the Tesla setup virtual key onboarding step.
 *
 * The possible errors that can be raised are:
 *
 * - KT-CT-4371: Onboarding wizard ID is invalid.
 * - KT-CT-4372: Simultaneous attempts to update onboarding process.
 * - KT-CT-4375: Incorrect or missing parameters for SmartFlex onboarding step.
 * - KT-CT-4376: Unable to complete onboarding step. Please try agan later.
 * - KT-CT-4377: Invalid onboarding step ID.
 * - KT-CT-4378: Invalid input or step id. Please make sure you are using the correct step id and providing the expected input params.
 * - KT-CT-4379: Vehicle is not ready for a test charge.
 * - KT-CT-1113: Disabled GraphQL field requested.
 * - KT-CT-1111: Unauthorized.
 * - KT-CT-1112: 'Authorization' header not provided.
 */
export type CompleteTeslaSetupVirtualKeyForSmartFlexOnboarding = {
  __typename?: "CompleteTeslaSetupVirtualKeyForSmartFlexOnboarding";
  possibleErrors?: Maybe<Array<Maybe<PossibleErrorType>>>;
  /** The wizard created for onboarding the device with SmartFlex. */
  wizard?: Maybe<SmartFlexOnboardingWizard>;
};

/**
 * A list of components which comprise a screen.
 *
 * This is a legacy type; GenericBackendScreen should be preferred. This is because API clients
 * should not need to explicitly query for screen attributes like `items` - these fields are
 * embedded in the screenData field.
 */
export type ComponentListType = AccessibilityInterface &
  BackendScreenInterface &
  IdentifiableInterface & {
    __typename?: "ComponentListType";
    /** Whether the element is hidden from view. */
    accessibilityHidden?: Maybe<Scalars["Boolean"]>;
    /** Accessible description of the element. */
    accessibilityLabel?: Maybe<Scalars["String"]>;
    /** Unique identifier of the object. */
    id?: Maybe<Scalars["ID"]>;
    /**
     * The list of components.
     * @deprecated The 'items' field is deprecated.
     *
     * Access `items` via `screenData` instead.
     *
     * - Marked as deprecated on 2024-07-02.
     * - Will be removed on 2024-08-01.
     */
    items: Array<Maybe<ItemType>>;
    /** The name of the screen. */
    name: Scalars["String"];
    /** The refresh / polling frequency in milliseconds. */
    refreshFrequency?: Maybe<Scalars["Int"]>;
    /** Serialized JSON representation of the screen. */
    screenData?: Maybe<Scalars["String"]>;
    /** The name of the object's type. */
    typename?: Maybe<Scalars["String"]>;
  };

export type ConciseRatesBaseInput = {
  esiId?: InputMaybe<Scalars["String"]>;
  loadZone?: InputMaybe<LoadZone>;
  serviceProvider?: InputMaybe<ServiceProvider>;
};

export type ConciseRatesInput = {
  esiId?: InputMaybe<Scalars["String"]>;
  kwhUsage?: InputMaybe<Scalars["Decimal"]>;
  loadZone?: InputMaybe<LoadZone>;
  serviceProvider?: InputMaybe<ServiceProvider>;
};

export type ConciseRatesOutput = {
  __typename?: "ConciseRatesOutput";
  /** Discount rate for Electric Vehicle (EV). */
  evDiscountRate?: Maybe<Scalars["Decimal"]>;
  kwhUsage?: Maybe<Scalars["Decimal"]>;
  loadZone?: Maybe<LoadZone>;
  loadZoneConsumptionRate?: Maybe<Scalars["Decimal"]>;
  loadZoneConsumptionRateUnitType?: Maybe<UnitType>;
  loadZoneDayTimeConsumptionRate?: Maybe<Scalars["Decimal"]>;
  loadZoneDayTimeRateUnitType?: Maybe<UnitType>;
  loadZoneNightTimeConsumptionRate?: Maybe<Scalars["Decimal"]>;
  loadZoneNightTimeRateUnitType?: Maybe<UnitType>;
  monthlySubscriptionFees?: Maybe<Scalars["Decimal"]>;
  serviceProvider?: Maybe<ServiceProvider>;
  serviceProviderConsumptionRate?: Maybe<Scalars["Decimal"]>;
  serviceProviderConsumptionRateUnitType?: Maybe<UnitType>;
  serviceProviderStandingRate?: Maybe<Scalars["Decimal"]>;
  serviceProviderStandingRateUnitType?: Maybe<UnitType>;
  /** Discount rate for thermostats. */
  thermostatDiscountRate?: Maybe<Scalars["Decimal"]>;
  totalApplicableDayRate?: Maybe<Scalars["Decimal"]>;
  /**
   * Sum of total applicable day rate rate with the subscription fees.
   * Matches with rate/kwh usage on the EFL.
   */
  totalApplicableDayRateWithMonthlySubscriptionFees?: Maybe<Scalars["Decimal"]>;
  totalApplicableNightRate?: Maybe<Scalars["Decimal"]>;
  /**
   * Sum of total applicable night rate rate with the subscription fees.
   * Matches with rate/kwh usage on the EFL.
   */
  totalApplicableNightRateWithMonthlySubscriptionFees?: Maybe<
    Scalars["Decimal"]
  >;
  totalApplicableRate?: Maybe<Scalars["Decimal"]>;
  /** Sum of total EV rate. */
  totalApplicableRateWithEv?: Maybe<Scalars["Decimal"]>;
  /**
   * Sum of total applicable rate with the subscription fees.
   * Matches with rate/kwh usage on the EFL.
   */
  totalApplicableRateWithMonthlySubscriptionFees?: Maybe<Scalars["Decimal"]>;
  /** Sum of total EV rate with the subscription fees. Matches with rate/kwh usage on the EFL. */
  totalApplicableRateWithMonthlySubscriptionFeesEv?: Maybe<Scalars["Decimal"]>;
  /** Sum of total thermostats rate with the subscription fees. Matches with rate/kwh usage on the EFL. */
  totalApplicableRateWithMonthlySubscriptionFeesThermostat?: Maybe<
    Scalars["Decimal"]
  >;
  /** Sum of total thermostats day rate. */
  totalApplicableRateWithThermostat?: Maybe<Scalars["Decimal"]>;
};

/** A consent given by a signing identity for a consent type. */
export type ConsentType = {
  __typename?: "ConsentType";
  /** Unique code given to this consent type. */
  code?: Maybe<Scalars["String"]>;
  /** id of the consent. */
  id?: Maybe<Scalars["BigInt"]>;
  /** Value of the consent. */
  value?: Maybe<ConsentValue>;
};

export type ConsentTypeInput = {
  /** The code of the consent type. */
  consentTypeCode: Scalars["String"];
  value?: InputMaybe<ConsentValue>;
};

/** An enumeration. */
export enum ConsentValue {
  Accepted = "ACCEPTED",
  Pending = "PENDING",
  Rejected = "REJECTED",
  Unknown = "UNKNOWN",
}

/** This field is a connection type. Connections are used to implement [cursor based pagination](https://graphql.org/learn/pagination/#pagination-and-edges). */
export type ConstituentConnection = {
  __typename?: "ConstituentConnection";
  /** Number of nodes in the edge. */
  edgeCount: Scalars["Int"];
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<ConstituentEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  /** Total number of nodes. */
  totalCount: Scalars["Int"];
};

/** A Relay edge containing a `Constituent` and its cursor. */
export type ConstituentEdge = {
  __typename?: "ConstituentEdge";
  /** A cursor for use in pagination */
  cursor: Scalars["String"];
  /** The item at the end of the edge */
  node?: Maybe<ConstituentInterface>;
};

export type ConstituentInterface = {
  /** The date of the constituent bill covered from. */
  fromDate?: Maybe<Scalars["Date"]>;
  /** The ID of the constituent bill. */
  id?: Maybe<Scalars["ID"]>;
  /** The date of the constituent bill covered to. */
  toDate?: Maybe<Scalars["Date"]>;
};

export type Consumption = {
  __typename?: "Consumption";
  endDate?: Maybe<Scalars["Date"]>;
  quantity?: Maybe<Scalars["Decimal"]>;
  startDate?: Maybe<Scalars["Date"]>;
  supplyCharge?: Maybe<Scalars["Int"]>;
  unit?: Maybe<ConsumptionUnit>;
  usageCost?: Maybe<Scalars["Int"]>;
};

/** This field is a connection type. Connections are used to implement [cursor based pagination](https://graphql.org/learn/pagination/#pagination-and-edges). */
export type ConsumptionBreakdownConnectionTypeConnection = {
  __typename?: "ConsumptionBreakdownConnectionTypeConnection";
  /** Number of nodes in the edge. */
  edgeCount: Scalars["Int"];
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<ConsumptionBreakdownConnectionTypeEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  /** Total number of nodes. */
  totalCount: Scalars["Int"];
};

/** A Relay edge containing a `ConsumptionBreakdownConnectionType` and its cursor. */
export type ConsumptionBreakdownConnectionTypeEdge = {
  __typename?: "ConsumptionBreakdownConnectionTypeEdge";
  /** A cursor for use in pagination */
  cursor: Scalars["String"];
  /** The item at the end of the edge */
  node?: Maybe<ConsumptionBreakdownType>;
};

export type ConsumptionBreakdownType = {
  __typename?: "ConsumptionBreakdownType";
  /** Charges grouped per displayed period. */
  charges?: Maybe<ChargesBreakdownConnectionTypeConnection>;
  /** Name of the market the charges are for. */
  marketName?: Maybe<Scalars["String"]>;
};

export type ConsumptionBreakdownTypeChargesArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
};

export type ConsumptionRatesType = {
  __typename?: "ConsumptionRatesType";
  band?: Maybe<Scalars["String"]>;
  bandCategory?: Maybe<BandCategory>;
  bandSubcategory?: Maybe<Scalars["String"]>;
  currency?: Maybe<Scalars["String"]>;
  description?: Maybe<Scalars["String"]>;
  /** Returns a list of EFLs that are applicable to the resolved product. */
  efls?: Maybe<Array<Maybe<EflType>>>;
  id?: Maybe<Scalars["String"]>;
  loadZone?: Maybe<LoadZone>;
  /** Price per unit is returned in decimal format. For example: Decimal('0.10'). */
  pricePerUnit?: Maybe<Scalars["Decimal"]>;
  serviceProvider?: Maybe<ServiceProvider>;
  taxCode?: Maybe<Scalars["String"]>;
  taxable?: Maybe<Scalars["Boolean"]>;
  timeOfUse?: Maybe<TimeOfUse>;
  unitType?: Maybe<UnitType>;
};

export type ConsumptionRatesTypeEflsArgs = {
  language?: InputMaybe<EflLanguage>;
  loadZone?: InputMaybe<LoadZone>;
};

/** An enumeration. */
export enum ConsumptionUnit {
  Mj = "MJ",
  KWh = "kWh",
}

/** A single Contribution Agreement. */
export type ContributionAgreementType = {
  __typename?: "ContributionAgreementType";
  /** The start datetime of the agreement. */
  activeFrom?: Maybe<Scalars["DateTime"]>;
  /** The end datetime of the agreement, if any. */
  activeTo?: Maybe<Scalars["DateTime"]>;
  /** The amount contributed per interval. Note, this is in the smallest domination that the currecy support. e.g. Pence, Cents, Yen, etc. */
  amount?: Maybe<Scalars["Int"]>;
  /** The scheme to which the agreement contributes. */
  contributionScheme?: Maybe<ContributionSchemeType>;
  id: Scalars["ID"];
  /** The frequency of contributions. */
  interval?: Maybe<Interval>;
  /** The periods over which contributions have been made. */
  periods?: Maybe<ContributionPeriodConnection>;
};

/** A single Contribution Agreement. */
export type ContributionAgreementTypePeriodsArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
};

/** Pagination for contribution periods. */
export type ContributionPeriodConnection = {
  __typename?: "ContributionPeriodConnection";
  /** Number of nodes in the edge. */
  edgeCount: Scalars["Int"];
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<ContributionPeriodEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  /** Total number of nodes. */
  totalCount: Scalars["Int"];
};

/** A Relay edge containing a `ContributionPeriod` and its cursor. */
export type ContributionPeriodEdge = {
  __typename?: "ContributionPeriodEdge";
  /** A cursor for use in pagination */
  cursor: Scalars["String"];
  /** The item at the end of the edge */
  node?: Maybe<ContributionPeriodType>;
};

/** A single Contribution Period. */
export type ContributionPeriodType = {
  __typename?: "ContributionPeriodType";
  agreement: ContributionAgreementType;
  /** When the contribution was fulfilled */
  fulfilledAt?: Maybe<Scalars["DateTime"]>;
  id: Scalars["ID"];
  /** The datetime the marks the beginning of the period. */
  periodFrom?: Maybe<Scalars["DateTime"]>;
  /** The datetime the marks the end of the period. */
  periodTo?: Maybe<Scalars["DateTime"]>;
};

/** A single Contribution Scheme. */
export type ContributionSchemeType = {
  __typename?: "ContributionSchemeType";
  /** Is this scheme currently accepting contributions? */
  acceptingContributions: Scalars["Boolean"];
  code: Scalars["String"];
  displayName: Scalars["String"];
  id: Scalars["ID"];
  /** Are contributions to this scheme taxable? */
  taxable: Scalars["Boolean"];
};

export type CoordinatesType = {
  __typename?: "CoordinatesType";
  latitude?: Maybe<Scalars["Float"]>;
  longitude?: Maybe<Scalars["Float"]>;
};

export type CostOfChargeType = {
  __typename?: "CostOfChargeType";
  costOfChargeId?: Maybe<Scalars["String"]>;
  isSmartCharge?: Maybe<Scalars["Boolean"]>;
  krakenflexDeviceId?: Maybe<Scalars["String"]>;
  reportDate?: Maybe<Scalars["Date"]>;
  /** Consumption in kWh. */
  totalConsumption?: Maybe<Scalars["Float"]>;
  /** Cost in pence (excl. tax). */
  totalCostExclTax?: Maybe<Scalars["Float"]>;
  /** Cost in pence (incl. tax). */
  totalCostInclTax?: Maybe<Scalars["Float"]>;
};

/**
 * The possible errors that can be raised are:
 *
 * - KT-CT-7803: Received an invalid apiExceptionId.
 * - KT-CT-1113: Disabled GraphQL field requested.
 * - KT-CT-1111: Unauthorized.
 * - KT-CT-1112: 'Authorization' header not provided.
 */
export type CreateApiCall = {
  __typename?: "CreateAPICall";
  /** The created APICall. */
  apiCall?: Maybe<ApiCallType>;
  possibleErrors?: Maybe<Array<Maybe<PossibleErrorType>>>;
};

export type CreateApiCallInput = {
  /** The ID of the associated API exception, if any. */
  apiExceptionId?: InputMaybe<Scalars["Int"]>;
  /** Any optional useful context involved in the API call. */
  context?: InputMaybe<Scalars["JSONString"]>;
  /** The correlation id header from the HTTP request. */
  correlationId: Scalars["String"];
  /** The input data provided to the API, if any. */
  inputData?: InputMaybe<Scalars["JSONString"]>;
  /** The name of the operation associated with this call. */
  operationName: Scalars["String"];
  /** The response returned by the API. */
  response: Scalars["JSONString"];
};

/**
 * The possible errors that can be raised are:
 *
 * - KT-CT-7801: Received an invalid operationsTeamId.
 * - KT-CT-7802: The external identifier already exists.
 * - KT-CT-7805: Too many tags associated with this API Exception.
 * - KT-CT-7806: Cannot create duplicate tags for the same API exception.
 * - KT-CT-7811: Received an invalid assignedUserId.
 * - KT-CT-1113: Disabled GraphQL field requested.
 * - KT-CT-1111: Unauthorized.
 * - KT-CT-1112: 'Authorization' header not provided.
 */
export type CreateApiException = {
  __typename?: "CreateAPIException";
  /** The created APIException. */
  apiException?: Maybe<ApiExceptionType>;
  possibleErrors?: Maybe<Array<Maybe<PossibleErrorType>>>;
};

/**
 * The possible errors that can be raised are:
 *
 * - KT-CT-7803: Received an invalid apiExceptionId.
 * - KT-CT-1113: Disabled GraphQL field requested.
 * - KT-CT-1111: Unauthorized.
 * - KT-CT-1112: 'Authorization' header not provided.
 */
export type CreateApiExceptionEvent = {
  __typename?: "CreateAPIExceptionEvent";
  /** The created APIExceptionEvent. */
  apiExceptionEvent?: Maybe<ApiExceptionEventType>;
  possibleErrors?: Maybe<Array<Maybe<PossibleErrorType>>>;
};

export type CreateApiExceptionEventInput = {
  /** The ID of the associated API exception, if any. */
  apiExceptionId?: InputMaybe<Scalars["Int"]>;
  /** The event category. */
  category: Scalars["String"];
  /** Any optional useful context involved in the event. */
  context?: InputMaybe<Scalars["JSONString"]>;
  /** Any useful event description. */
  description: Scalars["String"];
  /** The event type. */
  eventType: Scalars["String"];
};

export type CreateApiExceptionInput = {
  /** The account number associated with the exception, if available. */
  accountNumber?: InputMaybe<Scalars["ID"]>;
  /** The ID of the user assigned to handle this exception.If no user is provided, no user will be assigned to the exception. */
  assignedUserId?: InputMaybe<Scalars["Int"]>;
  /** Category associated with this exception. Uses the default category if not provided. */
  category?: InputMaybe<ApiExceptionCategories>;
  /** The API client channel where the exception was triggered from. */
  channel: Scalars["String"];
  /** Contextual information about the exception, if any. */
  context?: InputMaybe<Scalars["JSONString"]>;
  /** The customer contact associated with the exception, if available. */
  customerContact?: InputMaybe<Scalars["String"]>;
  /** External identifier mapping an entity on the client's database. */
  externalIdentifier: Scalars["String"];
  /** The key date associated with the exception, if available. */
  keyDate?: InputMaybe<Scalars["Date"]>;
  /** The ID of an operations team to handle this exception. If no team is provided, no team will be assigned to the exception. */
  operationsTeamId?: InputMaybe<Scalars["Int"]>;
  /** The priority. Defaults to LOW if not provided. */
  priority?: InputMaybe<ApiExceptionPriority>;
  /** The resolution status. Defaults to UNASSIGNED if not provided. */
  resolutionStatus?: InputMaybe<ApiExceptionResolutionStatus>;
  /** The resolution type. Defaults to UNASSIGNED if not provided. */
  resolutionType?: InputMaybe<ApiExceptionResolutionType>;
  /** The supply point identifier associated with the exception, if available. */
  supplyPointIdentifier?: InputMaybe<Scalars["String"]>;
  /** Tags associated with this exception if any. */
  tags?: InputMaybe<Array<InputMaybe<ApiExceptionTags>>>;
  /** The user ID associated with the exception, if available. */
  userId?: InputMaybe<Scalars["Int"]>;
};

/**
 * The possible errors that can be raised are:
 *
 * - KT-CT-7803: Received an invalid apiExceptionId.
 * - KT-CT-1113: Disabled GraphQL field requested.
 * - KT-CT-1111: Unauthorized.
 * - KT-CT-1112: 'Authorization' header not provided.
 */
export type CreateApiExceptionNote = {
  __typename?: "CreateAPIExceptionNote";
  /** The created APIExceptionNote. */
  apiException?: Maybe<ApiExceptionType>;
  possibleErrors?: Maybe<Array<Maybe<PossibleErrorType>>>;
};

export type CreateApiExceptionNoteInput = {
  /** The ID of the associated API exception. */
  apiExceptionId: Scalars["ID"];
  /** The body of the note. */
  body: Scalars["String"];
};

/**
 * The possible errors that can be raised are:
 *
 * - KT-US-4101: Cannot create an account.
 * - KT-US-4116: Either salesSubchannel or salesAffiliateSubdomain is required.
 * - KT-US-4417: Received an invalid state code/name.
 * - KT-US-4118: Invalid US state.
 * - KT-CT-4123: Unauthorized.
 * - KT-CT-1113: Disabled GraphQL field requested.
 * - KT-CT-1111: Unauthorized.
 * - KT-CT-1112: 'Authorization' header not provided.
 */
export type CreateAccount = {
  __typename?: "CreateAccount";
  account?: Maybe<CreateAccountOutput>;
  possibleErrors?: Maybe<Array<Maybe<PossibleErrorType>>>;
};

/**
 * Add charges to an account.
 *
 * The possible errors that can be raised are:
 *
 * - KT-CT-5211: The charge reason with the requested code is deprecated.
 * - KT-CT-5212: The charge reason with the requested code does not exist.
 * - KT-CT-5213: Invalid data.
 * - KT-CT-1113: Disabled GraphQL field requested.
 * - KT-CT-1111: Unauthorized.
 * - KT-CT-1112: 'Authorization' header not provided.
 */
export type CreateAccountCharge = {
  __typename?: "CreateAccountCharge";
  accountCharge?: Maybe<AccountChargeType>;
  possibleErrors?: Maybe<Array<Maybe<PossibleErrorType>>>;
};

/** The input type for the account charge. */
export type CreateAccountChargeInput = {
  /** The account number. */
  accountNumber: Scalars["String"];
  /** Optional short note about account charge for customer display. */
  displayNote?: InputMaybe<Scalars["String"]>;
  /** The gross amount of the charge to be added. */
  grossAmount: Scalars["Int"];
  /** Any extra data that will be associated with account charge. */
  metadata?: InputMaybe<Scalars["JSONString"]>;
  /** Optional short note about account charge for internal use. */
  note?: InputMaybe<Scalars["String"]>;
  /** The reason why the charge is added to the account. This should be a valid charge reason code. */
  reason: Scalars["String"];
};

/**
 * Add credits to an account.
 *
 * The possible errors that can be raised are:
 *
 * - KT-CT-5315: Invalid data.
 * - KT-CT-5314: Invalid data.
 * - KT-CT-1113: Disabled GraphQL field requested.
 * - KT-CT-1111: Unauthorized.
 * - KT-CT-1112: 'Authorization' header not provided.
 */
export type CreateAccountCredit = {
  __typename?: "CreateAccountCredit";
  accountCredit?: Maybe<AccountCreditType>;
  possibleErrors?: Maybe<Array<Maybe<PossibleErrorType>>>;
};

/** The input type for the account credit. */
export type CreateAccountCreditInput = {
  /** The account number. */
  accountNumber: Scalars["String"];
  /** The gross amount of the credit to be created. */
  grossAmount: Scalars["Int"];
  /** Any extra data that will be associated with account credit. */
  metadata?: InputMaybe<Scalars["JSONString"]>;
  /** The net amount of the credit to be created. */
  netAmount: Scalars["Int"];
  /** Optional short note about account credit. */
  note?: InputMaybe<Scalars["String"]>;
  /** The reason why the credit is added to the account. */
  reason: AccountCreditReasonType;
  /** The sales tax amount of the credit to be created. */
  salesTaxAmount: Scalars["Int"];
};

export type CreateAccountFileAttachmentInput = {
  accountNumber: Scalars["String"];
  category: Category;
  clientMutationId?: InputMaybe<Scalars["String"]>;
  filename: Scalars["String"];
};

export type CreateAccountFileAttachmentPayload = {
  __typename?: "CreateAccountFileAttachmentPayload";
  clientMutationId?: Maybe<Scalars["String"]>;
  postRequest?: Maybe<UploadPostRequest>;
};

/** Input fields for creating an account. */
export type CreateAccountInput = {
  accountUser: AccountUserInput;
  billingAddress: BillingAddressInput;
  /** Quote code. */
  quoteCode?: InputMaybe<Scalars["String"]>;
  salesInformation: SalesInfoInput;
};

/**
 * The possible errors that can be raised are:
 *
 * - KT-CT-4123: Unauthorized.
 * - KT-CT-4180: Account note must be a valid string.
 * - KT-CT-1113: Disabled GraphQL field requested.
 * - KT-CT-1111: Unauthorized.
 * - KT-CT-1112: 'Authorization' header not provided.
 */
export type CreateAccountNote = {
  __typename?: "CreateAccountNote";
  /** Account, which has the added note. */
  account?: Maybe<AccountType>;
  possibleErrors?: Maybe<Array<Maybe<PossibleErrorType>>>;
};

export type CreateAccountNoteInput = {
  /** The account number. */
  accountNumber: Scalars["String"];
  /** Pin the note to account. */
  isPinned: Scalars["Boolean"];
  /** The note to add. */
  note: Scalars["String"];
};

export type CreateAccountOutput = {
  __typename?: "CreateAccountOutput";
  /** Returns the account number. */
  id?: Maybe<Scalars["String"]>;
  number?: Maybe<Scalars["String"]>;
};

/**
 * The possible errors that can be raised are:
 *
 * - KT-CT-1111: Unauthorized.
 * - KT-CT-3815: No active payment schedule found for this account.
 * - KT-CT-3822: Unauthorized.
 * - KT-CT-3923: Unauthorized.
 * - KT-CT-3941: Invalid data.
 * - KT-CT-3942: An unexpected error occurred.
 * - KT-CT-3947: An unexpected error occurred.
 * - KT-CT-3960: Invalid value for payment day.
 * - KT-CT-3961: Cannot update plan-associated payment schedule.
 * - KT-CT-3962: No new value provided to update payment schedule.
 * - KT-CT-1113: Disabled GraphQL field requested.
 * - KT-CT-1111: Unauthorized.
 * - KT-CT-1112: 'Authorization' header not provided.
 */
export type CreateAccountPaymentSchedule = {
  __typename?: "CreateAccountPaymentSchedule";
  possibleErrors?: Maybe<Array<Maybe<PossibleErrorType>>>;
  /** New payment schedule. */
  schedule: PaymentScheduleType;
};

/** Input type for updating the payment schedule on a ledger. Requires an `account_number`, `ledger_id` and one of `payment_day` or `payment_amount` to be provided. */
export type CreateAccountPaymentScheduleInput = {
  /** Number of the account for which to update the schedule. */
  accountNumber: Scalars["String"];
  /** Number of the ledger associated with the current payment schedule. */
  ledgerNumber: Scalars["String"];
  /** The new fixed payment amount. */
  paymentAmount?: InputMaybe<Scalars["Int"]>;
  /** The new day of the month at which to take payment; ranges from 1 to 28. */
  paymentDay?: InputMaybe<Scalars["Int"]>;
};

/**
 * Create an account reference.
 *
 * The possible errors that can be raised are:
 *
 * - KT-CT-4123: Unauthorized.
 * - KT-CT-8310: Invalid data.
 * - KT-CT-8311: Invalid data.
 * - KT-CT-1113: Disabled GraphQL field requested.
 * - KT-CT-1111: Unauthorized.
 * - KT-CT-1112: 'Authorization' header not provided.
 */
export type CreateAccountReference = {
  __typename?: "CreateAccountReference";
  accountReference?: Maybe<AccountReferenceType>;
  possibleErrors?: Maybe<Array<Maybe<PossibleErrorType>>>;
};

/**
 * The possible errors that can be raised are:
 *
 * - KT-CT-1401: Invalid data.
 * - KT-CT-1402: Unable to create account reminder.
 * - KT-CT-1113: Disabled GraphQL field requested.
 * - KT-CT-1111: Unauthorized.
 * - KT-CT-1112: 'Authorization' header not provided.
 */
export type CreateAccountReminder = {
  __typename?: "CreateAccountReminder";
  /** Account reminder. */
  accountReminder?: Maybe<AccountReminder>;
  possibleErrors?: Maybe<Array<Maybe<PossibleErrorType>>>;
};

export type CreateAccountReminderInput = {
  /** The account number. */
  accountNumber: Scalars["String"];
  /** Reminder content. */
  content: Scalars["String"];
  /** When the reminder is due. */
  dueAt: Scalars["DateTime"];
  /** The reminder type. */
  reminderType: AccountReminderTypes;
};

/**
 * Create an affiliate link for a new sales agent.
 *
 * The possible errors that can be raised are:
 *
 * - KT-CT-7711: Invalid data.
 * - KT-CT-7713: Invalid data.
 * - KT-CT-7714: Invalid data.
 * - KT-CT-7715: Invalid data.
 * - KT-CT-1113: Disabled GraphQL field requested.
 * - KT-CT-1111: Unauthorized.
 * - KT-CT-1112: 'Authorization' header not provided.
 */
export type CreateAffiliateLink = {
  __typename?: "CreateAffiliateLink";
  affiliateLink?: Maybe<AffiliateLinkType>;
  possibleErrors?: Maybe<Array<Maybe<PossibleErrorType>>>;
};

export type CreateAffiliateLinkInputType = {
  contactEmail: Scalars["String"];
  contactName: Scalars["String"];
  /** The organisation for whom to create the affiliate link for. */
  organisationId: Scalars["ID"];
  /**
   * Will be validated as follows:
   *
   * - should be at least two characters
   * - should only contain (letters, numbers, and Hyphen)
   * - should not contain bad words
   * - should not contain any of the reserved words including:
   *  affiliates, api, business, click, consul, developer, friends, kraken, mail, sendgrid, tech, webhooks, www, www2
   */
  subdomain: Scalars["String"];
};

/**
 * Create an affiliate organisation.
 *
 * The possible errors that can be raised are:
 *
 * - KT-CT-7716: Invalid data.
 * - KT-CT-1113: Disabled GraphQL field requested.
 * - KT-CT-1111: Unauthorized.
 * - KT-CT-1112: 'Authorization' header not provided.
 */
export type CreateAffiliateOrganisation = {
  __typename?: "CreateAffiliateOrganisation";
  affiliateOrganisation?: Maybe<AffiliateOrganisationType>;
  possibleErrors?: Maybe<Array<Maybe<PossibleErrorType>>>;
};

export type CreateAffiliateOrganisationInputType = {
  /** Is this partner allowed to specify payment methods other than Direct Debit in the import csv or API. */
  allowAlternativePaymentMethods?: InputMaybe<Scalars["Boolean"]>;
  /** Are meter point registrations limited for profile classes 1 and 2 for registrations from csv or API. */
  canRegisterBusinessMeterPoints?: InputMaybe<Scalars["Boolean"]>;
  /** Allow registration requests with customers without an email address. */
  canRegisterCustomersWithoutEmailAddress?: InputMaybe<Scalars["Boolean"]>;
  /** Allow registration requests with exiting account user emails to add to the portfolio belonging to the account user. */
  canRegisterPortfolioAccounts?: InputMaybe<Scalars["Boolean"]>;
  /** Allow performing tariff renewals via API. */
  canRenewTariffs?: InputMaybe<Scalars["Boolean"]>;
  /** Allow this partner access to the IVR support API (modify their own IVR handling through third party 'IVR Flow Editor'). */
  canUseIvrSupportApi?: InputMaybe<Scalars["Boolean"]>;
  /** Default Account Type. */
  defaultAccountType: AccountTypeChoices;
  /** Restrict to field-sales-only products? This is only allowed for the 'field-sales' and 'events' sales channels. */
  isFieldSalesOnlyProduct?: InputMaybe<Scalars["Boolean"]>;
  name: Scalars["String"];
  /** Sales Channel. */
  salesChannel: SalesChannelChoices;
  /** Allow this partner to skip validation that ensures all meter points belong to the same address. */
  skipMeterPointAddressValidation?: InputMaybe<Scalars["Boolean"]>;
};

/** Create a session for an affiliate link. */
export type CreateAffiliateSession = {
  __typename?: "CreateAffiliateSession";
  affiliateSession?: Maybe<AffiliateSessionType>;
};

export type CreateAffiliateSessionInputType = {
  /** The IP Address of the user. */
  ipAddress?: InputMaybe<Scalars["String"]>;
  /** The affiliate link for whom to create the session for. */
  linkId: Scalars["ID"];
  queryParams?: InputMaybe<CreateAffiliateSessionParams>;
  /** The quote share that led to this session. */
  quoteShareId?: InputMaybe<Scalars["ID"]>;
  /** The HTTP user agent. */
  userAgent?: InputMaybe<Scalars["String"]>;
};

export type CreateAffiliateSessionParams = {
  /** Additional query params to consider. */
  additionalQueryParams?: InputMaybe<Scalars["JSONString"]>;
  /** Description of the event. */
  event?: InputMaybe<Scalars["String"]>;
  /** Date of when the event fired in string format. */
  eventCreated?: InputMaybe<Scalars["String"]>;
  /** Information about the event. */
  eventInformation?: InputMaybe<Scalars["JSONString"]>;
  /** Join, buy now, etc. */
  route?: InputMaybe<Scalars["String"]>;
  /** Session id generated either FE or BE, so we can group sessions together. */
  sessionId?: InputMaybe<Scalars["ID"]>;
  /** If the user purchased a product from us. */
  successfulConversion?: InputMaybe<Scalars["Boolean"]>;
};

/**
 * The possible errors that can be raised are:
 *
 * - KT-CT-4123: Unauthorized.
 * - KT-CT-9601: Invalid data.
 * - KT-CT-9602: Unable to create contribution agreement.
 * - KT-CT-9605: Contribution amount cannot be 0 or negative.
 * - KT-CT-9606: Scheme is not accepting contributions at this time.
 * - KT-CT-1113: Disabled GraphQL field requested.
 * - KT-CT-1111: Unauthorized.
 * - KT-CT-1112: 'Authorization' header not provided.
 */
export type CreateContributionAgreement = {
  __typename?: "CreateContributionAgreement";
  /** The created contribution agreement. */
  contributionAgreement?: Maybe<ContributionAgreementType>;
  possibleErrors?: Maybe<Array<Maybe<PossibleErrorType>>>;
};

export type CreateContributionAgreementInput = {
  /** The account number. */
  accountNumber: Scalars["String"];
  /** The start datetime of the agreement. */
  activeFrom: Scalars["DateTime"];
  /** The end datetime of the agreement, if any. */
  activeTo?: InputMaybe<Scalars["DateTime"]>;
  /** The amount contributed per interval. Note, this is in the smallest domination that the currency supports. e.g. Pence, Cents, Yen, etc. */
  amount: Scalars["Int"];
  /** The frequency of contributions. */
  interval: Interval;
  /** The code of the scheme to contribute to. */
  schemeCode: Scalars["String"];
};

/**
 * Create a new deposit agreement for the account if it needs one.
 *
 * The possible errors that can be raised are:
 *
 * - KT-CT-4177: Unauthorized.
 * - KT-CT-1113: Disabled GraphQL field requested.
 * - KT-CT-1111: Unauthorized.
 * - KT-CT-1112: 'Authorization' header not provided.
 */
export type CreateDepositAgreement = {
  __typename?: "CreateDepositAgreement";
  depositAgreement?: Maybe<CreateDepositAgreementOutput>;
  possibleErrors?: Maybe<Array<Maybe<PossibleErrorType>>>;
};

export type CreateDepositAgreementInput = {
  accountNumber: Scalars["String"];
  depositKey: Scalars["String"];
  reason: Scalars["String"];
};

export type CreateDepositAgreementOutput = {
  __typename?: "CreateDepositAgreementOutput";
  depositAmount?: Maybe<Scalars["Int"]>;
  depositRequired?: Maybe<Scalars["Boolean"]>;
};

/**
 * Create an external account event.
 *
 * The possible errors that can be raised are:
 *
 * - KT-CT-7123: Unauthorized.
 * - KT-CT-1113: Disabled GraphQL field requested.
 * - KT-CT-1111: Unauthorized.
 * - KT-CT-1112: 'Authorization' header not provided.
 */
export type CreateExternalAccountEvent = {
  __typename?: "CreateExternalAccountEvent";
  /** The new event data. */
  event?: Maybe<ExternalAccountEvent>;
  possibleErrors?: Maybe<Array<Maybe<PossibleErrorType>>>;
};

export type CreateExternalAccountEventInput = {
  /** The number of the account that the event should be created for. */
  accountNumber: Scalars["String"];
  /** The category of the event. */
  category: ExternalAccountEventCategory;
  /** An array of content data associated with the event. */
  content: Array<InputMaybe<ExternalAccountEventContent>>;
  /** A human-readable description of the event. */
  description?: InputMaybe<Scalars["String"]>;
  /** The time the event occurred. */
  occurredAt?: InputMaybe<Scalars["DateTime"]>;
  /** The subcategory of the event. */
  subcategory?: InputMaybe<ExternalAccountEventSubCategory>;
};

/**
 * Create an external account user event.
 *
 * The possible errors that can be raised are:
 *
 * - KT-CT-7123: Unauthorized.
 * - KT-CT-1113: Disabled GraphQL field requested.
 * - KT-CT-1111: Unauthorized.
 * - KT-CT-1112: 'Authorization' header not provided.
 */
export type CreateExternalAccountUserEvent = {
  __typename?: "CreateExternalAccountUserEvent";
  /** The new event data. */
  event?: Maybe<ExternalAccountUserEvent>;
  possibleErrors?: Maybe<Array<Maybe<PossibleErrorType>>>;
};

export type CreateExternalAccountUserEventInput = {
  /** The category of the event. */
  category: ExternalAccountEventCategory;
  /** An array of content data associated with the event. */
  content: Array<InputMaybe<ExternalAccountEventContent>>;
  /** A human-readable description of the event. */
  description?: InputMaybe<Scalars["String"]>;
  /** The time the event occurred. */
  occurredAt?: InputMaybe<Scalars["DateTime"]>;
  /** The subcategory of the event. */
  subcategory?: InputMaybe<ExternalAccountEventSubCategory>;
  /** The user that the event should be created for. */
  userId: Scalars["String"];
};

/**
 * The possible errors that can be raised are:
 *
 * - KT-CT-8206: Invalid data.
 * - KT-CT-1113: Disabled GraphQL field requested.
 * - KT-CT-1111: Unauthorized.
 * - KT-CT-1112: 'Authorization' header not provided.
 */
export type CreateGoodsPurchase = {
  __typename?: "CreateGoodsPurchase";
  /** Goods purchase created. */
  goodsPurchase?: Maybe<GoodsPurchase>;
  possibleErrors?: Maybe<Array<Maybe<PossibleErrorType>>>;
};

/**
 * The possible errors that can be raised are:
 *
 * - KT-CT-8202: Invalid data.
 * - KT-CT-1113: Disabled GraphQL field requested.
 * - KT-CT-1111: Unauthorized.
 * - KT-CT-1112: 'Authorization' header not provided.
 */
export type CreateGoodsQuote = {
  __typename?: "CreateGoodsQuote";
  /** Goods quote created for the customer. */
  goodsQuote?: Maybe<GoodsQuote>;
  possibleErrors?: Maybe<Array<Maybe<PossibleErrorType>>>;
};

export type CreateGoodsQuoteInput = {
  /** The account number. */
  accountNumber: Scalars["String"];
  /** A JSON object containing client parameters to store on the quote. */
  clientParams?: InputMaybe<Scalars["JSONString"]>;
  /** A JSON object containing market parameters to store on the quote. */
  marketParams?: InputMaybe<Scalars["JSONString"]>;
  /** Products to get a quote for. */
  productsToQuote: Array<InputMaybe<ProductToQuoteInput>>;
};

/**
 * The possible errors that can be raised are:
 *
 * - KT-CT-8202: Invalid data.
 * - KT-CT-1113: Disabled GraphQL field requested.
 * - KT-CT-1111: Unauthorized.
 * - KT-CT-1112: 'Authorization' header not provided.
 */
export type CreateGoodsQuoteWithoutAccount = {
  __typename?: "CreateGoodsQuoteWithoutAccount";
  /** Goods quote created for the customer. */
  goodsQuote?: Maybe<GoodsQuote>;
  possibleErrors?: Maybe<Array<Maybe<PossibleErrorType>>>;
};

export type CreateGoodsQuoteWithoutAccountInput = {
  /** A JSON object containing client parameters to store on the quote. */
  clientParams?: InputMaybe<Scalars["JSONString"]>;
  /** Customer profile. */
  customerProfile: CustomerProfileInput;
  /** A JSON object containing market parameters to store on the quote. */
  marketParams?: InputMaybe<Scalars["JSONString"]>;
  /** Products to get a quote for. */
  productsToQuote: Array<InputMaybe<ProductToQuoteInput>>;
};

export type CreateInkInboundMessageInput = {
  channel: InkCommunicationChannel;
  clientMutationId?: InputMaybe<Scalars["String"]>;
  /**
   * An optional parameter where we can pass the generic message headers if it has one
   *
   * Email channel tries to get the value `conversation-relay-id` from this
   * parameter
   */
  messageHeaders?: InputMaybe<Scalars["JSONString"]>;
  /**
   * An arbitrary, unique ID for this message.
   *
   * This must be unique for each message that is supplied
   * using the same organisation; collisions between messages
   * provided by different organisations are tolerated.
   *
   * Stored as vendor_id.
   */
  messageId: Scalars["String"];
  newMessage: InkMessageInput;
  /** When the message occurred in the system of origin. */
  occurredAt?: InputMaybe<Scalars["DateTime"]>;
  /**
   * An optional vendor value to denote which system it originated from.
   *
   * If no vendor is passed, we will get the default generic vendor from
   * the setting called INK_DEFAULT_GENERIC_MESSAGE_API_VENDOR.
   */
  vendor?: InputMaybe<Scalars["String"]>;
};

/** Register an Ink inbound message. */
export type CreateInkInboundMessagePayload = {
  __typename?: "CreateInkInboundMessagePayload";
  clientMutationId?: Maybe<Scalars["String"]>;
  /** The Ink message that was created. */
  message: InkMessage;
};

/**
 * Create metadata on an object.
 *
 * The possible errors that can be raised are:
 *
 * - KT-CT-8412: Invalid data.
 * - KT-CT-8414: Invalid data.
 * - KT-CT-1113: Disabled GraphQL field requested.
 * - KT-CT-1111: Unauthorized.
 * - KT-CT-1112: 'Authorization' header not provided.
 */
export type CreateMetadata = {
  __typename?: "CreateMetadata";
  metadata?: Maybe<Metadata>;
  possibleErrors?: Maybe<Array<Maybe<PossibleErrorType>>>;
};

/**
 * Create a Lead instance.
 *
 * The possible errors that can be raised are:
 *
 * - KT-CT-8901: Unable to create lead.
 * - KT-CT-1113: Disabled GraphQL field requested.
 * - KT-CT-1111: Unauthorized.
 * - KT-CT-1112: 'Authorization' header not provided.
 */
export type CreateNewLead = {
  __typename?: "CreateNewLead";
  /** Lead created. */
  lead?: Maybe<NewLeadType>;
  possibleErrors?: Maybe<Array<Maybe<PossibleErrorType>>>;
};

export type CreateOrUpdateLoyaltyCardInput = {
  /** The id of the account user. */
  accountUserId?: InputMaybe<Scalars["String"]>;
  /** The number of the loyalty card. */
  number?: InputMaybe<Scalars["String"]>;
  /** The scheme of the loyalty card. */
  scheme?: InputMaybe<Scalars["String"]>;
};

/**
 * Create a Loyalty Card for the given account user.
 *
 * The possible errors that can be raised are:
 *
 * - KT-CT-5412: No account user exists with the given id.
 * - KT-CT-8610: Invalid data.
 * - KT-CT-8611: Invalid data.
 * - KT-CT-1113: Disabled GraphQL field requested.
 * - KT-CT-1111: Unauthorized.
 * - KT-CT-1112: 'Authorization' header not provided.
 */
export type CreateOrUpdateLoyaltyCardMutation = {
  __typename?: "CreateOrUpdateLoyaltyCardMutation";
  /** Created or updated loyalty card. */
  loyaltyCard?: Maybe<LoyaltyCardType>;
  possibleErrors?: Maybe<Array<Maybe<PossibleErrorType>>>;
};

/**
 * Create a new portfolio.
 *
 * The possible errors that can be raised are:
 *
 * - KT-CT-9402: Received an invalid brandCode.
 * - KT-CT-9401: Received an invalid operationsTeamId.
 * - KT-CT-1113: Disabled GraphQL field requested.
 * - KT-CT-1111: Unauthorized.
 * - KT-CT-1112: 'Authorization' header not provided.
 */
export type CreatePortfolio = {
  __typename?: "CreatePortfolio";
  /** The created portfolio. */
  portfolio?: Maybe<PortfolioType>;
  possibleErrors?: Maybe<Array<Maybe<PossibleErrorType>>>;
};

export type CreatePortfolioInput = {
  /** The brand to associate with this portfolio, if not provided the default brand will be used. */
  brandCode?: InputMaybe<Scalars["String"]>;
  /** Whether collective bills should be issued for the portfolio's accounts. The default value is False. */
  collectiveBilling?: InputMaybe<Scalars["Boolean"]>;
  /** The ID of the operations team to associate with this portfolio.If no team is provided, no team will be assigned to the portfolio. */
  operationsTeamId?: InputMaybe<Scalars["ID"]>;
};

/**
 * The possible errors that can be raised are:
 *
 * - KT-CT-9403: Received an invalid portfolioId.
 * - KT-CT-9404: Received an invalid accountUserId.
 * - KT-CT-1113: Disabled GraphQL field requested.
 * - KT-CT-1111: Unauthorized.
 * - KT-CT-1112: 'Authorization' header not provided.
 */
export type CreatePortfolioUserRole = {
  __typename?: "CreatePortfolioUserRole";
  /** The created role for a user in association with a portfolio. */
  portfolioUserRole?: Maybe<PortfolioUserRoleType>;
  possibleErrors?: Maybe<Array<Maybe<PossibleErrorType>>>;
};

export type CreatePortfolioUserRoleInput = {
  /** The user to associate with the portfolio. */
  accountUserId: Scalars["ID"];
  /** The portfolio to associate the user with. */
  portfolioId: Scalars["ID"];
  /** The role to assign to the user. If not provided the default role will be used. */
  role?: InputMaybe<Scalars["RoleString"]>;
};

export type CreatePurchaseInput = {
  /** The account number. */
  accountNumber: Scalars["String"];
  /** A JSON object containing client parameters to store on the purchase. */
  clientParams?: InputMaybe<Scalars["JSONString"]>;
  /** A JSON object containing client parameters to store on the purchase. */
  marketParams?: InputMaybe<Scalars["JSONString"]>;
  /** Products being purchased. */
  saleItems: Array<InputMaybe<ProductToPurchaseInput>>;
};

/**
 * Creates a quote request with quoted products that are available in the given post_code.
 *
 * The possible errors that can be raised are:
 *
 * - KT-CT-4616: Unable to create a quote.
 * - KT-CT-1113: Disabled GraphQL field requested.
 * - KT-CT-1111: Unauthorized.
 * - KT-CT-1112: 'Authorization' header not provided.
 */
export type CreateQuote = {
  __typename?: "CreateQuote";
  possibleErrors?: Maybe<Array<Maybe<PossibleErrorType>>>;
  /** Returns a quote request with quoted products that are available in the given post_code. */
  quote?: Maybe<CreateQuoteOutputType>;
};

export type CreateQuoteInput = {
  /** Optional affiliate session id for associating a quote with an affiliate. */
  affiliateSessionId?: InputMaybe<Scalars["String"]>;
  /** The meter point's Electric Service Identifier. */
  esiId?: InputMaybe<Scalars["String"]>;
  /** A meta data field. */
  extraCriteria?: InputMaybe<QuoteExtraCriteria>;
  /** Indicates whether the meter point has distribution generation (Solar, Wind, etc.). */
  isDistributionGenerationMeterPoint?: InputMaybe<Scalars["Boolean"]>;
  /** A payment method that should be used when a quote has been accepted. */
  paymentMethod: PaymentMethods;
  /** A post code that should be used to generate a quote for. */
  postCode: Scalars["String"];
};

export type CreateQuoteOutputType = {
  __typename?: "CreateQuoteOutputType";
  /** Code generated for a quote request. */
  code?: Maybe<Scalars["String"]>;
  /** Indicates whether this quote has expired. A valid quote will return False. */
  isExpired?: Maybe<Scalars["Boolean"]>;
  /** Unique id of the quote. */
  quoteId?: Maybe<Scalars["ID"]>;
  /** Returns quoted products with estimated rates based on the generated quote request. */
  quotedProducts?: Maybe<Array<Maybe<QuotedProducts>>>;
};

/**
 * Use a referral code to create a referral and trigger a referral reward.
 *
 * The possible errors that can be raised are:
 *
 * - KT-CT-6723: Unauthorized.
 * - KT-CT-6710: Unable to create referral.
 * - KT-CT-6711: Accounts may not self-refer.
 * - KT-CT-6713: Referring and referred account brands do not match.
 * - KT-CT-6712: Invalid reference.
 * - KT-CT-1113: Disabled GraphQL field requested.
 * - KT-CT-1111: Unauthorized.
 * - KT-CT-1112: 'Authorization' header not provided.
 */
export type CreateReferral = {
  __typename?: "CreateReferral";
  possibleErrors?: Maybe<Array<Maybe<PossibleErrorType>>>;
  /** The reward amount to be issued to the referred account, in smallest currency subunits. */
  referredAccountRewardAmount?: Maybe<Scalars["Int"]>;
};

/** Required information for creating a referral */
export type CreateReferralInput = {
  /** The account number for the referred account. */
  accountNumber: Scalars["String"];
  /** An email address, link or code, referencing the referring account. */
  reference: Scalars["String"];
};

export type CreateShellAccountInput = {
  billingAddressLine1?: InputMaybe<Scalars["String"]>;
  billingAddressLine2?: InputMaybe<Scalars["String"]>;
  billingAddressLine3?: InputMaybe<Scalars["String"]>;
  billingAddressLine4?: InputMaybe<Scalars["String"]>;
  billingAddressLine5?: InputMaybe<Scalars["String"]>;
  billingName?: InputMaybe<Scalars["String"]>;
  /** Day to fixed bill on if billing_period_length set. */
  billingPeriodDay?: InputMaybe<Scalars["Int"]>;
  /** For fixed billing accounts only, the length of their billing period. Can be MONTHLY or QUARTERLY. */
  billingPeriodLength?: InputMaybe<Scalars["String"]>;
  /** Month to start billing from if billing_period_length set to QUARTERLY or the multiplier is > 1. */
  billingPeriodMonth?: InputMaybe<Scalars["Int"]>;
  /** For fixed billing accounts only, the number the period length is to be multiplied by to get the total period length, i.e. for billing every second month, select 2 combined with a billing period length MONTHLY. Can't be > 1 for quarterly billing. */
  billingPeriodMultiplier?: InputMaybe<Scalars["Int"]>;
  billingPostcode?: InputMaybe<Scalars["String"]>;
  billingRichAddress?: InputMaybe<Scalars["String"]>;
  brand?: InputMaybe<Scalars["String"]>;
  businessType?: InputMaybe<Scalars["String"]>;
  clientMutationId?: InputMaybe<Scalars["String"]>;
  companyName?: InputMaybe<Scalars["String"]>;
  companyNumber?: InputMaybe<Scalars["String"]>;
  dateOfBirth?: InputMaybe<Scalars["Date"]>;
  email: Scalars["String"];
  familyName: Scalars["String"];
  givenName: Scalars["String"];
  isBusinessAccount?: InputMaybe<Scalars["Boolean"]>;
  landline?: InputMaybe<Scalars["String"]>;
  mobile?: InputMaybe<Scalars["String"]>;
  password?: InputMaybe<Scalars["String"]>;
  passwordUpdateToken?: InputMaybe<Scalars["String"]>;
  portfolioNumber?: InputMaybe<Scalars["String"]>;
  urn?: InputMaybe<Scalars["String"]>;
};

/** Create a shell account (a billable account with no property/energy supply). */
export type CreateShellAccountPayload = {
  __typename?: "CreateShellAccountPayload";
  account?: Maybe<AccountInterface>;
  billingAddressLine1?: Maybe<Scalars["String"]>;
  billingAddressLine2?: Maybe<Scalars["String"]>;
  billingAddressLine3?: Maybe<Scalars["String"]>;
  billingAddressLine4?: Maybe<Scalars["String"]>;
  billingAddressLine5?: Maybe<Scalars["String"]>;
  billingName?: Maybe<Scalars["String"]>;
  /** Day to fixed bill on if billing_period_length set. */
  billingPeriodDay?: Maybe<Scalars["Int"]>;
  /** For fixed billing accounts only, the length of their billing period. Can be MONTHLY or QUARTERLY. */
  billingPeriodLength?: Maybe<Scalars["String"]>;
  /** Month to start billing from if billing_period_length set to QUARTERLY or the multiplier is > 1. */
  billingPeriodMonth?: Maybe<Scalars["Int"]>;
  /** For fixed billing accounts only, the number the period length is to be multiplied by to get the total period length, i.e. for billing every second month, select 2 combined with a billing period length MONTHLY. Can't be > 1 for quarterly billing. */
  billingPeriodMultiplier?: Maybe<Scalars["Int"]>;
  billingPostcode?: Maybe<Scalars["String"]>;
  billingRichAddress?: Maybe<Scalars["String"]>;
  brand?: Maybe<Scalars["String"]>;
  businessType?: Maybe<Scalars["String"]>;
  clientMutationId?: Maybe<Scalars["String"]>;
  companyName?: Maybe<Scalars["String"]>;
  companyNumber?: Maybe<Scalars["String"]>;
  dateOfBirth?: Maybe<Scalars["Date"]>;
  email: Scalars["String"];
  errors?: Maybe<Array<Maybe<ErrorType>>>;
  familyName: Scalars["String"];
  givenName: Scalars["String"];
  isBusinessAccount?: Maybe<Scalars["Boolean"]>;
  landline?: Maybe<Scalars["String"]>;
  mobile?: Maybe<Scalars["String"]>;
  password?: Maybe<Scalars["String"]>;
  passwordUpdateToken?: Maybe<Scalars["String"]>;
  portfolioNumber?: Maybe<Scalars["String"]>;
  urn?: Maybe<Scalars["String"]>;
};

/**
 * Create a SmartDeviceToken object.
 *
 * The possible errors that can be raised are:
 *
 * - KT-CT-7223: Unauthorized.
 * - KT-CT-1113: Disabled GraphQL field requested.
 */
export type CreateSmartDeviceToken = {
  __typename?: "CreateSmartDeviceToken";
  possibleErrors?: Maybe<Array<Maybe<PossibleErrorType>>>;
  /** Value to use for state field. */
  state?: Maybe<Scalars["String"]>;
};

/** The input type for creating a SmartDeviceToken object. */
export type CreateSmartDeviceTokenInput = {
  /** The account number. */
  accountNumber: Scalars["String"];
  /** The type of smart device. */
  deviceType: Scalars["String"];
  /** The smart device vendor. */
  deviceVendor: Scalars["String"];
};

/**
 * Mutation to generate a Pre-signed S3 post request that can be use to upload files to S3.
 *
 * The possible errors that can be raised are:
 *
 * - KT-US-8701: Upload category not found.
 * - KT-US-8702: File already exists.
 * - KT-CT-1113: Disabled GraphQL field requested.
 * - KT-CT-1111: Unauthorized.
 * - KT-CT-1112: 'Authorization' header not provided.
 */
export type CreateUsaFileAttachment = {
  __typename?: "CreateUSAFileAttachment";
  possibleErrors?: Maybe<Array<Maybe<PossibleErrorType>>>;
  /** Pre-signed S3 post request. */
  postRequest?: Maybe<UploadFileAttachmentPostRequest>;
};

export type CreateUsaFileAttachmentInputType = {
  /** Category of uploaded file type. */
  category?: InputMaybe<UsaFileAttachmentCategoryOptions>;
  /** Filename. */
  filename: Scalars["String"];
};

/** A credit to the customer from the energy retailer. */
export type Credit = TransactionType & {
  __typename?: "Credit";
  /** Unique identifier of the account the transaction belongs to. */
  accountNumber?: Maybe<Scalars["String"]>;
  /**
   * Gross amount including tax (when payable). Refer to the `amounts` field for a breakdown of this information.
   * @deprecated The 'amount' field is deprecated.
   *
   * Use `amounts` instead for a breakdown of the relevant net, tax, and gross amounts.
   *
   * - Marked as deprecated on 2023-12-06.
   * - Will be removed on 2024-06-01.
   */
  amount?: Maybe<Scalars["Int"]>;
  /** The net, tax and gross amounts for the transaction. Note: for payments and repayments, only the net amount is returned. */
  amounts?: Maybe<TransactionAmountType>;
  /** The customer's resulting balance after this transaction has been applied, in the smallest unit of currency. */
  balanceCarriedForward?: Maybe<Scalars["Int"]>;
  /** The unique identifier for the most recent billing document linked with the transaction.Note: a transaction may be linked with multiple documents, but this field will only return the identifier for the most recent billing document. */
  billingDocumentIdentifier?: Maybe<Scalars["ID"]>;
  /** The date time when the transaction is created. */
  createdAt?: Maybe<Scalars["DateTime"]>;
  /** Returns True if the transaction is linked with a statement. */
  hasStatement?: Maybe<Scalars["Boolean"]>;
  id?: Maybe<Scalars["ID"]>;
  /**
   * Deprecated.
   * @deprecated The 'isAccountCharge' field is deprecated.
   *
   * This information is provided by the __typename introspection query.
   *
   * - Marked as deprecated on 2020-06-19.
   * - Will be removed on 2022-11-15.
   */
  isAccountCharge?: Maybe<Scalars["Boolean"]>;
  /**
   * Deprecated.
   * @deprecated The 'isAccountPayment' field is deprecated.
   *
   * This information is provided by the __typename introspection query.
   *
   * - Marked as deprecated on 2020-06-19.
   * - Will be removed on 2022-11-15.
   */
  isAccountPayment?: Maybe<Scalars["Boolean"]>;
  /**
   * Deprecated.
   * @deprecated The 'isCredit' field is deprecated.
   *
   * This information is provided by the __typename introspection query.
   *
   * - Marked as deprecated on 2020-06-19.
   * - Will be removed on 2022-11-15.
   */
  isCredit?: Maybe<Scalars["Boolean"]>;
  /** Whether the statement this transaction is on has been held. A held statement is not sent to a customer automatically, but is instead marked for manual attention by operations staff.  Returns False if a statement is not linked with the transaction. */
  isHeld?: Maybe<Scalars["Boolean"]>;
  /** Whether this transaction has been issued on any billing document.Note: Look for the most recently issued transaction instead of looking through all transactions as some accounts may have initial transactions that were not issued.This will return False if the transaction is not associated with any billing documents. */
  isIssued?: Maybe<Scalars["Boolean"]>;
  isReversed: Scalars["Boolean"];
  /** Returns the note field value for the transaction, which contains additional info. */
  note?: Maybe<Scalars["String"]>;
  postedDate?: Maybe<Scalars["Date"]>;
  /** Returns the reason. */
  reasonCode?: Maybe<Scalars["String"]>;
  /**
   * Returns None if a statement is not linked with the transaction.
   * @deprecated The 'statementId' field is deprecated.
   *
   * Use `billingDocumentIdentifier` instead.
   *
   * - Marked as deprecated on 2023-11-30.
   * - Will be removed on 2024-06-01.
   */
  statementId?: Maybe<Scalars["ID"]>;
  title?: Maybe<Scalars["String"]>;
};

export type CreditCheckOutputType = {
  __typename?: "CreditCheckOutputType";
  status?: Maybe<CreditCheckStatus>;
};

/** An enumeration. */
export enum CreditCheckStatus {
  Failed = "FAILED",
  NotAvailable = "NOT_AVAILABLE",
  Passed = "PASSED",
}

export type CreditDataStatus = {
  /** A boolean that represents if credit report was suppressed or not. */
  isSuppressed: Scalars["Boolean"];
  /** A reason based on why a credit report was suppressed. */
  reason: CreditDataStatusReason;
};

export type CreditDataStatusReason = {
  /** Provide an enum from the given choices. */
  reasonUsingEnum?: InputMaybe<ReportSuppressedReason>;
  /** Provide a value that is supported by `ReportSuppressedReasonChoices`. If an invalid string is received, an error will be raised. */
  reasonUsingValue?: InputMaybe<Scalars["String"]>;
};

export type CreditReasonType = {
  __typename?: "CreditReasonType";
  /** The credit reason code. */
  code?: Maybe<Scalars["String"]>;
  /** The credit reason display text. */
  display?: Maybe<Scalars["String"]>;
  /** The group the credit reason belongs to (if applicable). */
  group?: Maybe<Scalars["String"]>;
  /** Whether the credit reason is deprecated. */
  isDeprecated?: Maybe<Scalars["Boolean"]>;
  /** Whether the credit reason is hidden. */
  isHidden?: Maybe<Scalars["Boolean"]>;
  /** Whether the credit reason is sales tax exempt. */
  isTaxExempt?: Maybe<Scalars["Boolean"]>;
};

export type CurrentAndHistoricalProductPricingHistoryInput = {
  /** The end time (inclusive) of the historical prices period. */
  periodEndAt: Scalars["DateTime"];
  /** The start time of the historical prices period. */
  periodStartAt: Scalars["DateTime"];
};

export type CurrentAndHistoricalProductPricingHistoryOutput = {
  __typename?: "CurrentAndHistoricalProductPricingHistoryOutput";
  /** Product's display name. */
  displayName?: Maybe<Scalars["String"]>;
  /** Product's name. */
  fullName?: Maybe<Scalars["String"]>;
  /** A product's current and historic pricing history. */
  priceHistory?: Maybe<Array<Maybe<PricingHistory>>>;
};

/**
 * Accepts renewal quote request using the given code and product id.
 *
 * The possible errors that can be raised are:
 *
 * - KT-CT-4623: Unauthorized.
 * - KT-CT-4618: Unable to accept given quote.
 * - KT-US-4615: Received an invalid quote code.
 * - KT-US-4617: Unable to create agreement.
 * - KT-CT-1113: Disabled GraphQL field requested.
 * - KT-CT-1111: Unauthorized.
 * - KT-CT-1112: 'Authorization' header not provided.
 */
export type CustomerAcceptQuote = {
  __typename?: "CustomerAcceptQuote";
  /** Returns a renewed agreement created after a quote request has been accepted. */
  agreement?: Maybe<AgreementType>;
  possibleErrors?: Maybe<Array<Maybe<PossibleErrorType>>>;
};

export type CustomerAcceptQuoteInput = {
  /** Account number. */
  accountNumber: Scalars["String"];
  /** The selected quote's code. */
  code: Scalars["String"];
  /** Datetime at which the new agreement should be effective from. If not given, it defaults to the current local time (CST) midnight aligned. */
  effectiveFrom?: InputMaybe<Scalars["DateTime"]>;
  /** ID of the selected quoted product. */
  productId: Scalars["ID"];
};

/**
 * Creates a quote request with quoted products that can be served in the given ESI ID's location.
 *
 * The possible errors that can be raised are:
 *
 * - KT-CT-4623: Unauthorized.
 * - KT-US-4517: Invalid ESI ID.
 * - KT-CT-4616: Unable to create a quote.
 * - KT-CT-1113: Disabled GraphQL field requested.
 * - KT-CT-1111: Unauthorized.
 * - KT-CT-1112: 'Authorization' header not provided.
 */
export type CustomerCreateQuote = {
  __typename?: "CustomerCreateQuote";
  possibleErrors?: Maybe<Array<Maybe<PossibleErrorType>>>;
  /** Returns a quote request with quoted products that can be served in the given ESI ID's location. */
  quote?: Maybe<CustomerCreateQuoteOutputType>;
};

export type CustomerCreateQuoteInput = {
  /** Account number. */
  accountNumber: Scalars["String"];
  /** The meter point's unique identification number. */
  esiId: Scalars["String"];
};

export type CustomerCreateQuoteOutputType = {
  __typename?: "CustomerCreateQuoteOutputType";
  /** Code generated for a quote request. */
  code?: Maybe<Scalars["String"]>;
  /** Indicates whether this quote has expired. A valid quote will return False. */
  isExpired?: Maybe<Scalars["Boolean"]>;
  /** Unique id of the quote. */
  quoteId?: Maybe<Scalars["ID"]>;
  /** Returns quoted products with estimated rates based on the generated quote request. */
  quotedProducts?: Maybe<Array<Maybe<QuotedProducts>>>;
};

/** Details about the customer. */
export type CustomerDetailsInput = {
  /** The customer's date of birth. */
  dateOfBirth?: InputMaybe<Scalars["Date"]>;
  /** Account email. */
  email?: InputMaybe<Scalars["String"]>;
  /** Family name. */
  familyName: Scalars["String"];
  /** Given name. */
  givenName: Scalars["String"];
  /** Account landline number. */
  landline?: InputMaybe<Scalars["String"]>;
  /** Account mobile phone number. */
  mobile?: InputMaybe<Scalars["String"]>;
  /** The customer's communication preferences. */
  preferences?: InputMaybe<UpdateAccountUserCommsPreferencesInput>;
  /** The customer's pronouns. */
  pronouns?: InputMaybe<Scalars["String"]>;
  /** The customer's title. */
  title?: InputMaybe<Scalars["String"]>;
};

export type CustomerFeedbackInputType = {
  accountNumber: Scalars["String"];
  answer?: InputMaybe<Scalars["String"]>;
  feedbackId: Scalars["Int"];
  formId: Scalars["Int"];
  issueResolved: Scalars["Boolean"];
};

export type CustomerFeedbackType = {
  __typename?: "CustomerFeedbackType";
  id: Scalars["ID"];
  /** The value attached to the source */
  rawScore?: Maybe<Scalars["Int"]>;
  /** The datetime the feedback was submitted */
  submittedAt?: Maybe<Scalars["DateTime"]>;
};

export type CustomerProfileInput = {
  /** Line 1 of customer's address. */
  addressLine1: Scalars["String"];
  /** Line 2 of customer's address. */
  addressLine2?: InputMaybe<Scalars["String"]>;
  /** Line 3 of customer's address. */
  addressLine3?: InputMaybe<Scalars["String"]>;
  /** Line 4 of customer's address. */
  addressLine4?: InputMaybe<Scalars["String"]>;
  /** Line 5 of customer's address. */
  addressLine5?: InputMaybe<Scalars["String"]>;
  /** Customer's email. */
  email: Scalars["String"];
  /** Customer's family name. */
  familyName: Scalars["String"];
  /** Customer's given name. */
  givenName: Scalars["String"];
  /** Customer's phone number. */
  phoneNumber: Scalars["String"];
  /** Customer's postcode. */
  postcode: Scalars["String"];
};

/**
 * Revokes a customer's future agreement in order to recieve a new quote.
 *
 * The possible errors that can be raised are:
 *
 * - KT-CT-4623: Unauthorized.
 * - KT-CT-4617: Unable to revoke at least one future agreement.
 * - KT-CT-1113: Disabled GraphQL field requested.
 */
export type CustomerRevokeFutureAgreements = {
  __typename?: "CustomerRevokeFutureAgreements";
  possibleErrors?: Maybe<Array<Maybe<PossibleErrorType>>>;
  /** Outcome of revoking the future agreement. */
  successfulRevocation?: Maybe<Scalars["Boolean"]>;
};

export type CustomerRevokeFutureAgreementsInput = {
  /** Account number. */
  accountNumber: Scalars["String"];
};

export type DailyReadingInput = {
  accountNumber: Scalars["String"];
  /**
   * The unique identifier for a particular meter in the ERCOT market.
   *
   * If an ESI ID is not given, Kraken will try to get the ESI ID from the
   * latest active agreement on the account or raise an error if not found.
   */
  esiId?: InputMaybe<Scalars["String"]>;
  fromDatetime: Scalars["DateTime"];
  toDatetime: Scalars["DateTime"];
};

export type DailyReadingOutput = {
  __typename?: "DailyReadingOutput";
  dailyUsage?: Maybe<Scalars["Decimal"]>;
  meterPointId?: Maybe<Scalars["Int"]>;
  meterReading?: Maybe<Scalars["Decimal"]>;
  readAt?: Maybe<Scalars["String"]>;
  source?: Maybe<Scalars["String"]>;
};

/** A list of components which comprise a dashboard screen. */
export type Dashboard = IdentifiableInterface & {
  __typename?: "Dashboard";
  /** The list of sections for a dashboard. */
  dashboardItems: Array<Maybe<SectionType>>;
  /** Unique identifier of the object. */
  id?: Maybe<Scalars["ID"]>;
  /** The serialised dashboard items. */
  serialisedDashboardItems: Scalars["String"];
  /** The name of the object's type. */
  typename?: Maybe<Scalars["String"]>;
};

/**
 * The frequency of the cost of charge data to be shown in the consumer app.
 *
 * We are generating this data daily, weekly, monthly or annually, with the following
 * aggregations:
 * daily -> half-hourly aggregation
 * weekly & monthly -> daily aggregations
 * annually -> monthly aggregations
 */
export enum DataFrequency {
  Annually = "ANNUALLY",
  Daily = "DAILY",
  Monthly = "MONTHLY",
  Weekly = "WEEKLY",
}

/** Day of the week. */
export enum DayOfWeek {
  Friday = "FRIDAY",
  Monday = "MONDAY",
  Saturday = "SATURDAY",
  Sunday = "SUNDAY",
  Thursday = "THURSDAY",
  Tuesday = "TUESDAY",
  Wednesday = "WEDNESDAY",
}

export type DeAuthenticationInput = {
  /** Account number. */
  accountNumber: Scalars["String"];
  /** The most recently registered device of this type will be de-authenticated. */
  deviceType?: InputMaybe<KrakenFlexDeviceTypes>;
};

/**
 * De-authenticate a registered device.
 *
 * The possible errors that can be raised are:
 *
 * - KT-CT-4301: Unable to find device for given account.
 * - KT-CT-4350: Unable to de-authenticate device.
 * - KT-CT-1113: Disabled GraphQL field requested.
 */
export type DeauthenticateDevice = {
  __typename?: "DeauthenticateDevice";
  krakenflexDevice?: Maybe<DeviceDetailsType>;
  possibleErrors?: Maybe<Array<Maybe<PossibleErrorType>>>;
};

export type DebtCollectionAgencyType = {
  __typename?: "DebtCollectionAgencyType";
  code: Scalars["String"];
  isActive: Scalars["Boolean"];
  name: Scalars["String"];
};

/** An enumeration. */
export enum DebtCollectionProceedingStopReason {
  /** Arrears to client */
  ArrearsToClient = "ARREARS_TO_CLIENT",
  /** Bankrupt */
  Bankrupt = "BANKRUPT",
  /** Confirmed empty */
  ConfirmedEmpty = "CONFIRMED_EMPTY",
  /** COS */
  Cos = "COS",
  /** COT / COS */
  CotCos = "COT_COS",
  /** COT no proof */
  CotNoProof = "COT_NO_PROOF",
  /** COT proof seen */
  CotProofSeen = "COT_PROOF_SEEN",
  /** Deceased */
  Deceased = "DECEASED",
  /** Demolished */
  Demolished = "DEMOLISHED",
  /** Enrolled in Error */
  EnrolledInError = "ENROLLED_IN_ERROR",
  /** Expired */
  Exp = "EXP",
  /** Gone away */
  GoneAway = "GONE_AWAY",
  /** High level complaint */
  HighLevelComplaint = "HIGH_LEVEL_COMPLAINT",
  /** Insolvencies dealing */
  InsolvenciesDealing = "INSOLVENCIES_DEALING",
  /** In prison */
  InPrison = "IN_PRISON",
  /** Live to final */
  LiveToFinal = "LIVE_TO_FINAL",
  /** Low balance */
  LowBalance = "LOW_BALANCE",
  /** Negative trace */
  NegativeTrace = "NEGATIVE_TRACE",
  /** Paid in full */
  PaidInFull = "PAID_IN_FULL",
  /** Partial settlement */
  PartialSettlement = "PARTIAL_SETTLEMENT",
  /** Part payment */
  PartPayment = "PART_PAYMENT",
  /** Payment arrangement */
  PaymentArrangement = "PAYMENT_ARRANGEMENT",
  /** Payment plan agreed */
  PaymentPlanAgreed = "PAYMENT_PLAN_AGREED",
  /** PPM arrangement */
  PpmArrangement = "PPM_ARRANGEMENT",
  /** PPM fitted */
  PpmFitted = "PPM_FITTED",
  /** PPM requested */
  PpmRequested = "PPM_REQUESTED",
  /** Process exhausted */
  ProcessExhausted = "PROCESS_EXHAUSTED",
  /** Process exhausted - contact */
  ProcessExhaustedContact = "PROCESS_EXHAUSTED_CONTACT",
  /** Process exhausted - no contact */
  ProcessExhaustedNoContact = "PROCESS_EXHAUSTED_NO_CONTACT",
  /** Query */
  Query = "QUERY",
  /** Reduced settlement */
  ReducedSettlement = "REDUCED_SETTLEMENT",
  /** Refused to deal */
  RefusedToDeal = "REFUSED_TO_DEAL",
  /** Unable to locate property */
  UnableToLocateProperty = "UNABLE_TO_LOCATE_PROPERTY",
  /** Unable to trace */
  UnableToTrace = "UNABLE_TO_TRACE",
  /** Vulnerable */
  Vulnerable = "VULNERABLE",
  /** Withdrawn */
  Withdrawn = "WITHDRAWN",
  /** Write off */
  WriteOff = "WRITE_OFF",
}

export type DebtCollectionProceedingType = {
  __typename?: "DebtCollectionProceedingType";
  /** The agency responsible for the collection proceedings. */
  agency?: Maybe<DebtCollectionAgencyType>;
  amount?: Maybe<Scalars["Int"]>;
  /** The campaign type of the collection proceedings. */
  campaign?: Maybe<CollectionCampaignType>;
  startedAt: Scalars["DateTime"];
  stopReason?: Maybe<DebtCollectionProceedingStopReason>;
  stoppedAt?: Maybe<Scalars["DateTime"]>;
};

/** An action which navigates to the URL of another backend screen. */
export type DeeplinkActionType = ActionInterface &
  IdentifiableInterface & {
    __typename?: "DeeplinkActionType";
    /** Unique identifier of the object. */
    id?: Maybe<Scalars["ID"]>;
    /** The name of the action object's type. */
    typeName?: Maybe<Scalars["String"]>;
    /** The name of the object's type. */
    typename?: Maybe<Scalars["String"]>;
    /** The URL to navigate to. */
    url: Scalars["String"];
  };

/**
 * Delete a reference for a particular account and namespace.
 *
 * The possible errors that can be raised are:
 *
 * - KT-CT-4123: Unauthorized.
 * - KT-CT-8310: Invalid data.
 * - KT-CT-8312: Invalid data.
 * - KT-CT-1113: Disabled GraphQL field requested.
 * - KT-CT-1111: Unauthorized.
 * - KT-CT-1112: 'Authorization' header not provided.
 */
export type DeleteAccountReference = {
  __typename?: "DeleteAccountReference";
  accountReference?: Maybe<DeleteAccountReferenceType>;
  possibleErrors?: Maybe<Array<Maybe<PossibleErrorType>>>;
};

export type DeleteAccountReferenceInput = {
  /** The account number associated with the removed AccountReference. */
  accountNumber: Scalars["String"];
  /** The namespace associated with the removed AccountReference. */
  namespace: Scalars["String"];
};

export type DeleteAccountReferenceType = {
  __typename?: "DeleteAccountReferenceType";
  /** The namespace associated with the removed AccountReference. */
  namespace: Scalars["String"];
};

/**
 * The possible errors that can be raised are:
 *
 * - KT-CT-4301: Unable to find device for given account.
 * - KT-CT-4354: Unable to cancel boost charge.
 * - KT-CT-1113: Disabled GraphQL field requested.
 */
export type DeleteBoostCharge = {
  __typename?: "DeleteBoostCharge";
  krakenflexDevice?: Maybe<KrakenFlexDeviceType>;
  possibleErrors?: Maybe<Array<Maybe<PossibleErrorType>>>;
};

export type DeleteBoostChargeInput = {
  /** Account number. */
  accountNumber: Scalars["String"];
};

/**
 * The possible errors that can be raised are:
 *
 * - KT-CT-5411: Invalid token or no push notification binding found for the given account user.
 * - KT-CT-1113: Disabled GraphQL field requested.
 * - KT-CT-1111: Unauthorized.
 * - KT-CT-1112: 'Authorization' header not provided.
 */
export type DeletePushNotificationBinding = {
  __typename?: "DeletePushNotificationBinding";
  possibleErrors?: Maybe<Array<Maybe<PossibleErrorType>>>;
  status?: Maybe<DeletePushNotificationBindingOutput>;
};

export type DeletePushNotificationBindingInput = {
  /** Device push notification token. */
  token: Scalars["String"];
};

export enum DeletePushNotificationBindingOutput {
  Failed = "FAILED",
  Successful = "SUCCESSFUL",
}

/**
 * Permanently delete access tokens associated with a given thermostat.
 *
 * The possible errors that can be raised are:
 *
 * - KT-CT-4810: Unable to find matching device for this request.
 * - KT-CT-4302: Unable to deregister site.
 * - KT-CT-1113: Disabled GraphQL field requested.
 */
export type DeleteThermostatToken = {
  __typename?: "DeleteThermostatToken";
  possibleErrors?: Maybe<Array<Maybe<PossibleErrorType>>>;
  status?: Maybe<Scalars["String"]>;
};

/** The input type for deleting a thermostat's SmartDeviceToken object. */
export type DeleteThermostatTokenInput = {
  /** The account number. */
  accountNumber: Scalars["String"];
  /** Device ID. */
  id: Scalars["String"];
};

export type DepositAgreementInput = {
  accountNumber: Scalars["String"];
  depositKey: Scalars["String"];
};

export type DepositAgreementOutput = {
  __typename?: "DepositAgreementOutput";
  acceptedAt?: Maybe<Scalars["DateTime"]>;
  depositAmount?: Maybe<Scalars["Int"]>;
  depositKey?: Maybe<Scalars["String"]>;
  fulfilledAt?: Maybe<Scalars["DateTime"]>;
};

export type DetailsInputType = {
  /** Namespace of the detail. */
  namespace: Scalars["String"];
  /** Value of the detail. */
  value?: InputMaybe<Scalars["String"]>;
};

export type DeviceDetailsInput = {
  /** Auxiliary device variant id. */
  auxDeviceVariantId?: InputMaybe<Scalars["ID"]>;
  /** Unique device variant id. */
  deviceVariantId?: InputMaybe<Scalars["ID"]>;
};

export type DeviceDetailsType = {
  __typename?: "DeviceDetailsType";
  /** The type of device. */
  deviceType?: Maybe<KrakenFlexDeviceTypes>;
  krakenflexDeviceId?: Maybe<Scalars["String"]>;
  /** The third party that provides control over this device. */
  provider?: Maybe<ProviderChoices>;
};

/**
 * Register a device for smart controlling.
 *
 * Where device refers to batteries, electric vehicles, heat pumps or thermostats.
 *
 * The possible errors that can be raised are:
 *
 * - KT-CT-4324: Device already registered error.
 * - KT-CT-4321: Serializer validation error.
 * - KT-CT-4312: Unable to register device.
 * - KT-CT-4363: No capable devices found.
 * - KT-CT-4364: Multiple devices found.
 * - KT-CT-1113: Disabled GraphQL field requested.
 */
export type DeviceRegistration = {
  __typename?: "DeviceRegistration";
  possibleErrors?: Maybe<Array<Maybe<PossibleErrorType>>>;
  /** Device ID(s) of the registered device(s). */
  registeredDeviceIds?: Maybe<Array<Maybe<Scalars["String"]>>>;
};

export type DeviceRegistrationInput = {
  /** Account number. */
  accountNumber: Scalars["String"];
  /** The authentication details required given the chosen provider. */
  authentication?: InputMaybe<AuthenticationInput>;
  /** The device type specific details required for registering a device. */
  deviceDetails?: InputMaybe<DeviceDetailsInput>;
  /** The device type to be registered - batteries, electric vehicles, heat pumps or thermostats. */
  deviceType: KrakenFlexDeviceTypes;
  /** The ID of the property the device belongs to. */
  propertyId: Scalars["Int"];
  /** The provider used to authenticate the device. */
  provider: ProviderChoices;
};

export type DeviceStatusReadingType = {
  __typename?: "DeviceStatusReadingType";
  /** Timestamp of the reading. */
  timestamp: Scalars["DateTime"];
  /** Value of the reading. */
  value: Scalars["Float"];
};

export type DeviceStatusReadingsType = {
  __typename?: "DeviceStatusReadingsType";
  /** Device charging power in kilowatts (kW) - negative for import, positive for export. */
  activePowerInKw?: Maybe<DeviceStatusReadingType>;
  /** Energy stored in the device in kilowatt-hours (kWh). */
  energyInKwh?: Maybe<DeviceStatusReadingType>;
  /** State of charge of the battery in % (may not be a whole number). */
  socPercentage?: Maybe<DeviceStatusReadingType>;
};

export type DeviceStatusType = {
  __typename?: "DeviceStatusType";
  /** The maximum export power of the device in kilowatts. */
  maxExportInKw?: Maybe<Scalars["Float"]>;
  /** The maximum import power of the device in kilowatts. */
  maxImportInKw?: Maybe<Scalars["Float"]>;
  /** Whether device is connected. */
  pluggedIn?: Maybe<Scalars["Boolean"]>;
  /** Device status telemetry readings. */
  readings?: Maybe<DeviceStatusReadingsType>;
  /**
   * The lifecycle status of the device.
   * @deprecated The 'status' field is deprecated.
   *
   * Please use the 'devices' query instead.
   *
   * - Marked as deprecated on 2023-12-06.
   * - Will be removed on 2024-06-06.
   */
  status?: Maybe<KrakenFlexDeviceStatusChoices>;
  /** Whether control of the device has been temporarily suspended. */
  suspended?: Maybe<Scalars["Boolean"]>;
};

export type DirectDebitInstructionConnectionTypeConnection = {
  __typename?: "DirectDebitInstructionConnectionTypeConnection";
  /** Number of nodes in the edge. */
  edgeCount: Scalars["Int"];
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<DirectDebitInstructionConnectionTypeEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  /** Total number of nodes. */
  totalCount: Scalars["Int"];
};

/** A Relay edge containing a `DirectDebitInstructionConnectionType` and its cursor. */
export type DirectDebitInstructionConnectionTypeEdge = {
  __typename?: "DirectDebitInstructionConnectionTypeEdge";
  /** A cursor for use in pagination */
  cursor: Scalars["String"];
  /** The item at the end of the edge */
  node?: Maybe<DirectDebitInstructionType>;
};

/** An enumeration. */
export enum DirectDebitInstructionStatus {
  /** The account for this instruction was closed. */
  AccountClosed = "ACCOUNT_CLOSED",
  /** The instruction is active and can be used to take payments. */
  Active = "ACTIVE",
  /** The instruction could not be set up with the vendor. */
  Failed = "FAILED",
  /** The instruction has not yet been set up. */
  Provisional = "PROVISIONAL",
}

/** Direct Debit Instructions */
export type DirectDebitInstructionType = {
  __typename?: "DirectDebitInstructionType";
  accountHolder: Scalars["String"];
  cardExpiryMonth?: Maybe<Scalars["Int"]>;
  cardExpiryYear?: Maybe<Scalars["Int"]>;
  cardPaymentNetwork?: Maybe<Scalars["String"]>;
  iban: Scalars["String"];
  id: Scalars["ID"];
  instructionType: Scalars["String"];
  /**
   * The last four digits of the account number.
   * @deprecated The 'lastFourDigitsOfAccountNumber' field is deprecated.
   *
   * Use 'maskedAccountIdentifier' for a masked reference to the instruction.
   *
   * - Marked as deprecated on 2021-12-23.
   * - Will be removed on 2024-01-01.
   */
  lastFourDigitsOfAccountNumber?: Maybe<Scalars["String"]>;
  /** A masked reference to a recurring payment method. */
  maskedAccountIdentifier?: Maybe<Scalars["String"]>;
  /** A masked version of the IBAN. */
  maskedIban?: Maybe<Scalars["String"]>;
  sortCode: Scalars["String"];
  status: Scalars["String"];
};

/** An enumeration. */
export enum DocumentAccessibilityChoices {
  /** Audio-accessible documents are requested for this account. */
  Audio = "AUDIO",
  /** Braille documents are requested for this account. */
  Braille = "BRAILLE",
  /** Large print documents are requested for this account. */
  LargePrint = "LARGE_PRINT",
}

/** An enumeration. */
export enum EflFileLoadZone {
  /** LZ Houston */
  LzHouston = "LZ_HOUSTON",
  /** LZ North */
  LzNorth = "LZ_NORTH",
  /** LZ South */
  LzSouth = "LZ_SOUTH",
  /** LZ West */
  LzWest = "LZ_WEST",
}

/** An enumeration. */
export enum EflFileServiceProvider {
  /** AEP TCC */
  AepTcc = "AEP_TCC",
  /** AEP TNC */
  AepTnc = "AEP_TNC",
  /** Centerpoint */
  Centerpoint = "CENTERPOINT",
  /** LPL */
  Lpl = "LPL",
  /** ONCOR */
  Oncor = "ONCOR",
  /** TNMP */
  Tnmp = "TNMP",
}

/** An enumeration. */
export enum EflLanguage {
  En = "EN",
  Es = "ES",
}

export type EflType = {
  __typename?: "EflType";
  id: Scalars["ID"];
  language: Scalars["String"];
  loadZone: EflFileLoadZone;
  /** Code of the product. */
  productCode?: Maybe<Scalars["String"]>;
  productDisplayName?: Maybe<Scalars["String"]>;
  serviceProvider: EflFileServiceProvider;
  url?: Maybe<Scalars["String"]>;
  version: Scalars["String"];
};

export type ElectricVehicleModelType = {
  __typename?: "ElectricVehicleModelType";
  /** This field is replacing `year`. */
  availableFrom?: Maybe<Scalars["Int"]>;
  availableTo?: Maybe<Scalars["Int"]>;
  batterySize?: Maybe<Scalars["Decimal"]>;
  /** Shows the availability status of an integration. */
  integrationStatus?: Maybe<IntegrationStatus>;
  isIntegrationLive?: Maybe<Scalars["Boolean"]>;
  model?: Maybe<Scalars["String"]>;
  supportedProviders?: Maybe<Array<Maybe<Scalars["String"]>>>;
  vehicleId?: Maybe<Scalars["Int"]>;
  year?: Maybe<Scalars["Int"]>;
};

export type ElectricVehicleType = {
  __typename?: "ElectricVehicleType";
  make?: Maybe<Scalars["String"]>;
  models?: Maybe<Array<Maybe<ElectricVehicleModelType>>>;
};

/** Filter measurements by electricity parameters. */
export type ElectricityFiltersInput = {
  deviceId?: InputMaybe<Scalars["String"]>;
  marketSupplyPointId?: InputMaybe<Scalars["String"]>;
  /** Reading direction is based on the utility generated or consumed by the customer. */
  readingDirection?: InputMaybe<ReadingDirectionType>;
  /** The frequency of the reading. */
  readingFrequencyType?: InputMaybe<ReadingFrequencyType>;
  readingQuality?: InputMaybe<ReadingQualityType>;
  registerId?: InputMaybe<Scalars["String"]>;
};

export type ElectricityFiltersOutput = {
  __typename?: "ElectricityFiltersOutput";
  deviceId?: Maybe<Scalars["String"]>;
  marketSupplyPointId?: Maybe<Scalars["String"]>;
  /** Reading direction is based on the utility generated or consumed by the customer. */
  readingDirection?: Maybe<ReadingDirectionType>;
  /** The frequency of the reading. */
  readingFrequencyType?: Maybe<ReadingFrequencyType>;
  readingQuality?: Maybe<ReadingQualityType>;
  registerId?: Maybe<Scalars["String"]>;
};

/** An enumeration. */
export enum ElectricityMeterPointLoadZone {
  /** LZ Houston */
  LzHouston = "LZ_HOUSTON",
  /** LZ North */
  LzNorth = "LZ_NORTH",
  /** LZ South */
  LzSouth = "LZ_SOUTH",
  /** LZ West */
  LzWest = "LZ_WEST",
}

/** An enumeration. */
export enum ElectricityMeterPointServiceProvider {
  /** AEP TCC */
  AepTcc = "AEP_TCC",
  /** AEP TNC */
  AepTnc = "AEP_TNC",
  /** Centerpoint */
  Centerpoint = "CENTERPOINT",
  /** LPL */
  Lpl = "LPL",
  /** ONCOR */
  Oncor = "ONCOR",
  /** TNMP */
  Tnmp = "TNMP",
}

/**
 * A ElectricityMeterPointType is collection of information that can identify
 * a property.
 *
 * ElectricityMeterType allows us to retrieve the information that is
 * associated to a given property. This means the type will include all
 * the meters and agreements that are associated with the given property.
 *
 * You will need the proper permissions to query a property meter(s).
 */
export type ElectricityMeterPointType = {
  __typename?: "ElectricityMeterPointType";
  /** A list of active agreements belonging to an account that is linked to the logged in user. */
  agreements?: Maybe<Array<Maybe<AgreementType>>>;
  esiId: Scalars["String"];
  hasCriticalCare: Scalars["Boolean"];
  id: Scalars["ID"];
  /** Load Profile for the meter point. */
  loadProfile?: Maybe<Scalars["String"]>;
  loadZone: ElectricityMeterPointLoadZone;
  /** Meters associated with the property. */
  meters?: Maybe<Array<Maybe<ElectricityMeterType>>>;
  serviceProvider: ElectricityMeterPointServiceProvider;
  status?: Maybe<Scalars["String"]>;
};

/**
 * A ElectricityMeterPointType is collection of information that can identify
 * a property.
 *
 * ElectricityMeterType allows us to retrieve the information that is
 * associated to a given property. This means the type will include all
 * the meters and agreements that are associated with the given property.
 *
 * You will need the proper permissions to query a property meter(s).
 */
export type ElectricityMeterPointTypeAgreementsArgs = {
  includeFutureActiveAgreements?: InputMaybe<Scalars["Boolean"]>;
};

/**
 * A ElectricityMeterPointType is collection of information that can identify
 * a property.
 *
 * ElectricityMeterType allows us to retrieve the information that is
 * associated to a given property. This means the type will include all
 * the meters and agreements that are associated with the given property.
 *
 * You will need the proper permissions to query a property meter(s).
 */
export type ElectricityMeterPointTypeMetersArgs = {
  id?: InputMaybe<Scalars["Int"]>;
  includeInactive?: InputMaybe<Scalars["Boolean"]>;
};

export type ElectricityMeterType = {
  __typename?: "ElectricityMeterType";
  activeFrom: Scalars["DateTime"];
  activeTo?: Maybe<Scalars["DateTime"]>;
  serialNumber: Scalars["String"];
};

/** Represents a file to attach to a email message. */
export type EmailAttachmentType = {
  __typename?: "EmailAttachmentType";
  filename: Scalars["String"];
  id: Scalars["ID"];
  /** Temporary URL at which the attachment is available. This URL will expire after approximately an hour. It is intended for redirection purposes, NOT persistence in any form (e.g. inclusion in emails or the body of a web page). */
  temporaryUrl?: Maybe<Scalars["String"]>;
};

export type EmailEventType = Node & {
  __typename?: "EmailEventType";
  eventType: Scalars["String"];
  /** The ID of the object */
  id: Scalars["ID"];
  /** Email message of the email event. Returns null for message's sent/received by other user's on the account. */
  message?: Maybe<EmailType>;
  occurredAt: Scalars["DateTime"];
};

/** An enumeration. */
export enum EmailFormats {
  Html = "HTML",
  Text = "TEXT",
}

/**
 * Sends a follow-up quote email to the provided email.
 *
 * The possible errors that can be raised are:
 *
 * - KT-US-4615: Received an invalid quote code.
 * - KT-CT-1113: Disabled GraphQL field requested.
 * - KT-CT-1111: Unauthorized.
 * - KT-CT-1112: 'Authorization' header not provided.
 */
export type EmailQuote = {
  __typename?: "EmailQuote";
  possibleErrors?: Maybe<Array<Maybe<PossibleErrorType>>>;
  /** Whether the email was scheduled successfully. */
  scheduled?: Maybe<Scalars["Boolean"]>;
};

export type EmailQuoteInput = {
  /** Email address. */
  email: Scalars["String"];
  /** Quote code. */
  quoteCode: Scalars["String"];
};

/** Represents an email communication */
export type EmailType = {
  __typename?: "EmailType";
  /** Attachments of the email message. */
  attachments?: Maybe<Array<Maybe<EmailAttachmentType>>>;
  channel: MessageChannel;
  createdAt: Scalars["DateTime"];
  fromEmail: Scalars["String"];
  fromNumber: Scalars["String"];
  htmlBody?: Maybe<Scalars["String"]>;
  id: Scalars["ID"];
  /** Email recipient. */
  recipient?: Maybe<Scalars["String"]>;
  /** Email sender. */
  sender?: Maybe<Scalars["String"]>;
  sentAt?: Maybe<Scalars["DateTime"]>;
  /** Subject line of the email message. */
  subject?: Maybe<Scalars["String"]>;
  templateCode: Scalars["String"];
  textBody?: Maybe<Scalars["String"]>;
  toAddress: Scalars["String"];
  toNumber: Scalars["String"];
};

export type EmbeddedElectricityFiltersOutput = {
  __typename?: "EmbeddedElectricityFiltersOutput";
  deviceId?: Maybe<Scalars["String"]>;
  marketSupplyPointId?: Maybe<Scalars["String"]>;
  /** Reading direction is based on the utility generated or consumed by the customer. */
  readingDirection?: Maybe<ReadingDirectionType>;
  /** The frequency of the reading. */
  readingFrequencyType?: Maybe<ReadingFrequencyType>;
  readingQuality?: Maybe<ReadingQualityType>;
  registerId?: Maybe<Scalars["String"]>;
};

/** Represents an embedded network that holds multiple embedded properties. */
export type EmbeddedNetworkType = {
  __typename?: "EmbeddedNetworkType";
  /** Get details about properties in an embedded network. */
  embeddedProperties?: Maybe<Array<Maybe<EmbeddedPropertyType>>>;
  id: Scalars["ID"];
  /** A unique name/code for the network */
  name: Scalars["String"];
};

/** Represents an embedded property in an embedded network. */
export type EmbeddedPropertyType = {
  __typename?: "EmbeddedPropertyType";
  embeddedNetwork: EmbeddedNetworkType;
  id: Scalars["ID"];
  /** The id of the physical property related to this embedded property type. */
  propertyId?: Maybe<Scalars["ID"]>;
};

/**
 * The possible errors that can be raised are:
 *
 * - KT-CT-9603: Unable to find contribution agreement.
 * - KT-CT-4123: Unauthorized.
 * - KT-CT-9604: Unable to end contribution agreement.
 * - KT-CT-1113: Disabled GraphQL field requested.
 * - KT-CT-1111: Unauthorized.
 * - KT-CT-1112: 'Authorization' header not provided.
 */
export type EndContributionAgreement = {
  __typename?: "EndContributionAgreement";
  /** The created contribution agreement. */
  contributionAgreement?: Maybe<ContributionAgreementType>;
  possibleErrors?: Maybe<Array<Maybe<PossibleErrorType>>>;
};

export type EndContributionAgreementInput = {
  /** The ID of the Contribution Agreement to end. */
  contributionAgreementId: Scalars["ID"];
  /** The future end datetime of the agreement. If not given, terminate now. */
  endAt?: InputMaybe<Scalars["DateTime"]>;
};

/** Energy mix data can include the latest carbon intensity index in a region. */
export type EnergyMixDataType = {
  __typename?: "EnergyMixDataType";
  /** Current carbon intensity index. */
  carbonIntensityIndex?: Maybe<Scalars["String"]>;
};

export type EnodeLinkSessionType = {
  __typename?: "EnodeLinkSessionType";
  linkState?: Maybe<Scalars["String"]>;
  linkUrl?: Maybe<Scalars["String"]>;
};

/** Available vendors supported by Enode. */
export enum EnodeVendors {
  Audi = "AUDI",
  Bmw = "BMW",
  Chevrolet = "CHEVROLET",
  Citroen = "CITROEN",
  Cupra = "CUPRA",
  Ds = "DS",
  Fiat = "FIAT",
  Ford = "FORD",
  Hyundai = "HYUNDAI",
  Jaguar = "JAGUAR",
  Kia = "KIA",
  Mercedes = "MERCEDES",
  Mini = "MINI",
  Nissan = "NISSAN",
  Opel = "OPEL",
  Peugeot = "PEUGEOT",
  Porsche = "PORSCHE",
  Renault = "RENAULT",
  Seat = "SEAT",
  Skoda = "SKODA",
  Toyota = "TOYOTA",
  Vauxhall = "VAUXHALL",
  Volkswagen = "VOLKSWAGEN",
  Volvo = "VOLVO",
}

export type EnrollmentAccountResetPasswordInputType = {
  accountNumber: Scalars["String"];
  emailAddress: Scalars["String"];
  password: Scalars["String"];
};

/**
 * The possible errors that can be raised are:
 *
 * - KT-CT-7323: Unauthorized.
 * - KT-CT-1113: Disabled GraphQL field requested.
 * - KT-CT-1111: Unauthorized.
 * - KT-CT-1112: 'Authorization' header not provided.
 */
export type EnrollmentAccountResetPasswordMutation = {
  __typename?: "EnrollmentAccountResetPasswordMutation";
  possibleErrors?: Maybe<Array<Maybe<PossibleErrorType>>>;
  resetStatus?: Maybe<Scalars["Boolean"]>;
};

/** Enrollment was successfully initiated. */
export type EnrollmentInitiated = {
  __typename?: "EnrollmentInitiated";
  /** The Enrollment processes that were initiated. */
  enrollmentProcesses: Array<Maybe<EnrollmentProcess>>;
  /** The message to display to the user on Enrollment initiation. */
  message: Scalars["String"];
};

export type EnrollmentInput = {
  /** The Kraken account number. */
  accountNumber: Scalars["String"];
  /** Bank details for setting up a payment instruction as part of the enrollment. */
  bankDetails?: InputMaybe<BankDetailsInput>;
  /** Details of an externally created payment instruction to be recorded in Kraken. */
  instructionDetails?: InputMaybe<InstructionDetailsInput>;
  /** Details of the payment schedule(s) to be created as part of enrollment. */
  paymentSchedule?: InputMaybe<PaymentScheduleDetailsInput>;
  /** Sales info for this enrollment. */
  salesInfo: SalesInformationInput;
};

export type EnrollmentProcess =
  | JoinSupplierProcessType
  | OccupyPropertyProcessType;

/** Real-Time LMPs for Load Zones and Trading Hubs Display */
export type ErcotMarketData = {
  __typename?: "ErcotMarketData";
  /** Price returned in cents per kWh. */
  LMP?: Maybe<Scalars["Float"]>;
  /** Price returned in cents per kWh. */
  LMP5MinuteDifference?: Maybe<Scalars["Float"]>;
  /** Sum of RTORPA+RTORDPA+LMP returned in cents per kWh. */
  RtorpaRtordpaLmp?: Maybe<Scalars["Float"]>;
  /** 5 Min Change to RTORPA + RTORDPA + LMP returned in cents per kWh. */
  RtorpaRtordpaLmp5MinDifference?: Maybe<Scalars["Float"]>;
  SettlementPoint?: Maybe<SettlementPointOptions>;
  /**
   * @deprecated The 'lastUpdatedAt' field is deprecated.
   *
   * Use `last_updated_at_datetime` instead.
   *
   * - Marked as deprecated on 2023-09-28.
   * - Will be removed on 2023-12-15.
   */
  lastUpdatedAt?: Maybe<Scalars["CustomGrapheneDateTime"]>;
  /** Returns last updated as a datetime object in iso8601 standard. */
  lastUpdatedAtDatetime?: Maybe<Scalars["DateTime"]>;
};

export type ErrorType = {
  __typename?: "ErrorType";
  field: Scalars["String"];
  messages: Array<Scalars["String"]>;
};

export type ErrorTypeUnion = SerializerErrorType | SerializerFieldErrorsType;

export type EstimatedMoneyType = {
  __typename?: "EstimatedMoneyType";
  /** Monetary currency of the statistic in ISO-4217 format. */
  costCurrency: Scalars["String"];
  /**
   * Monetary cost of the statistic. This is the smallest unit of currency,
   * e.g. cents for USD or yen for JPY. Because electricity is priced as a commodity, we must
   * account for fractional cents and this field must be a Decimal. Values from this field should
   * likely not be used for accounting purposes.
   */
  estimatedAmount: Scalars["Decimal"];
  /** Net price per unit of the statistic if applicable. */
  pricePerUnit?: Maybe<PricePerUnit>;
};

/** An enumeration. */
export enum EstimationInterval {
  Annually = "ANNUALLY",
  Monthly = "MONTHLY",
}

/** An enumeration. */
export enum ExpiringTokenScope {
  /** Scope that enables account user to accept the terms and conditions for a product. */
  AcceptTermsAndConditions = "ACCEPT_TERMS_AND_CONDITIONS",
  /** Scope that enables account user to book smart meter appointments. */
  BookSmartMeterAppointments = "BOOK_SMART_METER_APPOINTMENTS",
  /** Scope that enables account user to checkout a quote (validate terms & conds and provide a payment detail). */
  CheckoutQuote = "CHECKOUT_QUOTE",
  /** Edit Customer Marketing Preference */
  EditCustomerMarketingPreference = "EDIT_CUSTOMER_MARKETING_PREFERENCE",
  /** Scope that enables account user to join campaigns. */
  JoinCampaigns = "JOIN_CAMPAIGNS",
  /** Scope that enables account user to join campaign events. */
  JoinCampaignEvents = "JOIN_CAMPAIGN_EVENTS",
  /** Scope that enables account user to generate a renewal quote and renew agreements. */
  ManageAccountRenewals = "MANAGE_ACCOUNT_RENEWALS",
  /** Scope that enables account user to manage security deposit payments for business accounts. */
  ManageBusinessSecurityDeposit = "MANAGE_BUSINESS_SECURITY_DEPOSIT",
  /** Scope that enables account user to accept goods quotes and process goods purchases. */
  ManageGoodsPurchases = "MANAGE_GOODS_PURCHASES",
  /** Scope that enables account user to do a self-serve product switch through the Dashboard. */
  ManageProductSwitch = "MANAGE_PRODUCT_SWITCH",
  /** Scope that enables account user to redeem loyalty points */
  RedeemLoyaltyPoints = "REDEEM_LOYALTY_POINTS",
  /** Scope that enables account user to report a property move-out. */
  ReportMoveOut = "REPORT_MOVE_OUT",
  /** Scope that enables user to send a loss objection for a change of supplier process. */
  SendLossObjectionForChangeOfSupplier = "SEND_LOSS_OBJECTION_FOR_CHANGE_OF_SUPPLIER",
  /** Scope that enables account user to submit customer feedback. */
  SubmitCustomerFeedback = "SUBMIT_CUSTOMER_FEEDBACK",
  /** Scope that enables account user to submit meter readings. */
  SubmitMeterReadings = "SUBMIT_METER_READINGS",
  /** Scope that enables the user to update information about themselves and their account. */
  UpdateAccountDetails = "UPDATE_ACCOUNT_DETAILS",
  /** Scope that enables account user to update their blackhole email address. */
  UpdateBlackholeEmail = "UPDATE_BLACKHOLE_EMAIL",
  /** Update Sensitive Customer Information */
  UpdateSensitiveCustomerInformation = "UPDATE_SENSITIVE_CUSTOMER_INFORMATION",
  /** Scope that enables account user to visit campaign dashboard. */
  ViewCampaignDashboards = "VIEW_CAMPAIGN_DASHBOARDS",
  /** Scope that enables account user to visit detailed property usage pages. */
  ViewDetailedUsage = "VIEW_DETAILED_USAGE",
}

export type ExternalAccountEvent = {
  __typename?: "ExternalAccountEvent";
  category: Scalars["String"];
  content?: Maybe<Scalars["JSONString"]>;
  description?: Maybe<Scalars["String"]>;
  eventType: Scalars["String"];
  id: Scalars["ID"];
  occurredAt: Scalars["DateTime"];
  subcategory: Scalars["String"];
};

/** Enum of allowable event type categories for external account events. */
export enum ExternalAccountEventCategory {
  Communications = "COMMUNICATIONS",
  Debt = "DEBT",
  Messaging = "MESSAGING",
  Mobile = "MOBILE",
  Web = "WEB",
}

/** A piece of content associated with an external account event. */
export type ExternalAccountEventContent = {
  /** The content type of the content. */
  contentType: ExternalAccountEventContentType;
  /** A human-readable description of the content. */
  description: Scalars["String"];
  /** The value of the content. */
  value: Scalars["String"];
};

/**
 * Enum of allowable content types for external account events.
 *
 * The content type field is used to determine how to display the content in the account event
 * description.
 */
export enum ExternalAccountEventContentType {
  Html = "HTML",
  Link = "LINK",
  Plaintext = "PLAINTEXT",
  S3 = "S3",
}

/** Enum of allowable event type subcategories for external account events. */
export enum ExternalAccountEventSubCategory {
  ClickToCall = "CLICK_TO_CALL",
  Dunning = "DUNNING",
  Email = "EMAIL",
  Feedback = "FEEDBACK",
  Print = "PRINT",
  PushNotification = "PUSH_NOTIFICATION",
  Sms = "SMS",
  SocialMedia = "SOCIAL_MEDIA",
  Telephone = "TELEPHONE",
  ThirdParty = "THIRD_PARTY",
  Whatsapp = "WHATSAPP",
}

export type ExternalAccountUserEvent = {
  __typename?: "ExternalAccountUserEvent";
  category: Scalars["String"];
  content?: Maybe<Scalars["JSONString"]>;
  description?: Maybe<Scalars["String"]>;
  eventType: Scalars["String"];
  id: Scalars["ID"];
  occurredAt: Scalars["DateTime"];
  subcategory: Scalars["String"];
};

export type FanClubDiscountOutput = {
  __typename?: "FanClubDiscountOutput";
  /** Magnitude of the discount as decimal ranging between 0 and 0.5. */
  discountAmount?: Maybe<Scalars["Decimal"]>;
  /** The percentage of wind generation. */
  windGenerationPercentage?: Maybe<Scalars["Decimal"]>;
};

export type FanClubWindPowerProjections = {
  __typename?: "FanClubWindPowerProjections";
  /** Future wind power projections recieved from ERCOT. */
  windPowerProjections?: Maybe<Array<Maybe<WindPowerProjection>>>;
};

export type FieldSpecificRateLimitInformation = {
  __typename?: "FieldSpecificRateLimitInformation";
  /** The name of the GraphQL field for which the rate is limited. */
  field?: Maybe<Scalars["String"]>;
  /** Whether the viewer is currently blocked from making requests to this field due to exceeding the allowed request rate. */
  isBlocked?: Maybe<Scalars["Boolean"]>;
  /** Indicates the rate limit allowed for this field (e.g. 10/m). */
  rate?: Maybe<Scalars["String"]>;
  /** Time to live: The time remaining before the user is unblocked from making requests to this field. */
  ttl?: Maybe<Scalars["Int"]>;
};

export type FieldSpecificRateLimitInformationConnectionTypeConnection = {
  __typename?: "FieldSpecificRateLimitInformationConnectionTypeConnection";
  /** Number of nodes in the edge. */
  edgeCount: Scalars["Int"];
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<FieldSpecificRateLimitInformationConnectionTypeEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  /** Total number of nodes. */
  totalCount: Scalars["Int"];
};

/** A Relay edge containing a `FieldSpecificRateLimitInformationConnectionType` and its cursor. */
export type FieldSpecificRateLimitInformationConnectionTypeEdge = {
  __typename?: "FieldSpecificRateLimitInformationConnectionTypeEdge";
  /** A cursor for use in pagination */
  cursor: Scalars["String"];
  /** The item at the end of the edge */
  node?: Maybe<FieldSpecificRateLimitInformation>;
};

export type FileAttachment = {
  fetchUrl?: Maybe<Scalars["String"]>;
  /** Is the file ready for use / downloadable? */
  isReady?: Maybe<Scalars["Boolean"]>;
  /** Is the file uploaded to S3? */
  isUploaded?: Maybe<Scalars["Boolean"]>;
  sizeInBytes?: Maybe<Scalars["Int"]>;
};

export type FinancialRiskLevelType = {
  __typename?: "FinancialRiskLevelType";
  /** The ID type. Currently only 'meter_point' is supported. */
  identifierType?: Maybe<Scalars["String"]>;
  /** The ID to be checked. */
  identifierValue?: Maybe<Scalars["ID"]>;
  /** Returns the level of risk for the supplied object between `0.0` and `1.0`. Higher is worse. */
  riskLevel?: Maybe<Scalars["Decimal"]>;
};

export type ForceReauthentication = {
  __typename?: "ForceReauthentication";
  /** The time at which forced reauthentication is effective.  Kraken and refresh tokens issued before this time will be invalid. */
  effectiveAt: Scalars["DateTime"];
  /** Reports whether the mutation applied successfully.  Should always be 'true'. */
  tokensInvalidated: Scalars["Boolean"];
};

/** The input type for repudiating previously issued Kraken Tokens and refresh tokens. */
export type ForceReauthenticationInput = {
  /** Also force third-party applications you have authorized to use your account to reauthenticate. */
  includeThirdParties: Scalars["Boolean"];
};

export type FormSubmissionInput = {
  accountNumber: Scalars["String"];
  /** Form content. */
  content: Scalars["JSONString"];
  /** Form type. */
  formType?: InputMaybe<FormType>;
};

export type FormSubmissionOuput = {
  __typename?: "FormSubmissionOuput";
  content?: Maybe<Scalars["JSONString"]>;
  errors?: Maybe<Array<Maybe<SerializerFieldErrorsType>>>;
  id?: Maybe<Scalars["Int"]>;
};

/** An enumeration. */
export enum FormType {
  /** Covid-19 Financial Energy Assessment */
  Covid_19FinancialEnergyAssessment = "COVID_19_FINANCIAL_ENERGY_ASSESSMENT",
  /** Covid-19 Gas Prices Financial Energy Assessment */
  Covid_19GasPricesFinancialEnergyAssessment = "COVID_19_GAS_PRICES_FINANCIAL_ENERGY_ASSESSMENT",
}

/** A fractional measurement. */
export type FractionSizeType = IdentifiableInterface & {
  __typename?: "FractionSizeType";
  /** The fractional value. */
  fraction: Scalars["Decimal"];
  /** Unique identifier of the object. */
  id?: Maybe<Scalars["ID"]>;
  /** The name of the object's type. */
  typename?: Maybe<Scalars["String"]>;
};

export type FraudAlerts = {
  /** Fraud alert's code. */
  code?: InputMaybe<Scalars["Int"]>;
  description?: InputMaybe<Scalars["String"]>;
};

export type FraudMeterPointCheckType = {
  __typename?: "FraudMeterPointCheckType";
  /** Returns True if the meter point ID is in the list of suspicious meter point IDs. */
  isInSuspiciousList?: Maybe<Scalars["Boolean"]>;
  /** The meter point ID. */
  meterPointId?: Maybe<Scalars["String"]>;
};

/** Filter measurements by gas parameters. */
export type GasFiltersOutput = {
  __typename?: "GasFiltersOutput";
  deviceId?: Maybe<Scalars["String"]>;
  marketSupplyPointId?: Maybe<Scalars["String"]>;
  /** The frequency of the reading. */
  readingFrequencyType?: Maybe<ReadingFrequencyType>;
  registerId?: Maybe<Scalars["String"]>;
};

/**
 * The possible errors that can be raised are:
 *
 * - KT-CT-7620: Channel not supported.
 * - KT-CT-7618: Unable to process message.
 * - KT-CT-7624: Error when generating the presigned URL.
 * - KT-CT-1113: Disabled GraphQL field requested.
 * - KT-CT-1111: Unauthorized.
 * - KT-CT-1112: 'Authorization' header not provided.
 */
export type GenerateInkPresignedUrl = {
  __typename?: "GenerateInkPresignedUrl";
  /** Presigned post fields required to upload the file. */
  fields: Scalars["JSONString"];
  /** The key for the item. */
  key: Scalars["String"];
  possibleErrors?: Maybe<Array<Maybe<PossibleErrorType>>>;
  /**
   * Presigned post fields; key, awsAccessKeyId, xAmzSecurityToken, policy, signature.
   * @deprecated Replaced in favor of fields.
   */
  presignedPostFields?: Maybe<PresignedPostFields>;
  /** A presigned URL for the user to upload to the quarantine bucket. */
  uploadUrl: Scalars["String"];
};

export type GenerateInkPresignedUrlInput = {
  /** The channel of the contact. */
  channel: InkCommunicationChannel;
  /** The name of the file. */
  filename: Scalars["String"];
};

/**
 * Mutation to generate a pre-signed token.
 *
 * The pre-signed, expiring and opaque tokens will be swapped
 * for a limited scope JWT (Kraken Token).
 *
 * The possible errors that can be raised are:
 *
 * - KT-CT-1128: Unauthorized.
 * - KT-CT-1120: The Kraken Token has expired.
 * - KT-CT-1131: Unauthorized.
 * - KT-CT-1113: Disabled GraphQL field requested.
 * - KT-CT-1111: Unauthorized.
 * - KT-CT-1112: 'Authorization' header not provided.
 */
export type GeneratePreSignedToken = {
  __typename?: "GeneratePreSignedToken";
  possibleErrors?: Maybe<Array<Maybe<PossibleErrorType>>>;
  scope?: Maybe<PreSignedTokenScope>;
  token?: Maybe<Scalars["String"]>;
  tokenExpiryDatetime?: Maybe<Scalars["DateTime"]>;
};

/** A generic backend screen that can be used to define any type of screen. */
export type GenericBackendScreen = BackendScreenInterface & {
  __typename?: "GenericBackendScreen";
  /** The name of the screen. */
  name: Scalars["String"];
  /** The refresh / polling frequency in milliseconds. */
  refreshFrequency?: Maybe<Scalars["Int"]>;
  /** Serialized JSON representation of the screen. */
  screenData?: Maybe<Scalars["String"]>;
};

/**
 * Get the client secret needed to create a new payment instruction using an embedded form.
 *
 * The possible errors that can be raised are:
 *
 * - KT-CT-4177: Unauthorized.
 * - KT-CT-1113: Disabled GraphQL field requested.
 * - KT-CT-1111: Unauthorized.
 * - KT-CT-1112: 'Authorization' header not provided.
 */
export type GetEmbeddedSecretForNewPaymentInstruction = {
  __typename?: "GetEmbeddedSecretForNewPaymentInstruction";
  possibleErrors?: Maybe<Array<Maybe<PossibleErrorType>>>;
  secretKey?: Maybe<Scalars["String"]>;
};

/** The input for getting the client secret for an embedded new card payment method form. */
export type GetEmbeddedSecretForNewPaymentInstructionInput = {
  /** The account number. */
  accountNumber: Scalars["String"];
  /** The type of the new payment instruction. */
  instructionType: PaymentType;
  /**
   * **WARNING: Will be mandatory in future versions**
   *
   *  The ledger ID.
   */
  ledgerId?: InputMaybe<Scalars["String"]>;
};

/**
 * Get external URL where the user can set up a payment instruction.
 *
 * The possible errors that can be raised are:
 *
 * - KT-CT-1128: Unauthorized.
 * - KT-CT-3822: Unauthorized.
 * - KT-CT-1113: Disabled GraphQL field requested.
 * - KT-CT-1111: Unauthorized.
 * - KT-CT-1112: 'Authorization' header not provided.
 */
export type GetHostedUrlForNewPaymentInstruction = {
  __typename?: "GetHostedUrlForNewPaymentInstruction";
  possibleErrors?: Maybe<Array<Maybe<PossibleErrorType>>>;
  /** URL at which payment instruction can be set up. */
  url?: Maybe<Scalars["String"]>;
};

/** The input needed for getting the external URL for setting up a payment instruction. */
export type GetHostedUrlForNewPaymentInstructionInput = {
  /** The account number. */
  accountNumber: Scalars["String"];
  /** The type of the new payment instruction. */
  instructionType: PaymentType;
  /** The ledger number. */
  ledgerNumber: Scalars["String"];
  /** The URL to redirect the user to after the action was cancelled. */
  returnUrlCancel?: InputMaybe<Scalars["String"]>;
  /** The URL to redirect the user to after the action resulted in an error. */
  returnUrlError?: InputMaybe<Scalars["String"]>;
  /** The URL to redirect the user to after the action resulted in a failure. */
  returnUrlFailure?: InputMaybe<Scalars["String"]>;
  /** The URL to redirect the user to after the action was completed successfuly. */
  returnUrlSuccess?: InputMaybe<Scalars["String"]>;
};

export type GetQuoteOutputType = {
  __typename?: "GetQuoteOutputType";
  /** Code generated for a quote request. */
  code?: Maybe<Scalars["String"]>;
  /** Indicates whether this quote has expired. A valid quote will return False. */
  isExpired?: Maybe<Scalars["Boolean"]>;
  /** Unique id of the quote. */
  quoteId?: Maybe<Scalars["ID"]>;
  /** Returns quoted products with estimated rates based on the generated quote request. */
  quotedProducts?: Maybe<Array<Maybe<QuotedProducts>>>;
};

export type GetThermostatDetailsOutput = {
  __typename?: "GetThermostatDetailsOutput";
  thermostats?: Maybe<Array<Maybe<GetThermostatDetailsType>>>;
};

/** A smart thermostat device associated with this Kraken account. */
export type GetThermostatDetailsType = {
  __typename?: "GetThermostatDetailsType";
  /** Device's reported current indoor temperature, in F. */
  currentTemp?: Maybe<Scalars["String"]>;
  /** Device type. */
  deviceType?: Maybe<Scalars["String"]>;
  /** Device vendor. */
  deviceVendor?: Maybe<Scalars["String"]>;
  /** Whether this device can be controlled by Kraken. */
  enabled?: Maybe<Scalars["Boolean"]>;
  /** Hold is currently in effect. */
  hasActiveHold?: Maybe<Scalars["Boolean"]>;
  /** HVAC operations mode. */
  hvacMode?: Maybe<Scalars["String"]>;
  /** Unique device ID. */
  id?: Maybe<Scalars["String"]>;
  /** User-fiendly name for this device. */
  name?: Maybe<Scalars["String"]>;
  tokenStatus?: Maybe<TokenStatuses>;
};

export type GoodsGrant = {
  __typename?: "GoodsGrant";
  /** Currency. */
  currency?: Maybe<Scalars["String"]>;
  /** Gross amount. */
  grossAmount?: Maybe<Scalars["Float"]>;
  /** Net amount. */
  netAmount?: Maybe<Scalars["Float"]>;
  /** Grant type. */
  type?: Maybe<Scalars["String"]>;
};

export type GoodsProduct = {
  __typename?: "GoodsProduct";
  /** Product available from. */
  availableFrom?: Maybe<Scalars["Date"]>;
  /** Product available to. */
  availableTo?: Maybe<Scalars["Date"]>;
  /** Product code. */
  code?: Maybe<Scalars["String"]>;
  /** Currency. */
  currency?: Maybe<Scalars["String"]>;
  /** Product customer name. */
  customerName?: Maybe<Scalars["String"]>;
  /** Product description. */
  description?: Maybe<Scalars["String"]>;
  /** Gross price per unit. */
  grossPricePerUnit?: Maybe<Scalars["Float"]>;
  /** Product ID. */
  id?: Maybe<Scalars["Int"]>;
  /** Product internal name. */
  internalName?: Maybe<Scalars["String"]>;
  /** Market of the product. */
  marketName?: Maybe<Scalars["String"]>;
  /** Product notes. */
  notes?: Maybe<Scalars["String"]>;
  /** Price per unit. */
  pricePerUnit?: Maybe<Scalars["Float"]>;
  /** Type of the product. */
  productType?: Maybe<GoodsProductType>;
};

export type GoodsProductConnectionTypeConnection = {
  __typename?: "GoodsProductConnectionTypeConnection";
  /** Number of nodes in the edge. */
  edgeCount: Scalars["Int"];
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<GoodsProductConnectionTypeEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  /** Total number of nodes. */
  totalCount: Scalars["Int"];
};

/** A Relay edge containing a `GoodsProductConnectionType` and its cursor. */
export type GoodsProductConnectionTypeEdge = {
  __typename?: "GoodsProductConnectionTypeEdge";
  /** A cursor for use in pagination */
  cursor: Scalars["String"];
  /** The item at the end of the edge */
  node?: Maybe<GoodsProduct>;
};

export type GoodsProductType = {
  __typename?: "GoodsProductType";
  /** Product type code. */
  code?: Maybe<Scalars["String"]>;
  /** Product type name. */
  internalName?: Maybe<Scalars["String"]>;
};

export type GoodsPurchase = {
  __typename?: "GoodsPurchase";
  /** Client parameters of the purchase. */
  clientParams?: Maybe<Scalars["JSONString"]>;
  /** Purchase code. */
  code?: Maybe<Scalars["String"]>;
  /** Grants that apply in this purchase. */
  goodsGrants?: Maybe<Array<Maybe<GoodsGrant>>>;
  /** Sale items in this purchase. */
  goodsSaleItems?: Maybe<Array<Maybe<GoodsSaleItem>>>;
  /** Ledger ID associated to the purchase. */
  ledgerId?: Maybe<Scalars["String"]>;
  /** Market parameters of the purchase. */
  marketParams?: Maybe<Scalars["JSONString"]>;
};

export type GoodsQuote = {
  __typename?: "GoodsQuote";
  /** Code of the quote. */
  code?: Maybe<Scalars["String"]>;
  /** Products of this quote. */
  goodsQuotedProducts?: Maybe<Array<Maybe<GoodsQuotedProduct>>>;
  /** Indicates whether or not the quote is expired. */
  hasQuoteExpired?: Maybe<Scalars["Boolean"]>;
  /** ID of the quote. */
  id?: Maybe<Scalars["Int"]>;
  /** Date and time when the quote was created. */
  quotedAt?: Maybe<Scalars["DateTime"]>;
  /** Total net amount of the quote in cents. */
  totalNetAmount?: Maybe<Scalars["Int"]>;
};

export type GoodsQuoteShare = {
  __typename?: "GoodsQuoteShare";
  /** The ID of the quote share. */
  id?: Maybe<Scalars["Int"]>;
};

export type GoodsQuotedProduct = {
  __typename?: "GoodsQuotedProduct";
  /** Currency. */
  currency?: Maybe<Scalars["String"]>;
  /** Net amount. */
  netAmount?: Maybe<Scalars["Int"]>;
  /** Number of units. */
  numberOfUnits?: Maybe<Scalars["Int"]>;
  /** Price per unit. */
  pricePerUnit?: Maybe<Scalars["Int"]>;
  /** Product code. */
  product?: Maybe<Scalars["String"]>;
};

export type GoodsSaleItem = {
  __typename?: "GoodsSaleItem";
  /** Currency. */
  currency?: Maybe<Scalars["String"]>;
  /** Gross amount. */
  grossAmount?: Maybe<Scalars["Float"]>;
  /** Net amount. */
  netAmount?: Maybe<Scalars["Float"]>;
  /** Number of units. */
  numberOfUnits?: Maybe<Scalars["Int"]>;
  /** Price per unit. */
  pricePerUnit?: Maybe<Scalars["Float"]>;
  /** Product code. */
  product?: Maybe<Scalars["String"]>;
};

/** An enumeration. */
export enum HardshipAgreementHardshipEntryReason {
  /** Financial counsellor or external agent referral */
  ExternalReference = "EXTERNAL_REFERENCE",
  /** Retailer referral */
  RetailerReferral = "RETAILER_REFERRAL",
  /** Customer self-identified as being in hardship */
  SelfIdentified = "SELF_IDENTIFIED",
}

/** An enumeration. */
export enum HardshipAgreementHardshipType {
  /** Death in the family */
  DeathInFamily = "DEATH_IN_FAMILY",
  /** Family violence */
  FamilyViolence = "FAMILY_VIOLENCE",
  /** Household illness */
  HouseholdIllness = "HOUSEHOLD_ILLNESS",
  /** Other */
  Other = "OTHER",
  /** Reduced income */
  ReducedIncome = "REDUCED_INCOME",
  /** Unemployment */
  Unemployment = "UNEMPLOYMENT",
}

/** Represents a Hardship Agreement for a particular Account. */
export type HardshipAgreementType = {
  __typename?: "HardshipAgreementType";
  /** These are internal notes detailing the hardship. */
  hardshipDetails: Scalars["String"];
  hardshipEntryReason?: Maybe<HardshipAgreementHardshipEntryReason>;
  hardshipType: HardshipAgreementHardshipType;
  id: Scalars["ID"];
  paymentPlanDetails: Scalars["String"];
  startDate: Scalars["Date"];
};

export type HeldStatus = {
  __typename?: "HeldStatus";
  /** Whether a statement is currently held. */
  isHeld?: Maybe<Scalars["Boolean"]>;
  /** Reason for statement being held. */
  reason?: Maybe<Scalars["String"]>;
};

/** The identity of an object. */
export type IdentifiableInterface = {
  /** Unique identifier of the object. */
  id?: Maybe<Scalars["ID"]>;
  /** The name of the object's type. */
  typename?: Maybe<Scalars["String"]>;
};

/** A media element containing an image. */
export type ImageType = AccessibilityInterface &
  IdentifiableInterface &
  MediaInterface &
  SizedItemInterface & {
    __typename?: "ImageType";
    /** Whether the element is hidden from view. */
    accessibilityHidden?: Maybe<Scalars["Boolean"]>;
    /** Accessible description of the element. */
    accessibilityLabel?: Maybe<Scalars["String"]>;
    /** The horizontal alignment of the media. */
    horizontalAlignment?: Maybe<Alignment>;
    /** Unique identifier of the object. */
    id?: Maybe<Scalars["ID"]>;
    /** The resource URL of the media. */
    mediaUrl: Scalars["String"];
    /** The name of the object's type. */
    typename?: Maybe<Scalars["String"]>;
    /** The measurement of the element. */
    width?: Maybe<ItemSizeType>;
  };

/**
 * Initiate a standalone payment and return the client secret required to complete it.
 *
 * The possible errors that can be raised are:
 *
 * - KT-CT-1128: Unauthorized.
 * - KT-CT-3822: Unauthorized.
 * - KT-CT-3943: Invalid ledger.
 * - KT-CT-3957: No collection method provided.
 * - KT-CT-3958: Provide either ledger ID or ledger number.
 * - KT-CT-1113: Disabled GraphQL field requested.
 * - KT-CT-1111: Unauthorized.
 * - KT-CT-1112: 'Authorization' header not provided.
 */
export type InitiateHostedStandalonePayment = {
  __typename?: "InitiateHostedStandalonePayment";
  /** The details required to refer to and complete a hosted payment. */
  payment?: Maybe<InitiateHostedStandalonePaymentOutput>;
  possibleErrors?: Maybe<Array<Maybe<PossibleErrorType>>>;
};

/**
 * Input fields for initiating a hosted standalone payment.
 *
 * The amount should always be provided in the minor unit of currency (e.g., pence not pounds,
 * cents not dollars, etc.).
 *
 * A standalone payment can be made against a specific ledger (e.g., a debt ledger) by
 * providing the ledger id. Accounts have a default ledger that will be used if not provided.
 */
export type InitiateHostedStandalonePaymentInput = {
  /** The account number. */
  accountNumber: Scalars["String"];
  /** The amount to be collected in the minor unit of currency. */
  amount: Scalars["Int"];
  /** The method by which the payment is being collected. */
  collectionMethod: CollectionMethod;
  /** A description of the purpose of the payment. */
  description: Scalars["String"];
  /** The id of the specific ledger against which this payment should be applied. Please provide either ledger number or ID. */
  ledgerId?: InputMaybe<Scalars["ID"]>;
  /** The number of the specific ledger against which this payment should be applied. Please provide either ledger number or ID. */
  ledgerNumber?: InputMaybe<Scalars["String"]>;
  /** The URL to redirect the user to after the action was cancelled. */
  returnUrlCancel?: InputMaybe<Scalars["String"]>;
  /** The URL to redirect the user to after the action resulted in an error. */
  returnUrlError?: InputMaybe<Scalars["String"]>;
  /** The URL to redirect the user to if the url is not longer valid. */
  returnUrlExpired?: InputMaybe<Scalars["String"]>;
  /** The URL to redirect the user to after the action resulted in a failure. */
  returnUrlFailure?: InputMaybe<Scalars["String"]>;
  /** The URL to redirect the user to after the action was completed but the payment is still being processed. */
  returnUrlPending?: InputMaybe<Scalars["String"]>;
  /** The URL to redirect the user to after the action was completed successfuly. */
  returnUrlSuccess?: InputMaybe<Scalars["String"]>;
};

/** Tokens required to collect and retrieve a standalone payment. */
export type InitiateHostedStandalonePaymentOutput = {
  __typename?: "InitiateHostedStandalonePaymentOutput";
  /** The retrieval token for this standalone payment. */
  retrievalToken: Scalars["String"];
  /** The url for the customer to complete the payment. */
  url: Scalars["String"];
};

/**
 * Mutation for the initiation of house move out (MVO).
 *
 * The possible errors that can be raised are:
 *
 * - KT-CT-6623: Unauthorized.
 * - KT-US-6619: Can't Perform house move out (MVO).
 * - KT-US-6614: Unauthorized. Can't perform a house move out (MVO).
 * - KT-US-6615: House move out (MVO) process in progress.
 * - KT-US-6617: House move-out not possible. Move-out date can't be before today.
 * - KT-CT-4922: Unauthorized.
 * - KT-US-6616: Can't Perform House move out (MVO). Property must have exactly 1 meter point attached to it.
 * - KT-US-6618: Can't Perform house move out (MVO).
 * - KT-CT-1113: Disabled GraphQL field requested.
 * - KT-CT-1111: Unauthorized.
 * - KT-CT-1112: 'Authorization' header not provided.
 */
export type InitiateMoveOut = {
  __typename?: "InitiateMoveOut";
  /** Returns True if request was successful, False otherwise. */
  initiateMoveOutData?: Maybe<InitiateMoveOutOutputType>;
  possibleErrors?: Maybe<Array<Maybe<PossibleErrorType>>>;
};

/** Input type for initiating a property MVO (Move Out). */
export type InitiateMoveOutInputType = {
  /** Active account number. */
  accountNumber: Scalars["String"];
  /** Datetime at which to schedule a move out. */
  moveOutDate: Scalars["DateTime"];
  /** Additional context or information for MVO (Move Out) initiation. */
  note?: InputMaybe<Scalars["String"]>;
  /** Boolean to indicate if the electricity meter point should be removed during (MVO) move-out; defaults to false for normal MVO. */
  removeMeterPoint?: InputMaybe<Scalars["Boolean"]>;
};

export type InitiateMoveOutOutputType = {
  __typename?: "InitiateMoveOutOutputType";
  /** Returns True if request was successful, False otherwise. */
  moveOutRequest?: Maybe<Scalars["Boolean"]>;
};

/**
 * The possible errors that can be raised are:
 *
 * - KT-CT-1111: Unauthorized.
 * - KT-CT-4619: Quote with given code not found.
 * - KT-CT-4624: Unable to accept the given product code.
 * - KT-CT-4626: No product selected for the given quote code.
 * - KT-CT-4719: No supply point found for identifier provided.
 * - KT-CT-4922: Unauthorized.
 * - KT-CT-1507: Agreement product switch date is not within the acceptable range.
 * - KT-CT-1113: Disabled GraphQL field requested.
 * - KT-CT-1111: Unauthorized.
 * - KT-CT-1112: 'Authorization' header not provided.
 */
export type InitiateProductSwitch = {
  __typename?: "InitiateProductSwitch";
  possibleErrors?: Maybe<Array<Maybe<PossibleErrorType>>>;
  /** The selected product for a specific product switch. */
  productCode: Scalars["String"];
  /** The date at which the product switch becomes effective. */
  switchDate: Scalars["Date"];
};

export type InitiateProductSwitchInput = {
  /** Identification of the account that requesting the product switch. */
  accountNumber: Scalars["String"];
  /** ID of the selected quoted product, obtain from quoting that supply point. */
  quotedProductId: Scalars["ID"];
  /** The date at which the product switch becomes effective. */
  switchDate: Scalars["Date"];
  /** The user for whom to perform the update. This is only needed when using an Organisation role. */
  userId?: InputMaybe<Scalars["ID"]>;
};

/**
 * Initiate a standalone payment and return the client secret required to complete it.
 *
 * The possible errors that can be raised are:
 *
 * - KT-CT-4177: Unauthorized.
 * - KT-CT-3943: Invalid ledger.
 * - KT-CT-1113: Disabled GraphQL field requested.
 * - KT-CT-1111: Unauthorized.
 * - KT-CT-1112: 'Authorization' header not provided.
 */
export type InitiateStandalonePayment = {
  __typename?: "InitiateStandalonePayment";
  payment?: Maybe<InitiateStandalonePaymentOutput>;
  possibleErrors?: Maybe<Array<Maybe<PossibleErrorType>>>;
};

/**
 * Input fields for initiating a standalone payment.
 *
 * The amount should always be provided in the minor unit of currency (e.g., pence not pounds,
 * cents not dollars, etc.).
 *
 * A standalone payment can be made against a specific ledger (e.g., a debt ledger) by
 * providing the ledger id. Accounts have a default ledger that will be used if not provided.
 */
export type InitiateStandalonePaymentInput = {
  /** The account number. */
  accountNumber: Scalars["String"];
  /** The amount to be collected in the minor unit of currency. */
  amount: Scalars["Int"];
  /** The method by which the payment is being collected. */
  collectionMethod?: InputMaybe<CollectionMethod>;
  /** A description of the purpose of the payment. */
  description: Scalars["String"];
  /** The id of the specific ledger against which this payment should be applied. */
  ledgerId?: InputMaybe<Scalars["ID"]>;
};

/** Tokens required to collect and retrieve a standalone payment. */
export type InitiateStandalonePaymentOutput = {
  __typename?: "InitiateStandalonePaymentOutput";
  /** The retrieval token for this standalone payment. */
  retrievalToken: Scalars["String"];
  /** The secret used to collect the payment. */
  secretToken: Scalars["String"];
};

/**
 * Mutation for initiating a transfer of service.
 *
 * The possible errors that can be raised are:
 *
 * - KT-CT-6623: Unauthorized.
 * - KT-CT-4922: Unauthorized.
 * - KT-US-4519: TDSP outside of supported service areas.
 * - KT-US-4415: Unable to find address for ESI ID.
 * - KT-US-6613: Unable to transfer service.
 * - KT-CT-1113: Disabled GraphQL field requested.
 * - KT-CT-1111: Unauthorized.
 * - KT-CT-1112: 'Authorization' header not provided.
 */
export type InitiateTransferOfService = {
  __typename?: "InitiateTransferOfService";
  /** Returns new account number. */
  initiateTransferOfServiceData?: Maybe<InitiateTransferOfServiceOutputType>;
  possibleErrors?: Maybe<Array<Maybe<PossibleErrorType>>>;
};

/** Input type for initiating a transfer of service. */
export type InitiateTransferOfServiceInputType = {
  /** Active account number. */
  accountNumber: Scalars["String"];
  /** Defaults to False. */
  hasCriticalCare?: InputMaybe<Scalars["Boolean"]>;
  /** Datetime at which the move in/switch request should be initiated. */
  moveInDatetime: Scalars["DateTime"];
  /** Datetime at which to move out. */
  moveOutDatetime: Scalars["DateTime"];
  /** New service address meter point ESI ID. */
  newEsiId: Scalars["String"];
  /** Either move-in or switch request. */
  requestedStatus: ShellAccountEnrollmentTypeEnum;
  /** Should the new service address be used for the billing address. */
  useServiceAddressAsBillingAddress?: InputMaybe<Scalars["Boolean"]>;
};

export type InitiateTransferOfServiceOutputType = {
  __typename?: "InitiateTransferOfServiceOutputType";
  /** New account number. */
  newAccountNumber?: Maybe<Scalars["String"]>;
};

export type InkBucket = Node & {
  __typename?: "InkBucket";
  /** The icon code point. */
  icon?: Maybe<Scalars["String"]>;
  /** The ID of the object */
  id: Scalars["ID"];
  /** The ink bucket name. */
  name: Scalars["String"];
};

/** An enumeration. */
export enum InkCommunicationChannel {
  Email = "EMAIL",
  GenericApi = "GENERIC_API",
  Post = "POST",
  Sms = "SMS",
}

export type InkContactChannelIdentities = {
  __typename?: "InkContactChannelIdentities";
  /** All contacts for this conversation. */
  all: Array<InkContactChannelIdentity>;
  /** The default contact for this conversation. */
  default?: Maybe<InkContactChannelIdentity>;
};

export type InkContactChannelIdentity = {
  __typename?: "InkContactChannelIdentity";
  /** The channel of the contact. */
  channel: InkCommunicationChannel;
  /** The name to display to the user. */
  displayName: Scalars["String"];
  /** The handle. */
  handle: Scalars["String"];
};

export type InkConversation = {
  __typename?: "InkConversation";
  /** The account users on the conversation. */
  accountUsers?: Maybe<Array<AccountUserType>>;
  /** The buckets the conversation is currently in. */
  buckets?: Maybe<Array<InkBucket>>;
  /** The contact channel identities associated with this conversation. */
  contactChannelIdentities: InkContactChannelIdentities;
  /** Conversation events. */
  events: InkConversationEventsConnection;
  id: Scalars["ID"];
  /** The status of the conversation. */
  status: InkConversationStatus;
};

export type InkConversationEventsArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
};

export type InkConversationEvent = InkNewMessage;

export type InkConversationEventInterface = {
  /** The time the conversation event occurred. */
  occurredAt: Scalars["DateTime"];
};

export type InkConversationEventsConnection = {
  __typename?: "InkConversationEventsConnection";
  /** Contains the nodes in this connection. */
  edges: Array<InkConversationEventsEdge>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
};

/** A Relay edge containing a `InkConversationEvents` and its cursor. */
export type InkConversationEventsEdge = {
  __typename?: "InkConversationEventsEdge";
  /** A cursor for use in pagination */
  cursor: Scalars["String"];
  /** The item at the end of the edge */
  node: InkConversationEvent;
};

export enum InkConversationStatus {
  Closed = "CLOSED",
  Open = "OPEN",
  OpenCustomerReplied = "OPEN_CUSTOMER_REPLIED",
  OpenNew = "OPEN_NEW",
  OpenReminded = "OPEN_REMINDED",
  Snoozed = "SNOOZED",
}

/** This type wraps around the `Message` type for emails. */
export type InkEmail = {
  __typename?: "InkEmail";
  /** Attachments on the message. */
  attachments: Array<InkMessageAttachment>;
  /** CC recipients on the message. */
  cc: Array<Scalars["String"]>;
  /** The contact channel identity. */
  contactChannelIdentity: InkContactChannelIdentity;
  /** The delivery status of the message. */
  delivery: InkMessageDelivery;
  /** The direction of the email. */
  direction: InkMessageDirection;
  /** The content of the message. */
  displayContent: Scalars["String"];
  /** From email address. */
  fromHandle: Scalars["String"];
  /** Is this an message an email. */
  isChannelEmail: Scalars["Boolean"];
  /** The time the message was sent/received. */
  occurredAt: Scalars["DateTime"];
  /** The email subject. */
  subject: Scalars["String"];
  /** All Tags associated with a message. */
  tags: Array<InkTag>;
  /** The addresses that the message was sent to. */
  toHandles?: Maybe<Array<Scalars["String"]>>;
};

/** This type is used to create an inbound email. */
export type InkEmailMessageInput = {
  /** Message attachments. */
  attachments?: InputMaybe<Array<InkGenericMessageAttachmentInput>>;
  /** The carbon copy (cc) email addresses the message was sent to. */
  ccAddresses?: InputMaybe<Array<Scalars["Email"]>>;
  /** The email address the message was sent from. */
  fromAddress: Scalars["Email"];
  /** The content of the message, as plain text. */
  plainTextContent: Scalars["String"];
  /** The S3 bucket in which the original email is stored. */
  s3Bucket?: InputMaybe<Scalars["String"]>;
  /** The S3 key of the original email. */
  s3Key?: InputMaybe<Scalars["String"]>;
  /** The email subject/title. */
  subject: Scalars["String"];
  /** The email addresses the message was sent to. */
  toAddresses: Array<Scalars["Email"]>;
};

/**
 * This message type is used for messages that belong to contact
 * channels without a more granular message type.
 */
export type InkGenericMessage = Node & {
  __typename?: "InkGenericMessage";
  /** The contact channel identity. */
  contactChannelIdentity: InkContactChannelIdentity;
  /** The delivery status. */
  delivery: InkMessageDelivery;
  /** The direction of the message. */
  direction: InkMessageDirection;
  /** The content of the message. */
  displayContent: Scalars["String"];
  /** The identity the message was sent from. */
  fromHandle: Scalars["String"];
  /** The ID of the object */
  id: Scalars["ID"];
  /** The time the message was sent/received at. */
  occurredAt: Scalars["DateTime"];
  /** The identity the message was sent to. */
  toHandle: Scalars["String"];
};

export type InkGenericMessageAttachmentInput = {
  /** The S3 bucket of the attachment. */
  s3Bucket: Scalars["String"];
  /** The S3 key of the attachment. */
  s3Key: Scalars["String"];
};

/** This type is used to create an generic message. */
export type InkGenericMessageInput = {
  /** Message attachments. */
  attachments?: InputMaybe<Array<InkGenericMessageAttachmentInput>>;
  /** The identity the message was sent from. */
  fromHandle: Scalars["String"];
  /** The content of the message, as plain text. */
  plainTextContent: Scalars["String"];
  /** The identity the message was sent to. */
  toHandle: Scalars["String"];
};

/** This type wraps around the `Message` type for LINE message. */
export type InkLine = {
  __typename?: "InkLine";
  /** Attachments on the LINE message. */
  attachments: Array<InkMessageAttachment>;
  /** The contact channel identity. */
  contactChannelIdentity: InkContactChannelIdentity;
  /** The delivery status of the message. */
  delivery: InkMessageDelivery;
  /** The direction of the message. */
  direction: InkMessageDirection;
  /** From LINE id. */
  fromHandle: Scalars["String"];
  /** Is this a LINE message. */
  isChannelLine: Scalars["Boolean"];
  /** The line message content. */
  lineMessage: LineMessage;
  /** The time the message was sent/received. */
  occurredAt: Scalars["DateTime"];
  /** All Tags associated with a message. */
  tags: Array<InkTag>;
  /** To LINE id. */
  toHandle: Scalars["String"];
};

export type InkMessage =
  | InkEmail
  | InkGenericMessage
  | InkLine
  | InkPost
  | InkSms
  | InkWhatsApp;

export type InkMessageAttachment = {
  __typename?: "InkMessageAttachment";
  /** The url for fetching the attachment. */
  fetchUrl?: Maybe<Scalars["String"]>;
  /** The filename. */
  filename: Scalars["String"];
  /** The size in bytes. */
  sizeInBytes?: Maybe<Scalars["Int"]>;
};

export type InkMessageDelivery = {
  __typename?: "InkMessageDelivery";
  /** Message delivery status. */
  status: InkMessageDeliveryStatus;
};

export enum InkMessageDeliveryStatus {
  Delivered = "DELIVERED",
  Failed = "FAILED",
  OutsideReplyWindow = "OUTSIDE_REPLY_WINDOW",
  Pending = "PENDING",
  Sent = "SENT",
}

export enum InkMessageDirection {
  Inbound = "INBOUND",
  Outbound = "OUTBOUND",
}

/**
 * An Ink message used as an input.
 *
 * This is intended to be morally equivalent to a tagged union; exactly
 * one of the properties provided here is expected to be provided.
 *
 * At current, only the generic message type is provided, because only
 * the generic message type is currently supported as an input type.
 * This is intended to be a backwards-compatible extension point to
 * allow other message input types to be added in the future.
 */
export type InkMessageInput = {
  email?: InputMaybe<InkEmailMessageInput>;
  generic?: InputMaybe<InkGenericMessageInput>;
  post?: InputMaybe<InkPostMessageInput>;
};

/** This types is used for both the message-received and message-sent conversation events. */
export type InkNewMessage = InkConversationEventInterface & {
  __typename?: "InkNewMessage";
  /** The message. */
  message: InkMessage;
  /** The time the conversation event occurred. */
  occurredAt: Scalars["DateTime"];
};

/** This type wraps around the `Message` type for Post. */
export type InkPost = {
  __typename?: "InkPost";
  /** The contact channel identity. */
  contactChannelIdentity: InkContactChannelIdentity;
  /** The notes left when a message was uploaded. */
  displayContent: Scalars["String"];
  /** The from property id. */
  fromHandle: Scalars["String"];
  /** Determine if the message is a post message. */
  isChannelPost: Scalars["Boolean"];
  /** The content of the message. */
  rawPlainTextContent: Scalars["String"];
  /** The to property id. */
  toHandle: Scalars["String"];
};

/** This type is used to create an inbound post. */
export type InkPostMessageInput = {
  /** The account number that the letter was sent from. */
  accountNumber?: InputMaybe<Scalars["String"]>;
  /** Message attachments. */
  attachments?: InputMaybe<Array<InkGenericMessageAttachmentInput>>;
  /** Notes on the letter. */
  notes?: InputMaybe<Scalars["String"]>;
  /** The content of the message, as plain text. */
  plainTextContent: Scalars["String"];
};

/** This type wraps around the `Message` type for SMS. */
export type InkSms = {
  __typename?: "InkSMS";
  /** The contact channel identity. */
  contactChannelIdentity: InkContactChannelIdentity;
  /** The delivery status. */
  delivery: InkMessageDelivery;
  /** The direction of the message. */
  direction: InkMessageDirection;
  /** The content of the message. */
  displayContent: Scalars["String"];
  /** The phone number the message was sent from. */
  fromHandle: Scalars["String"];
  /** Is this an SMS message. */
  isChannelSms: Scalars["Boolean"];
  /** The time the message was sent/received at. */
  occurredAt: Scalars["DateTime"];
  /** All Tags associated with a message. */
  tags: Array<InkTag>;
  /** The phone number the message was sent to. */
  toHandle: Scalars["String"];
};

export type InkTag = Node & {
  __typename?: "InkTag";
  /** The ID of the object */
  id: Scalars["ID"];
  /** Tag for a message. */
  name: Scalars["String"];
};

/** This type wraps around the `Message` type for WhatsApp message. */
export type InkWhatsApp = {
  __typename?: "InkWhatsApp";
  /** Whatsapp message contact channel identity. */
  contactChannelIdentity: InkContactChannelIdentity;
  /** Whatsapp message delivery status. */
  delivery: InkMessageDelivery;
  /** The direction of the message. */
  direction: InkMessageDirection;
  /** From WhatsApp phone number. */
  fromHandle: Scalars["String"];
  /** Whether or not the message is a whatsapp message. */
  isChannelWhatsapp: Scalars["Boolean"];
  /** Date when the conversation event was created. */
  occurredAt: Scalars["DateTime"];
  /** All Tags associated with a message. */
  tags: Array<InkTag>;
  /** Whatsapp contact phone number. */
  toHandle: Scalars["String"];
  /** The vendor id. */
  vendorId?: Maybe<Scalars["String"]>;
  /** Whatsapp message content. */
  whatsappContent: WhatsAppTextMessage;
};

/**
 * Input type for instruction details that was created using the embedded process.
 *
 * This means it was set up in the vendor system, and we only need to store a representation
 * of the instruction in Kraken, but not make any calls to the vendor.
 * This method of instruction creation must always be used for creating card instructions.
 */
export type InstructionDetailsInput = {
  /** The type of the payment instruction. */
  instructionType: PaymentType;
  /** The datetime from which the instruction is vaild. */
  validFrom: Scalars["DateTime"];
  /** The vendor's reference for this payment instruction. */
  vendorReference: Scalars["String"];
};

export enum IntegrationStatus {
  /** Generally Available - The device is available. */
  GenerallyAvailable = "GENERALLY_AVAILABLE",
  /** Not Available - The device is not available. */
  NotAvailable = "NOT_AVAILABLE",
  /** Testing - The device is in testing status. */
  Testing = "TESTING",
}

/** The frequency at which contributations are made */
export enum Interval {
  Monthly = "MONTHLY",
  Quarterly = "QUARTERLY",
}

export type IntervalMeasurementType = MeasurementInterface & {
  __typename?: "IntervalMeasurementType";
  accumulation?: Maybe<Scalars["Decimal"]>;
  durationInSeconds: Scalars["Int"];
  endAt: Scalars["DateTime"];
  /** This type will return more granular data about the measurement. */
  metaData?: Maybe<MeasurementsMetadataOutput>;
  readAt: Scalars["DateTime"];
  source: Scalars["String"];
  startAt: Scalars["DateTime"];
  unit: Scalars["String"];
  value: Scalars["Decimal"];
};

export type IntervalReadingInput = {
  accountNumber: Scalars["String"];
  /**
   * The unique identifier for a particular meter in the ERCOT market.
   *
   * If an ESI ID is not given, Kraken will try to get the ESI ID from the
   * latest active agreement on the account or raise an error if not found.
   */
  esiId?: InputMaybe<Scalars["String"]>;
  fromDatetime: Scalars["DateTime"];
  toDatetime: Scalars["DateTime"];
};

export type IntervalReadingOutput = {
  __typename?: "IntervalReadingOutput";
  /** Consumption in kWh. */
  consumption?: Maybe<Scalars["Decimal"]>;
  /** Start of the reading period. */
  fromDatetime?: Maybe<Scalars["DateTime"]>;
  /**
   * The unique identifier for a particular meter in the ERCOT market.
   *
   * If an ESI ID is not given, Kraken will try to get the ESI ID from the
   * latest active agreement on the account or raise an error if not found.
   */
  meterPointId?: Maybe<Scalars["Int"]>;
  /** The observed price for this reading. */
  price?: Maybe<Scalars["Decimal"]>;
  /** End of the reading period. */
  toDatetime?: Maybe<Scalars["DateTime"]>;
};

/**
 * Invalidates a payment instruction.
 *
 * The possible errors that can be raised are:
 *
 * - KT-CT-3926: Unauthorized.
 * - KT-CT-1113: Disabled GraphQL field requested.
 * - KT-CT-1111: Unauthorized.
 * - KT-CT-1112: 'Authorization' header not provided.
 */
export type InvalidatePaymentInstruction = {
  __typename?: "InvalidatePaymentInstruction";
  instruction?: Maybe<InvalidatePaymentInstructionOutput>;
  possibleErrors?: Maybe<Array<Maybe<PossibleErrorType>>>;
};

/** Input for invalidating an arbitrary payment instruction. */
export type InvalidatePaymentInstructionInput = {
  accountNumber: Scalars["String"];
  /** The id of the payment instruction to be invalidated. */
  id: Scalars["String"];
};

/** Output for invalidating an arbitrary payment instruction. */
export type InvalidatePaymentInstructionOutput = {
  __typename?: "InvalidatePaymentInstructionOutput";
  id?: Maybe<Scalars["Int"]>;
};

/**
 * Invalidate a previously issued expiring/pre-signed token.
 *
 * This mutation can be used to invalidate the token itself.
 *
 * To invalidate tokens issued to a particular user, use
 * InvalidatePreSignedTokensForUser mutation.
 *
 * The possible errors that can be raised are:
 *
 * - KT-CT-1129: Unauthorized.
 * - KT-CT-1113: Disabled GraphQL field requested.
 * - KT-CT-1111: Unauthorized.
 * - KT-CT-1112: 'Authorization' header not provided.
 */
export type InvalidatePreSignedToken = {
  __typename?: "InvalidatePreSignedToken";
  possibleErrors?: Maybe<Array<Maybe<PossibleErrorType>>>;
  token?: Maybe<PreSignedToken>;
};

/** Input type for the InvalidatePreSignedToken mutation. */
export type InvalidatePreSignedTokenInput = {
  token: Scalars["String"];
};

/**
 * Invalidate pre-signed tokens previously issued to a particular user.
 *
 * This mutation can invalidate all pre-signed tokens issued to a
 * customer, or only tokens of a given scope.
 *
 * The possible errors that can be raised are:
 *
 * - KT-CT-1129: Unauthorized.
 * - KT-CT-1113: Disabled GraphQL field requested.
 * - KT-CT-1111: Unauthorized.
 * - KT-CT-1112: 'Authorization' header not provided.
 */
export type InvalidatePreSignedTokensForUser = {
  __typename?: "InvalidatePreSignedTokensForUser";
  possibleErrors?: Maybe<Array<Maybe<PossibleErrorType>>>;
  tokens?: Maybe<Array<Maybe<PreSignedToken>>>;
};

/** Input type for the InvalidatePreSignedTokensForUser mutation. */
export type InvalidatePreSignedTokensForUserInput = {
  /** The email address of the user whose tokens should be invalidated. */
  email: Scalars["String"];
  /** The scope of the token to invalidate.  If this argument is not specified, all pre-signed tokens issued to the user are invalidated. */
  scope?: InputMaybe<PreSignedTokenScope>;
};

/**
 * Invalidate a previously issued refresh token.
 *
 * This mutation can be used to invalidate the token itself.  To
 * invalidate tokens issued to a particular user, use
 * InvalidateRefreshTokensForUser.
 *
 * The possible errors that can be raised are:
 *
 * - KT-CT-1130: Unauthorized.
 * - KT-CT-1113: Disabled GraphQL field requested.
 * - KT-CT-1111: Unauthorized.
 * - KT-CT-1112: 'Authorization' header not provided.
 */
export type InvalidateRefreshToken = {
  __typename?: "InvalidateRefreshToken";
  possibleErrors?: Maybe<Array<Maybe<PossibleErrorType>>>;
  token?: Maybe<RefreshToken>;
};

/** Input type for the InvalidateRefreshToken mutation. */
export type InvalidateRefreshTokenInput = {
  refreshToken: Scalars["String"];
};

/**
 * Invalidate refresh tokens previously issued to a particular user.
 *
 * This mutation will invalidate all refresh tokens issued to a
 * customer.
 *
 * The possible errors that can be raised are:
 *
 * - KT-CT-1128: Unauthorized.
 * - KT-CT-1113: Disabled GraphQL field requested.
 * - KT-CT-1111: Unauthorized.
 * - KT-CT-1112: 'Authorization' header not provided.
 */
export type InvalidateRefreshTokensForUser = {
  __typename?: "InvalidateRefreshTokensForUser";
  possibleErrors?: Maybe<Array<Maybe<PossibleErrorType>>>;
  tokens?: Maybe<Array<Maybe<RefreshToken>>>;
};

/** Input type for the InvalidateRefreshTokensForUser mutation. */
export type InvalidateRefreshTokensForUserInput = {
  /** The email address of the user whose tokens should be invalidated. */
  email: Scalars["String"];
};

/** An invoice is a bill that contains individual transactions  (i.e. charges, credits, payments, and repayments). These may come from any period of time. */
export type InvoiceBillingDocumentConnectionTypeConnection = {
  __typename?: "InvoiceBillingDocumentConnectionTypeConnection";
  /** Number of nodes in the edge. */
  edgeCount: Scalars["Int"];
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<InvoiceBillingDocumentConnectionTypeEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  /** Total number of nodes. */
  totalCount: Scalars["Int"];
};

/** A Relay edge containing a `InvoiceBillingDocumentConnectionType` and its cursor. */
export type InvoiceBillingDocumentConnectionTypeEdge = {
  __typename?: "InvoiceBillingDocumentConnectionTypeEdge";
  /** A cursor for use in pagination */
  cursor: Scalars["String"];
  /** The item at the end of the edge */
  node?: Maybe<InvoiceBillingDocumentType>;
};

/** An invoice is a bill that contains individual transactions  (i.e. charges, credits, payments, and repayments). These may come from any period of time. */
export type InvoiceBillingDocumentType = {
  __typename?: "InvoiceBillingDocumentType";
  /** Billing document that annuls this invoice. */
  annulledBy?: Maybe<AnnulmentBillingDocumentType>;
  /** First time the invoice was issued. */
  firstIssued?: Maybe<Scalars["DateTime"]>;
  id?: Maybe<Scalars["Int"]>;
  /** The invoiced amount of the billing document. */
  invoicedAmount?: Maybe<Scalars["Int"]>;
  /** The unique billing document's reference that can be used for identifying it externally. */
  number?: Maybe<Scalars["String"]>;
  /** URL to the PDF of the Invoice. */
  pdfUrl?: Maybe<Scalars["String"]>;
};

export type InvoiceType = BillInterface & {
  __typename?: "InvoiceType";
  attachments?: Maybe<BillingAttachmentConnectionTypeConnection>;
  /** The type of the bill. */
  billType?: Maybe<BillTypeEnum>;
  /** The date of the bill is covered from. */
  fromDate?: Maybe<Scalars["Date"]>;
  /** This field returns the total gross amount of the bill in pence. */
  grossAmount?: Maybe<Scalars["Int"]>;
  /** The ID of the bill. */
  id?: Maybe<Scalars["ID"]>;
  /** The date the bill was sent to the customer. */
  issuedDate?: Maybe<Scalars["Date"]>;
  /**
   * Requesting this field generates a temporary URL at which bill is available.
   *         This URL will expire after approximately an hour.  It is intended for redirection purposes,
   *         NOT persistence in any form (e.g. inclusion in emails or the body of a web page).
   *         This field can raise an error with errorClass NOT_FOUND if the bill document has not
   *         been created/issued yet. This field is deprecated use 'attachments' field instead.
   * @deprecated The 'temporaryUrl' field is deprecated.
   *
   * This field is deprecated. Use the 'attachments' field instead.
   *
   * - Marked as deprecated on 2024-09-16.
   * - Will be removed on 2025-09-01.
   */
  temporaryUrl?: Maybe<Scalars["String"]>;
  /** The date of the bill is covered to. */
  toDate?: Maybe<Scalars["Date"]>;
};

export type InvoiceTypeAttachmentsArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
};

export type IoDeviceRatesType = {
  __typename?: "IoDeviceRatesType";
  currency?: Maybe<Scalars["String"]>;
  description?: Maybe<Scalars["String"]>;
  /** Types of IO devices that are relevant to this rate. */
  deviceTypes?: Maybe<Array<Maybe<KrakenFlexDeviceTypes>>>;
  /** Returns a list of EFLs that are applicable to the resolved product. */
  efls?: Maybe<Array<Maybe<EflType>>>;
  id?: Maybe<Scalars["String"]>;
  loadZone?: Maybe<LoadZone>;
  /** Price per unit is returned in decimal format. For example: Decimal('0.10'). */
  pricePerUnit?: Maybe<Scalars["Decimal"]>;
  serviceProvider?: Maybe<ServiceProvider>;
  taxCode?: Maybe<Scalars["String"]>;
  taxable?: Maybe<Scalars["Boolean"]>;
  unitType?: Maybe<UnitType>;
};

export type IoDeviceRatesTypeEflsArgs = {
  language?: InputMaybe<EflLanguage>;
  loadZone?: InputMaybe<LoadZone>;
};

export type IsQuotableOutputType = {
  __typename?: "IsQuotableOutputType";
  /** Returns whether the given post code has multiple TDSPs or load zones. */
  hasMultipleTdspsOrLoadZones?: Maybe<Scalars["Boolean"]>;
  /** Returns whether we can create a quote based on the given information. */
  isQuotable?: Maybe<Scalars["Boolean"]>;
};

export type ItemSizeType = FractionSizeType | PointsSizeType;

/** Items are sections making up a screen. They can be different types, hence Union. */
export type ItemType =
  | AnimationType
  | CardComponentType
  | ImageType
  | PillButtonType
  | RectangularButtonType
  | TextType;

/**
 * The possible errors that can be raised are:
 *
 * - KT-CT-1111: Unauthorized.
 * - KT-CT-4501: Unauthorized.
 * - KT-CT-1113: Disabled GraphQL field requested.
 * - KT-CT-1111: Unauthorized.
 * - KT-CT-1112: 'Authorization' header not provided.
 */
export type JoinSupplierAcceptTermsAndConditions = {
  __typename?: "JoinSupplierAcceptTermsAndConditions";
  possibleErrors?: Maybe<Array<Maybe<PossibleErrorType>>>;
  /** Indicator that the mutation has completed successfully. */
  success?: Maybe<Scalars["Boolean"]>;
};

export type JoinSupplierAcceptTermsAndConditionsInput = {
  /** The account number of the join supplier process (e.g. A-12345678). */
  accountNumber: Scalars["String"];
  /** The JoinSupplierProcess ID. */
  joinSupplierProcessId: Scalars["Int"];
};

export type JoinSupplierProcessConnectionTypeConnection = {
  __typename?: "JoinSupplierProcessConnectionTypeConnection";
  /** Number of nodes in the edge. */
  edgeCount: Scalars["Int"];
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<JoinSupplierProcessConnectionTypeEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  /** Total number of nodes. */
  totalCount: Scalars["Int"];
};

/** A Relay edge containing a `JoinSupplierProcessConnectionType` and its cursor. */
export type JoinSupplierProcessConnectionTypeEdge = {
  __typename?: "JoinSupplierProcessConnectionTypeEdge";
  /** A cursor for use in pagination */
  cursor: Scalars["String"];
  /** The item at the end of the edge */
  node?: Maybe<JoinSupplierProcessType>;
};

/** Represents a Join Supplier process. */
export type JoinSupplierProcessType = AbstractSupplyPointProcessInterface & {
  __typename?: "JoinSupplierProcessType";
  /** The ID or the primary key of the lifecycle process. */
  id?: Maybe<Scalars["ID"]>;
  /** The status of the process. */
  status?: Maybe<LifecycleSupplyPointProcessStatus>;
  /** The supply points associated with the process. */
  supplyPoints: SupplyPointConnectionTypeConnection;
};

/** Represents a Join Supplier process. */
export type JoinSupplierProcessTypeSupplyPointsArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
};

/**
 * The current lifecycle status of a KrakenFlex device on the smarter tariff API:
 *
 * - `ONBOARDING` indicates the device has been registered on the system but has not yet completed the on-boarding process.
 *     - Including a test charge step, to validate that communication and control of the device is possible within expected limits.
 * - `ONBOARDING_TEST_IN_PROGRESS` indicates that there is a test charge in progress for the device.
 * - `LIVE` indicates that the device has completed the on-boarding process and is ready for control.
 * - `FAILED_ONBOARDING_TEST` indicates that there has been an issue validating that the device can be reliably communicated with or controlled on the system.
 * - `RETIRED` indicates that the device has no connection details and is not currently available for control.
 */
export enum KrakenFlexDeviceStatusChoices {
  FailedOnboardingTest = "FAILED_ONBOARDING_TEST",
  Live = "LIVE",
  Onboarding = "ONBOARDING",
  OnboardingTestInProgress = "ONBOARDING_TEST_IN_PROGRESS",
  PendingLive = "PENDING_LIVE",
  Retired = "RETIRED",
}

export type KrakenFlexDeviceType = {
  __typename?: "KrakenFlexDeviceType";
  chargePointMake?: Maybe<Scalars["String"]>;
  chargePointModel?: Maybe<Scalars["String"]>;
  chargePointPowerInKw?: Maybe<Scalars["Decimal"]>;
  createdAt?: Maybe<Scalars["DateTime"]>;
  hasToken?: Maybe<Scalars["Boolean"]>;
  krakenflexDeviceId?: Maybe<Scalars["String"]>;
  /** The third party that provides control over this device. */
  provider?: Maybe<ProviderChoices>;
  /** Maximum state of charge (SoC) limit telemetry. */
  stateOfChargeLimit?: Maybe<StateOfChargeLimit>;
  status?: Maybe<Scalars["String"]>;
  suspended?: Maybe<Scalars["Boolean"]>;
  /** The reason for the most recent failed test dispatch (if any). */
  testDispatchFailureReason?: Maybe<TestDispatchAssessmentFailureReason>;
  vehicleBatterySizeInKwh?: Maybe<Scalars["Decimal"]>;
  vehicleMake?: Maybe<Scalars["String"]>;
  vehicleModel?: Maybe<Scalars["String"]>;
};

/** The device types that can be controlled by KrakenFlex. */
export enum KrakenFlexDeviceTypes {
  Batteries = "BATTERIES",
  ElectricVehicles = "ELECTRIC_VEHICLES",
  HeatPumps = "HEAT_PUMPS",
  Inverters = "INVERTERS",
  StorageHeaters = "STORAGE_HEATERS",
  Thermostats = "THERMOSTATS",
}

/** Information about what version of Kraken is being executed by this service. */
export type KrakenVersionType = {
  __typename?: "KrakenVersionType";
  /** The git commit SHA that is being executed. */
  SHA?: Maybe<Scalars["String"]>;
  /** The version number that is being executed. */
  number?: Maybe<Scalars["String"]>;
};

export enum LanguagePreferenceChoices {
  English = "ENGLISH",
  Spanish = "SPANISH",
}

export type LanguagePreferenceInput = {
  accountNumber: Scalars["String"];
  languagePreference?: InputMaybe<LanguagePreferenceChoices>;
};

/** Output Type for Move out Date Query. */
export type LatestMoveOutDateOutputType = {
  __typename?: "LatestMoveOutDateOutputType";
  /** The date of the latest move out request event. */
  latestMoveOutDate?: Maybe<Scalars["DateTime"]>;
};

export type LeavePropertyProcessConnectionTypeConnection = {
  __typename?: "LeavePropertyProcessConnectionTypeConnection";
  /** Number of nodes in the edge. */
  edgeCount: Scalars["Int"];
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<LeavePropertyProcessConnectionTypeEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  /** Total number of nodes. */
  totalCount: Scalars["Int"];
};

/** A Relay edge containing a `LeavePropertyProcessConnectionType` and its cursor. */
export type LeavePropertyProcessConnectionTypeEdge = {
  __typename?: "LeavePropertyProcessConnectionTypeEdge";
  /** A cursor for use in pagination */
  cursor: Scalars["String"];
  /** The item at the end of the edge */
  node?: Maybe<LeavePropertyProcessType>;
};

/** Represents a Leave Property process. */
export type LeavePropertyProcessType = AbstractSupplyPointProcessInterface & {
  __typename?: "LeavePropertyProcessType";
  /** The ID or the primary key of the lifecycle process. */
  id?: Maybe<Scalars["ID"]>;
  /** The status of the process. */
  status?: Maybe<LifecycleSupplyPointProcessStatus>;
  /** The supply points associated with the process. */
  supplyPoints: SupplyPointConnectionTypeConnection;
};

/** Represents a Leave Property process. */
export type LeavePropertyProcessTypeSupplyPointsArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
};

/** Output of a LeaveSupplier journey cancellation. */
export type LeaveSupplierCancelled = {
  __typename?: "LeaveSupplierCancelled";
  /** The message to display to the user on cancellation. */
  message: Scalars["String"];
};

export type LeaveSupplierInput = {
  /** The Kraken account number. */
  accountNumber: Scalars["String"];
  /** Future billing address. */
  futureBillingAddress?: InputMaybe<RichAddressInput>;
  marketData?: InputMaybe<LeaveSupplierMarketInputType>;
  /** The requested last day of supply. */
  requestedSupplyEndDate: Scalars["Date"];
};

/** Termination was successfully initiated. */
export type LeaveSupplierInstigated = {
  __typename?: "LeaveSupplierInstigated";
  /** The ID of the newly created or existing leave supplier process. */
  leaveSupplierProcessId: Scalars["ID"];
  /** The message to display to the user on termination initiation. */
  message: Scalars["String"];
};

export type LeaveSupplierMarketInputType = {
  /** A list of supply points for initiating a leave supplier journey. */
  supplyPointData?: InputMaybe<
    Array<InputMaybe<_DefaultMarketTerminationInput>>
  >;
};

export type LeaveSupplierProcessConnectionTypeConnection = {
  __typename?: "LeaveSupplierProcessConnectionTypeConnection";
  /** Number of nodes in the edge. */
  edgeCount: Scalars["Int"];
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<LeaveSupplierProcessConnectionTypeEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  /** Total number of nodes. */
  totalCount: Scalars["Int"];
};

/** A Relay edge containing a `LeaveSupplierProcessConnectionType` and its cursor. */
export type LeaveSupplierProcessConnectionTypeEdge = {
  __typename?: "LeaveSupplierProcessConnectionTypeEdge";
  /** A cursor for use in pagination */
  cursor: Scalars["String"];
  /** The item at the end of the edge */
  node?: Maybe<LeaveSupplierProcessType>;
};

/** Represents a Leave Supplier process. Conceptually, it contains information related to supply points that are associated with a Leave Supplier journey. */
export type LeaveSupplierProcessType = AbstractSupplyPointProcessInterface & {
  __typename?: "LeaveSupplierProcessType";
  /** The ID or the primary key of the lifecycle process. */
  id?: Maybe<Scalars["ID"]>;
  /** The status of the process. */
  status?: Maybe<LifecycleSupplyPointProcessStatus>;
  /** The supply points associated with the process. */
  supplyPoints: SupplyPointConnectionTypeConnection;
};

/** Represents a Leave Supplier process. Conceptually, it contains information related to supply points that are associated with a Leave Supplier journey. */
export type LeaveSupplierProcessTypeSupplyPointsArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
};

export type LedgerInterface = {
  /** Whether this ledger's balance contributes to the account's balance. */
  affectsAccountBalance?: Maybe<Scalars["Boolean"]>;
  /** The amount owed from the customer perspective. A positive value implies the customer owes the business, while a negative amount implies the customer is in credit. */
  amountOwedByCustomer?: Maybe<Scalars["Int"]>;
  /** The current balance on the ledger in minor units of currency. */
  balance?: Maybe<Scalars["Int"]>;
  id?: Maybe<Scalars["ID"]>;
  /** An invoice is a bill that contains individual transactions  (i.e. charges, credits, payments, and repayments). These may come from any period of time. */
  invoices?: Maybe<InvoiceBillingDocumentConnectionTypeConnection>;
  ledgerType?: Maybe<Scalars["String"]>;
  /** The display name of the ledger. */
  name?: Maybe<Scalars["String"]>;
  /** The canonical name of the ledger. */
  number?: Maybe<Scalars["String"]>;
  paymentAdequacy?: Maybe<PaymentAdequacyDetailsType>;
  /** Refund requests for a given ledger. */
  refundRequests?: Maybe<RefundRequestConnectionTypeConnection>;
  /** Repayment requests for a given ledger. */
  repaymentRequests?: Maybe<RepaymentRequestConnectionTypeConnection>;
  /** A statement is a billing document that contains all entries on a ledger during a period of time. A customer can understand how their ledger's balance has changed by looking at each statement in series. */
  statements?: Maybe<StatementBillingDocumentConnectionTypeConnection>;
  /** Transactions on the given ledger. */
  transactions?: Maybe<TransactionConnectionTypeConnection>;
};

export type LedgerInterfaceInvoicesArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
};

export type LedgerInterfaceRefundRequestsArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
};

export type LedgerInterfaceRepaymentRequestsArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
};

export type LedgerInterfaceStatementsArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
};

export type LedgerInterfaceTransactionsArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  fromDate?: InputMaybe<Scalars["Date"]>;
  last?: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<TransactionsOrderBy>;
  toDate?: InputMaybe<Scalars["Date"]>;
  transactionTypes?: InputMaybe<Array<InputMaybe<TransactionTypeFilter>>>;
};

/** Ledgers provide the foundation of Kraken’s bookkeeping functionality. Similar to a bank account, they allow us to keep track of financial activity on a particular Kraken account. */
export type LedgerType = LedgerInterface & {
  __typename?: "LedgerType";
  /** Whether this ledger's balance contributes to the account's balance. */
  affectsAccountBalance?: Maybe<Scalars["Boolean"]>;
  /** The amount owed from the customer perspective. A positive value implies the customer owes the business, while a negative amount implies the customer is in credit. */
  amountOwedByCustomer?: Maybe<Scalars["Int"]>;
  /** The current balance on the ledger in minor units of currency. */
  balance?: Maybe<Scalars["Int"]>;
  id?: Maybe<Scalars["ID"]>;
  /** An invoice is a bill that contains individual transactions  (i.e. charges, credits, payments, and repayments). These may come from any period of time. */
  invoices?: Maybe<InvoiceBillingDocumentConnectionTypeConnection>;
  /** The ledger type code. */
  ledgerType?: Maybe<Scalars["String"]>;
  /** The display name of the ledger. */
  name?: Maybe<Scalars["String"]>;
  /** The canonical name of the ledger. */
  number?: Maybe<Scalars["String"]>;
  paymentAdequacy?: Maybe<PaymentAdequacyDetailsType>;
  /** Refund requests for a given ledger. */
  refundRequests?: Maybe<RefundRequestConnectionTypeConnection>;
  /** Repayment requests for a given ledger. */
  repaymentRequests?: Maybe<RepaymentRequestConnectionTypeConnection>;
  /** A statement is a billing document that contains all entries on a ledger during a period of time. A customer can understand how their ledger's balance has changed by looking at each statement in series. */
  statements?: Maybe<StatementBillingDocumentConnectionTypeConnection>;
  /** Transactions on the given ledger. */
  transactions?: Maybe<TransactionConnectionTypeConnection>;
};

/** Ledgers provide the foundation of Kraken’s bookkeeping functionality. Similar to a bank account, they allow us to keep track of financial activity on a particular Kraken account. */
export type LedgerTypeInvoicesArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  invoiceId?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
};

/** Ledgers provide the foundation of Kraken’s bookkeeping functionality. Similar to a bank account, they allow us to keep track of financial activity on a particular Kraken account. */
export type LedgerTypeRefundRequestsArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
};

/** Ledgers provide the foundation of Kraken’s bookkeeping functionality. Similar to a bank account, they allow us to keep track of financial activity on a particular Kraken account. */
export type LedgerTypeRepaymentRequestsArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
};

/** Ledgers provide the foundation of Kraken’s bookkeeping functionality. Similar to a bank account, they allow us to keep track of financial activity on a particular Kraken account. */
export type LedgerTypeStatementsArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  statementId?: InputMaybe<Scalars["Int"]>;
};

/** Ledgers provide the foundation of Kraken’s bookkeeping functionality. Similar to a bank account, they allow us to keep track of financial activity on a particular Kraken account. */
export type LedgerTypeTransactionsArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  fromDate?: InputMaybe<Scalars["Date"]>;
  last?: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<TransactionsOrderBy>;
  toDate?: InputMaybe<Scalars["Date"]>;
  transactionTypes?: InputMaybe<Array<InputMaybe<TransactionTypeFilter>>>;
};

/** An enumeration. */
export enum LegalDocumentLanguageOption {
  English = "ENGLISH",
  Spanish = "SPANISH",
}

/**
 * This type represents PDFs that PUC requires to be sent as such. There are other examples
 * of legalese appearing elsewhere (https://octopusenergy.com/privacy-policy). We are only
 * concerned with documents that must be a PDF as mandated by PUC. The four listed below are
 * the only documents subject to these regulation at the time this was written.
 */
export type LegalDocumentsOutput = {
  __typename?: "LegalDocumentsOutput";
  prepaidDisclosureStatement?: Maybe<Link>;
  termsOfService?: Maybe<Link>;
  termsOfUse?: Maybe<Link>;
  yourRightsAsACustomer?: Maybe<Link>;
};

export type LifecycleAddressInput = {
  /** Country code. */
  countryCode?: InputMaybe<Scalars["String"]>;
  /** Line 1 of address. */
  line1: Scalars["String"];
  /** Line 2 of address. */
  line2?: InputMaybe<Scalars["String"]>;
  /** Line 3 of address. */
  line3?: InputMaybe<Scalars["String"]>;
  /** Line 4 of address. */
  line4?: InputMaybe<Scalars["String"]>;
  /** Line 5 of address. */
  line5?: InputMaybe<Scalars["String"]>;
  /** Postal code. */
  postalCode: Scalars["String"];
};

export type LifecycleProcessesType = {
  __typename?: "LifecycleProcessesType";
  /** List of JoinSupplierProcess for an account. */
  joinSupplierProcesses?: Maybe<JoinSupplierProcessConnectionTypeConnection>;
  /** List of LeavePropertyProcess for an account. */
  leavePropertyProcesses?: Maybe<LeavePropertyProcessConnectionTypeConnection>;
  /** List of LeaveSupplierProcess for an account. */
  leaveSupplierProcesses?: Maybe<LeaveSupplierProcessConnectionTypeConnection>;
  /** List of OccupyPropertyProcess for an account. */
  occupyPropertyProcesses?: Maybe<OccupyPropertyProcessConnectionTypeConnection>;
};

export type LifecycleProcessesTypeJoinSupplierProcessesArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
};

export type LifecycleProcessesTypeLeavePropertyProcessesArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
};

export type LifecycleProcessesTypeLeaveSupplierProcessesArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
};

export type LifecycleProcessesTypeOccupyPropertyProcessesArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
};

/** The status of the lifecycle process. */
export enum LifecycleSupplyPointProcessStatus {
  Cancelled = "CANCELLED",
  Completed = "COMPLETED",
  Errored = "ERRORED",
  Failed = "FAILED",
  InProgress = "IN_PROGRESS",
  PartiallyCancelled = "PARTIALLY_CANCELLED",
  PartiallyCompleted = "PARTIALLY_COMPLETED",
  PartiallyReversed = "PARTIALLY_REVERSED",
  Pending = "PENDING",
  Reversed = "REVERSED",
  ReverseInProgress = "REVERSE_IN_PROGRESS",
  Stalled = "STALLED",
}

/** A base error type. Should be used for general application or lower level errors. */
export type LineCommonError = {
  __typename?: "LineCommonError";
  /** The field that for which this error should be associated. */
  field?: Maybe<Scalars["String"]>;
  /** The error message to display to the user. */
  message: Scalars["String"];
};

/**
 * A LINE specific emoji object.
 * refs: https://developers.line.biz/en/reference/messaging-api/#text-message
 */
export type LineEmoji = {
  __typename?: "LineEmoji";
  /** The emoji id. */
  emojiId: Scalars["String"];
  /** The location of the emoji in the message. */
  index: Scalars["Int"];
  /** The length of the emoji string placeholder. */
  length?: Maybe<Scalars["Int"]>;
  /** The product id. */
  productId: Scalars["String"];
};

export type LineImageMessage = {
  __typename?: "LineImageMessage";
  id: Scalars["ID"];
};

export enum LineLinkErrorType {
  AlreadyLinked = "ALREADY_LINKED",
  NoMatchingLineLink = "NO_MATCHING_LINE_LINK",
}

/** Link Successful. Complete link process with LINE. */
export type LineLinkRedirectResponse = {
  __typename?: "LineLinkRedirectResponse";
  redirectUrl: Scalars["String"];
};

export type LineMessage =
  | LineImageMessage
  | LineStickerMessage
  | LineTextMessage;

export type LineStickerMessage = {
  __typename?: "LineStickerMessage";
  /** Keywords describing the sticker. */
  keywords: Array<Scalars["String"]>;
  /** Sticker package id. */
  packageId: Scalars["String"];
  /** Sticker resource type. */
  resourceType: Scalars["String"];
  /** Sticker id. */
  stickerId: Scalars["String"];
  /** Text used to customize some stickers. */
  text: Scalars["String"];
};

export type LineTextMessage = {
  __typename?: "LineTextMessage";
  /** The display content. */
  displayContent: Scalars["String"];
  /** The emojis in the message. */
  emojis?: Maybe<Array<LineEmoji>>;
};

export type LineUnlinkedResponse = {
  __typename?: "LineUnlinkedResponse";
  message: Scalars["String"];
};

export type Link = {
  __typename?: "Link";
  fullName?: Maybe<Scalars["String"]>;
  language?: Maybe<Scalars["String"]>;
  name?: Maybe<Scalars["String"]>;
  url?: Maybe<Scalars["String"]>;
};

/** An action which navigates to any URL. */
export type LinkActionType = ActionInterface &
  IdentifiableInterface & {
    __typename?: "LinkActionType";
    /** Unique identifier of the object. */
    id?: Maybe<Scalars["ID"]>;
    /** The name of the action object's type. */
    typeName?: Maybe<Scalars["String"]>;
    /** The name of the object's type. */
    typename?: Maybe<Scalars["String"]>;
    /** The URL to navigate to. */
    url: Scalars["String"];
  };

/** Returned when no LineAccountLink record matching the parameters exists. */
export type LinkTokenNotFound = {
  __typename?: "LinkTokenNotFound";
  /** The type of error that occurred. */
  type: LineLinkErrorType;
};

/** An enumeration. */
export enum LinkTrainingStatus {
  /** In training */
  InTraining = "IN_TRAINING",
  /** Not applicable */
  NotApplicable = "NOT_APPLICABLE",
  /** Qualified */
  Qualified = "QUALIFIED",
}

/** Link an AccountUser to a LINE account. */
export type LinkUserToLineInput = {
  linkToken: Scalars["String"];
};

export type LinkUserToLineResponse =
  | AlreadyLinkedError
  | LineLinkRedirectResponse
  | LinkTokenNotFound;

export enum LinkedObjectType {
  Account = "ACCOUNT",
  AccountUser = "ACCOUNT_USER",
}

export type LivePaymentAdequacyCalculation = {
  __typename?: "LivePaymentAdequacyCalculation";
  /** The combined average montly cost for all markets based on usage. */
  averageMonthlyCharge?: Maybe<Scalars["Int"]>;
  /** Suggested temporary adjustment to ongoing usage amount to cover debt or overpayment. */
  balanceAdjustment?: Maybe<Scalars["Int"]>;
  /** Breakdown of customer's estimated or real usage per market and per month. */
  consumption?: Maybe<ConsumptionBreakdownConnectionTypeConnection>;
  /** The balance the ledger has at the time of review. */
  currentBalance?: Maybe<Scalars["Int"]>;
  /** The amount the customer is paying monthly at the time of the review. */
  existingMonthlyAmount?: Maybe<Scalars["Int"]>;
  /** The date that we used to calculate the review of the ledger. */
  reviewedOn?: Maybe<Scalars["Date"]>;
  /** The suggested monthly payment amount in minor currency following the payment adequacy review. */
  suggestedNewMonthlyAmount?: Maybe<Scalars["Int"]>;
  /** The balance we expect the ledger to have at the end period of the review. */
  targetBalance?: Maybe<Scalars["Int"]>;
};

export type LivePaymentAdequacyCalculationConsumptionArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
};

/** An enumeration. */
export enum LoadZone {
  LzHouston = "LZ_HOUSTON",
  LzNorth = "LZ_NORTH",
  LzSouth = "LZ_SOUTH",
  LzWest = "LZ_WEST",
}

/** Returns a list of consumption rates that are calculated for the Load zone. */
export type LoadZoneRatesType = {
  __typename?: "LoadZoneRatesType";
  /** The consumption rate will represent all consumption-based charges based on the load zone. */
  consumptionRates?: Maybe<Array<Maybe<ConsumptionRatesType>>>;
};

/** A loyalty card. */
export type LoyaltyCardType = {
  __typename?: "LoyaltyCardType";
  id: Scalars["ID"];
  /** The number of the loyalty card. */
  number?: Maybe<Scalars["String"]>;
  /** The scheme of the loyalty card. */
  scheme?: Maybe<Scalars["String"]>;
  /** The status of the loyalty card. */
  status?: Maybe<Scalars["String"]>;
};

/** A Loyalty Point ledger entry. */
export type LoyaltyPointLedgerEntryType = {
  __typename?: "LoyaltyPointLedgerEntryType";
  /** The account number associated with the entry. */
  accountNumber?: Maybe<Scalars["String"]>;
  /** Equal to the `balance_carried_forward` from the previous ledger entry or zero if this is the first one. */
  balanceBroughtForward?: Maybe<Scalars["String"]>;
  /** Equal to the `balance_brought_forward` plus or minus the value depending on the ledger_type. */
  balanceCarriedForward?: Maybe<Scalars["String"]>;
  id: Scalars["ID"];
  /** The `LedgerEntryType`. Either CHARGE or CREDIT. */
  ledgerType?: Maybe<Scalars["String"]>;
  /** The date the points were added to the ledger. */
  postedAt?: Maybe<Scalars["DateTime"]>;
  /** The reason the entry was being added. */
  reasonCode?: Maybe<Scalars["String"]>;
  /** The value of the charge or credit. */
  value?: Maybe<Scalars["String"]>;
};

/**
 * Mark a closed print batch as Processed
 *
 * The possible errors that can be raised are:
 *
 * - KT-CT-9011: Invalid data.
 * - KT-CT-1113: Disabled GraphQL field requested.
 * - KT-CT-1111: Unauthorized.
 * - KT-CT-1112: 'Authorization' header not provided.
 */
export type MarkPrintBatchAsProcessed = {
  __typename?: "MarkPrintBatchAsProcessed";
  possibleErrors?: Maybe<Array<Maybe<PossibleErrorType>>>;
  printBatch?: Maybe<PrintBatchType>;
};

export type MasqueradeAuthentication = {
  __typename?: "MasqueradeAuthentication";
  /** A list of any errors that occurred while running this mutation. */
  errors?: Maybe<Array<Maybe<ErrorType>>>;
  /** A Kraken Token that can be used to authenticate to the API, masquerading as the desired user. */
  token?: Maybe<Scalars["String"]>;
};

/** An enumeration. */
export enum MaximumRefundReasonChoices {
  /** Maximum refund is equal to the maximum refund amount allowed to be requested via the dashboard. */
  MaxAllowedToRequestViaDashboard = "MAX_ALLOWED_TO_REQUEST_VIA_DASHBOARD",
  /** Maximum refund is equal to the current balance minus the account recommended balance. */
  MaxAvailableAmount = "MAX_AVAILABLE_AMOUNT",
  /** Maximum refund is equal to the total amount the customer has paid using the current account Direct Debit instruction. */
  TotalAmountPaidViaActiveDdi = "TOTAL_AMOUNT_PAID_VIA_ACTIVE_DDI",
}

export type MaximumRefundType = {
  __typename?: "MaximumRefundType";
  /** The maximum amount available to be requested as a refund. */
  amount?: Maybe<Scalars["Int"]>;
  /** The reason why a specific amount is the maximum available to be requested as a refund. */
  reasonToRecommendAmount?: Maybe<MaximumRefundReasonChoices>;
  /** The recommended minimum balance an account should have when asking for a refund. */
  recommendedBalance?: Maybe<Scalars["Int"]>;
};

/** Pagination for measurements. */
export type MeasurementConnection = {
  __typename?: "MeasurementConnection";
  /** Number of nodes in the edge. */
  edgeCount: Scalars["Int"];
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<MeasurementEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  /** Total number of nodes. */
  totalCount: Scalars["Int"];
};

/** A Relay edge containing a `Measurement` and its cursor. */
export type MeasurementEdge = {
  __typename?: "MeasurementEdge";
  /** A cursor for use in pagination */
  cursor: Scalars["String"];
  /** The item at the end of the edge */
  node?: Maybe<MeasurementInterface>;
};

export type MeasurementInterface = {
  /** This type will return more granular data about the measurement. */
  metaData?: Maybe<MeasurementsMetadataOutput>;
  readAt: Scalars["DateTime"];
  source: Scalars["String"];
  unit: Scalars["String"];
  value: Scalars["Decimal"];
};

export type MeasurementType = MeasurementInterface & {
  __typename?: "MeasurementType";
  /** This type will return more granular data about the measurement. */
  metaData?: Maybe<MeasurementsMetadataOutput>;
  readAt: Scalars["DateTime"];
  source: Scalars["String"];
  unit: Scalars["String"];
  value: Scalars["Decimal"];
};

export type MeasurementsMetadataOutput = {
  __typename?: "MeasurementsMetadataOutput";
  /** Statistics relating to the parent measurement node. */
  statistics?: Maybe<Array<Maybe<StatisticOutput>>>;
  /**
   * The source information relating to the parent measurement node.
   * @deprecated The 'typedSource' type is deprecated.
   *
   *
   * Please use 'utilityFilters' and the corresponding filter output instead.
   *
   *
   * - Marked as deprecated on 2024-06-17.
   * - Will be removed on 2026-01-30.
   */
  typedSource?: Maybe<TypedSourceOutput>;
  /** The source information relating to the parent measurement node. */
  utilityFilters?: Maybe<UtilityFiltersOutput>;
};

/** The media interface. */
export type MediaInterface = {
  /** The horizontal alignment of the media. */
  horizontalAlignment?: Maybe<Alignment>;
  /** The resource URL of the media. */
  mediaUrl: Scalars["String"];
};

/** An enumeration. */
export enum MessageChannel {
  /** Email */
  Email = "EMAIL",
  /** Intercom */
  Intercom = "INTERCOM",
  /** Junifer */
  Junifer = "JUNIFER",
  /** Print */
  Print = "PRINT",
  /** Push Notification */
  PushNotification = "PUSH_NOTIFICATION",
  /** SMS */
  Sms = "SMS",
}

export type Metadata = {
  __typename?: "Metadata";
  /** The key for the metadata. */
  key: Scalars["String"];
  /** The metadata value. */
  value?: Maybe<Scalars["JSONString"]>;
};

/** The metadata input type for mutations. */
export type MetadataInput = {
  /** An identifier for the associated object, e.g. account_number for the Account linked object type or email for the Account User linked object type. */
  identifier: Scalars["String"];
  /** The key for the metadata. */
  key: Scalars["String"];
  /** The object that the metadata is associated with. */
  linkedObjectType: LinkedObjectType;
  /** The metadata value which should be a valid JSON string. */
  value: Scalars["JSONString"];
};

/** Meter Point Statuses. */
export enum MeterPointStatus {
  CancelledApplication = "CANCELLED_APPLICATION",
  CustomRequirementNeeded = "CUSTOM_REQUIREMENT_NEEDED",
  Disconnected = "DISCONNECTED",
  DisconnectFailed = "DISCONNECT_FAILED",
  Initial = "INITIAL",
  Lost = "LOST",
  OnSupply = "ON_SUPPLY",
  PendingApplicationAcceptance = "PENDING_APPLICATION_ACCEPTANCE",
  PendingCancelResponse = "PENDING_CANCEL_RESPONSE",
  PendingDisconnectRequest = "PENDING_DISCONNECT_REQUEST",
  PendingDisconnectResponse = "PENDING_DISCONNECT_RESPONSE",
  PendingFinalUsage = "PENDING_FINAL_USAGE",
  PendingInitialMeterRead = "PENDING_INITIAL_METER_READ",
  PendingMoveInRequest = "PENDING_MOVE_IN_REQUEST",
  PendingMoveOutRequest = "PENDING_MOVE_OUT_REQUEST",
  PendingMoveOutResponse = "PENDING_MOVE_OUT_RESPONSE",
  PendingReassignment = "PENDING_REASSIGNMENT",
  PendingReconnectRequest = "PENDING_RECONNECT_REQUEST",
  PendingReconnectResponse = "PENDING_RECONNECT_RESPONSE",
  PendingSwitchHoldRequest = "PENDING_SWITCH_HOLD_REQUEST",
  PendingSwitchHoldResponse = "PENDING_SWITCH_HOLD_RESPONSE",
  PendingSwitchRequest = "PENDING_SWITCH_REQUEST",
  PermitRequired = "PERMIT_REQUIRED",
  Reconnected = "RECONNECTED",
  RejectedApplication = "REJECTED_APPLICATION",
  RequestCancelled = "REQUEST_CANCELLED",
  RequestRejected = "REQUEST_REJECTED",
  SwitchHold = "SWITCH_HOLD",
}

export enum MeterTypeOptions {
  /** No remote connect and disconnect capability */
  Amsm = "AMSM",
  /** Has remote connect and disconnect capability */
  Amsr = "AMSR",
}

export type MonthlyPrice = {
  __typename?: "MonthlyPrice";
  /** The date at which we priced this rate. */
  effectiveDate?: Maybe<Scalars["DateTime"]>;
  /** The kWh price based on an avg 1000 kWh consumption. */
  kwhPrice?: Maybe<Scalars["Decimal"]>;
};

/**
 * Mutations are the GraphQL equivalent of POST requests in REST. By convention, they are used when data is mutated on the server. To learn about how to form Mutations in graphql, see [GraphQL's documentation](https://graphql.org/learn/queries/#mutations).
 *
 * ⬅️ This interface will autocomplete, so just try typing what you want. You can also search these docs. Some mutations will require authentication. Check the documentation or search `Authentication` for details.
 */
export type Mutation = {
  __typename?: "Mutation";
  /**
   * Accept a goods quote.
   *
   * The possible errors that can be raised are:
   *
   * - KT-CT-8223: Unauthorized.
   * - KT-CT-8201: Received an invalid quoteId.
   * - KT-CT-8224: Invalid data.
   * - KT-CT-1113: Disabled GraphQL field requested.
   * - KT-CT-1111: Unauthorized.
   * - KT-CT-1112: 'Authorization' header not provided.
   */
  acceptGoodsQuote?: Maybe<AcceptGoodsQuote>;
  /**
   * Acquire OAuth tokens from vendor.
   *
   * The possible errors that can be raised are:
   *
   * - KT-CT-7223: Unauthorized.
   * - KT-CT-4810: Unable to find matching device for this request.
   * - KT-CT-1113: Disabled GraphQL field requested.
   */
  acquireOauthTokens?: Maybe<AcquireOauthTokens>;
  /**
   * The possible errors that can be raised are:
   *
   * - KT-CT-4123: Unauthorized.
   * - KT-CT-1113: Disabled GraphQL field requested.
   * - KT-CT-1111: Unauthorized.
   * - KT-CT-1112: 'Authorization' header not provided.
   */
  addAccountCampaign?: Maybe<AddAccountCampaign>;
  /**
   * The possible errors that can be raised are:
   *
   * - KT-CT-4123: Unauthorized.
   * - KT-CT-1113: Disabled GraphQL field requested.
   * - KT-CT-1111: Unauthorized.
   * - KT-CT-1112: 'Authorization' header not provided.
   */
  addCampaignToAccount?: Maybe<AddCampaignToAccount>;
  /**
   * Add property to a shell account.
   *
   * The possible errors that can be raised are:
   *
   * - KT-CT-6623: Unauthorized.
   * - KT-US-6611: Product not found.
   * - KT-US-6612: Account cannot have multiple properties. Please contact OPS.
   * - KT-US-6620: Given ESI ID is not served by us.
   * - KT-US-4615: Received an invalid quote code.
   * - KT-US-6622: Preferred supply start date cannot be in the past.
   * - KT-US-6624: Large commercial properties are not being served at the moment.
   * - KT-CT-1113: Disabled GraphQL field requested.
   * - KT-CT-1111: Unauthorized.
   * - KT-CT-1112: 'Authorization' header not provided.
   */
  addPropertyToShellAccount?: Maybe<AddPropertyToShellAccount>;
  /**
   * Allow a repayment to be submitted.
   *
   * The possible errors that can be raised are:
   *
   * - KT-CT-3944: Account repayment does not exist.
   * - KT-CT-3945: Unable to allow a repayment to be submitted.
   * - KT-CT-3950: The provided reason text is too long.
   * - KT-CT-1113: Disabled GraphQL field requested.
   * - KT-CT-1111: Unauthorized.
   * - KT-CT-1112: 'Authorization' header not provided.
   */
  allowRepaymentSubmission?: Maybe<AllowRepaymentSubmission>;
  /**
   * Amend an existing payment.
   *
   * The possible errors that can be raised are:
   *
   * - KT-CT-3924: Unauthorized.
   * - KT-CT-1113: Disabled GraphQL field requested.
   * - KT-CT-1111: Unauthorized.
   * - KT-CT-1112: 'Authorization' header not provided.
   */
  amendPayment?: Maybe<AmendPayment>;
  /**
   * Apply referrals or promo codes.
   *
   * The possible errors that can be raised are:
   *
   * - KT-CT-6710: Unable to create referral.
   * - KT-US-6711: Invalid reference.
   * - KT-CT-6712: Invalid reference.
   * - KT-CT-6723: Unauthorized.
   * - KT-CT-1113: Disabled GraphQL field requested.
   * - KT-CT-1111: Unauthorized.
   * - KT-CT-1112: 'Authorization' header not provided.
   */
  applyReferralCode?: Maybe<ApplyReward>;
  /**
   * Approve a repayment.
   *
   * The possible errors that can be raised are:
   *
   * - KT-CT-3934: Repayment request already approved.
   * - KT-CT-3935: Repayment request cannot be paid.
   * - KT-CT-3959: Unauthorized.
   * - KT-CT-1113: Disabled GraphQL field requested.
   * - KT-CT-1111: Unauthorized.
   * - KT-CT-1112: 'Authorization' header not provided.
   */
  approveRepayment?: Maybe<ApproveRepayment>;
  assignInkBucket?: Maybe<AssignInkBucketPayload>;
  /**
   * Look up an event to perform from its event_id, and return the next action to perform.
   *
   * The possible errors that can be raised are:
   *
   * - KT-CT-1111: Unauthorized.
   * - KT-CT-8002: No event found.
   * - KT-CT-8003: Event has no execute function.
   * - KT-CT-8004: Error executing event in the backend.
   * - KT-CT-8007: Incorrect or missing parameters for backend screen event.
   * - KT-GB-9310: Account ineligible for joining Octoplus.
   * - KT-CT-1113: Disabled GraphQL field requested.
   */
  backendScreenEvent?: Maybe<BackendScreenEvent>;
  /**
   * Block a repayment from being submitted.
   *
   * The possible errors that can be raised are:
   *
   * - KT-CT-3944: Account repayment does not exist.
   * - KT-CT-3946: Unable to block a repayment from being submitted.
   * - KT-CT-3950: The provided reason text is too long.
   * - KT-CT-1113: Disabled GraphQL field requested.
   * - KT-CT-1111: Unauthorized.
   * - KT-CT-1112: 'Authorization' header not provided.
   */
  blockRepaymentSubmission?: Maybe<BlockRepaymentSubmission>;
  /**
   * Cancel a leave supplier process.
   *
   * The possible errors that can be raised are:
   *
   * - KT-CT-10304: Mutation not enabled in this environment.
   * - KT-CT-10302: Invalid data.
   * - KT-CT-10305: Failed to cancel leave supplier process - market actions are no longer cancellable.
   * - KT-CT-10306: Failed to cancel leave supplier process - the cancellation workflow has not been configured.
   * - KT-CT-10307: Failed to cancel leave supplier process - failed to cancel market actions.
   * - KT-CT-10308: Failed to cancel leave supplier process.
   * - KT-CT-10311: Failed to cancel leave supplier process. The process status is not in cancellable status.
   * - KT-CT-1113: Disabled GraphQL field requested.
   * - KT-CT-1111: Unauthorized.
   * - KT-CT-1112: 'Authorization' header not provided.
   */
  cancelLeaveSupplier: LeaveSupplierCancelled;
  /**
   * Cancel an in-flight payment.
   *
   * The possible errors that can be raised are:
   *
   * - KT-CT-3924: Unauthorized.
   * - KT-CT-3954: Payment cancellation failed.
   * - KT-CT-3955: Payment cannot be cancelled.
   * - KT-CT-3956: Temporary error occurred.
   * - KT-CT-1113: Disabled GraphQL field requested.
   * - KT-CT-1111: Unauthorized.
   * - KT-CT-1112: 'Authorization' header not provided.
   */
  cancelPayment?: Maybe<CancelPayment>;
  /**
   * Cancel a repayment or refund request.
   *
   * The possible errors that can be raised are:
   *
   * - KT-CT-4231: Unauthorized.
   * - KT-CT-3930: The repayment or refund request does not exist.
   * - KT-CT-3931: This repayment or refund request cannot be cancelled.
   * - KT-CT-1113: Disabled GraphQL field requested.
   */
  cancelRepaymentRequest?: Maybe<CancelRepaymentRequest>;
  /**
   * Cancel onboarding of a device with SmartFlex.
   *
   * The possible errors that can be raised are:
   *
   * - KT-CT-4371: Onboarding wizard ID is invalid.
   * - KT-CT-4372: Simultaneous attempts to update onboarding process.
   * - KT-CT-1113: Disabled GraphQL field requested.
   * - KT-CT-1111: Unauthorized.
   * - KT-CT-1112: 'Authorization' header not provided.
   */
  cancelSmartFlexOnboarding?: Maybe<CancelSmartFlexOnboarding>;
  /**
   * Update the current payment schedule to a new type for autopay.
   *
   * The possible errors that can be raised are:
   *
   * - KT-CT-6623: Unauthorized.
   * - KT-CT-3822: Unauthorized.
   * - KT-US-3915: Account must have an active payment instruction of the given type.
   * - KT-US-3914: Prepay account must not be Manual pay.
   * - KT-CT-3815: No active payment schedule found for this account.
   * - KT-CT-1113: Disabled GraphQL field requested.
   * - KT-CT-1111: Unauthorized.
   * - KT-CT-1112: 'Authorization' header not provided.
   */
  changePaymentScheduleType?: Maybe<ChangePaymentScheduleType>;
  /**
   * Close the Open Print Batch if any.
   *
   * The possible errors that can be raised are:
   *
   * - KT-CT-9010: Invalid data.
   * - KT-CT-1113: Disabled GraphQL field requested.
   * - KT-CT-1111: Unauthorized.
   * - KT-CT-1112: 'Authorization' header not provided.
   */
  closeOpenPrintBatch: CloseOpenPrintBatch;
  /**
   * Collect an ad hoc ACH payment.
   *
   * The possible errors that can be raised are:
   *
   * - KT-CT-3923: Unauthorized.
   * - KT-US-3911: No active direct debit instruction found for this account.
   * - KT-US-3916: Payment could not be collected for the given account number.
   * - KT-CT-1113: Disabled GraphQL field requested.
   * - KT-CT-1111: Unauthorized.
   * - KT-CT-1112: 'Authorization' header not provided.
   * @deprecated The 'collectAdHocAchPayment' field is deprecated.
   *
   * Please use 'collectPayment' instead.
   *
   * - Marked as deprecated on 2022-04-21.
   * - Will be removed on 2023-12-15.
   */
  collectAdHocACHPayment?: Maybe<CollectAdHocAchPayment>;
  /**
   * Collect an ad hoc payment for a given account.
   *
   * The possible errors that can be raised are:
   *
   * - KT-CT-3923: Unauthorized.
   * - KT-US-3912: Invalid reference or given account number is invalid.
   * - KT-US-3913: No active instruction found for this account.
   * - KT-US-3916: Payment could not be collected for the given account number.
   * - KT-CT-1113: Disabled GraphQL field requested.
   * - KT-CT-1111: Unauthorized.
   * - KT-CT-1112: 'Authorization' header not provided.
   * @deprecated The 'collectAdHocPaymentForInstruction' field is deprecated.
   *
   * Please use 'collectPayment' instead.
   *
   * - Marked as deprecated on 2022-04-21.
   * - Will be removed on 2023-12-15.
   */
  collectAdHocPaymentForInstruction?: Maybe<CollectAdHocPaymentForInstruction>;
  /**
   * Collect deposit for the given account.
   *
   * The possible errors that can be raised are:
   *
   * - KT-CT-4177: Unauthorized.
   * - KT-CT-5711: No collection is required.
   * - KT-CT-5712: Deposit agreement does not exist or has not been accepted.
   * - KT-CT-1113: Disabled GraphQL field requested.
   * - KT-CT-1111: Unauthorized.
   * - KT-CT-1112: 'Authorization' header not provided.
   */
  collectDeposit?: Maybe<CollectDeposit>;
  /**
   * Attempt to collect a one-off payment. If an instruction type is provided and there is an existing payment instruction, the payment can be collected immediately. A request to collect a payment at a future date can also be made, in which case the instruction input type is not necessary, but an instruction must exist at the specified collection date for the payment to be collected successfully.
   *
   * The possible errors that can be raised are:
   *
   * - KT-CT-3932: Invalid data.
   * - KT-CT-1113: Disabled GraphQL field requested.
   * - KT-CT-1111: Unauthorized.
   * - KT-CT-1112: 'Authorization' header not provided.
   */
  collectPayment?: Maybe<CollectPayment>;
  /**
   * Complete the authentication flow to proceed in the onboarding journey.
   *
   * The possible errors that can be raised are:
   *
   * - KT-CT-4371: Onboarding wizard ID is invalid.
   * - KT-CT-4372: Simultaneous attempts to update onboarding process.
   * - KT-CT-4375: Incorrect or missing parameters for SmartFlex onboarding step.
   * - KT-CT-4376: Unable to complete onboarding step. Please try agan later.
   * - KT-CT-4377: Invalid onboarding step ID.
   * - KT-CT-4378: Invalid input or step id. Please make sure you are using the correct step id and providing the expected input params.
   * - KT-CT-4379: Vehicle is not ready for a test charge.
   * - KT-CT-1113: Disabled GraphQL field requested.
   * - KT-CT-1111: Unauthorized.
   * - KT-CT-1112: 'Authorization' header not provided.
   */
  completeAuthFlowForSmartFlexOnboarding?: Maybe<CompleteAuthFlowForSmartFlexOnboarding>;
  /**
   * Complete the Tesla virtual key setup onboarding step.
   *
   * The possible errors that can be raised are:
   *
   * - KT-CT-4371: Onboarding wizard ID is invalid.
   * - KT-CT-4372: Simultaneous attempts to update onboarding process.
   * - KT-CT-4375: Incorrect or missing parameters for SmartFlex onboarding step.
   * - KT-CT-4376: Unable to complete onboarding step. Please try agan later.
   * - KT-CT-4377: Invalid onboarding step ID.
   * - KT-CT-4378: Invalid input or step id. Please make sure you are using the correct step id and providing the expected input params.
   * - KT-CT-4379: Vehicle is not ready for a test charge.
   * - KT-CT-1113: Disabled GraphQL field requested.
   * - KT-CT-1111: Unauthorized.
   * - KT-CT-1112: 'Authorization' header not provided.
   */
  completeTeslaSetupVirtualKeyForSmartFlexOnboarding?: Maybe<CompleteTeslaSetupVirtualKeyForSmartFlexOnboarding>;
  /**
   * Mutation for creating an account.
   *
   * The possible errors that can be raised are:
   *
   * - KT-US-4101: Cannot create an account.
   * - KT-US-4116: Either salesSubchannel or salesAffiliateSubdomain is required.
   * - KT-US-4417: Received an invalid state code/name.
   * - KT-US-4118: Invalid US state.
   * - KT-CT-4123: Unauthorized.
   * - KT-CT-1113: Disabled GraphQL field requested.
   * - KT-CT-1111: Unauthorized.
   * - KT-CT-1112: 'Authorization' header not provided.
   */
  createAccount?: Maybe<CreateAccount>;
  /**
   * Add charge to an account.
   *
   * The possible errors that can be raised are:
   *
   * - KT-CT-5211: The charge reason with the requested code is deprecated.
   * - KT-CT-5212: The charge reason with the requested code does not exist.
   * - KT-CT-5213: Invalid data.
   * - KT-CT-1113: Disabled GraphQL field requested.
   * - KT-CT-1111: Unauthorized.
   * - KT-CT-1112: 'Authorization' header not provided.
   */
  createAccountCharge?: Maybe<CreateAccountCharge>;
  /**
   * Add credit to an account.
   *
   * The possible errors that can be raised are:
   *
   * - KT-CT-5315: Invalid data.
   * - KT-CT-5314: Invalid data.
   * - KT-CT-1113: Disabled GraphQL field requested.
   * - KT-CT-1111: Unauthorized.
   * - KT-CT-1112: 'Authorization' header not provided.
   * @deprecated The 'createAccountCredit' field is deprecated.
   *
   * Use postCredit mutation as it is ledger aware.
   *
   * - Marked as deprecated on 2022-07-04.
   * - Will be removed on 2024-01-01.
   */
  createAccountCredit?: Maybe<CreateAccountCredit>;
  createAccountFileAttachment: CreateAccountFileAttachmentPayload;
  /**
   * Add a note to an account.
   *
   * The possible errors that can be raised are:
   *
   * - KT-CT-4123: Unauthorized.
   * - KT-CT-4180: Account note must be a valid string.
   * - KT-CT-1113: Disabled GraphQL field requested.
   * - KT-CT-1111: Unauthorized.
   * - KT-CT-1112: 'Authorization' header not provided.
   */
  createAccountNote?: Maybe<CreateAccountNote>;
  /**
   * Replace an existing payment schedule with a new one that updates either the payment amount or payment day.
   *
   * The possible errors that can be raised are:
   *
   * - KT-CT-1111: Unauthorized.
   * - KT-CT-3815: No active payment schedule found for this account.
   * - KT-CT-3822: Unauthorized.
   * - KT-CT-3923: Unauthorized.
   * - KT-CT-3941: Invalid data.
   * - KT-CT-3942: An unexpected error occurred.
   * - KT-CT-3947: An unexpected error occurred.
   * - KT-CT-3960: Invalid value for payment day.
   * - KT-CT-3961: Cannot update plan-associated payment schedule.
   * - KT-CT-3962: No new value provided to update payment schedule.
   * - KT-CT-1113: Disabled GraphQL field requested.
   * - KT-CT-1111: Unauthorized.
   * - KT-CT-1112: 'Authorization' header not provided.
   */
  createAccountPaymentSchedule?: Maybe<CreateAccountPaymentSchedule>;
  /**
   * Create an account reference.
   *
   * The possible errors that can be raised are:
   *
   * - KT-CT-4123: Unauthorized.
   * - KT-CT-8310: Invalid data.
   * - KT-CT-8311: Invalid data.
   * - KT-CT-1113: Disabled GraphQL field requested.
   * - KT-CT-1111: Unauthorized.
   * - KT-CT-1112: 'Authorization' header not provided.
   */
  createAccountReference?: Maybe<CreateAccountReference>;
  /**
   * Create an account reminder.
   *
   * The possible errors that can be raised are:
   *
   * - KT-CT-1401: Invalid data.
   * - KT-CT-1402: Unable to create account reminder.
   * - KT-CT-1113: Disabled GraphQL field requested.
   * - KT-CT-1111: Unauthorized.
   * - KT-CT-1112: 'Authorization' header not provided.
   */
  createAccountReminder?: Maybe<CreateAccountReminder>;
  /**
   * Create an affiliate link for a new sales agent.
   *
   * The possible errors that can be raised are:
   *
   * - KT-CT-7711: Invalid data.
   * - KT-CT-7713: Invalid data.
   * - KT-CT-7714: Invalid data.
   * - KT-CT-7715: Invalid data.
   * - KT-CT-1113: Disabled GraphQL field requested.
   * - KT-CT-1111: Unauthorized.
   * - KT-CT-1112: 'Authorization' header not provided.
   */
  createAffiliateLink: CreateAffiliateLink;
  /**
   * Create an affiliate organisation.
   *
   * The possible errors that can be raised are:
   *
   * - KT-CT-7716: Invalid data.
   * - KT-CT-1113: Disabled GraphQL field requested.
   * - KT-CT-1111: Unauthorized.
   * - KT-CT-1112: 'Authorization' header not provided.
   */
  createAffiliateOrganisation: CreateAffiliateOrganisation;
  /** Create a session for an affiliate link. */
  createAffiliateSession: CreateAffiliateSession;
  /**
   * Mutation to create a new APICall instance.
   *
   * The possible errors that can be raised are:
   *
   * - KT-CT-7803: Received an invalid apiExceptionId.
   * - KT-CT-1113: Disabled GraphQL field requested.
   * - KT-CT-1111: Unauthorized.
   * - KT-CT-1112: 'Authorization' header not provided.
   */
  createApiCall?: Maybe<CreateApiCall>;
  /**
   * Mutation to create a new APIException instance.
   *
   * The possible errors that can be raised are:
   *
   * - KT-CT-7801: Received an invalid operationsTeamId.
   * - KT-CT-7802: The external identifier already exists.
   * - KT-CT-7805: Too many tags associated with this API Exception.
   * - KT-CT-7806: Cannot create duplicate tags for the same API exception.
   * - KT-CT-7811: Received an invalid assignedUserId.
   * - KT-CT-1113: Disabled GraphQL field requested.
   * - KT-CT-1111: Unauthorized.
   * - KT-CT-1112: 'Authorization' header not provided.
   */
  createApiException?: Maybe<CreateApiException>;
  /**
   * Mutation to create a new APIExceptionEvent instance.
   *
   * The possible errors that can be raised are:
   *
   * - KT-CT-7803: Received an invalid apiExceptionId.
   * - KT-CT-1113: Disabled GraphQL field requested.
   * - KT-CT-1111: Unauthorized.
   * - KT-CT-1112: 'Authorization' header not provided.
   */
  createApiExceptionEvent?: Maybe<CreateApiExceptionEvent>;
  /**
   * Mutation to create a new APIExceptionNote instance.
   *
   * The possible errors that can be raised are:
   *
   * - KT-CT-7803: Received an invalid apiExceptionId.
   * - KT-CT-1113: Disabled GraphQL field requested.
   * - KT-CT-1111: Unauthorized.
   * - KT-CT-1112: 'Authorization' header not provided.
   */
  createApiExceptionNote?: Maybe<CreateApiExceptionNote>;
  /**
   * Create a contribution agreement for an account.
   *
   * The possible errors that can be raised are:
   *
   * - KT-CT-4123: Unauthorized.
   * - KT-CT-9601: Invalid data.
   * - KT-CT-9602: Unable to create contribution agreement.
   * - KT-CT-9605: Contribution amount cannot be 0 or negative.
   * - KT-CT-9606: Scheme is not accepting contributions at this time.
   * - KT-CT-1113: Disabled GraphQL field requested.
   * - KT-CT-1111: Unauthorized.
   * - KT-CT-1112: 'Authorization' header not provided.
   */
  createContributionAgreement?: Maybe<CreateContributionAgreement>;
  /**
   * Create a new deposit agreement for the account if it needs one.
   *
   * The possible errors that can be raised are:
   *
   * - KT-CT-4177: Unauthorized.
   * - KT-CT-1113: Disabled GraphQL field requested.
   * - KT-CT-1111: Unauthorized.
   * - KT-CT-1112: 'Authorization' header not provided.
   */
  createDepositAgreement?: Maybe<CreateDepositAgreement>;
  /**
   * Create an external account event.
   *
   * The possible errors that can be raised are:
   *
   * - KT-CT-7123: Unauthorized.
   * - KT-CT-1113: Disabled GraphQL field requested.
   * - KT-CT-1111: Unauthorized.
   * - KT-CT-1112: 'Authorization' header not provided.
   */
  createExternalAccountEvent?: Maybe<CreateExternalAccountEvent>;
  /**
   * Create an external account user event.
   *
   * The possible errors that can be raised are:
   *
   * - KT-CT-7123: Unauthorized.
   * - KT-CT-1113: Disabled GraphQL field requested.
   * - KT-CT-1111: Unauthorized.
   * - KT-CT-1112: 'Authorization' header not provided.
   */
  createExternalAccountUserEvent?: Maybe<CreateExternalAccountUserEvent>;
  /**
   * Create a "form submission" entity. This is only meant to be used as a quick way of putting together a form and submit data for it, in the form of JSON - it is not expected that all form submissions will come through this path.
   *
   * This field requires the `Authorization` header to be set.
   */
  createFormSubmission?: Maybe<FormSubmissionOuput>;
  /**
   * Create a goods purchase.
   *
   * The possible errors that can be raised are:
   *
   * - KT-CT-8206: Invalid data.
   * - KT-CT-1113: Disabled GraphQL field requested.
   * - KT-CT-1111: Unauthorized.
   * - KT-CT-1112: 'Authorization' header not provided.
   */
  createGoodsPurchase?: Maybe<CreateGoodsPurchase>;
  /**
   * Create a goods quote.
   *
   * The possible errors that can be raised are:
   *
   * - KT-CT-8202: Invalid data.
   * - KT-CT-1113: Disabled GraphQL field requested.
   * - KT-CT-1111: Unauthorized.
   * - KT-CT-1112: 'Authorization' header not provided.
   */
  createGoodsQuote?: Maybe<CreateGoodsQuote>;
  /**
   * Create a goods quote without an account.
   *
   * The possible errors that can be raised are:
   *
   * - KT-CT-8202: Invalid data.
   * - KT-CT-1113: Disabled GraphQL field requested.
   * - KT-CT-1111: Unauthorized.
   * - KT-CT-1112: 'Authorization' header not provided.
   */
  createGoodsQuoteWithoutAccount?: Maybe<CreateGoodsQuoteWithoutAccount>;
  /** Register an Ink inbound message. */
  createInkInboundMessage?: Maybe<CreateInkInboundMessagePayload>;
  /**
   * Create metadata on an object.
   *
   * The possible errors that can be raised are:
   *
   * - KT-CT-8412: Invalid data.
   * - KT-CT-8414: Invalid data.
   * - KT-CT-1113: Disabled GraphQL field requested.
   * - KT-CT-1111: Unauthorized.
   * - KT-CT-1112: 'Authorization' header not provided.
   */
  createMetadata?: Maybe<CreateMetadata>;
  /**
   * Create a lead.
   *
   * The possible errors that can be raised are:
   *
   * - KT-CT-8901: Unable to create lead.
   * - KT-CT-1113: Disabled GraphQL field requested.
   * - KT-CT-1111: Unauthorized.
   * - KT-CT-1112: 'Authorization' header not provided.
   */
  createNewLead?: Maybe<CreateNewLead>;
  /**
   * Create or update a loyalty card for the given account user.
   *
   * The possible errors that can be raised are:
   *
   * - KT-CT-5412: No account user exists with the given id.
   * - KT-CT-8610: Invalid data.
   * - KT-CT-8611: Invalid data.
   * - KT-CT-1113: Disabled GraphQL field requested.
   * - KT-CT-1111: Unauthorized.
   * - KT-CT-1112: 'Authorization' header not provided.
   */
  createOrUpdateLoyaltyCard?: Maybe<CreateOrUpdateLoyaltyCardMutation>;
  /**
   * Mutation to create a new Portfolio instance.
   *
   * The possible errors that can be raised are:
   *
   * - KT-CT-9402: Received an invalid brandCode.
   * - KT-CT-9401: Received an invalid operationsTeamId.
   * - KT-CT-1113: Disabled GraphQL field requested.
   * - KT-CT-1111: Unauthorized.
   * - KT-CT-1112: 'Authorization' header not provided.
   */
  createPortfolio?: Maybe<CreatePortfolio>;
  /**
   * Mutation to create a new portfolio user role. This will effectively link the user to the portfolio giving them all the permissions enabled for the specific role.
   *
   * The possible errors that can be raised are:
   *
   * - KT-CT-9403: Received an invalid portfolioId.
   * - KT-CT-9404: Received an invalid accountUserId.
   * - KT-CT-1113: Disabled GraphQL field requested.
   * - KT-CT-1111: Unauthorized.
   * - KT-CT-1112: 'Authorization' header not provided.
   */
  createPortfolioUserRole?: Maybe<CreatePortfolioUserRole>;
  /**
   * Create a quote.
   *
   * The possible errors that can be raised are:
   *
   * - KT-CT-4616: Unable to create a quote.
   * - KT-CT-1113: Disabled GraphQL field requested.
   * - KT-CT-1111: Unauthorized.
   * - KT-CT-1112: 'Authorization' header not provided.
   */
  createQuote?: Maybe<CreateQuote>;
  /**
   * Create a referral using an email address, personal link or code.
   *
   * The possible errors that can be raised are:
   *
   * - KT-CT-6723: Unauthorized.
   * - KT-CT-6710: Unable to create referral.
   * - KT-CT-6711: Accounts may not self-refer.
   * - KT-CT-6713: Referring and referred account brands do not match.
   * - KT-CT-6712: Invalid reference.
   * - KT-CT-1113: Disabled GraphQL field requested.
   * - KT-CT-1111: Unauthorized.
   * - KT-CT-1112: 'Authorization' header not provided.
   */
  createReferral?: Maybe<CreateReferral>;
  /** Create a shell/payment account. */
  createShellAccount?: Maybe<CreateShellAccountPayload>;
  /**
   * Create a SmartDeviceToken object.
   *
   * The possible errors that can be raised are:
   *
   * - KT-CT-7223: Unauthorized.
   * - KT-CT-1113: Disabled GraphQL field requested.
   */
  createSmartDeviceToken?: Maybe<CreateSmartDeviceToken>;
  /**
   * Generate a Pre-signed S3 post request and create File Attachment.
   *
   * The possible errors that can be raised are:
   *
   * - KT-US-8701: Upload category not found.
   * - KT-US-8702: File already exists.
   * - KT-CT-1113: Disabled GraphQL field requested.
   * - KT-CT-1111: Unauthorized.
   * - KT-CT-1112: 'Authorization' header not provided.
   */
  createUsaFileAttachment?: Maybe<CreateUsaFileAttachment>;
  /**
   * Accept a quote for agreement renewal.
   *
   * The possible errors that can be raised are:
   *
   * - KT-CT-4623: Unauthorized.
   * - KT-CT-4618: Unable to accept given quote.
   * - KT-US-4615: Received an invalid quote code.
   * - KT-US-4617: Unable to create agreement.
   * - KT-CT-1113: Disabled GraphQL field requested.
   * - KT-CT-1111: Unauthorized.
   * - KT-CT-1112: 'Authorization' header not provided.
   */
  customerAcceptQuote?: Maybe<CustomerAcceptQuote>;
  /**
   * Request a quote for an existing customer.
   *
   * The possible errors that can be raised are:
   *
   * - KT-CT-4623: Unauthorized.
   * - KT-US-4517: Invalid ESI ID.
   * - KT-CT-4616: Unable to create a quote.
   * - KT-CT-1113: Disabled GraphQL field requested.
   * - KT-CT-1111: Unauthorized.
   * - KT-CT-1112: 'Authorization' header not provided.
   */
  customerCreateQuote?: Maybe<CustomerCreateQuote>;
  /**
   * Revoke future agreements without charges in order to request a new quote.
   *
   * The possible errors that can be raised are:
   *
   * - KT-CT-4623: Unauthorized.
   * - KT-CT-4617: Unable to revoke at least one future agreement.
   * - KT-CT-1113: Disabled GraphQL field requested.
   */
  customerRevokeFutureAgreements?: Maybe<CustomerRevokeFutureAgreements>;
  /**
   * De-authenticate a device.
   *
   * The possible errors that can be raised are:
   *
   * - KT-CT-4301: Unable to find device for given account.
   * - KT-CT-4350: Unable to de-authenticate device.
   * - KT-CT-1113: Disabled GraphQL field requested.
   */
  deauthenticateDevice?: Maybe<DeauthenticateDevice>;
  /**
   * Delete an account reference.
   *
   * The possible errors that can be raised are:
   *
   * - KT-CT-4123: Unauthorized.
   * - KT-CT-8310: Invalid data.
   * - KT-CT-8312: Invalid data.
   * - KT-CT-1113: Disabled GraphQL field requested.
   * - KT-CT-1111: Unauthorized.
   * - KT-CT-1112: 'Authorization' header not provided.
   */
  deleteAccountReference?: Maybe<DeleteAccountReference>;
  /**
   * Stop any active boost charging.
   *
   * The possible errors that can be raised are:
   *
   * - KT-CT-4301: Unable to find device for given account.
   * - KT-CT-4354: Unable to cancel boost charge.
   * - KT-CT-1113: Disabled GraphQL field requested.
   */
  deleteBoostCharge?: Maybe<DeleteBoostCharge>;
  /**
   * Delete a device token used for push notifications.
   *
   *
   *
   * This field requires the `Authorization` header to be set.
   *
   *
   *
   * The possible errors that can be raised are:
   *
   * - KT-CT-5411: Invalid token or no push notification binding found for the given account user.
   * - KT-CT-1113: Disabled GraphQL field requested.
   * - KT-CT-1111: Unauthorized.
   * - KT-CT-1112: 'Authorization' header not provided.
   */
  deletePushNotificationBinding?: Maybe<DeletePushNotificationBinding>;
  /**
   * Permanently delete access tokens associated with a given thermostat.
   *
   * The possible errors that can be raised are:
   *
   * - KT-CT-4810: Unable to find matching device for this request.
   * - KT-CT-4302: Unable to deregister site.
   * - KT-CT-1113: Disabled GraphQL field requested.
   */
  deleteThermostatToken?: Maybe<DeleteThermostatToken>;
  /**
   * Register a device (EV, battery or heat pump) for smart control.
   *
   * The possible errors that can be raised are:
   *
   * - KT-CT-4324: Device already registered error.
   * - KT-CT-4321: Serializer validation error.
   * - KT-CT-4312: Unable to register device.
   * - KT-CT-4363: No capable devices found.
   * - KT-CT-4364: Multiple devices found.
   * - KT-CT-1113: Disabled GraphQL field requested.
   */
  deviceRegistration?: Maybe<DeviceRegistration>;
  /**
   * Email a quote.
   *
   * The possible errors that can be raised are:
   *
   * - KT-US-4615: Received an invalid quote code.
   * - KT-CT-1113: Disabled GraphQL field requested.
   * - KT-CT-1111: Unauthorized.
   * - KT-CT-1112: 'Authorization' header not provided.
   */
  emailQuote?: Maybe<EmailQuote>;
  /**
   * End a contribution agreement for an account.
   *
   * The possible errors that can be raised are:
   *
   * - KT-CT-9603: Unable to find contribution agreement.
   * - KT-CT-4123: Unauthorized.
   * - KT-CT-9604: Unable to end contribution agreement.
   * - KT-CT-1113: Disabled GraphQL field requested.
   * - KT-CT-1111: Unauthorized.
   * - KT-CT-1112: 'Authorization' header not provided.
   */
  endContributionAgreement?: Maybe<EndContributionAgreement>;
  /**
   * Initiate an enrollment for an account and a set of supply points.
   *
   * The possible errors that can be raised are:
   *
   * - KT-CT-10312: Mutation not enabled in this environment.
   * - KT-CT-4501: Unauthorized.
   * - KT-CT-1602: Serializer validation error.
   * - KT-CT-10301: Unable to instigate leave supplier process.
   * - KT-CT-10309: Failed to update leave supplier process - the service is not enabled.
   * - KT-CT-10310: Failed to update leave supplier process. The process status is not in updatable status.
   * - KT-CT-1113: Disabled GraphQL field requested.
   * - KT-CT-1111: Unauthorized.
   * - KT-CT-1112: 'Authorization' header not provided.
   */
  enrollment: EnrollmentInitiated;
  /**
   * Force users of Kraken Tokens and refresh tokens issued to the viewer to reauthenticate.
   *
   * Calling this mutation will cause all Kraken Tokens and refresh tokens issued to the authenticated viewer before the mutation was called to become invalid.
   */
  forceReauthentication?: Maybe<ForceReauthentication>;
  /**
   * The possible errors that can be raised are:
   *
   * - KT-CT-7620: Channel not supported.
   * - KT-CT-7618: Unable to process message.
   * - KT-CT-7624: Error when generating the presigned URL.
   * - KT-CT-1113: Disabled GraphQL field requested.
   * - KT-CT-1111: Unauthorized.
   * - KT-CT-1112: 'Authorization' header not provided.
   */
  generateInkPresignedUrl?: Maybe<GenerateInkPresignedUrl>;
  /**
   * Generate a pre-signed token with a set expiry time.
   *
   * The possible errors that can be raised are:
   *
   * - KT-CT-1128: Unauthorized.
   * - KT-CT-1120: The Kraken Token has expired.
   * - KT-CT-1131: Unauthorized.
   * - KT-CT-1113: Disabled GraphQL field requested.
   * - KT-CT-1111: Unauthorized.
   * - KT-CT-1112: 'Authorization' header not provided.
   */
  generatePreSignedToken?: Maybe<GeneratePreSignedToken>;
  /**
   * Get the client secret needed to create a new payment instruction using an embedded form.
   *
   * The possible errors that can be raised are:
   *
   * - KT-CT-4177: Unauthorized.
   * - KT-CT-1113: Disabled GraphQL field requested.
   * - KT-CT-1111: Unauthorized.
   * - KT-CT-1112: 'Authorization' header not provided.
   */
  getEmbeddedSecretForNewPaymentInstruction?: Maybe<GetEmbeddedSecretForNewPaymentInstruction>;
  /**
   * Get the external URL where the user can set up a payment instruction.
   *
   * The possible errors that can be raised are:
   *
   * - KT-CT-1128: Unauthorized.
   * - KT-CT-3822: Unauthorized.
   * - KT-CT-1113: Disabled GraphQL field requested.
   * - KT-CT-1111: Unauthorized.
   * - KT-CT-1112: 'Authorization' header not provided.
   */
  getHostedUrlForNewPaymentInstruction?: Maybe<GetHostedUrlForNewPaymentInstruction>;
  /**
   * Initiate a standalone payment and return the url where the customer can complete it.
   *
   * The possible errors that can be raised are:
   *
   * - KT-CT-1128: Unauthorized.
   * - KT-CT-3822: Unauthorized.
   * - KT-CT-3943: Invalid ledger.
   * - KT-CT-3957: No collection method provided.
   * - KT-CT-3958: Provide either ledger ID or ledger number.
   * - KT-CT-1113: Disabled GraphQL field requested.
   * - KT-CT-1111: Unauthorized.
   * - KT-CT-1112: 'Authorization' header not provided.
   */
  initiateHostedStandalonePayment?: Maybe<InitiateHostedStandalonePayment>;
  /**
   * Initiate a MVO (Move Out) Request on a current account.
   *
   * The possible errors that can be raised are:
   *
   * - KT-CT-6623: Unauthorized.
   * - KT-US-6619: Can't Perform house move out (MVO).
   * - KT-US-6614: Unauthorized. Can't perform a house move out (MVO).
   * - KT-US-6615: House move out (MVO) process in progress.
   * - KT-US-6617: House move-out not possible. Move-out date can't be before today.
   * - KT-CT-4922: Unauthorized.
   * - KT-US-6616: Can't Perform House move out (MVO). Property must have exactly 1 meter point attached to it.
   * - KT-US-6618: Can't Perform house move out (MVO).
   * - KT-CT-1113: Disabled GraphQL field requested.
   * - KT-CT-1111: Unauthorized.
   * - KT-CT-1112: 'Authorization' header not provided.
   */
  initiateMoveOut?: Maybe<InitiateMoveOut>;
  /**
   * Do a product switch for a user.
   *
   * The possible errors that can be raised are:
   *
   * - KT-CT-1111: Unauthorized.
   * - KT-CT-4619: Quote with given code not found.
   * - KT-CT-4624: Unable to accept the given product code.
   * - KT-CT-4626: No product selected for the given quote code.
   * - KT-CT-4719: No supply point found for identifier provided.
   * - KT-CT-4922: Unauthorized.
   * - KT-CT-1507: Agreement product switch date is not within the acceptable range.
   * - KT-CT-1113: Disabled GraphQL field requested.
   * - KT-CT-1111: Unauthorized.
   * - KT-CT-1112: 'Authorization' header not provided.
   */
  initiateProductSwitch?: Maybe<InitiateProductSwitch>;
  /**
   * Initiate a standalone payment and return the client secret required to complete it.
   *
   * The possible errors that can be raised are:
   *
   * - KT-CT-4177: Unauthorized.
   * - KT-CT-3943: Invalid ledger.
   * - KT-CT-1113: Disabled GraphQL field requested.
   * - KT-CT-1111: Unauthorized.
   * - KT-CT-1112: 'Authorization' header not provided.
   */
  initiateStandalonePayment?: Maybe<InitiateStandalonePayment>;
  /**
   * Initiate a transfer of service to another property.
   *
   * The possible errors that can be raised are:
   *
   * - KT-CT-6623: Unauthorized.
   * - KT-CT-4922: Unauthorized.
   * - KT-US-4519: TDSP outside of supported service areas.
   * - KT-US-4415: Unable to find address for ESI ID.
   * - KT-US-6613: Unable to transfer service.
   * - KT-CT-1113: Disabled GraphQL field requested.
   * - KT-CT-1111: Unauthorized.
   * - KT-CT-1112: 'Authorization' header not provided.
   */
  initiateTransferOfService?: Maybe<InitiateTransferOfService>;
  /**
   * Instigate a leave supplier process or update an existing process.
   *
   * The possible errors that can be raised are:
   *
   * - KT-CT-10304: Mutation not enabled in this environment.
   * - KT-CT-4501: Unauthorized.
   * - KT-CT-1602: Serializer validation error.
   * - KT-JP-8801: Supply point not found.
   * - KT-CT-10301: Unable to instigate leave supplier process.
   * - KT-CT-10309: Failed to update leave supplier process - the service is not enabled.
   * - KT-CT-10310: Failed to update leave supplier process. The process status is not in updatable status.
   * - KT-CT-1113: Disabled GraphQL field requested.
   * - KT-CT-1111: Unauthorized.
   * - KT-CT-1112: 'Authorization' header not provided.
   */
  instigateLeaveSupplier: LeaveSupplierInstigated;
  /**
   * Invalidate an existing instruction.
   *
   * The possible errors that can be raised are:
   *
   * - KT-CT-3926: Unauthorized.
   * - KT-CT-1113: Disabled GraphQL field requested.
   * - KT-CT-1111: Unauthorized.
   * - KT-CT-1112: 'Authorization' header not provided.
   */
  invalidatePaymentInstruction?: Maybe<InvalidatePaymentInstruction>;
  /**
   * Invalidate a previously-issued pre-signed token.
   *
   * The possible errors that can be raised are:
   *
   * - KT-CT-1129: Unauthorized.
   * - KT-CT-1113: Disabled GraphQL field requested.
   * - KT-CT-1111: Unauthorized.
   * - KT-CT-1112: 'Authorization' header not provided.
   */
  invalidatePreSignedToken?: Maybe<InvalidatePreSignedToken>;
  /**
   * Invalidate pre-signed tokens issued to a particular user.
   *
   * The possible errors that can be raised are:
   *
   * - KT-CT-1129: Unauthorized.
   * - KT-CT-1113: Disabled GraphQL field requested.
   * - KT-CT-1111: Unauthorized.
   * - KT-CT-1112: 'Authorization' header not provided.
   */
  invalidatePreSignedTokensForUser?: Maybe<InvalidatePreSignedTokensForUser>;
  /**
   * Invalidate a previously-issued refresh token.
   *
   * The possible errors that can be raised are:
   *
   * - KT-CT-1130: Unauthorized.
   * - KT-CT-1113: Disabled GraphQL field requested.
   * - KT-CT-1111: Unauthorized.
   * - KT-CT-1112: 'Authorization' header not provided.
   */
  invalidateRefreshToken?: Maybe<InvalidateRefreshToken>;
  /**
   * Invalidate refresh tokens issued to a particular user.
   *
   * The possible errors that can be raised are:
   *
   * - KT-CT-1128: Unauthorized.
   * - KT-CT-1113: Disabled GraphQL field requested.
   * - KT-CT-1111: Unauthorized.
   * - KT-CT-1112: 'Authorization' header not provided.
   */
  invalidateRefreshTokensForUser?: Maybe<InvalidateRefreshTokensForUser>;
  /**
   * Accept terms and conditions for a join supplier process.
   *
   * The possible errors that can be raised are:
   *
   * - KT-CT-1111: Unauthorized.
   * - KT-CT-4501: Unauthorized.
   * - KT-CT-1113: Disabled GraphQL field requested.
   * - KT-CT-1111: Unauthorized.
   * - KT-CT-1112: 'Authorization' header not provided.
   */
  joinSupplierAcceptTermsAndConditions?: Maybe<JoinSupplierAcceptTermsAndConditions>;
  /** Link an account user and line user together. */
  linkUserToLine: LinkUserToLineResponse;
  /**
   * Mark the print batch as processed.
   *
   * The possible errors that can be raised are:
   *
   * - KT-CT-9011: Invalid data.
   * - KT-CT-1113: Disabled GraphQL field requested.
   * - KT-CT-1111: Unauthorized.
   * - KT-CT-1112: 'Authorization' header not provided.
   */
  markPrintBatchAsProcessed: MarkPrintBatchAsProcessed;
  /** Provide a temporary token to get an auth token. This is intended to allow support users to view customer data through the brand interface. */
  masqueradeAuthentication?: Maybe<MasqueradeAuthentication>;
  /**
   * Create a Kraken Token (JWT) for authentication.
   *
   * Provide the required input fields to obtain the token.
   *
   * The token should be used as the `Authorization` header for any authenticated requests.
   *
   * The possible errors that can be raised are:
   *
   * - KT-CT-1135: Invalid data.
   * - KT-CT-1134: Invalid data.
   * - KT-CT-1113: Disabled GraphQL field requested.
   */
  obtainKrakenToken?: Maybe<ObtainKrakenJsonWebToken>;
  /**
   * For authorized third-party organizations only.
   *
   * The possible errors that can be raised are:
   *
   * - KT-CT-1120: The Kraken Token has expired.
   * - KT-CT-1121: Please use Kraken Token to issue long-lived refresh tokens.
   * - KT-CT-1132: Unauthorized.
   * - KT-CT-1122: Long-lived refresh tokens can only be issued for account users.
   * - KT-CT-1113: Disabled GraphQL field requested.
   * - KT-CT-1111: Unauthorized.
   * - KT-CT-1112: 'Authorization' header not provided.
   */
  obtainLongLivedRefreshToken?: Maybe<ObtainLongLivedRefreshToken>;
  /**
   * Trigger OCPP authentication.
   *
   * The possible errors that can be raised are:
   *
   * - KT-CT-4301: Unable to find device for given account.
   * - KT-CT-4310: Unable to register OCPP authentication details.
   * - KT-CT-1113: Disabled GraphQL field requested.
   */
  ocppAuthentication?: Maybe<OcppAuthentication>;
  /**
   * Pause the dunning process for an account.
   *
   * The possible errors that can be raised are:
   *
   * - KT-CT-4178: No account found with given account number.
   * - KT-CT-11301: Account not in a dunning process for the given path name.
   * - KT-CT-11302: No active dunning process found.
   * - KT-CT-11303: Multiple active dunning processes found.
   * - KT-CT-11304: Dunning pause process failed verifying the dates.
   * - KT-CT-11305: Pausing the dunning process failed.
   * - KT-CT-1113: Disabled GraphQL field requested.
   * - KT-CT-1111: Unauthorized.
   * - KT-CT-1112: 'Authorization' header not provided.
   */
  pauseDunning?: Maybe<PauseDunning>;
  /**
   * Post credit to a ledger.
   *
   * The possible errors that can be raised are:
   *
   * - KT-CT-5316: Invalid data.
   * - KT-CT-5311: The credit reason with the requested code is deprecated.
   * - KT-CT-5312: The credit reason with the requested code does not exist.
   * - KT-CT-5313: An error occurred whilst posting the credit.
   * - KT-CT-1113: Disabled GraphQL field requested.
   * - KT-CT-1111: Unauthorized.
   * - KT-CT-1112: 'Authorization' header not provided.
   */
  postCredit?: Maybe<PostCredit>;
  /**
   * Prepare account for sign up. Returns the existing account and/or user if matching datafound for the provided input, otherwise creates a new account and account user.
   *
   * The possible errors that can be raised are:
   *
   * - KT-CT-10303: Mutation not enabled in this environment.
   * - KT-CT-1113: Disabled GraphQL field requested.
   * - KT-CT-1111: Unauthorized.
   * - KT-CT-1112: 'Authorization' header not provided.
   */
  prepareAccount: PrepareAccountResult;
  /**
   * Publish a trigger within the transactional messaging service.
   *
   * The possible errors that can be raised are:
   *
   * - KT-CT-9901: Invalid trigger type code.
   * - KT-CT-9902: Invalid trigger type params.
   * - KT-CT-9903: Trigger type cannot be published externally.
   * - KT-CT-1113: Disabled GraphQL field requested.
   * - KT-CT-1111: Unauthorized.
   * - KT-CT-1112: 'Authorization' header not provided.
   */
  publishTransactionalMessagingTrigger?: Maybe<PublishTransactionalMessagingTrigger>;
  /**
   * Reauthenticate an already registered device.
   *
   * The possible errors that can be raised are:
   *
   * - KT-CT-4313: Could not find KrakenFlex device.
   * - KT-CT-4314: Unable to get provider details.
   * - KT-CT-4315: Unable to re-authenticate device.
   * - KT-CT-4363: No capable devices found.
   * - KT-CT-4364: Multiple devices found.
   * - KT-CT-1113: Disabled GraphQL field requested.
   * @deprecated The 'reauthenticateDevice' field is deprecated.
   *
   * This is no longer a supported operation. Please deauthenticate and then re-register the device instead.
   *
   * - Marked as deprecated on 2024-07-22.
   * - Will be removed on 2024-12-01.
   */
  reauthenticateDevice?: Maybe<ReauthenticateDevice>;
  /**
   * Record the customer's acceptance of a deposit agreement.
   *
   * The possible errors that can be raised are:
   *
   * - KT-CT-4177: Unauthorized.
   * - KT-CT-1113: Disabled GraphQL field requested.
   * - KT-CT-1111: Unauthorized.
   * - KT-CT-1112: 'Authorization' header not provided.
   */
  recordDepositAgreementAccepted?: Maybe<RecordDepositAgreementAccepted>;
  /**
   * Redeem the passed number of Loyalty Points as account credit.
   *
   * The possible errors that can be raised are:
   *
   * - KT-CT-1111: Unauthorized.
   * - KT-CT-9201: No Loyalty Point ledger found for the user.
   * - KT-CT-9202: Loyalty Points adapter not configured.
   * - KT-CT-9203: No ledger entries for the ledger.
   * - KT-CT-9205: Insufficient Loyalty Points.
   * - KT-CT-9206: Indivisible points.
   * - KT-CT-9204: Negative or zero points set.
   * - KT-CT-9208: Invalid posted at datetime.
   * - KT-CT-9209: Negative Loyalty Points balance.
   * - KT-CT-9210: Unhandled Loyalty Points exception.
   * - KT-CT-1113: Disabled GraphQL field requested.
   */
  redeemLoyaltyPointsForAccountCredit?: Maybe<RedeemLoyaltyPointsForAccountCredit>;
  /**
   * Redeem the referral claim code from certain referral scheme.
   *
   * The possible errors that can be raised are:
   *
   * - KT-CT-6723: Unauthorized.
   * - KT-CT-6724: Referral claim code not found.
   * - KT-CT-6725: Referral claim code redeeming error.
   * - KT-CT-6726: Referral claim code has already been redeemed.
   * - KT-CT-6727: Referral claim code is not available.
   * - KT-CT-1113: Disabled GraphQL field requested.
   */
  redeemReferralClaimCode?: Maybe<RedeemReferralClaimCode>;
  /**
   * Refund a cleared payment.
   *
   * The possible errors that can be raised are:
   *
   * - KT-CT-3924: Unauthorized.
   * - KT-CT-3928: Idempotency key used for another repayment request.
   * - KT-CT-3929: The payment is not in a refundable state.
   * - KT-CT-3933: Refund amount greater than payment amount.
   * - KT-CT-1113: Disabled GraphQL field requested.
   * - KT-CT-1111: Unauthorized.
   * - KT-CT-1112: 'Authorization' header not provided.
   */
  refundPayment?: Maybe<RefundPayment>;
  /** Regenerate the live secret key for the authenticated user. */
  regenerateSecretKey?: Maybe<RegenerateSecretKey>;
  /**
   * Register a device token to be used for push notifications for an app.
   *
   *
   *
   * This field requires the `Authorization` header to be set.
   */
  registerPushNotificationBinding?: Maybe<RegisterPushNotificationBinding>;
  /**
   * Reset an enrollment account's password.
   *
   * The possible errors that can be raised are:
   *
   * - KT-CT-7323: Unauthorized.
   * - KT-CT-1113: Disabled GraphQL field requested.
   * - KT-CT-1111: Unauthorized.
   * - KT-CT-1112: 'Authorization' header not provided.
   */
  requestEnrollmentAccountPasswordReset?: Maybe<EnrollmentAccountResetPasswordMutation>;
  /**
   * Provide the email address of an account user to send them an email with instructions on how to reset their password.
   *
   * The possible errors that can be raised are:
   *
   * - KT-CT-1133: Unable to request password reset email.
   * - KT-CT-1113: Disabled GraphQL field requested.
   */
  requestPasswordReset?: Maybe<RequestPasswordResetOutputType>;
  /** Reset the password of an account user indicated by the userId to the value supplied. */
  resetPassword?: Maybe<ResetPasswordMutationPayload>;
  /**
   * Reset the password of an account user.
   *
   * The possible errors that can be raised are:
   *
   * - KT-CT-4125: Unauthorized.
   * - KT-CT-1132: Unauthorized.
   * - KT-CT-1113: Disabled GraphQL field requested.
   */
  resetUserPassword?: Maybe<ResetUserPasswordOutput>;
  /**
   * Resume control of the device.
   *
   * The possible errors that can be raised are:
   *
   * - KT-CT-4301: Unable to find device for given account.
   * - KT-CT-4359: Unable to resume device control.
   * - KT-CT-1113: Disabled GraphQL field requested.
   * @deprecated The 'resumeControl' field is deprecated.
   *
   * Please use 'updateDeviceSmartControl' instead.
   *
   * - Marked as deprecated on 2024-09-17.
   * - Will be removed on 2025-04-01.
   */
  resumeControl?: Maybe<ResumeDeviceControl>;
  /**
   * Revoke an agreement.
   *
   * The possible errors that can be raised are:
   *
   * - KT-CT-4123: Unauthorized.
   * - KT-CT-1501: Agreement not found.
   * - KT-CT-1502: Billed agreements cannot be revoked.
   * - KT-CT-1113: Disabled GraphQL field requested.
   * - KT-CT-1111: Unauthorized.
   * - KT-CT-1112: 'Authorization' header not provided.
   */
  revokeAgreement?: Maybe<RevokeAgreement>;
  /**
   * Mutation for running a credit check.
   *
   * The possible errors that can be raised are:
   *
   * - KT-US-4119: You are not authorized to use the credit engine.
   * - KT-US-3814: Received invalid data. Unable to store credit score.
   * - KT-US-3812: Unexpected error when running credit check.
   * - KT-CT-1113: Disabled GraphQL field requested.
   * - KT-CT-1111: Unauthorized.
   * - KT-CT-1112: 'Authorization' header not provided.
   */
  runCreditCheck?: Maybe<RunCreditCheck>;
  /**
   * Schedule a quote follow-up to the provided recipient.
   *
   * The possible errors that can be raised are:
   *
   * - KT-CT-4619: Quote with given code not found.
   * - KT-CT-4632: Invalid recipient information.
   * - KT-CT-4633: Mutation not enabled in this environment.
   * - KT-CT-1113: Disabled GraphQL field requested.
   */
  scheduleQuoteFollowUp?: Maybe<ScheduleQuoteFollowUp>;
  /**
   * Select the charge point make to proceed in the onboarding journey.
   *
   * The possible errors that can be raised are:
   *
   * - KT-CT-4371: Onboarding wizard ID is invalid.
   * - KT-CT-4372: Simultaneous attempts to update onboarding process.
   * - KT-CT-4375: Incorrect or missing parameters for SmartFlex onboarding step.
   * - KT-CT-4376: Unable to complete onboarding step. Please try agan later.
   * - KT-CT-4377: Invalid onboarding step ID.
   * - KT-CT-4378: Invalid input or step id. Please make sure you are using the correct step id and providing the expected input params.
   * - KT-CT-4379: Vehicle is not ready for a test charge.
   * - KT-CT-1113: Disabled GraphQL field requested.
   * - KT-CT-1111: Unauthorized.
   * - KT-CT-1112: 'Authorization' header not provided.
   */
  selectChargePointMakeForSmartFlexOnboarding?: Maybe<SelectChargePointMakeForSmartFlexOnboarding>;
  /**
   * Select the charge point model variant to proceed in the onboarding journey.
   *
   * The possible errors that can be raised are:
   *
   * - KT-CT-4371: Onboarding wizard ID is invalid.
   * - KT-CT-4372: Simultaneous attempts to update onboarding process.
   * - KT-CT-4375: Incorrect or missing parameters for SmartFlex onboarding step.
   * - KT-CT-4376: Unable to complete onboarding step. Please try agan later.
   * - KT-CT-4377: Invalid onboarding step ID.
   * - KT-CT-4378: Invalid input or step id. Please make sure you are using the correct step id and providing the expected input params.
   * - KT-CT-4379: Vehicle is not ready for a test charge.
   * - KT-CT-1113: Disabled GraphQL field requested.
   * - KT-CT-1111: Unauthorized.
   * - KT-CT-1112: 'Authorization' header not provided.
   */
  selectChargePointVariantForSmartFlexOnboarding?: Maybe<SelectChargePointVariantForSmartFlexOnboarding>;
  /**
   * Select the device type to proceed in the onboarding journey.
   *
   * The possible errors that can be raised are:
   *
   * - KT-CT-4371: Onboarding wizard ID is invalid.
   * - KT-CT-4372: Simultaneous attempts to update onboarding process.
   * - KT-CT-4375: Incorrect or missing parameters for SmartFlex onboarding step.
   * - KT-CT-4376: Unable to complete onboarding step. Please try agan later.
   * - KT-CT-4377: Invalid onboarding step ID.
   * - KT-CT-4378: Invalid input or step id. Please make sure you are using the correct step id and providing the expected input params.
   * - KT-CT-4379: Vehicle is not ready for a test charge.
   * - KT-CT-1113: Disabled GraphQL field requested.
   * - KT-CT-1111: Unauthorized.
   * - KT-CT-1112: 'Authorization' header not provided.
   */
  selectDeviceTypeForSmartFlexOnboarding?: Maybe<SelectDeviceTypeForSmartFlexOnboarding>;
  /**
   * Mark quoted products on a quote request as selected.
   *
   * The possible errors that can be raised are:
   *
   * - KT-CT-4619: Quote with given code not found.
   * - KT-CT-4634: Quoted product with given id not found.
   * - KT-CT-4626: No product selected for the given quote code.
   * - KT-CT-4635: Missing a quoted product for at least one quoted supply point on the quote request.
   * - KT-CT-4636: Quoted product not linked to a product.
   * - KT-CT-1113: Disabled GraphQL field requested.
   * - KT-CT-1111: Unauthorized.
   * - KT-CT-1112: 'Authorization' header not provided.
   */
  selectProducts?: Maybe<SelectProducts>;
  /**
   * Select the user's vehicle to proceed in the onboarding journey.
   *
   * The possible errors that can be raised are:
   *
   * - KT-CT-4371: Onboarding wizard ID is invalid.
   * - KT-CT-4372: Simultaneous attempts to update onboarding process.
   * - KT-CT-4375: Incorrect or missing parameters for SmartFlex onboarding step.
   * - KT-CT-4376: Unable to complete onboarding step. Please try agan later.
   * - KT-CT-4377: Invalid onboarding step ID.
   * - KT-CT-4378: Invalid input or step id. Please make sure you are using the correct step id and providing the expected input params.
   * - KT-CT-4379: Vehicle is not ready for a test charge.
   * - KT-CT-1113: Disabled GraphQL field requested.
   * - KT-CT-1111: Unauthorized.
   * - KT-CT-1112: 'Authorization' header not provided.
   */
  selectUserVehicleForSmartFlexOnboarding?: Maybe<SelectUserVehicleForSmartFlexOnboarding>;
  /**
   * Select the vehicle make to proceed in the onboarding journey.
   *
   * The possible errors that can be raised are:
   *
   * - KT-CT-4371: Onboarding wizard ID is invalid.
   * - KT-CT-4372: Simultaneous attempts to update onboarding process.
   * - KT-CT-4375: Incorrect or missing parameters for SmartFlex onboarding step.
   * - KT-CT-4376: Unable to complete onboarding step. Please try agan later.
   * - KT-CT-4377: Invalid onboarding step ID.
   * - KT-CT-4378: Invalid input or step id. Please make sure you are using the correct step id and providing the expected input params.
   * - KT-CT-4379: Vehicle is not ready for a test charge.
   * - KT-CT-1113: Disabled GraphQL field requested.
   * - KT-CT-1111: Unauthorized.
   * - KT-CT-1112: 'Authorization' header not provided.
   */
  selectVehicleMakeForSmartFlexOnboarding?: Maybe<SelectVehicleMakeForSmartFlexOnboarding>;
  /**
   * Select the vehicle model variant to proceed in the onboarding journey.
   *
   * The possible errors that can be raised are:
   *
   * - KT-CT-4371: Onboarding wizard ID is invalid.
   * - KT-CT-4372: Simultaneous attempts to update onboarding process.
   * - KT-CT-4375: Incorrect or missing parameters for SmartFlex onboarding step.
   * - KT-CT-4376: Unable to complete onboarding step. Please try agan later.
   * - KT-CT-4377: Invalid onboarding step ID.
   * - KT-CT-4378: Invalid input or step id. Please make sure you are using the correct step id and providing the expected input params.
   * - KT-CT-4379: Vehicle is not ready for a test charge.
   * - KT-CT-1113: Disabled GraphQL field requested.
   * - KT-CT-1111: Unauthorized.
   * - KT-CT-1112: 'Authorization' header not provided.
   */
  selectVehicleVariantForSmartFlexOnboarding?: Maybe<SelectVehicleVariantForSmartFlexOnboarding>;
  /**
   * Send a quote summary to the provided recipient.
   *
   * The possible errors that can be raised are:
   *
   * - KT-CT-4619: Quote with given code not found.
   * - KT-CT-4178: No account found with given account number.
   * - KT-CT-4632: Invalid recipient information.
   * - KT-CT-1113: Disabled GraphQL field requested.
   * - KT-CT-1111: Unauthorized.
   * - KT-CT-1112: 'Authorization' header not provided.
   */
  sendQuoteSummary?: Maybe<SendQuoteSummary>;
  /**
   * Set charging preferences for a home battery.
   *
   * The possible errors that can be raised are:
   *
   * - KT-CT-4321: Serializer validation error.
   * - KT-CT-4301: Unable to find device for given account.
   * - KT-CT-4353: An error occurred while trying to update your charging preferences.
   * - KT-CT-1113: Disabled GraphQL field requested.
   */
  setBatteryChargingPreferences?: Maybe<SetBatteryChargingPreferences>;
  /**
   * Set the user preferences for a device.
   *
   * The possible errors that can be raised are:
   *
   * - KT-CT-4314: Unable to get provider details.
   * - KT-CT-4321: Serializer validation error.
   * - KT-CT-4374: An error occurred while trying to set your device preferences.
   * - KT-CT-1113: Disabled GraphQL field requested.
   */
  setDevicePreferences?: Maybe<SmartFlexDeviceInterface>;
  /**
   * Mutation for setting email and phone number for LPL customers.
   *
   * The possible errors that can be raised are:
   *
   * - KT-CT-5413: Invalid data.
   * - KT-CT-5414: Invalid data.
   * - KT-CT-4123: Unauthorized.
   * - KT-CT-4121: Invalid phone number.
   * - KT-CT-4177: Unauthorized.
   * - KT-CT-1113: Disabled GraphQL field requested.
   * - KT-CT-1111: Unauthorized.
   * - KT-CT-1112: 'Authorization' header not provided.
   */
  setEmailAndPhone?: Maybe<SetEmailAndPhone>;
  /**
   * Set the Loyalty Point user for the account.
   *
   * The possible errors that can be raised are:
   *
   * - KT-CT-1111: Unauthorized.
   * - KT-CT-1111: Unauthorized.
   * - KT-CT-9210: Unhandled Loyalty Points exception.
   * - KT-CT-1113: Disabled GraphQL field requested.
   */
  setLoyaltyPointsUser?: Maybe<SetLoyaltyPointsUser>;
  /**
   * Set up a new direct debit instruction.
   *
   * The possible errors that can be raised are:
   *
   * - KT-CT-3940: Invalid data.
   * - KT-CT-1113: Disabled GraphQL field requested.
   * - KT-CT-1111: Unauthorized.
   * - KT-CT-1112: 'Authorization' header not provided.
   */
  setUpDirectDebitInstruction?: Maybe<SetUpDirectDebitInstruction>;
  /**
   * Set charging preferences for your electric vehicle.
   *
   * The possible errors that can be raised are:
   *
   * - KT-CT-4301: Unable to find device for given account.
   * - KT-CT-4321: Serializer validation error.
   * - KT-CT-4353: An error occurred while trying to update your charging preferences.
   * - KT-CT-1113: Disabled GraphQL field requested.
   * @deprecated The 'setVehicleChargingPreferences' field is deprecated.
   *
   * Please use `setDevicePreferences` instead of this endpoint.
   *
   * - Marked as deprecated on 2024-09-18.
   * - Will be removed on 2025-04-01.
   */
  setVehicleChargePreferences?: Maybe<SetVehicleChargingPreferences>;
  /**
   * Share a goods quote.
   *
   * The possible errors that can be raised are:
   *
   * - KT-CT-4122: Invalid email.
   * - KT-CT-8203: Received an invalid quote code.
   * - KT-CT-1113: Disabled GraphQL field requested.
   */
  shareGoodsQuote?: Maybe<ShareGoodsQuote>;
  /**
   * Create a wizard for onboarding a device with SmartFlex.
   *
   * The possible errors that can be raised are:
   *
   * - KT-CT-4321: Serializer validation error.
   * - KT-CT-1113: Disabled GraphQL field requested.
   * - KT-CT-1111: Unauthorized.
   * - KT-CT-1112: 'Authorization' header not provided.
   */
  startSmartFlexOnboarding?: Maybe<StartSmartFlexOnboarding>;
  /**
   * Attempt to start a test charge.
   *
   * The possible errors that can be raised are:
   *
   * - KT-CT-4371: Onboarding wizard ID is invalid.
   * - KT-CT-4372: Simultaneous attempts to update onboarding process.
   * - KT-CT-4375: Incorrect or missing parameters for SmartFlex onboarding step.
   * - KT-CT-4376: Unable to complete onboarding step. Please try agan later.
   * - KT-CT-4377: Invalid onboarding step ID.
   * - KT-CT-4378: Invalid input or step id. Please make sure you are using the correct step id and providing the expected input params.
   * - KT-CT-4379: Vehicle is not ready for a test charge.
   * - KT-CT-1113: Disabled GraphQL field requested.
   * - KT-CT-1111: Unauthorized.
   * - KT-CT-1112: 'Authorization' header not provided.
   */
  startTestChargeForSmartFlexOnboarding?: Maybe<StartTestChargeForSmartFlexOnboarding>;
  /**
   * Create an ach direct debit instruction.
   *
   * The possible errors that can be raised are:
   *
   * - KT-CT-3923: Unauthorized.
   * - KT-CT-1113: Disabled GraphQL field requested.
   * - KT-CT-1111: Unauthorized.
   * - KT-CT-1112: 'Authorization' header not provided.
   * @deprecated The 'storeAchDirectDebitInstruction' field is deprecated.
   *
   * Please use 'storePaymentInstruction' instead.
   *
   * - Marked as deprecated on 2022-04-21.
   * - Will be removed on 2023-12-15.
   */
  storeAchDirectDebitInstruction?: Maybe<StoreAchDirectDebitInstruction>;
  /**
   * Mutation for storing credit score.
   *
   * The possible errors that can be raised are:
   *
   * - KT-CT-4123: Unauthorized.
   * - KT-US-3814: Received invalid data. Unable to store credit score.
   * - KT-US-4414: Received an invalid postcode.
   * - KT-US-4117: Cannot find an account user with the given account number.
   * - KT-CT-1113: Disabled GraphQL field requested.
   * - KT-CT-1111: Unauthorized.
   * - KT-CT-1112: 'Authorization' header not provided.
   */
  storeCreditScore?: Maybe<StoreCreditScore>;
  /**
   * Store a new payment instruction created through the embedded process.
   *
   * The possible errors that can be raised are:
   *
   * - KT-CT-4177: Unauthorized.
   * - KT-CT-1113: Disabled GraphQL field requested.
   * - KT-CT-1111: Unauthorized.
   * - KT-CT-1112: 'Authorization' header not provided.
   */
  storePaymentInstruction?: Maybe<StorePaymentInstruction>;
  /**
   * Submit customer feedback.
   *
   * The possible errors that can be raised are:
   *
   * - KT-CT-5514: Unable to submit feedback.
   * - KT-CT-5511: The feedback_id should be provided for feedback source.
   * - KT-CT-5512: The feedback doesn't match the account.
   * - KT-CT-1113: Disabled GraphQL field requested.
   * - KT-CT-1111: Unauthorized.
   * - KT-CT-1112: 'Authorization' header not provided.
   */
  submitCustomerFeedback?: Maybe<SubmitCustomerFeedback>;
  /**
   * Submit a repayment request.
   *
   * The possible errors that can be raised are:
   *
   * - KT-CT-1132: Unauthorized.
   * - KT-CT-3927: Invalid Amount.
   * - KT-CT-3928: Idempotency key used for another repayment request.
   * - KT-CT-1113: Disabled GraphQL field requested.
   * - KT-CT-1111: Unauthorized.
   * - KT-CT-1112: 'Authorization' header not provided.
   */
  submitRepaymentRequest?: Maybe<SubmitRepaymentRequest>;
  /**
   * Suspend control of the device.
   *
   * The possible errors that can be raised are:
   *
   * - KT-CT-4301: Unable to find device for given account.
   * - KT-CT-4358: Unable to suspend device control.
   * - KT-CT-1113: Disabled GraphQL field requested.
   * @deprecated The 'suspendControl' field is deprecated.
   *
   * Please use 'updateDeviceSmartControl' instead.
   *
   * - Marked as deprecated on 2024-09-17.
   * - Will be removed on 2025-04-01.
   */
  suspendControl?: Maybe<SuspendDeviceControl>;
  /**
   * Completes the registration of a device if the contract is eligible and the device registration valid.
   *
   * The possible errors that can be raised are:
   *
   * - KT-CT-4321: Serializer validation error.
   * - KT-CT-4322: Unable to complete registration error.
   * - KT-CT-1113: Disabled GraphQL field requested.
   * - KT-CT-1111: Unauthorized.
   * - KT-CT-1112: 'Authorization' header not provided.
   */
  thirdPartyCompleteDeviceRegistration?: Maybe<ThirdPartyCompleteDeviceRegistration>;
  /**
   * Toggle Kraken's ability to control this thermostat.
   *
   * The possible errors that can be raised are:
   *
   * - KT-CT-4810: Unable to find matching device for this request.
   * - KT-CT-1113: Disabled GraphQL field requested.
   */
  toggleThermostatControl?: Maybe<ToggleThermostatControl>;
  /**
   * Transfer value from a source ledger to a destination ledger. This decreases the balance of the source ledger by the given amount and increases the balance of the destination ledger by the same amount. If the amount is negative, the effect is reversed (the source ledger's balance increases and the destination ledger's balance decreases).
   *
   *
   *
   * This field requires the `Authorization` header to be set.
   *
   *
   *
   * The possible errors that can be raised are:
   *
   * - KT-CT-3822: Unauthorized.
   * - KT-CT-3823: Unauthorized.
   * - KT-CT-9701: Balance transfer to same account is not allowed.
   * - KT-CT-9702: Balance transfer is not support for debit account with Zero balance.
   * - KT-CT-9703: Balance transfer is not supported for debit account.
   * - KT-CT-9704: Balance transfer amount should be non-zero.
   * - KT-CT-1113: Disabled GraphQL field requested.
   * - KT-CT-1111: Unauthorized.
   * - KT-CT-1112: 'Authorization' header not provided.
   */
  transferLedgerBalance?: Maybe<TransferLedgerBalance>;
  /**
   * Transfer Loyalty Point from one account user to another.
   *
   * The possible errors that can be raised are:
   *
   * - KT-CT-1111: Unauthorized.
   * - KT-CT-9205: Insufficient Loyalty Points.
   * - KT-CT-9204: Negative or zero points set.
   * - KT-CT-9208: Invalid posted at datetime.
   * - KT-CT-9209: Negative Loyalty Points balance.
   * - KT-CT-9210: Unhandled Loyalty Points exception.
   * - KT-CT-1113: Disabled GraphQL field requested.
   */
  transferLoyaltyPointsBetweenUsers?: Maybe<TransferLoyaltyPointsBetweenUsers>;
  /**
   * Initiate a boost charge for an electric vehicle (EV).
   *
   * This will start charging the EV and will not stop until the battery reaches 100% charged.
   *
   * If it is not possible to initiate a boost charge, a KT-CT-4357 error will be returned.
   * It may have a `boostChargeRefusalReasons` extension which lists the reasons why the boost
   * charge was refused. Possible reasons include:
   *
   * - `BC_DEVICE_NOT_YET_LIVE` (device is not yet live)
   * - `BC_DEVICE_RETIRED` (device is retired)
   * - `BC_DEVICE_SUSPENDED` (device is suspended)
   * - `BC_DEVICE_DISCONNECTED` (device is disconnected)
   * - `BC_DEVICE_NOT_AT_HOME` (device is not at home)
   * - `BC_BOOST_CHARGE_IN_PROGRESS` (boost charge already in progress)
   *
   * The possible errors that can be raised are:
   *
   * - KT-CT-4356: A boost charge cannot currently be performed.
   * - KT-CT-4357: Unable to trigger boost charge.
   * - KT-CT-1113: Disabled GraphQL field requested.
   */
  triggerBoostCharge?: Maybe<PerformBoostCharge>;
  /**
   * The possible errors that can be raised are:
   *
   * - KT-CT-8710: Invalid data.
   * - KT-CT-1113: Disabled GraphQL field requested.
   * - KT-CT-1111: Unauthorized.
   * - KT-CT-1112: 'Authorization' header not provided.
   */
  triggerPostUploadOperations: TriggerPostUploadOperations;
  /**
   * Initiate a test charge of an electric vehicle (EV).
   *
   * This is to ensure that the EV or EVSE (charge point) can be controlled remotely and successfully charged for a
   * short period.
   *
   * If it is not possible to initiate a test charge, a KT-CT-4355 error will be returned. It may have a
   * `testChargeRefusalReasons` extension which lists the reasons why the test charge was refused. Possible reasons
   * include:
   *
   * - `TC_DEVICE_LIVE` (device is already live)
   * - `TC_DEVICE_ONBOARDING_IN_PROGRESS` (test dispatch already in progress)
   * - `TC_DEVICE_RETIRED` (device is retired)
   * - `TC_DEVICE_SUSPENDED` (device is suspended)
   * - `TC_DEVICE_DISCONNECTED` (device is disconnected)
   * - `TC_DEVICE_AWAY_FROM_HOME` (device is away from home)
   * - `TC_DEVICE_NO_LOCATION_CONFIGURED` (device has no location configured)
   * - `TC_DEVICE_LOCATION_UNABLE_TO_IDENTIFY` (unable to identify device location)
   * - `TC_DEVICE_LOCATION_MISSING` (device location is missing)
   *
   * The possible errors that can be raised are:
   *
   * - KT-CT-4301: Unable to find device for given account.
   * - KT-CT-4355: Unable to trigger charge.
   * - KT-CT-1113: Disabled GraphQL field requested.
   */
  triggerTestCharge?: Maybe<PerformTestCharge>;
  /** Unlink an account user and line together. */
  unlinkUserFromLine: UnlinkUserFromLineResponse;
  /**
   * Update the account billing address.
   *
   * The possible errors that can be raised are:
   *
   * - KT-CT-4145: Invalid address.
   * - KT-CT-7123: Unauthorized.
   * - KT-CT-1113: Disabled GraphQL field requested.
   * - KT-CT-1111: Unauthorized.
   * - KT-CT-1112: 'Authorization' header not provided.
   */
  updateAccountBillingAddress?: Maybe<UpdateAccountBillingAddress>;
  /**
   * Update account billing email.
   *
   * The possible errors that can be raised are:
   *
   * - KT-CT-4123: Unauthorized.
   * - KT-CT-4122: Invalid email.
   * - KT-CT-1113: Disabled GraphQL field requested.
   * - KT-CT-1111: Unauthorized.
   * - KT-CT-1112: 'Authorization' header not provided.
   */
  updateAccountBillingEmail?: Maybe<UpdateAccountBillingEmail>;
  /**
   * Update an account reference.
   *
   * The possible errors that can be raised are:
   *
   * - KT-CT-4123: Unauthorized.
   * - KT-CT-8310: Invalid data.
   * - KT-CT-8311: Invalid data.
   * - KT-CT-1113: Disabled GraphQL field requested.
   * - KT-CT-1111: Unauthorized.
   * - KT-CT-1112: 'Authorization' header not provided.
   */
  updateAccountReference?: Maybe<UpdateAccountReference>;
  /**
   * Update the consents of an account user (the authenticated user)
   *
   * The possible errors that can be raised are:
   *
   * - KT-CT-9014: Duplicate consent.
   * - KT-CT-9016: Consent management not enabled.
   * - KT-CT-9017: Consent type not found.
   * - KT-CT-1113: Disabled GraphQL field requested.
   * - KT-CT-1111: Unauthorized.
   * - KT-CT-1112: 'Authorization' header not provided.
   */
  updateAccountUserConsents?: Maybe<UpdateAccountUserConsents>;
  /**
   * Update an active purchase.
   *
   * The possible errors that can be raised are:
   *
   * - KT-CT-8225: Received an invalid purchaseId.
   * - KT-CT-8226: The provided purchase is not active.
   * - KT-CT-8206: Invalid data.
   * - KT-CT-8227: Available grants could not be applied.
   * - KT-CT-1113: Disabled GraphQL field requested.
   * - KT-CT-1111: Unauthorized.
   * - KT-CT-1112: 'Authorization' header not provided.
   */
  updateActivePurchase?: Maybe<UpdateActivePurchase>;
  /**
   * Update an existing affiliate link.
   *
   * The possible errors that can be raised are:
   *
   * - KT-CT-7711: Invalid data.
   * - KT-CT-7713: Invalid data.
   * - KT-CT-7714: Invalid data.
   * - KT-CT-7715: Invalid data.
   * - KT-CT-1113: Disabled GraphQL field requested.
   * - KT-CT-1111: Unauthorized.
   * - KT-CT-1112: 'Authorization' header not provided.
   */
  updateAffiliateLink: UpdateAffiliateLink;
  /**
   * Update an existing affiliate organisation.
   *
   * The possible errors that can be raised are:
   *
   * - KT-CT-7717: Invalid data.
   * - KT-CT-1113: Disabled GraphQL field requested.
   * - KT-CT-1111: Unauthorized.
   * - KT-CT-1112: 'Authorization' header not provided.
   */
  updateAffiliateOrganisation: UpdateAffiliateOrganisation;
  /**
   * Update the period of an agreement.
   *
   * The possible errors that can be raised are:
   *
   * - KT-CT-4178: No account found with given account number.
   * - KT-CT-1501: Agreement not found.
   * - KT-CT-1503: Agreement valid_to date must be later than valid_from date.
   * - KT-CT-1504: Account does not match with the agreement.
   * - KT-CT-1505: Unable to edit agreement.
   * - KT-CT-1506: Agreement period is not within the supply and property period.
   * - KT-CT-1113: Disabled GraphQL field requested.
   * - KT-CT-1111: Unauthorized.
   * - KT-CT-1112: 'Authorization' header not provided.
   */
  updateAgreementPeriod?: Maybe<UpdateAgreementPeriod>;
  /**
   * Mutation to update an existing APIException instance.
   *
   * The possible errors that can be raised are:
   *
   * - KT-CT-7804: No fields present in the input for updating the APIException.
   * - KT-CT-7803: Received an invalid apiExceptionId.
   * - KT-CT-7809: Update results in no changes to API Exception.
   * - KT-CT-7805: Too many tags associated with this API Exception.
   * - KT-CT-7806: Cannot create duplicate tags for the same API exception.
   * - KT-CT-7801: Received an invalid operationsTeamId.
   * - KT-CT-7811: Received an invalid assignedUserId.
   * - KT-CT-7812: Support user is inactive.
   * - KT-CT-1113: Disabled GraphQL field requested.
   * - KT-CT-1111: Unauthorized.
   * - KT-CT-1112: 'Authorization' header not provided.
   */
  updateApiException?: Maybe<UpdateApiException>;
  /**
   * Mutation to update an existing APIExceptionNote instance.
   *
   * The possible errors that can be raised are:
   *
   * - KT-CT-7807: Received an invalid apiExceptionNoteId.
   * - KT-CT-7808: Unauthorized.
   * - KT-CT-1113: Disabled GraphQL field requested.
   * - KT-CT-1111: Unauthorized.
   * - KT-CT-1112: 'Authorization' header not provided.
   */
  updateApiExceptionNote?: Maybe<UpdateApiExceptionNote>;
  /**
   * Change the auto top up amount for the payment schedule.
   *
   * The possible errors that can be raised are:
   *
   * - KT-CT-3815: No active payment schedule found for this account.
   * - KT-CT-3941: Invalid data.
   * - KT-CT-3942: An unexpected error occurred.
   * - KT-CT-3947: An unexpected error occurred.
   * - KT-CT-3953: The payment schedule is not a balance triggered schedule.
   * - KT-CT-1113: Disabled GraphQL field requested.
   * - KT-CT-1111: Unauthorized.
   * - KT-CT-1112: 'Authorization' header not provided.
   */
  updateAutoTopUpAmount?: Maybe<UpdateAutoTopUpAmount>;
  /**
   * Update the Collection Process Record from raised status to active.
   *
   * The possible errors that can be raised are:
   *
   * - KT-CT-1111: Unauthorized.
   * - KT-CT-11201: No Collection Process Records associated with id.
   * - KT-CT-11202: No External reference provided.
   * - KT-CT-1113: Disabled GraphQL field requested.
   * - KT-CT-1111: Unauthorized.
   * - KT-CT-1112: 'Authorization' header not provided.
   */
  updateCollectionProcessRecordToActive?: Maybe<UpdateCollectionProcessRecordToActive>;
  /**
   * Update the Collection Process Record from raised status to complete.
   *
   * The possible errors that can be raised are:
   *
   * - KT-CT-1111: Unauthorized.
   * - KT-CT-11201: No Collection Process Records associated with id.
   * - KT-CT-11203: No Completion reason provided.
   * - KT-CT-11204: No Completion details provided.
   * - KT-CT-1113: Disabled GraphQL field requested.
   * - KT-CT-1111: Unauthorized.
   * - KT-CT-1112: 'Authorization' header not provided.
   */
  updateCollectionProcessRecordToComplete?: Maybe<UpdateCollectionProcessRecordToComplete>;
  /**
   * Update account communication delivery preference.
   *
   * The possible errors that can be raised are:
   *
   * - KT-CT-4123: Unauthorized.
   * - KT-CT-4136: Cannot set comms preference to email when account has no email.
   * - KT-CT-1113: Disabled GraphQL field requested.
   * - KT-CT-1111: Unauthorized.
   * - KT-CT-1112: 'Authorization' header not provided.
   */
  updateCommsDeliveryPreference?: Maybe<UpdateCommsDeliveryPreference>;
  /** Update the comms preferences of the account user (the authenticated user). */
  updateCommsPreferences?: Maybe<UpdateAccountUserCommsPreferencesMutationPayload>;
  /**
   * Suspends or resumes the smart control of a specific device.
   *
   * The possible errors that can be raised are:
   *
   * - KT-CT-4358: Unable to suspend device control.
   * - KT-CT-4359: Unable to resume device control.
   * - KT-CT-1113: Disabled GraphQL field requested.
   */
  updateDeviceSmartControl?: Maybe<SmartFlexDeviceInterface>;
  /**
   * Update an account's language preference.
   *
   * The possible errors that can be raised are:
   *
   * - KT-CT-4123: Unauthorized.
   * - KT-US-4101: Cannot create an account.
   * - KT-CT-1113: Disabled GraphQL field requested.
   */
  updateLanguagePreference?: Maybe<UpdateLanguagePreference>;
  updateMessageTags?: Maybe<UpdateMessageTagsPayload>;
  /**
   * Update metadata on an object.
   *
   * The possible errors that can be raised are:
   *
   * - KT-CT-8413: Invalid data.
   * - KT-CT-1113: Disabled GraphQL field requested.
   * - KT-CT-1111: Unauthorized.
   * - KT-CT-1112: 'Authorization' header not provided.
   */
  updateMetadata?: Maybe<UpdateMetadata>;
  /**
   * Update password of the authenticated user
   *
   *
   *
   * This field requires the `Authorization` header to be set.
   */
  updatePassword?: Maybe<UpdatePassword>;
  /**
   * Update user data associated with a quote.
   *
   * The possible errors that can be raised are:
   *
   * - KT-US-4615: Received an invalid quote code.
   * - KT-US-4619: No quoted supply point associated with this quote request.
   * - KT-US-4621: Unable to update quoted supply point.
   * - KT-CT-1113: Disabled GraphQL field requested.
   * - KT-CT-1111: Unauthorized.
   * - KT-CT-1112: 'Authorization' header not provided.
   */
  updateQuoteUser?: Maybe<UpdateQuoteUser>;
  /**
   * Update shell account communication preferences using an organization.
   *
   * The possible errors that can be raised are:
   *
   * - KT-CT-4124: Unauthorized.
   * - KT-CT-1113: Disabled GraphQL field requested.
   * - KT-CT-1111: Unauthorized.
   * - KT-CT-1112: 'Authorization' header not provided.
   */
  updateShellAccountCommsPreferences?: Maybe<UpdateShellAccountCommsPreference>;
  /**
   * Update the account user details of the authenticated user.
   *
   * Only one name field can be updated per day, other fields can be updated freely. This prevents users from switching accounts to someone else (usually when moving homes). All account changes should be handled by operations or the move out journey. New customers are exempt from this rule for the first 31 days.
   *
   *
   *
   * This field requires the `Authorization` header to be set.
   *
   *
   *
   * The possible errors that can be raised are:
   *
   * - KT-CT-5413: Invalid data.
   * - KT-CT-5414: Invalid data.
   * - KT-CT-1113: Disabled GraphQL field requested.
   * - KT-CT-1111: Unauthorized.
   * - KT-CT-1112: 'Authorization' header not provided.
   */
  updateUser?: Maybe<UpdateUserMutation>;
  /**
   * **DEPRECATED: Please use updateUser instead**
   *
   * Update the account user details of the authenticated user. Only one field can be updated per day. This prevents users from switching accounts to someone else (usually when moving homes) All account changes should be handled by operations or the move out journey. New customers are exempt from this rule for the first 31 days.
   * @deprecated The 'updateUserDetails' field is deprecated.
   *
   *
   * Please use the 'updateUser' mutation instead.
   *
   *
   * - Marked as deprecated on 2020-10-02.
   * - Will be removed on 2023-04-06.
   */
  updateUserDetails?: Maybe<UpdateAccountUserMutationPayload>;
  /** Validate user's email address. */
  validateEmail?: Maybe<ValidateEmail>;
  /** Validate user's phone number. */
  validatePhone?: Maybe<ValidatePhone>;
  /**
   * The possible errors that can be raised are:
   *
   * - KT-CT-4123: Unauthorized.
   * - KT-US-4418: Missing fraud record for email verification.
   * - KT-CT-1113: Disabled GraphQL field requested.
   * - KT-CT-1111: Unauthorized.
   * - KT-CT-1112: 'Authorization' header not provided.
   */
  verifyAccount?: Maybe<VerifyAccount>;
  /**
   * Verify user's email address.
   *
   * The possible errors that can be raised are:
   *
   * - KT-CT-1113: Disabled GraphQL field requested.
   * - KT-CT-1111: Unauthorized.
   * - KT-CT-1112: 'Authorization' header not provided.
   */
  verifyEmail?: Maybe<VerifyEmail>;
  /**
   * Provide identifying information about an account and user to get a scoped token that will permit access to associate an email address with the account’s user.
   *
   * The possible errors that can be raised are:
   *
   * - KT-CT-1145: Account/user details do not match.
   * - KT-CT-1113: Disabled GraphQL field requested.
   */
  verifyIdentity?: Maybe<VerifyIdentity>;
};

/**
 * Mutations are the GraphQL equivalent of POST requests in REST. By convention, they are used when data is mutated on the server. To learn about how to form Mutations in graphql, see [GraphQL's documentation](https://graphql.org/learn/queries/#mutations).
 *
 * ⬅️ This interface will autocomplete, so just try typing what you want. You can also search these docs. Some mutations will require authentication. Check the documentation or search `Authentication` for details.
 */
export type MutationAcceptGoodsQuoteArgs = {
  input: AcceptGoodsQuoteInput;
};

/**
 * Mutations are the GraphQL equivalent of POST requests in REST. By convention, they are used when data is mutated on the server. To learn about how to form Mutations in graphql, see [GraphQL's documentation](https://graphql.org/learn/queries/#mutations).
 *
 * ⬅️ This interface will autocomplete, so just try typing what you want. You can also search these docs. Some mutations will require authentication. Check the documentation or search `Authentication` for details.
 */
export type MutationAcquireOauthTokensArgs = {
  input: AcquireOauthTokensInput;
};

/**
 * Mutations are the GraphQL equivalent of POST requests in REST. By convention, they are used when data is mutated on the server. To learn about how to form Mutations in graphql, see [GraphQL's documentation](https://graphql.org/learn/queries/#mutations).
 *
 * ⬅️ This interface will autocomplete, so just try typing what you want. You can also search these docs. Some mutations will require authentication. Check the documentation or search `Authentication` for details.
 */
export type MutationAddAccountCampaignArgs = {
  input: AddAccountCampaignInput;
};

/**
 * Mutations are the GraphQL equivalent of POST requests in REST. By convention, they are used when data is mutated on the server. To learn about how to form Mutations in graphql, see [GraphQL's documentation](https://graphql.org/learn/queries/#mutations).
 *
 * ⬅️ This interface will autocomplete, so just try typing what you want. You can also search these docs. Some mutations will require authentication. Check the documentation or search `Authentication` for details.
 */
export type MutationAddCampaignToAccountArgs = {
  input: AddCampaignToAccountInput;
};

/**
 * Mutations are the GraphQL equivalent of POST requests in REST. By convention, they are used when data is mutated on the server. To learn about how to form Mutations in graphql, see [GraphQL's documentation](https://graphql.org/learn/queries/#mutations).
 *
 * ⬅️ This interface will autocomplete, so just try typing what you want. You can also search these docs. Some mutations will require authentication. Check the documentation or search `Authentication` for details.
 */
export type MutationAddPropertyToShellAccountArgs = {
  input: AddPropertyToShellAccountInputType;
};

/**
 * Mutations are the GraphQL equivalent of POST requests in REST. By convention, they are used when data is mutated on the server. To learn about how to form Mutations in graphql, see [GraphQL's documentation](https://graphql.org/learn/queries/#mutations).
 *
 * ⬅️ This interface will autocomplete, so just try typing what you want. You can also search these docs. Some mutations will require authentication. Check the documentation or search `Authentication` for details.
 */
export type MutationAllowRepaymentSubmissionArgs = {
  input: RepaymentInput;
};

/**
 * Mutations are the GraphQL equivalent of POST requests in REST. By convention, they are used when data is mutated on the server. To learn about how to form Mutations in graphql, see [GraphQL's documentation](https://graphql.org/learn/queries/#mutations).
 *
 * ⬅️ This interface will autocomplete, so just try typing what you want. You can also search these docs. Some mutations will require authentication. Check the documentation or search `Authentication` for details.
 */
export type MutationAmendPaymentArgs = {
  input: AmendPaymentInput;
};

/**
 * Mutations are the GraphQL equivalent of POST requests in REST. By convention, they are used when data is mutated on the server. To learn about how to form Mutations in graphql, see [GraphQL's documentation](https://graphql.org/learn/queries/#mutations).
 *
 * ⬅️ This interface will autocomplete, so just try typing what you want. You can also search these docs. Some mutations will require authentication. Check the documentation or search `Authentication` for details.
 */
export type MutationApplyReferralCodeArgs = {
  input: ApplyRewardInputType;
};

/**
 * Mutations are the GraphQL equivalent of POST requests in REST. By convention, they are used when data is mutated on the server. To learn about how to form Mutations in graphql, see [GraphQL's documentation](https://graphql.org/learn/queries/#mutations).
 *
 * ⬅️ This interface will autocomplete, so just try typing what you want. You can also search these docs. Some mutations will require authentication. Check the documentation or search `Authentication` for details.
 */
export type MutationApproveRepaymentArgs = {
  input: ApproveRepaymentInput;
};

/**
 * Mutations are the GraphQL equivalent of POST requests in REST. By convention, they are used when data is mutated on the server. To learn about how to form Mutations in graphql, see [GraphQL's documentation](https://graphql.org/learn/queries/#mutations).
 *
 * ⬅️ This interface will autocomplete, so just try typing what you want. You can also search these docs. Some mutations will require authentication. Check the documentation or search `Authentication` for details.
 */
export type MutationAssignInkBucketArgs = {
  input: AssignInkBucketInput;
};

/**
 * Mutations are the GraphQL equivalent of POST requests in REST. By convention, they are used when data is mutated on the server. To learn about how to form Mutations in graphql, see [GraphQL's documentation](https://graphql.org/learn/queries/#mutations).
 *
 * ⬅️ This interface will autocomplete, so just try typing what you want. You can also search these docs. Some mutations will require authentication. Check the documentation or search `Authentication` for details.
 */
export type MutationBackendScreenEventArgs = {
  input: BackendScreenEventInput;
};

/**
 * Mutations are the GraphQL equivalent of POST requests in REST. By convention, they are used when data is mutated on the server. To learn about how to form Mutations in graphql, see [GraphQL's documentation](https://graphql.org/learn/queries/#mutations).
 *
 * ⬅️ This interface will autocomplete, so just try typing what you want. You can also search these docs. Some mutations will require authentication. Check the documentation or search `Authentication` for details.
 */
export type MutationBlockRepaymentSubmissionArgs = {
  input: RepaymentInput;
};

/**
 * Mutations are the GraphQL equivalent of POST requests in REST. By convention, they are used when data is mutated on the server. To learn about how to form Mutations in graphql, see [GraphQL's documentation](https://graphql.org/learn/queries/#mutations).
 *
 * ⬅️ This interface will autocomplete, so just try typing what you want. You can also search these docs. Some mutations will require authentication. Check the documentation or search `Authentication` for details.
 */
export type MutationCancelLeaveSupplierArgs = {
  input: CancelLeaveSupplierInput;
};

/**
 * Mutations are the GraphQL equivalent of POST requests in REST. By convention, they are used when data is mutated on the server. To learn about how to form Mutations in graphql, see [GraphQL's documentation](https://graphql.org/learn/queries/#mutations).
 *
 * ⬅️ This interface will autocomplete, so just try typing what you want. You can also search these docs. Some mutations will require authentication. Check the documentation or search `Authentication` for details.
 */
export type MutationCancelPaymentArgs = {
  input: CancelPaymentInput;
};

/**
 * Mutations are the GraphQL equivalent of POST requests in REST. By convention, they are used when data is mutated on the server. To learn about how to form Mutations in graphql, see [GraphQL's documentation](https://graphql.org/learn/queries/#mutations).
 *
 * ⬅️ This interface will autocomplete, so just try typing what you want. You can also search these docs. Some mutations will require authentication. Check the documentation or search `Authentication` for details.
 */
export type MutationCancelRepaymentRequestArgs = {
  input: CancelRepaymentRequestInputType;
};

/**
 * Mutations are the GraphQL equivalent of POST requests in REST. By convention, they are used when data is mutated on the server. To learn about how to form Mutations in graphql, see [GraphQL's documentation](https://graphql.org/learn/queries/#mutations).
 *
 * ⬅️ This interface will autocomplete, so just try typing what you want. You can also search these docs. Some mutations will require authentication. Check the documentation or search `Authentication` for details.
 */
export type MutationCancelSmartFlexOnboardingArgs = {
  input: CancelSmartFlexOnboardingInput;
};

/**
 * Mutations are the GraphQL equivalent of POST requests in REST. By convention, they are used when data is mutated on the server. To learn about how to form Mutations in graphql, see [GraphQL's documentation](https://graphql.org/learn/queries/#mutations).
 *
 * ⬅️ This interface will autocomplete, so just try typing what you want. You can also search these docs. Some mutations will require authentication. Check the documentation or search `Authentication` for details.
 */
export type MutationChangePaymentScheduleTypeArgs = {
  input: ChangePaymentScheduleInput;
};

/**
 * Mutations are the GraphQL equivalent of POST requests in REST. By convention, they are used when data is mutated on the server. To learn about how to form Mutations in graphql, see [GraphQL's documentation](https://graphql.org/learn/queries/#mutations).
 *
 * ⬅️ This interface will autocomplete, so just try typing what you want. You can also search these docs. Some mutations will require authentication. Check the documentation or search `Authentication` for details.
 */
export type MutationCollectAdHocAchPaymentArgs = {
  input: CollectAdHocAchPaymentInput;
};

/**
 * Mutations are the GraphQL equivalent of POST requests in REST. By convention, they are used when data is mutated on the server. To learn about how to form Mutations in graphql, see [GraphQL's documentation](https://graphql.org/learn/queries/#mutations).
 *
 * ⬅️ This interface will autocomplete, so just try typing what you want. You can also search these docs. Some mutations will require authentication. Check the documentation or search `Authentication` for details.
 */
export type MutationCollectAdHocPaymentForInstructionArgs = {
  input: CollectAdHocPaymentForInstructionInput;
};

/**
 * Mutations are the GraphQL equivalent of POST requests in REST. By convention, they are used when data is mutated on the server. To learn about how to form Mutations in graphql, see [GraphQL's documentation](https://graphql.org/learn/queries/#mutations).
 *
 * ⬅️ This interface will autocomplete, so just try typing what you want. You can also search these docs. Some mutations will require authentication. Check the documentation or search `Authentication` for details.
 */
export type MutationCollectDepositArgs = {
  input: CollectDepositInput;
};

/**
 * Mutations are the GraphQL equivalent of POST requests in REST. By convention, they are used when data is mutated on the server. To learn about how to form Mutations in graphql, see [GraphQL's documentation](https://graphql.org/learn/queries/#mutations).
 *
 * ⬅️ This interface will autocomplete, so just try typing what you want. You can also search these docs. Some mutations will require authentication. Check the documentation or search `Authentication` for details.
 */
export type MutationCollectPaymentArgs = {
  input: CollectPaymentInput;
};

/**
 * Mutations are the GraphQL equivalent of POST requests in REST. By convention, they are used when data is mutated on the server. To learn about how to form Mutations in graphql, see [GraphQL's documentation](https://graphql.org/learn/queries/#mutations).
 *
 * ⬅️ This interface will autocomplete, so just try typing what you want. You can also search these docs. Some mutations will require authentication. Check the documentation or search `Authentication` for details.
 */
export type MutationCompleteAuthFlowForSmartFlexOnboardingArgs = {
  input: CompleteAuthFlowInput;
};

/**
 * Mutations are the GraphQL equivalent of POST requests in REST. By convention, they are used when data is mutated on the server. To learn about how to form Mutations in graphql, see [GraphQL's documentation](https://graphql.org/learn/queries/#mutations).
 *
 * ⬅️ This interface will autocomplete, so just try typing what you want. You can also search these docs. Some mutations will require authentication. Check the documentation or search `Authentication` for details.
 */
export type MutationCompleteTeslaSetupVirtualKeyForSmartFlexOnboardingArgs = {
  input: CompleteSmartFlexOnboardingStepInput;
};

/**
 * Mutations are the GraphQL equivalent of POST requests in REST. By convention, they are used when data is mutated on the server. To learn about how to form Mutations in graphql, see [GraphQL's documentation](https://graphql.org/learn/queries/#mutations).
 *
 * ⬅️ This interface will autocomplete, so just try typing what you want. You can also search these docs. Some mutations will require authentication. Check the documentation or search `Authentication` for details.
 */
export type MutationCreateAccountArgs = {
  input: CreateAccountInput;
};

/**
 * Mutations are the GraphQL equivalent of POST requests in REST. By convention, they are used when data is mutated on the server. To learn about how to form Mutations in graphql, see [GraphQL's documentation](https://graphql.org/learn/queries/#mutations).
 *
 * ⬅️ This interface will autocomplete, so just try typing what you want. You can also search these docs. Some mutations will require authentication. Check the documentation or search `Authentication` for details.
 */
export type MutationCreateAccountChargeArgs = {
  input: CreateAccountChargeInput;
};

/**
 * Mutations are the GraphQL equivalent of POST requests in REST. By convention, they are used when data is mutated on the server. To learn about how to form Mutations in graphql, see [GraphQL's documentation](https://graphql.org/learn/queries/#mutations).
 *
 * ⬅️ This interface will autocomplete, so just try typing what you want. You can also search these docs. Some mutations will require authentication. Check the documentation or search `Authentication` for details.
 */
export type MutationCreateAccountCreditArgs = {
  input: CreateAccountCreditInput;
};

/**
 * Mutations are the GraphQL equivalent of POST requests in REST. By convention, they are used when data is mutated on the server. To learn about how to form Mutations in graphql, see [GraphQL's documentation](https://graphql.org/learn/queries/#mutations).
 *
 * ⬅️ This interface will autocomplete, so just try typing what you want. You can also search these docs. Some mutations will require authentication. Check the documentation or search `Authentication` for details.
 */
export type MutationCreateAccountFileAttachmentArgs = {
  input: CreateAccountFileAttachmentInput;
};

/**
 * Mutations are the GraphQL equivalent of POST requests in REST. By convention, they are used when data is mutated on the server. To learn about how to form Mutations in graphql, see [GraphQL's documentation](https://graphql.org/learn/queries/#mutations).
 *
 * ⬅️ This interface will autocomplete, so just try typing what you want. You can also search these docs. Some mutations will require authentication. Check the documentation or search `Authentication` for details.
 */
export type MutationCreateAccountNoteArgs = {
  input: CreateAccountNoteInput;
};

/**
 * Mutations are the GraphQL equivalent of POST requests in REST. By convention, they are used when data is mutated on the server. To learn about how to form Mutations in graphql, see [GraphQL's documentation](https://graphql.org/learn/queries/#mutations).
 *
 * ⬅️ This interface will autocomplete, so just try typing what you want. You can also search these docs. Some mutations will require authentication. Check the documentation or search `Authentication` for details.
 */
export type MutationCreateAccountPaymentScheduleArgs = {
  input: CreateAccountPaymentScheduleInput;
};

/**
 * Mutations are the GraphQL equivalent of POST requests in REST. By convention, they are used when data is mutated on the server. To learn about how to form Mutations in graphql, see [GraphQL's documentation](https://graphql.org/learn/queries/#mutations).
 *
 * ⬅️ This interface will autocomplete, so just try typing what you want. You can also search these docs. Some mutations will require authentication. Check the documentation or search `Authentication` for details.
 */
export type MutationCreateAccountReferenceArgs = {
  input: AccountReferenceInput;
};

/**
 * Mutations are the GraphQL equivalent of POST requests in REST. By convention, they are used when data is mutated on the server. To learn about how to form Mutations in graphql, see [GraphQL's documentation](https://graphql.org/learn/queries/#mutations).
 *
 * ⬅️ This interface will autocomplete, so just try typing what you want. You can also search these docs. Some mutations will require authentication. Check the documentation or search `Authentication` for details.
 */
export type MutationCreateAccountReminderArgs = {
  input: CreateAccountReminderInput;
};

/**
 * Mutations are the GraphQL equivalent of POST requests in REST. By convention, they are used when data is mutated on the server. To learn about how to form Mutations in graphql, see [GraphQL's documentation](https://graphql.org/learn/queries/#mutations).
 *
 * ⬅️ This interface will autocomplete, so just try typing what you want. You can also search these docs. Some mutations will require authentication. Check the documentation or search `Authentication` for details.
 */
export type MutationCreateAffiliateLinkArgs = {
  input: CreateAffiliateLinkInputType;
};

/**
 * Mutations are the GraphQL equivalent of POST requests in REST. By convention, they are used when data is mutated on the server. To learn about how to form Mutations in graphql, see [GraphQL's documentation](https://graphql.org/learn/queries/#mutations).
 *
 * ⬅️ This interface will autocomplete, so just try typing what you want. You can also search these docs. Some mutations will require authentication. Check the documentation or search `Authentication` for details.
 */
export type MutationCreateAffiliateOrganisationArgs = {
  input: CreateAffiliateOrganisationInputType;
};

/**
 * Mutations are the GraphQL equivalent of POST requests in REST. By convention, they are used when data is mutated on the server. To learn about how to form Mutations in graphql, see [GraphQL's documentation](https://graphql.org/learn/queries/#mutations).
 *
 * ⬅️ This interface will autocomplete, so just try typing what you want. You can also search these docs. Some mutations will require authentication. Check the documentation or search `Authentication` for details.
 */
export type MutationCreateAffiliateSessionArgs = {
  input: CreateAffiliateSessionInputType;
};

/**
 * Mutations are the GraphQL equivalent of POST requests in REST. By convention, they are used when data is mutated on the server. To learn about how to form Mutations in graphql, see [GraphQL's documentation](https://graphql.org/learn/queries/#mutations).
 *
 * ⬅️ This interface will autocomplete, so just try typing what you want. You can also search these docs. Some mutations will require authentication. Check the documentation or search `Authentication` for details.
 */
export type MutationCreateApiCallArgs = {
  input: CreateApiCallInput;
};

/**
 * Mutations are the GraphQL equivalent of POST requests in REST. By convention, they are used when data is mutated on the server. To learn about how to form Mutations in graphql, see [GraphQL's documentation](https://graphql.org/learn/queries/#mutations).
 *
 * ⬅️ This interface will autocomplete, so just try typing what you want. You can also search these docs. Some mutations will require authentication. Check the documentation or search `Authentication` for details.
 */
export type MutationCreateApiExceptionArgs = {
  input: CreateApiExceptionInput;
};

/**
 * Mutations are the GraphQL equivalent of POST requests in REST. By convention, they are used when data is mutated on the server. To learn about how to form Mutations in graphql, see [GraphQL's documentation](https://graphql.org/learn/queries/#mutations).
 *
 * ⬅️ This interface will autocomplete, so just try typing what you want. You can also search these docs. Some mutations will require authentication. Check the documentation or search `Authentication` for details.
 */
export type MutationCreateApiExceptionEventArgs = {
  input: CreateApiExceptionEventInput;
};

/**
 * Mutations are the GraphQL equivalent of POST requests in REST. By convention, they are used when data is mutated on the server. To learn about how to form Mutations in graphql, see [GraphQL's documentation](https://graphql.org/learn/queries/#mutations).
 *
 * ⬅️ This interface will autocomplete, so just try typing what you want. You can also search these docs. Some mutations will require authentication. Check the documentation or search `Authentication` for details.
 */
export type MutationCreateApiExceptionNoteArgs = {
  input: CreateApiExceptionNoteInput;
};

/**
 * Mutations are the GraphQL equivalent of POST requests in REST. By convention, they are used when data is mutated on the server. To learn about how to form Mutations in graphql, see [GraphQL's documentation](https://graphql.org/learn/queries/#mutations).
 *
 * ⬅️ This interface will autocomplete, so just try typing what you want. You can also search these docs. Some mutations will require authentication. Check the documentation or search `Authentication` for details.
 */
export type MutationCreateContributionAgreementArgs = {
  input: CreateContributionAgreementInput;
};

/**
 * Mutations are the GraphQL equivalent of POST requests in REST. By convention, they are used when data is mutated on the server. To learn about how to form Mutations in graphql, see [GraphQL's documentation](https://graphql.org/learn/queries/#mutations).
 *
 * ⬅️ This interface will autocomplete, so just try typing what you want. You can also search these docs. Some mutations will require authentication. Check the documentation or search `Authentication` for details.
 */
export type MutationCreateDepositAgreementArgs = {
  input: CreateDepositAgreementInput;
};

/**
 * Mutations are the GraphQL equivalent of POST requests in REST. By convention, they are used when data is mutated on the server. To learn about how to form Mutations in graphql, see [GraphQL's documentation](https://graphql.org/learn/queries/#mutations).
 *
 * ⬅️ This interface will autocomplete, so just try typing what you want. You can also search these docs. Some mutations will require authentication. Check the documentation or search `Authentication` for details.
 */
export type MutationCreateExternalAccountEventArgs = {
  input: CreateExternalAccountEventInput;
};

/**
 * Mutations are the GraphQL equivalent of POST requests in REST. By convention, they are used when data is mutated on the server. To learn about how to form Mutations in graphql, see [GraphQL's documentation](https://graphql.org/learn/queries/#mutations).
 *
 * ⬅️ This interface will autocomplete, so just try typing what you want. You can also search these docs. Some mutations will require authentication. Check the documentation or search `Authentication` for details.
 */
export type MutationCreateExternalAccountUserEventArgs = {
  input: CreateExternalAccountUserEventInput;
};

/**
 * Mutations are the GraphQL equivalent of POST requests in REST. By convention, they are used when data is mutated on the server. To learn about how to form Mutations in graphql, see [GraphQL's documentation](https://graphql.org/learn/queries/#mutations).
 *
 * ⬅️ This interface will autocomplete, so just try typing what you want. You can also search these docs. Some mutations will require authentication. Check the documentation or search `Authentication` for details.
 */
export type MutationCreateFormSubmissionArgs = {
  input: FormSubmissionInput;
};

/**
 * Mutations are the GraphQL equivalent of POST requests in REST. By convention, they are used when data is mutated on the server. To learn about how to form Mutations in graphql, see [GraphQL's documentation](https://graphql.org/learn/queries/#mutations).
 *
 * ⬅️ This interface will autocomplete, so just try typing what you want. You can also search these docs. Some mutations will require authentication. Check the documentation or search `Authentication` for details.
 */
export type MutationCreateGoodsPurchaseArgs = {
  input: CreatePurchaseInput;
};

/**
 * Mutations are the GraphQL equivalent of POST requests in REST. By convention, they are used when data is mutated on the server. To learn about how to form Mutations in graphql, see [GraphQL's documentation](https://graphql.org/learn/queries/#mutations).
 *
 * ⬅️ This interface will autocomplete, so just try typing what you want. You can also search these docs. Some mutations will require authentication. Check the documentation or search `Authentication` for details.
 */
export type MutationCreateGoodsQuoteArgs = {
  input: CreateGoodsQuoteInput;
};

/**
 * Mutations are the GraphQL equivalent of POST requests in REST. By convention, they are used when data is mutated on the server. To learn about how to form Mutations in graphql, see [GraphQL's documentation](https://graphql.org/learn/queries/#mutations).
 *
 * ⬅️ This interface will autocomplete, so just try typing what you want. You can also search these docs. Some mutations will require authentication. Check the documentation or search `Authentication` for details.
 */
export type MutationCreateGoodsQuoteWithoutAccountArgs = {
  input: CreateGoodsQuoteWithoutAccountInput;
};

/**
 * Mutations are the GraphQL equivalent of POST requests in REST. By convention, they are used when data is mutated on the server. To learn about how to form Mutations in graphql, see [GraphQL's documentation](https://graphql.org/learn/queries/#mutations).
 *
 * ⬅️ This interface will autocomplete, so just try typing what you want. You can also search these docs. Some mutations will require authentication. Check the documentation or search `Authentication` for details.
 */
export type MutationCreateInkInboundMessageArgs = {
  input: CreateInkInboundMessageInput;
};

/**
 * Mutations are the GraphQL equivalent of POST requests in REST. By convention, they are used when data is mutated on the server. To learn about how to form Mutations in graphql, see [GraphQL's documentation](https://graphql.org/learn/queries/#mutations).
 *
 * ⬅️ This interface will autocomplete, so just try typing what you want. You can also search these docs. Some mutations will require authentication. Check the documentation or search `Authentication` for details.
 */
export type MutationCreateMetadataArgs = {
  input: MetadataInput;
};

/**
 * Mutations are the GraphQL equivalent of POST requests in REST. By convention, they are used when data is mutated on the server. To learn about how to form Mutations in graphql, see [GraphQL's documentation](https://graphql.org/learn/queries/#mutations).
 *
 * ⬅️ This interface will autocomplete, so just try typing what you want. You can also search these docs. Some mutations will require authentication. Check the documentation or search `Authentication` for details.
 */
export type MutationCreateNewLeadArgs = {
  input: NewLeadInputType;
};

/**
 * Mutations are the GraphQL equivalent of POST requests in REST. By convention, they are used when data is mutated on the server. To learn about how to form Mutations in graphql, see [GraphQL's documentation](https://graphql.org/learn/queries/#mutations).
 *
 * ⬅️ This interface will autocomplete, so just try typing what you want. You can also search these docs. Some mutations will require authentication. Check the documentation or search `Authentication` for details.
 */
export type MutationCreateOrUpdateLoyaltyCardArgs = {
  input: CreateOrUpdateLoyaltyCardInput;
};

/**
 * Mutations are the GraphQL equivalent of POST requests in REST. By convention, they are used when data is mutated on the server. To learn about how to form Mutations in graphql, see [GraphQL's documentation](https://graphql.org/learn/queries/#mutations).
 *
 * ⬅️ This interface will autocomplete, so just try typing what you want. You can also search these docs. Some mutations will require authentication. Check the documentation or search `Authentication` for details.
 */
export type MutationCreatePortfolioArgs = {
  input?: InputMaybe<CreatePortfolioInput>;
};

/**
 * Mutations are the GraphQL equivalent of POST requests in REST. By convention, they are used when data is mutated on the server. To learn about how to form Mutations in graphql, see [GraphQL's documentation](https://graphql.org/learn/queries/#mutations).
 *
 * ⬅️ This interface will autocomplete, so just try typing what you want. You can also search these docs. Some mutations will require authentication. Check the documentation or search `Authentication` for details.
 */
export type MutationCreatePortfolioUserRoleArgs = {
  input?: InputMaybe<CreatePortfolioUserRoleInput>;
};

/**
 * Mutations are the GraphQL equivalent of POST requests in REST. By convention, they are used when data is mutated on the server. To learn about how to form Mutations in graphql, see [GraphQL's documentation](https://graphql.org/learn/queries/#mutations).
 *
 * ⬅️ This interface will autocomplete, so just try typing what you want. You can also search these docs. Some mutations will require authentication. Check the documentation or search `Authentication` for details.
 */
export type MutationCreateQuoteArgs = {
  input: CreateQuoteInput;
};

/**
 * Mutations are the GraphQL equivalent of POST requests in REST. By convention, they are used when data is mutated on the server. To learn about how to form Mutations in graphql, see [GraphQL's documentation](https://graphql.org/learn/queries/#mutations).
 *
 * ⬅️ This interface will autocomplete, so just try typing what you want. You can also search these docs. Some mutations will require authentication. Check the documentation or search `Authentication` for details.
 */
export type MutationCreateReferralArgs = {
  input: CreateReferralInput;
};

/**
 * Mutations are the GraphQL equivalent of POST requests in REST. By convention, they are used when data is mutated on the server. To learn about how to form Mutations in graphql, see [GraphQL's documentation](https://graphql.org/learn/queries/#mutations).
 *
 * ⬅️ This interface will autocomplete, so just try typing what you want. You can also search these docs. Some mutations will require authentication. Check the documentation or search `Authentication` for details.
 */
export type MutationCreateShellAccountArgs = {
  input: CreateShellAccountInput;
};

/**
 * Mutations are the GraphQL equivalent of POST requests in REST. By convention, they are used when data is mutated on the server. To learn about how to form Mutations in graphql, see [GraphQL's documentation](https://graphql.org/learn/queries/#mutations).
 *
 * ⬅️ This interface will autocomplete, so just try typing what you want. You can also search these docs. Some mutations will require authentication. Check the documentation or search `Authentication` for details.
 */
export type MutationCreateSmartDeviceTokenArgs = {
  input: CreateSmartDeviceTokenInput;
};

/**
 * Mutations are the GraphQL equivalent of POST requests in REST. By convention, they are used when data is mutated on the server. To learn about how to form Mutations in graphql, see [GraphQL's documentation](https://graphql.org/learn/queries/#mutations).
 *
 * ⬅️ This interface will autocomplete, so just try typing what you want. You can also search these docs. Some mutations will require authentication. Check the documentation or search `Authentication` for details.
 */
export type MutationCreateUsaFileAttachmentArgs = {
  input?: InputMaybe<CreateUsaFileAttachmentInputType>;
};

/**
 * Mutations are the GraphQL equivalent of POST requests in REST. By convention, they are used when data is mutated on the server. To learn about how to form Mutations in graphql, see [GraphQL's documentation](https://graphql.org/learn/queries/#mutations).
 *
 * ⬅️ This interface will autocomplete, so just try typing what you want. You can also search these docs. Some mutations will require authentication. Check the documentation or search `Authentication` for details.
 */
export type MutationCustomerAcceptQuoteArgs = {
  input: CustomerAcceptQuoteInput;
};

/**
 * Mutations are the GraphQL equivalent of POST requests in REST. By convention, they are used when data is mutated on the server. To learn about how to form Mutations in graphql, see [GraphQL's documentation](https://graphql.org/learn/queries/#mutations).
 *
 * ⬅️ This interface will autocomplete, so just try typing what you want. You can also search these docs. Some mutations will require authentication. Check the documentation or search `Authentication` for details.
 */
export type MutationCustomerCreateQuoteArgs = {
  input: CustomerCreateQuoteInput;
};

/**
 * Mutations are the GraphQL equivalent of POST requests in REST. By convention, they are used when data is mutated on the server. To learn about how to form Mutations in graphql, see [GraphQL's documentation](https://graphql.org/learn/queries/#mutations).
 *
 * ⬅️ This interface will autocomplete, so just try typing what you want. You can also search these docs. Some mutations will require authentication. Check the documentation or search `Authentication` for details.
 */
export type MutationCustomerRevokeFutureAgreementsArgs = {
  input: CustomerRevokeFutureAgreementsInput;
};

/**
 * Mutations are the GraphQL equivalent of POST requests in REST. By convention, they are used when data is mutated on the server. To learn about how to form Mutations in graphql, see [GraphQL's documentation](https://graphql.org/learn/queries/#mutations).
 *
 * ⬅️ This interface will autocomplete, so just try typing what you want. You can also search these docs. Some mutations will require authentication. Check the documentation or search `Authentication` for details.
 */
export type MutationDeauthenticateDeviceArgs = {
  input?: InputMaybe<DeAuthenticationInput>;
};

/**
 * Mutations are the GraphQL equivalent of POST requests in REST. By convention, they are used when data is mutated on the server. To learn about how to form Mutations in graphql, see [GraphQL's documentation](https://graphql.org/learn/queries/#mutations).
 *
 * ⬅️ This interface will autocomplete, so just try typing what you want. You can also search these docs. Some mutations will require authentication. Check the documentation or search `Authentication` for details.
 */
export type MutationDeleteAccountReferenceArgs = {
  input: DeleteAccountReferenceInput;
};

/**
 * Mutations are the GraphQL equivalent of POST requests in REST. By convention, they are used when data is mutated on the server. To learn about how to form Mutations in graphql, see [GraphQL's documentation](https://graphql.org/learn/queries/#mutations).
 *
 * ⬅️ This interface will autocomplete, so just try typing what you want. You can also search these docs. Some mutations will require authentication. Check the documentation or search `Authentication` for details.
 */
export type MutationDeleteBoostChargeArgs = {
  input?: InputMaybe<DeleteBoostChargeInput>;
};

/**
 * Mutations are the GraphQL equivalent of POST requests in REST. By convention, they are used when data is mutated on the server. To learn about how to form Mutations in graphql, see [GraphQL's documentation](https://graphql.org/learn/queries/#mutations).
 *
 * ⬅️ This interface will autocomplete, so just try typing what you want. You can also search these docs. Some mutations will require authentication. Check the documentation or search `Authentication` for details.
 */
export type MutationDeletePushNotificationBindingArgs = {
  input: DeletePushNotificationBindingInput;
};

/**
 * Mutations are the GraphQL equivalent of POST requests in REST. By convention, they are used when data is mutated on the server. To learn about how to form Mutations in graphql, see [GraphQL's documentation](https://graphql.org/learn/queries/#mutations).
 *
 * ⬅️ This interface will autocomplete, so just try typing what you want. You can also search these docs. Some mutations will require authentication. Check the documentation or search `Authentication` for details.
 */
export type MutationDeleteThermostatTokenArgs = {
  input: DeleteThermostatTokenInput;
};

/**
 * Mutations are the GraphQL equivalent of POST requests in REST. By convention, they are used when data is mutated on the server. To learn about how to form Mutations in graphql, see [GraphQL's documentation](https://graphql.org/learn/queries/#mutations).
 *
 * ⬅️ This interface will autocomplete, so just try typing what you want. You can also search these docs. Some mutations will require authentication. Check the documentation or search `Authentication` for details.
 */
export type MutationDeviceRegistrationArgs = {
  input?: InputMaybe<DeviceRegistrationInput>;
};

/**
 * Mutations are the GraphQL equivalent of POST requests in REST. By convention, they are used when data is mutated on the server. To learn about how to form Mutations in graphql, see [GraphQL's documentation](https://graphql.org/learn/queries/#mutations).
 *
 * ⬅️ This interface will autocomplete, so just try typing what you want. You can also search these docs. Some mutations will require authentication. Check the documentation or search `Authentication` for details.
 */
export type MutationEmailQuoteArgs = {
  input: EmailQuoteInput;
};

/**
 * Mutations are the GraphQL equivalent of POST requests in REST. By convention, they are used when data is mutated on the server. To learn about how to form Mutations in graphql, see [GraphQL's documentation](https://graphql.org/learn/queries/#mutations).
 *
 * ⬅️ This interface will autocomplete, so just try typing what you want. You can also search these docs. Some mutations will require authentication. Check the documentation or search `Authentication` for details.
 */
export type MutationEndContributionAgreementArgs = {
  input: EndContributionAgreementInput;
};

/**
 * Mutations are the GraphQL equivalent of POST requests in REST. By convention, they are used when data is mutated on the server. To learn about how to form Mutations in graphql, see [GraphQL's documentation](https://graphql.org/learn/queries/#mutations).
 *
 * ⬅️ This interface will autocomplete, so just try typing what you want. You can also search these docs. Some mutations will require authentication. Check the documentation or search `Authentication` for details.
 */
export type MutationEnrollmentArgs = {
  input: EnrollmentInput;
};

/**
 * Mutations are the GraphQL equivalent of POST requests in REST. By convention, they are used when data is mutated on the server. To learn about how to form Mutations in graphql, see [GraphQL's documentation](https://graphql.org/learn/queries/#mutations).
 *
 * ⬅️ This interface will autocomplete, so just try typing what you want. You can also search these docs. Some mutations will require authentication. Check the documentation or search `Authentication` for details.
 */
export type MutationForceReauthenticationArgs = {
  input: ForceReauthenticationInput;
};

/**
 * Mutations are the GraphQL equivalent of POST requests in REST. By convention, they are used when data is mutated on the server. To learn about how to form Mutations in graphql, see [GraphQL's documentation](https://graphql.org/learn/queries/#mutations).
 *
 * ⬅️ This interface will autocomplete, so just try typing what you want. You can also search these docs. Some mutations will require authentication. Check the documentation or search `Authentication` for details.
 */
export type MutationGenerateInkPresignedUrlArgs = {
  input?: InputMaybe<GenerateInkPresignedUrlInput>;
};

/**
 * Mutations are the GraphQL equivalent of POST requests in REST. By convention, they are used when data is mutated on the server. To learn about how to form Mutations in graphql, see [GraphQL's documentation](https://graphql.org/learn/queries/#mutations).
 *
 * ⬅️ This interface will autocomplete, so just try typing what you want. You can also search these docs. Some mutations will require authentication. Check the documentation or search `Authentication` for details.
 */
export type MutationGeneratePreSignedTokenArgs = {
  email: Scalars["String"];
  numberOfDaysAllowed: Scalars["Int"];
  scope: PreSignedTokenScope;
};

/**
 * Mutations are the GraphQL equivalent of POST requests in REST. By convention, they are used when data is mutated on the server. To learn about how to form Mutations in graphql, see [GraphQL's documentation](https://graphql.org/learn/queries/#mutations).
 *
 * ⬅️ This interface will autocomplete, so just try typing what you want. You can also search these docs. Some mutations will require authentication. Check the documentation or search `Authentication` for details.
 */
export type MutationGetEmbeddedSecretForNewPaymentInstructionArgs = {
  input: GetEmbeddedSecretForNewPaymentInstructionInput;
};

/**
 * Mutations are the GraphQL equivalent of POST requests in REST. By convention, they are used when data is mutated on the server. To learn about how to form Mutations in graphql, see [GraphQL's documentation](https://graphql.org/learn/queries/#mutations).
 *
 * ⬅️ This interface will autocomplete, so just try typing what you want. You can also search these docs. Some mutations will require authentication. Check the documentation or search `Authentication` for details.
 */
export type MutationGetHostedUrlForNewPaymentInstructionArgs = {
  input: GetHostedUrlForNewPaymentInstructionInput;
};

/**
 * Mutations are the GraphQL equivalent of POST requests in REST. By convention, they are used when data is mutated on the server. To learn about how to form Mutations in graphql, see [GraphQL's documentation](https://graphql.org/learn/queries/#mutations).
 *
 * ⬅️ This interface will autocomplete, so just try typing what you want. You can also search these docs. Some mutations will require authentication. Check the documentation or search `Authentication` for details.
 */
export type MutationInitiateHostedStandalonePaymentArgs = {
  input: InitiateHostedStandalonePaymentInput;
};

/**
 * Mutations are the GraphQL equivalent of POST requests in REST. By convention, they are used when data is mutated on the server. To learn about how to form Mutations in graphql, see [GraphQL's documentation](https://graphql.org/learn/queries/#mutations).
 *
 * ⬅️ This interface will autocomplete, so just try typing what you want. You can also search these docs. Some mutations will require authentication. Check the documentation or search `Authentication` for details.
 */
export type MutationInitiateMoveOutArgs = {
  input: InitiateMoveOutInputType;
};

/**
 * Mutations are the GraphQL equivalent of POST requests in REST. By convention, they are used when data is mutated on the server. To learn about how to form Mutations in graphql, see [GraphQL's documentation](https://graphql.org/learn/queries/#mutations).
 *
 * ⬅️ This interface will autocomplete, so just try typing what you want. You can also search these docs. Some mutations will require authentication. Check the documentation or search `Authentication` for details.
 */
export type MutationInitiateProductSwitchArgs = {
  input: InitiateProductSwitchInput;
};

/**
 * Mutations are the GraphQL equivalent of POST requests in REST. By convention, they are used when data is mutated on the server. To learn about how to form Mutations in graphql, see [GraphQL's documentation](https://graphql.org/learn/queries/#mutations).
 *
 * ⬅️ This interface will autocomplete, so just try typing what you want. You can also search these docs. Some mutations will require authentication. Check the documentation or search `Authentication` for details.
 */
export type MutationInitiateStandalonePaymentArgs = {
  input: InitiateStandalonePaymentInput;
};

/**
 * Mutations are the GraphQL equivalent of POST requests in REST. By convention, they are used when data is mutated on the server. To learn about how to form Mutations in graphql, see [GraphQL's documentation](https://graphql.org/learn/queries/#mutations).
 *
 * ⬅️ This interface will autocomplete, so just try typing what you want. You can also search these docs. Some mutations will require authentication. Check the documentation or search `Authentication` for details.
 */
export type MutationInitiateTransferOfServiceArgs = {
  input: InitiateTransferOfServiceInputType;
};

/**
 * Mutations are the GraphQL equivalent of POST requests in REST. By convention, they are used when data is mutated on the server. To learn about how to form Mutations in graphql, see [GraphQL's documentation](https://graphql.org/learn/queries/#mutations).
 *
 * ⬅️ This interface will autocomplete, so just try typing what you want. You can also search these docs. Some mutations will require authentication. Check the documentation or search `Authentication` for details.
 */
export type MutationInstigateLeaveSupplierArgs = {
  input: LeaveSupplierInput;
};

/**
 * Mutations are the GraphQL equivalent of POST requests in REST. By convention, they are used when data is mutated on the server. To learn about how to form Mutations in graphql, see [GraphQL's documentation](https://graphql.org/learn/queries/#mutations).
 *
 * ⬅️ This interface will autocomplete, so just try typing what you want. You can also search these docs. Some mutations will require authentication. Check the documentation or search `Authentication` for details.
 */
export type MutationInvalidatePaymentInstructionArgs = {
  input: InvalidatePaymentInstructionInput;
};

/**
 * Mutations are the GraphQL equivalent of POST requests in REST. By convention, they are used when data is mutated on the server. To learn about how to form Mutations in graphql, see [GraphQL's documentation](https://graphql.org/learn/queries/#mutations).
 *
 * ⬅️ This interface will autocomplete, so just try typing what you want. You can also search these docs. Some mutations will require authentication. Check the documentation or search `Authentication` for details.
 */
export type MutationInvalidatePreSignedTokenArgs = {
  input: InvalidatePreSignedTokenInput;
};

/**
 * Mutations are the GraphQL equivalent of POST requests in REST. By convention, they are used when data is mutated on the server. To learn about how to form Mutations in graphql, see [GraphQL's documentation](https://graphql.org/learn/queries/#mutations).
 *
 * ⬅️ This interface will autocomplete, so just try typing what you want. You can also search these docs. Some mutations will require authentication. Check the documentation or search `Authentication` for details.
 */
export type MutationInvalidatePreSignedTokensForUserArgs = {
  input: InvalidatePreSignedTokensForUserInput;
};

/**
 * Mutations are the GraphQL equivalent of POST requests in REST. By convention, they are used when data is mutated on the server. To learn about how to form Mutations in graphql, see [GraphQL's documentation](https://graphql.org/learn/queries/#mutations).
 *
 * ⬅️ This interface will autocomplete, so just try typing what you want. You can also search these docs. Some mutations will require authentication. Check the documentation or search `Authentication` for details.
 */
export type MutationInvalidateRefreshTokenArgs = {
  input: InvalidateRefreshTokenInput;
};

/**
 * Mutations are the GraphQL equivalent of POST requests in REST. By convention, they are used when data is mutated on the server. To learn about how to form Mutations in graphql, see [GraphQL's documentation](https://graphql.org/learn/queries/#mutations).
 *
 * ⬅️ This interface will autocomplete, so just try typing what you want. You can also search these docs. Some mutations will require authentication. Check the documentation or search `Authentication` for details.
 */
export type MutationInvalidateRefreshTokensForUserArgs = {
  input: InvalidateRefreshTokensForUserInput;
};

/**
 * Mutations are the GraphQL equivalent of POST requests in REST. By convention, they are used when data is mutated on the server. To learn about how to form Mutations in graphql, see [GraphQL's documentation](https://graphql.org/learn/queries/#mutations).
 *
 * ⬅️ This interface will autocomplete, so just try typing what you want. You can also search these docs. Some mutations will require authentication. Check the documentation or search `Authentication` for details.
 */
export type MutationJoinSupplierAcceptTermsAndConditionsArgs = {
  input: JoinSupplierAcceptTermsAndConditionsInput;
};

/**
 * Mutations are the GraphQL equivalent of POST requests in REST. By convention, they are used when data is mutated on the server. To learn about how to form Mutations in graphql, see [GraphQL's documentation](https://graphql.org/learn/queries/#mutations).
 *
 * ⬅️ This interface will autocomplete, so just try typing what you want. You can also search these docs. Some mutations will require authentication. Check the documentation or search `Authentication` for details.
 */
export type MutationLinkUserToLineArgs = {
  input: LinkUserToLineInput;
};

/**
 * Mutations are the GraphQL equivalent of POST requests in REST. By convention, they are used when data is mutated on the server. To learn about how to form Mutations in graphql, see [GraphQL's documentation](https://graphql.org/learn/queries/#mutations).
 *
 * ⬅️ This interface will autocomplete, so just try typing what you want. You can also search these docs. Some mutations will require authentication. Check the documentation or search `Authentication` for details.
 */
export type MutationMarkPrintBatchAsProcessedArgs = {
  printBatchId: Scalars["ID"];
};

/**
 * Mutations are the GraphQL equivalent of POST requests in REST. By convention, they are used when data is mutated on the server. To learn about how to form Mutations in graphql, see [GraphQL's documentation](https://graphql.org/learn/queries/#mutations).
 *
 * ⬅️ This interface will autocomplete, so just try typing what you want. You can also search these docs. Some mutations will require authentication. Check the documentation or search `Authentication` for details.
 */
export type MutationMasqueradeAuthenticationArgs = {
  masqueradeToken: Scalars["String"];
  userId: Scalars["String"];
};

/**
 * Mutations are the GraphQL equivalent of POST requests in REST. By convention, they are used when data is mutated on the server. To learn about how to form Mutations in graphql, see [GraphQL's documentation](https://graphql.org/learn/queries/#mutations).
 *
 * ⬅️ This interface will autocomplete, so just try typing what you want. You can also search these docs. Some mutations will require authentication. Check the documentation or search `Authentication` for details.
 */
export type MutationObtainKrakenTokenArgs = {
  input: ObtainJsonWebTokenInput;
};

/**
 * Mutations are the GraphQL equivalent of POST requests in REST. By convention, they are used when data is mutated on the server. To learn about how to form Mutations in graphql, see [GraphQL's documentation](https://graphql.org/learn/queries/#mutations).
 *
 * ⬅️ This interface will autocomplete, so just try typing what you want. You can also search these docs. Some mutations will require authentication. Check the documentation or search `Authentication` for details.
 */
export type MutationObtainLongLivedRefreshTokenArgs = {
  input: ObtainLongLivedRefreshTokenInput;
};

/**
 * Mutations are the GraphQL equivalent of POST requests in REST. By convention, they are used when data is mutated on the server. To learn about how to form Mutations in graphql, see [GraphQL's documentation](https://graphql.org/learn/queries/#mutations).
 *
 * ⬅️ This interface will autocomplete, so just try typing what you want. You can also search these docs. Some mutations will require authentication. Check the documentation or search `Authentication` for details.
 */
export type MutationOcppAuthenticationArgs = {
  input?: InputMaybe<OcppAuthenticationInput>;
};

/**
 * Mutations are the GraphQL equivalent of POST requests in REST. By convention, they are used when data is mutated on the server. To learn about how to form Mutations in graphql, see [GraphQL's documentation](https://graphql.org/learn/queries/#mutations).
 *
 * ⬅️ This interface will autocomplete, so just try typing what you want. You can also search these docs. Some mutations will require authentication. Check the documentation or search `Authentication` for details.
 */
export type MutationPauseDunningArgs = {
  input: PauseDunningInputType;
};

/**
 * Mutations are the GraphQL equivalent of POST requests in REST. By convention, they are used when data is mutated on the server. To learn about how to form Mutations in graphql, see [GraphQL's documentation](https://graphql.org/learn/queries/#mutations).
 *
 * ⬅️ This interface will autocomplete, so just try typing what you want. You can also search these docs. Some mutations will require authentication. Check the documentation or search `Authentication` for details.
 */
export type MutationPostCreditArgs = {
  input: PostCreditInput;
};

/**
 * Mutations are the GraphQL equivalent of POST requests in REST. By convention, they are used when data is mutated on the server. To learn about how to form Mutations in graphql, see [GraphQL's documentation](https://graphql.org/learn/queries/#mutations).
 *
 * ⬅️ This interface will autocomplete, so just try typing what you want. You can also search these docs. Some mutations will require authentication. Check the documentation or search `Authentication` for details.
 */
export type MutationPrepareAccountArgs = {
  input: PrepareAccountInput;
};

/**
 * Mutations are the GraphQL equivalent of POST requests in REST. By convention, they are used when data is mutated on the server. To learn about how to form Mutations in graphql, see [GraphQL's documentation](https://graphql.org/learn/queries/#mutations).
 *
 * ⬅️ This interface will autocomplete, so just try typing what you want. You can also search these docs. Some mutations will require authentication. Check the documentation or search `Authentication` for details.
 */
export type MutationPublishTransactionalMessagingTriggerArgs = {
  input: PublishTransactionalMessagingTriggerInput;
};

/**
 * Mutations are the GraphQL equivalent of POST requests in REST. By convention, they are used when data is mutated on the server. To learn about how to form Mutations in graphql, see [GraphQL's documentation](https://graphql.org/learn/queries/#mutations).
 *
 * ⬅️ This interface will autocomplete, so just try typing what you want. You can also search these docs. Some mutations will require authentication. Check the documentation or search `Authentication` for details.
 */
export type MutationReauthenticateDeviceArgs = {
  input?: InputMaybe<ReauthenticateDeviceInput>;
};

/**
 * Mutations are the GraphQL equivalent of POST requests in REST. By convention, they are used when data is mutated on the server. To learn about how to form Mutations in graphql, see [GraphQL's documentation](https://graphql.org/learn/queries/#mutations).
 *
 * ⬅️ This interface will autocomplete, so just try typing what you want. You can also search these docs. Some mutations will require authentication. Check the documentation or search `Authentication` for details.
 */
export type MutationRecordDepositAgreementAcceptedArgs = {
  input: DepositAgreementInput;
};

/**
 * Mutations are the GraphQL equivalent of POST requests in REST. By convention, they are used when data is mutated on the server. To learn about how to form Mutations in graphql, see [GraphQL's documentation](https://graphql.org/learn/queries/#mutations).
 *
 * ⬅️ This interface will autocomplete, so just try typing what you want. You can also search these docs. Some mutations will require authentication. Check the documentation or search `Authentication` for details.
 */
export type MutationRedeemLoyaltyPointsForAccountCreditArgs = {
  input: RedeemLoyaltyPointsInput;
};

/**
 * Mutations are the GraphQL equivalent of POST requests in REST. By convention, they are used when data is mutated on the server. To learn about how to form Mutations in graphql, see [GraphQL's documentation](https://graphql.org/learn/queries/#mutations).
 *
 * ⬅️ This interface will autocomplete, so just try typing what you want. You can also search these docs. Some mutations will require authentication. Check the documentation or search `Authentication` for details.
 */
export type MutationRedeemReferralClaimCodeArgs = {
  input: RedeemReferralClaimCodeInput;
};

/**
 * Mutations are the GraphQL equivalent of POST requests in REST. By convention, they are used when data is mutated on the server. To learn about how to form Mutations in graphql, see [GraphQL's documentation](https://graphql.org/learn/queries/#mutations).
 *
 * ⬅️ This interface will autocomplete, so just try typing what you want. You can also search these docs. Some mutations will require authentication. Check the documentation or search `Authentication` for details.
 */
export type MutationRefundPaymentArgs = {
  input: RefundPaymentInput;
};

/**
 * Mutations are the GraphQL equivalent of POST requests in REST. By convention, they are used when data is mutated on the server. To learn about how to form Mutations in graphql, see [GraphQL's documentation](https://graphql.org/learn/queries/#mutations).
 *
 * ⬅️ This interface will autocomplete, so just try typing what you want. You can also search these docs. Some mutations will require authentication. Check the documentation or search `Authentication` for details.
 */
export type MutationRegisterPushNotificationBindingArgs = {
  input: RegisterPushNotificationBindingInput;
};

/**
 * Mutations are the GraphQL equivalent of POST requests in REST. By convention, they are used when data is mutated on the server. To learn about how to form Mutations in graphql, see [GraphQL's documentation](https://graphql.org/learn/queries/#mutations).
 *
 * ⬅️ This interface will autocomplete, so just try typing what you want. You can also search these docs. Some mutations will require authentication. Check the documentation or search `Authentication` for details.
 */
export type MutationRequestEnrollmentAccountPasswordResetArgs = {
  input: EnrollmentAccountResetPasswordInputType;
};

/**
 * Mutations are the GraphQL equivalent of POST requests in REST. By convention, they are used when data is mutated on the server. To learn about how to form Mutations in graphql, see [GraphQL's documentation](https://graphql.org/learn/queries/#mutations).
 *
 * ⬅️ This interface will autocomplete, so just try typing what you want. You can also search these docs. Some mutations will require authentication. Check the documentation or search `Authentication` for details.
 */
export type MutationRequestPasswordResetArgs = {
  input: RequestPasswordResetInput;
};

/**
 * Mutations are the GraphQL equivalent of POST requests in REST. By convention, they are used when data is mutated on the server. To learn about how to form Mutations in graphql, see [GraphQL's documentation](https://graphql.org/learn/queries/#mutations).
 *
 * ⬅️ This interface will autocomplete, so just try typing what you want. You can also search these docs. Some mutations will require authentication. Check the documentation or search `Authentication` for details.
 */
export type MutationResetPasswordArgs = {
  input: ResetPasswordMutationInput;
};

/**
 * Mutations are the GraphQL equivalent of POST requests in REST. By convention, they are used when data is mutated on the server. To learn about how to form Mutations in graphql, see [GraphQL's documentation](https://graphql.org/learn/queries/#mutations).
 *
 * ⬅️ This interface will autocomplete, so just try typing what you want. You can also search these docs. Some mutations will require authentication. Check the documentation or search `Authentication` for details.
 */
export type MutationResetUserPasswordArgs = {
  input: ResetUserPasswordInput;
};

/**
 * Mutations are the GraphQL equivalent of POST requests in REST. By convention, they are used when data is mutated on the server. To learn about how to form Mutations in graphql, see [GraphQL's documentation](https://graphql.org/learn/queries/#mutations).
 *
 * ⬅️ This interface will autocomplete, so just try typing what you want. You can also search these docs. Some mutations will require authentication. Check the documentation or search `Authentication` for details.
 */
export type MutationResumeControlArgs = {
  input?: InputMaybe<AccountNumberInput>;
};

/**
 * Mutations are the GraphQL equivalent of POST requests in REST. By convention, they are used when data is mutated on the server. To learn about how to form Mutations in graphql, see [GraphQL's documentation](https://graphql.org/learn/queries/#mutations).
 *
 * ⬅️ This interface will autocomplete, so just try typing what you want. You can also search these docs. Some mutations will require authentication. Check the documentation or search `Authentication` for details.
 */
export type MutationRevokeAgreementArgs = {
  input: RevokeAgreementInput;
};

/**
 * Mutations are the GraphQL equivalent of POST requests in REST. By convention, they are used when data is mutated on the server. To learn about how to form Mutations in graphql, see [GraphQL's documentation](https://graphql.org/learn/queries/#mutations).
 *
 * ⬅️ This interface will autocomplete, so just try typing what you want. You can also search these docs. Some mutations will require authentication. Check the documentation or search `Authentication` for details.
 */
export type MutationRunCreditCheckArgs = {
  input: RunCreditCheckInput;
};

/**
 * Mutations are the GraphQL equivalent of POST requests in REST. By convention, they are used when data is mutated on the server. To learn about how to form Mutations in graphql, see [GraphQL's documentation](https://graphql.org/learn/queries/#mutations).
 *
 * ⬅️ This interface will autocomplete, so just try typing what you want. You can also search these docs. Some mutations will require authentication. Check the documentation or search `Authentication` for details.
 */
export type MutationScheduleQuoteFollowUpArgs = {
  input: QuoteShareInput;
};

/**
 * Mutations are the GraphQL equivalent of POST requests in REST. By convention, they are used when data is mutated on the server. To learn about how to form Mutations in graphql, see [GraphQL's documentation](https://graphql.org/learn/queries/#mutations).
 *
 * ⬅️ This interface will autocomplete, so just try typing what you want. You can also search these docs. Some mutations will require authentication. Check the documentation or search `Authentication` for details.
 */
export type MutationSelectChargePointMakeForSmartFlexOnboardingArgs = {
  input: SelectChargePointMakeInput;
};

/**
 * Mutations are the GraphQL equivalent of POST requests in REST. By convention, they are used when data is mutated on the server. To learn about how to form Mutations in graphql, see [GraphQL's documentation](https://graphql.org/learn/queries/#mutations).
 *
 * ⬅️ This interface will autocomplete, so just try typing what you want. You can also search these docs. Some mutations will require authentication. Check the documentation or search `Authentication` for details.
 */
export type MutationSelectChargePointVariantForSmartFlexOnboardingArgs = {
  input: SelectChargePointVariantInput;
};

/**
 * Mutations are the GraphQL equivalent of POST requests in REST. By convention, they are used when data is mutated on the server. To learn about how to form Mutations in graphql, see [GraphQL's documentation](https://graphql.org/learn/queries/#mutations).
 *
 * ⬅️ This interface will autocomplete, so just try typing what you want. You can also search these docs. Some mutations will require authentication. Check the documentation or search `Authentication` for details.
 */
export type MutationSelectDeviceTypeForSmartFlexOnboardingArgs = {
  input: SelectDeviceTypeInput;
};

/**
 * Mutations are the GraphQL equivalent of POST requests in REST. By convention, they are used when data is mutated on the server. To learn about how to form Mutations in graphql, see [GraphQL's documentation](https://graphql.org/learn/queries/#mutations).
 *
 * ⬅️ This interface will autocomplete, so just try typing what you want. You can also search these docs. Some mutations will require authentication. Check the documentation or search `Authentication` for details.
 */
export type MutationSelectProductsArgs = {
  input: SelectProductsInput;
};

/**
 * Mutations are the GraphQL equivalent of POST requests in REST. By convention, they are used when data is mutated on the server. To learn about how to form Mutations in graphql, see [GraphQL's documentation](https://graphql.org/learn/queries/#mutations).
 *
 * ⬅️ This interface will autocomplete, so just try typing what you want. You can also search these docs. Some mutations will require authentication. Check the documentation or search `Authentication` for details.
 */
export type MutationSelectUserVehicleForSmartFlexOnboardingArgs = {
  input: SelectUserVehicleInput;
};

/**
 * Mutations are the GraphQL equivalent of POST requests in REST. By convention, they are used when data is mutated on the server. To learn about how to form Mutations in graphql, see [GraphQL's documentation](https://graphql.org/learn/queries/#mutations).
 *
 * ⬅️ This interface will autocomplete, so just try typing what you want. You can also search these docs. Some mutations will require authentication. Check the documentation or search `Authentication` for details.
 */
export type MutationSelectVehicleMakeForSmartFlexOnboardingArgs = {
  input: SelectVehicleMakeInput;
};

/**
 * Mutations are the GraphQL equivalent of POST requests in REST. By convention, they are used when data is mutated on the server. To learn about how to form Mutations in graphql, see [GraphQL's documentation](https://graphql.org/learn/queries/#mutations).
 *
 * ⬅️ This interface will autocomplete, so just try typing what you want. You can also search these docs. Some mutations will require authentication. Check the documentation or search `Authentication` for details.
 */
export type MutationSelectVehicleVariantForSmartFlexOnboardingArgs = {
  input: SelectVehicleVariantInput;
};

/**
 * Mutations are the GraphQL equivalent of POST requests in REST. By convention, they are used when data is mutated on the server. To learn about how to form Mutations in graphql, see [GraphQL's documentation](https://graphql.org/learn/queries/#mutations).
 *
 * ⬅️ This interface will autocomplete, so just try typing what you want. You can also search these docs. Some mutations will require authentication. Check the documentation or search `Authentication` for details.
 */
export type MutationSendQuoteSummaryArgs = {
  input: QuoteShareInput;
};

/**
 * Mutations are the GraphQL equivalent of POST requests in REST. By convention, they are used when data is mutated on the server. To learn about how to form Mutations in graphql, see [GraphQL's documentation](https://graphql.org/learn/queries/#mutations).
 *
 * ⬅️ This interface will autocomplete, so just try typing what you want. You can also search these docs. Some mutations will require authentication. Check the documentation or search `Authentication` for details.
 */
export type MutationSetBatteryChargingPreferencesArgs = {
  input?: InputMaybe<BatteryChargingPreferencesInput>;
};

/**
 * Mutations are the GraphQL equivalent of POST requests in REST. By convention, they are used when data is mutated on the server. To learn about how to form Mutations in graphql, see [GraphQL's documentation](https://graphql.org/learn/queries/#mutations).
 *
 * ⬅️ This interface will autocomplete, so just try typing what you want. You can also search these docs. Some mutations will require authentication. Check the documentation or search `Authentication` for details.
 */
export type MutationSetDevicePreferencesArgs = {
  input: SmartFlexDevicePreferencesInput;
};

/**
 * Mutations are the GraphQL equivalent of POST requests in REST. By convention, they are used when data is mutated on the server. To learn about how to form Mutations in graphql, see [GraphQL's documentation](https://graphql.org/learn/queries/#mutations).
 *
 * ⬅️ This interface will autocomplete, so just try typing what you want. You can also search these docs. Some mutations will require authentication. Check the documentation or search `Authentication` for details.
 */
export type MutationSetEmailAndPhoneArgs = {
  input: SetEmailAndPhoneInput;
};

/**
 * Mutations are the GraphQL equivalent of POST requests in REST. By convention, they are used when data is mutated on the server. To learn about how to form Mutations in graphql, see [GraphQL's documentation](https://graphql.org/learn/queries/#mutations).
 *
 * ⬅️ This interface will autocomplete, so just try typing what you want. You can also search these docs. Some mutations will require authentication. Check the documentation or search `Authentication` for details.
 */
export type MutationSetLoyaltyPointsUserArgs = {
  input: SetLoyaltyPointsUserInput;
};

/**
 * Mutations are the GraphQL equivalent of POST requests in REST. By convention, they are used when data is mutated on the server. To learn about how to form Mutations in graphql, see [GraphQL's documentation](https://graphql.org/learn/queries/#mutations).
 *
 * ⬅️ This interface will autocomplete, so just try typing what you want. You can also search these docs. Some mutations will require authentication. Check the documentation or search `Authentication` for details.
 */
export type MutationSetUpDirectDebitInstructionArgs = {
  input: SetUpDirectDebitInstructionInput;
};

/**
 * Mutations are the GraphQL equivalent of POST requests in REST. By convention, they are used when data is mutated on the server. To learn about how to form Mutations in graphql, see [GraphQL's documentation](https://graphql.org/learn/queries/#mutations).
 *
 * ⬅️ This interface will autocomplete, so just try typing what you want. You can also search these docs. Some mutations will require authentication. Check the documentation or search `Authentication` for details.
 */
export type MutationSetVehicleChargePreferencesArgs = {
  input?: InputMaybe<VehicleChargingPreferencesInput>;
};

/**
 * Mutations are the GraphQL equivalent of POST requests in REST. By convention, they are used when data is mutated on the server. To learn about how to form Mutations in graphql, see [GraphQL's documentation](https://graphql.org/learn/queries/#mutations).
 *
 * ⬅️ This interface will autocomplete, so just try typing what you want. You can also search these docs. Some mutations will require authentication. Check the documentation or search `Authentication` for details.
 */
export type MutationShareGoodsQuoteArgs = {
  input: ShareGoodsQuoteInput;
};

/**
 * Mutations are the GraphQL equivalent of POST requests in REST. By convention, they are used when data is mutated on the server. To learn about how to form Mutations in graphql, see [GraphQL's documentation](https://graphql.org/learn/queries/#mutations).
 *
 * ⬅️ This interface will autocomplete, so just try typing what you want. You can also search these docs. Some mutations will require authentication. Check the documentation or search `Authentication` for details.
 */
export type MutationStartSmartFlexOnboardingArgs = {
  input: StartSmartFlexOnboardingInput;
};

/**
 * Mutations are the GraphQL equivalent of POST requests in REST. By convention, they are used when data is mutated on the server. To learn about how to form Mutations in graphql, see [GraphQL's documentation](https://graphql.org/learn/queries/#mutations).
 *
 * ⬅️ This interface will autocomplete, so just try typing what you want. You can also search these docs. Some mutations will require authentication. Check the documentation or search `Authentication` for details.
 */
export type MutationStartTestChargeForSmartFlexOnboardingArgs = {
  input: CompleteSmartFlexOnboardingStepInput;
};

/**
 * Mutations are the GraphQL equivalent of POST requests in REST. By convention, they are used when data is mutated on the server. To learn about how to form Mutations in graphql, see [GraphQL's documentation](https://graphql.org/learn/queries/#mutations).
 *
 * ⬅️ This interface will autocomplete, so just try typing what you want. You can also search these docs. Some mutations will require authentication. Check the documentation or search `Authentication` for details.
 */
export type MutationStoreAchDirectDebitInstructionArgs = {
  input: StoreAchDirectDebitInstructionInput;
};

/**
 * Mutations are the GraphQL equivalent of POST requests in REST. By convention, they are used when data is mutated on the server. To learn about how to form Mutations in graphql, see [GraphQL's documentation](https://graphql.org/learn/queries/#mutations).
 *
 * ⬅️ This interface will autocomplete, so just try typing what you want. You can also search these docs. Some mutations will require authentication. Check the documentation or search `Authentication` for details.
 */
export type MutationStoreCreditScoreArgs = {
  input: StoreCreditScoreInputType;
};

/**
 * Mutations are the GraphQL equivalent of POST requests in REST. By convention, they are used when data is mutated on the server. To learn about how to form Mutations in graphql, see [GraphQL's documentation](https://graphql.org/learn/queries/#mutations).
 *
 * ⬅️ This interface will autocomplete, so just try typing what you want. You can also search these docs. Some mutations will require authentication. Check the documentation or search `Authentication` for details.
 */
export type MutationStorePaymentInstructionArgs = {
  input: StorePaymentInstructionInput;
};

/**
 * Mutations are the GraphQL equivalent of POST requests in REST. By convention, they are used when data is mutated on the server. To learn about how to form Mutations in graphql, see [GraphQL's documentation](https://graphql.org/learn/queries/#mutations).
 *
 * ⬅️ This interface will autocomplete, so just try typing what you want. You can also search these docs. Some mutations will require authentication. Check the documentation or search `Authentication` for details.
 */
export type MutationSubmitCustomerFeedbackArgs = {
  input: CustomerFeedbackInputType;
};

/**
 * Mutations are the GraphQL equivalent of POST requests in REST. By convention, they are used when data is mutated on the server. To learn about how to form Mutations in graphql, see [GraphQL's documentation](https://graphql.org/learn/queries/#mutations).
 *
 * ⬅️ This interface will autocomplete, so just try typing what you want. You can also search these docs. Some mutations will require authentication. Check the documentation or search `Authentication` for details.
 */
export type MutationSubmitRepaymentRequestArgs = {
  input: RequestRepaymentInputType;
};

/**
 * Mutations are the GraphQL equivalent of POST requests in REST. By convention, they are used when data is mutated on the server. To learn about how to form Mutations in graphql, see [GraphQL's documentation](https://graphql.org/learn/queries/#mutations).
 *
 * ⬅️ This interface will autocomplete, so just try typing what you want. You can also search these docs. Some mutations will require authentication. Check the documentation or search `Authentication` for details.
 */
export type MutationSuspendControlArgs = {
  input?: InputMaybe<AccountNumberInput>;
};

/**
 * Mutations are the GraphQL equivalent of POST requests in REST. By convention, they are used when data is mutated on the server. To learn about how to form Mutations in graphql, see [GraphQL's documentation](https://graphql.org/learn/queries/#mutations).
 *
 * ⬅️ This interface will autocomplete, so just try typing what you want. You can also search these docs. Some mutations will require authentication. Check the documentation or search `Authentication` for details.
 */
export type MutationThirdPartyCompleteDeviceRegistrationArgs = {
  input?: InputMaybe<CompleteDeviceRegistrationInput>;
};

/**
 * Mutations are the GraphQL equivalent of POST requests in REST. By convention, they are used when data is mutated on the server. To learn about how to form Mutations in graphql, see [GraphQL's documentation](https://graphql.org/learn/queries/#mutations).
 *
 * ⬅️ This interface will autocomplete, so just try typing what you want. You can also search these docs. Some mutations will require authentication. Check the documentation or search `Authentication` for details.
 */
export type MutationToggleThermostatControlArgs = {
  input: ToggleThermostatControlInput;
};

/**
 * Mutations are the GraphQL equivalent of POST requests in REST. By convention, they are used when data is mutated on the server. To learn about how to form Mutations in graphql, see [GraphQL's documentation](https://graphql.org/learn/queries/#mutations).
 *
 * ⬅️ This interface will autocomplete, so just try typing what you want. You can also search these docs. Some mutations will require authentication. Check the documentation or search `Authentication` for details.
 */
export type MutationTransferLedgerBalanceArgs = {
  input: TransferLedgerBalanceInputType;
};

/**
 * Mutations are the GraphQL equivalent of POST requests in REST. By convention, they are used when data is mutated on the server. To learn about how to form Mutations in graphql, see [GraphQL's documentation](https://graphql.org/learn/queries/#mutations).
 *
 * ⬅️ This interface will autocomplete, so just try typing what you want. You can also search these docs. Some mutations will require authentication. Check the documentation or search `Authentication` for details.
 */
export type MutationTransferLoyaltyPointsBetweenUsersArgs = {
  input: TransferLoyaltyPointsBetweenUsersInput;
};

/**
 * Mutations are the GraphQL equivalent of POST requests in REST. By convention, they are used when data is mutated on the server. To learn about how to form Mutations in graphql, see [GraphQL's documentation](https://graphql.org/learn/queries/#mutations).
 *
 * ⬅️ This interface will autocomplete, so just try typing what you want. You can also search these docs. Some mutations will require authentication. Check the documentation or search `Authentication` for details.
 */
export type MutationTriggerBoostChargeArgs = {
  input?: InputMaybe<AccountNumberInput>;
};

/**
 * Mutations are the GraphQL equivalent of POST requests in REST. By convention, they are used when data is mutated on the server. To learn about how to form Mutations in graphql, see [GraphQL's documentation](https://graphql.org/learn/queries/#mutations).
 *
 * ⬅️ This interface will autocomplete, so just try typing what you want. You can also search these docs. Some mutations will require authentication. Check the documentation or search `Authentication` for details.
 */
export type MutationTriggerPostUploadOperationsArgs = {
  s3Key: Scalars["String"];
};

/**
 * Mutations are the GraphQL equivalent of POST requests in REST. By convention, they are used when data is mutated on the server. To learn about how to form Mutations in graphql, see [GraphQL's documentation](https://graphql.org/learn/queries/#mutations).
 *
 * ⬅️ This interface will autocomplete, so just try typing what you want. You can also search these docs. Some mutations will require authentication. Check the documentation or search `Authentication` for details.
 */
export type MutationTriggerTestChargeArgs = {
  input?: InputMaybe<AccountNumberInput>;
};

/**
 * Mutations are the GraphQL equivalent of POST requests in REST. By convention, they are used when data is mutated on the server. To learn about how to form Mutations in graphql, see [GraphQL's documentation](https://graphql.org/learn/queries/#mutations).
 *
 * ⬅️ This interface will autocomplete, so just try typing what you want. You can also search these docs. Some mutations will require authentication. Check the documentation or search `Authentication` for details.
 */
export type MutationUpdateAccountBillingAddressArgs = {
  input: AccountBillingAddressInput;
};

/**
 * Mutations are the GraphQL equivalent of POST requests in REST. By convention, they are used when data is mutated on the server. To learn about how to form Mutations in graphql, see [GraphQL's documentation](https://graphql.org/learn/queries/#mutations).
 *
 * ⬅️ This interface will autocomplete, so just try typing what you want. You can also search these docs. Some mutations will require authentication. Check the documentation or search `Authentication` for details.
 */
export type MutationUpdateAccountBillingEmailArgs = {
  input: UpdateAccountBillingEmailInput;
};

/**
 * Mutations are the GraphQL equivalent of POST requests in REST. By convention, they are used when data is mutated on the server. To learn about how to form Mutations in graphql, see [GraphQL's documentation](https://graphql.org/learn/queries/#mutations).
 *
 * ⬅️ This interface will autocomplete, so just try typing what you want. You can also search these docs. Some mutations will require authentication. Check the documentation or search `Authentication` for details.
 */
export type MutationUpdateAccountReferenceArgs = {
  input: AccountReferenceInput;
};

/**
 * Mutations are the GraphQL equivalent of POST requests in REST. By convention, they are used when data is mutated on the server. To learn about how to form Mutations in graphql, see [GraphQL's documentation](https://graphql.org/learn/queries/#mutations).
 *
 * ⬅️ This interface will autocomplete, so just try typing what you want. You can also search these docs. Some mutations will require authentication. Check the documentation or search `Authentication` for details.
 */
export type MutationUpdateAccountUserConsentsArgs = {
  consents?: InputMaybe<Array<InputMaybe<ConsentTypeInput>>>;
};

/**
 * Mutations are the GraphQL equivalent of POST requests in REST. By convention, they are used when data is mutated on the server. To learn about how to form Mutations in graphql, see [GraphQL's documentation](https://graphql.org/learn/queries/#mutations).
 *
 * ⬅️ This interface will autocomplete, so just try typing what you want. You can also search these docs. Some mutations will require authentication. Check the documentation or search `Authentication` for details.
 */
export type MutationUpdateActivePurchaseArgs = {
  input: UpdatePurchaseInput;
};

/**
 * Mutations are the GraphQL equivalent of POST requests in REST. By convention, they are used when data is mutated on the server. To learn about how to form Mutations in graphql, see [GraphQL's documentation](https://graphql.org/learn/queries/#mutations).
 *
 * ⬅️ This interface will autocomplete, so just try typing what you want. You can also search these docs. Some mutations will require authentication. Check the documentation or search `Authentication` for details.
 */
export type MutationUpdateAffiliateLinkArgs = {
  input: UpdateAffiliateLinkInputType;
};

/**
 * Mutations are the GraphQL equivalent of POST requests in REST. By convention, they are used when data is mutated on the server. To learn about how to form Mutations in graphql, see [GraphQL's documentation](https://graphql.org/learn/queries/#mutations).
 *
 * ⬅️ This interface will autocomplete, so just try typing what you want. You can also search these docs. Some mutations will require authentication. Check the documentation or search `Authentication` for details.
 */
export type MutationUpdateAffiliateOrganisationArgs = {
  input: UpdateAffiliateOrganisationInputType;
};

/**
 * Mutations are the GraphQL equivalent of POST requests in REST. By convention, they are used when data is mutated on the server. To learn about how to form Mutations in graphql, see [GraphQL's documentation](https://graphql.org/learn/queries/#mutations).
 *
 * ⬅️ This interface will autocomplete, so just try typing what you want. You can also search these docs. Some mutations will require authentication. Check the documentation or search `Authentication` for details.
 */
export type MutationUpdateAgreementPeriodArgs = {
  input: UpdateAgreementPeriodInput;
};

/**
 * Mutations are the GraphQL equivalent of POST requests in REST. By convention, they are used when data is mutated on the server. To learn about how to form Mutations in graphql, see [GraphQL's documentation](https://graphql.org/learn/queries/#mutations).
 *
 * ⬅️ This interface will autocomplete, so just try typing what you want. You can also search these docs. Some mutations will require authentication. Check the documentation or search `Authentication` for details.
 */
export type MutationUpdateApiExceptionArgs = {
  input: UpdateApiExceptionInput;
};

/**
 * Mutations are the GraphQL equivalent of POST requests in REST. By convention, they are used when data is mutated on the server. To learn about how to form Mutations in graphql, see [GraphQL's documentation](https://graphql.org/learn/queries/#mutations).
 *
 * ⬅️ This interface will autocomplete, so just try typing what you want. You can also search these docs. Some mutations will require authentication. Check the documentation or search `Authentication` for details.
 */
export type MutationUpdateApiExceptionNoteArgs = {
  input: UpdateApiExceptionNoteInput;
};

/**
 * Mutations are the GraphQL equivalent of POST requests in REST. By convention, they are used when data is mutated on the server. To learn about how to form Mutations in graphql, see [GraphQL's documentation](https://graphql.org/learn/queries/#mutations).
 *
 * ⬅️ This interface will autocomplete, so just try typing what you want. You can also search these docs. Some mutations will require authentication. Check the documentation or search `Authentication` for details.
 */
export type MutationUpdateAutoTopUpAmountArgs = {
  input: UpdateAutoTopUpAmountInput;
};

/**
 * Mutations are the GraphQL equivalent of POST requests in REST. By convention, they are used when data is mutated on the server. To learn about how to form Mutations in graphql, see [GraphQL's documentation](https://graphql.org/learn/queries/#mutations).
 *
 * ⬅️ This interface will autocomplete, so just try typing what you want. You can also search these docs. Some mutations will require authentication. Check the documentation or search `Authentication` for details.
 */
export type MutationUpdateCollectionProcessRecordToActiveArgs = {
  input: UpdateCollectionProcessRecordToActiveInputType;
};

/**
 * Mutations are the GraphQL equivalent of POST requests in REST. By convention, they are used when data is mutated on the server. To learn about how to form Mutations in graphql, see [GraphQL's documentation](https://graphql.org/learn/queries/#mutations).
 *
 * ⬅️ This interface will autocomplete, so just try typing what you want. You can also search these docs. Some mutations will require authentication. Check the documentation or search `Authentication` for details.
 */
export type MutationUpdateCollectionProcessRecordToCompleteArgs = {
  input: UpdateCollectionProcessRecordToCompleteInputType;
};

/**
 * Mutations are the GraphQL equivalent of POST requests in REST. By convention, they are used when data is mutated on the server. To learn about how to form Mutations in graphql, see [GraphQL's documentation](https://graphql.org/learn/queries/#mutations).
 *
 * ⬅️ This interface will autocomplete, so just try typing what you want. You can also search these docs. Some mutations will require authentication. Check the documentation or search `Authentication` for details.
 */
export type MutationUpdateCommsDeliveryPreferenceArgs = {
  input: UpdateCommsDeliveryPreferenceInput;
};

/**
 * Mutations are the GraphQL equivalent of POST requests in REST. By convention, they are used when data is mutated on the server. To learn about how to form Mutations in graphql, see [GraphQL's documentation](https://graphql.org/learn/queries/#mutations).
 *
 * ⬅️ This interface will autocomplete, so just try typing what you want. You can also search these docs. Some mutations will require authentication. Check the documentation or search `Authentication` for details.
 */
export type MutationUpdateCommsPreferencesArgs = {
  input: UpdateAccountUserCommsPreferencesMutationInput;
};

/**
 * Mutations are the GraphQL equivalent of POST requests in REST. By convention, they are used when data is mutated on the server. To learn about how to form Mutations in graphql, see [GraphQL's documentation](https://graphql.org/learn/queries/#mutations).
 *
 * ⬅️ This interface will autocomplete, so just try typing what you want. You can also search these docs. Some mutations will require authentication. Check the documentation or search `Authentication` for details.
 */
export type MutationUpdateDeviceSmartControlArgs = {
  input: SmartControlInput;
};

/**
 * Mutations are the GraphQL equivalent of POST requests in REST. By convention, they are used when data is mutated on the server. To learn about how to form Mutations in graphql, see [GraphQL's documentation](https://graphql.org/learn/queries/#mutations).
 *
 * ⬅️ This interface will autocomplete, so just try typing what you want. You can also search these docs. Some mutations will require authentication. Check the documentation or search `Authentication` for details.
 */
export type MutationUpdateLanguagePreferenceArgs = {
  input: LanguagePreferenceInput;
};

/**
 * Mutations are the GraphQL equivalent of POST requests in REST. By convention, they are used when data is mutated on the server. To learn about how to form Mutations in graphql, see [GraphQL's documentation](https://graphql.org/learn/queries/#mutations).
 *
 * ⬅️ This interface will autocomplete, so just try typing what you want. You can also search these docs. Some mutations will require authentication. Check the documentation or search `Authentication` for details.
 */
export type MutationUpdateMessageTagsArgs = {
  input: UpdateMessageTagsInput;
};

/**
 * Mutations are the GraphQL equivalent of POST requests in REST. By convention, they are used when data is mutated on the server. To learn about how to form Mutations in graphql, see [GraphQL's documentation](https://graphql.org/learn/queries/#mutations).
 *
 * ⬅️ This interface will autocomplete, so just try typing what you want. You can also search these docs. Some mutations will require authentication. Check the documentation or search `Authentication` for details.
 */
export type MutationUpdateMetadataArgs = {
  input: MetadataInput;
};

/**
 * Mutations are the GraphQL equivalent of POST requests in REST. By convention, they are used when data is mutated on the server. To learn about how to form Mutations in graphql, see [GraphQL's documentation](https://graphql.org/learn/queries/#mutations).
 *
 * ⬅️ This interface will autocomplete, so just try typing what you want. You can also search these docs. Some mutations will require authentication. Check the documentation or search `Authentication` for details.
 */
export type MutationUpdatePasswordArgs = {
  input?: InputMaybe<UpdatePasswordInput>;
};

/**
 * Mutations are the GraphQL equivalent of POST requests in REST. By convention, they are used when data is mutated on the server. To learn about how to form Mutations in graphql, see [GraphQL's documentation](https://graphql.org/learn/queries/#mutations).
 *
 * ⬅️ This interface will autocomplete, so just try typing what you want. You can also search these docs. Some mutations will require authentication. Check the documentation or search `Authentication` for details.
 */
export type MutationUpdateQuoteUserArgs = {
  input: UpdateQuoteUserInput;
};

/**
 * Mutations are the GraphQL equivalent of POST requests in REST. By convention, they are used when data is mutated on the server. To learn about how to form Mutations in graphql, see [GraphQL's documentation](https://graphql.org/learn/queries/#mutations).
 *
 * ⬅️ This interface will autocomplete, so just try typing what you want. You can also search these docs. Some mutations will require authentication. Check the documentation or search `Authentication` for details.
 */
export type MutationUpdateShellAccountCommsPreferencesArgs = {
  input: UpdateShellAccountCommsPreferenceInputType;
};

/**
 * Mutations are the GraphQL equivalent of POST requests in REST. By convention, they are used when data is mutated on the server. To learn about how to form Mutations in graphql, see [GraphQL's documentation](https://graphql.org/learn/queries/#mutations).
 *
 * ⬅️ This interface will autocomplete, so just try typing what you want. You can also search these docs. Some mutations will require authentication. Check the documentation or search `Authentication` for details.
 */
export type MutationUpdateUserArgs = {
  input: UpdateUserInput;
};

/**
 * Mutations are the GraphQL equivalent of POST requests in REST. By convention, they are used when data is mutated on the server. To learn about how to form Mutations in graphql, see [GraphQL's documentation](https://graphql.org/learn/queries/#mutations).
 *
 * ⬅️ This interface will autocomplete, so just try typing what you want. You can also search these docs. Some mutations will require authentication. Check the documentation or search `Authentication` for details.
 */
export type MutationUpdateUserDetailsArgs = {
  input: UpdateAccountUserMutationInput;
};

/**
 * Mutations are the GraphQL equivalent of POST requests in REST. By convention, they are used when data is mutated on the server. To learn about how to form Mutations in graphql, see [GraphQL's documentation](https://graphql.org/learn/queries/#mutations).
 *
 * ⬅️ This interface will autocomplete, so just try typing what you want. You can also search these docs. Some mutations will require authentication. Check the documentation or search `Authentication` for details.
 */
export type MutationValidateEmailArgs = {
  input: ValidateEmailInput;
};

/**
 * Mutations are the GraphQL equivalent of POST requests in REST. By convention, they are used when data is mutated on the server. To learn about how to form Mutations in graphql, see [GraphQL's documentation](https://graphql.org/learn/queries/#mutations).
 *
 * ⬅️ This interface will autocomplete, so just try typing what you want. You can also search these docs. Some mutations will require authentication. Check the documentation or search `Authentication` for details.
 */
export type MutationValidatePhoneArgs = {
  input: ValidatePhoneNumberInput;
};

/**
 * Mutations are the GraphQL equivalent of POST requests in REST. By convention, they are used when data is mutated on the server. To learn about how to form Mutations in graphql, see [GraphQL's documentation](https://graphql.org/learn/queries/#mutations).
 *
 * ⬅️ This interface will autocomplete, so just try typing what you want. You can also search these docs. Some mutations will require authentication. Check the documentation or search `Authentication` for details.
 */
export type MutationVerifyAccountArgs = {
  input: VerifyAccountInput;
};

/**
 * Mutations are the GraphQL equivalent of POST requests in REST. By convention, they are used when data is mutated on the server. To learn about how to form Mutations in graphql, see [GraphQL's documentation](https://graphql.org/learn/queries/#mutations).
 *
 * ⬅️ This interface will autocomplete, so just try typing what you want. You can also search these docs. Some mutations will require authentication. Check the documentation or search `Authentication` for details.
 */
export type MutationVerifyEmailArgs = {
  input: VerifyEmailInput;
};

/**
 * Mutations are the GraphQL equivalent of POST requests in REST. By convention, they are used when data is mutated on the server. To learn about how to form Mutations in graphql, see [GraphQL's documentation](https://graphql.org/learn/queries/#mutations).
 *
 * ⬅️ This interface will autocomplete, so just try typing what you want. You can also search these docs. Some mutations will require authentication. Check the documentation or search `Authentication` for details.
 */
export type MutationVerifyIdentityArgs = {
  input: VerifyIdentityInput;
};

export type NewLeadInputType = {
  /** Lead email. */
  email?: InputMaybe<Scalars["String"]>;
  /** To know if the lead is domestic or business. */
  leadType?: InputMaybe<Scalars["String"]>;
  /** The product the lead is interested in. */
  marketName?: InputMaybe<Scalars["String"]>;
  /** Lead name. */
  name?: InputMaybe<Scalars["String"]>;
  /** Lead phone. */
  phone?: InputMaybe<Scalars["String"]>;
  /** The source where the lead comes from. */
  source?: InputMaybe<Scalars["String"]>;
};

export type NewLeadType = {
  __typename?: "NewLeadType";
  /** Lead email. */
  email?: Maybe<Scalars["String"]>;
  /** ID of the lead. */
  id?: Maybe<Scalars["Int"]>;
  /** To know if the lead is domestic or business. */
  leadType?: Maybe<Scalars["String"]>;
  /** Lead name. */
  name?: Maybe<Scalars["String"]>;
  /** Lead phone. */
  phone?: Maybe<Scalars["String"]>;
  /** The source where the lead comes from. */
  source?: Maybe<Scalars["String"]>;
};

/** An object with an ID */
export type Node = {
  /** The ID of the object */
  id: Scalars["ID"];
};

/** An enumeration. */
export enum NotifiableApplicationExternalProvider {
  /** AWS Pinpoint */
  Pinpoint = "PINPOINT",
}

/** An enumeration. */
export enum NotifiableApplicationService {
  /** iOS (APNs) */
  Apns = "APNS",
  /** iOS Sandbox (APNs Sandbox) */
  ApnsSandbox = "APNS_SANDBOX",
  /** Android (GCM) */
  Gcm = "GCM",
}

/** Represents an application that can receive push notifications. */
export type NotifiableApplicationType = {
  __typename?: "NotifiableApplicationType";
  /** Bundle ID or package name of the app. */
  bundleId: Scalars["String"];
  description: Scalars["String"];
  /** Project ID used in push notification delivery service. (Currently: AWS Pinpoint) */
  externalProjectId: Scalars["String"];
  externalProvider: NotifiableApplicationExternalProvider;
  id: Scalars["ID"];
  /** Human readable name for the app. */
  name: Scalars["String"];
  pushNotificationBindings: Array<PushNotificationBindingType>;
  service: NotifiableApplicationService;
};

/**
 * Open Charge Point Protocol (OCPP) authentication.
 *
 * Take the given OCPP authentication details and trigger OCPP authentication.
 *
 * The possible errors that can be raised are:
 *
 * - KT-CT-4301: Unable to find device for given account.
 * - KT-CT-4310: Unable to register OCPP authentication details.
 * - KT-CT-1113: Disabled GraphQL field requested.
 */
export type OcppAuthentication = {
  __typename?: "OCPPAuthentication";
  krakenflexDevice?: Maybe<KrakenFlexDeviceType>;
  possibleErrors?: Maybe<Array<Maybe<PossibleErrorType>>>;
};

export type OcppAuthenticationInput = {
  /** Account number. */
  accountNumber: Scalars["String"];
  /** The OCPP authentication details. */
  details: Scalars["String"];
};

export type OcppConnectionType = {
  __typename?: "OCPPConnectionType";
  isConnected?: Maybe<Scalars["Boolean"]>;
};

export type OcppDetailsType = {
  __typename?: "OCPPDetailsType";
  url?: Maybe<Scalars["String"]>;
  username?: Maybe<Scalars["String"]>;
};

/** The input type for obtaining a Kraken Token (JWT). */
export type ObtainJsonWebTokenInput = {
  /** API key of the account user. Use standalone, don't provide a second input field. */
  APIKey?: InputMaybe<Scalars["String"]>;
  /** Email address of the account user. Use with 'password' field. */
  email?: InputMaybe<Scalars["String"]>;
  /** Live secret key of an third-party organization. Use standalone, don't provide a second input field. */
  organizationSecretKey?: InputMaybe<Scalars["String"]>;
  /** Password of the account user. Use with 'email' field. */
  password?: InputMaybe<Scalars["String"]>;
  /** Short-lived, temporary key (that's pre-signed). Use standalone, don't provide a second input field. */
  preSignedKey?: InputMaybe<Scalars["String"]>;
  /** The refresh token that can be used to extend the expiry claim of a Kraken token. Use standalone, don't provide a second input field. */
  refreshToken?: InputMaybe<Scalars["String"]>;
};

/**
 * The unifying approach used to get a Kraken token (JWT: JSON Web Token) with
 * different types of input.
 *
 * The currently supported inputs are:
 * - account user email/password combination
 * - account user API key
 * - organization live secret key
 * - pre-signed key
 * - refresh token
 *
 * The possible errors that can be raised are:
 *
 * - KT-CT-1135: Invalid data.
 * - KT-CT-1134: Invalid data.
 * - KT-CT-1113: Disabled GraphQL field requested.
 */
export type ObtainKrakenJsonWebToken = {
  __typename?: "ObtainKrakenJSONWebToken";
  /** The body payload of the Kraken Token.  The same information can be obtained by using JWT decoding tools on the value of the `token` field. */
  payload: Scalars["GenericScalar"];
  possibleErrors?: Maybe<Array<Maybe<PossibleErrorType>>>;
  /** A Unix timestamp representing the point in time at which the refresh token will expire. */
  refreshExpiresIn?: Maybe<Scalars["Int"]>;
  /** A token that can be used in a subsequent call to `obtainKrakenToken` to get a new Kraken Token with the same access conditions after the previous one has expired. */
  refreshToken?: Maybe<Scalars["String"]>;
  /** The Kraken Token.  Can be used in the `Authorization` header for subsequent calls to the API to access protected resources. */
  token: Scalars["String"];
};

/**
 * Obtain a long-lived refresh token.
 *
 * This mutation is limited to authorized third-party organizations only.
 *
 * Account users can only generate short-lived refresh tokens.
 *
 * The short-lived refresh tokens (for account users) can be obtained from
 * the 'refreshToken' field in 'obtainKrakenToken' mutation.
 *
 * The possible errors that can be raised are:
 *
 * - KT-CT-1120: The Kraken Token has expired.
 * - KT-CT-1121: Please use Kraken Token to issue long-lived refresh tokens.
 * - KT-CT-1132: Unauthorized.
 * - KT-CT-1122: Long-lived refresh tokens can only be issued for account users.
 * - KT-CT-1113: Disabled GraphQL field requested.
 * - KT-CT-1111: Unauthorized.
 * - KT-CT-1112: 'Authorization' header not provided.
 */
export type ObtainLongLivedRefreshToken = {
  __typename?: "ObtainLongLivedRefreshToken";
  possibleErrors?: Maybe<Array<Maybe<PossibleErrorType>>>;
  refreshExpiresIn: Scalars["Int"];
  refreshToken?: Maybe<Scalars["String"]>;
};

/** The input type for obtaining a long-lived refresh token. */
export type ObtainLongLivedRefreshTokenInput = {
  /** The Kraken Token that will be used to generate the long-lived refresh token. */
  krakenToken: Scalars["String"];
};

/** An occupancy period for a property. */
export type OccupancyPeriodType = {
  __typename?: "OccupancyPeriodType";
  /** Date the occupancy period is effective from. */
  effectiveFrom?: Maybe<Scalars["DateTime"]>;
  /** Date the occupancy period is effective to. */
  effectiveTo?: Maybe<Scalars["DateTime"]>;
  id?: Maybe<Scalars["ID"]>;
  /** Whether the account associated with the occupancy period is an occupier account type. */
  isOccupier?: Maybe<Scalars["Boolean"]>;
};

export type OccupyPropertyProcessConnectionTypeConnection = {
  __typename?: "OccupyPropertyProcessConnectionTypeConnection";
  /** Number of nodes in the edge. */
  edgeCount: Scalars["Int"];
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<OccupyPropertyProcessConnectionTypeEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  /** Total number of nodes. */
  totalCount: Scalars["Int"];
};

/** A Relay edge containing a `OccupyPropertyProcessConnectionType` and its cursor. */
export type OccupyPropertyProcessConnectionTypeEdge = {
  __typename?: "OccupyPropertyProcessConnectionTypeEdge";
  /** A cursor for use in pagination */
  cursor: Scalars["String"];
  /** The item at the end of the edge */
  node?: Maybe<OccupyPropertyProcessType>;
};

/** Represents a Occupy Property process. */
export type OccupyPropertyProcessType = AbstractSupplyPointProcessInterface & {
  __typename?: "OccupyPropertyProcessType";
  /** The ID or the primary key of the lifecycle process. */
  id?: Maybe<Scalars["ID"]>;
  occupyAt: Scalars["DateTime"];
  /** The status of the process. */
  status?: Maybe<LifecycleSupplyPointProcessStatus>;
  /** The supply points associated with the process. */
  supplyPoints: SupplyPointConnectionTypeConnection;
};

/** Represents a Occupy Property process. */
export type OccupyPropertyProcessTypeSupplyPointsArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
};

export type OeCustomerOutput = {
  __typename?: "OeCustomerOutput";
  isOeCustomer?: Maybe<Scalars["Boolean"]>;
};

export type OperationsTeamType = {
  __typename?: "OperationsTeamType";
  /** ID for the Operations Team. */
  id?: Maybe<Scalars["ID"]>;
  /** Name for the Operations Team. */
  name?: Maybe<Scalars["String"]>;
};

export type OutageMapOutputType = {
  __typename?: "OutageMapOutputType";
  /** Link to service provider's outage information page. */
  link?: Maybe<Scalars["String"]>;
  /** Service provider's outage reporting contact number. */
  outageReportNumber?: Maybe<Scalars["String"]>;
  serviceProvider?: Maybe<ServiceProvider>;
  /** Service provider's service request number. */
  serviceRequestNumber?: Maybe<Scalars["String"]>;
};

/** The Relay compliant `PageInfo` type, containing data necessary to paginate this connection. */
export type PageInfo = {
  __typename?: "PageInfo";
  /** When paginating forwards, the cursor to continue. */
  endCursor?: Maybe<Scalars["String"]>;
  /** When paginating forwards, are there more items? */
  hasNextPage: Scalars["Boolean"];
  /** When paginating backwards, are there more items? */
  hasPreviousPage: Scalars["Boolean"];
  /** When paginating backwards, the cursor to continue. */
  startCursor?: Maybe<Scalars["String"]>;
};

/**
 * Pause the dunning process for an account.
 *
 * The possible errors that can be raised are:
 *
 * - KT-CT-4178: No account found with given account number.
 * - KT-CT-11301: Account not in a dunning process for the given path name.
 * - KT-CT-11302: No active dunning process found.
 * - KT-CT-11303: Multiple active dunning processes found.
 * - KT-CT-11304: Dunning pause process failed verifying the dates.
 * - KT-CT-11305: Pausing the dunning process failed.
 * - KT-CT-1113: Disabled GraphQL field requested.
 * - KT-CT-1111: Unauthorized.
 * - KT-CT-1112: 'Authorization' header not provided.
 */
export type PauseDunning = {
  __typename?: "PauseDunning";
  /** Whether the pause has been successfully created. */
  pauseCreated?: Maybe<Scalars["Boolean"]>;
  possibleErrors?: Maybe<Array<Maybe<PossibleErrorType>>>;
};

export type PauseDunningInputType = {
  /** Account number for which to pause the dunning process. */
  accountNumber: Scalars["String"];
  /** An optional note for the pause. */
  note?: InputMaybe<Scalars["String"]>;
  /** The dunning process path name to pause. */
  pathName: Scalars["String"];
  /** The date from which the pause to take effect. */
  startDate: Scalars["Date"];
  /** The date at which the pause should stop. */
  stopDate: Scalars["Date"];
};

/** A payment from the customer to the energy supplier. */
export type Payment = TransactionType & {
  __typename?: "Payment";
  /** Unique identifier of the account the transaction belongs to. */
  accountNumber?: Maybe<Scalars["String"]>;
  /**
   * Gross amount including tax (when payable). Refer to the `amounts` field for a breakdown of this information.
   * @deprecated The 'amount' field is deprecated.
   *
   * Use `amounts` instead for a breakdown of the relevant net, tax, and gross amounts.
   *
   * - Marked as deprecated on 2023-12-06.
   * - Will be removed on 2024-06-01.
   */
  amount?: Maybe<Scalars["Int"]>;
  /** The net, tax and gross amounts for the transaction. Note: for payments and repayments, only the net amount is returned. */
  amounts?: Maybe<TransactionAmountType>;
  /** The customer's resulting balance after this transaction has been applied, in the smallest unit of currency. */
  balanceCarriedForward?: Maybe<Scalars["Int"]>;
  /** The unique identifier for the most recent billing document linked with the transaction.Note: a transaction may be linked with multiple documents, but this field will only return the identifier for the most recent billing document. */
  billingDocumentIdentifier?: Maybe<Scalars["ID"]>;
  /** The date time when the transaction is created. */
  createdAt?: Maybe<Scalars["DateTime"]>;
  /** Returns True if the transaction is linked with a statement. */
  hasStatement?: Maybe<Scalars["Boolean"]>;
  id?: Maybe<Scalars["ID"]>;
  /**
   * Deprecated.
   * @deprecated The 'isAccountCharge' field is deprecated.
   *
   * This information is provided by the __typename introspection query.
   *
   * - Marked as deprecated on 2020-06-19.
   * - Will be removed on 2022-11-15.
   */
  isAccountCharge?: Maybe<Scalars["Boolean"]>;
  /**
   * Deprecated.
   * @deprecated The 'isAccountPayment' field is deprecated.
   *
   * This information is provided by the __typename introspection query.
   *
   * - Marked as deprecated on 2020-06-19.
   * - Will be removed on 2022-11-15.
   */
  isAccountPayment?: Maybe<Scalars["Boolean"]>;
  /**
   * Deprecated.
   * @deprecated The 'isCredit' field is deprecated.
   *
   * This information is provided by the __typename introspection query.
   *
   * - Marked as deprecated on 2020-06-19.
   * - Will be removed on 2022-11-15.
   */
  isCredit?: Maybe<Scalars["Boolean"]>;
  /** Whether the statement this transaction is on has been held. A held statement is not sent to a customer automatically, but is instead marked for manual attention by operations staff.  Returns False if a statement is not linked with the transaction. */
  isHeld?: Maybe<Scalars["Boolean"]>;
  /** Whether this transaction has been issued on any billing document.Note: Look for the most recently issued transaction instead of looking through all transactions as some accounts may have initial transactions that were not issued.This will return False if the transaction is not associated with any billing documents. */
  isIssued?: Maybe<Scalars["Boolean"]>;
  isReversed: Scalars["Boolean"];
  /** Returns the note field value for the transaction, which contains additional info. */
  note?: Maybe<Scalars["String"]>;
  postedDate?: Maybe<Scalars["Date"]>;
  /** Returns the reason. */
  reasonCode?: Maybe<Scalars["String"]>;
  /**
   * Returns None if a statement is not linked with the transaction.
   * @deprecated The 'statementId' field is deprecated.
   *
   * Use `billingDocumentIdentifier` instead.
   *
   * - Marked as deprecated on 2023-11-30.
   * - Will be removed on 2024-06-01.
   */
  statementId?: Maybe<Scalars["ID"]>;
  title?: Maybe<Scalars["String"]>;
};

/** Payment adequacy adjusts fixed payment schedules to maintain a healthy ledger balance over a year. */
export type PaymentAdequacyDetailsType = {
  __typename?: "PaymentAdequacyDetailsType";
  /** This ledger will be exempt from default Payment Adequacy. This may mean that it is completely exempt, or handled with special rules. */
  isCurrentlyExempt?: Maybe<Scalars["Boolean"]>;
};

export enum PaymentDayDirectionType {
  /** The payment day is calculated backwards from the last day of the month. */
  BeforeEndOfMonth = "BEFORE_END_OF_MONTH",
  /** The payment day is calculated forward. */
  OfMonth = "OF_MONTH",
}

export type PaymentForecastConnectionTypeConnection = {
  __typename?: "PaymentForecastConnectionTypeConnection";
  /** Number of nodes in the edge. */
  edgeCount: Scalars["Int"];
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<PaymentForecastConnectionTypeEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  /** Total number of nodes. */
  totalCount: Scalars["Int"];
};

/** A Relay edge containing a `PaymentForecastConnectionType` and its cursor. */
export type PaymentForecastConnectionTypeEdge = {
  __typename?: "PaymentForecastConnectionTypeEdge";
  /** A cursor for use in pagination */
  cursor: Scalars["String"];
  /** The item at the end of the edge */
  node?: Maybe<PaymentForecastType>;
};

export type PaymentForecastType = {
  __typename?: "PaymentForecastType";
  amount?: Maybe<Scalars["Int"]>;
  date?: Maybe<Scalars["Date"]>;
  /** The payment method used for the forecasted payment. */
  method?: Maybe<ScheduleType>;
  paymentNumber?: Maybe<Scalars["Int"]>;
};

/** An enumeration. */
export enum PaymentFrequencyOptions {
  /** Monthly */
  Monthly = "Monthly",
  /** Planned */
  Planned = "Planned",
  /** Weekly */
  Weekly = "Weekly",
}

/** Payment Instructions */
export type PaymentInstructionType = {
  __typename?: "PaymentInstructionType";
  accountHolder: Scalars["String"];
  accountType?: Maybe<Scalars["String"]>;
  bankCode?: Maybe<Scalars["String"]>;
  cardExpiryMonth?: Maybe<Scalars["Int"]>;
  cardExpiryYear?: Maybe<Scalars["Int"]>;
  cardNumber: Scalars["String"];
  cardPaymentNetwork?: Maybe<Scalars["String"]>;
  cardType?: Maybe<Scalars["String"]>;
  iban: Scalars["String"];
  id: Scalars["ID"];
  instructionType: Scalars["String"];
  /** A masked reference to a recurring payment method. */
  maskedAccountIdentifier?: Maybe<Scalars["String"]>;
  sortCode: Scalars["String"];
  status: Scalars["String"];
  validFrom: Scalars["DateTime"];
  vendor: Scalars["String"];
};

/** An enumeration. */
export enum PaymentMethods {
  CreditCard = "CREDIT_CARD",
  DirectDebit = "DIRECT_DEBIT",
  PayOnReceipt = "PAY_ON_RECEIPT",
}

export enum PaymentReasonOptions {
  BalanceThresholdCrossed = "BALANCE_THRESHOLD_CROSSED",
  BillIssued = "BILL_ISSUED",
  PaymentPlan = "PAYMENT_PLAN",
  RegularSchedule = "REGULAR_SCHEDULE",
}

export type PaymentScheduleConnectionTypeConnection = {
  __typename?: "PaymentScheduleConnectionTypeConnection";
  /** Number of nodes in the edge. */
  edgeCount: Scalars["Int"];
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<PaymentScheduleConnectionTypeEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  /** Total number of nodes. */
  totalCount: Scalars["Int"];
};

/** A Relay edge containing a `PaymentScheduleConnectionType` and its cursor. */
export type PaymentScheduleConnectionTypeEdge = {
  __typename?: "PaymentScheduleConnectionTypeEdge";
  /** A cursor for use in pagination */
  cursor: Scalars["String"];
  /** The item at the end of the edge */
  node?: Maybe<PaymentScheduleType>;
};

/**
 * Input type for payment schedule details.
 *
 * This enables us to collect information to create 4 different types of schedules during enrollment:
 * - Payment on receipt of bill
 * - Payment at a fixed day of the month once a receipt of bill has been issued
 * - Payment when a ledger balance drops below a certain value
 * - Payment at regular intervals for a fixed amount
 *
 * This is intended to be morally equivalent to a "union" input type.
 */
export type PaymentScheduleDetailsInput = {
  balanceTriggered?: InputMaybe<BalanceTriggeredScheduleInput>;
  billTriggered?: InputMaybe<BillTriggeredScheduleInput>;
  regularTriggered?: InputMaybe<RegularTriggeredScheduleInput>;
};

export type PaymentSchedulePaymentDayDetailsType = {
  __typename?: "PaymentSchedulePaymentDayDetailsType";
  /** A human-readable description of the value. */
  description?: Maybe<Scalars["String"]>;
  /** Direction of payment day. */
  direction?: Maybe<PaymentDayDirectionType>;
};

/** An enumeration. */
export enum PaymentScheduleReasonOptions {
  /** A payment schedule created to take payments to pay back a debt. These schedules typically expire once the debt has been re-payed. */
  DebtRepaymentPlan = "DEBT_REPAYMENT_PLAN",
  /** A payment schedule created to take the final payment when an account is closed. */
  FinalPayment = "FINAL_PAYMENT",
  /** The default value for usual account payments. */
  GeneralAccountPayment = "GENERAL_ACCOUNT_PAYMENT",
  /** A payment schedule created to take a payment around the supply start date of a meterpoint to help prevent accounts accruing debt. */
  SsdPayment = "SSD_PAYMENT",
}

/** An object that represents when we have agreed to take payments from a payment instruction. */
export type PaymentScheduleType = {
  __typename?: "PaymentScheduleType";
  id: Scalars["ID"];
  isPaymentHoliday?: Maybe<Scalars["Boolean"]>;
  isVariablePaymentAmount: Scalars["Boolean"];
  paymentAdequacyAdjustment?: Maybe<Scalars["Int"]>;
  paymentAdequacyAdjustmentExpiryDate?: Maybe<Scalars["Date"]>;
  paymentAmount: Scalars["Int"];
  paymentDay?: Maybe<Scalars["Int"]>;
  /** Details of the payment_day value. */
  paymentDayDetails?: Maybe<PaymentSchedulePaymentDayDetailsType>;
  /** The frequency of the payment schedule. */
  paymentFrequency?: Maybe<PaymentFrequencyOptions>;
  paymentFrequencyMultiplier: Scalars["Int"];
  paymentHolidayReason: Scalars["String"];
  /** The reason the payment schedule was created. */
  reason?: Maybe<PaymentScheduleReasonOptions>;
  /** The method of payment for the schedule. */
  scheduleType?: Maybe<ScheduleType>;
  /** The supplementary ledger for this payment schedule, if it is on one. */
  supplementaryLedger?: Maybe<SupplementaryLedgerType>;
  /** The sum of the payment adequacy contributions on the payment schedule that are expected to be taken before the debt repayment is complete. */
  totalDebtAmount?: Maybe<Scalars["Int"]>;
  validFrom: Scalars["Date"];
  validTo?: Maybe<Scalars["Date"]>;
};

/** Options for selecting payment schedule types. `CARD_PAYMENT`: Credit or Debit card, `DIRECT_DEBIT`: Bank account, `BACS_TRANSFER`: Manual pay  */
export enum PaymentScheduleTypeChoices {
  CardPayment = "CARD_PAYMENT",
  DirectDebit = "DIRECT_DEBIT",
  Manual = "MANUAL",
}

/** An enumeration. */
export enum PaymentType {
  Bpay = "BPAY",
  Card = "CARD",
  DirectDebit = "DIRECT_DEBIT",
  GmoRefund = "GMO_REFUND",
  PaymentSlip = "PAYMENT_SLIP",
}

/**
 * Initiate a boost charge for an electric vehicle (EV).
 *
 * This will start charging the EV and will not stop until the battery reaches 100% charged.
 *
 * If it is not possible to initiate a boost charge, a KT-CT-4357 error will be returned.
 * It may have a `boostChargeRefusalReasons` extension which lists the reasons why the boost
 * charge was refused. Possible reasons include:
 *
 * - `BC_DEVICE_NOT_YET_LIVE` (device is not yet live)
 * - `BC_DEVICE_RETIRED` (device is retired)
 * - `BC_DEVICE_SUSPENDED` (device is suspended)
 * - `BC_DEVICE_DISCONNECTED` (device is disconnected)
 * - `BC_DEVICE_NOT_AT_HOME` (device is not at home)
 * - `BC_BOOST_CHARGE_IN_PROGRESS` (boost charge already in progress)
 *
 * The possible errors that can be raised are:
 *
 * - KT-CT-4356: A boost charge cannot currently be performed.
 * - KT-CT-4357: Unable to trigger boost charge.
 * - KT-CT-1113: Disabled GraphQL field requested.
 */
export type PerformBoostCharge = {
  __typename?: "PerformBoostCharge";
  krakenflexDevice?: Maybe<KrakenFlexDeviceType>;
  possibleErrors?: Maybe<Array<Maybe<PossibleErrorType>>>;
};

/**
 * Initiate a test charge of an electric vehicle (EV).
 *
 * This is to ensure that the EV or EVSE (charge point) can be controlled remotely and successfully charged for a
 * short period.
 *
 * If it is not possible to initiate a test charge, a KT-CT-4355 error will be returned. It may have a
 * `testChargeRefusalReasons` extension which lists the reasons why the test charge was refused. Possible reasons
 * include:
 *
 * - `TC_DEVICE_LIVE` (device is already live)
 * - `TC_DEVICE_ONBOARDING_IN_PROGRESS` (test dispatch already in progress)
 * - `TC_DEVICE_RETIRED` (device is retired)
 * - `TC_DEVICE_SUSPENDED` (device is suspended)
 * - `TC_DEVICE_DISCONNECTED` (device is disconnected)
 * - `TC_DEVICE_AWAY_FROM_HOME` (device is away from home)
 * - `TC_DEVICE_NO_LOCATION_CONFIGURED` (device has no location configured)
 * - `TC_DEVICE_LOCATION_UNABLE_TO_IDENTIFY` (unable to identify device location)
 * - `TC_DEVICE_LOCATION_MISSING` (device location is missing)
 *
 * The possible errors that can be raised are:
 *
 * - KT-CT-4301: Unable to find device for given account.
 * - KT-CT-4355: Unable to trigger charge.
 * - KT-CT-1113: Disabled GraphQL field requested.
 */
export type PerformTestCharge = {
  __typename?: "PerformTestCharge";
  krakenflexDevice?: Maybe<KrakenFlexDeviceType>;
  possibleErrors?: Maybe<Array<Maybe<PossibleErrorType>>>;
};

export type PeriodBasedDocumentType = BillInterface &
  ConstituentInterface & {
    __typename?: "PeriodBasedDocumentType";
    attachments?: Maybe<BillingAttachmentConnectionTypeConnection>;
    /** The type of the bill. */
    billType?: Maybe<BillTypeEnum>;
    /** The date of the constituent bill covered from. */
    fromDate?: Maybe<Scalars["Date"]>;
    /** The ID of the constituent bill. */
    id?: Maybe<Scalars["ID"]>;
    /**
     * The unique identifier for the billing document. Note: a pending billing document will not have an identifier yet;
     * and not all finalized billing documents will have an identifier assigned to them, in which case this will be null.
     */
    identifier?: Maybe<Scalars["ID"]>;
    /** Whether the billing document has been annulled. */
    isAnnulled: Scalars["Boolean"];
    /** The date the bill was sent to the customer. */
    issuedDate?: Maybe<Scalars["Date"]>;
    /**
     * Requesting this field generates a temporary URL at which bill is available.
     *         This URL will expire after approximately an hour.  It is intended for redirection purposes,
     *         NOT persistence in any form (e.g. inclusion in emails or the body of a web page).
     *         This field can raise an error with errorClass NOT_FOUND if the bill document has not
     *         been created/issued yet. This field is deprecated use 'attachments' field instead.
     * @deprecated The 'temporaryUrl' field is deprecated.
     *
     * This field is deprecated. Use the 'attachments' field instead.
     *
     * - Marked as deprecated on 2024-09-16.
     * - Will be removed on 2025-09-01.
     */
    temporaryUrl?: Maybe<Scalars["String"]>;
    /** The date of the constituent bill covered to. */
    toDate?: Maybe<Scalars["Date"]>;
    /** The total amounts for all charges on the billing document. */
    totalCharges?: Maybe<StatementTotalType>;
    /** The total amounts for all credits on the statement. */
    totalCredits?: Maybe<StatementTotalType>;
  };

export type PeriodBasedDocumentTypeAttachmentsArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
};

export type PhoneNumberOutput = {
  __typename?: "PhoneNumberOutput";
  isValidPhoneNumber?: Maybe<Scalars["Boolean"]>;
};

export type PillButtonType = ButtonInterface &
  IdentifiableInterface & {
    __typename?: "PillButtonType";
    /** The action to perform when the button is pressed. */
    buttonAction: ActionType;
    /** The button style. */
    buttonStyle?: Maybe<ButtonStyle>;
    /** Unique identifier of the object. */
    id?: Maybe<Scalars["ID"]>;
    /** Title text of the button. */
    title: Scalars["String"];
    /** The name of the object's type. */
    typename?: Maybe<Scalars["String"]>;
  };

export type PointsAllowanceRateLimitInformation = {
  __typename?: "PointsAllowanceRateLimitInformation";
  /** Whether the viewer has been blocked due to spending all its allowed points. */
  isBlocked?: Maybe<Scalars["Boolean"]>;
  /** The maximum number of points the viewer gets for requests per hour. */
  limit?: Maybe<Scalars["Int"]>;
  /** The remaining points for the viewer in one hour time limit. */
  remainingPoints?: Maybe<Scalars["Int"]>;
  /** Time To Live: UNIX timestamp when the viewer will get a new allowance of points. */
  ttl?: Maybe<Scalars["Int"]>;
  /** The points used so far in one hour time limit. */
  usedPoints?: Maybe<Scalars["Int"]>;
};

/** A measurement in points. */
export type PointsSizeType = IdentifiableInterface & {
  __typename?: "PointsSizeType";
  /** Unique identifier of the object. */
  id?: Maybe<Scalars["ID"]>;
  /** The points value. */
  points: Scalars["Int"];
  /** The name of the object's type. */
  typename?: Maybe<Scalars["String"]>;
};

/** Paginator of Operations Team */
export type PortfolioConnectionTypeConnection = {
  __typename?: "PortfolioConnectionTypeConnection";
  /** Number of nodes in the edge. */
  edgeCount: Scalars["Int"];
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<PortfolioConnectionTypeEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  /** Total number of nodes. */
  totalCount: Scalars["Int"];
};

/** A Relay edge containing a `PortfolioConnectionType` and its cursor. */
export type PortfolioConnectionTypeEdge = {
  __typename?: "PortfolioConnectionTypeEdge";
  /** A cursor for use in pagination */
  cursor: Scalars["String"];
  /** The item at the end of the edge */
  node?: Maybe<PortfolioType>;
};

export type PortfolioType = {
  __typename?: "PortfolioType";
  billingName?: Maybe<Scalars["String"]>;
  collectiveBilling: Scalars["Boolean"];
  createdAt: Scalars["DateTime"];
  id: Scalars["ID"];
  /** The lead account for this portfolio. */
  leadAccountNumber?: Maybe<Scalars["String"]>;
  number: Scalars["String"];
  /** Operations team for this portfolio. */
  operationsTeam?: Maybe<OperationsTeamType>;
  updatedAt: Scalars["DateTime"];
};

/** The role a user has in association with one portfolio. */
export type PortfolioUserRoleType = {
  __typename?: "PortfolioUserRoleType";
  id: Scalars["ID"];
  /** Portfolio object. */
  portfolio: PortfolioType;
  /** The portfolio role. */
  role?: Maybe<Scalars["RoleString"]>;
  user: AccountUserType;
};

/**
 * The GraphQL error type for displaying information about
 * GraphQL errors that might be raised from the API.
 */
export type PossibleErrorType = {
  __typename?: "PossibleErrorType";
  /** The error code that might be returned from the query/mutation. */
  code?: Maybe<Scalars["String"]>;
  /** The error description that might be returned from the query/mutation. */
  description?: Maybe<Scalars["String"]>;
  /** The error message that might be returned from the query/mutation. */
  message?: Maybe<Scalars["String"]>;
  /** The error type that might be returned from the query/mutation. */
  type?: Maybe<Scalars["String"]>;
};

/**
 * The possible errors that can be raised are:
 *
 * - KT-CT-5316: Invalid data.
 * - KT-CT-5311: The credit reason with the requested code is deprecated.
 * - KT-CT-5312: The credit reason with the requested code does not exist.
 * - KT-CT-5313: An error occurred whilst posting the credit.
 * - KT-CT-1113: Disabled GraphQL field requested.
 * - KT-CT-1111: Unauthorized.
 * - KT-CT-1112: 'Authorization' header not provided.
 */
export type PostCredit = {
  __typename?: "PostCredit";
  /** Posted account credit. */
  credit?: Maybe<Credit>;
  possibleErrors?: Maybe<Array<Maybe<PossibleErrorType>>>;
};

export type PostCreditInput = {
  /** The account number. */
  accountNumber: Scalars["String"];
  /** Optional short note about the credit, to be displayed to the user. */
  displayNote?: InputMaybe<Scalars["String"]>;
  /** The ID of the ledger where the credit will be posted. */
  ledgerId: Scalars["ID"];
  /** The net amount of the credit to be posted. Amount should be posted in the smallest unit of currency. */
  netAmount: Scalars["Int"];
  /** Optional short note about the credit, to be displayed to internal systems. */
  note?: InputMaybe<Scalars["String"]>;
  /** The reason why the credit is posted. This should be a valid credit reason code. */
  reason: Scalars["String"];
  /** The tax amount of the credit to be posted. Amount should be posted in the smallest unit of currency. */
  taxAmount: Scalars["Int"];
};

/**
 * Fetch service providers by zip code.
 *
 * A zip code is required to perform the lookup.
 */
export type PostcodeZoneMapping = {
  __typename?: "PostcodeZoneMapping";
  id: Scalars["ID"];
  loadZone?: Maybe<PostcodeZoneMappingLoadZone>;
  postcode: Scalars["String"];
  serviceProvider?: Maybe<PostcodeZoneMappingServiceProvider>;
};

/** An enumeration. */
export enum PostcodeZoneMappingLoadZone {
  /** LZ Houston */
  LzHouston = "LZ_HOUSTON",
  /** LZ North */
  LzNorth = "LZ_NORTH",
  /** LZ South */
  LzSouth = "LZ_SOUTH",
  /** LZ West */
  LzWest = "LZ_WEST",
}

/** An enumeration. */
export enum PostcodeZoneMappingServiceProvider {
  /** AEP TCC */
  AepTcc = "AEP_TCC",
  /** AEP TNC */
  AepTnc = "AEP_TNC",
  /** Centerpoint */
  Centerpoint = "CENTERPOINT",
  /** LPL */
  Lpl = "LPL",
  /** ONCOR */
  Oncor = "ONCOR",
  /** TNMP */
  Tnmp = "TNMP",
}

export type PreKrakenBillType = BillInterface & {
  __typename?: "PreKrakenBillType";
  attachments?: Maybe<BillingAttachmentConnectionTypeConnection>;
  /** The type of the bill. */
  billType?: Maybe<BillTypeEnum>;
  /** The date of the bill is covered from. */
  fromDate?: Maybe<Scalars["Date"]>;
  /** The gross amount of the historical bill. */
  grossAmount?: Maybe<Scalars["BigInt"]>;
  /** The ID of the bill. */
  id?: Maybe<Scalars["ID"]>;
  /** The date the bill was sent to the customer. */
  issuedDate?: Maybe<Scalars["Date"]>;
  /** The params associated with the historical bill. */
  params?: Maybe<Scalars["JSONString"]>;
  /**
   * Requesting this field generates a temporary URL at which bill is available.
   *         This URL will expire after approximately an hour.  It is intended for redirection purposes,
   *         NOT persistence in any form (e.g. inclusion in emails or the body of a web page).
   *         This field can raise an error with errorClass NOT_FOUND if the bill document has not
   *         been created/issued yet. This field is deprecated use 'attachments' field instead.
   * @deprecated The 'temporaryUrl' field is deprecated.
   *
   * This field is deprecated. Use the 'attachments' field instead.
   *
   * - Marked as deprecated on 2024-09-16.
   * - Will be removed on 2025-09-01.
   */
  temporaryUrl?: Maybe<Scalars["String"]>;
  /** The date of the bill is covered to. */
  toDate?: Maybe<Scalars["Date"]>;
};

export type PreKrakenBillTypeAttachmentsArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
};

/** A pre-signed, expiring and opaque tokens that can be swapped for a limited scope JWT (Kraken Token). */
export type PreSignedToken = {
  __typename?: "PreSignedToken";
  isValid?: Maybe<Scalars["Boolean"]>;
  key: Scalars["String"];
  /** The scope that the token will grant to the account user. */
  scope: ExpiringTokenScope;
};

/**
 * Choices class for the pre-signed expiring tokens.
 *
 * These choices must have a certain format:
 *
 * {ACTION-VERB}_{DEFINING-NOUN}
 *
 * They should start with an action verb. It should be a single word.
 * The action verb enables the account user to do the thing (defining noun)
 * that comes after the action verb. Together they represent a task.
 *
 * The defining noun could be longer than a single word.
 * Preferably, it should be kept short and simple as much as possible.
 */
export enum PreSignedTokenScope {
  /** Scope that enables account user to accept the terms and conditions for a product. */
  AcceptTermsAndConditions = "ACCEPT_TERMS_AND_CONDITIONS",
  /** Scope that enables account user to book smart meter appointments. */
  BookSmartMeterAppointments = "BOOK_SMART_METER_APPOINTMENTS",
  /** Scope that enables account user to checkout a quote (validate terms & conds and provide a payment detail). */
  CheckoutQuote = "CHECKOUT_QUOTE",
  /** Edit Customer Marketing Preference */
  EditCustomerMarketingPreference = "EDIT_CUSTOMER_MARKETING_PREFERENCE",
  /** Scope that enables account user to join campaigns. */
  JoinCampaigns = "JOIN_CAMPAIGNS",
  /** Scope that enables account user to join campaign events. */
  JoinCampaignEvents = "JOIN_CAMPAIGN_EVENTS",
  /** Scope that enables account user to generate a renewal quote and renew agreements. */
  ManageAccountRenewals = "MANAGE_ACCOUNT_RENEWALS",
  /** Scope that enables account user to manage security deposit payments for business accounts. */
  ManageBusinessSecurityDeposit = "MANAGE_BUSINESS_SECURITY_DEPOSIT",
  /** Scope that enables account user to accept goods quotes and process goods purchases. */
  ManageGoodsPurchases = "MANAGE_GOODS_PURCHASES",
  /** Scope that enables account user to do a self-serve product switch through the Dashboard. */
  ManageProductSwitch = "MANAGE_PRODUCT_SWITCH",
  /** Scope that enables account user to redeem loyalty points */
  RedeemLoyaltyPoints = "REDEEM_LOYALTY_POINTS",
  /** Scope that enables account user to report a property move-out. */
  ReportMoveOut = "REPORT_MOVE_OUT",
  /** Scope that enables user to send a loss objection for a change of supplier process. */
  SendLossObjectionForChangeOfSupplier = "SEND_LOSS_OBJECTION_FOR_CHANGE_OF_SUPPLIER",
  /** Scope that enables account user to submit customer feedback. */
  SubmitCustomerFeedback = "SUBMIT_CUSTOMER_FEEDBACK",
  /** Scope that enables account user to submit meter readings. */
  SubmitMeterReadings = "SUBMIT_METER_READINGS",
  /** Scope that enables the user to update information about themselves and their account. */
  UpdateAccountDetails = "UPDATE_ACCOUNT_DETAILS",
  /** Scope that enables account user to update their blackhole email address. */
  UpdateBlackholeEmail = "UPDATE_BLACKHOLE_EMAIL",
  /** Update Sensitive Customer Information */
  UpdateSensitiveCustomerInformation = "UPDATE_SENSITIVE_CUSTOMER_INFORMATION",
  /** Scope that enables account user to visit campaign dashboard. */
  ViewCampaignDashboards = "VIEW_CAMPAIGN_DASHBOARDS",
  /** Scope that enables account user to visit detailed property usage pages. */
  ViewDetailedUsage = "VIEW_DETAILED_USAGE",
}

export type PrecheckEmailValidationOutput = {
  __typename?: "PrecheckEmailValidationOutput";
  /** Error message in English. */
  errorMessageInEn?: Maybe<Scalars["String"]>;
  /** Error message in Spanish. */
  errorMessageInEs?: Maybe<Scalars["String"]>;
};

export type PrechecksValidationInputType = {
  /** Email address for pre-check validation. */
  email: Scalars["String"];
};

export type PrechecksValidationOutputType = {
  __typename?: "PrechecksValidationOutputType";
  /** Deny the enrollment based on the pre-checks. */
  denyEnrollment?: Maybe<Scalars["Boolean"]>;
  /** Pre-check result error for email. */
  email?: Maybe<PrecheckEmailValidationOutput>;
};

/** The mode for a user's preferences. */
export enum PreferencesModeChoices {
  Charge = "CHARGE",
  Cool = "COOL",
  Heat = "HEAT",
}

export type PreferencesScheduleInput = {
  /** The day of the week. */
  dayOfWeek: DayOfWeek;
  /** The maximum value. */
  max: Scalars["Decimal"];
  /** The minimum value. */
  min?: InputMaybe<Scalars["Decimal"]>;
  /** Time of day this change should apply. Format: HH:MM. */
  time: Scalars["Time"];
};

/** The target type for a user's preferences. */
export enum PreferencesTargetType {
  AbsoluteStateOfCharge = "ABSOLUTE_STATE_OF_CHARGE",
  AbsoluteTemperature = "ABSOLUTE_TEMPERATURE",
  RelativeStateOfCharge = "RELATIVE_STATE_OF_CHARGE",
}

/** The unit for a user's preferences. */
export enum PreferencesUnitChoices {
  Celsius = "CELSIUS",
  PercentageAbsolute = "PERCENTAGE_ABSOLUTE",
  PercentageRelative = "PERCENTAGE_RELATIVE",
}

/** An enumeration. */
export enum PremiseType {
  LargeNonResidential = "LARGE_NON_RESIDENTIAL",
  Residential = "RESIDENTIAL",
  SmallNonResidential = "SMALL_NON_RESIDENTIAL",
}

export type PrepareAccountInput = {
  /** The type of account to create. */
  accountType?: InputMaybe<AccountTypeChoices>;
  /** The billing address. */
  billingAddress: LifecycleAddressInput;
  /** The billing name. */
  billingName: Scalars["String"];
  /** The brand of the created account. */
  brandCode: Scalars["String"];
  /** The chosen payment day. */
  chosenPaymentDay?: InputMaybe<Scalars["Int"]>;
  /** The customer's details. */
  customerDetails: CustomerDetailsInput;
  /** The date of sale, defaults to today if not provided. */
  dateOfSale?: InputMaybe<Scalars["Date"]>;
  /** The preferred supply start date. */
  preferredSsd?: InputMaybe<Scalars["Date"]>;
  /** Sales information. */
  salesInfo: SalesInformationInput;
};

export type PrepareAccountResult = {
  __typename?: "PrepareAccountResult";
  /** The account number of the newly created account or the existing account to be re-used. */
  accountNumber?: Maybe<Scalars["String"]>;
  /** Was a new account created. */
  isNewAccount?: Maybe<Scalars["Boolean"]>;
  /** Was a new user created. */
  isNewUser?: Maybe<Scalars["Boolean"]>;
  /** The ID of the newly created or existing account user. */
  userId?: Maybe<Scalars["ID"]>;
};

/** Fields returned by generate_presigned_post. */
export type PresignedPostFields = {
  __typename?: "PresignedPostFields";
  /** The AWS access key ID. */
  awsAccessKeyId: Scalars["String"];
  /** The S3 bucket key. */
  key: Scalars["String"];
  /** The S3 policy. */
  policy: Scalars["String"];
  /** AWS Signature Version 4 Authentication. */
  signature: Scalars["String"];
  /** The AMZ security token. */
  xAmzSecurityToken: Scalars["String"];
};

export type PricePerUnit = {
  __typename?: "PricePerUnit";
  /**
   * Monetary value of a single unit of the measurement.
   * This is the smallest unit of currency e.g. cents for USD or yen for JPY.
   */
  amount: Scalars["Decimal"];
  /** Unit that monetary amount relates to eg. 27 cents per kwh. */
  unit?: Maybe<Unit>;
};

export type PricingHistory = {
  __typename?: "PricingHistory";
  /** Historic prices calculated based on the TDSP and 1000 kWh average consumption. */
  prices?: Maybe<Array<Maybe<MonthlyPrice>>>;
  /** The TDSP that is applicable to the current and/or historic rates. */
  serviceProvider?: Maybe<ServiceProvider>;
};

/** Represents a print attachment */
export type PrintAttachmentType = {
  __typename?: "PrintAttachmentType";
  filename: Scalars["String"];
  id: Scalars["ID"];
  s3Bucket: Scalars["String"];
  s3Key: Scalars["String"];
  /** Temporary URL at which the attachment is available. This URL will expire after approximately an hour. It is intended for redirection purposes, NOT persistence in any form (e.g. inclusion in emails or the body of a web page). */
  temporaryUrl?: Maybe<Scalars["String"]>;
};

/** An enumeration. */
export enum PrintBatchStatus {
  Closed = "CLOSED",
  Open = "OPEN",
  Processed = "PROCESSED",
}

/** Represents print batch details */
export type PrintBatchType = {
  __typename?: "PrintBatchType";
  id: Scalars["ID"];
  /** Messages in a print batch. */
  messages?: Maybe<PrintMessageTypeConnection>;
  /** The status of the print batch. */
  status?: Maybe<PrintBatchStatus>;
};

/** Represents print batch details */
export type PrintBatchTypeMessagesArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  isHighPriority?: InputMaybe<Scalars["Boolean"]>;
  last?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
};

export type PrintEventType = Node & {
  __typename?: "PrintEventType";
  eventType: Scalars["String"];
  /** The ID of the object */
  id: Scalars["ID"];
  /** Print message of the print event. */
  message?: Maybe<PrintMessageType>;
  occurredAt: Scalars["DateTime"];
};

/** Represents a print communication. */
export type PrintMessageType = Node & {
  __typename?: "PrintMessageType";
  account?: Maybe<AccountType>;
  /** Attachments of the message. */
  attachments?: Maybe<Array<Maybe<PrintAttachmentType>>>;
  /** Comms that are marked as high priority. */
  highPriority?: Maybe<Scalars["Boolean"]>;
  /** The ID of the object */
  id: Scalars["ID"];
  templateCode: Scalars["String"];
};

export type PrintMessageTypeConnection = {
  __typename?: "PrintMessageTypeConnection";
  /** Number of nodes in the edge. */
  edgeCount: Scalars["Int"];
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<PrintMessageTypeEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  /** Total number of nodes. */
  totalCount: Scalars["Int"];
};

/** A Relay edge containing a `PrintMessageType` and its cursor. */
export type PrintMessageTypeEdge = {
  __typename?: "PrintMessageTypeEdge";
  /** A cursor for use in pagination */
  cursor: Scalars["String"];
  /** The item at the end of the edge */
  node?: Maybe<PrintMessageType>;
};

/** An enumeration. */
export enum ProductAvailabilityStatus {
  /** No restrictions */
  Public = "PUBLIC",
  /** Restricted */
  Restricted = "RESTRICTED",
}

/** Returns estimated rates for a quoted product. */
export type ProductEstimate = {
  __typename?: "ProductEstimate";
  /** Annually estimated cost in cents. */
  annualCostEstimate?: Maybe<Scalars["Decimal"]>;
  /** Applicable rates for the product. */
  applicableRates?: Maybe<ConciseRatesOutput>;
  /** Estimated % of total load used during the day - peak hours. */
  dayPercentage?: Maybe<Scalars["Decimal"]>;
  /** The kWh usage used to calculate estimated rates. */
  kwhUsage?: Maybe<Scalars["Decimal"]>;
  /** A monthly estimated cost in cents. */
  monthlyCostEstimate?: Maybe<Scalars["Decimal"]>;
  /** Estimated % of total load used during the night - off-peak hours. */
  nightPercentage?: Maybe<Scalars["Decimal"]>;
  /** The period used for estimating a quote request. */
  usagePeriod?: Maybe<EstimationInterval>;
};

export type ProductInput = {
  /**
   * Filters products that are available until the given time.
   *
   * If the available_at argument is not provided, it defaults to the current localtime (America/Chicago).
   */
  availableAt?: InputMaybe<Scalars["DateTime"]>;
  /** Get products that have the Time of Use flag. */
  basedOnTimeOfUse?: InputMaybe<Scalars["Boolean"]>;
  /** Name of the product. For example: OctoPlus 365 Day Fixed. */
  displayName?: InputMaybe<Scalars["String"]>;
  /** ID of the product. */
  id?: InputMaybe<Scalars["ID"]>;
  /** Get products that have the Prepay flag. */
  prepay?: InputMaybe<Scalars["Boolean"]>;
};

export type ProductToPurchaseInput = {
  /** Number of units. */
  numberOfUnits: Scalars["Int"];
  /** Products code to purchase. */
  productCode: Scalars["String"];
};

/** Represents a product and the quantity to quote for a customer. */
export type ProductToQuoteInput = {
  /** Currency. */
  currency: Scalars["String"];
  /** Number of units. */
  numberOfUnits: Scalars["Int"];
  /** Price per unit in smallest sub-unit of the currency. */
  pricePerUnit?: InputMaybe<Scalars["Int"]>;
  /** ID of the product to quote. */
  productId: Scalars["Int"];
};

/**
 * Fetch products that are available.
 *
 * A Product models a group of rates for electricity available during a fixed
 * term (number of months).
 *
 * Products require an availableAt input to filter products that are available
 * until the given datetime. If no input is given, it defaults to the current
 * local time.
 */
export type ProductType = {
  __typename?: "ProductType";
  autoTopUpDefaultAmount?: Maybe<Scalars["Int"]>;
  autoTopUpMinimumAmount?: Maybe<Scalars["Int"]>;
  availabilityStatus: ProductAvailabilityStatus;
  availableFrom: Scalars["DateTime"];
  availableTo?: Maybe<Scalars["DateTime"]>;
  basedOnTimeOfUse?: Maybe<Scalars["Boolean"]>;
  code: Scalars["String"];
  /** This will be shown to customers during sign-up */
  description: Scalars["String"];
  /** This name will be shown to customers during sign-up */
  displayName: Scalars["String"];
  /** This is when end-dated products expire */
  endsAt?: Maybe<Scalars["DateTime"]>;
  fullName: Scalars["String"];
  /** Returns whether or not products are eligible for solar credits. */
  generationCredit?: Maybe<Scalars["Boolean"]>;
  id: Scalars["ID"];
  /** Returns whether or not the given product has IO discount rates. */
  ioDiscountEnabled?: Maybe<Scalars["Boolean"]>;
  isWholesale?: Maybe<Scalars["Boolean"]>;
  marketName: Scalars["String"];
  /** These are internal notes to explain why this product exists */
  notes: Scalars["String"];
  prepay?: Maybe<Scalars["Boolean"]>;
  /**
   * A list of rates per product.
   *
   * You will need to provide one of the following options:
   * `Postcode`, `Service Provider`, `Load Zone`, `ESI ID`, or `Address`
   */
  rates?: Maybe<RatesType>;
  /** Duration of agreements using this product in months */
  term?: Maybe<Scalars["Int"]>;
  termsContractType: Scalars["String"];
};

/**
 * Fetch products that are available.
 *
 * A Product models a group of rates for electricity available during a fixed
 * term (number of months).
 *
 * Products require an availableAt input to filter products that are available
 * until the given datetime. If no input is given, it defaults to the current
 * local time.
 */
export type ProductTypeRatesArgs = {
  address?: InputMaybe<AddressTdspMappingInputType>;
  esiId?: InputMaybe<Scalars["String"]>;
  loadZone?: InputMaybe<LoadZone>;
  postcode?: InputMaybe<Scalars["String"]>;
  serviceProvider?: InputMaybe<ServiceProvider>;
};

export type ProductWithRatesOutput = {
  __typename?: "ProductWithRatesOutput";
  code?: Maybe<Scalars["String"]>;
  /** Returns rates for 500 kWh, 1000 kWh, and 2000 kWh usages. */
  consolidatedRates?: Maybe<Array<Maybe<Array<Maybe<ConciseRatesOutput>>>>>;
  description?: Maybe<Scalars["String"]>;
  displayName?: Maybe<Scalars["String"]>;
  fullName?: Maybe<Scalars["String"]>;
  id?: Maybe<Scalars["ID"]>;
  prepay?: Maybe<Scalars["Boolean"]>;
  rates?: Maybe<Array<Maybe<ConciseRatesOutput>>>;
  /** Duration of agreements using this product in months. */
  term?: Maybe<Scalars["Int"]>;
};

export type ProductWithRatesOutputConsolidatedRatesArgs = {
  filterConsolidatedRatesBy?: InputMaybe<ConciseRatesBaseInput>;
};

export type ProductWithRatesOutputRatesArgs = {
  filterRatesBy?: InputMaybe<ConciseRatesInput>;
};

export type PropertyInterface = {
  /** The address of the property, formatted into a single string. */
  address?: Maybe<Scalars["String"]>;
  /** Coordinates for the property, useful for displaying the property on a map. */
  coordinates?: Maybe<CoordinatesType>;
  /** The embedded network this property belongs to, if any. */
  embeddedNetwork?: Maybe<EmbeddedNetworkType>;
  id?: Maybe<Scalars["String"]>;
  /** Measurements at a property */
  measurements?: Maybe<MeasurementConnection>;
  /** Time periods during which the property is associated with an account. Useful to display information about house-moves, as performing a move out of a property will set the end date for the occupancy period. */
  occupancyPeriods?: Maybe<Array<Maybe<OccupancyPeriodType>>>;
  /** Property rich address. */
  richAddress?: Maybe<PropertyRichAddressType>;
  /** List of address lines. */
  splitAddress?: Maybe<Array<Maybe<Scalars["String"]>>>;
};

export type PropertyInterfaceMeasurementsArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  endAt?: InputMaybe<Scalars["DateTime"]>;
  endOn?: InputMaybe<Scalars["Date"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  startAt?: InputMaybe<Scalars["DateTime"]>;
  startOn?: InputMaybe<Scalars["Date"]>;
  timezone?: InputMaybe<Scalars["String"]>;
  utilityFilters?: InputMaybe<Array<InputMaybe<UtilityFiltersInput>>>;
};

export type PropertyRichAddressType = {
  __typename?: "PropertyRichAddressType";
  /**
   * Top-level administrative subdivision, e.g. US state, AU
   * state/territory, IT region, JP prefecture.
   *
   * ### `AU`: Australia
   *
   * This must be one of `NSW`, `VIC`, `QLD`, `TAS`, `ACT`, `SA`,
   * `NT`, `WA`. For addresses not within these locations, use
   * the value that Australia Post uses, e.g. `ACT` for the
   * Jervis Bay Territory or `WA` for Christmas Island.
   */
  administrativeArea?: Maybe<Scalars["String"]>;
  /**
   * ISO 3166-1 alpha-2 code of the country this address belongs
   * to, e.g. `AU`, `GB`.
   */
  country?: Maybe<Scalars["String"]>;
  /**
   * Identifier used by the local postal service for this
   * address, e.g. AU DPID, GB postcode + Delivery Point Suffix,
   * US Zip-9 + Delivery Point.
   *
   * This is the value that gets encoded in the barcode printed
   * on the envelope by large-volume bulk mail providers.
   */
  deliveryPointIdentifier?: Maybe<Scalars["String"]>;
  /**
   * UK dependent localities, or neighbourhoods or boroughs in
   * some other locations.
   */
  dependentLocality?: Maybe<Scalars["String"]>;
  /**
   * City or town portion of an address, e.g. US city, AU
   * suburb/town, IT comune, UK post town.
   */
  locality?: Maybe<Scalars["String"]>;
  /** A personal name. */
  name?: Maybe<Scalars["String"]>;
  /** The name of a business or organisation. */
  organization?: Maybe<Scalars["String"]>;
  /** Postal code (ZIP code in the US). */
  postalCode?: Maybe<Scalars["String"]>;
  /** Sorting code, e.g. FR CEDEX code. This field is not used in many countries. */
  sortingCode?: Maybe<Scalars["String"]>;
  /**
   * The 'street address' component.
   *
   * This value can (and often will) contain newline characters
   * when appropriate.
   *
   * In some cases, data may appear in this field instead of the
   * below fields; e.g. a UK post town name may appear here
   * instead of in the `dependent_locality` field. This happens
   * when data has been migrated from a legacy format, and that
   * format had insufficient metadata to determine the
   * appropriate field.
   *
   * If `structured_street_address` is also set, the value of
   * this field will be a string generated from that value.
   */
  streetAddress?: Maybe<Scalars["String"]>;
  /**
   * The 'street address' component, in a structured format.
   *
   * This field stores the same value as `street_address`, but
   * with more detail; for instance, instead of `123 Example
   * Street` it might be `{'street_number': '123',
   * 'street_name': 'Example', 'street_type': 'Street'}`. In
   * many cases this will be blank; we only use this field for
   * Krakens where we need to supply this level of granularity
   * to some third-party service, like a bulk mail provider.
   *
   * The exact structure of this value depends on the country _of
   * the address_, which is not necessarily the same as the
   * country this Kraken is configured to serve. For addresses
   * outside of the countries listed below, this field will be
   * left blank.
   *
   * ### `AU`: Australia
   *
   * The following keys may be present; all are optional. All
   * keys have string values, and their meaning is the same as
   * their aseXML counterparts. (Note that, unlike aseXML, all
   * keys are provided at the top level, rather than being
   * nested.)
   *
   * - `flat_or_unit_type`
   * - `flat_or_unit_number`
   * - `floor_or_level_type`
   * - `floor_or_level_number`
   * - `building_or_property_name`
   * - `location_descriptor`
   * - `lot_number`
   * - `house_number_1`
   * - `house_number_suffix_1`
   * - `house_number_2`
   * - `house_number_suffix_2`
   * - `street_name`
   * - `street_type`
   * - `street_suffix`
   * - `postal_delivery_type`
   * - `postal_delivery_number_prefix`
   * - `postal_delivery_number_value`
   * - `postal_delivery_number_suffix`
   *
   * ### `JP`: Japan
   *
   * The following keys may be present; all are optional.
   * If keys are empty, they may be omitted from the response entirely.
   *
   * - `chome`
   * - `banchi`
   * - `go`
   * - `edaban`
   * - `kana_building_name`
   * - `kanji_building_name`
   * - `building_number`
   * - `room_number`
   * - `address_code`
   * - `physical_location_identifier`
   */
  structuredStreetAddress?: Maybe<Scalars["GenericScalar"]>;
};

export type PropertySearchResult = {
  __typename?: "PropertySearchResult";
  /** The matched property. */
  property: PropertyType;
  /** A score representing the degree of confidence for a match. */
  score: Scalars["Decimal"];
};

/** Represents a property. Conceptually, it collects supply points under an address. */
export type PropertyType = PropertyInterface & {
  __typename?: "PropertyType";
  /** The address of the property, formatted into a single string. */
  address?: Maybe<Scalars["String"]>;
  addressLine1: Scalars["String"];
  addressLine2: Scalars["String"];
  addressLine3: Scalars["String"];
  addressLine4: Scalars["String"];
  addressLine5: Scalars["String"];
  /** Coordinates for the property, useful for displaying the property on a map. */
  coordinates?: Maybe<CoordinatesType>;
  /** The embedded network this property belongs to, if any. */
  embeddedNetwork?: Maybe<EmbeddedNetworkType>;
  id?: Maybe<Scalars["String"]>;
  /** Measurements at a property */
  measurements?: Maybe<MeasurementConnection>;
  /** Retrieve the details of an electricity meter-point. */
  meterPoints?: Maybe<Array<Maybe<ElectricityMeterPointType>>>;
  /** Time periods during which the property is associated with an account. Useful to display information about house-moves, as performing a move out of a property will set the end date for the occupancy period. */
  occupancyPeriods?: Maybe<Array<Maybe<OccupancyPeriodType>>>;
  postcode: Scalars["String"];
  /** Property rich address. */
  richAddress?: Maybe<PropertyRichAddressType>;
  /** List of address lines. */
  splitAddress?: Maybe<Array<Maybe<Scalars["String"]>>>;
};

/** Represents a property. Conceptually, it collects supply points under an address. */
export type PropertyTypeMeasurementsArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  endAt?: InputMaybe<Scalars["DateTime"]>;
  endOn?: InputMaybe<Scalars["Date"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  startAt?: InputMaybe<Scalars["DateTime"]>;
  startOn?: InputMaybe<Scalars["Date"]>;
  timezone?: InputMaybe<Scalars["String"]>;
  utilityFilters?: InputMaybe<Array<InputMaybe<UtilityFiltersInput>>>;
};

/** Represents a property. Conceptually, it collects supply points under an address. */
export type PropertyTypeMeterPointsArgs = {
  meterPointId?: InputMaybe<Scalars["ID"]>;
};

export type ProviderAuthDetailsType = {
  __typename?: "ProviderAuthDetailsType";
  /** OAuth 2.0 URI for the provider. */
  oauthUri: Scalars["String"];
};

/** This refers to the provider that is used to authenticate when registering a device. */
export enum ProviderChoices {
  Daikin = "DAIKIN",
  Ecobee = "ECOBEE",
  Energizer = "ENERGIZER",
  Enode = "ENODE",
  Enphase = "ENPHASE",
  Ford = "FORD",
  Givenergy = "GIVENERGY",
  Huawei = "HUAWEI",
  Hypervolt = "HYPERVOLT",
  Indra = "INDRA",
  Jedlix = "JEDLIX",
  Myenergi = "MYENERGI",
  OcppWallbox = "OCPP_WALLBOX",
  Ohme = "OHME",
  Sensi = "SENSI",
  Smartcar = "SMARTCAR",
  SmartPear = "SMART_PEAR",
  Tesla = "TESLA",
  VpAmazon = "VP_AMAZON",
  VpHoneywellCc = "VP_HONEYWELL_CC",
  VpHoneywellRes = "VP_HONEYWELL_RES",
  VpNest = "VP_NEST",
}

/**
 * Details of a public key that can be added to devices for end-to-end authentication or encryption.
 *
 * E.g. for Tesla the user visits a URL and the name can be used to show what the key is called.
 * https://github.com/teslamotors/vehicle-command#distributing-your-public-key
 */
export type ProviderVirtualKeyDetailsType = {
  __typename?: "ProviderVirtualKeyDetailsType";
  /** Friendly human-readable name for the virtual key. */
  virtualKeyName: Scalars["String"];
  /** URI for the virtual key. */
  virtualKeyUri: Scalars["String"];
};

export type ProvisionalTransactionConnectionTypeConnection = {
  __typename?: "ProvisionalTransactionConnectionTypeConnection";
  /** Number of nodes in the edge. */
  edgeCount: Scalars["Int"];
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<ProvisionalTransactionConnectionTypeEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  /** Total number of nodes. */
  totalCount: Scalars["Int"];
};

/** A Relay edge containing a `ProvisionalTransactionConnectionType` and its cursor. */
export type ProvisionalTransactionConnectionTypeEdge = {
  __typename?: "ProvisionalTransactionConnectionTypeEdge";
  /** A cursor for use in pagination */
  cursor: Scalars["String"];
  /** The item at the end of the edge */
  node?: Maybe<ProvisionalTransactionType>;
};

/** A provisional transaction represents some debit or credit to or from a customer's account which we cannot yet finalise for some reason, but which is still useful to keep a note of, and display to the customer. Provisional transactions are purely to give guidance in the absence of finalised information. We therefore only return provisional transactions that have not been finalised. When a transaction is finalised, it is available through the `transactions` field. */
export type ProvisionalTransactionType = {
  __typename?: "ProvisionalTransactionType";
  /** The amount in pence for this provisional transaction. It will be negative for charges, positive for credits. */
  amount?: Maybe<Scalars["Int"]>;
  /** The date at which the charge should be applied to the account. */
  date?: Maybe<Scalars["Date"]>;
  id: Scalars["ID"];
  /** A user readable string that indicates what this transaction relates to. */
  title?: Maybe<Scalars["String"]>;
};

/**
 * Publish a trigger within the transactional messaging service.
 *
 * The possible errors that can be raised are:
 *
 * - KT-CT-9901: Invalid trigger type code.
 * - KT-CT-9902: Invalid trigger type params.
 * - KT-CT-9903: Trigger type cannot be published externally.
 * - KT-CT-1113: Disabled GraphQL field requested.
 * - KT-CT-1111: Unauthorized.
 * - KT-CT-1112: 'Authorization' header not provided.
 */
export type PublishTransactionalMessagingTrigger = {
  __typename?: "PublishTransactionalMessagingTrigger";
  possibleErrors?: Maybe<Array<Maybe<PossibleErrorType>>>;
  /** The trigger that has been published. */
  trigger?: Maybe<TriggerType>;
};

export type PublishTransactionalMessagingTriggerInput = {
  /** The params of the trigger type, as a JSON string. These are defined in the Params class for a trigger type. */
  params: Scalars["JSONString"];
  /** The code of the trigger type to be published. */
  triggerTypeCode: Scalars["String"];
};

/** Represents a pairing of a single app installation to an account user. */
export type PushNotificationBindingType = {
  __typename?: "PushNotificationBindingType";
  application: NotifiableApplicationType;
  expiresAt: Scalars["DateTime"];
  id: Scalars["ID"];
  messages: PrintMessageTypeConnection;
  registeredAt: Scalars["DateTime"];
  token: Scalars["String"];
  user: AccountUserType;
};

/** Represents a pairing of a single app installation to an account user. */
export type PushNotificationBindingTypeMessagesArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
};

/**
 * Queries are the GraphQL equivalent of GET requests in REST. By convention, they do not mutate data. To learn about how to form Queries in graphql, see [GraphQL's documentation](https://graphql.org/learn/queries/).
 *
 * ⬅️ This interface will autocomplete, so just try typing what you want. You can also search these docs. Some queries will require authentication. Check the documentation or search `Authentication` for details.
 */
export type Query = {
  __typename?: "Query";
  /** Get details about an account. */
  account?: Maybe<AccountType>;
  /** Available reasons for use in account charge mutations. */
  accountChargeReasons?: Maybe<Array<Maybe<ChargeReasonType>>>;
  /** Available reasons for use in account credit mutations. */
  accountCreditReasons?: Maybe<Array<Maybe<CreditReasonType>>>;
  /** Returns whether the given data passes pre-check validation. */
  accountInfoPrechecksValidation?: Maybe<PrechecksValidationOutputType>;
  /** Determines whether an account is eligible to register devices with Intelligent Octopus. */
  accountIoEligibility?: Maybe<AccountIoEligibility>;
  /** List of matching account references. */
  accountReference?: Maybe<Array<Maybe<AccountReferenceType>>>;
  /** Get details about multiple accounts. */
  accounts?: Maybe<Array<Maybe<AccountType>>>;
  /** Search for account that are already in Kraken and match the search terms. */
  accountsSearch?: Maybe<Array<Maybe<AccountSearchItemType>>>;
  /** Return the current active referral reward scheme of a given affiliate organisation, if any exists. */
  activeAffiliateReferralScheme?: Maybe<ReferralSchemeType>;
  /** Return the current active signup referral reward scheme with the given code, if any exists. */
  activeDomesticSignupRewardScheme?: Maybe<ReferralSchemeType>;
  /** Get address mapping by providing . */
  addressLookup?: Maybe<Array<Maybe<AddressTdspMappingOutputType>>>;
  addressLookupUsingEsSearch?: Maybe<
    Array<Maybe<AddressTdspMappingOutputType>>
  >;
  /** Link object for an affiliate organization. */
  affiliateLink: AffiliateLinkType;
  /** Return the details of a given affiliate organization, if any exists. */
  affiliateOrganisation?: Maybe<AffiliateOrganisationType>;
  /** Get brownouts by status. */
  apiBrownouts?: Maybe<ApiBrownoutConnection>;
  /** Get a connection containing API Exceptions. */
  apiExceptions?: Maybe<ApiExceptionConnectionTypeConnection>;
  /** Get available dates for product switch. */
  availableProductSwitchDates?: Maybe<Array<Maybe<Scalars["Date"]>>>;
  /** List of available dates for move-in/switch/other service orders. */
  availableServiceDates?: Maybe<Array<Maybe<Scalars["Date"]>>>;
  /** Get mobile screen details to render. */
  backendScreen?: Maybe<BackendScreenType>;
  /** Get all registered backend screen event IDs. */
  backendScreenEventIds?: Maybe<Array<Maybe<Scalars["String"]>>>;
  /** Get all registered backend screen IDs. */
  backendScreenIds?: Maybe<Array<Maybe<Scalars["String"]>>>;
  /** The currently active battery device. */
  batteryDevice?: Maybe<BatteryDeviceType>;
  /** All supported batteries and their details. */
  batteryVariants?: Maybe<Array<Maybe<BatteryVariantsType>>>;
  /** Get details about a business. */
  business?: Maybe<BusinessType>;
  /**
   * All charge point devices.
   * @deprecated The 'chargePointDevices' field is deprecated.
   *
   * Please use chargePointVariants instead of this query.
   *
   * - Marked as deprecated on 2023-05-10.
   * - Will be removed on 2024-01-01.
   */
  chargePointDevices?: Maybe<Array<Maybe<ChargePointDevicesType>>>;
  /** All charge points variants. */
  chargePointVariants?: Maybe<Array<Maybe<ChargePointVariantType>>>;
  /** All completed device dispatches 12 hours behind, in reverse time order. */
  completedDispatches?: Maybe<Array<Maybe<UpsideDispatchType>>>;
  /** Get contribution schemes. */
  contributionSchemes?: Maybe<Array<Maybe<ContributionSchemeType>>>;
  /** Aggregated cost of charge for an EV device. */
  costOfCharge?: Maybe<Array<Maybe<CostOfChargeType>>>;
  /** Returns the credit check status for the given account. */
  creditCheckStatus?: Maybe<CreditCheckOutputType>;
  /**
   * Get current and historical electricity products' price history for the given period.
   * Note: period should be less than or equal to last thirteen months.
   */
  currentAndHistoricalProductPriceHistory?: Maybe<
    Array<Maybe<CurrentAndHistoricalProductPricingHistoryOutput>>
  >;
  /** Get daily readings for a meter point. */
  dailyReadings?: Maybe<Array<Maybe<DailyReadingOutput>>>;
  /** Get a dashboard screen to render in the form of a json list of sections containing cards or grouped cards each with an order attribute. */
  dashboardScreen?: Maybe<Dashboard>;
  /** Get the default payment instruction for the account's main ledger. */
  defaultPaymentInstruction?: Maybe<PaymentInstructionType>;
  /** Get default raw score for a customer feedback form. */
  defaultRawScore?: Maybe<Scalars["Int"]>;
  /** Get deposit agreements for a given account. */
  depositAgreements?: Maybe<Array<Maybe<DepositAgreementOutput>>>;
  /** A list of devices registered to an account. */
  devices?: Maybe<Array<SmartFlexDeviceInterface>>;
  /** Return a referral reward scheme for the given account referral code. */
  domesticAccountReferralRewardScheme?: Maybe<ReferralSchemeType>;
  /** Return a joining reward scheme with the given code, if it's active. A joining reward can be a signup reward or a promotional reward. */
  domesticJoiningRewardScheme?: Maybe<ReferralSchemeType>;
  /** Return a signup referral reward scheme with the given code, if it's active. */
  domesticSignupRewardScheme?: Maybe<ReferralSchemeType>;
  /**
   * Get all active Electricity Facts Label (EFLs).
   *
   * By default, the query will return active EFLs for all service providers in both English (EN)
   * and Spanish (ES) versions.
   *
   * Query can be filtered by the given service provider, product id, and language.
   */
  efls?: Maybe<Array<Maybe<EflType>>>;
  /** All electric vehicle types and their details. */
  electricVehicles?: Maybe<Array<Maybe<ElectricVehicleType>>>;
  /** A list of device types that are eligible for registration. */
  eligibleDeviceTypes?: Maybe<Array<Maybe<KrakenFlexDeviceTypes>>>;
  /** Get details about an embedded network. */
  embeddedNetwork?: Maybe<EmbeddedNetworkType>;
  /** The current energy generation mix. */
  energyMixData?: Maybe<EnergyMixDataType>;
  /** The user specific Enode link session details. */
  enodeLinkSession?: Maybe<EnodeLinkSessionType>;
  /** Check if a given meter point ID is in suspicious meter point IDs list. */
  fraudMeterPointChecks?: Maybe<FraudMeterPointCheckType>;
  /** Check if a given ID and type have a financial risk level. */
  fraudRiskLevel?: Maybe<FinancialRiskLevelType>;
  /** Returns basic product information to finalize checkout. */
  getAccountCheckoutInformation?: Maybe<AccountCheckoutInformationOutput>;
  getAccountCreatedAt?: Maybe<AccountCreatedAtOutputType>;
  /** Returns basic account information. */
  getAccountCreatedByAffiliate?: Maybe<AccountCreatedByAffiliateOutput>;
  /** Get active domestic referral scheme for the given account number. */
  getAccountReferralScheme?: Maybe<ReferralSchemeType>;
  /** Get an account using a urn. */
  getAccountWithUrn?: Maybe<AccountType>;
  /** Get Real-Time LMPs for Load Zones and Trading Hubs. */
  getAllRealTimeLmpData?: Maybe<Array<Maybe<ErcotMarketData>>>;
  /** Get whether the percentage of wind power on the grid is sufficient for an energy discount to apply. */
  getFanClubDiscount?: Maybe<FanClubDiscountOutput>;
  /** Future wind power projections recieved from ERCOT. Updates every four hours. */
  getFanClubWindPowerProjections?: Maybe<FanClubWindPowerProjections>;
  /** Get Real-Time LMPs for Load Zones based on the given LMP value and sort option. */
  getLmpValue?: Maybe<Array<Maybe<ErcotMarketData>>>;
  /** Returns a quote request including all quoted products. */
  getQuote?: Maybe<GetQuoteOutputType>;
  getReferralCodeStatus?: Maybe<ReferralCodeStatusType>;
  /** Returns the referral competition stats. */
  getReferralCompetitionStats?: Maybe<Array<Maybe<ReferralCompetitionStats>>>;
  /** Get Real-Time LMPs for Load Zones based on the given settlement point. */
  getSettlementPoint?: Maybe<ErcotMarketData>;
  /** Returns stats for the top solar exporters along with the given account's export data. The results are ranked from the highest to lowest exporters. */
  getSolarContestStats?: Maybe<SolarContestStats>;
  /** Returns solar forecasted data. */
  getSolarForecastedData?: Maybe<AllSolarForecastedData>;
  /** Returns the system wide day ahead prices. */
  getSystemWideDayAheadPrices?: Maybe<AllSystemWidePriceData>;
  /**
   * @deprecated The 'getThermostatDetails' field is deprecated.
   *
   * Please use 'devices' query with `... on SmartFlexThermostatStatus` instead.
   *
   * - Marked as deprecated on 2024-10-08.
   * - Will be removed on 2025-02-01.
   */
  getThermostatDetails?: Maybe<GetThermostatDetailsOutput>;
  /** List Goods products given a market. */
  goodsProducts?: Maybe<GoodsProductConnectionTypeConnection>;
  /** List purchases for an account. */
  goodsPurchases?: Maybe<Array<Maybe<GoodsPurchase>>>;
  /** List quotes given an account number or retrieve a Goods quote given a quote code. */
  goodsQuotes?: Maybe<Array<Maybe<GoodsQuote>>>;
  /** Get the Ink conversation for a given account. */
  inkConversation: InkConversation;
  /** Get the content for a given message. */
  inkMessage: InkMessage;
  /** 15 minute interval readings for a meter point. */
  intervalReadings?: Maybe<Array<Maybe<IntervalReadingOutput>>>;
  /** Returns whether autopay is enabled for the given account. */
  isAutopayEnabled?: Maybe<AutoPayOutputType>;
  /** Check validity of a password reset token. */
  isPasswordResetTokenValid?: Maybe<Scalars["Boolean"]>;
  /** Returns whether a post code is quotable and has multiple TDSPs or load zones. */
  isPostCodeQuotable?: Maybe<IsQuotableOutputType>;
  isValidSubdomain?: Maybe<SubdomainOutput>;
  /** The current version of kraken. */
  krakenVersion?: Maybe<KrakenVersionType>;
  languagePreference?: Maybe<LanguagePreferenceChoices>;
  /** For on-supply accounts, return the latest requested MVO (Move out) date, or return None if there are no MVO events scheduled for the account. */
  latestMoveOutDate?: Maybe<LatestMoveOutDateOutputType>;
  /** Details associated with a LeaveSupplier process. */
  leaveSupplierProcess?: Maybe<LeaveSupplierProcessType>;
  legalDocuments?: Maybe<LegalDocumentsOutput>;
  /** Get all lifecycle processes associated with an account. */
  lifecycleProcesses?: Maybe<LifecycleProcessesType>;
  /** Get payment adequacy data with an up to date calculation. */
  livePaymentAdequacyCalculation?: Maybe<LivePaymentAdequacyCalculation>;
  /** Get all loyalty cards for the given account user. */
  loyaltyCards?: Maybe<Array<Maybe<LoyaltyCardType>>>;
  /** Get the Loyalty Point ledger entries for the passed user. */
  loyaltyPointLedgers?: Maybe<Array<Maybe<LoyaltyPointLedgerEntryType>>>;
  /** Metadata for a linked object. */
  metadata?: Maybe<Array<Maybe<Metadata>>>;
  /** Metadata for a linked object with key. */
  metadataForKey?: Maybe<Metadata>;
  node?: Maybe<Node>;
  /** To confirm whether a device is connected to OCPP. */
  ocppConnection?: Maybe<OcppConnectionType>;
  /** The user specific generated OCPP details. */
  ocppDetails?: Maybe<OcppDetailsType>;
  /** The help text of all configured password validators as plain-text or html. Defaults to plain-text. */
  passwordValidatorHelpTexts?: Maybe<Array<Maybe<Scalars["String"]>>>;
  /** All planned device dispatches 24 hours ahead, (usually) in time order. */
  plannedDispatches?: Maybe<Array<Maybe<UpsideDispatchType>>>;
  /** Get print batch details, including messages in the batch. */
  printBatch: PrintBatchType;
  /** Get all available electricity products. */
  products?: Maybe<Array<Maybe<ProductType>>>;
  /** Get all available electricity products with concise rates. */
  productsWithConciseApplicableRates?: Maybe<
    Array<Maybe<ProductWithRatesOutput>>
  >;
  /** Properties linked to the account now and in the future. */
  properties?: Maybe<Array<Maybe<PropertyType>>>;
  /** Search for properties that are already in Kraken and match the search term. */
  propertiesSearch: Array<PropertySearchResult>;
  /** A property with the given ID. Usually associated with supply points. */
  property?: Maybe<PropertyType>;
  /**
   * Search for properties that are already in Kraken and match the search term.
   * @deprecated The 'propertySearch' field is deprecated.
   *
   * This query is being deprecated in favour of `propertiesSearch`. The latter returns not only the matched properties but the level of confidence in the results through the `score` field.
   *
   * - Marked as deprecated on 2023-05-23.
   * - Will be removed on 2024-01-01.
   */
  propertySearch?: Maybe<Array<Maybe<PropertyType>>>;
  /** Auth details (e.g. OAuth 2.0 URI) for the provider (if available). */
  providerAuthDetails?: Maybe<ProviderAuthDetailsType>;
  /** Virtual key details (e.g. certificate public key) for the provider (if available). */
  providerVirtualKeyDetails?: Maybe<ProviderVirtualKeyDetailsType>;
  /** Get the complexity of a query. */
  queryComplexity?: Maybe<QueryComplexityOutputType>;
  /** Get the customer feedback survey question. */
  question?: Maybe<Scalars["String"]>;
  /**
   * Information about rate limit for viewer.
   * @deprecated The 'rateLimit' field is deprecated.
   *
   * `rateLimit` has been replaced by 'rateLimitInfo', which contains more detailed info about rate limit than the former one
   *
   * - Marked as deprecated on 2024-07-17.
   * - Will be removed on 2025-01-01.
   */
  rateLimit?: Maybe<RateLimitInformation>;
  /** Combined information about points-allowance rate limiting and request-specific rate limiting. */
  rateLimitInfo?: Maybe<CombinedRateLimitInformation>;
  /**
   * A device registered with KrakenFlex for a given account.
   * @deprecated The 'registeredKrakenflexDevice' field is deprecated.
   *
   * Please use 'devices' instead.
   *
   * - Marked as deprecated on 2024-04-23.
   * - Will be removed on 2024-11-01.
   */
  registeredKrakenflexDevice?: Maybe<KrakenFlexDeviceType>;
  /**
   * @deprecated The 'registeredThermostats' field is deprecated.
   *
   * Please use 'devices' query with `... on SmartFlexThermostatStatus` instead.
   *
   * - Marked as deprecated on 2024-10-08.
   * - Will be removed on 2025-02-01.
   */
  registeredThermostats?: Maybe<RegisteredThermostats>;
  /** A list of wizards for onboarding devices for an account and property. */
  smartFlexOnboardingWizards?: Maybe<Array<SmartFlexOnboardingWizard>>;
  /** Get the status of a background task. */
  taskResult?: Maybe<TaskResult>;
  tdspByPostalcode?: Maybe<Array<Maybe<PostcodeZoneMapping>>>;
  /** Get the active terms and conditions for a product. */
  termsAndConditionsForProduct?: Maybe<TermsAndConditionsType>;
  /**
   *  The currently authenticated third party.
   *
   *
   *
   * This field requires the `Authorization` header to be set.
   */
  thirdPartyViewer?: Maybe<ThirdPartyOrganizationType>;
  /** A list of vehicles available to the user. */
  userVehicles?: Maybe<Array<Maybe<UserVehiclesType>>>;
  validPhoneNumber?: Maybe<PhoneNumberOutput>;
  /**
   * Vehicle charging preference details.
   * @deprecated The 'vehicleChargingPreferences' field is deprecated.
   *
   * Please use 'devices.chargingPreferences' instead.
   *
   * - Marked as deprecated on 2024-04-23.
   * - Will be removed on 2024-11-01.
   */
  vehicleChargingPreferences?: Maybe<VehicleChargingPreferencesType>;
  /**
   *  The currently authenticated user.
   *
   *
   *
   * This field requires the `Authorization` header to be set.
   */
  viewer?: Maybe<AccountUserType>;
};

/**
 * Queries are the GraphQL equivalent of GET requests in REST. By convention, they do not mutate data. To learn about how to form Queries in graphql, see [GraphQL's documentation](https://graphql.org/learn/queries/).
 *
 * ⬅️ This interface will autocomplete, so just try typing what you want. You can also search these docs. Some queries will require authentication. Check the documentation or search `Authentication` for details.
 */
export type QueryAccountArgs = {
  accountNumber: Scalars["String"];
};

/**
 * Queries are the GraphQL equivalent of GET requests in REST. By convention, they do not mutate data. To learn about how to form Queries in graphql, see [GraphQL's documentation](https://graphql.org/learn/queries/).
 *
 * ⬅️ This interface will autocomplete, so just try typing what you want. You can also search these docs. Some queries will require authentication. Check the documentation or search `Authentication` for details.
 */
export type QueryAccountInfoPrechecksValidationArgs = {
  precheckData: PrechecksValidationInputType;
};

/**
 * Queries are the GraphQL equivalent of GET requests in REST. By convention, they do not mutate data. To learn about how to form Queries in graphql, see [GraphQL's documentation](https://graphql.org/learn/queries/).
 *
 * ⬅️ This interface will autocomplete, so just try typing what you want. You can also search these docs. Some queries will require authentication. Check the documentation or search `Authentication` for details.
 */
export type QueryAccountIoEligibilityArgs = {
  accountNumber: Scalars["String"];
};

/**
 * Queries are the GraphQL equivalent of GET requests in REST. By convention, they do not mutate data. To learn about how to form Queries in graphql, see [GraphQL's documentation](https://graphql.org/learn/queries/).
 *
 * ⬅️ This interface will autocomplete, so just try typing what you want. You can also search these docs. Some queries will require authentication. Check the documentation or search `Authentication` for details.
 */
export type QueryAccountReferenceArgs = {
  value?: InputMaybe<Scalars["String"]>;
};

/**
 * Queries are the GraphQL equivalent of GET requests in REST. By convention, they do not mutate data. To learn about how to form Queries in graphql, see [GraphQL's documentation](https://graphql.org/learn/queries/).
 *
 * ⬅️ This interface will autocomplete, so just try typing what you want. You can also search these docs. Some queries will require authentication. Check the documentation or search `Authentication` for details.
 */
export type QueryAccountsArgs = {
  phoneNumber?: InputMaybe<Scalars["String"]>;
  portfolioNumber?: InputMaybe<Scalars["String"]>;
};

/**
 * Queries are the GraphQL equivalent of GET requests in REST. By convention, they do not mutate data. To learn about how to form Queries in graphql, see [GraphQL's documentation](https://graphql.org/learn/queries/).
 *
 * ⬅️ This interface will autocomplete, so just try typing what you want. You can also search these docs. Some queries will require authentication. Check the documentation or search `Authentication` for details.
 */
export type QueryAccountsSearchArgs = {
  maxResults?: InputMaybe<Scalars["Int"]>;
  searchTerms?: InputMaybe<AccountSearchInputType>;
};

/**
 * Queries are the GraphQL equivalent of GET requests in REST. By convention, they do not mutate data. To learn about how to form Queries in graphql, see [GraphQL's documentation](https://graphql.org/learn/queries/).
 *
 * ⬅️ This interface will autocomplete, so just try typing what you want. You can also search these docs. Some queries will require authentication. Check the documentation or search `Authentication` for details.
 */
export type QueryActiveAffiliateReferralSchemeArgs = {
  subdomain: Scalars["String"];
};

/**
 * Queries are the GraphQL equivalent of GET requests in REST. By convention, they do not mutate data. To learn about how to form Queries in graphql, see [GraphQL's documentation](https://graphql.org/learn/queries/).
 *
 * ⬅️ This interface will autocomplete, so just try typing what you want. You can also search these docs. Some queries will require authentication. Check the documentation or search `Authentication` for details.
 */
export type QueryAddressLookupArgs = {
  address?: InputMaybe<Scalars["String"]>;
  address2?: InputMaybe<Scalars["String"]>;
  city?: InputMaybe<Scalars["String"]>;
  esiId?: InputMaybe<Scalars["String"]>;
  postCode?: InputMaybe<Scalars["String"]>;
  state?: InputMaybe<Scalars["String"]>;
};

/**
 * Queries are the GraphQL equivalent of GET requests in REST. By convention, they do not mutate data. To learn about how to form Queries in graphql, see [GraphQL's documentation](https://graphql.org/learn/queries/).
 *
 * ⬅️ This interface will autocomplete, so just try typing what you want. You can also search these docs. Some queries will require authentication. Check the documentation or search `Authentication` for details.
 */
export type QueryAddressLookupUsingEsSearchArgs = {
  search: Scalars["String"];
};

/**
 * Queries are the GraphQL equivalent of GET requests in REST. By convention, they do not mutate data. To learn about how to form Queries in graphql, see [GraphQL's documentation](https://graphql.org/learn/queries/).
 *
 * ⬅️ This interface will autocomplete, so just try typing what you want. You can also search these docs. Some queries will require authentication. Check the documentation or search `Authentication` for details.
 */
export type QueryAffiliateLinkArgs = {
  subdomain: Scalars["String"];
};

/**
 * Queries are the GraphQL equivalent of GET requests in REST. By convention, they do not mutate data. To learn about how to form Queries in graphql, see [GraphQL's documentation](https://graphql.org/learn/queries/).
 *
 * ⬅️ This interface will autocomplete, so just try typing what you want. You can also search these docs. Some queries will require authentication. Check the documentation or search `Authentication` for details.
 */
export type QueryAffiliateOrganisationArgs = {
  id: Scalars["Int"];
};

/**
 * Queries are the GraphQL equivalent of GET requests in REST. By convention, they do not mutate data. To learn about how to form Queries in graphql, see [GraphQL's documentation](https://graphql.org/learn/queries/).
 *
 * ⬅️ This interface will autocomplete, so just try typing what you want. You can also search these docs. Some queries will require authentication. Check the documentation or search `Authentication` for details.
 */
export type QueryApiBrownoutsArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  input?: InputMaybe<ApiBrownoutInput>;
  last?: InputMaybe<Scalars["Int"]>;
};

/**
 * Queries are the GraphQL equivalent of GET requests in REST. By convention, they do not mutate data. To learn about how to form Queries in graphql, see [GraphQL's documentation](https://graphql.org/learn/queries/).
 *
 * ⬅️ This interface will autocomplete, so just try typing what you want. You can also search these docs. Some queries will require authentication. Check the documentation or search `Authentication` for details.
 */
export type QueryApiExceptionsArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  input?: InputMaybe<ApiExceptionQueryInput>;
  last?: InputMaybe<Scalars["Int"]>;
};

/**
 * Queries are the GraphQL equivalent of GET requests in REST. By convention, they do not mutate data. To learn about how to form Queries in graphql, see [GraphQL's documentation](https://graphql.org/learn/queries/).
 *
 * ⬅️ This interface will autocomplete, so just try typing what you want. You can also search these docs. Some queries will require authentication. Check the documentation or search `Authentication` for details.
 */
export type QueryAvailableProductSwitchDatesArgs = {
  agreementId: Scalars["Int"];
};

/**
 * Queries are the GraphQL equivalent of GET requests in REST. By convention, they do not mutate data. To learn about how to form Queries in graphql, see [GraphQL's documentation](https://graphql.org/learn/queries/).
 *
 * ⬅️ This interface will autocomplete, so just try typing what you want. You can also search these docs. Some queries will require authentication. Check the documentation or search `Authentication` for details.
 */
export type QueryAvailableServiceDatesArgs = {
  esiId: Scalars["String"];
};

/**
 * Queries are the GraphQL equivalent of GET requests in REST. By convention, they do not mutate data. To learn about how to form Queries in graphql, see [GraphQL's documentation](https://graphql.org/learn/queries/).
 *
 * ⬅️ This interface will autocomplete, so just try typing what you want. You can also search these docs. Some queries will require authentication. Check the documentation or search `Authentication` for details.
 */
export type QueryBackendScreenArgs = {
  maxVersionSupported?: InputMaybe<Scalars["Int"]>;
  params?: InputMaybe<Array<InputMaybe<BackendScreenParamInputType>>>;
  screenId: Scalars["ID"];
};

/**
 * Queries are the GraphQL equivalent of GET requests in REST. By convention, they do not mutate data. To learn about how to form Queries in graphql, see [GraphQL's documentation](https://graphql.org/learn/queries/).
 *
 * ⬅️ This interface will autocomplete, so just try typing what you want. You can also search these docs. Some queries will require authentication. Check the documentation or search `Authentication` for details.
 */
export type QueryBatteryDeviceArgs = {
  accountNumber: Scalars["String"];
  propertyId: Scalars["Int"];
};

/**
 * Queries are the GraphQL equivalent of GET requests in REST. By convention, they do not mutate data. To learn about how to form Queries in graphql, see [GraphQL's documentation](https://graphql.org/learn/queries/).
 *
 * ⬅️ This interface will autocomplete, so just try typing what you want. You can also search these docs. Some queries will require authentication. Check the documentation or search `Authentication` for details.
 */
export type QueryBatteryVariantsArgs = {
  make?: InputMaybe<Scalars["String"]>;
};

/**
 * Queries are the GraphQL equivalent of GET requests in REST. By convention, they do not mutate data. To learn about how to form Queries in graphql, see [GraphQL's documentation](https://graphql.org/learn/queries/).
 *
 * ⬅️ This interface will autocomplete, so just try typing what you want. You can also search these docs. Some queries will require authentication. Check the documentation or search `Authentication` for details.
 */
export type QueryBusinessArgs = {
  id: Scalars["ID"];
};

/**
 * Queries are the GraphQL equivalent of GET requests in REST. By convention, they do not mutate data. To learn about how to form Queries in graphql, see [GraphQL's documentation](https://graphql.org/learn/queries/).
 *
 * ⬅️ This interface will autocomplete, so just try typing what you want. You can also search these docs. Some queries will require authentication. Check the documentation or search `Authentication` for details.
 */
export type QueryCompletedDispatchesArgs = {
  accountNumber: Scalars["String"];
};

/**
 * Queries are the GraphQL equivalent of GET requests in REST. By convention, they do not mutate data. To learn about how to form Queries in graphql, see [GraphQL's documentation](https://graphql.org/learn/queries/).
 *
 * ⬅️ This interface will autocomplete, so just try typing what you want. You can also search these docs. Some queries will require authentication. Check the documentation or search `Authentication` for details.
 */
export type QueryCostOfChargeArgs = {
  accountNumber: Scalars["String"];
  frequency: DataFrequency;
  startDate?: InputMaybe<Scalars["Date"]>;
};

/**
 * Queries are the GraphQL equivalent of GET requests in REST. By convention, they do not mutate data. To learn about how to form Queries in graphql, see [GraphQL's documentation](https://graphql.org/learn/queries/).
 *
 * ⬅️ This interface will autocomplete, so just try typing what you want. You can also search these docs. Some queries will require authentication. Check the documentation or search `Authentication` for details.
 */
export type QueryCreditCheckStatusArgs = {
  accountNumber: Scalars["String"];
};

/**
 * Queries are the GraphQL equivalent of GET requests in REST. By convention, they do not mutate data. To learn about how to form Queries in graphql, see [GraphQL's documentation](https://graphql.org/learn/queries/).
 *
 * ⬅️ This interface will autocomplete, so just try typing what you want. You can also search these docs. Some queries will require authentication. Check the documentation or search `Authentication` for details.
 */
export type QueryCurrentAndHistoricalProductPriceHistoryArgs = {
  filterBy: CurrentAndHistoricalProductPricingHistoryInput;
};

/**
 * Queries are the GraphQL equivalent of GET requests in REST. By convention, they do not mutate data. To learn about how to form Queries in graphql, see [GraphQL's documentation](https://graphql.org/learn/queries/).
 *
 * ⬅️ This interface will autocomplete, so just try typing what you want. You can also search these docs. Some queries will require authentication. Check the documentation or search `Authentication` for details.
 */
export type QueryDailyReadingsArgs = {
  input: DailyReadingInput;
};

/**
 * Queries are the GraphQL equivalent of GET requests in REST. By convention, they do not mutate data. To learn about how to form Queries in graphql, see [GraphQL's documentation](https://graphql.org/learn/queries/).
 *
 * ⬅️ This interface will autocomplete, so just try typing what you want. You can also search these docs. Some queries will require authentication. Check the documentation or search `Authentication` for details.
 */
export type QueryDashboardScreenArgs = {
  accountNumber: Scalars["String"];
  dashboardId: Scalars["ID"];
  ledgerId?: InputMaybe<Scalars["String"]>;
  maxVersionSupported?: Scalars["Int"];
  params?: InputMaybe<Array<InputMaybe<BackendScreenParamInputType>>>;
  propertyId?: InputMaybe<Scalars["String"]>;
};

/**
 * Queries are the GraphQL equivalent of GET requests in REST. By convention, they do not mutate data. To learn about how to form Queries in graphql, see [GraphQL's documentation](https://graphql.org/learn/queries/).
 *
 * ⬅️ This interface will autocomplete, so just try typing what you want. You can also search these docs. Some queries will require authentication. Check the documentation or search `Authentication` for details.
 */
export type QueryDefaultPaymentInstructionArgs = {
  accountNumber: Scalars["String"];
  instructionType?: InputMaybe<PaymentType>;
};

/**
 * Queries are the GraphQL equivalent of GET requests in REST. By convention, they do not mutate data. To learn about how to form Queries in graphql, see [GraphQL's documentation](https://graphql.org/learn/queries/).
 *
 * ⬅️ This interface will autocomplete, so just try typing what you want. You can also search these docs. Some queries will require authentication. Check the documentation or search `Authentication` for details.
 */
export type QueryDefaultRawScoreArgs = {
  formId: Scalars["Int"];
};

/**
 * Queries are the GraphQL equivalent of GET requests in REST. By convention, they do not mutate data. To learn about how to form Queries in graphql, see [GraphQL's documentation](https://graphql.org/learn/queries/).
 *
 * ⬅️ This interface will autocomplete, so just try typing what you want. You can also search these docs. Some queries will require authentication. Check the documentation or search `Authentication` for details.
 */
export type QueryDepositAgreementsArgs = {
  accountNumber: Scalars["String"];
};

/**
 * Queries are the GraphQL equivalent of GET requests in REST. By convention, they do not mutate data. To learn about how to form Queries in graphql, see [GraphQL's documentation](https://graphql.org/learn/queries/).
 *
 * ⬅️ This interface will autocomplete, so just try typing what you want. You can also search these docs. Some queries will require authentication. Check the documentation or search `Authentication` for details.
 */
export type QueryDevicesArgs = {
  accountNumber: Scalars["String"];
  deviceId?: InputMaybe<Scalars["String"]>;
  propertyId?: InputMaybe<Scalars["ID"]>;
};

/**
 * Queries are the GraphQL equivalent of GET requests in REST. By convention, they do not mutate data. To learn about how to form Queries in graphql, see [GraphQL's documentation](https://graphql.org/learn/queries/).
 *
 * ⬅️ This interface will autocomplete, so just try typing what you want. You can also search these docs. Some queries will require authentication. Check the documentation or search `Authentication` for details.
 */
export type QueryDomesticAccountReferralRewardSchemeArgs = {
  code: Scalars["String"];
};

/**
 * Queries are the GraphQL equivalent of GET requests in REST. By convention, they do not mutate data. To learn about how to form Queries in graphql, see [GraphQL's documentation](https://graphql.org/learn/queries/).
 *
 * ⬅️ This interface will autocomplete, so just try typing what you want. You can also search these docs. Some queries will require authentication. Check the documentation or search `Authentication` for details.
 */
export type QueryDomesticJoiningRewardSchemeArgs = {
  code: Scalars["String"];
};

/**
 * Queries are the GraphQL equivalent of GET requests in REST. By convention, they do not mutate data. To learn about how to form Queries in graphql, see [GraphQL's documentation](https://graphql.org/learn/queries/).
 *
 * ⬅️ This interface will autocomplete, so just try typing what you want. You can also search these docs. Some queries will require authentication. Check the documentation or search `Authentication` for details.
 */
export type QueryDomesticSignupRewardSchemeArgs = {
  code: Scalars["String"];
};

/**
 * Queries are the GraphQL equivalent of GET requests in REST. By convention, they do not mutate data. To learn about how to form Queries in graphql, see [GraphQL's documentation](https://graphql.org/learn/queries/).
 *
 * ⬅️ This interface will autocomplete, so just try typing what you want. You can also search these docs. Some queries will require authentication. Check the documentation or search `Authentication` for details.
 */
export type QueryEflsArgs = {
  language?: InputMaybe<EflLanguage>;
  loadZone?: InputMaybe<LoadZone>;
  productId?: InputMaybe<Scalars["ID"]>;
  serviceProvider?: InputMaybe<ServiceProvider>;
};

/**
 * Queries are the GraphQL equivalent of GET requests in REST. By convention, they do not mutate data. To learn about how to form Queries in graphql, see [GraphQL's documentation](https://graphql.org/learn/queries/).
 *
 * ⬅️ This interface will autocomplete, so just try typing what you want. You can also search these docs. Some queries will require authentication. Check the documentation or search `Authentication` for details.
 */
export type QueryElectricVehiclesArgs = {
  isIntegrationLive?: InputMaybe<Scalars["Boolean"]>;
  make?: InputMaybe<Scalars["String"]>;
  supportedProvider?: InputMaybe<ProviderChoices>;
};

/**
 * Queries are the GraphQL equivalent of GET requests in REST. By convention, they do not mutate data. To learn about how to form Queries in graphql, see [GraphQL's documentation](https://graphql.org/learn/queries/).
 *
 * ⬅️ This interface will autocomplete, so just try typing what you want. You can also search these docs. Some queries will require authentication. Check the documentation or search `Authentication` for details.
 */
export type QueryEligibleDeviceTypesArgs = {
  accountNumber: Scalars["String"];
};

/**
 * Queries are the GraphQL equivalent of GET requests in REST. By convention, they do not mutate data. To learn about how to form Queries in graphql, see [GraphQL's documentation](https://graphql.org/learn/queries/).
 *
 * ⬅️ This interface will autocomplete, so just try typing what you want. You can also search these docs. Some queries will require authentication. Check the documentation or search `Authentication` for details.
 */
export type QueryEmbeddedNetworkArgs = {
  id: Scalars["ID"];
};

/**
 * Queries are the GraphQL equivalent of GET requests in REST. By convention, they do not mutate data. To learn about how to form Queries in graphql, see [GraphQL's documentation](https://graphql.org/learn/queries/).
 *
 * ⬅️ This interface will autocomplete, so just try typing what you want. You can also search these docs. Some queries will require authentication. Check the documentation or search `Authentication` for details.
 */
export type QueryEnodeLinkSessionArgs = {
  accountNumber?: InputMaybe<Scalars["String"]>;
  vendor?: InputMaybe<EnodeVendors>;
};

/**
 * Queries are the GraphQL equivalent of GET requests in REST. By convention, they do not mutate data. To learn about how to form Queries in graphql, see [GraphQL's documentation](https://graphql.org/learn/queries/).
 *
 * ⬅️ This interface will autocomplete, so just try typing what you want. You can also search these docs. Some queries will require authentication. Check the documentation or search `Authentication` for details.
 */
export type QueryFraudMeterPointChecksArgs = {
  meterPointId: Scalars["String"];
};

/**
 * Queries are the GraphQL equivalent of GET requests in REST. By convention, they do not mutate data. To learn about how to form Queries in graphql, see [GraphQL's documentation](https://graphql.org/learn/queries/).
 *
 * ⬅️ This interface will autocomplete, so just try typing what you want. You can also search these docs. Some queries will require authentication. Check the documentation or search `Authentication` for details.
 */
export type QueryFraudRiskLevelArgs = {
  identifierType: Scalars["String"];
  identifierValue: Scalars["String"];
};

/**
 * Queries are the GraphQL equivalent of GET requests in REST. By convention, they do not mutate data. To learn about how to form Queries in graphql, see [GraphQL's documentation](https://graphql.org/learn/queries/).
 *
 * ⬅️ This interface will autocomplete, so just try typing what you want. You can also search these docs. Some queries will require authentication. Check the documentation or search `Authentication` for details.
 */
export type QueryGetAccountCheckoutInformationArgs = {
  input: AccountCheckoutInformationInput;
};

/**
 * Queries are the GraphQL equivalent of GET requests in REST. By convention, they do not mutate data. To learn about how to form Queries in graphql, see [GraphQL's documentation](https://graphql.org/learn/queries/).
 *
 * ⬅️ This interface will autocomplete, so just try typing what you want. You can also search these docs. Some queries will require authentication. Check the documentation or search `Authentication` for details.
 */
export type QueryGetAccountCreatedAtArgs = {
  accountNumber: Scalars["String"];
};

/**
 * Queries are the GraphQL equivalent of GET requests in REST. By convention, they do not mutate data. To learn about how to form Queries in graphql, see [GraphQL's documentation](https://graphql.org/learn/queries/).
 *
 * ⬅️ This interface will autocomplete, so just try typing what you want. You can also search these docs. Some queries will require authentication. Check the documentation or search `Authentication` for details.
 */
export type QueryGetAccountCreatedByAffiliateArgs = {
  input: AccountCreatedByAffiliateInput;
};

/**
 * Queries are the GraphQL equivalent of GET requests in REST. By convention, they do not mutate data. To learn about how to form Queries in graphql, see [GraphQL's documentation](https://graphql.org/learn/queries/).
 *
 * ⬅️ This interface will autocomplete, so just try typing what you want. You can also search these docs. Some queries will require authentication. Check the documentation or search `Authentication` for details.
 */
export type QueryGetAccountReferralSchemeArgs = {
  accountNumber: Scalars["String"];
};

/**
 * Queries are the GraphQL equivalent of GET requests in REST. By convention, they do not mutate data. To learn about how to form Queries in graphql, see [GraphQL's documentation](https://graphql.org/learn/queries/).
 *
 * ⬅️ This interface will autocomplete, so just try typing what you want. You can also search these docs. Some queries will require authentication. Check the documentation or search `Authentication` for details.
 */
export type QueryGetAccountWithUrnArgs = {
  urn: Scalars["String"];
};

/**
 * Queries are the GraphQL equivalent of GET requests in REST. By convention, they do not mutate data. To learn about how to form Queries in graphql, see [GraphQL's documentation](https://graphql.org/learn/queries/).
 *
 * ⬅️ This interface will autocomplete, so just try typing what you want. You can also search these docs. Some queries will require authentication. Check the documentation or search `Authentication` for details.
 */
export type QueryGetFanClubWindPowerProjectionsArgs = {
  hoursIntoFuture?: InputMaybe<Scalars["Int"]>;
};

/**
 * Queries are the GraphQL equivalent of GET requests in REST. By convention, they do not mutate data. To learn about how to form Queries in graphql, see [GraphQL's documentation](https://graphql.org/learn/queries/).
 *
 * ⬅️ This interface will autocomplete, so just try typing what you want. You can also search these docs. Some queries will require authentication. Check the documentation or search `Authentication` for details.
 */
export type QueryGetLmpValueArgs = {
  sort?: InputMaybe<SortByOptions>;
  value?: InputMaybe<Scalars["Float"]>;
};

/**
 * Queries are the GraphQL equivalent of GET requests in REST. By convention, they do not mutate data. To learn about how to form Queries in graphql, see [GraphQL's documentation](https://graphql.org/learn/queries/).
 *
 * ⬅️ This interface will autocomplete, so just try typing what you want. You can also search these docs. Some queries will require authentication. Check the documentation or search `Authentication` for details.
 */
export type QueryGetQuoteArgs = {
  code: Scalars["String"];
};

/**
 * Queries are the GraphQL equivalent of GET requests in REST. By convention, they do not mutate data. To learn about how to form Queries in graphql, see [GraphQL's documentation](https://graphql.org/learn/queries/).
 *
 * ⬅️ This interface will autocomplete, so just try typing what you want. You can also search these docs. Some queries will require authentication. Check the documentation or search `Authentication` for details.
 */
export type QueryGetReferralCodeStatusArgs = {
  code: Scalars["String"];
};

/**
 * Queries are the GraphQL equivalent of GET requests in REST. By convention, they do not mutate data. To learn about how to form Queries in graphql, see [GraphQL's documentation](https://graphql.org/learn/queries/).
 *
 * ⬅️ This interface will autocomplete, so just try typing what you want. You can also search these docs. Some queries will require authentication. Check the documentation or search `Authentication` for details.
 */
export type QueryGetReferralCompetitionStatsArgs = {
  accountNumber: Scalars["String"];
};

/**
 * Queries are the GraphQL equivalent of GET requests in REST. By convention, they do not mutate data. To learn about how to form Queries in graphql, see [GraphQL's documentation](https://graphql.org/learn/queries/).
 *
 * ⬅️ This interface will autocomplete, so just try typing what you want. You can also search these docs. Some queries will require authentication. Check the documentation or search `Authentication` for details.
 */
export type QueryGetSettlementPointArgs = {
  settlementPoint: SettlementPointOptions;
};

/**
 * Queries are the GraphQL equivalent of GET requests in REST. By convention, they do not mutate data. To learn about how to form Queries in graphql, see [GraphQL's documentation](https://graphql.org/learn/queries/).
 *
 * ⬅️ This interface will autocomplete, so just try typing what you want. You can also search these docs. Some queries will require authentication. Check the documentation or search `Authentication` for details.
 */
export type QueryGetSolarContestStatsArgs = {
  accountNumber: Scalars["String"];
};

/**
 * Queries are the GraphQL equivalent of GET requests in REST. By convention, they do not mutate data. To learn about how to form Queries in graphql, see [GraphQL's documentation](https://graphql.org/learn/queries/).
 *
 * ⬅️ This interface will autocomplete, so just try typing what you want. You can also search these docs. Some queries will require authentication. Check the documentation or search `Authentication` for details.
 */
export type QueryGetThermostatDetailsArgs = {
  accountNumber: Scalars["String"];
};

/**
 * Queries are the GraphQL equivalent of GET requests in REST. By convention, they do not mutate data. To learn about how to form Queries in graphql, see [GraphQL's documentation](https://graphql.org/learn/queries/).
 *
 * ⬅️ This interface will autocomplete, so just try typing what you want. You can also search these docs. Some queries will require authentication. Check the documentation or search `Authentication` for details.
 */
export type QueryGoodsProductsArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  code?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  marketName: Scalars["String"];
  productType?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
};

/**
 * Queries are the GraphQL equivalent of GET requests in REST. By convention, they do not mutate data. To learn about how to form Queries in graphql, see [GraphQL's documentation](https://graphql.org/learn/queries/).
 *
 * ⬅️ This interface will autocomplete, so just try typing what you want. You can also search these docs. Some queries will require authentication. Check the documentation or search `Authentication` for details.
 */
export type QueryGoodsPurchasesArgs = {
  accountNumber: Scalars["String"];
};

/**
 * Queries are the GraphQL equivalent of GET requests in REST. By convention, they do not mutate data. To learn about how to form Queries in graphql, see [GraphQL's documentation](https://graphql.org/learn/queries/).
 *
 * ⬅️ This interface will autocomplete, so just try typing what you want. You can also search these docs. Some queries will require authentication. Check the documentation or search `Authentication` for details.
 */
export type QueryGoodsQuotesArgs = {
  accountNumber?: InputMaybe<Scalars["String"]>;
  quoteCode?: InputMaybe<Scalars["String"]>;
};

/**
 * Queries are the GraphQL equivalent of GET requests in REST. By convention, they do not mutate data. To learn about how to form Queries in graphql, see [GraphQL's documentation](https://graphql.org/learn/queries/).
 *
 * ⬅️ This interface will autocomplete, so just try typing what you want. You can also search these docs. Some queries will require authentication. Check the documentation or search `Authentication` for details.
 */
export type QueryInkConversationArgs = {
  accountNumber?: InputMaybe<Scalars["String"]>;
  conversationRelayId?: InputMaybe<Scalars["String"]>;
};

/**
 * Queries are the GraphQL equivalent of GET requests in REST. By convention, they do not mutate data. To learn about how to form Queries in graphql, see [GraphQL's documentation](https://graphql.org/learn/queries/).
 *
 * ⬅️ This interface will autocomplete, so just try typing what you want. You can also search these docs. Some queries will require authentication. Check the documentation or search `Authentication` for details.
 */
export type QueryInkMessageArgs = {
  messageRelayId: Scalars["String"];
};

/**
 * Queries are the GraphQL equivalent of GET requests in REST. By convention, they do not mutate data. To learn about how to form Queries in graphql, see [GraphQL's documentation](https://graphql.org/learn/queries/).
 *
 * ⬅️ This interface will autocomplete, so just try typing what you want. You can also search these docs. Some queries will require authentication. Check the documentation or search `Authentication` for details.
 */
export type QueryIntervalReadingsArgs = {
  input: IntervalReadingInput;
};

/**
 * Queries are the GraphQL equivalent of GET requests in REST. By convention, they do not mutate data. To learn about how to form Queries in graphql, see [GraphQL's documentation](https://graphql.org/learn/queries/).
 *
 * ⬅️ This interface will autocomplete, so just try typing what you want. You can also search these docs. Some queries will require authentication. Check the documentation or search `Authentication` for details.
 */
export type QueryIsAutopayEnabledArgs = {
  accountNumber: Scalars["String"];
  considerFutureSchedule?: InputMaybe<Scalars["Boolean"]>;
};

/**
 * Queries are the GraphQL equivalent of GET requests in REST. By convention, they do not mutate data. To learn about how to form Queries in graphql, see [GraphQL's documentation](https://graphql.org/learn/queries/).
 *
 * ⬅️ This interface will autocomplete, so just try typing what you want. You can also search these docs. Some queries will require authentication. Check the documentation or search `Authentication` for details.
 */
export type QueryIsPasswordResetTokenValidArgs = {
  token: Scalars["String"];
  userId: Scalars["String"];
};

/**
 * Queries are the GraphQL equivalent of GET requests in REST. By convention, they do not mutate data. To learn about how to form Queries in graphql, see [GraphQL's documentation](https://graphql.org/learn/queries/).
 *
 * ⬅️ This interface will autocomplete, so just try typing what you want. You can also search these docs. Some queries will require authentication. Check the documentation or search `Authentication` for details.
 */
export type QueryIsPostCodeQuotableArgs = {
  postcode: Scalars["String"];
};

/**
 * Queries are the GraphQL equivalent of GET requests in REST. By convention, they do not mutate data. To learn about how to form Queries in graphql, see [GraphQL's documentation](https://graphql.org/learn/queries/).
 *
 * ⬅️ This interface will autocomplete, so just try typing what you want. You can also search these docs. Some queries will require authentication. Check the documentation or search `Authentication` for details.
 */
export type QueryIsValidSubdomainArgs = {
  subdomain: Scalars["String"];
};

/**
 * Queries are the GraphQL equivalent of GET requests in REST. By convention, they do not mutate data. To learn about how to form Queries in graphql, see [GraphQL's documentation](https://graphql.org/learn/queries/).
 *
 * ⬅️ This interface will autocomplete, so just try typing what you want. You can also search these docs. Some queries will require authentication. Check the documentation or search `Authentication` for details.
 */
export type QueryLanguagePreferenceArgs = {
  accountNumber: Scalars["String"];
};

/**
 * Queries are the GraphQL equivalent of GET requests in REST. By convention, they do not mutate data. To learn about how to form Queries in graphql, see [GraphQL's documentation](https://graphql.org/learn/queries/).
 *
 * ⬅️ This interface will autocomplete, so just try typing what you want. You can also search these docs. Some queries will require authentication. Check the documentation or search `Authentication` for details.
 */
export type QueryLatestMoveOutDateArgs = {
  accountNumber: Scalars["String"];
};

/**
 * Queries are the GraphQL equivalent of GET requests in REST. By convention, they do not mutate data. To learn about how to form Queries in graphql, see [GraphQL's documentation](https://graphql.org/learn/queries/).
 *
 * ⬅️ This interface will autocomplete, so just try typing what you want. You can also search these docs. Some queries will require authentication. Check the documentation or search `Authentication` for details.
 */
export type QueryLeaveSupplierProcessArgs = {
  leaveSupplierProcessId: Scalars["ID"];
};

/**
 * Queries are the GraphQL equivalent of GET requests in REST. By convention, they do not mutate data. To learn about how to form Queries in graphql, see [GraphQL's documentation](https://graphql.org/learn/queries/).
 *
 * ⬅️ This interface will autocomplete, so just try typing what you want. You can also search these docs. Some queries will require authentication. Check the documentation or search `Authentication` for details.
 */
export type QueryLegalDocumentsArgs = {
  language: LegalDocumentLanguageOption;
};

/**
 * Queries are the GraphQL equivalent of GET requests in REST. By convention, they do not mutate data. To learn about how to form Queries in graphql, see [GraphQL's documentation](https://graphql.org/learn/queries/).
 *
 * ⬅️ This interface will autocomplete, so just try typing what you want. You can also search these docs. Some queries will require authentication. Check the documentation or search `Authentication` for details.
 */
export type QueryLifecycleProcessesArgs = {
  accountNumber: Scalars["String"];
  onlyActive?: InputMaybe<Scalars["Boolean"]>;
};

/**
 * Queries are the GraphQL equivalent of GET requests in REST. By convention, they do not mutate data. To learn about how to form Queries in graphql, see [GraphQL's documentation](https://graphql.org/learn/queries/).
 *
 * ⬅️ This interface will autocomplete, so just try typing what you want. You can also search these docs. Some queries will require authentication. Check the documentation or search `Authentication` for details.
 */
export type QueryLivePaymentAdequacyCalculationArgs = {
  ledgerNumber: Scalars["String"];
};

/**
 * Queries are the GraphQL equivalent of GET requests in REST. By convention, they do not mutate data. To learn about how to form Queries in graphql, see [GraphQL's documentation](https://graphql.org/learn/queries/).
 *
 * ⬅️ This interface will autocomplete, so just try typing what you want. You can also search these docs. Some queries will require authentication. Check the documentation or search `Authentication` for details.
 */
export type QueryLoyaltyCardsArgs = {
  accountUserId: Scalars["String"];
};

/**
 * Queries are the GraphQL equivalent of GET requests in REST. By convention, they do not mutate data. To learn about how to form Queries in graphql, see [GraphQL's documentation](https://graphql.org/learn/queries/).
 *
 * ⬅️ This interface will autocomplete, so just try typing what you want. You can also search these docs. Some queries will require authentication. Check the documentation or search `Authentication` for details.
 */
export type QueryMetadataArgs = {
  identifier: Scalars["String"];
  linkedObjectType?: InputMaybe<LinkedObjectType>;
};

/**
 * Queries are the GraphQL equivalent of GET requests in REST. By convention, they do not mutate data. To learn about how to form Queries in graphql, see [GraphQL's documentation](https://graphql.org/learn/queries/).
 *
 * ⬅️ This interface will autocomplete, so just try typing what you want. You can also search these docs. Some queries will require authentication. Check the documentation or search `Authentication` for details.
 */
export type QueryMetadataForKeyArgs = {
  identifier: Scalars["String"];
  key: Scalars["String"];
  linkedObjectType?: InputMaybe<LinkedObjectType>;
};

/**
 * Queries are the GraphQL equivalent of GET requests in REST. By convention, they do not mutate data. To learn about how to form Queries in graphql, see [GraphQL's documentation](https://graphql.org/learn/queries/).
 *
 * ⬅️ This interface will autocomplete, so just try typing what you want. You can also search these docs. Some queries will require authentication. Check the documentation or search `Authentication` for details.
 */
export type QueryNodeArgs = {
  id: Scalars["ID"];
};

/**
 * Queries are the GraphQL equivalent of GET requests in REST. By convention, they do not mutate data. To learn about how to form Queries in graphql, see [GraphQL's documentation](https://graphql.org/learn/queries/).
 *
 * ⬅️ This interface will autocomplete, so just try typing what you want. You can also search these docs. Some queries will require authentication. Check the documentation or search `Authentication` for details.
 */
export type QueryOcppConnectionArgs = {
  accountNumber: Scalars["String"];
};

/**
 * Queries are the GraphQL equivalent of GET requests in REST. By convention, they do not mutate data. To learn about how to form Queries in graphql, see [GraphQL's documentation](https://graphql.org/learn/queries/).
 *
 * ⬅️ This interface will autocomplete, so just try typing what you want. You can also search these docs. Some queries will require authentication. Check the documentation or search `Authentication` for details.
 */
export type QueryOcppDetailsArgs = {
  accountNumber: Scalars["String"];
};

/**
 * Queries are the GraphQL equivalent of GET requests in REST. By convention, they do not mutate data. To learn about how to form Queries in graphql, see [GraphQL's documentation](https://graphql.org/learn/queries/).
 *
 * ⬅️ This interface will autocomplete, so just try typing what you want. You can also search these docs. Some queries will require authentication. Check the documentation or search `Authentication` for details.
 */
export type QueryPasswordValidatorHelpTextsArgs = {
  asHtml?: InputMaybe<Scalars["Boolean"]>;
};

/**
 * Queries are the GraphQL equivalent of GET requests in REST. By convention, they do not mutate data. To learn about how to form Queries in graphql, see [GraphQL's documentation](https://graphql.org/learn/queries/).
 *
 * ⬅️ This interface will autocomplete, so just try typing what you want. You can also search these docs. Some queries will require authentication. Check the documentation or search `Authentication` for details.
 */
export type QueryPlannedDispatchesArgs = {
  accountNumber: Scalars["String"];
};

/**
 * Queries are the GraphQL equivalent of GET requests in REST. By convention, they do not mutate data. To learn about how to form Queries in graphql, see [GraphQL's documentation](https://graphql.org/learn/queries/).
 *
 * ⬅️ This interface will autocomplete, so just try typing what you want. You can also search these docs. Some queries will require authentication. Check the documentation or search `Authentication` for details.
 */
export type QueryPrintBatchArgs = {
  batchId?: InputMaybe<Scalars["ID"]>;
};

/**
 * Queries are the GraphQL equivalent of GET requests in REST. By convention, they do not mutate data. To learn about how to form Queries in graphql, see [GraphQL's documentation](https://graphql.org/learn/queries/).
 *
 * ⬅️ This interface will autocomplete, so just try typing what you want. You can also search these docs. Some queries will require authentication. Check the documentation or search `Authentication` for details.
 */
export type QueryProductsArgs = {
  availableAt?: InputMaybe<Scalars["DateTime"]>;
  basedOnTimeOfUse?: InputMaybe<Scalars["Boolean"]>;
  displayName?: InputMaybe<Scalars["String"]>;
  id?: InputMaybe<Scalars["ID"]>;
  prepay?: InputMaybe<Scalars["Boolean"]>;
};

/**
 * Queries are the GraphQL equivalent of GET requests in REST. By convention, they do not mutate data. To learn about how to form Queries in graphql, see [GraphQL's documentation](https://graphql.org/learn/queries/).
 *
 * ⬅️ This interface will autocomplete, so just try typing what you want. You can also search these docs. Some queries will require authentication. Check the documentation or search `Authentication` for details.
 */
export type QueryProductsWithConciseApplicableRatesArgs = {
  filterProductsBy?: InputMaybe<ProductInput>;
};

/**
 * Queries are the GraphQL equivalent of GET requests in REST. By convention, they do not mutate data. To learn about how to form Queries in graphql, see [GraphQL's documentation](https://graphql.org/learn/queries/).
 *
 * ⬅️ This interface will autocomplete, so just try typing what you want. You can also search these docs. Some queries will require authentication. Check the documentation or search `Authentication` for details.
 */
export type QueryPropertiesArgs = {
  accountNumber: Scalars["String"];
  activeFrom?: InputMaybe<Scalars["DateTime"]>;
};

/**
 * Queries are the GraphQL equivalent of GET requests in REST. By convention, they do not mutate data. To learn about how to form Queries in graphql, see [GraphQL's documentation](https://graphql.org/learn/queries/).
 *
 * ⬅️ This interface will autocomplete, so just try typing what you want. You can also search these docs. Some queries will require authentication. Check the documentation or search `Authentication` for details.
 */
export type QueryPropertiesSearchArgs = {
  searchTerm: Scalars["String"];
};

/**
 * Queries are the GraphQL equivalent of GET requests in REST. By convention, they do not mutate data. To learn about how to form Queries in graphql, see [GraphQL's documentation](https://graphql.org/learn/queries/).
 *
 * ⬅️ This interface will autocomplete, so just try typing what you want. You can also search these docs. Some queries will require authentication. Check the documentation or search `Authentication` for details.
 */
export type QueryPropertyArgs = {
  id: Scalars["ID"];
};

/**
 * Queries are the GraphQL equivalent of GET requests in REST. By convention, they do not mutate data. To learn about how to form Queries in graphql, see [GraphQL's documentation](https://graphql.org/learn/queries/).
 *
 * ⬅️ This interface will autocomplete, so just try typing what you want. You can also search these docs. Some queries will require authentication. Check the documentation or search `Authentication` for details.
 */
export type QueryPropertySearchArgs = {
  searchTerm: Scalars["String"];
};

/**
 * Queries are the GraphQL equivalent of GET requests in REST. By convention, they do not mutate data. To learn about how to form Queries in graphql, see [GraphQL's documentation](https://graphql.org/learn/queries/).
 *
 * ⬅️ This interface will autocomplete, so just try typing what you want. You can also search these docs. Some queries will require authentication. Check the documentation or search `Authentication` for details.
 */
export type QueryProviderAuthDetailsArgs = {
  accountNumber?: InputMaybe<Scalars["String"]>;
  clientType?: InputMaybe<ClientType>;
  deviceType: KrakenFlexDeviceTypes;
  propertyId?: InputMaybe<Scalars["Int"]>;
  provider: ProviderChoices;
};

/**
 * Queries are the GraphQL equivalent of GET requests in REST. By convention, they do not mutate data. To learn about how to form Queries in graphql, see [GraphQL's documentation](https://graphql.org/learn/queries/).
 *
 * ⬅️ This interface will autocomplete, so just try typing what you want. You can also search these docs. Some queries will require authentication. Check the documentation or search `Authentication` for details.
 */
export type QueryProviderVirtualKeyDetailsArgs = {
  deviceType: KrakenFlexDeviceTypes;
  provider: ProviderChoices;
};

/**
 * Queries are the GraphQL equivalent of GET requests in REST. By convention, they do not mutate data. To learn about how to form Queries in graphql, see [GraphQL's documentation](https://graphql.org/learn/queries/).
 *
 * ⬅️ This interface will autocomplete, so just try typing what you want. You can also search these docs. Some queries will require authentication. Check the documentation or search `Authentication` for details.
 */
export type QueryQueryComplexityArgs = {
  input: QueryComplexityInputType;
};

/**
 * Queries are the GraphQL equivalent of GET requests in REST. By convention, they do not mutate data. To learn about how to form Queries in graphql, see [GraphQL's documentation](https://graphql.org/learn/queries/).
 *
 * ⬅️ This interface will autocomplete, so just try typing what you want. You can also search these docs. Some queries will require authentication. Check the documentation or search `Authentication` for details.
 */
export type QueryQuestionArgs = {
  formId: Scalars["Int"];
};

/**
 * Queries are the GraphQL equivalent of GET requests in REST. By convention, they do not mutate data. To learn about how to form Queries in graphql, see [GraphQL's documentation](https://graphql.org/learn/queries/).
 *
 * ⬅️ This interface will autocomplete, so just try typing what you want. You can also search these docs. Some queries will require authentication. Check the documentation or search `Authentication` for details.
 */
export type QueryRegisteredKrakenflexDeviceArgs = {
  accountNumber: Scalars["String"];
};

/**
 * Queries are the GraphQL equivalent of GET requests in REST. By convention, they do not mutate data. To learn about how to form Queries in graphql, see [GraphQL's documentation](https://graphql.org/learn/queries/).
 *
 * ⬅️ This interface will autocomplete, so just try typing what you want. You can also search these docs. Some queries will require authentication. Check the documentation or search `Authentication` for details.
 */
export type QueryRegisteredThermostatsArgs = {
  accountNumber: Scalars["String"];
};

/**
 * Queries are the GraphQL equivalent of GET requests in REST. By convention, they do not mutate data. To learn about how to form Queries in graphql, see [GraphQL's documentation](https://graphql.org/learn/queries/).
 *
 * ⬅️ This interface will autocomplete, so just try typing what you want. You can also search these docs. Some queries will require authentication. Check the documentation or search `Authentication` for details.
 */
export type QuerySmartFlexOnboardingWizardsArgs = {
  accountNumber: Scalars["String"];
  propertyId?: InputMaybe<Scalars["Int"]>;
  wizardId?: InputMaybe<Scalars["ID"]>;
};

/**
 * Queries are the GraphQL equivalent of GET requests in REST. By convention, they do not mutate data. To learn about how to form Queries in graphql, see [GraphQL's documentation](https://graphql.org/learn/queries/).
 *
 * ⬅️ This interface will autocomplete, so just try typing what you want. You can also search these docs. Some queries will require authentication. Check the documentation or search `Authentication` for details.
 */
export type QueryTaskResultArgs = {
  accountNumber: Scalars["String"];
  taskId: Scalars["String"];
};

/**
 * Queries are the GraphQL equivalent of GET requests in REST. By convention, they do not mutate data. To learn about how to form Queries in graphql, see [GraphQL's documentation](https://graphql.org/learn/queries/).
 *
 * ⬅️ This interface will autocomplete, so just try typing what you want. You can also search these docs. Some queries will require authentication. Check the documentation or search `Authentication` for details.
 */
export type QueryTdspByPostalcodeArgs = {
  postalCode: Scalars["String"];
};

/**
 * Queries are the GraphQL equivalent of GET requests in REST. By convention, they do not mutate data. To learn about how to form Queries in graphql, see [GraphQL's documentation](https://graphql.org/learn/queries/).
 *
 * ⬅️ This interface will autocomplete, so just try typing what you want. You can also search these docs. Some queries will require authentication. Check the documentation or search `Authentication` for details.
 */
export type QueryTermsAndConditionsForProductArgs = {
  productCode: Scalars["String"];
};

/**
 * Queries are the GraphQL equivalent of GET requests in REST. By convention, they do not mutate data. To learn about how to form Queries in graphql, see [GraphQL's documentation](https://graphql.org/learn/queries/).
 *
 * ⬅️ This interface will autocomplete, so just try typing what you want. You can also search these docs. Some queries will require authentication. Check the documentation or search `Authentication` for details.
 */
export type QueryUserVehiclesArgs = {
  accountNumber?: InputMaybe<Scalars["String"]>;
  authentication?: InputMaybe<AuthenticationInput>;
  supportedProvider?: InputMaybe<ProviderChoices>;
};

/**
 * Queries are the GraphQL equivalent of GET requests in REST. By convention, they do not mutate data. To learn about how to form Queries in graphql, see [GraphQL's documentation](https://graphql.org/learn/queries/).
 *
 * ⬅️ This interface will autocomplete, so just try typing what you want. You can also search these docs. Some queries will require authentication. Check the documentation or search `Authentication` for details.
 */
export type QueryValidPhoneNumberArgs = {
  phoneNumber: Scalars["String"];
};

/**
 * Queries are the GraphQL equivalent of GET requests in REST. By convention, they do not mutate data. To learn about how to form Queries in graphql, see [GraphQL's documentation](https://graphql.org/learn/queries/).
 *
 * ⬅️ This interface will autocomplete, so just try typing what you want. You can also search these docs. Some queries will require authentication. Check the documentation or search `Authentication` for details.
 */
export type QueryVehicleChargingPreferencesArgs = {
  accountNumber: Scalars["String"];
};

/** Information about the complexity of the query. */
export type QueryComplexityInputType = {
  /** The operation name of the query to calculate complexity for if more than one is provided. */
  operationName?: InputMaybe<Scalars["String"]>;
  /** The query to calculate complexity for. */
  query: Scalars["String"];
  /** Any variables to include for the query. Pagination variables should be included as they will affect the overall weight of the query. */
  variables?: InputMaybe<Scalars["JSONString"]>;
};

export type QueryComplexityOutputType = {
  __typename?: "QueryComplexityOutputType";
  /** The complexity of the query. */
  complexityValue?: Maybe<Scalars["Int"]>;
};

export type QuoteExtraCriteria = {
  /** The email address associated with this quote. */
  email?: InputMaybe<Scalars["String"]>;
  /** Defaults to 1000 kWh if no value is given. */
  kwhConsumption?: InputMaybe<Scalars["Decimal"]>;
  /** A usage period that should be used to estimate a quote request. Defaults to `ANNUALLY` if not given. */
  kwhUsagePeriod?: InputMaybe<EstimationInterval>;
};

export type QuoteShareInput = {
  /** Account for which the quote was created. */
  accountNumber?: InputMaybe<Scalars["String"]>;
  /** Quote code. */
  quoteCode: Scalars["String"];
  /** Recipient information. */
  recipient: Recipient;
};

/** Returns a quoted product's information. */
export type QuotedProduct = {
  __typename?: "QuotedProduct";
  autoTopUpDefaultAmount?: Maybe<Scalars["Int"]>;
  autoTopUpMinimumAmount?: Maybe<Scalars["Int"]>;
  basedOnTimeOfUse?: Maybe<Scalars["Boolean"]>;
  /** Product's code. */
  code?: Maybe<Scalars["String"]>;
  /** Product's description. */
  description?: Maybe<Scalars["String"]>;
  /** Product's display name. */
  displayName?: Maybe<Scalars["String"]>;
  /** Returns a list of EFLs that are applicable to the resolved product. */
  efls?: Maybe<Array<Maybe<EflType>>>;
  /** Product's full name. */
  fullName?: Maybe<Scalars["String"]>;
  /** Returns whether or not products are eligible for solar credits. */
  generationCredit?: Maybe<Scalars["Boolean"]>;
  /** Product's id. */
  id?: Maybe<Scalars["ID"]>;
  /** Returns whether or not the given product has IO discount rates. */
  ioDiscountEnabled?: Maybe<Scalars["Boolean"]>;
  isWholesale?: Maybe<Scalars["Boolean"]>;
  prepay?: Maybe<Scalars["Boolean"]>;
  /** Duration in months this product is available for. */
  term?: Maybe<Scalars["Int"]>;
};

export type QuotedProducts = {
  __typename?: "QuotedProducts";
  /** Returns estimated details for a quoted product (includes rates). */
  estimatedDetails?: Maybe<ProductEstimate>;
  /** The time upto which the estimated rates are valid for. */
  expiresAt?: Maybe<Scalars["DateTime"]>;
  /** If the product is available in the given region, it returns the load zone. */
  loadZone?: Maybe<LoadZone>;
  /** Returns a quoted product. */
  product?: Maybe<QuotedProduct>;
  /** If the product is available in the given region, it returns the service provider (TDSP). */
  serviceProvider?: Maybe<ServiceProvider>;
};

export type RateLimitInformation = {
  __typename?: "RateLimitInformation";
  /** The maximum number of points the viewer gets for requests per hour. */
  limit?: Maybe<Scalars["Int"]>;
  /** The remaining points for the viewer in one hour time limit. */
  remainingPoints?: Maybe<Scalars["Int"]>;
  /** The points used so far in one hour time limit. */
  usedPoints?: Maybe<Scalars["Int"]>;
};

/**
 * `TDSP rates`
 * These are rates which apply per TDSP. It will return a list of standing and consumption rates.
 *
 * `Load Zone rates`
 *     These are rates which apply per load zone. It will return a list of standing and consumption rates.
 *
 * `Agnostic rates`
 *     These are rates that apply to products. They are not tied to any TDSP/Load zone. This means
 *     these rates will be applicable to all TDSPs and Rates.
 *
 * `IO rates`
 *     These are rates that apply under certain conditions for a given type of device. They are not
 *     tied to any specific TDSP or Load Zone.
 */
export type RatesType = {
  __typename?: "RatesType";
  agnosticRates?: Maybe<AgnosticRatesType>;
  /** List of rates that are configured based on IO device criteria. */
  ioRates?: Maybe<Array<Maybe<IoDeviceRatesType>>>;
  loadZoneRates?: Maybe<LoadZoneRatesType>;
  tdspRates?: Maybe<TdspRatesType>;
};

/** An enumeration. */
export enum ReadingDirectionType {
  /** Reading is based on the customer's usage of the utility. */
  Consumption = "CONSUMPTION",
  /**
   * Reading is based on the utility generated by the customer.
   *
   * For example: This will return solar readings if a customer has solar panels installed at their location.
   */
  Generation = "GENERATION",
}

/** An enumeration. */
export enum ReadingFrequencyType {
  /** Readings taken on a day to day basis. */
  Daily = "DAILY",
  DayInterval = "DAY_INTERVAL",
  /** Readings taken in every 15 minute intervals. */
  FifteenMinInterval = "FIFTEEN_MIN_INTERVAL",
  /** Readings taken in every 5 minute intervals. */
  FiveMinInterval = "FIVE_MIN_INTERVAL",
  HourInterval = "HOUR_INTERVAL",
  MonthInterval = "MONTH_INTERVAL",
  /** Readings taken at a point in time. */
  PointInTime = "POINT_IN_TIME",
  QuarterInterval = "QUARTER_INTERVAL",
  /** Interval Readings as provided, may be variable in length. */
  RawInterval = "RAW_INTERVAL",
  /** Readings taken in every 30 minute intervals. */
  ThirtyMinInterval = "THIRTY_MIN_INTERVAL",
  WeekInterval = "WEEK_INTERVAL",
}

/** An enumeration. */
export enum ReadingQualityType {
  Actual = "ACTUAL",
  Combined = "COMBINED",
  Estimate = "ESTIMATE",
}

/** The type of statistic for the reading interval. */
export enum ReadingStatisticTypeEnum {
  /** The estimated carbon cost of the interval. */
  CarbonCost = "CARBON_COST",
  /** The calculated cost of consumption for the interval. */
  ConsumptionCost = "CONSUMPTION_COST",
  /** The calculated monetary value of generation for the interval */
  GenerationValue = "GENERATION_VALUE",
  /** The calculated cost of standing charges for the interval. */
  StandingChargeCost = "STANDING_CHARGE_COST",
  /** The apportion cost of a time of use bucket for the interval. */
  TouBucketCost = "TOU_BUCKET_COST",
}

/**
 * Re-authenticate a device. The authentication details provided must be for the
 * same device as was previously authenticated.
 *
 * The possible errors that can be raised are:
 *
 * - KT-CT-4313: Could not find KrakenFlex device.
 * - KT-CT-4314: Unable to get provider details.
 * - KT-CT-4315: Unable to re-authenticate device.
 * - KT-CT-4363: No capable devices found.
 * - KT-CT-4364: Multiple devices found.
 * - KT-CT-1113: Disabled GraphQL field requested.
 */
export type ReauthenticateDevice = {
  __typename?: "ReauthenticateDevice";
  krakenflexDevice?: Maybe<KrakenFlexDeviceType>;
  possibleErrors?: Maybe<Array<Maybe<PossibleErrorType>>>;
};

export type ReauthenticateDeviceInput = {
  /** Account number. */
  accountNumber: Scalars["String"];
  /** The authentication details required for the currently authenticated device. */
  authentication: AuthenticationInput;
  /** The most recently registered device of this type will be re-authenticated. */
  deviceType: KrakenFlexDeviceTypes;
};

export type Recipient = {
  /** Email address of the recipient. */
  email?: InputMaybe<Scalars["String"]>;
  /** Family name of the recipient. */
  familyName?: InputMaybe<Scalars["String"]>;
  /** Given name of the recipient. */
  givenName?: InputMaybe<Scalars["String"]>;
  /** Mobile number of the recipient. */
  mobile?: InputMaybe<Scalars["String"]>;
};

/**
 * Record the customer's acceptance of a deposit agreement.
 *
 * The possible errors that can be raised are:
 *
 * - KT-CT-4177: Unauthorized.
 * - KT-CT-1113: Disabled GraphQL field requested.
 * - KT-CT-1111: Unauthorized.
 * - KT-CT-1112: 'Authorization' header not provided.
 */
export type RecordDepositAgreementAccepted = {
  __typename?: "RecordDepositAgreementAccepted";
  isRecorded?: Maybe<Scalars["Boolean"]>;
  possibleErrors?: Maybe<Array<Maybe<PossibleErrorType>>>;
};

export type RectangularButtonType = ButtonInterface &
  IdentifiableInterface & {
    __typename?: "RectangularButtonType";
    /** The action to perform when the button is pressed. */
    buttonAction: ActionType;
    /** The button style. */
    buttonStyle?: Maybe<ButtonStyle>;
    /** Unique identifier of the object. */
    id?: Maybe<Scalars["ID"]>;
    /** Title text of the button. */
    title: Scalars["String"];
    /** The name of the object's type. */
    typename?: Maybe<Scalars["String"]>;
    /** Colour style of button eg. filled, outlined, text_only. */
    variant?: Maybe<ButtonVariance>;
  };

/**
 * Redeem Loyalty Points as account credit.
 *
 * The possible errors that can be raised are:
 *
 * - KT-CT-1111: Unauthorized.
 * - KT-CT-9201: No Loyalty Point ledger found for the user.
 * - KT-CT-9202: Loyalty Points adapter not configured.
 * - KT-CT-9203: No ledger entries for the ledger.
 * - KT-CT-9205: Insufficient Loyalty Points.
 * - KT-CT-9206: Indivisible points.
 * - KT-CT-9204: Negative or zero points set.
 * - KT-CT-9208: Invalid posted at datetime.
 * - KT-CT-9209: Negative Loyalty Points balance.
 * - KT-CT-9210: Unhandled Loyalty Points exception.
 * - KT-CT-1113: Disabled GraphQL field requested.
 */
export type RedeemLoyaltyPointsForAccountCredit = {
  __typename?: "RedeemLoyaltyPointsForAccountCredit";
  /** The number of OctoPoints that were redeemed. */
  pointsRedeemed?: Maybe<Scalars["Int"]>;
  possibleErrors?: Maybe<Array<Maybe<PossibleErrorType>>>;
};

/** The input type for redeeming Loyalty Points. */
export type RedeemLoyaltyPointsInput = {
  /** The account number. */
  accountNumber: Scalars["String"];
  /** The number of Loyalty Points to redeem. */
  points: Scalars["Int"];
};

/**
 * The possible errors that can be raised are:
 *
 * - KT-CT-6723: Unauthorized.
 * - KT-CT-6724: Referral claim code not found.
 * - KT-CT-6725: Referral claim code redeeming error.
 * - KT-CT-6726: Referral claim code has already been redeemed.
 * - KT-CT-6727: Referral claim code is not available.
 * - KT-CT-1113: Disabled GraphQL field requested.
 */
export type RedeemReferralClaimCode = {
  __typename?: "RedeemReferralClaimCode";
  possibleErrors?: Maybe<Array<Maybe<PossibleErrorType>>>;
};

/** Required payload to redeem the benefit for partner reward referral scheme */
export type RedeemReferralClaimCodeInput = {
  /** The account number for the referred account. */
  accountNumber: Scalars["String"];
  /** Referral scheme claim code value. */
  code: Scalars["String"];
};

/** An enumeration. */
export enum ReferralCodeApplicationStatusChoices {
  /** PROMO CODE APPLIED. */
  PromoCodeApplied = "PROMO_CODE_APPLIED",
  /** REFERRAL CODE APPLIED. */
  ReferralCodeApplied = "REFERRAL_CODE_APPLIED",
  /** SIGNUP CODE APPLIED. */
  SignupCodeApplied = "SIGNUP_CODE_APPLIED",
}

export type ReferralCodeStatusType = {
  __typename?: "ReferralCodeStatusType";
  codeType?: Maybe<CodeType>;
  description?: Maybe<Scalars["String"]>;
  descriptionInSpanish?: Maybe<Scalars["String"]>;
  isValid?: Maybe<Scalars["Boolean"]>;
  maxRecurrence?: Maybe<Scalars["String"]>;
};

export type ReferralCompetitionStats = {
  __typename?: "ReferralCompetitionStats";
  /** The custom identifier for the account that is requesting the stats. */
  customIdentifier?: Maybe<Scalars["String"]>;
  /** The num of entries given for each action. Such as one point for referring a customer, two points for connecting their personal IO device or if their friend connects an IO device. */
  numOfEntries?: Maybe<Scalars["Int"]>;
  /** The number of personal IO devices connected during the competition period. */
  numOfPersonalConnectedDevices?: Maybe<Scalars["Int"]>;
  /** The number of pending/paid referrals made during the competition period. */
  numOfReferrals?: Maybe<Scalars["Int"]>;
  /** The number of referred IO devices connected during the competition period. */
  numOfReferredConnectedDevices?: Maybe<Scalars["Int"]>;
  /** The rank of the account in the competition. */
  rank?: Maybe<Scalars["Int"]>;
  /** Referral code of the account. */
  referralCode?: Maybe<Scalars["String"]>;
};

export type ReferralConnectionTypeConnection = {
  __typename?: "ReferralConnectionTypeConnection";
  /** Number of nodes in the edge. */
  edgeCount: Scalars["Int"];
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<ReferralConnectionTypeEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  /** Total number of nodes. */
  totalCount: Scalars["Int"];
};

/** A Relay edge containing a `ReferralConnectionType` and its cursor. */
export type ReferralConnectionTypeEdge = {
  __typename?: "ReferralConnectionTypeEdge";
  /** A cursor for use in pagination */
  cursor: Scalars["String"];
  /** The item at the end of the edge */
  node?: Maybe<ReferralType>;
};

export type ReferralInterface = {
  code?: Maybe<Scalars["String"]>;
  /** The payment amount in cents received by the referrer and the referee combined. */
  combinedPaymentAmount?: Maybe<Scalars["Int"]>;
  paymentDate?: Maybe<Scalars["Date"]>;
  paymentStatus?: Maybe<Scalars["String"]>;
  referredUserJoinDate?: Maybe<Scalars["DateTime"]>;
  referredUserName?: Maybe<Scalars["String"]>;
  /** Payment amount given to the referred account in cents. */
  referredUserPaymentAmount?: Maybe<Scalars["Int"]>;
  /** Payment amount given to the referring account in cents. */
  referringUserPaymentAmount?: Maybe<Scalars["Int"]>;
  /** The type of reward scheme. */
  schemeType?: Maybe<ReferralSchemeTypeChoices>;
};

/** A referral scheme is a way for one account to earn a reward for referring another. This is achieved by the referred account using a url (provided by the referring account) to sign up. */
export type ReferralSchemeType = {
  __typename?: "ReferralSchemeType";
  /** Whether the current account is eligible to be referred under this scheme. */
  canBeReferred?: Maybe<Scalars["Boolean"]>;
  /** The unique code for the scheme. */
  code?: Maybe<Scalars["String"]>;
  /** The reward amount received by the referrer and the referee combined. */
  combinedRewardAmount?: Maybe<Scalars["Int"]>;
  /** True if the the scheme has limit of uses, and if the usage is at capacity. */
  isUsageAtCapacity?: Maybe<Scalars["Boolean"]>;
  /** The number of loyalty points to be awarded to the referrer in addition to the reward amount. */
  loyaltyPointsBonus?: Maybe<Scalars["Int"]>;
  /** A referral url for display purposes. */
  referralDisplayUrl?: Maybe<Scalars["String"]>;
  /** A fully qualified url give people to create accounts referred by this scheme. */
  referralUrl?: Maybe<Scalars["String"]>;
  /** The reward amount received by the referred party. */
  referredRewardAmount?: Maybe<Scalars["Int"]>;
  /**
   * The family name of the person making the referral.
   * @deprecated The 'referringFamilyName' field is deprecated.
   *
   * Only make use of the referrerGivenName for privacy reasons.
   *
   * - Marked as deprecated on 2022-11-07.
   * - Will be removed on 2023-01-07.
   */
  referrerFamilyName?: Maybe<Scalars["String"]>;
  /** The given name of the person making the referral. */
  referrerGivenName?: Maybe<Scalars["String"]>;
  /** The reward amount received by the referrer. */
  referrerRewardAmount?: Maybe<Scalars["Int"]>;
  /** Scheme type of the referral scheme. */
  schemeType?: Maybe<Scalars["String"]>;
};

/** An enumeration. */
export enum ReferralSchemeTypeChoices {
  /** Legacy Referral. */
  LegacyReferral = "LEGACY_REFERRAL",
  /** Partner Reward. */
  PartnerReward = "PARTNER_REWARD",
  /** Promo Reward. */
  PromoReward = "PROMO_REWARD",
  /** Referral Reward. */
  ReferralReward = "REFERRAL_REWARD",
  /** Signup Reward. */
  SignupReward = "SIGNUP_REWARD",
}

export type ReferralSchemeTypes = {
  __typename?: "ReferralSchemeTypes";
  business?: Maybe<ReferralSchemeType>;
  domestic?: Maybe<ReferralSchemeType>;
  /**
   * @deprecated The 'friendsAndFamily' field is deprecated.
   *
   * Please use domestic instead.
   *
   * - Marked as deprecated on 2020-03-05.
   * - Will be removed on 2024-01-01.
   */
  friendsAndFamily?: Maybe<ReferralSchemeType>;
};

/** An enumeration. */
export enum ReferralStatusChoices {
  /** Cancelled. */
  Cancelled = "Cancelled",
  /** Paid. */
  Paid = "Paid",
  /** Pending. */
  Pending = "Pending",
}

/** Details of an account referral */
export type ReferralType = ReferralInterface & {
  __typename?: "ReferralType";
  code?: Maybe<Scalars["String"]>;
  /** The payment amount in cents received by the referrer and the referee combined. */
  combinedPaymentAmount?: Maybe<Scalars["Int"]>;
  id: Scalars["ID"];
  paymentDate?: Maybe<Scalars["Date"]>;
  paymentStatus?: Maybe<Scalars["String"]>;
  referredUserJoinDate?: Maybe<Scalars["DateTime"]>;
  referredUserName?: Maybe<Scalars["String"]>;
  /** Payment amount given to the referred account in cents. */
  referredUserPaymentAmount?: Maybe<Scalars["Int"]>;
  /** Payment amount given to the referring account in cents. */
  referringUserPaymentAmount?: Maybe<Scalars["Int"]>;
  /** The type of reward scheme. */
  schemeType?: Maybe<ReferralSchemeTypeChoices>;
};

/** An opaque token that can be used to renew a Kraken Token. */
export type RefreshToken = {
  __typename?: "RefreshToken";
  /** The datetime when the token will expire. */
  expiryDt: Scalars["DateTime"];
  isValid?: Maybe<Scalars["Boolean"]>;
  key: Scalars["String"];
};

/** A refund to the customer from the energy supplier. */
export type Refund = TransactionType & {
  __typename?: "Refund";
  /** Unique identifier of the account the transaction belongs to. */
  accountNumber?: Maybe<Scalars["String"]>;
  /**
   * Gross amount including tax (when payable). Refer to the `amounts` field for a breakdown of this information.
   * @deprecated The 'amount' field is deprecated.
   *
   * Use `amounts` instead for a breakdown of the relevant net, tax, and gross amounts.
   *
   * - Marked as deprecated on 2023-12-06.
   * - Will be removed on 2024-06-01.
   */
  amount?: Maybe<Scalars["Int"]>;
  /** The net, tax and gross amounts for the transaction. Note: for payments and repayments, only the net amount is returned. */
  amounts?: Maybe<TransactionAmountType>;
  /** The customer's resulting balance after this transaction has been applied, in the smallest unit of currency. */
  balanceCarriedForward?: Maybe<Scalars["Int"]>;
  /** The unique identifier for the most recent billing document linked with the transaction.Note: a transaction may be linked with multiple documents, but this field will only return the identifier for the most recent billing document. */
  billingDocumentIdentifier?: Maybe<Scalars["ID"]>;
  /** The date time when the transaction is created. */
  createdAt?: Maybe<Scalars["DateTime"]>;
  /** Returns True if the transaction is linked with a statement. */
  hasStatement?: Maybe<Scalars["Boolean"]>;
  id?: Maybe<Scalars["ID"]>;
  /**
   * Deprecated.
   * @deprecated The 'isAccountCharge' field is deprecated.
   *
   * This information is provided by the __typename introspection query.
   *
   * - Marked as deprecated on 2020-06-19.
   * - Will be removed on 2022-11-15.
   */
  isAccountCharge?: Maybe<Scalars["Boolean"]>;
  /**
   * Deprecated.
   * @deprecated The 'isAccountPayment' field is deprecated.
   *
   * This information is provided by the __typename introspection query.
   *
   * - Marked as deprecated on 2020-06-19.
   * - Will be removed on 2022-11-15.
   */
  isAccountPayment?: Maybe<Scalars["Boolean"]>;
  /**
   * Deprecated.
   * @deprecated The 'isCredit' field is deprecated.
   *
   * This information is provided by the __typename introspection query.
   *
   * - Marked as deprecated on 2020-06-19.
   * - Will be removed on 2022-11-15.
   */
  isCredit?: Maybe<Scalars["Boolean"]>;
  /** Whether the statement this transaction is on has been held. A held statement is not sent to a customer automatically, but is instead marked for manual attention by operations staff.  Returns False if a statement is not linked with the transaction. */
  isHeld?: Maybe<Scalars["Boolean"]>;
  /** Whether this transaction has been issued on any billing document.Note: Look for the most recently issued transaction instead of looking through all transactions as some accounts may have initial transactions that were not issued.This will return False if the transaction is not associated with any billing documents. */
  isIssued?: Maybe<Scalars["Boolean"]>;
  isReversed: Scalars["Boolean"];
  /** Returns the note field value for the transaction, which contains additional info. */
  note?: Maybe<Scalars["String"]>;
  postedDate?: Maybe<Scalars["Date"]>;
  /** Returns the reason. */
  reasonCode?: Maybe<Scalars["String"]>;
  /**
   * Returns None if a statement is not linked with the transaction.
   * @deprecated The 'statementId' field is deprecated.
   *
   * Use `billingDocumentIdentifier` instead.
   *
   * - Marked as deprecated on 2023-11-30.
   * - Will be removed on 2024-06-01.
   */
  statementId?: Maybe<Scalars["ID"]>;
  title?: Maybe<Scalars["String"]>;
};

/**
 * The possible errors that can be raised are:
 *
 * - KT-CT-3924: Unauthorized.
 * - KT-CT-3928: Idempotency key used for another repayment request.
 * - KT-CT-3929: The payment is not in a refundable state.
 * - KT-CT-3933: Refund amount greater than payment amount.
 * - KT-CT-1113: Disabled GraphQL field requested.
 * - KT-CT-1111: Unauthorized.
 * - KT-CT-1112: 'Authorization' header not provided.
 */
export type RefundPayment = {
  __typename?: "RefundPayment";
  possibleErrors?: Maybe<Array<Maybe<PossibleErrorType>>>;
  /** The repayment for the requested refund. */
  repayment?: Maybe<AccountRepaymentType>;
};

export type RefundPaymentInput = {
  /** The account number. */
  accountNumber: Scalars["ID"];
  /** The amount to be repaid. */
  amountInMinorUnit: Scalars["Int"];
  /** Unique constraint to prevent duplicate requests. */
  idempotencyKey: Scalars["String"];
  /** The ID of the payment to refund. */
  paymentId: Scalars["ID"];
  /** Reason for refunding the payment. */
  reason: Scalars["String"];
};

export type RefundPaymentRequestType = {
  __typename?: "RefundPaymentRequestType";
  /** The amount of money requested. */
  amount?: Maybe<Scalars["Int"]>;
  /** The payment which is being refunded. */
  payment?: Maybe<AccountPaymentType>;
  /** Internal code for the reason the refund is being requested. */
  reasonCode?: Maybe<Scalars["String"]>;
  /** The ID of the refund request. */
  requestId?: Maybe<Scalars["ID"]>;
  /** The current status of the refund request. */
  status?: Maybe<RepaymentRequestStatus>;
};

export type RefundRequestConnectionTypeConnection = {
  __typename?: "RefundRequestConnectionTypeConnection";
  /** Number of nodes in the edge. */
  edgeCount: Scalars["Int"];
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<RefundRequestConnectionTypeEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  /** Total number of nodes. */
  totalCount: Scalars["Int"];
};

/** A Relay edge containing a `RefundRequestConnectionType` and its cursor. */
export type RefundRequestConnectionTypeEdge = {
  __typename?: "RefundRequestConnectionTypeEdge";
  /** A cursor for use in pagination */
  cursor: Scalars["String"];
  /** The item at the end of the edge */
  node?: Maybe<RefundPaymentRequestType>;
};

/** Regenerate the user's live secret key. */
export type RegenerateSecretKey = {
  __typename?: "RegenerateSecretKey";
  /**
   *  The currently authenticated user.
   *
   *
   *
   * This field requires the `Authorization` header to be set.
   */
  viewer?: Maybe<AccountUserType>;
};

export type RegisterPushNotificationBinding = {
  __typename?: "RegisterPushNotificationBinding";
  pushNotificationBinding?: Maybe<PushNotificationBindingType>;
};

export type RegisterPushNotificationBindingInput = {
  /** Register a push notification binding. A push notification binding connects an account user to a specific application running on a specific device through a 'registration token' (Android) or 'device token' (iOS). Using this binding we can send push notifications to the account user's devices. */
  bundleId: Scalars["String"];
  /** Device push notification token. */
  token: Scalars["String"];
};

export type RegisteredThermostats = {
  __typename?: "RegisteredThermostats";
  /** List of smart thermostat devices associated with this Kraken account. */
  thermostats?: Maybe<Array<Maybe<ThermostatDeviceType>>>;
};

/** A payment schedule which triggers a payment at regular intervals. */
export type RegularTriggeredScheduleInput = {
  /** The based unit of frequency at which payments are to be taken. */
  frequency?: InputMaybe<ScheduleFrequencyEnum>;
  /** The multiple of the frequency at which payment are taken; should be between 1 and 11. */
  frequencyMultiplier?: InputMaybe<Scalars["Int"]>;
  /** The day of the month/week at which to take payment; ranges from 1 to 28 for 'Monthly'. */
  paymentDay?: InputMaybe<Scalars["Int"]>;
};

/** Input fields for Repayment Intervention. */
export type RepaymentInput = {
  /** The Repayment Intervention reason. */
  reason?: InputMaybe<Scalars["String"]>;
  /** The repayment ID. */
  repaymentId: Scalars["ID"];
};

export type RepaymentInterventionType = {
  __typename?: "RepaymentInterventionType";
  /** The repayment intervention outcome. */
  outcome?: Maybe<Scalars["String"]>;
  /** The repayment intervention reason. */
  reason?: Maybe<Scalars["String"]>;
};

/** Methods by which repayments can be sent to the customer. */
export enum RepaymentMethod {
  BankTransfer = "BANK_TRANSFER",
  Card = "CARD",
  Cheque = "CHEQUE",
}

export type RepaymentRequestConnectionTypeConnection = {
  __typename?: "RepaymentRequestConnectionTypeConnection";
  /** Number of nodes in the edge. */
  edgeCount: Scalars["Int"];
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<RepaymentRequestConnectionTypeEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  /** Total number of nodes. */
  totalCount: Scalars["Int"];
};

/** A Relay edge containing a `RepaymentRequestConnectionType` and its cursor. */
export type RepaymentRequestConnectionTypeEdge = {
  __typename?: "RepaymentRequestConnectionTypeEdge";
  /** A cursor for use in pagination */
  cursor: Scalars["String"];
  /** The item at the end of the edge */
  node?: Maybe<RepaymentRequestType>;
};

/** Possible status' for a repayment (or refund) request */
export enum RepaymentRequestStatus {
  Accepted = "ACCEPTED",
  Cancelled = "CANCELLED",
  Rejected = "REJECTED",
  Requested = "REQUESTED",
}

export type RepaymentRequestType = {
  __typename?: "RepaymentRequestType";
  /** The amount of money requested. */
  amount?: Maybe<Scalars["Int"]>;
  /** The payment instruction, if any, associated with the repayment request. */
  instruction?: Maybe<PaymentInstructionType>;
  /** The method by which the money will be transferred to the customer. */
  method?: Maybe<RepaymentMethod>;
  /** Classifier code for repayment reason. */
  reasonCode?: Maybe<Scalars["String"]>;
  /** The ID of the repayment request. */
  requestId?: Maybe<Scalars["String"]>;
  /** The current status of the repayment request. */
  status?: Maybe<RepaymentRequestStatus>;
};

/** An enumeration. */
export enum ReportSuppressedReason {
  Disputed = "DISPUTED",
  Empty = "EMPTY",
  Minor = "MINOR",
  Other = "OTHER",
}

/** Input type for the RequestPasswordReset mutation. */
export type RequestPasswordResetInput = {
  /** The email requesting a password reset email. */
  email: Scalars["String"];
};

export type RequestPasswordResetOutputType = {
  __typename?: "RequestPasswordResetOutputType";
  /** The email that requested a password reset email. */
  email?: Maybe<Scalars["String"]>;
};

export type RequestRefundEligibilityType = {
  __typename?: "RequestRefundEligibilityType";
  /** Whether the account can request a refund. */
  canRequestRefund: Scalars["Boolean"];
  /** The reason why a refund cannot be requested. */
  reason?: Maybe<Scalars["String"]>;
};

export type RequestRepaymentInputType = {
  /** The account number for the requested ledger's account. */
  accountNumber: Scalars["String"];
  /** The amount to be repaid. */
  amountInMinorUnit: Scalars["Int"];
  /** Unique constraint to prevent duplicate requests. */
  idempotencyKey: Scalars["String"];
  /** The ledger id from which the repayment will be requested. */
  ledgerId: Scalars["String"];
  /** The method by which the money will be transferred to the customer. */
  method?: InputMaybe<RequestableRepaymentMethod>;
  /** The reason for the repayment. */
  reason?: InputMaybe<Scalars["String"]>;
};

/** Output for creating a repayment request. */
export type RequestRepaymentOutputType = {
  __typename?: "RequestRepaymentOutputType";
  /** The ID of the repayment request. */
  requestId?: Maybe<Scalars["String"]>;
  /** The current status of the repayment request. */
  status?: Maybe<RepaymentRequestStatus>;
};

/** An enumeration. */
export enum RequestableRepaymentMethod {
  BankTransfer = "BANK_TRANSFER",
  Cheque = "CHEQUE",
}

export type ResetPasswordMutationInput = {
  clientMutationId?: InputMaybe<Scalars["String"]>;
  password: Scalars["String"];
  token: Scalars["String"];
  userId: Scalars["String"];
};

export type ResetPasswordMutationPayload = {
  __typename?: "ResetPasswordMutationPayload";
  clientMutationId?: Maybe<Scalars["String"]>;
  errors?: Maybe<Array<Maybe<SerializerFieldErrorsType>>>;
};

export type ResetUserPasswordInput = {
  /** The new password. */
  newPassword: Scalars["String"];
  /** The token from the presigned url. */
  token: Scalars["String"];
  /** A base64 bytestring representing the user's unique id. */
  userId: Scalars["String"];
};

export type ResetUserPasswordOutput = {
  __typename?: "ResetUserPasswordOutput";
  /** A list of which password validations the new password failed against if applicable. */
  failureReasons?: Maybe<Array<Maybe<Scalars["String"]>>>;
  /** True if the password update was successful, false otherwise. */
  passwordUpdated?: Maybe<Scalars["Boolean"]>;
};

/**
 * Resume control of a device after having been away from home.
 *
 * This is so that the device can be charged again according to the set preferences.
 *
 * The possible errors that can be raised are:
 *
 * - KT-CT-4301: Unable to find device for given account.
 * - KT-CT-4359: Unable to resume device control.
 * - KT-CT-1113: Disabled GraphQL field requested.
 */
export type ResumeDeviceControl = {
  __typename?: "ResumeDeviceControl";
  krakenflexDevice?: Maybe<KrakenFlexDeviceType>;
  possibleErrors?: Maybe<Array<Maybe<PossibleErrorType>>>;
};

/**
 * The possible errors that can be raised are:
 *
 * - KT-CT-4123: Unauthorized.
 * - KT-CT-1501: Agreement not found.
 * - KT-CT-1502: Billed agreements cannot be revoked.
 * - KT-CT-1113: Disabled GraphQL field requested.
 * - KT-CT-1111: Unauthorized.
 * - KT-CT-1112: 'Authorization' header not provided.
 */
export type RevokeAgreement = {
  __typename?: "RevokeAgreement";
  /** Account responsible for the revoked agreement. */
  account?: Maybe<AccountType>;
  possibleErrors?: Maybe<Array<Maybe<PossibleErrorType>>>;
};

export type RevokeAgreementInput = {
  /** The account number. */
  accountNumber: Scalars["String"];
  /** The ID of the agreement to be revoked. */
  agreementId: Scalars["ID"];
  /** The reason for revoking the agreement. */
  reason?: InputMaybe<Scalars["String"]>;
};

export type RewardInterface = {
  paymentDate?: Maybe<Scalars["Date"]>;
  /** The status of the reward payment. */
  paymentStatus?: Maybe<ReferralStatusChoices>;
  /** Reward amount given to the account in cents. */
  rewardAmount?: Maybe<Scalars["Int"]>;
  /** The type of reward scheme. */
  schemeType?: Maybe<ReferralSchemeTypeChoices>;
};

/**
 * A reward is based on a scheme that an account has applied for in order to be
 * eligible for a discount. Examples can include signup, promo, or partner codes that
 * were applied to an account.
 */
export type RewardType = RewardInterface & {
  __typename?: "RewardType";
  id: Scalars["ID"];
  paymentDate?: Maybe<Scalars["Date"]>;
  /** The status of the reward payment. */
  paymentStatus?: Maybe<ReferralStatusChoices>;
  /** Reward amount given to the account in cents. */
  rewardAmount?: Maybe<Scalars["Int"]>;
  /** The type of reward scheme. */
  schemeType?: Maybe<ReferralSchemeTypeChoices>;
};

/**
 * A postal address.
 *
 * This data model is based on the structure used by Google's
 * [libaddressinput library](https://github.com/google/libaddressinput)&mdash;so
 * you can use it, or other libraries that use its data model
 * and reference data, to accept input.
 *
 * All fields can be blank, except for ``country`` which must
 * always be supplied.
 *
 * This type is the input equivalent of `RichAddressType`; all
 * the fields here are semantically equivalent to fields
 * there, except where documented.
 */
export type RichAddressInput = {
  /**
   * Top-level administrative subdivision, e.g. US state, AU
   * state/territory, IT region, JP prefecture.
   */
  administrativeArea?: InputMaybe<Scalars["String"]>;
  /**
   * ISO 3166-1 alpha-2 code of the country this address belongs
   * to, e.g. `AU`, `GB`, `JP`.
   */
  country?: InputMaybe<Scalars["String"]>;
  /**
   * Identifier used by the local postal service for this
   * address, e.g. AU DPID, GB postcode + Delivery Point Suffix,
   * US Zip-9 + Delivery Point.
   *
   * This is the value that gets encoded in the barcode printed
   * on the envelope by large-volume bulk mail providers.
   */
  deliveryPointIdentifier?: InputMaybe<Scalars["String"]>;
  /**
   * UK dependent localities, or neighbourhoods or boroughs in
   * some other locations.
   */
  dependentLocality?: InputMaybe<Scalars["String"]>;
  /**
   * City or town portion of an address, e.g. US city, AU
   * suburb/town, IT comune, UK post town.
   */
  locality?: InputMaybe<Scalars["String"]>;
  /** A personal name. */
  name?: InputMaybe<Scalars["String"]>;
  /** The name of a business or organisation. */
  organization?: InputMaybe<Scalars["String"]>;
  /** Postal code (ZIP code in the US). */
  postalCode?: InputMaybe<Scalars["String"]>;
  /** Sorting code, e.g. FR CEDEX code. This field is not used in many countries. */
  sortingCode?: InputMaybe<Scalars["String"]>;
  /**
   * At most one of this field and `structured_street_address`
   * can be supplied.
   *
   * This is a divergence from `RichAddressType.street_address`,
   * where the field is always supplied; if
   * `structured_street_address` is present, it's generated from
   * that.
   */
  streetAddress?: InputMaybe<Scalars["String"]>;
  /**
   * At most one of this field and `street_address` can be
   * supplied.
   *
   * ### `AU`: Australia
   *
   * The following keys may be present; all are optional. All
   * keys have string values, and their meaning is the same as
   * their aseXML counterparts. (Note that, unlike aseXML, all
   * keys are provided at the top level, rather than being
   * nested.)
   *
   * - `flat_or_unit_type`
   * - `flat_or_unit_number`
   * - `floor_or_level_type`
   * - `floor_or_level_number`
   * - `building_or_property_name`
   * - `location_descriptor`
   * - `lot_number`
   * - `house_number_1`
   * - `house_number_suffix_1`
   * - `house_number_2`
   * - `house_number_suffix_2`
   * - `street_name`
   * - `street_type`
   * - `street_suffix`
   * - `postal_delivery_type`
   * - `postal_delivery_number_prefix`
   * - `postal_delivery_number_value`
   * - `postal_delivery_number_suffix`
   *
   * ### `JP`: Japan
   *
   * The following keys may be present; all are optional.
   * If keys are empty, they may be omitted from the response entirely.
   *
   * - `chome`
   * - `banchi`
   * - `go`
   * - `edaban`
   * - `kana_building_name`
   * - `kanji_building_name`
   * - `building_number`
   * - `room_number`
   * - `address_code`
   * - `physical_location_identifier`
   */
  structuredStreetAddress?: InputMaybe<Scalars["GenericScalar"]>;
};

/**
 * A postal address.
 *
 * This data model is based on the structure used by Google's
 * [libaddressinput library](https://github.com/google/libaddressinput)&mdash;so
 * you can use it, or other libraries that use its data model
 * and reference data, to accept input.
 *
 * All fields can be blank, except for ``country`` which must
 * always be supplied.
 */
export type RichAddressType = {
  __typename?: "RichAddressType";
  /**
   * Top-level administrative subdivision, e.g. US state, AU
   * state/territory, IT region, JP prefecture.
   *
   * ### `AU`: Australia
   *
   * This must be one of `NSW`, `VIC`, `QLD`, `TAS`, `ACT`, `SA`,
   * `NT`, `WA`. For addresses not within these locations, use
   * the value that Australia Post uses, e.g. `ACT` for the
   * Jervis Bay Territory or `WA` for Christmas Island.
   */
  administrativeArea?: Maybe<Scalars["String"]>;
  /**
   * ISO 3166-1 alpha-2 code of the country this address belongs
   * to, e.g. `AU`, `GB`.
   */
  country?: Maybe<Scalars["String"]>;
  /**
   * Identifier used by the local postal service for this
   * address, e.g. AU DPID, GB postcode + Delivery Point Suffix,
   * US Zip-9 + Delivery Point.
   *
   * This is the value that gets encoded in the barcode printed
   * on the envelope by large-volume bulk mail providers.
   */
  deliveryPointIdentifier?: Maybe<Scalars["String"]>;
  /**
   * UK dependent localities, or neighbourhoods or boroughs in
   * some other locations.
   */
  dependentLocality?: Maybe<Scalars["String"]>;
  /**
   * City or town portion of an address, e.g. US city, AU
   * suburb/town, IT comune, UK post town.
   */
  locality?: Maybe<Scalars["String"]>;
  /** A personal name. */
  name?: Maybe<Scalars["String"]>;
  /** The name of a business or organisation. */
  organization?: Maybe<Scalars["String"]>;
  /** Postal code (ZIP code in the US). */
  postalCode?: Maybe<Scalars["String"]>;
  /** Sorting code, e.g. FR CEDEX code. This field is not used in many countries. */
  sortingCode?: Maybe<Scalars["String"]>;
  /**
   * The 'street address' component.
   *
   * This value can (and often will) contain newline characters
   * when appropriate.
   *
   * In some cases, data may appear in this field instead of the
   * below fields; e.g. a UK post town name may appear here
   * instead of in the `dependent_locality` field. This happens
   * when data has been migrated from a legacy format, and that
   * format had insufficient metadata to determine the
   * appropriate field.
   *
   * If `structured_street_address` is also set, the value of
   * this field will be a string generated from that value.
   */
  streetAddress?: Maybe<Scalars["String"]>;
  /**
   * The 'street address' component, in a structured format.
   *
   * This field stores the same value as `street_address`, but
   * with more detail; for instance, instead of `123 Example
   * Street` it might be `{'street_number': '123',
   * 'street_name': 'Example', 'street_type': 'Street'}`. In
   * many cases this will be blank; we only use this field for
   * Krakens where we need to supply this level of granularity
   * to some third-party service, like a bulk mail provider.
   *
   * The exact structure of this value depends on the country _of
   * the address_, which is not necessarily the same as the
   * country this Kraken is configured to serve. For addresses
   * outside of the countries listed below, this field will be
   * left blank.
   *
   * ### `AU`: Australia
   *
   * The following keys may be present; all are optional. All
   * keys have string values, and their meaning is the same as
   * their aseXML counterparts. (Note that, unlike aseXML, all
   * keys are provided at the top level, rather than being
   * nested.)
   *
   * - `flat_or_unit_type`
   * - `flat_or_unit_number`
   * - `floor_or_level_type`
   * - `floor_or_level_number`
   * - `building_or_property_name`
   * - `location_descriptor`
   * - `lot_number`
   * - `house_number_1`
   * - `house_number_suffix_1`
   * - `house_number_2`
   * - `house_number_suffix_2`
   * - `street_name`
   * - `street_type`
   * - `street_suffix`
   * - `postal_delivery_type`
   * - `postal_delivery_number_prefix`
   * - `postal_delivery_number_value`
   * - `postal_delivery_number_suffix`
   *
   * ### `JP`: Japan
   *
   * The following keys may be present; all are optional.
   * If keys are empty, they may be omitted from the response entirely.
   *
   * - `chome`
   * - `banchi`
   * - `go`
   * - `edaban`
   * - `kana_building_name`
   * - `kanji_building_name`
   * - `building_number`
   * - `room_number`
   * - `address_code`
   * - `physical_location_identifier`
   */
  structuredStreetAddress?: Maybe<Scalars["GenericScalar"]>;
};

/** An enumeration. */
export enum RiskBracket {
  High = "HIGH",
  Low = "LOW",
  Medium = "MEDIUM",
}

/**
 * The possible errors that can be raised are:
 *
 * - KT-US-4119: You are not authorized to use the credit engine.
 * - KT-US-3814: Received invalid data. Unable to store credit score.
 * - KT-US-3812: Unexpected error when running credit check.
 * - KT-CT-1113: Disabled GraphQL field requested.
 * - KT-CT-1111: Unauthorized.
 * - KT-CT-1112: 'Authorization' header not provided.
 */
export type RunCreditCheck = {
  __typename?: "RunCreditCheck";
  /** A unique id to identify the credit check attempt. */
  id?: Maybe<Scalars["ID"]>;
  /** Returns true if the applicant's credit score is sufficient, false otherwise. */
  passedCreditCheck?: Maybe<Scalars["Boolean"]>;
  possibleErrors?: Maybe<Array<Maybe<PossibleErrorType>>>;
};

export type RunCreditCheckInput = {
  /** The address of the primary account user. */
  address: Scalars["String"];
  /** The address 2 of the primary account user. */
  address2?: InputMaybe<Scalars["String"]>;
  /** The birthday of the primary account user. Birthday should be in YYYY-MM-DD format. */
  birthday: Scalars["Date"];
  /** The city of the primary account user. */
  city: Scalars["String"];
  /** The first name of the primary account user. */
  firstName: Scalars["String"];
  /** The last name of the primary account user. */
  lastName: Scalars["String"];
  /** Code for the current quote for the customer. */
  quoteCode: Scalars["String"];
  /** The sales sub-channel. */
  salesSubchannel: Scalars["String"];
  /** The social security number of the primary account user. Social Security Number should not have any punctuation. */
  ssn: Scalars["String"];
  /** The state of the primary account user. */
  state: Scalars["String"];
  /** The zipcode of the primary account user. 5 and 9 digit zip codes are allowed. */
  zipcode: Scalars["String"];
};

/** An enumeration. */
export enum SalesChannelChoices {
  Acquisition = "ACQUISITION",
  Aggregator = "AGGREGATOR",
  Broker = "BROKER",
  DebtCollectionAgency = "DEBT_COLLECTION_AGENCY",
  DigiTelesales = "DIGI_TELESALES",
  Direct = "DIRECT",
  Events = "EVENTS",
  FieldSales = "FIELD_SALES",
  GiftOfKit = "GIFT_OF_KIT",
  HighReferrer = "HIGH_REFERRER",
  Landlord = "LANDLORD",
  MoveIn = "MOVE_IN",
  NewTenant = "NEW_TENANT",
  ParentPower = "PARENT_POWER",
  Partnerships = "PARTNERSHIPS",
  PeoplePower = "PEOPLE_POWER",
  PriceComparison = "PRICE_COMPARISON",
  SupplierOfLastResort = "SUPPLIER_OF_LAST_RESORT",
  Telesales = "TELESALES",
  WorkplacePopUp = "WORKPLACE_POP_UP",
  WorksWithOctopus = "WORKS_WITH_OCTOPUS",
}

export type SalesInfoInput = {
  /** The audio recording's id that should be linked with this sale. */
  audioRecordingId?: InputMaybe<Scalars["String"]>;
  /** By default this will be set to True. */
  optedInForMarketing?: InputMaybe<Scalars["Boolean"]>;
  /** By default this will be set to False. */
  optedInToAssociatedCompanies?: InputMaybe<Scalars["Boolean"]>;
  /** By default this will be set to True. */
  optedInToRecommendedMessages?: InputMaybe<Scalars["Boolean"]>;
  /** By default this will be set to True. */
  optedInToSms?: InputMaybe<Scalars["Boolean"]>;
  /** By default this will be set to False. */
  optedInToThirdParties?: InputMaybe<Scalars["Boolean"]>;
  /** By default this will be set to True. */
  optedInToUpdates?: InputMaybe<Scalars["Boolean"]>;
  /** The subdomain associated with the affiliate link. At least one of the fields 'sales_subchannel' or 'sales_affiliate_subdomain' must be provided. */
  salesAffiliateSubdomain?: InputMaybe<Scalars["String"]>;
  /** Select the sales subchannel that best describes your organization. At least one of the fields 'sales_subchannel' or 'sales_affiliate_subdomain' must be provided. */
  salesSubchannel?: InputMaybe<SalesSubchannel>;
  uniqueReferenceNumber?: InputMaybe<Scalars["String"]>;
};

/** Information about the sale to associate with the account. */
export type SalesInformationInput = {
  /** Optional ID of the affiliate session. */
  affiliateSessionId?: InputMaybe<Scalars["String"]>;
  /** The referral code used by the customer when signing up. */
  referralCode?: InputMaybe<Scalars["String"]>;
  /** Sales channel. */
  salesChannel?: InputMaybe<Scalars["String"]>;
  /** Sales subchannel. */
  salesSubchannel?: InputMaybe<Scalars["String"]>;
  /** Unique reference number. */
  urn?: InputMaybe<Scalars["String"]>;
};

/** An enumeration. */
export enum SalesSubchannel {
  BillBuddy = "BILL_BUDDY",
  BreakerBox = "BREAKER_BOX",
  EnergyBot = "ENERGY_BOT",
  Gatby = "GATBY",
  Ichoosr = "ICHOOSR",
  Leviathan = "LEVIATHAN",
  Luztuya = "LUZTUYA",
  Navigator = "NAVIGATOR",
  SupportSite = "SUPPORT_SITE",
}

export enum ScheduleFrequencyEnum {
  Monthly = "MONTHLY",
  Weekly = "WEEKLY",
}

/**
 * Schedule a quote follow up message to the provided recipient.
 *
 * The possible errors that can be raised are:
 *
 * - KT-CT-4619: Quote with given code not found.
 * - KT-CT-4632: Invalid recipient information.
 * - KT-CT-4633: Mutation not enabled in this environment.
 * - KT-CT-1113: Disabled GraphQL field requested.
 */
export type ScheduleQuoteFollowUp = {
  __typename?: "ScheduleQuoteFollowUp";
  possibleErrors?: Maybe<Array<Maybe<PossibleErrorType>>>;
  /** Whether the message was scheduled successfully. */
  success: Scalars["Boolean"];
};

/** An enumeration. */
export enum ScheduleType {
  BacsTransfer = "BACS_TRANSFER",
  CardPayment = "CARD_PAYMENT",
  DirectDebit = "DIRECT_DEBIT",
  PaymentSlip = "PAYMENT_SLIP",
}

/** An action which calls another backend screen via its screen id. */
export type ScreenActionType = ActionInterface &
  IdentifiableInterface & {
    __typename?: "ScreenActionType";
    /** Whether to allow returning to the original caller screen. */
    allowBack: Scalars["Boolean"];
    /** Unique identifier of the object. */
    id?: Maybe<Scalars["ID"]>;
    /** Map of the parameters (key-value pairs) to pass to the next backend screen. */
    params: Array<Maybe<BackendScreenParam>>;
    /** The ID of the screen to navigate to. */
    screenId: Scalars["String"];
    /** The name of the action object's type. */
    typeName?: Maybe<Scalars["String"]>;
    /** The name of the object's type. */
    typename?: Maybe<Scalars["String"]>;
  };

export type SectionContent = CardComponentType | CarouselItemType;

/** A section containing a list of cards or carousel items */
export type SectionType = IdentifiableInterface & {
  __typename?: "SectionType";
  /** The content of the section. */
  content: SectionContent;
  /** Unique identifier of the object. */
  id?: Maybe<Scalars["ID"]>;
  /** The order of the section. */
  order: Scalars["Int"];
  /** The name of the object's type. */
  typename?: Maybe<Scalars["String"]>;
};

/** Options for selecting a charge point's make from a list of options. */
export type SelectChargePointMake = SelectFromList &
  SmartFlexOnboardingStepInterface & {
    __typename?: "SelectChargePointMake";
    /** A unique identifier for this onboarding step. */
    id?: Maybe<Scalars["ID"]>;
    /** The options the user can select. */
    options?: Maybe<Array<Maybe<SmartFlexListItemInterface>>>;
    /** The ID of the option that has been selected, if any. */
    selectedOptionId?: Maybe<Scalars["ID"]>;
  };

/**
 * Complete the select charge point make step.
 *
 * The possible errors that can be raised are:
 *
 * - KT-CT-4371: Onboarding wizard ID is invalid.
 * - KT-CT-4372: Simultaneous attempts to update onboarding process.
 * - KT-CT-4375: Incorrect or missing parameters for SmartFlex onboarding step.
 * - KT-CT-4376: Unable to complete onboarding step. Please try agan later.
 * - KT-CT-4377: Invalid onboarding step ID.
 * - KT-CT-4378: Invalid input or step id. Please make sure you are using the correct step id and providing the expected input params.
 * - KT-CT-4379: Vehicle is not ready for a test charge.
 * - KT-CT-1113: Disabled GraphQL field requested.
 * - KT-CT-1111: Unauthorized.
 * - KT-CT-1112: 'Authorization' header not provided.
 */
export type SelectChargePointMakeForSmartFlexOnboarding = {
  __typename?: "SelectChargePointMakeForSmartFlexOnboarding";
  possibleErrors?: Maybe<Array<Maybe<PossibleErrorType>>>;
  /** The wizard created for onboarding the device with SmartFlex. */
  wizard?: Maybe<SmartFlexOnboardingWizard>;
};

export type SelectChargePointMakeInput = {
  /** The ID of the selected option from the list. */
  selectedOption: Scalars["ID"];
  /** The ID of the SmartFlex onboarding step to complete. */
  stepId: Scalars["ID"];
  /** The ID of the SmartFlex onboarding wizard. */
  wizardId: Scalars["ID"];
};

export type SelectChargePointMakeListItem = SmartFlexListItemInterface & {
  __typename?: "SelectChargePointMakeListItem";
  /** The make of the charge point, e.g. myenergi. */
  chargePointMake?: Maybe<ChargePointVariantType>;
  /** A unique identifier for this list item. */
  id?: Maybe<Scalars["ID"]>;
};

/** Options for selecting a charge point's variant from a list of options. */
export type SelectChargePointVariant = SelectFromList &
  SmartFlexOnboardingStepInterface & {
    __typename?: "SelectChargePointVariant";
    /** A unique identifier for this onboarding step. */
    id?: Maybe<Scalars["ID"]>;
    /** The options the user can select. */
    options?: Maybe<Array<Maybe<SmartFlexListItemInterface>>>;
    /** The ID of the option that has been selected, if any. */
    selectedOptionId?: Maybe<Scalars["ID"]>;
  };

/**
 * Complete the select charge point variant step.
 *
 * The possible errors that can be raised are:
 *
 * - KT-CT-4371: Onboarding wizard ID is invalid.
 * - KT-CT-4372: Simultaneous attempts to update onboarding process.
 * - KT-CT-4375: Incorrect or missing parameters for SmartFlex onboarding step.
 * - KT-CT-4376: Unable to complete onboarding step. Please try agan later.
 * - KT-CT-4377: Invalid onboarding step ID.
 * - KT-CT-4378: Invalid input or step id. Please make sure you are using the correct step id and providing the expected input params.
 * - KT-CT-4379: Vehicle is not ready for a test charge.
 * - KT-CT-1113: Disabled GraphQL field requested.
 * - KT-CT-1111: Unauthorized.
 * - KT-CT-1112: 'Authorization' header not provided.
 */
export type SelectChargePointVariantForSmartFlexOnboarding = {
  __typename?: "SelectChargePointVariantForSmartFlexOnboarding";
  possibleErrors?: Maybe<Array<Maybe<PossibleErrorType>>>;
  /** The wizard created for onboarding the device with SmartFlex. */
  wizard?: Maybe<SmartFlexOnboardingWizard>;
};

export type SelectChargePointVariantInput = {
  /** The ID of the selected option from the list. */
  selectedOption: Scalars["ID"];
  /** The ID of the SmartFlex onboarding step to complete. */
  stepId: Scalars["ID"];
  /** The ID of the SmartFlex onboarding wizard. */
  wizardId: Scalars["ID"];
};

export type SelectChargePointVariantListItem = SmartFlexListItemInterface & {
  __typename?: "SelectChargePointVariantListItem";
  /** The model variant of the charge point, e.g. Zappi. */
  chargePointVariant?: Maybe<ChargePointVariantType>;
  /** A unique identifier for this list item. */
  id?: Maybe<Scalars["ID"]>;
};

/** A type where the user must select the type of device to onboard. */
export type SelectDeviceType = SelectFromList &
  SmartFlexOnboardingStepInterface & {
    __typename?: "SelectDeviceType";
    /** A unique identifier for this onboarding step. */
    id?: Maybe<Scalars["ID"]>;
    /** The options the user can select. */
    options?: Maybe<Array<Maybe<SmartFlexListItemInterface>>>;
    /** The ID of the option that has been selected, if any. */
    selectedOptionId?: Maybe<Scalars["ID"]>;
  };

/**
 * Select the type of device to start the onboarding process.
 *
 * The possible errors that can be raised are:
 *
 * - KT-CT-4371: Onboarding wizard ID is invalid.
 * - KT-CT-4372: Simultaneous attempts to update onboarding process.
 * - KT-CT-4375: Incorrect or missing parameters for SmartFlex onboarding step.
 * - KT-CT-4376: Unable to complete onboarding step. Please try agan later.
 * - KT-CT-4377: Invalid onboarding step ID.
 * - KT-CT-4378: Invalid input or step id. Please make sure you are using the correct step id and providing the expected input params.
 * - KT-CT-4379: Vehicle is not ready for a test charge.
 * - KT-CT-1113: Disabled GraphQL field requested.
 * - KT-CT-1111: Unauthorized.
 * - KT-CT-1112: 'Authorization' header not provided.
 */
export type SelectDeviceTypeForSmartFlexOnboarding = {
  __typename?: "SelectDeviceTypeForSmartFlexOnboarding";
  possibleErrors?: Maybe<Array<Maybe<PossibleErrorType>>>;
  /** The wizard created for onboarding the device with SmartFlex. */
  wizard?: Maybe<SmartFlexOnboardingWizard>;
};

export type SelectDeviceTypeInput = {
  /** The ID of the selected option from the list. */
  selectedOption: Scalars["ID"];
  /** The ID of the SmartFlex onboarding step to complete. */
  stepId: Scalars["ID"];
  /** The ID of the SmartFlex onboarding wizard. */
  wizardId: Scalars["ID"];
};

export type SelectDeviceTypeListItem = SmartFlexListItemInterface & {
  __typename?: "SelectDeviceTypeListItem";
  /** A unique identifier for this list item. */
  id?: Maybe<Scalars["ID"]>;
  /** The device type, e.g. Electric Vehicle. */
  label?: Maybe<Scalars["String"]>;
};

/** A base implementation of an interface where the user must select an option from a list. */
export type SelectFromList = {
  /** A unique identifier for this onboarding step. */
  id?: Maybe<Scalars["ID"]>;
  /** The options the user can select. */
  options?: Maybe<Array<Maybe<SmartFlexListItemInterface>>>;
  /** The ID of the option that has been selected, if any. */
  selectedOptionId?: Maybe<Scalars["ID"]>;
};

/**
 * Mark chosen quoted products for quoted supply points on quote request as selected.
 *
 * The possible errors that can be raised are:
 *
 * - KT-CT-4619: Quote with given code not found.
 * - KT-CT-4634: Quoted product with given id not found.
 * - KT-CT-4626: No product selected for the given quote code.
 * - KT-CT-4635: Missing a quoted product for at least one quoted supply point on the quote request.
 * - KT-CT-4636: Quoted product not linked to a product.
 * - KT-CT-1113: Disabled GraphQL field requested.
 * - KT-CT-1111: Unauthorized.
 * - KT-CT-1112: 'Authorization' header not provided.
 */
export type SelectProducts = {
  __typename?: "SelectProducts";
  possibleErrors?: Maybe<Array<Maybe<PossibleErrorType>>>;
  /** Whether we successfully marked the chosen quoted products as selected. */
  success: Scalars["Boolean"];
};

export type SelectProductsInput = {
  /** Quote code. */
  quoteCode: Scalars["String"];
  /** IDs of the quoted products to be selected. */
  selectedQuotedProductIds: Array<InputMaybe<Scalars["ID"]>>;
};

/** Options for selecting a user's vehicle from a list of options. */
export type SelectUserVehicle = SelectFromList &
  SmartFlexOnboardingStepInterface & {
    __typename?: "SelectUserVehicle";
    /** A unique identifier for this onboarding step. */
    id?: Maybe<Scalars["ID"]>;
    /** The options the user can select. */
    options?: Maybe<Array<Maybe<SmartFlexListItemInterface>>>;
    /** The ID of the option that has been selected, if any. */
    selectedOptionId?: Maybe<Scalars["ID"]>;
  };

/**
 * Complete the select user vehicle step.
 *
 * The possible errors that can be raised are:
 *
 * - KT-CT-4371: Onboarding wizard ID is invalid.
 * - KT-CT-4372: Simultaneous attempts to update onboarding process.
 * - KT-CT-4375: Incorrect or missing parameters for SmartFlex onboarding step.
 * - KT-CT-4376: Unable to complete onboarding step. Please try agan later.
 * - KT-CT-4377: Invalid onboarding step ID.
 * - KT-CT-4378: Invalid input or step id. Please make sure you are using the correct step id and providing the expected input params.
 * - KT-CT-4379: Vehicle is not ready for a test charge.
 * - KT-CT-1113: Disabled GraphQL field requested.
 * - KT-CT-1111: Unauthorized.
 * - KT-CT-1112: 'Authorization' header not provided.
 */
export type SelectUserVehicleForSmartFlexOnboarding = {
  __typename?: "SelectUserVehicleForSmartFlexOnboarding";
  possibleErrors?: Maybe<Array<Maybe<PossibleErrorType>>>;
  /** The wizard created for onboarding the device with SmartFlex. */
  wizard?: Maybe<SmartFlexOnboardingWizard>;
};

export type SelectUserVehicleInput = {
  /** The ID of the selected option from the list. */
  selectedOption: Scalars["ID"];
  /** The ID of the SmartFlex onboarding step to complete. */
  stepId: Scalars["ID"];
  /** The ID of the SmartFlex onboarding wizard. */
  wizardId: Scalars["ID"];
};

export type SelectUserVehicleListItem = SmartFlexListItemInterface & {
  __typename?: "SelectUserVehicleListItem";
  /** A unique identifier for this list item. */
  id?: Maybe<Scalars["ID"]>;
  /** The selected electric vehicle when multiple vehicles were available. */
  userVehicle?: Maybe<ElectricVehicleType>;
};

/** Options for selecting a vehicle's make from a list of options. */
export type SelectVehicleMake = SelectFromList &
  SmartFlexOnboardingStepInterface & {
    __typename?: "SelectVehicleMake";
    /** A unique identifier for this onboarding step. */
    id?: Maybe<Scalars["ID"]>;
    /** The options the user can select. */
    options?: Maybe<Array<Maybe<SmartFlexListItemInterface>>>;
    /** The ID of the option that has been selected, if any. */
    selectedOptionId?: Maybe<Scalars["ID"]>;
  };

/**
 * Complete the select vehicle make step.
 *
 * The possible errors that can be raised are:
 *
 * - KT-CT-4371: Onboarding wizard ID is invalid.
 * - KT-CT-4372: Simultaneous attempts to update onboarding process.
 * - KT-CT-4375: Incorrect or missing parameters for SmartFlex onboarding step.
 * - KT-CT-4376: Unable to complete onboarding step. Please try agan later.
 * - KT-CT-4377: Invalid onboarding step ID.
 * - KT-CT-4378: Invalid input or step id. Please make sure you are using the correct step id and providing the expected input params.
 * - KT-CT-4379: Vehicle is not ready for a test charge.
 * - KT-CT-1113: Disabled GraphQL field requested.
 * - KT-CT-1111: Unauthorized.
 * - KT-CT-1112: 'Authorization' header not provided.
 */
export type SelectVehicleMakeForSmartFlexOnboarding = {
  __typename?: "SelectVehicleMakeForSmartFlexOnboarding";
  possibleErrors?: Maybe<Array<Maybe<PossibleErrorType>>>;
  /** The wizard created for onboarding the device with SmartFlex. */
  wizard?: Maybe<SmartFlexOnboardingWizard>;
};

export type SelectVehicleMakeInput = {
  /** The ID of the selected option from the list. */
  selectedOption: Scalars["ID"];
  /** The ID of the SmartFlex onboarding step to complete. */
  stepId: Scalars["ID"];
  /** The ID of the SmartFlex onboarding wizard. */
  wizardId: Scalars["ID"];
};

export type SelectVehicleMakeListItem = SmartFlexListItemInterface & {
  __typename?: "SelectVehicleMakeListItem";
  /** A unique identifier for this list item. */
  id?: Maybe<Scalars["ID"]>;
  /** The make of the electric vehicle make, e.g. Tesla. */
  vehicleMake?: Maybe<ElectricVehicleType>;
};

/** Options for selecting a vehicle's variant from a list of options. */
export type SelectVehicleVariant = SelectFromList &
  SmartFlexOnboardingStepInterface & {
    __typename?: "SelectVehicleVariant";
    /** A unique identifier for this onboarding step. */
    id?: Maybe<Scalars["ID"]>;
    /** The options the user can select. */
    options?: Maybe<Array<Maybe<SmartFlexListItemInterface>>>;
    /** The ID of the option that has been selected, if any. */
    selectedOptionId?: Maybe<Scalars["ID"]>;
  };

/**
 * Complete the select vehicle variant step.
 *
 * The possible errors that can be raised are:
 *
 * - KT-CT-4371: Onboarding wizard ID is invalid.
 * - KT-CT-4372: Simultaneous attempts to update onboarding process.
 * - KT-CT-4375: Incorrect or missing parameters for SmartFlex onboarding step.
 * - KT-CT-4376: Unable to complete onboarding step. Please try agan later.
 * - KT-CT-4377: Invalid onboarding step ID.
 * - KT-CT-4378: Invalid input or step id. Please make sure you are using the correct step id and providing the expected input params.
 * - KT-CT-4379: Vehicle is not ready for a test charge.
 * - KT-CT-1113: Disabled GraphQL field requested.
 * - KT-CT-1111: Unauthorized.
 * - KT-CT-1112: 'Authorization' header not provided.
 */
export type SelectVehicleVariantForSmartFlexOnboarding = {
  __typename?: "SelectVehicleVariantForSmartFlexOnboarding";
  possibleErrors?: Maybe<Array<Maybe<PossibleErrorType>>>;
  /** The wizard created for onboarding the device with SmartFlex. */
  wizard?: Maybe<SmartFlexOnboardingWizard>;
};

export type SelectVehicleVariantInput = {
  /** The ID of the selected option from the list. */
  selectedOption: Scalars["ID"];
  /** The ID of the SmartFlex onboarding step to complete. */
  stepId: Scalars["ID"];
  /** The ID of the SmartFlex onboarding wizard. */
  wizardId: Scalars["ID"];
};

export type SelectVehicleVariantListItem = SmartFlexListItemInterface & {
  __typename?: "SelectVehicleVariantListItem";
  /** A unique identifier for this list item. */
  id?: Maybe<Scalars["ID"]>;
  /** The model variant of the electric vehicle, e.g. Model Y. */
  vehicleVariant?: Maybe<ElectricVehicleType>;
};

/**
 * Trigger the sending of a quote summary to the provided recipient.
 *
 * The possible errors that can be raised are:
 *
 * - KT-CT-4619: Quote with given code not found.
 * - KT-CT-4178: No account found with given account number.
 * - KT-CT-4632: Invalid recipient information.
 * - KT-CT-1113: Disabled GraphQL field requested.
 * - KT-CT-1111: Unauthorized.
 * - KT-CT-1112: 'Authorization' header not provided.
 */
export type SendQuoteSummary = {
  __typename?: "SendQuoteSummary";
  possibleErrors?: Maybe<Array<Maybe<PossibleErrorType>>>;
  /** Whether the triggering of the quote summary was successful. */
  success: Scalars["Boolean"];
};

export type SerializerErrorType = {
  __typename?: "SerializerErrorType";
  code?: Maybe<Scalars["String"]>;
  message?: Maybe<Scalars["String"]>;
};

export type SerializerFieldErrorsType = {
  __typename?: "SerializerFieldErrorsType";
  errors?: Maybe<Array<ErrorTypeUnion>>;
  field?: Maybe<Scalars["String"]>;
};

/** An enumeration. */
export enum ServiceProvider {
  AepTcc = "AEP_TCC",
  AepTnc = "AEP_TNC",
  Centerpoint = "CENTERPOINT",
  Lpl = "LPL",
  Oncor = "ONCOR",
  Tnmp = "TNMP",
}

/**
 * Allow customers to set/update their battery's charging preferences.
 *
 * The possible errors that can be raised are:
 *
 * - KT-CT-4321: Serializer validation error.
 * - KT-CT-4301: Unable to find device for given account.
 * - KT-CT-4353: An error occurred while trying to update your charging preferences.
 * - KT-CT-1113: Disabled GraphQL field requested.
 */
export type SetBatteryChargingPreferences = {
  __typename?: "SetBatteryChargingPreferences";
  /** The battery device updated, with the ID of the device in KrakenFlex. */
  krakenflexDevice?: Maybe<BatteryDeviceType>;
  possibleErrors?: Maybe<Array<Maybe<PossibleErrorType>>>;
};

/**
 * The possible errors that can be raised are:
 *
 * - KT-CT-5413: Invalid data.
 * - KT-CT-5414: Invalid data.
 * - KT-CT-4123: Unauthorized.
 * - KT-CT-4121: Invalid phone number.
 * - KT-CT-4177: Unauthorized.
 * - KT-CT-1113: Disabled GraphQL field requested.
 * - KT-CT-1111: Unauthorized.
 * - KT-CT-1112: 'Authorization' header not provided.
 */
export type SetEmailAndPhone = {
  __typename?: "SetEmailAndPhone";
  /** The account number of the account. */
  accountNumber?: Maybe<Scalars["String"]>;
  possibleErrors?: Maybe<Array<Maybe<PossibleErrorType>>>;
  /** True if the mutation was successful. */
  result?: Maybe<Scalars["Boolean"]>;
};

export type SetEmailAndPhoneInput = {
  /** Account number of account to be updated. */
  accountNumber?: InputMaybe<Scalars["String"]>;
  /** Email address to be updated. */
  email: Scalars["String"];
  /** Phone number to be updated. */
  phoneNumber: Scalars["String"];
};

/**
 * Set the Loyalty Points user for the account.
 *
 * The possible errors that can be raised are:
 *
 * - KT-CT-1111: Unauthorized.
 * - KT-CT-1111: Unauthorized.
 * - KT-CT-9210: Unhandled Loyalty Points exception.
 * - KT-CT-1113: Disabled GraphQL field requested.
 */
export type SetLoyaltyPointsUser = {
  __typename?: "SetLoyaltyPointsUser";
  /** ID of the new Loyalty Points user. */
  newLoyaltyPointsUserId?: Maybe<Scalars["String"]>;
  possibleErrors?: Maybe<Array<Maybe<PossibleErrorType>>>;
};

/** The input type for setting the Loyalty Points user. */
export type SetLoyaltyPointsUserInput = {
  /** The account number. */
  accountNumber: Scalars["String"];
  /** The account user receiving the points. */
  newLoyaltyPointsUserId: Scalars["String"];
};

/**
 * The possible errors that can be raised are:
 *
 * - KT-CT-3940: Invalid data.
 * - KT-CT-1113: Disabled GraphQL field requested.
 * - KT-CT-1111: Unauthorized.
 * - KT-CT-1112: 'Authorization' header not provided.
 */
export type SetUpDirectDebitInstruction = {
  __typename?: "SetUpDirectDebitInstruction";
  paymentInstruction?: Maybe<DirectDebitInstructionType>;
  possibleErrors?: Maybe<Array<Maybe<PossibleErrorType>>>;
};

export type SetUpDirectDebitInstructionInput = {
  accountNumber: Scalars["String"];
  bankDetails: BankDetailsInput;
  ledgerId: Scalars["ID"];
  validFrom: Scalars["DateTime"];
};

/**
 * Allow customers to set/update their vehicle's charging preferences.
 *
 * The possible errors that can be raised are:
 *
 * - KT-CT-4301: Unable to find device for given account.
 * - KT-CT-4321: Serializer validation error.
 * - KT-CT-4353: An error occurred while trying to update your charging preferences.
 * - KT-CT-1113: Disabled GraphQL field requested.
 */
export type SetVehicleChargingPreferences = {
  __typename?: "SetVehicleChargingPreferences";
  krakenflexDevice?: Maybe<KrakenFlexDeviceType>;
  possibleErrors?: Maybe<Array<Maybe<PossibleErrorType>>>;
};

/** Choose one of the following settlement point options. */
export enum SettlementPointOptions {
  HbBusavg = "HB_BUSAVG",
  HbHouston = "HB_HOUSTON",
  HbHubavg = "HB_HUBAVG",
  HbNorth = "HB_NORTH",
  HbPan = "HB_PAN",
  HbSouth = "HB_SOUTH",
  HbWest = "HB_WEST",
  LzAen = "LZ_AEN",
  LzCps = "LZ_CPS",
  LzHouston = "LZ_HOUSTON",
  LzLcra = "LZ_LCRA",
  LzNorth = "LZ_NORTH",
  LzRaybn = "LZ_RAYBN",
  LzSouth = "LZ_SOUTH",
  LzWest = "LZ_WEST",
}

/**
 * The possible errors that can be raised are:
 *
 * - KT-CT-4122: Invalid email.
 * - KT-CT-8203: Received an invalid quote code.
 * - KT-CT-1113: Disabled GraphQL field requested.
 */
export type ShareGoodsQuote = {
  __typename?: "ShareGoodsQuote";
  possibleErrors?: Maybe<Array<Maybe<PossibleErrorType>>>;
  /** Goods quote shared. */
  share?: Maybe<GoodsQuoteShare>;
};

export type ShareGoodsQuoteInput = {
  /** The email to share the quote with. */
  email: Scalars["String"];
  /** The quote to share. */
  quoteCode: Scalars["String"];
};

/**
 * Options to indicate if the account requires a switch or a move-in request.
 *
 * `PENDING_SWITCH_REQUEST`: means the customer is switching retail electricity companies while staying at
 * their current location.
 *
 * `PENDING_MOVE_IN_REQUEST`: means the customer is moving to a new location.
 *
 * **Note**
 * This type doesn't include the other enrollment type enums because the `AddPropertyToShellAccount` mutation
 * is intended to be used only by the enrollment process, therefore it is limited to just two options
 * ,`Move-In`, and `Switch`.
 */
export enum ShellAccountEnrollmentTypeEnum {
  PendingMoveInRequest = "PENDING_MOVE_IN_REQUEST",
  PendingSwitchRequest = "PENDING_SWITCH_REQUEST",
}

/** Possible statuses for a KrakenFlex site auth. */
export enum SiteAuthStatus {
  Expired = "EXPIRED",
  Revoked = "REVOKED",
  Unknown = "UNKNOWN",
  Valid = "VALID",
}

/** Sizes for elements. */
export type SizedItemInterface = {
  /** The measurement of the element. */
  width?: Maybe<ItemSizeType>;
};

/** The smart control action choices, i.e. suspend or unsuspend. */
export enum SmartControlAction {
  Suspend = "SUSPEND",
  Unsuspend = "UNSUSPEND",
}

export type SmartControlInput = {
  /** The smart control action, i.e. suspend or unsuspend. */
  action: SmartControlAction;
  /** The ID of the device. */
  deviceId: Scalars["ID"];
};

export type SmartFlexBattery = SmartFlexDeviceInterface & {
  __typename?: "SmartFlexBattery";
  /** Active alert message(s) for a device, showing the latest first. */
  alerts?: Maybe<Array<Maybe<SmartFlexDeviceAlertInterface>>>;
  /** The type of device. */
  deviceType: KrakenFlexDeviceTypes;
  /** A UUID that identifies this device registration. Re-registering this device will result in a different ID. */
  id: Scalars["ID"];
  /** Make of the battery. */
  make?: Maybe<Scalars["String"]>;
  /** Model of the battery. */
  model?: Maybe<Scalars["String"]>;
  /** The user-friendly name for the device. */
  name?: Maybe<Scalars["String"]>;
  /** The current onboarding wizard for a device. */
  onboardingWizard?: Maybe<SmartFlexOnboardingWizard>;
  /** The device's preference details. */
  preferences?: Maybe<SmartFlexDevicePreferencesInterface>;
  /** The third-party that enables control of this device. */
  provider: ProviderChoices;
  /** Information about the current status of this device. */
  status: SmartFlexDeviceStatusInterface;
};

/** Information about a charge point that has been registered with Kraken Flex. */
export type SmartFlexChargePoint = SmartFlexDeviceInterface & {
  __typename?: "SmartFlexChargePoint";
  /** Active alert message(s) for a device, showing the latest first. */
  alerts?: Maybe<Array<Maybe<SmartFlexDeviceAlertInterface>>>;
  /** The user's preferences for charging using this charge point. */
  chargingPreferences?: Maybe<SmartFlexVehicleChargingPreferences>;
  /** The type of device. */
  deviceType: KrakenFlexDeviceTypes;
  /** A UUID that identifies this device registration. Re-registering this device will result in a different ID. */
  id: Scalars["ID"];
  /** The make of the charge point, e.g. myenergi. */
  make?: Maybe<Scalars["String"]>;
  /** The model of the charge point, e.g. Zappi. */
  model?: Maybe<Scalars["String"]>;
  /** The user-friendly name for the device. */
  name?: Maybe<Scalars["String"]>;
  /** The current onboarding wizard for a device. */
  onboardingWizard?: Maybe<SmartFlexOnboardingWizard>;
  /** The device's preference details. */
  preferences?: Maybe<SmartFlexDevicePreferencesInterface>;
  /** The third-party that enables control of this device. */
  provider: ProviderChoices;
  /** Information about the current status of this device. */
  status: SmartFlexDeviceStatusInterface;
};

/** The current status of a registered charge point. */
export type SmartFlexChargePointStatus = SmartFlexDeviceStatusInterface & {
  __typename?: "SmartFlexChargePointStatus";
  /** The current status of the device. */
  current?: Maybe<SmartFlexDeviceLifecycleStatus>;
  /** The current state of this SmartFlex device state machine. */
  currentState?: Maybe<SmartFlexDeviceState>;
  /** Whether control of the device is currently disabled. */
  isSuspended?: Maybe<Scalars["Boolean"]>;
  /** Information about the limits for the SoC. */
  stateOfChargeLimit?: Maybe<StateOfChargeLimit>;
  /** The reason for the most recent failed test dispatch (if any). */
  testDispatchFailureReason?: Maybe<TestDispatchAssessmentFailureReason>;
};

/** Information about a device that has been registered for Smart Flex. */
export type SmartFlexDevice = SmartFlexDeviceInterface & {
  __typename?: "SmartFlexDevice";
  /** Active alert message(s) for a device, showing the latest first. */
  alerts?: Maybe<Array<Maybe<SmartFlexDeviceAlertInterface>>>;
  /** The type of device. */
  deviceType: KrakenFlexDeviceTypes;
  /** A UUID that identifies this device registration. Re-registering this device will result in a different ID. */
  id: Scalars["ID"];
  /** The user-friendly name for the device. */
  name?: Maybe<Scalars["String"]>;
  /** The current onboarding wizard for a device. */
  onboardingWizard?: Maybe<SmartFlexOnboardingWizard>;
  /** The device's preference details. */
  preferences?: Maybe<SmartFlexDevicePreferencesInterface>;
  /** The third-party that enables control of this device. */
  provider: ProviderChoices;
  /** Information about the current status of this device. */
  status: SmartFlexDeviceStatusInterface;
};

/** Information about an alert relevant to a device registered for Smart Flex. */
export type SmartFlexDeviceAlert = SmartFlexDeviceAlertInterface & {
  __typename?: "SmartFlexDeviceAlert";
  /** A device alert message. */
  message?: Maybe<Scalars["String"]>;
  /** When a device alert message is published. */
  publishedAt?: Maybe<Scalars["DateTime"]>;
};

export type SmartFlexDeviceAlertInterface = {
  /** A device alert message. */
  message?: Maybe<Scalars["String"]>;
  /** When a device alert message is published. */
  publishedAt?: Maybe<Scalars["DateTime"]>;
};

/** Some general information about a device. */
export type SmartFlexDeviceInterface = {
  /** Active alert message(s) for a device, showing the latest first. */
  alerts?: Maybe<Array<Maybe<SmartFlexDeviceAlertInterface>>>;
  /** The type of device. */
  deviceType: KrakenFlexDeviceTypes;
  /** A UUID that identifies this device registration. Re-registering this device will result in a different ID. */
  id: Scalars["ID"];
  /** The user-friendly name for the device. */
  name?: Maybe<Scalars["String"]>;
  /** The current onboarding wizard for a device. */
  onboardingWizard?: Maybe<SmartFlexOnboardingWizard>;
  /** The device's preference details. */
  preferences?: Maybe<SmartFlexDevicePreferencesInterface>;
  /** The third-party that enables control of this device. */
  provider: ProviderChoices;
  /** Information about the current status of this device. */
  status: SmartFlexDeviceStatusInterface;
};

/** The current lifecycle status of a KrakenFlex device on the smarter tariff API. */
export enum SmartFlexDeviceLifecycleStatus {
  FailedOnboardingTest = "FAILED_ONBOARDING_TEST",
  Live = "LIVE",
  Onboarding = "ONBOARDING",
  OnboardingTestInProgress = "ONBOARDING_TEST_IN_PROGRESS",
  PendingLive = "PENDING_LIVE",
  Retired = "RETIRED",
}

export type SmartFlexDevicePreferenceSchedule = {
  __typename?: "SmartFlexDevicePreferenceSchedule";
  /** Day of week schedule applies to. */
  dayOfWeek: DayOfWeek;
  /** Maximum value set for the schedule. */
  max: Scalars["Float"];
  /** Minimum value set for the schedule. */
  min?: Maybe<Scalars["Float"]>;
  /** Time of day the preference applies. */
  time: Scalars["Time"];
};

export type SmartFlexDevicePreferences = SmartFlexDevicePreferencesInterface & {
  __typename?: "SmartFlexDevicePreferences";
  /** The device's preference mode. */
  mode: PreferencesModeChoices;
  /** The schedules of the device's preference. */
  schedules?: Maybe<Array<Maybe<SmartFlexDevicePreferenceSchedule>>>;
  /** The target type of the preference. */
  targetType: PreferencesTargetType;
  /** The unit of the preference schedules' min and max values. */
  unit: PreferencesUnitChoices;
};

export type SmartFlexDevicePreferencesInput = {
  /** The ID of the device. */
  deviceId: Scalars["ID"];
  /** The mode of the schedule. */
  mode: PreferencesModeChoices;
  /** The schedule with the preference details. */
  schedules: Array<InputMaybe<PreferencesScheduleInput>>;
  /** The unit of the min and max values in the preferences schedule. */
  unit: PreferencesUnitChoices;
};

/** Details about a device's preferences. */
export type SmartFlexDevicePreferencesInterface = {
  /** The device's preference mode. */
  mode: PreferencesModeChoices;
  /** The schedules of the device's preference. */
  schedules?: Maybe<Array<Maybe<SmartFlexDevicePreferenceSchedule>>>;
  /** The target type of the preference. */
  targetType: PreferencesTargetType;
  /** The unit of the preference schedules' min and max values. */
  unit: PreferencesUnitChoices;
};

export enum SmartFlexDeviceState {
  /** Authentication Complete - ready to start test (if needed) or pending live where auth or telemetry is delayed. */
  AuthenticationComplete = "AUTHENTICATION_COMPLETE",
  /** Authentication Failed - failed to connect and ready to restart authentication and authorization. */
  AuthenticationFailed = "AUTHENTICATION_FAILED",
  /** Authentication Pending - ready to start authentication and authorization, or auth is in progress. */
  AuthenticationPending = "AUTHENTICATION_PENDING",
  /** Manual Boosting (e.g. bump charging) - user has overridden the schedule to immediately boost (e.g. bump charge now). */
  Boosting = "BOOSTING",
  /** Lost Connection - lost connection to the device, ready to re-auth (if not temporary / automatic fix). */
  LostConnection = "LOST_CONNECTION",
  /** Retired - deleted / de-authed (re-auth not possible, re-register device to onboard again). */
  Retired = "RETIRED",
  /** Setup Complete - test is complete (if needed) and device is live, but not ready for smart control. */
  SetupComplete = "SETUP_COMPLETE",
  /** Smart Control Capable - live and ready for smart control (e.g. at home and plugged in) but none is scheduled. */
  SmartControlCapable = "SMART_CONTROL_CAPABLE",
  /** Smart Control in Progress - smart control (e.g. smart charging) is scheduled or is currently occurring. */
  SmartControlInProgress = "SMART_CONTROL_IN_PROGRESS",
  /** Smart Control Not Available - not currently capable of smart control (e.g. away from home or unplugged). */
  SmartControlNotAvailable = "SMART_CONTROL_NOT_AVAILABLE",
  /** Smart Control Off (suspended) - smart control has been (temporarily) disabled (e.g. by the user with holiday mode). */
  SmartControlOff = "SMART_CONTROL_OFF",
  /** Test Charge Failed - connection or smart control test has failed or could not start, ready to retry test. */
  TestChargeFailed = "TEST_CHARGE_FAILED",
  /** Test Charge in Progress - connection and smart control test has successfully started and is occurring. */
  TestChargeInProgress = "TEST_CHARGE_IN_PROGRESS",
  /** Test Charge Not Available - not currently capable of smart control test (e.g. away from home or unplugged). */
  TestChargeNotAvailable = "TEST_CHARGE_NOT_AVAILABLE",
}

/** Information about the current status of a device registered for Smart Flex. */
export type SmartFlexDeviceStatus = SmartFlexDeviceStatusInterface & {
  __typename?: "SmartFlexDeviceStatus";
  /** The current status of the device. */
  current?: Maybe<SmartFlexDeviceLifecycleStatus>;
  /** The current state of this SmartFlex device state machine. */
  currentState?: Maybe<SmartFlexDeviceState>;
  /** Whether control of the device is currently disabled. */
  isSuspended?: Maybe<Scalars["Boolean"]>;
};

export type SmartFlexDeviceStatusInterface = {
  /** The current status of the device. */
  current?: Maybe<SmartFlexDeviceLifecycleStatus>;
  /** The current state of this SmartFlex device state machine. */
  currentState?: Maybe<SmartFlexDeviceState>;
  /** Whether control of the device is currently disabled. */
  isSuspended?: Maybe<Scalars["Boolean"]>;
};

/** An interface for a list item in a SmartFlex onboarding step. */
export type SmartFlexListItemInterface = {
  /** A unique identifier for this list item. */
  id?: Maybe<Scalars["ID"]>;
};

/** A type that returns True if the device(s) were successfully registered. */
export type SmartFlexOnboardingDeviceRegistration =
  SmartFlexOnboardingStepInterface & {
    __typename?: "SmartFlexOnboardingDeviceRegistration";
    /** Returns true if the device(s) were successfully registered. */
    deviceRegistered?: Maybe<Scalars["Boolean"]>;
    /** A unique identifier for this onboarding step. */
    id?: Maybe<Scalars["ID"]>;
  };

/** The next step of the SmartFlex onboarding wizard. */
export type SmartFlexOnboardingStepInterface = {
  /** A unique identifier for this onboarding step. */
  id?: Maybe<Scalars["ID"]>;
};

export type SmartFlexOnboardingWizard = {
  __typename?: "SmartFlexOnboardingWizard";
  /** A Backend Screen that renders the SmartFlex onboarding wizard. */
  backendScreen?: Maybe<BackendScreenType>;
  /**
   * The completed steps for all onboarding wizards that are currently in progress.
   * Note:
   * - The last step is the most recent one.
   * - If an onboarding journey is completed, it will not be included in this list.
   */
  completedSteps?: Maybe<Array<Maybe<SmartFlexOnboardingStepInterface>>>;
  /** The next step of the SmartFlex onboarding wizard. Returns `None` if the onboarding journey is completed. */
  currentStep?: Maybe<SmartFlexOnboardingStepInterface>;
  /** A unique identifier for this SmartFlex onboarding wizard. */
  id: Scalars["ID"];
};

export type SmartFlexOnboardingWizardBackendScreenArgs = {
  maxVersionSupported?: InputMaybe<Scalars["Int"]>;
};

export type SmartFlexThermostat = SmartFlexDeviceInterface & {
  __typename?: "SmartFlexThermostat";
  /** Active alert message(s) for a device, showing the latest first. */
  alerts?: Maybe<Array<Maybe<SmartFlexDeviceAlertInterface>>>;
  /** The type of device. */
  deviceType: KrakenFlexDeviceTypes;
  /** A UUID that identifies this device registration. Re-registering this device will result in a different ID. */
  id: Scalars["ID"];
  /** The manufacturer of the thermostat. */
  make?: Maybe<Scalars["String"]>;
  /** The user-friendly name for the thermostat. */
  name?: Maybe<Scalars["String"]>;
  /** The current onboarding wizard for a device. */
  onboardingWizard?: Maybe<SmartFlexOnboardingWizard>;
  /** The device's preference details. */
  preferences?: Maybe<SmartFlexDevicePreferencesInterface>;
  /** The third-party that enables control of this device. */
  provider: ProviderChoices;
  /** Information about the current status of this device. */
  status: SmartFlexDeviceStatusInterface;
};

export type SmartFlexThermostatStatus = SmartFlexDeviceStatusInterface & {
  __typename?: "SmartFlexThermostatStatus";
  /** The current status of the device. */
  current?: Maybe<SmartFlexDeviceLifecycleStatus>;
  /** The current state of this SmartFlex device state machine. */
  currentState?: Maybe<SmartFlexDeviceState>;
  /** Current ambient temperature reported by the thermostat. */
  currentTemperature?: Maybe<Temperature>;
  /** Whether control of the device is currently disabled. */
  isSuspended?: Maybe<Scalars["Boolean"]>;
  /** Current operation mode of the thermostat. */
  mode?: Maybe<ThermostatMode>;
  /** The target set point for cooling. */
  targetCoolTemperature?: Maybe<Temperature>;
  /** The target set point for heating. */
  targetHeatTemperature?: Maybe<Temperature>;
  /** The timestamp of the latest status update. */
  timestamp?: Maybe<Scalars["DateTime"]>;
};

/** Information about a vehicle that has been registered with Kraken Flex. */
export type SmartFlexVehicle = SmartFlexDeviceInterface & {
  __typename?: "SmartFlexVehicle";
  /** Active alert message(s) for a device, showing the latest first. */
  alerts?: Maybe<Array<Maybe<SmartFlexDeviceAlertInterface>>>;
  /** The user's preferences for charging this vehicle. */
  chargingPreferences?: Maybe<SmartFlexVehicleChargingPreferences>;
  /** The type of device. */
  deviceType: KrakenFlexDeviceTypes;
  /** A UUID that identifies this device registration. Re-registering this device will result in a different ID. */
  id: Scalars["ID"];
  /** The make of the vehicle, e.g. Tesla. */
  make?: Maybe<Scalars["String"]>;
  /** The model of the vehicle, e.g. Model 3. */
  model?: Maybe<Scalars["String"]>;
  /** The user-friendly name for the device. */
  name?: Maybe<Scalars["String"]>;
  /** The current onboarding wizard for a device. */
  onboardingWizard?: Maybe<SmartFlexOnboardingWizard>;
  /** The device's preference details. */
  preferences?: Maybe<SmartFlexDevicePreferencesInterface>;
  /** The third-party that enables control of this device. */
  provider: ProviderChoices;
  /** Information about the current status of this device. */
  status: SmartFlexDeviceStatusInterface;
};

/** The user's preferences for charging, e.g. target SoC (State of Charge). */
export type SmartFlexVehicleChargingPreferences = {
  __typename?: "SmartFlexVehicleChargingPreferences";
  /** The maximum SoC (percentage). */
  maximumSoc?: Maybe<Scalars["Int"]>;
  /** The minimum SoC (percentage). */
  minimumSoc?: Maybe<Scalars["Int"]>;
  /** The target SoC to achieve on a weekday (percentage). */
  weekdayTargetSoc: Scalars["Int"];
  /** The time at which the target SoC should be achieved on a weekday. */
  weekdayTargetTime: Scalars["Time"];
  /** The target SoC to achieve on a weekend (percentage). */
  weekendTargetSoc: Scalars["Int"];
  /** The time at which the target SoC should be achieved on a weekend. */
  weekendTargetTime: Scalars["Time"];
};

/** The current status of a registered vehicle. */
export type SmartFlexVehicleStatus = SmartFlexDeviceStatusInterface & {
  __typename?: "SmartFlexVehicleStatus";
  /** The current status of the device. */
  current?: Maybe<SmartFlexDeviceLifecycleStatus>;
  /** The current state of this SmartFlex device state machine. */
  currentState?: Maybe<SmartFlexDeviceState>;
  /** Whether control of the device is currently disabled. */
  isSuspended?: Maybe<Scalars["Boolean"]>;
  /** Information about the limits for the SoC. */
  stateOfChargeLimit?: Maybe<StateOfChargeLimit>;
  /** The reason for the most recent failed test dispatch (if any). */
  testDispatchFailureReason?: Maybe<TestDispatchAssessmentFailureReason>;
};

export type SolarContestStat = {
  __typename?: "SolarContestStat";
  /** A custom account identifier given to each customer. */
  accountIdentifier?: Maybe<Scalars["String"]>;
  /** The amount generated in cents. */
  amountGeneratedInCents?: Maybe<Scalars["Decimal"]>;
  /** The amount generated in dollars. */
  amountGeneratedInDollars?: Maybe<Scalars["Decimal"]>;
  /** The amount generated in kWh over a full day. */
  amountGeneratedInKwh?: Maybe<Scalars["Decimal"]>;
  /** A custom identifier given to the particular account that was used to fetch solar stats. */
  customAccountIdentifier?: Maybe<Scalars["String"]>;
  /** The difference in exports between the given account and other exporters. */
  differenceInExports?: Maybe<Scalars["Decimal"]>;
  /** The rank of the customer based on the amount generated in kWh over a full day. */
  rank?: Maybe<Scalars["Int"]>;
};

export type SolarContestStats = {
  __typename?: "SolarContestStats";
  /** The solar contest stat. */
  solarContestStats?: Maybe<Array<Maybe<SolarContestStat>>>;
  /** The time at which the stats were fetched. */
  statsFetchedAt?: Maybe<Scalars["DateTime"]>;
};

export type SolarForecastedData = {
  __typename?: "SolarForecastedData";
  /** The date of the forecasted data. */
  deliveryDate?: Maybe<Scalars["Date"]>;
  /** The hour of the forecasted data. */
  hourEnding?: Maybe<Scalars["String"]>;
  /** The solar forecasted data in kWh. */
  solarForecast?: Maybe<Scalars["Decimal"]>;
};

/** An enumeration. */
export enum Songs {
  /** . */
  NoSongPreferred = "NO_SONG_PREFERRED",
}

/** Choose one of the following sort options. */
export enum SortByOptions {
  EqualTo = "EQUAL_TO",
  GreaterThan = "GREATER_THAN",
  GreaterThanOrEqualTo = "GREATER_THAN_OR_EQUAL_TO",
  LessThan = "LESS_THAN",
  LessThanOrEqualTo = "LESS_THAN_OR_EQUAL_TO",
}

/** Any special circumstances that the user has notified us about, which may entitle them to some specialist services. */
export type SpecialCircumstanceRecordType = {
  __typename?: "SpecialCircumstanceRecordType";
  id?: Maybe<Scalars["ID"]>;
  internalCode?: Maybe<Scalars["String"]>;
  summary?: Maybe<Scalars["String"]>;
};

export type SpecialCircumstanceRecordUnion =
  | SpecialCircumstanceRecordType
  | TemporarySpecialCircumstanceRecordType;

/** Information about the special circumstances that relate to a user. */
export type SpecialCircumstancesType = {
  __typename?: "SpecialCircumstancesType";
  /** Whether the user has consented for their data to be given to the appropriate industry or regulatory bodies. We typically only ask for this once, so this field can be used to decide whether to ask the user for their initial consent. */
  isSharingConsentGiven?: Maybe<Scalars["Boolean"]>;
  records?: Maybe<Array<Maybe<SpecialCircumstanceRecordUnion>>>;
};

export type StandingRatesType = {
  __typename?: "StandingRatesType";
  band?: Maybe<Scalars["String"]>;
  bandCategory?: Maybe<BandCategory>;
  bandSubcategory?: Maybe<Scalars["String"]>;
  currency?: Maybe<Scalars["String"]>;
  description?: Maybe<Scalars["String"]>;
  /** Returns a list of EFLs that are applicable to the resolved product. */
  efls?: Maybe<Array<Maybe<EflType>>>;
  id?: Maybe<Scalars["String"]>;
  loadZone?: Maybe<LoadZone>;
  /** Price per unit is returned in decimal format. For example: Decimal('0.10'). */
  pricePerUnit?: Maybe<Scalars["Decimal"]>;
  serviceProvider?: Maybe<ServiceProvider>;
  taxCode?: Maybe<Scalars["String"]>;
  taxable?: Maybe<Scalars["Boolean"]>;
  unitType?: Maybe<UnitType>;
};

export type StandingRatesTypeEflsArgs = {
  language?: InputMaybe<EflLanguage>;
  loadZone?: InputMaybe<LoadZone>;
};

/**
 * Create a wizard for onboarding a device with SmartFlex.
 *
 * The possible errors that can be raised are:
 *
 * - KT-CT-4321: Serializer validation error.
 * - KT-CT-1113: Disabled GraphQL field requested.
 * - KT-CT-1111: Unauthorized.
 * - KT-CT-1112: 'Authorization' header not provided.
 */
export type StartSmartFlexOnboarding = {
  __typename?: "StartSmartFlexOnboarding";
  possibleErrors?: Maybe<Array<Maybe<PossibleErrorType>>>;
  /** The wizard created for onboarding the device with SmartFlex. */
  wizard?: Maybe<SmartFlexOnboardingWizard>;
};

export type StartSmartFlexOnboardingInput = {
  /** The account to which the device should be registered. */
  accountNumber: Scalars["String"];
  /** The property where the device is located/charged. */
  propertyId: Scalars["Int"];
};

/**
 * Attempt to start a test charge.
 *
 * The possible errors that can be raised are:
 *
 * - KT-CT-4371: Onboarding wizard ID is invalid.
 * - KT-CT-4372: Simultaneous attempts to update onboarding process.
 * - KT-CT-4375: Incorrect or missing parameters for SmartFlex onboarding step.
 * - KT-CT-4376: Unable to complete onboarding step. Please try agan later.
 * - KT-CT-4377: Invalid onboarding step ID.
 * - KT-CT-4378: Invalid input or step id. Please make sure you are using the correct step id and providing the expected input params.
 * - KT-CT-4379: Vehicle is not ready for a test charge.
 * - KT-CT-1113: Disabled GraphQL field requested.
 * - KT-CT-1111: Unauthorized.
 * - KT-CT-1112: 'Authorization' header not provided.
 */
export type StartTestChargeForSmartFlexOnboarding = {
  __typename?: "StartTestChargeForSmartFlexOnboarding";
  possibleErrors?: Maybe<Array<Maybe<PossibleErrorType>>>;
  /** The wizard created for onboarding the device with SmartFlex. */
  wizard?: Maybe<SmartFlexOnboardingWizard>;
};

export type StateOfChargeLimit = {
  __typename?: "StateOfChargeLimit";
  /** Whether or not target SoC exceeds upper SoC limit. */
  isLimitViolated?: Maybe<Scalars["Boolean"]>;
  /** Time of the latest SoC limit reading. */
  timestamp?: Maybe<Scalars["String"]>;
  /** Maximum level of charge allowed by the battery relative to its capacity (in percent). */
  upperSocLimit?: Maybe<Scalars["Int"]>;
};

/** A statement is a billing document that contains all entries on a ledger during a period of time. A customer can understand how their ledger's balance has changed by looking at each statement in series. */
export type StatementBillingDocumentConnectionTypeConnection = {
  __typename?: "StatementBillingDocumentConnectionTypeConnection";
  /** Number of nodes in the edge. */
  edgeCount: Scalars["Int"];
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<StatementBillingDocumentConnectionTypeEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  /** Total number of nodes. */
  totalCount: Scalars["Int"];
};

/** A Relay edge containing a `StatementBillingDocumentConnectionType` and its cursor. */
export type StatementBillingDocumentConnectionTypeEdge = {
  __typename?: "StatementBillingDocumentConnectionTypeEdge";
  /** A cursor for use in pagination */
  cursor: Scalars["String"];
  /** The item at the end of the edge */
  node?: Maybe<StatementBillingDocumentType>;
};

/** A statement is a billing document that contains all entries on a ledger during a period of time. A customer can understand how their ledger's balance has changed by looking at each statement in series. */
export type StatementBillingDocumentType = {
  __typename?: "StatementBillingDocumentType";
  /** Billing document that annuls this statement. */
  annulledBy?: Maybe<AnnulmentBillingDocumentType>;
  /** The earliest charge date of the statement. */
  earliestChargeAt?: Maybe<Scalars["DateTime"]>;
  /** The end of the statement's period. */
  endAt: Scalars["DateTime"];
  /** The date and time the statement was sent to the customer. */
  firstIssuedAt?: Maybe<Scalars["DateTime"]>;
  id?: Maybe<Scalars["Int"]>;
  /** The unique reference of the statement that can be used for identifying the statement externally. */
  identifier?: Maybe<Scalars["String"]>;
  /** The latest charge date of the statement. */
  latestChargeAt?: Maybe<Scalars["DateTime"]>;
  /** URL to the PDF of the statement. */
  pdfUrl?: Maybe<Scalars["String"]>;
  /** The start of the statement's period. */
  startAt: Scalars["DateTime"];
};

/** An enumeration. */
export enum StatementReversalsAfterClose {
  /** All charges have been reversed after the statement was closed. */
  All = "ALL",
  /** No reversals after the statement was closed. */
  None = "NONE",
  /** The statement has not been closed yet. */
  NotClosed = "NOT_CLOSED",
  /** Some charges have been reversed after the statement was closed. */
  Some = "SOME",
}

export type StatementTotalType = {
  __typename?: "StatementTotalType";
  /** The gross total amount for the statement (in minor currency units). */
  grossTotal?: Maybe<Scalars["Int"]>;
  /** The net total amount for the statement (in minor currency units). */
  netTotal?: Maybe<Scalars["Int"]>;
  /** The total amount of tax on the statement (in minor currency units). */
  taxTotal?: Maybe<Scalars["Int"]>;
};

export type StatementType = BillInterface &
  ConstituentInterface & {
    __typename?: "StatementType";
    attachments?: Maybe<BillingAttachmentConnectionTypeConnection>;
    /** The type of the bill. */
    billType?: Maybe<BillTypeEnum>;
    /** This field returns the closing balance of an issued statement. */
    closingBalance?: Maybe<Scalars["Int"]>;
    /** The last day of consumption that this statement includes. */
    consumptionEndDate?: Maybe<Scalars["Date"]>;
    /** The first day of consumption that this statement includes. */
    consumptionStartDate?: Maybe<Scalars["Date"]>;
    /** The date of the constituent bill covered from. */
    fromDate?: Maybe<Scalars["Date"]>;
    /** Retrieve the held status of a account statement. */
    heldStatus?: Maybe<HeldStatus>;
    /** The ID of the constituent bill. */
    id?: Maybe<Scalars["ID"]>;
    /** Whether the bill originated in Kraken or externally. */
    isExternalBill?: Maybe<Scalars["Boolean"]>;
    /** The date the bill was sent to the customer. */
    issuedDate?: Maybe<Scalars["Date"]>;
    /** This field returns the opening balance of a statement. */
    openingBalance?: Maybe<Scalars["Int"]>;
    /** The date the bill is due to be paid. */
    paymentDueDate?: Maybe<Scalars["Date"]>;
    /** How many charges have been reversed after the close date. */
    reversalsAfterClose: StatementReversalsAfterClose;
    /** Current status of the associated statement. */
    status?: Maybe<AccountStatementStatus>;
    /**
     * Requesting this field generates a temporary URL at which bill is available.
     *         This URL will expire after approximately an hour.  It is intended for redirection purposes,
     *         NOT persistence in any form (e.g. inclusion in emails or the body of a web page).
     *         This field can raise an error with errorClass NOT_FOUND if the bill document has not
     *         been created/issued yet. This field is deprecated use 'attachments' field instead.
     * @deprecated The 'temporaryUrl' field is deprecated.
     *
     * This field is deprecated. Use the 'attachments' field instead.
     *
     * - Marked as deprecated on 2024-09-16.
     * - Will be removed on 2025-09-01.
     */
    temporaryUrl?: Maybe<Scalars["String"]>;
    /** Email recipient address. */
    toAddress?: Maybe<Scalars["String"]>;
    /** The date of the constituent bill covered to. */
    toDate?: Maybe<Scalars["Date"]>;
    /** The total amounts for all charges on the statement. */
    totalCharges?: Maybe<StatementTotalType>;
    /** The total amounts for all credits on the statement. */
    totalCredits?: Maybe<StatementTotalType>;
    /** Transactions on the bill. */
    transactions?: Maybe<TransactionConnectionTypeConnection>;
    /** Email recipient user ID. */
    userId?: Maybe<Scalars["Int"]>;
  };

export type StatementTypeAttachmentsArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
};

export type StatementTypeTransactionsArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
};

export type StatisticOutput = {
  __typename?: "StatisticOutput";
  /** Monetary cost of the statistic (excluding tax), if applicable. */
  costExclTax?: Maybe<EstimatedMoneyType>;
  /** Monetary cost of the statistic (including tax), if applicable. */
  costInclTax?: Maybe<EstimatedMoneyType>;
  /** Description of the statistic for the parent node. */
  description?: Maybe<Scalars["String"]>;
  /** Display label of the statistic for the parent node. */
  label?: Maybe<Scalars["String"]>;
  /** The type of statistic being measured for the parent node. */
  type?: Maybe<ReadingStatisticTypeEnum>;
  /** Consumption / generation value of the statistic, if applicable. */
  value?: Maybe<Scalars["Decimal"]>;
};

/**
 * Stores the ACH payment instruction.
 *
 * The possible errors that can be raised are:
 *
 * - KT-CT-3923: Unauthorized.
 * - KT-CT-1113: Disabled GraphQL field requested.
 * - KT-CT-1111: Unauthorized.
 * - KT-CT-1112: 'Authorization' header not provided.
 */
export type StoreAchDirectDebitInstruction = {
  __typename?: "StoreAchDirectDebitInstruction";
  /** The payment instruction. */
  paymentInstruction?: Maybe<PaymentInstructionType>;
  possibleErrors?: Maybe<Array<Maybe<PossibleErrorType>>>;
};

/** Input for setting up an ACH payment instruction. */
export type StoreAchDirectDebitInstructionInput = {
  /** The account number. */
  accountNumber: Scalars["String"];
  /** Token from your financial institution. */
  secretKey: Scalars["String"];
};

/**
 * Mutation for storing credit score for an account.
 *
 * The possible errors that can be raised are:
 *
 * - KT-CT-4123: Unauthorized.
 * - KT-US-3814: Received invalid data. Unable to store credit score.
 * - KT-US-4414: Received an invalid postcode.
 * - KT-US-4117: Cannot find an account user with the given account number.
 * - KT-CT-1113: Disabled GraphQL field requested.
 * - KT-CT-1111: Unauthorized.
 * - KT-CT-1112: 'Authorization' header not provided.
 */
export type StoreCreditScore = {
  __typename?: "StoreCreditScore";
  possibleErrors?: Maybe<Array<Maybe<PossibleErrorType>>>;
  result?: Maybe<Scalars["Boolean"]>;
};

export type StoreCreditScoreInputType = {
  accountNumber: Scalars["String"];
  /** This is the number of collections. */
  collectionCount?: InputMaybe<Scalars["Int"]>;
  fraudAlerts?: InputMaybe<Array<InputMaybe<FraudAlerts>>>;
  fraudDetectionHash?: InputMaybe<Scalars["String"]>;
  /** A boolean that represents if credit is frozen or not. */
  isFrozen?: InputMaybe<Scalars["Boolean"]>;
  /** This is a list of previous addresses. */
  previousAddresses?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  riskBracket?: InputMaybe<RiskBracket>;
  score: Scalars["Int"];
  /** Credit report's status. */
  status?: InputMaybe<CreditDataStatus>;
};

/**
 * Store a new payment instruction created through the embedded process.
 *
 * The possible errors that can be raised are:
 *
 * - KT-CT-4177: Unauthorized.
 * - KT-CT-1113: Disabled GraphQL field requested.
 * - KT-CT-1111: Unauthorized.
 * - KT-CT-1112: 'Authorization' header not provided.
 */
export type StorePaymentInstruction = {
  __typename?: "StorePaymentInstruction";
  /** The stored payment instruction. */
  paymentInstruction?: Maybe<PaymentInstructionType>;
  possibleErrors?: Maybe<Array<Maybe<PossibleErrorType>>>;
};

/** The input for storing a new payment instruction created through the embedded process. */
export type StorePaymentInstructionInput = {
  /** The account number. */
  accountNumber: Scalars["String"];
  /** The type of the new payment instruction. */
  instructionType: PaymentType;
  /**
   * **WARNING: Will be mandatory in future versions**
   *
   *  The ledger ID to which the instructions will be linked.
   */
  ledgerId?: InputMaybe<Scalars["String"]>;
  /** The datetime from which the instruction is vaild. */
  validFrom: Scalars["DateTime"];
  /** The vendor's reference for this payment method. */
  vendorReference: Scalars["String"];
};

export type SubdomainOutput = {
  __typename?: "SubdomainOutput";
  isValid?: Maybe<Scalars["Boolean"]>;
};

/**
 * The possible errors that can be raised are:
 *
 * - KT-CT-5514: Unable to submit feedback.
 * - KT-CT-5511: The feedback_id should be provided for feedback source.
 * - KT-CT-5512: The feedback doesn't match the account.
 * - KT-CT-1113: Disabled GraphQL field requested.
 * - KT-CT-1111: Unauthorized.
 * - KT-CT-1112: 'Authorization' header not provided.
 */
export type SubmitCustomerFeedback = {
  __typename?: "SubmitCustomerFeedback";
  customerFeedback?: Maybe<CustomerFeedbackType>;
  possibleErrors?: Maybe<Array<Maybe<PossibleErrorType>>>;
};

/**
 * Mutation for executing the repayment request use case.
 *
 * The possible errors that can be raised are:
 *
 * - KT-CT-1132: Unauthorized.
 * - KT-CT-3927: Invalid Amount.
 * - KT-CT-3928: Idempotency key used for another repayment request.
 * - KT-CT-1113: Disabled GraphQL field requested.
 * - KT-CT-1111: Unauthorized.
 * - KT-CT-1112: 'Authorization' header not provided.
 */
export type SubmitRepaymentRequest = {
  __typename?: "SubmitRepaymentRequest";
  possibleErrors?: Maybe<Array<Maybe<PossibleErrorType>>>;
  /** The newly created repayment request. */
  repaymentRequest?: Maybe<RequestRepaymentOutputType>;
};

export type SupplementaryLedgerInterface = {
  /** The current final balance of the ledger in pence. */
  currentBalance?: Maybe<Scalars["Int"]>;
  id?: Maybe<Scalars["ID"]>;
  ledgerType?: Maybe<Scalars["String"]>;
  /** The display name of the ledger. */
  name?: Maybe<Scalars["String"]>;
  paymentAdequacy?: Maybe<PaymentAdequacyDetailsType>;
};

/** Ledgers provide the foundation of Kraken’s bookkeeping functionality. Similar to a bank account, they allow us to keep track of financial activity on a particular Kraken account. */
export type SupplementaryLedgerType = SupplementaryLedgerInterface & {
  __typename?: "SupplementaryLedgerType";
  /** The current final balance of the ledger in pence. */
  currentBalance?: Maybe<Scalars["Int"]>;
  id?: Maybe<Scalars["ID"]>;
  ledgerType?: Maybe<Scalars["String"]>;
  /** The display name of the ledger. */
  name?: Maybe<Scalars["String"]>;
  paymentAdequacy?: Maybe<PaymentAdequacyDetailsType>;
};

export type SupplyPointConnectionTypeConnection = {
  __typename?: "SupplyPointConnectionTypeConnection";
  /** Number of nodes in the edge. */
  edgeCount: Scalars["Int"];
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<SupplyPointConnectionTypeEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  /** Total number of nodes. */
  totalCount: Scalars["Int"];
};

/** A Relay edge containing a `SupplyPointConnectionType` and its cursor. */
export type SupplyPointConnectionTypeEdge = {
  __typename?: "SupplyPointConnectionTypeEdge";
  /** A cursor for use in pagination */
  cursor: Scalars["String"];
  /** The item at the end of the edge */
  node?: Maybe<SupplyPointType>;
};

/** Represents a SupplyPoint. */
export type SupplyPointType = CommonSupplyPointInterface & {
  __typename?: "SupplyPointType";
  /** The external identifier of the supply point. */
  externalIdentifier?: Maybe<Scalars["String"]>;
  /** The ID of the supply point. */
  id: Scalars["ID"];
  /** The market this supply point belongs to. */
  marketName: Scalars["String"];
};

/**
 * Suspend control of a device while away from home, e.g. on holiday.
 *
 * This is to prevent charging during that period.
 *
 * The possible errors that can be raised are:
 *
 * - KT-CT-4301: Unable to find device for given account.
 * - KT-CT-4358: Unable to suspend device control.
 * - KT-CT-1113: Disabled GraphQL field requested.
 */
export type SuspendDeviceControl = {
  __typename?: "SuspendDeviceControl";
  krakenflexDevice?: Maybe<KrakenFlexDeviceType>;
  possibleErrors?: Maybe<Array<Maybe<PossibleErrorType>>>;
};

export type SystemWidePrice = {
  __typename?: "SystemWidePrice";
  /** The settlement point price for each settlement point. */
  priceAndSettlementPoint?: Maybe<
    Array<Maybe<SystemWidePriceForSettlementPoint>>
  >;
  /** The timestamp of the settlement point price. */
  timestamp?: Maybe<Scalars["DateTime"]>;
};

export type SystemWidePriceForSettlementPoint = {
  __typename?: "SystemWidePriceForSettlementPoint";
  /** The settlement point price in $/kWh. */
  price?: Maybe<Scalars["Decimal"]>;
  /** The Hub/Load Zone for which the settlement point price was forecasted for. */
  settlementPoint?: Maybe<SettlementPointOptions>;
};

export type TaskResult = {
  __typename?: "TaskResult";
  /** The error message if the task failed. */
  error?: Maybe<Scalars["String"]>;
  /** The result of the task. */
  result?: Maybe<Scalars["JSONString"]>;
  /** The status of the task. */
  status?: Maybe<TaskStatusEnum>;
};

/** An enumeration. */
export enum TaskStatusEnum {
  Failed = "FAILED",
  Finished = "FINISHED",
  Started = "STARTED",
}

/** Returns a list of standing rates and consumption rates that are calculated for the TDSP. */
export type TdspRatesType = {
  __typename?: "TdspRatesType";
  /** The consumption rate will represent all consumption-based charges from the TDSP. */
  consumptionRates?: Maybe<Array<Maybe<ConsumptionRatesType>>>;
  /** The standing rate will be referenced for other recurring charges or one-off meter fees. */
  standingRates?: Maybe<Array<Maybe<StandingRatesType>>>;
};

export type Temperature = {
  __typename?: "Temperature";
  /** Temperature scale. */
  scale?: Maybe<TemperatureScale>;
  /** Numeric value representing the temperature. */
  value?: Maybe<Scalars["Decimal"]>;
};

/** An enumeration. */
export enum TemperatureScale {
  Celsius = "CELSIUS",
  Fahrenheit = "FAHRENHEIT",
}

/** Any special circumstances that the user has notified us about, which may entitle them to some specialist services. These circumstances have an end date, after which they will not longer apply. Having young children is an example of this in the UK. */
export type TemporarySpecialCircumstanceRecordType = {
  __typename?: "TemporarySpecialCircumstanceRecordType";
  expiryDate?: Maybe<Scalars["Date"]>;
  id?: Maybe<Scalars["ID"]>;
  internalCode?: Maybe<Scalars["String"]>;
  summary?: Maybe<Scalars["String"]>;
};

export type TermsAndConditionsType = {
  __typename?: "TermsAndConditionsType";
  brandCode?: Maybe<Scalars["String"]>;
  effectiveFrom?: Maybe<Scalars["DateTime"]>;
  /** The html of the terms and conditions document rendered as a JSON string. */
  html?: Maybe<Scalars["String"]>;
  /** The markdown text of the terms and conditions. */
  markdown?: Maybe<Scalars["String"]>;
  name?: Maybe<Scalars["String"]>;
  pdfUrl?: Maybe<Scalars["String"]>;
  version?: Maybe<Scalars["String"]>;
};

/**
 * A step which indicates that the Tesla permission scopes need to be granted by the user in order
 * to register their vehicle.
 */
export type TeslaModifyScopes = SmartFlexOnboardingStepInterface & {
  __typename?: "TeslaModifyScopes";
  /** A unique identifier for this onboarding step. */
  id?: Maybe<Scalars["ID"]>;
  /** Returns the URI to update the permission scopes. */
  modifyScopesUri?: Maybe<Scalars["String"]>;
  /** The redirect URI to return to after updating the permission scopes. */
  redirectUri?: Maybe<Scalars["String"]>;
};

/**
 * A step which indicates that the Tesla registration failed and the user must
 * re-grant permission scopes to register their vehicle.
 */
export type TeslaRegistrationFailed = SmartFlexOnboardingStepInterface & {
  __typename?: "TeslaRegistrationFailed";
  /** A unique identifier for this onboarding step. */
  id?: Maybe<Scalars["ID"]>;
  /** Returns:- `true` if user wants to update permissions scopes.- `false` if user wants to retry the OAuth journey.- `null` if user has not made a decision. */
  modifyScopesSelected?: Maybe<Scalars["Boolean"]>;
};

/** A step which indicates that the user must add the given URI as a virtual key in their Tesla account. */
export type TeslaSetupVirtualKey = SmartFlexOnboardingStepInterface & {
  __typename?: "TeslaSetupVirtualKey";
  /** A unique identifier for this onboarding step. */
  id?: Maybe<Scalars["ID"]>;
  /** The name of the key to add. */
  key?: Maybe<Scalars["String"]>;
  /** The URI to add as a virtual key in the user's Tesla account. */
  uri?: Maybe<Scalars["String"]>;
};

/** A step which indicates if we're able to test charge the user's vehicle(s). */
export type TestCharge = SmartFlexOnboardingStepInterface & {
  __typename?: "TestCharge";
  /** A unique identifier for this onboarding step. */
  id?: Maybe<Scalars["ID"]>;
  /** Returns `true` if the device is ready for a test charge. */
  isReady?: Maybe<Scalars["Boolean"]>;
  /** Returns `true` if the test charge has started. */
  isStarted?: Maybe<Scalars["Boolean"]>;
  /** Returns the dispatch status of test charge. */
  status?: Maybe<TestDispatchStatus>;
};

/** The reason (if any) that we believe a test dispatch (test charge) did not succeed. */
export enum TestDispatchAssessmentFailureReason {
  AssessmentsFailed = "ASSESSMENTS_FAILED",
  DeviceDisconnected = "DEVICE_DISCONNECTED",
  Error = "ERROR",
  None = "NONE",
  NotAtHome = "NOT_AT_HOME",
  SocLimitReached = "SOC_LIMIT_REACHED",
  UnableToCommunicate = "UNABLE_TO_COMMUNICATE",
  Unknown = "UNKNOWN",
}

/** All possible test dispatch statuses. */
export enum TestDispatchStatus {
  Complete = "COMPLETE",
  Failed = "FAILED",
  Triggered = "TRIGGERED",
}

export type TextInterface = {
  /** The text alignment. */
  textAlignment?: Maybe<Alignment>;
  /** The text style, i.e. header, body. */
  textStyle?: Maybe<TextStyleV1>;
  /** The text content. */
  value: Scalars["String"];
};

/**
 * The style is the typographical hierarchy.
 * These are Typescale Categories from the Mobile Design System (Figma).
 */
export enum TextStyleV1 {
  Body1 = "BODY1",
  Body2 = "BODY2",
  ButtonText = "BUTTON_TEXT",
  Callout1 = "CALLOUT1",
  Callout2 = "CALLOUT2",
  Callout3 = "CALLOUT3",
  InputTitle = "INPUT_TITLE",
  Small1 = "SMALL1",
  Small2 = "SMALL2",
  Small3 = "SMALL3",
  Tabular = "TABULAR",
  Title1 = "TITLE1",
  Title2 = "TITLE2",
  Title3 = "TITLE3",
  Title4 = "TITLE4",
  Title5 = "TITLE5",
  Title6 = "TITLE6",
}

/** A block of text. */
export type TextType = IdentifiableInterface &
  TextInterface & {
    __typename?: "TextType";
    /** Unique identifier of the object. */
    id?: Maybe<Scalars["ID"]>;
    /** The text alignment. */
    textAlignment?: Maybe<Alignment>;
    /** The text style, i.e. header, body. */
    textStyle?: Maybe<TextStyleV1>;
    /** The name of the object's type. */
    typename?: Maybe<Scalars["String"]>;
    /** The text content. */
    value: Scalars["String"];
  };

/** A smart thermostat device associated with this Kraken account. */
export type ThermostatDeviceType = {
  __typename?: "ThermostatDeviceType";
  /** Device's reported current indoor temperature, in F. */
  currentTemp?: Maybe<Scalars["String"]>;
  /** Device type. */
  deviceType?: Maybe<Scalars["String"]>;
  /** Device vendor. */
  deviceVendor?: Maybe<Scalars["String"]>;
  /** Whether this device can be controlled by Kraken. */
  enabled?: Maybe<Scalars["Boolean"]>;
  /** Hold is currently in effect. */
  hasActiveHold?: Maybe<Scalars["Boolean"]>;
  /** HVAC operations mode. */
  hvacMode?: Maybe<Scalars["String"]>;
  /** Unique device ID. */
  id?: Maybe<Scalars["String"]>;
  /** User-fiendly name for this device. */
  name?: Maybe<Scalars["String"]>;
  /** Device's authentication status. */
  tokenStatus?: Maybe<SiteAuthStatus>;
};

export enum ThermostatMode {
  /** The thermostat is in automatic mode. */
  Auto = "AUTO",
  /** The thermostat is in cool-only mode. */
  Cooling = "COOLING",
  /** The thermostat is in heat-only mode. */
  Heating = "HEATING",
  /** The thermostat is off. */
  Off = "OFF",
}

/**
 * Complete the registration of a device.
 *
 * The possible errors that can be raised are:
 *
 * - KT-CT-4321: Serializer validation error.
 * - KT-CT-4322: Unable to complete registration error.
 * - KT-CT-1113: Disabled GraphQL field requested.
 * - KT-CT-1111: Unauthorized.
 * - KT-CT-1112: 'Authorization' header not provided.
 */
export type ThirdPartyCompleteDeviceRegistration = {
  __typename?: "ThirdPartyCompleteDeviceRegistration";
  possibleErrors?: Maybe<Array<Maybe<PossibleErrorType>>>;
  /** The response showing account validity and optional tariff information. */
  success?: Maybe<ThirdPartyCompleteDeviceRegistrationType>;
};

export type ThirdPartyCompleteDeviceRegistrationType = {
  __typename?: "ThirdPartyCompleteDeviceRegistrationType";
  /** If the account is valid. */
  isAccountValid: Scalars["Boolean"];
  /** If a switch to the required tariff is in progress. */
  isTariffSwitchInProgress: Scalars["Boolean"];
  /** The tariff the account is on, if the switch is complete. */
  tariff?: Maybe<ThirdPartyTariffsType>;
};

/** Type for the third party organization. */
export type ThirdPartyOrganizationType = {
  __typename?: "ThirdPartyOrganizationType";
  name: Scalars["String"];
};

export type ThirdPartyTariffIntervalType = {
  __typename?: "ThirdPartyTariffIntervalType";
  /** Day(s) of the week of the tariff interval. */
  daysOfWeek: Array<Maybe<Scalars["String"]>>;
  /** Local 24hr end time of the tariff interval (format hh:mm). */
  endTime: Scalars["String"];
  /** Local 24hr start time of the tariff interval (format hh:mm). */
  startTime: Scalars["String"];
  /** The type of tariff this is (e.g. peak or offPeak). */
  tariffType: Scalars["String"];
};

export type ThirdPartyTariffType = {
  __typename?: "ThirdPartyTariffType";
  /** Currency according to the ISO 4217 standard. */
  currency: Scalars["String"];
  /** Price per kWh in the given currency. */
  price: Scalars["Decimal"];
  /** The type of tariff this is (e.g. peak or offPeak). */
  type: Scalars["String"];
};

export type ThirdPartyTariffsType = {
  __typename?: "ThirdPartyTariffsType";
  /** Tariff intervals of the charging location. */
  tariffIntervals: Array<Maybe<ThirdPartyTariffIntervalType>>;
  /** Tariff rates of the charging location. */
  tariffs: Array<Maybe<ThirdPartyTariffType>>;
};

/** An enumeration. */
export enum TimeOfUse {
  Day = "DAY",
  Night = "NIGHT",
}

/**
 * Toggle Kraken's ability to control given thermostat.
 *
 * The possible errors that can be raised are:
 *
 * - KT-CT-4810: Unable to find matching device for this request.
 * - KT-CT-1113: Disabled GraphQL field requested.
 */
export type ToggleThermostatControl = {
  __typename?: "ToggleThermostatControl";
  possibleErrors?: Maybe<Array<Maybe<PossibleErrorType>>>;
  thermostat?: Maybe<GetThermostatDetailsType>;
};

/** The input type for toggling Kraken's ability to control this thermostat. */
export type ToggleThermostatControlInput = {
  /** The account number. */
  accountNumber: Scalars["String"];
  /** The thermostat ID. */
  id: Scalars["String"];
  /** Whether device should be enabled or not. */
  setEnabled: Scalars["Boolean"];
};

/** An enumeration. */
export enum TokenStatuses {
  /** Authenticated. */
  Authenticated = "AUTHENTICATED",
  /** De-authenticated. */
  Deauthenticated = "DEAUTHENTICATED",
  /** Deleted. */
  Deleted = "DELETED",
  /** Migrated. */
  Migrated = "MIGRATED",
  /** New. */
  New = "NEW",
}

export type TransactionAmountType = {
  __typename?: "TransactionAmountType";
  /** The gross amount (in minor currency units). */
  gross?: Maybe<Scalars["Int"]>;
  /** The net amount (in minor currency units). */
  net?: Maybe<Scalars["Int"]>;
  /** The amount of tax (in minor currency units). */
  tax?: Maybe<Scalars["Int"]>;
};

export type TransactionConnectionTypeConnection = {
  __typename?: "TransactionConnectionTypeConnection";
  /** Number of nodes in the edge. */
  edgeCount: Scalars["Int"];
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<TransactionConnectionTypeEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  /** Total number of nodes. */
  totalCount: Scalars["Int"];
};

/** A Relay edge containing a `TransactionConnectionType` and its cursor. */
export type TransactionConnectionTypeEdge = {
  __typename?: "TransactionConnectionTypeEdge";
  /** A cursor for use in pagination */
  cursor: Scalars["String"];
  /** The item at the end of the edge */
  node?: Maybe<TransactionType>;
};

/** Transactions are a record of money being added or subtracted from the overall account balance */
export type TransactionType = {
  /** Unique identifier of the account the transaction belongs to. */
  accountNumber?: Maybe<Scalars["String"]>;
  /**
   * Gross amount including tax (when payable). Refer to the `amounts` field for a breakdown of this information.
   * @deprecated The 'amount' field is deprecated.
   *
   * Use `amounts` instead for a breakdown of the relevant net, tax, and gross amounts.
   *
   * - Marked as deprecated on 2023-12-06.
   * - Will be removed on 2024-06-01.
   */
  amount?: Maybe<Scalars["Int"]>;
  /** The net, tax and gross amounts for the transaction. Note: for payments and repayments, only the net amount is returned. */
  amounts?: Maybe<TransactionAmountType>;
  /** The customer's resulting balance after this transaction has been applied, in the smallest unit of currency. */
  balanceCarriedForward?: Maybe<Scalars["Int"]>;
  /** The unique identifier for the most recent billing document linked with the transaction.Note: a transaction may be linked with multiple documents, but this field will only return the identifier for the most recent billing document. */
  billingDocumentIdentifier?: Maybe<Scalars["ID"]>;
  /** The date time when the transaction is created. */
  createdAt?: Maybe<Scalars["DateTime"]>;
  /** Returns True if the transaction is linked with a statement. */
  hasStatement?: Maybe<Scalars["Boolean"]>;
  id?: Maybe<Scalars["ID"]>;
  /**
   * Deprecated.
   * @deprecated The 'isAccountCharge' field is deprecated.
   *
   * This information is provided by the __typename introspection query.
   *
   * - Marked as deprecated on 2020-06-19.
   * - Will be removed on 2022-11-15.
   */
  isAccountCharge?: Maybe<Scalars["Boolean"]>;
  /**
   * Deprecated.
   * @deprecated The 'isAccountPayment' field is deprecated.
   *
   * This information is provided by the __typename introspection query.
   *
   * - Marked as deprecated on 2020-06-19.
   * - Will be removed on 2022-11-15.
   */
  isAccountPayment?: Maybe<Scalars["Boolean"]>;
  /**
   * Deprecated.
   * @deprecated The 'isCredit' field is deprecated.
   *
   * This information is provided by the __typename introspection query.
   *
   * - Marked as deprecated on 2020-06-19.
   * - Will be removed on 2022-11-15.
   */
  isCredit?: Maybe<Scalars["Boolean"]>;
  /** Whether the statement this transaction is on has been held. A held statement is not sent to a customer automatically, but is instead marked for manual attention by operations staff.  Returns False if a statement is not linked with the transaction. */
  isHeld?: Maybe<Scalars["Boolean"]>;
  /** Whether this transaction has been issued on any billing document.Note: Look for the most recently issued transaction instead of looking through all transactions as some accounts may have initial transactions that were not issued.This will return False if the transaction is not associated with any billing documents. */
  isIssued?: Maybe<Scalars["Boolean"]>;
  isReversed: Scalars["Boolean"];
  /** Returns the note field value for the transaction, which contains additional info. */
  note?: Maybe<Scalars["String"]>;
  postedDate?: Maybe<Scalars["Date"]>;
  /** Returns the reason. */
  reasonCode?: Maybe<Scalars["String"]>;
  /**
   * Returns None if a statement is not linked with the transaction.
   * @deprecated The 'statementId' field is deprecated.
   *
   * Use `billingDocumentIdentifier` instead.
   *
   * - Marked as deprecated on 2023-11-30.
   * - Will be removed on 2024-06-01.
   */
  statementId?: Maybe<Scalars["ID"]>;
  title?: Maybe<Scalars["String"]>;
};

/** Transaction types which will be included or excluded, depending on the input argument. */
export enum TransactionTypeFilter {
  /** For filtering/excluding energy charge transactions: Gas or Electricity. */
  EnergyCharges = "ENERGY_CHARGES",
  /** For filtering/excluding imported charge transactions. */
  ImportedCharges = "IMPORTED_CHARGES",
  /** For filtering/excluding imported credit transactions. */
  ImportedCredits = "IMPORTED_CREDITS",
  /** For filtering/excluding imported payment transactions. */
  ImportedPayments = "IMPORTED_PAYMENTS",
  /** For filtering/excluding imported repayment transactions. */
  ImportedRepayments = "IMPORTED_REPAYMENTS",
  /** For filtering/excluding issued transactions. */
  IssuedTransactions = "ISSUED_TRANSACTIONS",
  /** For filtering/excluding water charge transactions. */
  WaterCharges = "WATER_CHARGES",
}

export enum TransactionsOrderBy {
  PostedDateAsc = "POSTED_DATE_ASC",
  PostedDateDesc = "POSTED_DATE_DESC",
}

/**
 * Trigger balance transfer between accounts.
 *
 * The possible errors that can be raised are:
 *
 * - KT-CT-3822: Unauthorized.
 * - KT-CT-3823: Unauthorized.
 * - KT-CT-9701: Balance transfer to same account is not allowed.
 * - KT-CT-9702: Balance transfer is not support for debit account with Zero balance.
 * - KT-CT-9703: Balance transfer is not supported for debit account.
 * - KT-CT-9704: Balance transfer amount should be non-zero.
 * - KT-CT-1113: Disabled GraphQL field requested.
 * - KT-CT-1111: Unauthorized.
 * - KT-CT-1112: 'Authorization' header not provided.
 */
export type TransferLedgerBalance = {
  __typename?: "TransferLedgerBalance";
  /** Balance transfer details. */
  balanceTransfer?: Maybe<AccountBalanceTransferType>;
  possibleErrors?: Maybe<Array<Maybe<PossibleErrorType>>>;
};

export type TransferLedgerBalanceInputType = {
  /** The amount ( in lowest unit ) to transfer. If the amount is negative,the effect is reversed  (the source ledger's balance increases and the destination ledger's balance decreases). */
  amount: Scalars["Int"];
  /** Optional short note about transfer reason. */
  note?: InputMaybe<Scalars["String"]>;
  /** Account's ledger from which the requested amount is debited. */
  sourceAccountLedger: AccountLedgerInput;
  /** Account's ledger to which the requested amount is credited. */
  targetAccountLedger: AccountLedgerInput;
};

/**
 * Transfer Loyalty Points between users.
 *
 * The possible errors that can be raised are:
 *
 * - KT-CT-1111: Unauthorized.
 * - KT-CT-9205: Insufficient Loyalty Points.
 * - KT-CT-9204: Negative or zero points set.
 * - KT-CT-9208: Invalid posted at datetime.
 * - KT-CT-9209: Negative Loyalty Points balance.
 * - KT-CT-9210: Unhandled Loyalty Points exception.
 * - KT-CT-1113: Disabled GraphQL field requested.
 */
export type TransferLoyaltyPointsBetweenUsers = {
  __typename?: "TransferLoyaltyPointsBetweenUsers";
  /** The number of OctoPoints that were transferred. */
  pointsTransferred?: Maybe<Scalars["Int"]>;
  possibleErrors?: Maybe<Array<Maybe<PossibleErrorType>>>;
};

/** The input type for transferring Loyalty Points. */
export type TransferLoyaltyPointsBetweenUsersInput = {
  /** The account number. */
  accountNumber: Scalars["String"];
  /** The number of Loyalty Points to transfer. */
  points: Scalars["Int"];
  /** The account user receiving the points. */
  receivingUserId: Scalars["String"];
};

/**
 * The possible errors that can be raised are:
 *
 * - KT-CT-8710: Invalid data.
 * - KT-CT-1113: Disabled GraphQL field requested.
 * - KT-CT-1111: Unauthorized.
 * - KT-CT-1112: 'Authorization' header not provided.
 */
export type TriggerPostUploadOperations = {
  __typename?: "TriggerPostUploadOperations";
  operationsTriggered?: Maybe<Scalars["Boolean"]>;
  possibleErrors?: Maybe<Array<Maybe<PossibleErrorType>>>;
};

export type TriggerType = {
  __typename?: "TriggerType";
  /** The ID of the trigger. */
  id: Scalars["String"];
};

/**
 * Note this is deprecated and will be removed in the future.
 * Please use the `UtilityFiltersOutput` type instead.
 */
export type TypedSourceOutput = {
  __typename?: "TypedSourceOutput";
  /** Reading direction is based on the utility generated or consumed by the customer. */
  readingDirection?: Maybe<ReadingDirectionType>;
  /** The frequency of the reading. */
  readingFrequencyType?: Maybe<ReadingFrequencyType>;
  readingQuality?: Maybe<ReadingQualityType>;
  sourceIdentifier?: Maybe<Scalars["String"]>;
  utility?: Maybe<UtilityType>;
};

/** An enumeration. */
export enum UsaFileAttachmentCategoryOptions {
  AmsIntervals = "ams_intervals",
  DailyErcotAddressReports = "daily_ercot_address_reports",
  MonthlyErcotAddressReports = "monthly_ercot_address_reports",
  UidEsiIdMappings = "uid_esi_id_mappings",
}

/** An enumeration. */
export enum Unit {
  KilowattHours = "KILOWATT_HOURS",
  MetersCubed = "METERS_CUBED",
}

/** An enumeration. */
export enum UnitType {
  ChargesIncurred = "CHARGES_INCURRED",
  CubicMetresConsumption = "CUBIC_METRES_CONSUMPTION",
  CurrencyYear = "CURRENCY_YEAR",
  DaysOnSupply = "DAYS_ON_SUPPLY",
  KvarhConsumption = "KVARH_CONSUMPTION",
  KvaCapacityDays = "KVA_CAPACITY_DAYS",
  KvaCapacityMonths = "KVA_CAPACITY_MONTHS",
  KvaDemandDays = "KVA_DEMAND_DAYS",
  KvaDemandMonths = "KVA_DEMAND_MONTHS",
  KwhConsumption = "KWH_CONSUMPTION",
  KwCapacityMonths = "KW_CAPACITY_MONTHS",
  KwDemand = "KW_DEMAND",
  LitresConsumption = "LITRES_CONSUMPTION",
  MegajoulesConsumption = "MEGAJOULES_CONSUMPTION",
  MonthsOnSupply = "MONTHS_ON_SUPPLY",
  PartnerChargesIncurred = "PARTNER_CHARGES_INCURRED",
  PerItem = "PER_ITEM",
  UnitsWa = "UNITS_WA",
  YearsOnSupplyPerDiem = "YEARS_ON_SUPPLY_PER_DIEM",
}

export type UnlinkUserFromLineResponse =
  | LineCommonError
  | LineUnlinkedResponse
  | LinkTokenNotFound;

/**
 * The possible errors that can be raised are:
 *
 * - KT-CT-7804: No fields present in the input for updating the APIException.
 * - KT-CT-7803: Received an invalid apiExceptionId.
 * - KT-CT-7809: Update results in no changes to API Exception.
 * - KT-CT-7805: Too many tags associated with this API Exception.
 * - KT-CT-7806: Cannot create duplicate tags for the same API exception.
 * - KT-CT-7801: Received an invalid operationsTeamId.
 * - KT-CT-7811: Received an invalid assignedUserId.
 * - KT-CT-7812: Support user is inactive.
 * - KT-CT-1113: Disabled GraphQL field requested.
 * - KT-CT-1111: Unauthorized.
 * - KT-CT-1112: 'Authorization' header not provided.
 */
export type UpdateApiException = {
  __typename?: "UpdateAPIException";
  /** The updated APIException. */
  apiException?: Maybe<ApiExceptionType>;
  possibleErrors?: Maybe<Array<Maybe<PossibleErrorType>>>;
};

export type UpdateApiExceptionInput = {
  /** The ID of the user assigned to handle this exception.If no user is provided, no user will be assigned to the exception. */
  assignedUserId?: InputMaybe<Scalars["Int"]>;
  /** The new category. If none is provided, the field won't be updated. */
  category?: InputMaybe<ApiExceptionCategories>;
  /** The new context. If none is provided, the field won't be updated. This will completely replace the existing context by the new one. */
  context?: InputMaybe<Scalars["JSONString"]>;
  /** The ID of the API Exception that will be updated. */
  id: Scalars["Int"];
  /** The new key date. If none is provided, the field won't be updated. */
  keyDate?: InputMaybe<Scalars["Date"]>;
  /** The ID of an operations team to handle this exception. If no team is provided, no team will be assigned to the exception. */
  operationsTeamId?: InputMaybe<Scalars["Int"]>;
  /** The new priority. If none is provided, the field won't be updated. */
  priority?: InputMaybe<ApiExceptionPriority>;
  /** The new resolution status. If none is provided, the field won't be updated. */
  resolutionStatus?: InputMaybe<ApiExceptionResolutionStatus>;
  /** The new resolution type. If none is provided, the field won't be updated. */
  resolutionType?: InputMaybe<ApiExceptionResolutionType>;
  /** The updated list of tags. If none is provided, the field won't be updated. */
  tags?: InputMaybe<Array<InputMaybe<ApiExceptionTags>>>;
};

/**
 * The possible errors that can be raised are:
 *
 * - KT-CT-7807: Received an invalid apiExceptionNoteId.
 * - KT-CT-7808: Unauthorized.
 * - KT-CT-1113: Disabled GraphQL field requested.
 * - KT-CT-1111: Unauthorized.
 * - KT-CT-1112: 'Authorization' header not provided.
 */
export type UpdateApiExceptionNote = {
  __typename?: "UpdateAPIExceptionNote";
  /** The updates APIExceptionNote. */
  apiException?: Maybe<ApiExceptionType>;
  possibleErrors?: Maybe<Array<Maybe<PossibleErrorType>>>;
};

export type UpdateApiExceptionNoteInput = {
  /** The ID of the API Exception note being updated. */
  apiExceptionNoteId: Scalars["ID"];
  /** The body of the note. */
  body: Scalars["String"];
};

/**
 * The possible errors that can be raised are:
 *
 * - KT-CT-4145: Invalid address.
 * - KT-CT-7123: Unauthorized.
 * - KT-CT-1113: Disabled GraphQL field requested.
 * - KT-CT-1111: Unauthorized.
 * - KT-CT-1112: 'Authorization' header not provided.
 */
export type UpdateAccountBillingAddress = {
  __typename?: "UpdateAccountBillingAddress";
  /** The updated account. */
  account?: Maybe<AccountType>;
  possibleErrors?: Maybe<Array<Maybe<PossibleErrorType>>>;
};

/**
 * Update the billing email for the input account number to the
 * received email value.
 *
 * The possible errors that can be raised are:
 *
 * - KT-CT-4123: Unauthorized.
 * - KT-CT-4122: Invalid email.
 * - KT-CT-1113: Disabled GraphQL field requested.
 * - KT-CT-1111: Unauthorized.
 * - KT-CT-1112: 'Authorization' header not provided.
 */
export type UpdateAccountBillingEmail = {
  __typename?: "UpdateAccountBillingEmail";
  /** Account that was changed. */
  account?: Maybe<AccountInterface>;
  possibleErrors?: Maybe<Array<Maybe<PossibleErrorType>>>;
};

/** Input fields for updating billing email for an account. */
export type UpdateAccountBillingEmailInput = {
  /** Account number for account. */
  accountNumber: Scalars["String"];
  /** The billing_email which can be up to 512 characters. Use null to unset billing_email. */
  billingEmail?: InputMaybe<Scalars["String"]>;
};

/**
 * Update a reference for a particular account and namespace.
 *
 * The possible errors that can be raised are:
 *
 * - KT-CT-4123: Unauthorized.
 * - KT-CT-8310: Invalid data.
 * - KT-CT-8311: Invalid data.
 * - KT-CT-1113: Disabled GraphQL field requested.
 * - KT-CT-1111: Unauthorized.
 * - KT-CT-1112: 'Authorization' header not provided.
 */
export type UpdateAccountReference = {
  __typename?: "UpdateAccountReference";
  accountReference?: Maybe<AccountReferenceType>;
  possibleErrors?: Maybe<Array<Maybe<PossibleErrorType>>>;
};

export type UpdateAccountUserCommsPreferencesInput = {
  emailFormat?: InputMaybe<EmailFormats>;
  fontSizeMultiplier?: InputMaybe<Scalars["Float"]>;
  isOptedInMeterReadingConfirmations?: InputMaybe<Scalars["Boolean"]>;
  isOptedInToClientMessages?: InputMaybe<Scalars["Boolean"]>;
  isOptedInToOfferMessages?: InputMaybe<Scalars["Boolean"]>;
  isOptedInToRecommendedMessages?: InputMaybe<Scalars["Boolean"]>;
  isOptedInToSmsMessages?: InputMaybe<Scalars["Boolean"]>;
  isOptedInToThirdPartyMessages?: InputMaybe<Scalars["Boolean"]>;
  isOptedInToUpdateMessages?: InputMaybe<Scalars["Boolean"]>;
  isUsingInvertedEmailColours?: InputMaybe<Scalars["Boolean"]>;
  preferredHoldMusic?: InputMaybe<Songs>;
};

export type UpdateAccountUserCommsPreferencesMutationInput = {
  clientMutationId?: InputMaybe<Scalars["String"]>;
  emailFormat?: InputMaybe<Scalars["String"]>;
  fontSizeMultiplier?: InputMaybe<Scalars["Float"]>;
  isOptedInMeterReadingConfirmations?: InputMaybe<Scalars["Boolean"]>;
  isOptedInToClientMessages?: InputMaybe<Scalars["Boolean"]>;
  isOptedInToOfferMessages?: InputMaybe<Scalars["Boolean"]>;
  isOptedInToRecommendedMessages?: InputMaybe<Scalars["Boolean"]>;
  isOptedInToSmsMessages?: InputMaybe<Scalars["Boolean"]>;
  isOptedInToThirdPartyMessages?: InputMaybe<Scalars["Boolean"]>;
  isOptedInToUpdateMessages?: InputMaybe<Scalars["Boolean"]>;
  isUsingInvertedEmailColours?: InputMaybe<Scalars["Boolean"]>;
  preferredHoldMusic?: InputMaybe<Scalars["String"]>;
};

/** Update the account user comms preferences. */
export type UpdateAccountUserCommsPreferencesMutationPayload = {
  __typename?: "UpdateAccountUserCommsPreferencesMutationPayload";
  clientMutationId?: Maybe<Scalars["String"]>;
  commsPreferences?: Maybe<AccountUserCommsPreferences>;
  emailFormat?: Maybe<Scalars["String"]>;
  errors?: Maybe<Array<Maybe<ErrorType>>>;
  fontSizeMultiplier?: Maybe<Scalars["Float"]>;
  isOptedInMeterReadingConfirmations?: Maybe<Scalars["Boolean"]>;
  isOptedInToClientMessages?: Maybe<Scalars["Boolean"]>;
  isOptedInToOfferMessages?: Maybe<Scalars["Boolean"]>;
  isOptedInToRecommendedMessages?: Maybe<Scalars["Boolean"]>;
  isOptedInToSmsMessages?: Maybe<Scalars["Boolean"]>;
  isOptedInToThirdPartyMessages?: Maybe<Scalars["Boolean"]>;
  isOptedInToUpdateMessages?: Maybe<Scalars["Boolean"]>;
  isUsingInvertedEmailColours?: Maybe<Scalars["Boolean"]>;
  preferredHoldMusic?: Maybe<Scalars["String"]>;
};

/**
 * Update the consents from an account user using consent management system
 *
 * The possible errors that can be raised are:
 *
 * - KT-CT-9014: Duplicate consent.
 * - KT-CT-9016: Consent management not enabled.
 * - KT-CT-9017: Consent type not found.
 * - KT-CT-1113: Disabled GraphQL field requested.
 * - KT-CT-1111: Unauthorized.
 * - KT-CT-1112: 'Authorization' header not provided.
 */
export type UpdateAccountUserConsents = {
  __typename?: "UpdateAccountUserConsents";
  /** All the consents for an account user. */
  accountUserConsents?: Maybe<AccountUserConsents>;
  possibleErrors?: Maybe<Array<Maybe<PossibleErrorType>>>;
};

export type UpdateAccountUserMutationInput = {
  clientMutationId?: InputMaybe<Scalars["String"]>;
  dateOfBirth?: InputMaybe<Scalars["Date"]>;
  email?: InputMaybe<Scalars["String"]>;
  familyName?: InputMaybe<Scalars["String"]>;
  givenName?: InputMaybe<Scalars["String"]>;
  landline?: InputMaybe<Scalars["String"]>;
  mobile?: InputMaybe<Scalars["String"]>;
  pronouns?: InputMaybe<Scalars["String"]>;
};

export type UpdateAccountUserMutationPayload = {
  __typename?: "UpdateAccountUserMutationPayload";
  clientMutationId?: Maybe<Scalars["String"]>;
  dateOfBirth?: Maybe<Scalars["Date"]>;
  email?: Maybe<Scalars["String"]>;
  errors?: Maybe<Array<Maybe<ErrorType>>>;
  familyName?: Maybe<Scalars["String"]>;
  givenName?: Maybe<Scalars["String"]>;
  landline?: Maybe<Scalars["String"]>;
  mobile?: Maybe<Scalars["String"]>;
  pronouns?: Maybe<Scalars["String"]>;
};

/**
 * The possible errors that can be raised are:
 *
 * - KT-CT-8225: Received an invalid purchaseId.
 * - KT-CT-8226: The provided purchase is not active.
 * - KT-CT-8206: Invalid data.
 * - KT-CT-8227: Available grants could not be applied.
 * - KT-CT-1113: Disabled GraphQL field requested.
 * - KT-CT-1111: Unauthorized.
 * - KT-CT-1112: 'Authorization' header not provided.
 */
export type UpdateActivePurchase = {
  __typename?: "UpdateActivePurchase";
  /** Goods purchase updated. */
  goodsPurchase?: Maybe<GoodsPurchase>;
  possibleErrors?: Maybe<Array<Maybe<PossibleErrorType>>>;
};

/**
 * Update an affiliate link of an existing sales agent.
 *
 * The possible errors that can be raised are:
 *
 * - KT-CT-7711: Invalid data.
 * - KT-CT-7713: Invalid data.
 * - KT-CT-7714: Invalid data.
 * - KT-CT-7715: Invalid data.
 * - KT-CT-1113: Disabled GraphQL field requested.
 * - KT-CT-1111: Unauthorized.
 * - KT-CT-1112: 'Authorization' header not provided.
 */
export type UpdateAffiliateLink = {
  __typename?: "UpdateAffiliateLink";
  affiliateLink?: Maybe<AffiliateLinkType>;
  possibleErrors?: Maybe<Array<Maybe<PossibleErrorType>>>;
};

export type UpdateAffiliateLinkInputType = {
  contactEmail?: InputMaybe<Scalars["String"]>;
  contactName?: InputMaybe<Scalars["String"]>;
  isBusiness?: InputMaybe<Scalars["Boolean"]>;
  landingUrl?: InputMaybe<Scalars["String"]>;
  /** The id of the affiliate link that is going to be edited. */
  linkId: Scalars["ID"];
  /** The organisation for whom to update the affiliate link for. */
  organisationId?: InputMaybe<Scalars["ID"]>;
  /**
   * Will be validated as follows:
   *
   * - should be at least two characters
   * - should only contain (letters, numbers, and Hyphen)
   * - should not contain bad words
   * - should not contain any of the reserved words including:
   *  affiliates, api, business, click, consul, developer, friends, kraken, mail, sendgrid, tech, webhooks, www, www2
   */
  subdomain?: InputMaybe<Scalars["String"]>;
  trainingStatus?: InputMaybe<Scalars["String"]>;
};

/**
 * Update an affiliate organisation.
 *
 * The possible errors that can be raised are:
 *
 * - KT-CT-7717: Invalid data.
 * - KT-CT-1113: Disabled GraphQL field requested.
 * - KT-CT-1111: Unauthorized.
 * - KT-CT-1112: 'Authorization' header not provided.
 */
export type UpdateAffiliateOrganisation = {
  __typename?: "UpdateAffiliateOrganisation";
  affiliateOrganisation?: Maybe<AffiliateOrganisationType>;
  possibleErrors?: Maybe<Array<Maybe<PossibleErrorType>>>;
};

export type UpdateAffiliateOrganisationInputType = {
  /** Is this partner allowed to specify payment methods other than Direct Debit in the import csv or API. */
  allowAlternativePaymentMethods?: InputMaybe<Scalars["Boolean"]>;
  /** Are meter point registrations limited for profile classes 1 and 2 for registrations from csv or API. */
  canRegisterBusinessMeterPoints?: InputMaybe<Scalars["Boolean"]>;
  /** Allow registration requests with customers without an email address. */
  canRegisterCustomersWithoutEmailAddress?: InputMaybe<Scalars["Boolean"]>;
  /** Allow registration requests with exiting account user emails to add to the portfolio belonging to the account user. */
  canRegisterPortfolioAccounts?: InputMaybe<Scalars["Boolean"]>;
  /** Allow performing tariff renewals via API. */
  canRenewTariffs?: InputMaybe<Scalars["Boolean"]>;
  /** Allow this partner access to the IVR support API (modify their own IVR handling through third party 'IVR Flow Editor'). */
  canUseIvrSupportApi?: InputMaybe<Scalars["Boolean"]>;
  /** Default Account Type. */
  defaultAccountType?: InputMaybe<AccountTypeChoices>;
  /** Restrict to field-sales-only products? This is only allowed for the 'field-sales' and 'events' sales channels. */
  isFieldSalesOnlyProduct?: InputMaybe<Scalars["Boolean"]>;
  name?: InputMaybe<Scalars["String"]>;
  /** The organisation that is going to be edited. */
  organisationId: Scalars["ID"];
  /** Sales Channel. */
  salesChannel?: InputMaybe<SalesChannelChoices>;
  /** Allow this partner to skip validation that ensures all meter points belong to the same address. */
  skipMeterPointAddressValidation?: InputMaybe<Scalars["Boolean"]>;
};

/**
 * Update the period of an agreement.
 *
 * The possible errors that can be raised are:
 *
 * - KT-CT-4178: No account found with given account number.
 * - KT-CT-1501: Agreement not found.
 * - KT-CT-1503: Agreement valid_to date must be later than valid_from date.
 * - KT-CT-1504: Account does not match with the agreement.
 * - KT-CT-1505: Unable to edit agreement.
 * - KT-CT-1506: Agreement period is not within the supply and property period.
 * - KT-CT-1113: Disabled GraphQL field requested.
 * - KT-CT-1111: Unauthorized.
 * - KT-CT-1112: 'Authorization' header not provided.
 */
export type UpdateAgreementPeriod = {
  __typename?: "UpdateAgreementPeriod";
  /** Account responsible for the update agreement. */
  account?: Maybe<AccountType>;
  possibleErrors?: Maybe<Array<Maybe<PossibleErrorType>>>;
};

export type UpdateAgreementPeriodInput = {
  /** A code that uniquely identifies the account. */
  accountNumber: Scalars["String"];
  /** A code that uniquely identifies the agreement. */
  agreementId: Scalars["ID"];
  /** The reason for the change. */
  reason?: InputMaybe<Scalars["String"]>;
  /** The start date of the agreement. */
  validFrom: Scalars["Date"];
  /** The end date of the agreement (exclusive). The agreement will end on midnight of this date, such that the previous day is the last day covered by this agreement. */
  validTo?: InputMaybe<Scalars["Date"]>;
};

/**
 * The possible errors that can be raised are:
 *
 * - KT-CT-3815: No active payment schedule found for this account.
 * - KT-CT-3941: Invalid data.
 * - KT-CT-3942: An unexpected error occurred.
 * - KT-CT-3947: An unexpected error occurred.
 * - KT-CT-3953: The payment schedule is not a balance triggered schedule.
 * - KT-CT-1113: Disabled GraphQL field requested.
 * - KT-CT-1111: Unauthorized.
 * - KT-CT-1112: 'Authorization' header not provided.
 */
export type UpdateAutoTopUpAmount = {
  __typename?: "UpdateAutoTopUpAmount";
  possibleErrors?: Maybe<Array<Maybe<PossibleErrorType>>>;
  /** The new schedule created. */
  schedule?: Maybe<PaymentScheduleType>;
};

/** Input type for updating the schedule auto top up amount for for an account. Requires an `account_number`, ledger_id and `payment_amount` to be provided. */
export type UpdateAutoTopUpAmountInput = {
  /** Account number to update the schedule auto top up amount for. */
  accountNumber: Scalars["String"];
  /** Specifies the ledger ID associated with the current schedule for updates. */
  ledgerId: Scalars["Int"];
  /** The new auto-top-up amount for the payment schedule. */
  paymentAmount: Scalars["Int"];
};

/**
 * Update the Collection Process Record from raised status to active
 *
 * The possible errors that can be raised are:
 *
 * - KT-CT-1111: Unauthorized.
 * - KT-CT-11201: No Collection Process Records associated with id.
 * - KT-CT-11202: No External reference provided.
 * - KT-CT-1113: Disabled GraphQL field requested.
 * - KT-CT-1111: Unauthorized.
 * - KT-CT-1112: 'Authorization' header not provided.
 */
export type UpdateCollectionProcessRecordToActive = {
  __typename?: "UpdateCollectionProcessRecordToActive";
  /** Whether the collection process was successfully updated. */
  collectionProcessActivated?: Maybe<UpdateCollectionProcessRecordToActiveOutputType>;
  possibleErrors?: Maybe<Array<Maybe<PossibleErrorType>>>;
};

export type UpdateCollectionProcessRecordToActiveInputType = {
  /** The reference code for the active flow. */
  externalReference?: InputMaybe<Scalars["String"]>;
  /** The Collection Process Record Number. */
  number: Scalars["String"];
};

/** Output for updating a Collection process Record to Active. */
export type UpdateCollectionProcessRecordToActiveOutputType = {
  __typename?: "UpdateCollectionProcessRecordToActiveOutputType";
  /** The number of the collection process record. */
  number?: Maybe<Scalars["String"]>;
  /** The current status of the repayment request. */
  status?: Maybe<CollectionProcessRecordStatusTypes>;
};

/**
 * Update the Collection Process Record from raised status to complete
 *
 * The possible errors that can be raised are:
 *
 * - KT-CT-1111: Unauthorized.
 * - KT-CT-11201: No Collection Process Records associated with id.
 * - KT-CT-11203: No Completion reason provided.
 * - KT-CT-11204: No Completion details provided.
 * - KT-CT-1113: Disabled GraphQL field requested.
 * - KT-CT-1111: Unauthorized.
 * - KT-CT-1112: 'Authorization' header not provided.
 */
export type UpdateCollectionProcessRecordToComplete = {
  __typename?: "UpdateCollectionProcessRecordToComplete";
  /** Whether the collection process was successfully updated. */
  collectionProcessComplete?: Maybe<UpdateCollectionProcessRecordToCompleteOutputType>;
  possibleErrors?: Maybe<Array<Maybe<PossibleErrorType>>>;
};

export type UpdateCollectionProcessRecordToCompleteInputType = {
  /** The Completion details for the Collection Process. */
  completionDetails?: InputMaybe<Scalars["String"]>;
  /** The Completion reason for the Collection Process. */
  completionReason?: InputMaybe<CollectionProcessRecordCompletionTypeChoices>;
  /** The Collection Process Record Number. */
  number: Scalars["String"];
};

/** Output for updating a Collection process Record to Complete. */
export type UpdateCollectionProcessRecordToCompleteOutputType = {
  __typename?: "UpdateCollectionProcessRecordToCompleteOutputType";
  /** The number of the collection process record. */
  number?: Maybe<Scalars["String"]>;
  /** The current status of the repayment request. */
  status?: Maybe<CollectionProcessRecordStatusTypes>;
};

/**
 * Update the comms delivery preference for the input account number to the
 * received commsDeliveryPreference value.
 *
 * The possible errors that can be raised are:
 *
 * - KT-CT-4123: Unauthorized.
 * - KT-CT-4136: Cannot set comms preference to email when account has no email.
 * - KT-CT-1113: Disabled GraphQL field requested.
 * - KT-CT-1111: Unauthorized.
 * - KT-CT-1112: 'Authorization' header not provided.
 */
export type UpdateCommsDeliveryPreference = {
  __typename?: "UpdateCommsDeliveryPreference";
  account?: Maybe<AccountInterface>;
  possibleErrors?: Maybe<Array<Maybe<PossibleErrorType>>>;
};

/** Input fields for updating comms delivery preferences for an account */
export type UpdateCommsDeliveryPreferenceInput = {
  accountNumber: Scalars["String"];
  commsDeliveryPreference: CommsDeliveryPreference;
};

/**
 * Update the language preference for an account.
 *
 * The possible errors that can be raised are:
 *
 * - KT-CT-4123: Unauthorized.
 * - KT-US-4101: Cannot create an account.
 * - KT-CT-1113: Disabled GraphQL field requested.
 */
export type UpdateLanguagePreference = {
  __typename?: "UpdateLanguagePreference";
  languagePreference?: Maybe<LanguagePreferenceChoices>;
  possibleErrors?: Maybe<Array<Maybe<PossibleErrorType>>>;
};

export type UpdateMessageTagsInput = {
  clientMutationId?: InputMaybe<Scalars["String"]>;
  /** The message to set the tags on. */
  messageRelayId: Scalars["ID"];
  /** The tag names to set on the message. */
  tagNames: Array<Scalars["String"]>;
  /** The tag code to set on the message. */
  taggerCode: Scalars["String"];
  /** The tag version to set on the message. */
  taggerVersion: Scalars["String"];
};

export type UpdateMessageTagsPayload = {
  __typename?: "UpdateMessageTagsPayload";
  clientMutationId?: Maybe<Scalars["String"]>;
  /** Confirmed tags. */
  tags: Array<InkTag>;
};

/**
 * Update existing metadata on an object.
 *
 * The possible errors that can be raised are:
 *
 * - KT-CT-8413: Invalid data.
 * - KT-CT-1113: Disabled GraphQL field requested.
 * - KT-CT-1111: Unauthorized.
 * - KT-CT-1112: 'Authorization' header not provided.
 */
export type UpdateMetadata = {
  __typename?: "UpdateMetadata";
  metadata?: Maybe<Metadata>;
  possibleErrors?: Maybe<Array<Maybe<PossibleErrorType>>>;
};

/** Update user's password. */
export type UpdatePassword = {
  __typename?: "UpdatePassword";
  /**
   *  The currently authenticated user.
   *
   *
   *
   * This field requires the `Authorization` header to be set.
   */
  viewer?: Maybe<AccountUserType>;
};

export type UpdatePasswordInput = {
  /** New password. */
  newPassword: Scalars["String"];
  /** Confirm new password. */
  newPasswordConfirmed: Scalars["String"];
  /** Old password. */
  oldPassword: Scalars["String"];
};

export type UpdatePurchaseInput = {
  /** The account number. */
  accountNumber: Scalars["String"];
  /** A JSON object containing client parameters to store on the purchase. */
  clientParams?: InputMaybe<Scalars["JSONString"]>;
  /** A JSON object containing market parameters to store on the purchase. */
  marketParams?: InputMaybe<Scalars["JSONString"]>;
  /** The purchase ID. */
  purchaseId: Scalars["ID"];
  /** Products being purchased. */
  saleItems: Array<InputMaybe<ProductToPurchaseInput>>;
};

/**
 * Updates user data associated with a quote request.
 *
 * The possible errors that can be raised are:
 *
 * - KT-US-4615: Received an invalid quote code.
 * - KT-US-4619: No quoted supply point associated with this quote request.
 * - KT-US-4621: Unable to update quoted supply point.
 * - KT-CT-1113: Disabled GraphQL field requested.
 * - KT-CT-1111: Unauthorized.
 * - KT-CT-1112: 'Authorization' header not provided.
 */
export type UpdateQuoteUser = {
  __typename?: "UpdateQuoteUser";
  possibleErrors?: Maybe<Array<Maybe<PossibleErrorType>>>;
  /** Whether the user data was stored successfully. */
  stored?: Maybe<Scalars["Boolean"]>;
};

export type UpdateQuoteUserInput = {
  /** The first line of the user's address. */
  addressLine1?: InputMaybe<Scalars["String"]>;
  /** The first line of the user's address. */
  addressLine2?: InputMaybe<Scalars["String"]>;
  /** The user's city. */
  city?: InputMaybe<Scalars["String"]>;
  /** The user's desired switch date. */
  desiredSwitchDate?: InputMaybe<Scalars["Date"]>;
  /** The email address associated with this quote. */
  email?: InputMaybe<Scalars["String"]>;
  /** The user's family name. */
  familyName?: InputMaybe<Scalars["String"]>;
  /** The user's given name. */
  givenName?: InputMaybe<Scalars["String"]>;
  /** The user's phone number. */
  phone?: InputMaybe<Scalars["String"]>;
  /** The user's post code (zip code). */
  postCode?: InputMaybe<Scalars["String"]>;
  /** Quote code. */
  quoteCode: Scalars["String"];
  /** The user's state. */
  state?: InputMaybe<Scalars["String"]>;
};

/**
 * The possible errors that can be raised are:
 *
 * - KT-CT-4124: Unauthorized.
 * - KT-CT-1113: Disabled GraphQL field requested.
 * - KT-CT-1111: Unauthorized.
 * - KT-CT-1112: 'Authorization' header not provided.
 */
export type UpdateShellAccountCommsPreference = {
  __typename?: "UpdateShellAccountCommsPreference";
  isUpdated?: Maybe<Scalars["Boolean"]>;
  possibleErrors?: Maybe<Array<Maybe<PossibleErrorType>>>;
};

export type UpdateShellAccountCommsPreferenceInputType = {
  emailAddress: Scalars["String"];
  fontSizeMultiplier?: InputMaybe<Scalars["Float"]>;
  optedInToClientMessages?: InputMaybe<Scalars["Boolean"]>;
  optedInToOfferMessages?: InputMaybe<Scalars["Boolean"]>;
  optedInToRecommendedMessages?: InputMaybe<Scalars["Boolean"]>;
  optedInToSms?: InputMaybe<Scalars["Boolean"]>;
  optedInToThirdParties?: InputMaybe<Scalars["Boolean"]>;
  useInvertedEmailColours?: InputMaybe<Scalars["Boolean"]>;
};

export type UpdateUserInput = {
  dateOfBirth?: InputMaybe<Scalars["Date"]>;
  /** User details. */
  details?: InputMaybe<Array<InputMaybe<DetailsInputType>>>;
  email?: InputMaybe<Scalars["String"]>;
  familyName?: InputMaybe<Scalars["String"]>;
  givenName?: InputMaybe<Scalars["String"]>;
  /** Because this field is clearable, null and the empty string are treated differently; passing null or omitting the field leaves the value as-is, but explicitly passing an empty string clears this value. */
  landline?: InputMaybe<Scalars["String"]>;
  /** Because this field is clearable, null and the empty string are treated differently; passing null or omitting the field leaves the value as-is, but explicitly passing an empty string clears this value. */
  mobile?: InputMaybe<Scalars["String"]>;
  /** How the user would like us to address them (e.g. 'she/her', 'they/them'). Because this field is clearable, null and the empty string are treated differently; passing null or omitting the field leaves the value as-is, but explicitly passing an empty string clears this value. */
  pronouns?: InputMaybe<Scalars["String"]>;
  /** The user's title. */
  title?: InputMaybe<Scalars["String"]>;
  /** The user for whom to perform the update. This is only needed when using an Organisation role. */
  userId?: InputMaybe<Scalars["String"]>;
};

/**
 * The possible errors that can be raised are:
 *
 * - KT-CT-5413: Invalid data.
 * - KT-CT-5414: Invalid data.
 * - KT-CT-1113: Disabled GraphQL field requested.
 * - KT-CT-1111: Unauthorized.
 * - KT-CT-1112: 'Authorization' header not provided.
 */
export type UpdateUserMutation = {
  __typename?: "UpdateUserMutation";
  possibleErrors?: Maybe<Array<Maybe<PossibleErrorType>>>;
  viewer?: Maybe<AccountUserType>;
};

/**
 * Information that should be used in the POST request to the S3 API.
 *
 * For more details on how to use fields please see [this](https://boto3.amazonaws.com/v1/documentation/api/latest/guide/s3-presigned-urls.html#generating-a-presigned-url-to-upload-a-file).
 */
export type UploadFileAttachmentPostRequest = {
  __typename?: "UploadFileAttachmentPostRequest";
  /** Required fields for presigned post. Fields are 'key','AWSAccessKeyId', 'policy', 'signature', 'x-amz-security-token'. */
  fields: Scalars["JSONString"];
  /** S3 key for resigned post. */
  key: Scalars["String"];
  /** S3 presigned post url. */
  url: Scalars["String"];
};

/**
 * Information that should be used in the POST request to the S3 API.
 *
 * For more details please see [this](https://boto3.amazonaws.com/v1/documentation/api/latest/guide/s3-presigned-urls.html#generating-a-presigned-url-to-upload-a-file).
 */
export type UploadPostRequest = {
  __typename?: "UploadPostRequest";
  fields: Scalars["JSONString"];
  key: Scalars["String"];
  url: Scalars["String"];
};

export type UpsideDispatchMetaType = {
  __typename?: "UpsideDispatchMetaType";
  /** Present for completed dispatches, otherwise `null`. The only relevant value is `AT_HOME` if present. */
  location?: Maybe<Scalars["String"]>;
  /** Present for planned dispatches, otherwise `null`. Value can be `smart-charge`, `test-charge` or `bump-charge`. */
  source?: Maybe<Scalars["String"]>;
};

export type UpsideDispatchType = {
  __typename?: "UpsideDispatchType";
  /** Energy in kWh (import has a negative value). */
  delta?: Maybe<Scalars["Decimal"]>;
  /**
   * This field has been replaced by `delta`.
   * @deprecated The 'delta_kwh' field is deprecated.
   *
   * `delta` has replaced `deltaKwh` for increased precision.
   *
   * - Marked as deprecated on 2024-04-19.
   * - Will be removed on 2025-01-01.
   */
  deltaKwh?: Maybe<Scalars["Int"]>;
  /** The end time of the dispatch. */
  end: Scalars["DateTime"];
  /**
   * This field has been replaced by `end`.
   * @deprecated The 'end_dt' field is deprecated.
   *
   * `end` has replaced `end_dt` for improved typing.
   *
   * - Marked as deprecated on 2024-04-19.
   * - Will be removed on 2025-01-01.
   */
  endDt?: Maybe<Scalars["String"]>;
  meta?: Maybe<UpsideDispatchMetaType>;
  /** The start time of the dispatch. */
  start: Scalars["DateTime"];
  /**
   * This field has been replaced by `start`.
   * @deprecated The 'start_dt' field is deprecated.
   *
   * `start` has replaced `start_dt` for improved typing.
   *
   * - Marked as deprecated on 2024-04-19.
   * - Will be removed on 2025-01-01.
   */
  startDt?: Maybe<Scalars["String"]>;
};

export type UserVehiclesType = {
  __typename?: "UserVehiclesType";
  information?: Maybe<VehicleInformationType>;
  vehicleId?: Maybe<Scalars["String"]>;
};

/** Filter measurements by the given utility parameters. */
export type UtilityFiltersInput = {
  electricityFilters?: InputMaybe<ElectricityFiltersInput>;
};

export type UtilityFiltersOutput =
  | ElectricityFiltersOutput
  | EmbeddedElectricityFiltersOutput
  | GasFiltersOutput;

export enum UtilityType {
  Electricity = "ELECTRICITY",
  EmbeddedElectricity = "EMBEDDED_ELECTRICITY",
  Gas = "GAS",
}

/** Validate whether a user's email is a valid email via the Kickbox API. */
export type ValidateEmail = {
  __typename?: "ValidateEmail";
  /** Whether the email is valid or not. */
  isValid?: Maybe<Scalars["Boolean"]>;
};

/** Input required to validate email address via Kickbox */
export type ValidateEmailInput = {
  /** Check if an email is already in use. */
  checkUniqueness?: InputMaybe<Scalars["Boolean"]>;
  /** The user's email address. */
  email: Scalars["String"];
};

/** Validate whether a user's phone number is a valid phone number. */
export type ValidatePhone = {
  __typename?: "ValidatePhone";
  /** Whether the phone number is valid or not. */
  isValid?: Maybe<Scalars["Boolean"]>;
};

export type ValidatePhoneNumberInput = {
  /** The user's phone number. */
  phoneNumber: Scalars["String"];
};

export type VehicleChargingPreferencesInput = {
  /** Account number. */
  accountNumber: Scalars["String"];
  targetType?: InputMaybe<Scalars["String"]>;
  weekdayTargetSoc: Scalars["Int"];
  weekdayTargetTime: Scalars["String"];
  weekendTargetSoc: Scalars["Int"];
  weekendTargetTime: Scalars["String"];
};

export type VehicleChargingPreferencesType = {
  __typename?: "VehicleChargingPreferencesType";
  /** The maximum state of charge (soc) %, if available. */
  maximumSocPercentage?: Maybe<Scalars["Int"]>;
  /** The minimum state of charge (soc) %, if available. */
  minimumSocPercentage?: Maybe<Scalars["Int"]>;
  weekdayTargetSoc?: Maybe<Scalars["Int"]>;
  weekdayTargetTime?: Maybe<Scalars["String"]>;
  weekendTargetSoc?: Maybe<Scalars["Int"]>;
  weekendTargetTime?: Maybe<Scalars["String"]>;
};

export type VehicleInformationType = {
  __typename?: "VehicleInformationType";
  brand?: Maybe<Scalars["String"]>;
  /** The (user chosen) display name of the vehicle, if available. */
  displayName?: Maybe<Scalars["String"]>;
  /** The model name of the vehicle, if available (e.g. i3s 120). */
  model?: Maybe<Scalars["String"]>;
  vin?: Maybe<Scalars["String"]>;
  /** The year of the vehicle model, if available. */
  year?: Maybe<Scalars["Int"]>;
};

/**
 * The possible errors that can be raised are:
 *
 * - KT-CT-4123: Unauthorized.
 * - KT-US-4418: Missing fraud record for email verification.
 * - KT-CT-1113: Disabled GraphQL field requested.
 * - KT-CT-1111: Unauthorized.
 * - KT-CT-1112: 'Authorization' header not provided.
 */
export type VerifyAccount = {
  __typename?: "VerifyAccount";
  isVerified?: Maybe<Scalars["Boolean"]>;
  possibleErrors?: Maybe<Array<Maybe<PossibleErrorType>>>;
};

export type VerifyAccountInput = {
  accountNumber: Scalars["String"];
  secretKey: Scalars["String"];
};

/**
 * The possible errors that can be raised are:
 *
 * - KT-CT-1113: Disabled GraphQL field requested.
 * - KT-CT-1111: Unauthorized.
 * - KT-CT-1112: 'Authorization' header not provided.
 */
export type VerifyEmail = {
  __typename?: "VerifyEmail";
  /** Whether the email is verified. */
  isVerified?: Maybe<Scalars["Boolean"]>;
  possibleErrors?: Maybe<Array<Maybe<PossibleErrorType>>>;
};

export type VerifyEmailInput = {
  /** Token string that will be used to verify the email. */
  token: Scalars["String"];
};

/**
 * The possible errors that can be raised are:
 *
 * - KT-CT-1145: Account/user details do not match.
 * - KT-CT-1113: Disabled GraphQL field requested.
 */
export type VerifyIdentity = {
  __typename?: "VerifyIdentity";
  possibleErrors?: Maybe<Array<Maybe<PossibleErrorType>>>;
  /** An expiring token that can be used to request to update the user's email address. */
  token: Scalars["String"];
};

export type VerifyIdentityInput = {
  /** The number of the account belonging to the user (e.g. A-12345678). */
  accountNumber: Scalars["String"];
  /** The first line of the user's address (this could be the energy supply property address or the billing address on the account). */
  firstLineOfAddress: Scalars["String"];
  /** The user's full name. */
  fullName: Scalars["String"];
  /** The user's postcode (this could be the postcode of the energy supply property address or of the billing address on the account). */
  postcode: Scalars["String"];
};

/**
 * Indicates a step where the integration is pending activation.
 *
 * Progress to the next onboarding step is blocked until the integration becomes active.
 */
export type WaitForLiveIntegration = SmartFlexOnboardingStepInterface & {
  __typename?: "WaitForLiveIntegration";
  /** A unique identifier for this onboarding step. */
  id?: Maybe<Scalars["ID"]>;
  /** Returns `true` if the device integration is supported by us, `false` otherwise. */
  isLive?: Maybe<Scalars["Boolean"]>;
};

export type WhatsAppTextMessage = {
  __typename?: "WhatsAppTextMessage";
  /** Whatsapp text message body. */
  body: Scalars["String"];
};

export type WindPowerProjection = {
  __typename?: "WindPowerProjection";
  /** The datetime to which this prediction applies in ISO format. */
  deliveryDatetime?: Maybe<Scalars["String"]>;
  /** The predicted proportion of wind power on the grid. Ranges from 0.0 to 1.0. */
  projectedWindPowerPercentage?: Maybe<Scalars["Decimal"]>;
  /** Projected total power grid-wide in megawatts. */
  totalPowerProjection?: Maybe<Scalars["Decimal"]>;
  /** Projected total wind power grid-wide in megawatts. */
  windPowerProjection?: Maybe<Scalars["Decimal"]>;
};

/**
 * Default input required to initiate a LeaveSupplier journey for a given market.
 * This type should only be used when there are no configured markets while building
 * the LeaveSupplierMarketInputType.
 */
export type _DefaultMarketTerminationInput = {
  /** The market supply point identification number. */
  supplyPointIdentifier: Scalars["String"];
};

/** An enumeration. */
export enum Communication_Preference {
  Online = "ONLINE",
  Print = "PRINT",
}
