import { gql } from "@apollo/client";
import {
  createUseQuery,
  createUseQueryFunction,
} from "@octopus-energy/apollo-client";
import {
  QueryUsageSolarExportAnalyticsQuery,
  QueryUsageSolarExportAnalyticsQueryVariables,
} from "./__generated__/useQueryUsageSolarExportAnalytics.generated";

export const QUERY_USAGE_SOLAR_EXPORT_ANALYTICS = gql`
  query QueryUsageSolarExportAnalytics(
    $accountNumber: String!
    $activeFrom: DateTime
    $first: Int
    $last: Int
    $utilityFilters: [UtilityFiltersInput!]
    $startOn: Date
    $endOn: Date
    $startAt: DateTime
    $endAt: DateTime
    $timezone: String
  ) {
    account(accountNumber: $accountNumber) {
      id
      properties(activeFrom: $activeFrom) {
        id
        measurements(
          first: $first
          last: $last
          utilityFilters: $utilityFilters
          startOn: $startOn
          endOn: $endOn
          startAt: $startAt
          endAt: $endAt
          timezone: $timezone
        ) {
          edges {
            node {
              value
              unit
              readAt
              ... on IntervalMeasurementType {
                startAt
                endAt
                accumulation
                durationInSeconds
                metaData {
                  utilityFilters {
                    ... on ElectricityFiltersOutput {
                      __typename
                      readingDirection
                      readingQuality
                      readingFrequencyType
                    }
                  }
                  statistics {
                    type
                    label
                    description
                    costExclTax {
                      estimatedAmount
                      costCurrency
                    }
                    costInclTax {
                      estimatedAmount
                      costCurrency
                    }
                    value
                  }
                }
              }
            }
          }
          pageInfo {
            hasNextPage
            hasPreviousPage
            startCursor
            endCursor
          }
        }
      }
    }
  }
`;

export const useQueryUsageSolarExportAnalytics = createUseQuery<
  QueryUsageSolarExportAnalyticsQuery,
  QueryUsageSolarExportAnalyticsQueryVariables
>(QUERY_USAGE_SOLAR_EXPORT_ANALYTICS);

export const useQueryUsageSolarExportAnalyticsFunction = createUseQueryFunction<
  QueryUsageSolarExportAnalyticsQuery,
  QueryUsageSolarExportAnalyticsQueryVariables
>(QUERY_USAGE_SOLAR_EXPORT_ANALYTICS);
