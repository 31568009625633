import {
  Box,
  Card,
  CardContent,
  Stack,
  Typography,
} from "@octopus-energy/coral-mui";
import useTranslation from "next-translate/useTranslation";
import { FC } from "react";
import { OctopusProductId, octopusProductTranslation } from "..";
import { isSolarBuybackProduct } from "../octopusProductFeatures";
import { OctopusProductTitle } from "../OctopusProducts";
import {
  FullyRenewable,
  ProductCardRateBreakdown,
  ProductCardRatePerKWH,
} from "../ProductCard";
import { withOctopusMembership } from "../withOctopusMembership";

type ProductCheckoutOverviewCardProps = {
  productId: OctopusProductId;
  displayName?: string | null;
  minPrice?: number | string;
};

export const ProductCheckoutOverviewCard: FC<
  ProductCheckoutOverviewCardProps
> = ({ productId, minPrice, displayName }) => {
  const { t: tProduct } = useTranslation(octopusProductTranslation(productId));
  const { t: tProductCard } = useTranslation("product/productCard");

  return (
    <Card
      borderColorVariant="tertiary"
      borderWidth="medium"
      data-cy="product-checkout-card"
    >
      <CardContent sx={{ p: 4 }}>
        <OctopusProductTitle bold={displayName} />
        <Stack direction={{ xs: "column", lg: "row" }}>
          <Box width={{ xs: "100%", lg: "50%" }} mb={1}>
            <>
              <ProductCardRatePerKWH
                minPrice={withOctopusMembership(minPrice)}
              />
              <Box mt={2}>
                <ProductCardRateBreakdown
                  minPrice={minPrice}
                  variant={"caption"}
                />
              </Box>
            </>
            <Box mt={2}>
              <FullyRenewable />
            </Box>
          </Box>
          <Box
            width={{ xs: "100%", lg: "50%" }}
            pl={4.75}
            mt={{ xs: 0, lg: 3 }}
          >
            <ul>
              {[
                tProduct("card_bullet_1"),
                isSolarBuybackProduct(productId) &&
                  tProductCard("solarBuyback"),
                tProduct("card_bullet_2"),
                tProduct("card_bullet_3"),
                tProduct("card_bullet_4"),
              ].map(
                (bullet) =>
                  bullet && (
                    <Typography
                      key={bullet}
                      component="li"
                      mb={1}
                      data-cy="product-checkout-bullet"
                    >
                      {bullet}
                    </Typography>
                  )
              )}
            </ul>
          </Box>
        </Stack>
      </CardContent>
    </Card>
  );
};
