import {
  QUERY_ACCOUNT,
  useChangePaymentScheduleTypeMutation,
  useQueryAccount,
  useQueryDefaultPaymentInstruction,
  useQueryProperties,
} from "@core/apiRequests";
import {
  PaymentScheduleTypeChoices,
  PaymentType,
  ScheduleType,
} from "@core/apiRequests/graphql-global-types";
import { handleError } from "@core/error";
import {
  getPaymentScheduleType,
  getPlan,
  usePortalAccountNumber,
} from "@core/portalUtils";
import { useDeviceBreakpoint } from "@core/utils/useDeviceBreakpoint";
import { Checkbox, Skeleton, Typography } from "@krakentech/coral";
import { MenuItem } from "@mui/material";
import { Box, Stack, TextField } from "@octopus-energy/coral-mui";
import { format } from "date-fns";
import useTranslation from "next-translate/useTranslation";
import {
  ChangeEvent,
  FC,
  InputHTMLAttributes,
  useEffect,
  useState,
} from "react";
import { useBooleanState } from "react-use-object-state";
import { useSnackbarNotification } from "../molecules";

const paymentScheduleTypeChoices = {
  [ScheduleType.CardPayment]: PaymentScheduleTypeChoices.CardPayment,
  [ScheduleType.DirectDebit]: PaymentScheduleTypeChoices.DirectDebit,
  [ScheduleType.BacsTransfer]: PaymentScheduleTypeChoices.Manual,
  [ScheduleType.PaymentSlip]: PaymentScheduleTypeChoices.Manual,
};

const useIsPrepay = () => {
  const accountNumber = usePortalAccountNumber();

  const queryProperties = useQueryProperties({
    variables: {
      accountNumber,
      includeSubscriptionFees: false,
      includeFutureActiveAgreements: false,
    },
    onError: handleError,
  });

  return getPlan(queryProperties.data).isPrepay;
};

const usePaymentInstruction = (paymentType: PaymentType) => {
  const accountNumber = usePortalAccountNumber();

  const queryDefaultPaymentInstruction = useQueryDefaultPaymentInstruction({
    variables: {
      accountNumber,
      instructionType: paymentType,
    },
  });

  return queryDefaultPaymentInstruction.data?.defaultPaymentInstruction
    ?.instructionType;
};

export type AutopaySettingsProps = {
  editing: ReturnType<typeof useBooleanState>;
  saveTrigger: ReturnType<typeof useBooleanState>;
  resetSaveTrigger: Function;
  cancelButtonClicked: ReturnType<typeof useBooleanState>;
};

export const AutopaySettings: FC<AutopaySettingsProps> = ({
  editing,
  saveTrigger,
  resetSaveTrigger,
  cancelButtonClicked,
}) => {
  const { isMobile } = useDeviceBreakpoint();
  const { t } = useTranslation("portalAccountSettings");
  const accountNumber = usePortalAccountNumber();
  const isPrepay = useIsPrepay();
  const paymentMethodCCActive = usePaymentInstruction(PaymentType.Card);
  const paymentMethodACHActive = usePaymentInstruction(PaymentType.DirectDebit);
  const [notification] = useSnackbarNotification();
  const autopayPreferenceTranslation = {
    [ScheduleType.CardPayment]: t("autopayPreferenceDropdownCC"),
    [ScheduleType.DirectDebit]: t("autopayPreferenceDropdownACH"),
    [ScheduleType.BacsTransfer]: t("autopayPreferenceDropdownManual"),
    [ScheduleType.PaymentSlip]: "",
  };

  const [autopayPreference, setAutopayPreference] =
    useState<ScheduleType | null>(null);

  const [checkboxChecked, setCheckboxChecked] = useState(false);
  const [showError, setShowError] = useState(false);

  const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setCheckboxChecked(event.target.checked);
    if (event.target.checked) {
      setShowError(false);
    }
  };

  const { data, loading } = useQueryAccount({
    variables: {
      accountNumber,
      activeOnDate: format(new Date(), "yyyy-MM-dd"),
    },
  });
  const paymentScheduleType = getPaymentScheduleType(data);

  useEffect(() => {
    setAutopayPreference(paymentScheduleType || null);
  }, [paymentScheduleType]);

  useEffect(() => {
    if (cancelButtonClicked.state) {
      setAutopayPreference(
        data?.account?.paymentSchedules?.edges[0]?.node?.scheduleType || null
      );
      cancelButtonClicked.setFalse();
    }
  }, [cancelButtonClicked.state]);

  const [changeScheduleType] = useChangePaymentScheduleTypeMutation();

  useEffect(() => {
    if (saveTrigger.state && autopayPreference) {
      if (!checkboxChecked && autopayPreference !== ScheduleType.BacsTransfer) {
        setShowError(true);
        resetSaveTrigger();
        return;
      }
      changeScheduleType({
        variables: {
          input: {
            accountNumber,
            scheduleType: paymentScheduleTypeChoices[autopayPreference],
          },
        },
        refetchQueries: [
          {
            query: QUERY_ACCOUNT,
            variables: {
              accountNumber,
            },
          },
        ],
      }).then(() => {
        resetSaveTrigger();
        editing.setFalse();
        notification.success(t("autopayPreferenceSuccessPrompt"));
      });
    }
  }, [saveTrigger, autopayPreference, checkboxChecked]);

  const handleChange = (
    e: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => setAutopayPreference(e.target.value as ScheduleType);

  if (loading) {
    return <Skeleton variant="rectangular" height={40} width="100%" />;
  }

  const shouldShowCheckbox = (
    currentType: ScheduleType,
    newType: ScheduleType
  ) =>
    (currentType === ScheduleType.BacsTransfer &&
      newType === ScheduleType.CardPayment) ||
    (currentType === ScheduleType.BacsTransfer &&
      newType === ScheduleType.DirectDebit) ||
    (currentType === ScheduleType.DirectDebit &&
      newType === ScheduleType.CardPayment) ||
    (currentType === ScheduleType.CardPayment &&
      newType === ScheduleType.DirectDebit);

  if (editing.state) {
    return (
      <>
        <Stack mt={1} width={isMobile ? "100%" : "50%"}>
          <Box
            display="flex"
            justifyContent="start"
            flexDirection={{ xs: "column", sm: "row" }}
            px={2}
            my={1}
          >
            <TextField
              select
              name="AutopayChoices"
              value={autopayPreference || ScheduleType.BacsTransfer}
              onChange={handleChange}
              label={t("autopayPreferencePrompt")}
              fullWidth
              sx={{
                mt: 3,
                maxWidth: 280,
                minWidth: 175,
              }}
              data-cy="autopay-settings-dropdown"
            >
              {paymentMethodCCActive && (
                <MenuItem
                  value={ScheduleType.CardPayment}
                  data-cy="autopay-card-option"
                >
                  {t("autopayPreferenceDropdownCC")}
                </MenuItem>
              )}
              {paymentMethodACHActive && (
                <MenuItem
                  value={ScheduleType.DirectDebit}
                  data-cy="autopay-ach-option"
                >
                  {t("autopayPreferenceDropdownACH")}
                </MenuItem>
              )}
              {!isPrepay && (
                <MenuItem
                  value={ScheduleType.BacsTransfer}
                  data-cy="autopay-manual-option"
                >
                  {t("autopayPreferenceDropdownManual")}
                </MenuItem>
              )}
            </TextField>
          </Box>
        </Stack>
        <Stack>
          <Box px={2} mt={{ xs: 0, sm: 2 }}>
            <Typography variant="body2">
              {paymentScheduleType &&
                autopayPreference &&
                shouldShowCheckbox(paymentScheduleType, autopayPreference) && (
                  <Checkbox
                    label={t("autopayPreferenceCheckboxPrompt")}
                    checked={checkboxChecked}
                    onChange={handleCheckboxChange}
                    error={showError}
                    errorMessage={t("pleaseCheckTheBoxAutopay")}
                    inputProps={
                      {
                        "data-cy": "autopay-checkbox",
                      } as InputHTMLAttributes<HTMLInputElement>
                    }
                  />
                )}
            </Typography>
          </Box>
        </Stack>
      </>
    );
  }

  return (
    <>
      <Box px={2} mt={3} mb={1} display="flex" justifyContent="space-between">
        <Typography>
          <Typography>{t("autopayPreferencePrompt")}</Typography>
          <Typography
            color={
              autopayPreference === ScheduleType.CardPayment
                ? "success"
                : autopayPreference === ScheduleType.DirectDebit
                ? "success"
                : "text-main"
            }
            data-cy="autopay-preference"
          >
            {autopayPreferenceTranslation[autopayPreference as ScheduleType]}
          </Typography>
        </Typography>
      </Box>
    </>
  );
};
