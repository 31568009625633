import { useEnrollmentV2 } from "@core/apiRequests/enrollment/useEnrollmentV2";
import {
  AccountCampaignChoices,
  QuotedProducts,
} from "@core/apiRequests/graphql-global-types";
import { IOEnrollmentConfirmationDialog } from "@core/enrollment/IOEnrollmentConfirmationDialog";
import { gTagEvent } from "@core/googleAnalytics";
import { LegalLinks } from "@core/legal";
import { RenewalConfirmationDialog } from "@core/renew";
import { Maybe } from "@core/types";
import { Button } from "@krakentech/coral";
import { IconArrowRight } from "@krakentech/icons";
import { CardContent, CardHeader, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
import { Box, DialogContent, Stack } from "@octopus-energy/coral-mui";
import useTranslation from "next-translate/useTranslation";
import { FC, ReactNode } from "react";
import { useBooleanState } from "react-use-object-state";
import {
  useCustomerIsPTC,
  useCustomerIsRivian,
  useOctopusProduct,
  useSelectProductAndStartEnrollment,
} from "../../enrollment";
import {
  EnrollmentProductInformationProps,
  ProductCard,
  ProductCardMonthlyEstimate,
  ProductCardRateBreakdown,
  ProductCardTitle,
} from "../../product";
import { EnrollmentProductDrawer } from "./EnrollmentProductDrawer";
import { EnrollmentProductSkeleton } from "./EnrollmentProductSkeleton";

export const DialogContentStyled = styled(DialogContent)(({ theme }) => ({
  "&&": {
    padding: theme.spacing(7),
    [theme.breakpoints.down("lg")]: {
      padding: theme.spacing(6, 4),
    },
    [theme.breakpoints.down("md")]: {
      padding: theme.spacing(7, 5),
    },
  },
}));

export type EnrollmentProductProps = Omit<
  EnrollmentProductInformationProps,
  "dialogProps"
> & {
  renewal?: boolean;
  renewalStartDate?: Date | undefined;
  quotedRenewalData?: QuotedProducts;
  renewalQuoteCode?: Maybe<string>;
  loading?: boolean;
  suppressCardHeaders?: boolean;
};

export type ProductAccordionFaqProps = {
  summary: string | ReactNode;
  details: string | ReactNode;
};

export const EnrollmentProduct: FC<EnrollmentProductProps> = ({
  productId,
  lang,
  hasTerminationFee,
  minPrice,
  withEV,
  withThermostat,
  title,
  disclaimer,
  serviceProviders,
  lockedInDuration,
  accordionFaq,
  renewal = false,
  renewalStartDate = undefined,
  renewalQuoteCode = undefined,
  loading = true,
  suppressCardHeaders = false,
  ...props
}) => {
  const { t: tProductCard } = useTranslation("product/productCard");
  const enrollmentProductDrawerOpen = useBooleanState(false);
  const ioConfirmationDialogOpen = useBooleanState(false);
  const renewalConfirmationDialogOpen = useBooleanState(false);
  const octopusProduct = useOctopusProduct(productId);
  const customerIsPTC = useCustomerIsPTC();
  const customerIsRivian = useCustomerIsRivian();
  const [{ smartDevices }] = useEnrollmentV2();
  const thermostatSelected = smartDevices.includes(
    AccountCampaignChoices.SmartThermostat
  );
  const evSelected = smartDevices.includes(
    AccountCampaignChoices.ElectricVehicle
  );

  const recommendedPlan =
    evSelected && thermostatSelected
      ? withThermostat
      : evSelected
      ? withEV
      : withThermostat;

  const secondRecommendedPlan =
    evSelected && thermostatSelected ? withEV : false;

  function addYears(date: Date, years: number) {
    const isoDate = new Date(date);
    return isoDate.setFullYear(isoDate.getFullYear() + years);
  }

  const formattedRenewalStartDate = renewalStartDate
    ? new Date(renewalStartDate).toLocaleDateString("en-US", {
        year: "numeric",
        month: "short",
        day: "numeric",
      })
    : undefined;

  const renewalEndDate = renewalStartDate
    ? addYears(renewalStartDate, (octopusProduct?.term as number) / 12)
    : undefined;

  const formattedRenewalEndDate = renewalStartDate
    ? new Date(renewalEndDate as unknown as Date).toLocaleDateString("en-US", {
        year: "numeric",
        month: "short",
        day: "numeric",
      })
    : undefined;

  const totalRate =
    (withEV && !customerIsPTC && octopusProduct?.prices.discountEVPrice) ||
    (withThermostat &&
      !customerIsPTC &&
      octopusProduct?.prices.discountThermostatPrice) ||
    octopusProduct?.prices.basePrice;

  const selectProductAndStartEnrollment = useSelectProductAndStartEnrollment();

  const handleSelectProduct = () => {
    selectProductAndStartEnrollment(productId, withEV, withThermostat);
    gTagEvent({
      action: "click",
      category: "plan_clicks",
      label: `${productId}${withEV && "_EV"}${withThermostat && "_thermostat"}`,
      value: 1,
    });
  };
  const handleRenewalStart = () => {
    enrollmentProductDrawerOpen.setTrue();
    gTagEvent({
      action: "click",
      category: "plan_clicks",
      label: `${productId}${withEV ? "_EV" : ""}${
        withThermostat ? "_thermostat" : ""
      }`,
      value: 1,
    });
  };

  if (loading) {
    return <EnrollmentProductSkeleton />;
  }

  return (
    <>
      <ProductCard
        sx={{
          borderColor:
            recommendedPlan || secondRecommendedPlan || customerIsRivian
              ? undefined
              : "secondary.dark",
        }}
        data-cy={props["data-cy"]}
      >
        {!suppressCardHeaders && (
          <CardHeader
            title={tProductCard("recommendedPlan")}
            titleTypographyProps={{ align: "center" }}
            sx={{
              backgroundColor: "secondary.main",
              visibility:
                recommendedPlan || secondRecommendedPlan || customerIsRivian
                  ? "visible"
                  : "hidden",
            }}
          />
        )}
        <CardContent
          sx={{
            display: "flex",
            flexDirection: "column",
            padding: customerIsRivian ? 5 : 3,
            backgroundColor:
              recommendedPlan || customerIsRivian
                ? "secondary.dark"
                : undefined,
          }}
        >
          <ProductCardTitle
            withThermostat={withThermostat}
            withEV={withEV}
            suppressCardHeaders={suppressCardHeaders}
          >
            {title}
          </ProductCardTitle>
          <ProductCardMonthlyEstimate
            minPrice={totalRate}
            offPeakPrice={octopusProduct?.prices.offPeakPrice}
          />
          <ProductCardRateBreakdown
            align="center"
            minPrice={totalRate}
            peakPrice={octopusProduct?.prices.peakPrice}
          />
          <Box textAlign="center" mt={renewal ? 2 : "unset"}>
            {!renewal && (
              <Box mt={3.5} mb={2.5}>
                <Button
                  variant="outlined"
                  size="small"
                  color="tertiary"
                  onClick={enrollmentProductDrawerOpen.setTrue}
                  data-cy="product-rate-info-button"
                >
                  {tProductCard("moreRateInfo")} &nbsp;
                  <IconArrowRight size={20} />
                </Button>{" "}
              </Box>
            )}
          </Box>
          <Box
            minHeight={
              withThermostat || withEV || !suppressCardHeaders ? 80 : 0
            }
          >
            {customerIsRivian && (
              <Typography variant="body1" my={2} textAlign="center">
                {tProductCard("touDescription")}
              </Typography>
            )}
            {withThermostat && (
              <Typography
                textAlign="center"
                data-cy="works-with-these-thermostats-text"
              >
                {tProductCard("worksWithTheseThermostats")}
              </Typography>
            )}
            {withEV && (
              <Typography
                textAlign="center"
                data-cy="works-with-these-evs-text"
              >
                {tProductCard("worksWithTheseEVs")}
              </Typography>
            )}
          </Box>
          <Box mt={2.5} mb={4}>
            <Button
              fullWidth
              onClick={() => {
                if (disclaimer?.paragraph) {
                  enrollmentProductDrawerOpen.setTrue();
                } else if (
                  !renewal &&
                  (withEV || withThermostat || customerIsRivian)
                ) {
                  ioConfirmationDialogOpen.setTrue();
                } else if (renewal) {
                  handleRenewalStart();
                } else {
                  handleSelectProduct();
                }
              }}
              color={
                recommendedPlan || customerIsRivian ? "primary" : "secondary"
              }
              data-cy="select-product-button"
            >
              {tProductCard("signMeUp")}
            </Button>
          </Box>
          <Stack alignItems="center">
            <LegalLinks
              productId={productId}
              serviceProviders={serviceProviders}
              color="tertiary"
              disclosures={{ efl: true }}
            />
          </Stack>
        </CardContent>
      </ProductCard>
      <EnrollmentProductDrawer
        title={title}
        withThermostat={withThermostat}
        withEV={withEV}
        productInformationDialogOpen={enrollmentProductDrawerOpen.state}
        onClose={() => enrollmentProductDrawerOpen.setFalse()}
        productId={productId}
        renewal={renewal}
        serviceProviders={serviceProviders}
        lang={lang}
        hasTerminationFee={hasTerminationFee}
        lockedInDuration={lockedInDuration}
        minPrice={totalRate}
        disclaimer={disclaimer}
        solarBuyback
        renewalStartDate={renewalStartDate}
        formattedRenewalStartDate={formattedRenewalStartDate}
        formattedRenewalEndDate={formattedRenewalEndDate}
        renewalQuoteCode={renewalQuoteCode}
        onClick={() => {
          enrollmentProductDrawerOpen.setFalse();
          if (renewal) {
            renewalConfirmationDialogOpen.setTrue();
          } else if (withEV || withThermostat || customerIsRivian) {
            ioConfirmationDialogOpen.setTrue();
          } else {
            handleSelectProduct();
          }
        }}
      />
      <IOEnrollmentConfirmationDialog
        open={ioConfirmationDialogOpen.state}
        onClose={() => {
          ioConfirmationDialogOpen.setFalse();
          if (renewal) {
            enrollmentProductDrawerOpen.setFalse();
          }
        }}
        IOType={
          customerIsRivian ? undefined : withThermostat ? "thermostat" : "ev"
        }
        onConfirm={() => {
          if (renewal) {
            ioConfirmationDialogOpen.setFalse();
            handleRenewalStart();
          } else {
            handleSelectProduct();
          }
        }}
      />
      <RenewalConfirmationDialog
        open={renewalConfirmationDialogOpen.state}
        onClose={() => {
          enrollmentProductDrawerOpen.setFalse();
          renewalConfirmationDialogOpen.setFalse();
        }}
        withEV={withEV}
        withThermostat={withThermostat}
        renewalStartDate={renewalStartDate}
        productId={productId}
        renewalQuoteCode={renewalQuoteCode}
      />
    </>
  );
};
