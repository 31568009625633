import {
  useQueryAccount,
  useQueryProperties,
  useUpdateCommsDeliveryPreferenceMutation,
} from "@core/apiRequests";
import { CommsDeliveryPreference } from "@core/apiRequests/graphql-global-types";
import { getPlan, usePortalAccountNumber } from "@core/portalUtils";
import {
  Checkbox,
  Skeleton,
  ToggleButton,
  ToggleButtonProps,
  Typography,
} from "@krakentech/coral";
import { Box, Stack } from "@octopus-energy/coral-mui";
import useTranslation from "next-translate/useTranslation";
import { FC, InputHTMLAttributes, useEffect, useState } from "react";
import { useBooleanState } from "react-use-object-state";
import { handleError } from "../error";

export type PaperlessBillingPreferenceProps = {
  editing: ReturnType<typeof useBooleanState>;
  saveTrigger: ReturnType<typeof useBooleanState>;
  resetSaveTrigger: Function;
};

export const PaperlessBillingPreference: FC<
  PaperlessBillingPreferenceProps
> = ({ editing, saveTrigger, resetSaveTrigger }) => {
  const accountNumber = usePortalAccountNumber();
  const { t } = useTranslation("portalAccountSettings");
  const paperlessBillingTranslation = {
    [CommsDeliveryPreference.Email]: t("commsDeliveryPreferenceToggleOn"),
    [CommsDeliveryPreference.PostalMail]: t("commsDeliveryPreferenceToggleOff"),
  };

  const [commsDeliveryPreference, setCommsDeliveryPreference] =
    useState<CommsDeliveryPreference | null>(null);

  const [checkboxChecked, setCheckboxChecked] = useState(false);
  const [showError, setShowError] = useState(false);

  const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setCheckboxChecked(event.target.checked);
    if (event.target.checked) {
      setShowError(false);
    }
  };

  const queryProperties = useQueryProperties({
    variables: {
      accountNumber,
      includeSubscriptionFees: false,
      includeFutureActiveAgreements: false,
    },
    onError: handleError,
  });

  const { isPrepay } = getPlan(queryProperties.data);

  const { loading, data, refetch } = useQueryAccount({
    variables: {
      accountNumber,
    },
    onError: handleError,
  });

  useEffect(() => {
    setCommsDeliveryPreference(data?.account?.commsDeliveryPreference || null);
  }, [data?.account?.commsDeliveryPreference]);

  const [updateCommsDeliveryPreference] =
    useUpdateCommsDeliveryPreferenceMutation();

  useEffect(() => {
    const shouldUpdatePreference = () => {
      return (
        saveTrigger.state &&
        commsDeliveryPreference &&
        commsDeliveryPreference !== data?.account?.commsDeliveryPreference
      );
    };

    const isSwitchingToEmailFromPostalWithoutConsent = () => {
      return (
        data?.account?.commsDeliveryPreference ===
          CommsDeliveryPreference.PostalMail &&
        commsDeliveryPreference === CommsDeliveryPreference.Email &&
        !checkboxChecked
      );
    };

    const handleError = () => {
      setShowError(true);
      resetSaveTrigger();
      editing.setTrue();
    };

    const updatePreference = () => {
      if (commsDeliveryPreference === null) return;

      const variables = {
        input: {
          accountNumber,
          commsDeliveryPreference,
        },
      };

      updateCommsDeliveryPreference({ variables }).then(() => {
        refetch();
        resetSaveTrigger();
      });
    };

    if (shouldUpdatePreference()) {
      if (isSwitchingToEmailFromPostalWithoutConsent()) {
        handleError();
      } else {
        updatePreference();
      }
    }
  }, [
    saveTrigger.state,
    commsDeliveryPreference,
    data?.account?.commsDeliveryPreference,
  ]);

  const handleChange: ToggleButtonProps["onChange"] = (e, value) => {
    if (value !== null) {
      setCommsDeliveryPreference(value as CommsDeliveryPreference);
    }
  };

  const toggleValues = [
    {
      label: t("commsDeliveryPreferenceToggleOn"),
      value: CommsDeliveryPreference.Email,
    },
    {
      label: t("commsDeliveryPreferenceToggleOff"),
      value: CommsDeliveryPreference.PostalMail,
    },
  ];

  if (loading) {
    return <Skeleton variant="rectangular" height={40} width="10%" />;
  }
  if (editing.state) {
    return (
      <>
        <form>
          <Box
            display="flex"
            justifyContent="start"
            flexDirection="column"
            mt={3}
          >
            <Stack mb={1}>
              <Typography variant="caption" color="tertiary">
                {t("paperlessBilling")}
              </Typography>
            </Stack>
            <ToggleButton
              size="small"
              disabled={isPrepay ? true : false}
              value={commsDeliveryPreference || ""}
              values={toggleValues}
              onChange={handleChange}
            />
            <Stack mt={1}>
              <Typography variant="caption" data-cy="paperless-to-paper-label">
                {isPrepay ? t("prepayCustomersAreRequired") : t("goodToKnow")}
              </Typography>
            </Stack>
          </Box>
          <Box my={2}>
            <Typography variant="body2" data-cy="comms-switching-prompt">
              {data?.account?.commsDeliveryPreference ===
                CommsDeliveryPreference.PostalMail &&
                commsDeliveryPreference === CommsDeliveryPreference.Email && (
                  <Checkbox
                    label={
                      t("paperToPaperlessPrompt1") +
                      " " +
                      t("paperToPaperlessPrompt2")
                    }
                    checked={checkboxChecked}
                    onChange={handleCheckboxChange}
                    data-cy="paper-to-paperless-label"
                    error={showError}
                    errorMessage={t("pleaseCheckTheBoxPaperless")}
                    inputProps={
                      {
                        "data-cy": "paper-to-paperless-checkbox",
                      } as InputHTMLAttributes<HTMLInputElement>
                    }
                  />
                )}
            </Typography>
          </Box>
        </form>
      </>
    );
  }

  return (
    <Box my={3} justifyContent="space-between" alignItems="end">
      <Typography variant="caption" color="tertiary">
        {t("paperlessBilling")}
      </Typography>
      <Typography>
        <Typography data-cy="paperless-billing-preference">
          {
            paperlessBillingTranslation[
              commsDeliveryPreference as CommsDeliveryPreference
            ]
          }
        </Typography>
      </Typography>
    </Box>
  );
};
