import { Button, Card, Typography, useCoralTheme } from "@krakentech/coral";
import { IconEdit } from "@krakentech/icons";
import { Stack } from "@mui/material";
import useTranslation from "next-translate/useTranslation";
import { FC } from "react";
import { useBooleanState } from "react-use-object-state";
import { useDeviceBreakpoint } from "../utils/useDeviceBreakpoint";
import { CommunicationMethodsPreference } from "./CommunicationMethodsPreference";
import { LanguagePreference } from "./LanguagePreference";
import MailingAddress from "./MailingAddress";
import { PaperlessBillingPreference } from "./PaperlessBillingPreference";

export const CommunicationPreferencesCard: FC = () => {
  const theme = useCoralTheme();
  const { t } = useTranslation("portalAccountSettings");
  const { isMobile } = useDeviceBreakpoint();
  const editing = useBooleanState(false);
  const saveTrigger = useBooleanState(false);
  const resetSaveTrigger = () => saveTrigger.setFalse();

  return (
    <Card
      borderless
      fullWidth
      padding="small"
      data-cy="communication-preferences-card"
    >
      <Stack my={1} px={2}>
        <Stack display="flex" direction="row" justifyContent="space-between">
          <Typography variant="h5">
            {t("communicationPreferenceTitle")}
          </Typography>
          {!editing.state && (
            <Button onClick={editing.setTrue} variant="text" size="small">
              <IconEdit size={18} color={theme.color.tertiary.main} />
            </Button>
          )}
        </Stack>
        <LanguagePreference
          editing={editing}
          saveTrigger={saveTrigger}
          resetSaveTrigger={resetSaveTrigger}
        />
        <CommunicationMethodsPreference
          editing={editing}
          saveTrigger={saveTrigger}
          resetSaveTrigger={resetSaveTrigger}
        />
        <PaperlessBillingPreference
          editing={editing}
          saveTrigger={saveTrigger}
          resetSaveTrigger={resetSaveTrigger}
        />
        <MailingAddress />
        {editing.state && (
          <Stack my={3} spacing={2} direction={isMobile ? "column" : "row"}>
            <Button
              size="medium"
              fullWidth
              variant="outlined"
              onClick={editing.setFalse}
            >
              {t("cancel")}
            </Button>
            <Button
              size="medium"
              fullWidth
              variant="contained"
              onClick={() => {
                saveTrigger.setTrue();
                editing.setFalse();
              }}
            >
              {t("save")}
            </Button>
          </Stack>
        )}
      </Stack>
    </Card>
  );
};
