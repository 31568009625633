import {
  INTELLIGENT_OCTOPUS_URL_ENGLISH,
  INTELLIGENT_OCTOPUS_URL_SPANISH,
  PREPAID_DISCLOSURE_STATEMENT_URL_ENGLISH,
  PREPAID_DISCLOSURE_STATEMENT_URL_SPANISH,
  TERMS_OF_SERVICE_URL_ENGLISH,
  TERMS_OF_SERVICE_URL_SPANISH,
  YOUR_RIGHTS_AS_A_CUSTOMER_URL_ENGLISH,
  YOUR_RIGHTS_AS_A_CUSTOMER_URL_SPANISH,
} from "@core/legal";
import { Link, Stack, Typography, TypographyProps } from "@krakentech/coral";
import { IconPDF } from "@krakentech/icons";
import useTranslation from "next-translate/useTranslation";
import { useRouter } from "next/router";
import { FC } from "react";
import { Locale } from "../constants";
import { ProductListType } from "../krakenHelpers";
import { isPrepayProduct } from "../product";
import { OctopusProductId } from "../product/octopusProductConstants";
import { isTruthy } from "../utils";

export type LegalLinksProps = {
  serviceProviders?: ProductListType[0]["serviceProviders"];
  productId: OctopusProductId;
  color?: TypographyProps["color"];
  disclosures: {
    allDisclosures?: boolean;
    efl?: boolean;
    yrac?: boolean;
    tos?: boolean;
    prepay?: boolean;
  };
  ioAgreement?: boolean;
  icon?: boolean;
};

export const LegalLinks: FC<LegalLinksProps> = ({
  productId,
  serviceProviders = [],
  color = "primary",
  disclosures: {
    allDisclosures = false,
    efl = false,
    yrac = false,
    tos = false,
    prepay = false,
  },
  icon = false,
  ioAgreement = false,
}) => {
  const locale = useRouter().locale;
  const { t } = useTranslation("product/productCard");
  const legalLinks: { href: string; label: string }[] = [];

  const eflDisclosure = [
    ...serviceProviders
      .map((data) => {
        const eflUrl =
          data[locale === Locale.ENGLISH ? "efl_en" : "efl_es"]?.url;
        if (eflUrl) {
          return {
            href: eflUrl,
            label: allDisclosures
              ? `${data.name} Electricity Facts Label`
              : t("electricityFactsLabels"),
          };
        }
      })
      .filter(isTruthy),
  ];

  const yracDisclosure = {
    href:
      locale === Locale.ENGLISH
        ? YOUR_RIGHTS_AS_A_CUSTOMER_URL_ENGLISH
        : YOUR_RIGHTS_AS_A_CUSTOMER_URL_SPANISH,
    label: t("yourRights") || "",
  };

  const tosDisclosure = {
    href:
      locale === Locale.ENGLISH
        ? TERMS_OF_SERVICE_URL_ENGLISH
        : TERMS_OF_SERVICE_URL_SPANISH,
    label: t("serviceTerms") || "",
  };

  const prepayDisclosure = {
    href:
      isPrepayProduct(productId) && locale === Locale.ENGLISH
        ? PREPAID_DISCLOSURE_STATEMENT_URL_ENGLISH
        : PREPAID_DISCLOSURE_STATEMENT_URL_SPANISH,
    label:
      (isPrepayProduct(productId) && t("prepaidDisclosureStatement")) || "",
  };

  const intelligentOctopusAgreement = {
    href:
      locale === Locale.ENGLISH
        ? INTELLIGENT_OCTOPUS_URL_ENGLISH
        : INTELLIGENT_OCTOPUS_URL_SPANISH,
    label: t("ioAgreement") || "",
  };

  if (allDisclosures) {
    efl = yrac = tos = prepay = true;
  }
  efl && legalLinks.push(...eflDisclosure);
  yrac && legalLinks.push(yracDisclosure);
  tos && legalLinks.push(tosDisclosure);
  prepay && legalLinks.push(prepayDisclosure);
  ioAgreement && legalLinks.push(intelligentOctopusAgreement);

  return (
    <Stack
      dataAttributes={{
        "data-cy": "legal-links",
      }}
      gap="sm"
      direction="vertical"
    >
      {legalLinks.map(
        ({ href, label }) =>
          href &&
          label && (
            <Stack alignItems="center" gap="md" key={label}>
              {icon && <IconPDF size={16} />}
              <Typography color={color} textAlign="center">
                <Link href={href} target="_blank" color="inherit">
                  {label}
                </Link>
              </Typography>
            </Stack>
          )
      )}
    </Stack>
  );
};
