import { Maybe } from "@core/types";
import { CoralTypographyVariant, Typography } from "@krakentech/coral";
import { Box } from "@octopus-energy/coral-mui";
import useTranslation from "next-translate/useTranslation";
import { FC } from "react";
import { EnrollmentProduct, hasTerminationFee } from ".";
import {
  useCustomerIsPTC,
  useCustomerIsRivian,
  useOctopusProduct,
} from "../enrollment";
import { useDeviceBreakpoint } from "../utils/useDeviceBreakpoint";
import {
  OctopusProductId,
  OctopusProductTranslation,
} from "./octopusProductConstants";

export type OctopusProductTitleProps = {
  variant?: CoralTypographyVariant;
  bold?: string | null;
  regular?: string | null;
  minHeight?: number;
  withEV?: Maybe<boolean>;
  withThermostat?: Maybe<boolean>;
  productId?: string;
};

export const OctopusProductTitle = ({
  variant,
  bold,
  regular,
  minHeight = 120,
  withEV,
  withThermostat,
}: OctopusProductTitleProps) => {
  const { t } = useTranslation("product/productCard");
  const { t: lang } = useTranslation("enrollment/plans");
  const customerIsPTC = useCustomerIsPTC();
  const customerIsRivian = useCustomerIsRivian();
  const { isMobile } = useDeviceBreakpoint();

  return (
    <Box
      display="flex"
      flexDirection="column"
      minHeight={isMobile ? "unset" : minHeight}
    >
      <Typography
        variant={variant || "h1"}
        component="h2"
        color={"text-main"}
        dataAttributes={{
          "data-cy": "product-title",
        }}
      >
        <strong>{bold}</strong>
        {regular}
      </Typography>
      {!customerIsPTC && !customerIsRivian && (
        <Box mb={4}>
          <Typography
            variant="body1"
            component="h2"
            color={!withThermostat && !withEV ? "text-main" : "tertiary"}
            dataAttributes={{
              "data-cy": "product-title-variant",
            }}
          >
            <strong>{withThermostat && t("withThermostat")}</strong>
            <strong>{withEV && t("withEV")}</strong>
            <strong> {!withThermostat && !withEV && lang("basicPlan")}</strong>
          </Typography>
        </Box>
      )}
    </Box>
  );
};

export type ProductCardProps = {
  loading?: boolean;
  renewal?: boolean;
  renewalStartDate?: Date | undefined;
  renewalQuoteCode?: string;
  withEV?: boolean;
  withThermostat?: boolean;
  suppressCardHeaders?: boolean;
};

export const RivianTimeOfUseProduct: FC<ProductCardProps> = ({
  loading = false,
  renewal = false,
  renewalStartDate = undefined,
  renewalQuoteCode = undefined,
  withEV = false,
  withThermostat = false,
}) => {
  const productId = OctopusProductId.RIVIAN_TIME_OF_USE;
  const octopusProduct = useOctopusProduct(productId);

  return (
    <EnrollmentProduct
      title={
        <OctopusProductTitle
          minHeight={85}
          withThermostat={withThermostat}
          withEV={withEV}
          bold={octopusProduct?.displayName}
          productId={productId}
        />
      }
      lang={OctopusProductTranslation.RIVIAN_TIME_OF_USE}
      productId={productId}
      hasTerminationFee={hasTerminationFee(productId)}
      serviceProviders={octopusProduct?.serviceProviders}
      data-cy="rivian-product-card"
      renewal={renewal}
      renewalStartDate={renewalStartDate}
      renewalQuoteCode={renewalQuoteCode || undefined}
      loading={loading}
    />
  );
};
export const OctoPlus12Product: FC<ProductCardProps> = ({
  loading = false,
  renewal = false,
  renewalStartDate = undefined,
  renewalQuoteCode = undefined,
  withEV = false,
  withThermostat = false,
  suppressCardHeaders = false,
}) => {
  const productId = OctopusProductId.OCTO_PLUS_12M;
  const octopusProduct = useOctopusProduct(productId);

  return (
    <EnrollmentProduct
      title={
        <OctopusProductTitle
          withThermostat={withThermostat}
          withEV={withEV}
          bold={octopusProduct?.displayName}
          productId={productId}
        />
      }
      lang={OctopusProductTranslation.OCTO_PLUS_12M}
      productId={productId}
      hasTerminationFee={hasTerminationFee(productId)}
      serviceProviders={octopusProduct?.serviceProviders}
      data-cy="octoplus-product-card"
      renewal={renewal}
      renewalStartDate={renewalStartDate}
      renewalQuoteCode={renewalQuoteCode || undefined}
      loading={loading}
      withThermostat={withThermostat}
      withEV={withEV}
      suppressCardHeaders={suppressCardHeaders}
    />
  );
};

export const OctoThermostat12Product: FC<ProductCardProps> = ({
  loading = false,
  renewal = false,
  renewalStartDate = undefined,
  renewalQuoteCode = undefined,
}) => {
  const productId = OctopusProductId.OCTO_THERMOSTAT_12M;
  const octopusProduct = useOctopusProduct(productId);

  return (
    <EnrollmentProduct
      title={
        <OctopusProductTitle
          bold={octopusProduct?.displayName}
          productId={productId}
        />
      }
      lang={OctopusProductTranslation.OCTO_PLUS_12M}
      productId={productId}
      hasTerminationFee={hasTerminationFee(productId)}
      serviceProviders={octopusProduct?.serviceProviders}
      data-cy="octothermostat-product-card"
      renewal={renewal}
      renewalStartDate={renewalStartDate}
      renewalQuoteCode={renewalQuoteCode || undefined}
      loading={loading}
      withThermostat
    />
  );
};

export const OctoEV12Product: FC<ProductCardProps> = ({
  loading = false,
  renewal = false,
  renewalStartDate = undefined,
  renewalQuoteCode = undefined,
}) => {
  const productId = OctopusProductId.OCTO_EV_12M;
  const octopusProduct = useOctopusProduct(productId);

  return (
    <EnrollmentProduct
      title={
        <OctopusProductTitle
          bold={octopusProduct?.displayName}
          productId={productId}
        />
      }
      lang={OctopusProductTranslation.OCTO_PLUS_12M}
      productId={productId}
      hasTerminationFee={hasTerminationFee(productId)}
      serviceProviders={octopusProduct?.serviceProviders}
      data-cy="octoev-product-card"
      renewal={renewal}
      renewalStartDate={renewalStartDate}
      renewalQuoteCode={renewalQuoteCode || undefined}
      loading={loading}
      withEV
    />
  );
};

export const OctoPlus36Product: FC<ProductCardProps> = ({
  renewal = false,
  renewalStartDate = undefined,
  renewalQuoteCode = undefined,
  loading = false,
}) => {
  const productId = OctopusProductId.OCTO_PLUS_36M;
  const octopusProduct = useOctopusProduct(productId);
  return (
    <EnrollmentProduct
      title={
        <OctopusProductTitle
          bold={octopusProduct?.displayName}
          productId={productId}
        />
      }
      lang={OctopusProductTranslation.OCTO_PLUS_36M}
      productId={productId}
      hasTerminationFee={hasTerminationFee(productId)}
      serviceProviders={octopusProduct?.serviceProviders}
      lockedInDuration={36}
      data-cy="octoplus-product-card"
      renewal={renewal}
      renewalStartDate={renewalStartDate as Date}
      renewalQuoteCode={renewalQuoteCode || undefined}
      loading={loading}
    />
  );
};

export const OctoGo12Product: FC<ProductCardProps> = ({
  loading = false,
  renewal = false,
  renewalStartDate = undefined,
  renewalQuoteCode = undefined,
  withEV = undefined,
  withThermostat = undefined,
  suppressCardHeaders = false,
}) => {
  const productId = OctopusProductId.OCTO_GO_12M;
  const octopusProduct = useOctopusProduct(productId);
  return (
    <EnrollmentProduct
      title={
        <OctopusProductTitle
          withEV={withEV}
          withThermostat={withThermostat}
          bold={octopusProduct?.displayName}
          variant="h2"
          productId={productId}
        />
      }
      lang={OctopusProductTranslation.OCTO_GO_12M}
      loading={loading}
      productId={productId}
      hasTerminationFee={hasTerminationFee(productId)}
      minPrice={octopusProduct?.prices.basePrice}
      serviceProviders={octopusProduct?.serviceProviders}
      data-cy="octogo-product-card"
      renewal={renewal}
      renewalStartDate={renewalStartDate}
      renewalQuoteCode={renewalQuoteCode || undefined}
      withEV={withEV}
      withThermostat={withThermostat}
      suppressCardHeaders={suppressCardHeaders}
    />
  );
};

const octopusProductComponent = {
  OCTO_PLUS_12M: <OctoPlus12Product />,
  OCTO_THERMOSTAT_12M: <OctoThermostat12Product />,
  OCTO_EV_12M: <OctoEV12Product />,
  OCTO_PLUS_24M: <OctoPlus12Product />,
  OCTO_PLUS_36M: <OctoPlus36Product />,
  OCTO_GO_12M: <OctoGo12Product />,
  RIVIAN_TIME_OF_USE: <RivianTimeOfUseProduct />,
};

type OctopusProducts = {
  [key in OctopusProductId]: {
    component: JSX.Element;
    translation: OctopusProductTranslation;
  };
};

/**
 * Object relating the product by ID to the Active Campaign ID, product component, and translation.
 */
const octopusProducts: OctopusProducts = (
  Object.keys(OctopusProductId) as Array<keyof typeof OctopusProductId>
).reduce(
  (a, k) => ({
    ...a,
    [OctopusProductId[k]]: {
      component: octopusProductComponent[k],
      translation: OctopusProductTranslation[k],
    },
  }),
  {} as OctopusProducts
);

export const OctopusProduct: FC<{
  productId: OctopusProductId;
}> = ({ productId }) => octopusProducts[productId].component;

export const octopusProductTranslation = (octopusProductId: OctopusProductId) =>
  octopusProducts[octopusProductId].translation;
