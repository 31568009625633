import {
  QUERY_ACCOUNT,
  useChangePaymentScheduleTypeMutation,
} from "@core/apiRequests";
import {
  PaymentScheduleTypeChoices,
  PaymentType,
} from "@core/apiRequests/graphql-global-types";
import { handleError } from "@core/error";
import { useSnackbarNotification } from "@core/molecules";
import {
  EditAchPaymentMethod,
  useCreateBankAccountPaymentMethod,
} from "@core/paymentMethods";
import { Button, Skeleton, Typography } from "@krakentech/coral";
import { Theme, useMediaQuery } from "@mui/material";
import {
  Box,
  EditAchPaymentMethodFormValues,
  Stack,
} from "@octopus-energy/coral-mui";
import useTranslation from "next-translate/useTranslation";
import { ChangeEvent, FC, FormEvent, useState } from "react";
import { useBooleanState } from "react-use-object-state";
import { useQueryDefaultPaymentInstruction } from "../apiRequests/payment";
import { DeletePaymentMethodSettings } from "./DeletePaymentMethodSettings";
import { AutopayPreferenceChoices } from "./PaymentCreditCardSettings";
import { useBillingBreakdown } from "@core/dashboard/useBillingBreakdown";

export type PaymentAchSettingsProps = {
  accountNumber: string;
  editing: ReturnType<typeof useBooleanState>;
  addBankAccountTrigger: ReturnType<typeof useBooleanState>;
  deleteAchConfirmation: ReturnType<typeof useBooleanState>;
};

export const PaymentAchSettings: FC<PaymentAchSettingsProps> = ({
  accountNumber,
  editing,
  addBankAccountTrigger,
  deleteAchConfirmation,
}) => {
  const isMobile = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down("sm")
  );
  const { t } = useTranslation("payments/payment-methods");
  const [notification] = useSnackbarNotification();
  const { isPrepay } = useBillingBreakdown();

  const [autopayPreference, setAutopayPreference] =
    useState<AutopayPreferenceChoices | null>(null);

  const showAutopayErrorMessage = useBooleanState(false);

  const handleAutopayPreferenceChange = (
    event: ChangeEvent<HTMLInputElement>
  ) => {
    setAutopayPreference(event.target.value as AutopayPreferenceChoices);
    showAutopayErrorMessage.setFalse();
  };

  const [changeScheduleType] = useChangePaymentScheduleTypeMutation();

  const {
    loading: queryLoading,
    data,
    refetch,
  } = useQueryDefaultPaymentInstruction({
    variables: {
      accountNumber,
      instructionType: PaymentType.DirectDebit,
    },
    onError: handleError,
  });

  const [
    createACHPaymentMethodLoading,
    {
      loading: mutationLoading,
      error,
      stripeError,
      stripeErrorMessage,
      setStripeError,
    },
  ] = useCreateBankAccountPaymentMethod();
  interface StripeResponse {
    data?: {
      storeAchDirectDebitInstruction: object;
    };
  }

  const loading = queryLoading || mutationLoading;

  const authorizeAutopayChecked = useBooleanState(false);
  const showAuthorizeAutopayError = useBooleanState(false);

  const handleAuthorizeAutopayCheckboxChange = (
    e: ChangeEvent<HTMLInputElement>
  ) => {
    authorizeAutopayChecked.setState(e.target.checked);
  };

  const onSubmit = async (
    e: FormEvent,
    bankAccountInputArgs: EditAchPaymentMethodFormValues
  ) => {
    e.preventDefault();

    if (autopayPreference === null && !isPrepay) {
      showAutopayErrorMessage.setTrue();
      return;
    }

    if (
      autopayPreference === AutopayPreferenceChoices.YES &&
      !authorizeAutopayChecked.state
    ) {
      showAuthorizeAutopayError.setTrue();
      return;
    }

    try {
      const response = await createACHPaymentMethodLoading(
        bankAccountInputArgs
      );

      const isSuccessfulStripeResponse = (response as StripeResponse)?.data
        ?.storeAchDirectDebitInstruction;

      if (!isSuccessfulStripeResponse) {
        addBankAccountTrigger.setTrue();
        return;
      } else {
        addBankAccountTrigger.setFalse();
        editing.setFalse();
      }

      if (autopayPreference === AutopayPreferenceChoices.YES) {
        const { data: changeScheduleTypeData } = await changeScheduleType({
          variables: {
            input: {
              accountNumber,
              scheduleType: PaymentScheduleTypeChoices.DirectDebit,
            },
          },
          refetchQueries: [
            {
              query: QUERY_ACCOUNT,
              variables: {
                accountNumber,
              },
            },
          ],
        });
        if (changeScheduleTypeData) {
          setTimeout(() => {
            notification.success(t("autopayPreferenceSuccessPrompt"));
          }, 3000);
        }
      }
    } catch (error) {
      handleError(error);
    }
  };

  const renderDeletePaymentMethodSettings = () => (
    <DeletePaymentMethodSettings
      additionalPaymentType={PaymentType.Card}
      onChangeConfirm={deleteAchConfirmation.toggle}
      confirm={deleteAchConfirmation.state}
      loading={loading}
      paymentMethodId={data?.defaultPaymentInstruction?.id || ""}
      refetch={refetch}
      editing={editing}
    />
  );

  return loading ? (
    <Skeleton variant="rectangular" height={65} width="98%" />
  ) : addBankAccountTrigger.state ? (
    <Box px={2} mt={2}>
      <Stack mb={2}>
        <Typography variant="body1">
          {t("achInformationPortalSettings")}
        </Typography>
      </Stack>
      <EditAchPaymentMethod
        editAchPayment={addBankAccountTrigger.toggle}
        loading={mutationLoading}
        error={error}
        stripeError={stripeError}
        stripeErrorMessage={stripeErrorMessage}
        onSubmit={onSubmit}
        setStripeError={setStripeError}
        autopayPreference={autopayPreference}
        handleAutopayPreferenceChange={handleAutopayPreferenceChange}
        showAutopayErrorMessage={showAutopayErrorMessage}
        handleAuthorizeAutopayCheckboxChange={
          handleAuthorizeAutopayCheckboxChange
        }
        showAuthorizeAutopayError={showAuthorizeAutopayError.state}
        isPrepay={Boolean(isPrepay)}
      />
    </Box>
  ) : (
    <Stack
      direction="column"
      px={2}
      mb={1}
      data-cy="bank-account-information-card"
    >
      <Stack justifyContent="space-between" mt={2}>
        <Stack>
          <Stack>
            <Typography variant="body1">
              {t("achInformationPortalSettings")}
            </Typography>
          </Stack>
          <Stack
            direction="row"
            justifyContent={
              data?.defaultPaymentInstruction?.maskedAccountIdentifier
                ? "space-between"
                : "left"
            }
          >
            <Typography
              variant={isMobile ? "body2" : "body1"}
              color="tertiary"
              textAlign="left"
            >
              {data?.defaultPaymentInstruction?.maskedAccountIdentifier &&
                t("last4DigitsAch")}{" "}
              {data?.defaultPaymentInstruction?.maskedAccountIdentifier ||
                (!editing.state && t("noAchPortalSettings"))}
            </Typography>

            {!data?.defaultPaymentInstruction?.maskedAccountIdentifier &&
              editing.state && (
                <Stack alignItems="flex-start">
                  <Button
                    variant="text"
                    onClick={addBankAccountTrigger.setTrue}
                  >
                    <Typography color="tertiary" textAlign="left">
                      <u>{t("addBankAccount")}</u>
                    </Typography>
                  </Button>
                </Stack>
              )}

            {data?.defaultPaymentInstruction?.maskedAccountIdentifier &&
              editing.state &&
              !deleteAchConfirmation.state &&
              renderDeletePaymentMethodSettings()}
          </Stack>
          {deleteAchConfirmation.state && renderDeletePaymentMethodSettings()}
        </Stack>
      </Stack>
    </Stack>
  );
};
