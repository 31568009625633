import { Locale } from "@core/constants";
import { Box, Stack, Typography } from "@octopus-energy/coral-mui";
import { format } from "date-fns";
import { es } from "date-fns/locale";
import { useFormik } from "formik";
import { AnimatePresence, motion } from "framer-motion";
import useTranslation from "next-translate/useTranslation";
import { useRouter } from "next/router";
import { useContext } from "react";
import * as Yup from "yup";
import { EnrollmentContext } from "../enrollmentContext";

const formatAsMobile = (mobile: string): string =>
  `(${mobile?.slice(0, 3)}) ${mobile?.slice(3, 6)}-${mobile?.slice(6, 11)}`;

const fadeDown = {
  hidden: {
    opacity: 0,
    top: -30,
  },
  show: {
    opacity: 1,
    top: 0,
  },
};

type ServiceAddressFormData = {
  firstName: string;
  lastName: string;
  phoneNumber: string;
  service_Address: string;
  service_AptSuite: string;
  service_City: string;
  service_State: string;
  service_ZipCode: string;
  esiId: string;
};

const ServiceAddress = () => {
  const enrollmentContext = useContext(EnrollmentContext);
  const { t } = useTranslation("enrollment/formFields");
  const router = useRouter();
  const lang = router.locale;
  const locale = lang === Locale.SPANISH ? es : undefined;
  const formattedSwitchDate = format(
    new Date(enrollmentContext.formData.getReady?.service_SwitchDate || ""),
    "MMMM dd, yyyy",
    { locale }
  );

  const handleSubmit = (values: ServiceAddressFormData) => {
    const toSend = enrollmentContext.formData;
    if (!toSend.getReady) {
      return;
    }
    toSend.getReady.service_Address = values.service_Address;
    toSend.getReady.service_AptSuite = values.service_AptSuite;
    toSend.getReady.service_City = values.service_City;
    toSend.getReady.service_State = values.service_State;
    toSend.getReady.service_ZipCode = values.service_ZipCode;
    toSend.getReady.firstName = values.firstName;
    toSend.getReady.lastName = values.lastName;
    toSend.getReady.phoneNumber = values.phoneNumber;
    enrollmentContext.setFormData(toSend);
  };

  const validationSchema = Yup.object().shape({
    firstName: Yup.string()
      .min(1, "validation_firstName")
      .required("validation_firstName"),
    lastName: Yup.string()
      .min(1, "validation_lastName")
      .required("validation_lastName"),
    phoneNumber: Yup.string()
      .min(10, "validation_phoneNotEntered")
      .max(10, "validation_phoneNotEntered")
      .required("validation_phoneNotEntered"),
    service_Address: Yup.string().required("validation_serviceAddressNotValid"),
    service_AptSuite: Yup.string(),
    service_City: Yup.string()
      .min(1, "validation_serviceCityNotValid")
      .required("validation_serviceCityNotValid"),
    service_State: Yup.string()
      .min(1, "validation_serviceStateNotValid")
      .required("validation_serviceStateNotValid"),
    service_ZipCode: Yup.string()
      .min(5, "validation_serviceZipCodeNotValid")
      .max(5, "validation_serviceZipCodeNotValid")
      .required("validation_serviceZipCodeNotValid"),
  });

  function initialData(): ServiceAddressFormData {
    return {
      firstName: `${enrollmentContext.formData.getReady?.firstName}`,
      lastName: `${enrollmentContext.formData.getReady?.lastName}`,
      phoneNumber: `${enrollmentContext.formData.getReady?.phoneNumber}`,
      service_Address: `${enrollmentContext.formData.getReady?.service_Address}`,
      service_AptSuite: `${enrollmentContext.formData.getReady?.service_AptSuite}`,
      service_City: `${enrollmentContext.formData.getReady?.service_City}`,
      service_State: `${enrollmentContext.formData.getReady?.service_State}`,
      service_ZipCode: `${enrollmentContext.formData.getReady?.service_ZipCode}`,
      esiId: `${enrollmentContext.formData.getReady?.esiId}`,
    };
  }

  const formik = useFormik({
    initialValues: initialData(),
    validationSchema: validationSchema,
    onSubmit: handleSubmit,
  });

  return (
    <div className="w-full" data-cy="service-address-form">
      <form
        onSubmit={formik.handleSubmit}
        className="w-full flex flex-row flex-wrap justify-start align-stretch flex-wrap relative"
      >
        <Stack direction="row" width="100%">
          <Typography variant="h3" fontWeight="bold" mb={2} width="100%">
            {t("finaliseCustomerDetailsHead")}
          </Typography>
        </Stack>
        <div className="edit-container md:w-10/12 w-full flex flex-wrap flex-row">
          <div className="w-full flex flex-row flex-wrap">
            <label className="mr-2 mt-0">
              <Typography data-cy="first-name" variant="h4" fontWeight="bold">
                {formik.values.firstName}
              </Typography>
            </label>
            <label className="mr-2 hide-label mt-0 transition-all">
              <Typography data-cy="last-name" variant="h4" fontWeight="bold">
                {formik.values.lastName}
              </Typography>
            </label>
          </div>
          <label className="mr-2 hide-label mt-3 mb-2 transition-all">
            <Typography data-cy="phone-number">
              {formatAsMobile(formik.values.phoneNumber)}
            </Typography>
          </label>
          <div className="w-full flex flex-row flex-wrap">
            <label className={`mr-2 mt-2`}>
              <Typography data-cy="service-address">
                {formik.values.service_Address}
              </Typography>
            </label>
            <label className={`mr-2 mt-2`}>
              <Typography data-cy="service-apt-suite">
                {formik.values.service_AptSuite}
              </Typography>
            </label>
          </div>

          <label className={`mr-2`}>
            <Box display="flex">
              <Typography data-cy="service-city">
                {formik.values.service_City}
              </Typography>
              <Typography>{formik.values.service_City && ","}</Typography>
              <Typography>&nbsp;</Typography>
              <Typography data-cy="service-state">
                {formik.values.service_State}
              </Typography>
              <Typography>&nbsp;</Typography>
              <Typography data-cy="service-zip-code">
                {formik.values.service_ZipCode}
              </Typography>
            </Box>
          </label>
          <div className="flex flex-row flex-wrap w-full">
            <p className="px-0 py-0 mb-0 mt-4 text-lg w-full" data-cy="esi">
              ESI: {formik.values.esiId}
            </p>
            <Typography data-cy="formatted-switch-date">
              {t("switchDate")}: {formattedSwitchDate}
            </Typography>
          </div>
        </div>
        <div className="error_msg global-error w-full">
          <AnimatePresence>
            {formik.errors.service_Address && formik.touched.service_Address ? (
              <motion.div
                initial="hidden"
                animate="show"
                exit="hidden"
                variants={fadeDown}
                className=" z-0 relative w-full pt-4 text-warning text-xs "
              >
                {t(formik.errors.service_Address)}
              </motion.div>
            ) : null}
            {formik.errors.service_City && formik.touched.service_City ? (
              <motion.div
                initial="hidden"
                animate="show"
                exit="hidden"
                variants={fadeDown}
                className=" z-0 relative w-full pt-4 text-warning text-xs"
              >
                {t(formik.errors.service_City)}
              </motion.div>
            ) : null}
            {formik.errors.service_State && formik.touched.service_State ? (
              <motion.div
                initial="hidden"
                animate="show"
                exit="hidden"
                variants={fadeDown}
                className=" z-0 relative w-full pt-4 text-warning text-xs"
              >
                {t(formik.errors.service_State)}
              </motion.div>
            ) : null}
            {formik.errors.service_ZipCode && formik.touched.service_ZipCode ? (
              <motion.div
                initial="hidden"
                animate="show"
                exit="hidden"
                variants={fadeDown}
                className=" z-0 relative w-full pt-4 text-warning text-xs"
              >
                {t(formik.errors.service_ZipCode)}
              </motion.div>
            ) : null}
            {formik.errors.firstName && formik.touched.firstName ? (
              <motion.div
                initial="hidden"
                animate="show"
                exit="hidden"
                variants={fadeDown}
                className=" z-0 relative w-full pt-4 text-warning text-xs"
              >
                {t(formik.errors.firstName)}
              </motion.div>
            ) : null}
            {formik.errors.lastName && formik.touched.lastName ? (
              <motion.div
                initial="hidden"
                animate="show"
                exit="hidden"
                variants={fadeDown}
                className=" z-0 relative w-full pt-4 text-warning text-xs"
              >
                {t(formik.errors.lastName)}
              </motion.div>
            ) : null}
            {formik.errors.phoneNumber && formik.touched.phoneNumber ? (
              <motion.div
                initial="hidden"
                animate="show"
                exit="hidden"
                variants={fadeDown}
                className=" z-0 relative w-full pt-4 text-warning text-xs"
              >
                {t(formik.errors.phoneNumber)}
              </motion.div>
            ) : null}
          </AnimatePresence>
        </div>
      </form>
    </div>
  );
};

export default ServiceAddress;
